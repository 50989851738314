import { useEffect, useMemo } from "react";
//

import { Route, Routes, useLocation } from "react-router-dom";

import { ExchangeV2 } from "pages/Exchange/ExchangeV2";

import { ReferralsV2 } from "pages/Referrals/ReferralsV2";
import "react-toastify/dist/ReactToastify.css";

import "styles/Font.css";
import "styles/Input.css";
import "styles/Shared.css";
import "./App.scss";

import EventToastContainer from "components/EventToast/EventToastContainer";
import useEventToast from "components/EventToast/useEventToast";

import PageNotFound from "pages/PageNotFound/PageNotFound";

import Header from "components/Header/Header";
import { useChainId } from "futures-lib/chains";
import { useHandleUnsupportedNetwork } from "futures-lib/wallets";
import Vault from "pages/Vault/Vault";

import { Trans } from "@lingui/macro";
import { currentLanguageAtom, resetAtom } from "atoms";
import { pendingTxnsAtom } from "atoms/exchange";
import cx from "classnames";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { getExplorerUrl, SUPPORTED_CHAIN_IDS } from "config/chains";
import useReferrerAddress from "futures-domain/referral/useReferrerAddress";
import { getWsProvider } from "futures-domain/trades/utils";
import { helperToast } from "futures-lib/helperToast";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import Landing from "pages/Landing/Landing";
// import { getAbi, getContract } from "config/contracts";
// import { BigNumber, ethers } from "ethers";
// import { toast } from "react-toastify";
// import { Trade } from "futures-domain/trades/types";
// import { formatAmount } from "futures-lib/numbers";
// import { getToken } from "config/tokens";
import Staking from "pages/Staking/Staking";
// import { formatUnits } from "@ethersproject/units";
// import { ADDED_DECIMALS } from "components/Exchange/constants";
// import Landing1 from "pages/Landing/Landing1";
// import TradingEvent from "components/Popup/TradingEvent";
import { TermsAgreementModal } from "components/Common/TermsAgreementModal";
import SignInModal from "components/SocialLogin/SignInModal";
import { APP_MODES } from "futures-domain/types";
import useWallet from "futures-lib/wallets/useWallet";
// import Login from "pages/Login/Login";

import ConfirmWithdraw from "components/Wallet/ConfirmWithdraw";
import EmailWalletModal from "components/Wallet/EmailWalletModal";
import WalletAccount from "components/Wallet/WalletAccount";
import { useCommonVault } from "futures-domain/vault/useCommonVault";
import ApproveRevoke from "pages/ApproveRevoke";
import SentryTest from "pages/SentryTest";
import { getConstantVersion } from "./App";

if (window?.ethereum?.autoRefreshOnNetworkChange) {
  window.ethereum.autoRefreshOnNetworkChange = false;
}

function FullAppV2() {
  const { account, isActive, signer } = useWallet();
  const { chainId } = useChainId();
  const reset = useSetAtom(resetAtom);

  useReferrerAddress();

  useCommonVault();

  useEffect(() => {
    reset();
  }, [chainId, account, isActive, reset]);

  useEventToast();

  useHandleUnsupportedNetwork();

  const [pendingTxns, setPendingTxns] = useAtom(pendingTxnsAtom);

  useEffect(() => {
    for (const chainId of SUPPORTED_CHAIN_IDS) {
      const key = JSON.stringify([chainId, "Selected-pair"]);
      window.localStorage.removeItem(key);
    }
  }, []);

  useEffect(() => {
    if (!signer) return;

    const checkPendingTxns = async () => {
      const updatedPendingTxns: any[] = [];

      for (const pendingTxn of pendingTxns) {
        const receipt = await signer.provider?.getTransactionReceipt(
          pendingTxn.hash
        );

        if (receipt) {
          if (receipt.status === 0) {
            const txUrl = `${getExplorerUrl(chainId)}tx/${pendingTxn.hash}`;
            helperToast.error(
              <div>
                <div className="toastify-body-only">
                  <Trans>Txn Failed</Trans>
                </div>
                <ExternalLink href={txUrl}>View</ExternalLink>
              </div>,
              { autoClose: 5000 }
            );
          }
          if (
            receipt.status === 1 &&
            pendingTxn.message &&
            typeof pendingTxn.message === "object"
          ) {
            helperToast.success(pendingTxn.message, {
              autoClose: 5000,
              toastId: pendingTxn.message,
            });
          } else if (
            receipt.status === 1 &&
            pendingTxn.message &&
            typeof pendingTxn.message === "string"
          ) {
            const txUrl = `${getExplorerUrl(chainId)}tx/${pendingTxn.hash}`;
            helperToast.success(
              <div>
                <div className="toastify-body-only">{pendingTxn.message}</div>
                <ExternalLink href={txUrl}>
                  <Trans>View Status</Trans>
                </ExternalLink>
              </div>,
              { autoClose: 5000, toastId: pendingTxn.message }
            );
          }
          continue;
        }
        updatedPendingTxns.push(pendingTxn);
      }

      if (updatedPendingTxns.length !== pendingTxns.length) {
        setPendingTxns(updatedPendingTxns);
      }
    };

    const interval = setInterval(() => {
      checkPendingTxns();
    }, 2 * 1000);
    return () => clearInterval(interval);
  }, [signer, chainId, setPendingTxns, pendingTxns]);

  const currentLanguage = useAtomValue(currentLanguageAtom);

  // const fromTokenAddress = getContract(chainId, "CollateralToken");
  // const fromToken = getToken(chainId, fromTokenAddress);

  // const pairs = useAtomValue(pairsAtom);
  // const setCurrentBlockNumberAtom = useSetAtom(currentBlockNumberAtom);
  // const setWaitingOpenTrades = useSetAtom(waitingOpenTradesAtom);

  useEffect(() => {
    if (!isActive) {
      return;
    }

    const wsProvider = getWsProvider(isActive, chainId);

    if (!wsProvider) {
      return;
    }

    // const tradingCallbacksAddress = getContract(chainId, "TradingCallbacks");

    /**
     * * '24.02.23
     * * block.timestamp가 javascript date.timestamp보다 약간 느림
     * * zksync 1 ~ 2초 / arbitrum 2 ~ 4초
     *
     */
    // wsProvider.on("block", async (value) => {
    //   // setCurrentBlockNumberAtom(value);
    //   const block = await wsProvider.getBlock(value);
    //   console.log(block.timestamp);
    //   console.log(new Date().getTime());
    // });

    // const wsTradingCallbacks = new ethers.Contract(
    //   tradingCallbacksAddress,
    //   getAbi(chainId, "TradingCallbacks").abi,
    //   wsProvider
    // );

    // handle the subscriptions here instead of within the Exchange component to avoid unsubscribing and re-subscribing
    // each time the Exchange components re-renders, which happens on every data update
    // const onMarketOrderInitiated = (...args) => {
    //   console.log(args);
    // };
    // const onOpenLimitPlaced = (...args) => {
    //   console.log(args);
    // };

    // const onMarketExecuted = (
    //   ...args: [BigNumber, any, boolean, BigNumber, BigNumber, BigNumber, BigNumber, BigNumber]
    // ) => {
    //   // console.log("onMarketExecuted");
    //   let [
    //     ,
    //     // orderId
    //     trade,
    //     open,
    //     openPrice, // priceImpactP
    //     ,
    //     positionSizeUsdc, // percentProfit
    //     ,
    //     usdcSentToTrader,
    //   ] = args;

    //   const [address, pairIndex, positionIndex, , tradePositionSizeUsdc, tradeOpenPrice, buy, leverage, tp, sl]: [
    //     string,
    //     BigNumber,
    //     BigNumber,
    //     BigNumber,
    //     BigNumber,
    //     BigNumber,
    //     boolean,
    //     BigNumber,
    //     BigNumber,
    //     BigNumber
    //   ] = trade;

    //   if (account?.toLocaleLowerCase() !== address.toLocaleLowerCase()) {
    //     return;
    //   }

    //   const profitable = _profitable(tradePositionSizeUsdc, usdcSentToTrader, buy);

    //   const pair = pairs.find((pair) => pair.pairIndex === pairIndex.toNumber());

    //   if (pair) {
    //     if (open && toast.isActive(`open:${buy}:${pair.from}:${pair.to}`)) {
    //       toast.dismiss(`open:${buy}:${pair.from}:${pair.to}`);
    //       helperToast.success(
    //         <div>
    //           <div className="toastify-title">
    //             <span>
    //               <Trans>Open Trade</Trans>
    //             </span>
    //             <span> ({buy ? t`LONG` : t`SHORT`})</span>
    //           </div>
    //           <div className="toastify-body">
    //             {pair.from}/{pair.to} @ {comma1000(trimPriceBN(tradeOpenPrice))}
    //           </div>
    //         </div>,
    //         {
    //           autoClose: 7000,
    //         }
    //       );

    //       setWaitingOpenTrades((prev) => {
    //         const newTrade = {
    //           address,
    //           pairIndex: pairIndex.toNumber(),
    //           pair: pair,
    //           positionIndex: positionIndex.toString(),
    //           // initialPosToken: [WEMIX_TESTNET, HARDHAT].includes(chainId) ? BigNumber.from(0) : initialPosToken,
    //           positionSizeUsdc,
    //           tradePositionSizeUsdc,
    //           openPrice: tradeOpenPrice,
    //           buy,
    //           leverage: +formatUnits(leverage, ADDED_DECIMALS),
    //           tp,
    //           sl,
    //           isWaitingTrade: true,
    //         } as Trade;
    //         if (prev) {
    //           return [...prev, newTrade];
    //         }
    //         return [newTrade];
    //       });
    //     } else {
    //       if (toast.isActive(`close:${buy}:${pair.from}:${pair.to}`)) {
    //         toast.dismiss(`close:${buy}:${pair.from}:${pair.to}`);
    //         helperToast.success(
    //           <div>
    //             <div className="toastify-title">
    //               <Trans>Close Trade</Trans>
    //             </div>
    //             <div className="toastify-body">
    //               {pair.from}/{pair.to} @ {comma1000(trimPriceBN(openPrice))}
    //             </div>
    //             <div className={cx("text-[1.4rem]", profitable ? "text-green-2" : "text-red-2")}>
    //               <span>
    //                 {formatAmount(usdcSentToTrader.sub(tradePositionSizeUsdc), fromToken.decimals, 2, true)}{" "}
    //                 {fromToken.symbol}{" "}
    //               </span>
    //               <span>
    //                 ({getClosePnL(tradePositionSizeUsdc, usdcSentToTrader, buy, fromToken.decimals)}
    //                 %)
    //               </span>
    //             </div>
    //           </div>,
    //           {
    //             autoClose: 7000,
    //           }
    //         );
    //       }
    //     }
    //   }
    // };
    // wsTradingCallbacks.on("MarketExecuted", onMarketExecuted);

    // return function cleanup() {
    //   // wsTradingCallbacks.off("MarketExecuted", onMarketExecuted);
    //   wsProvider.off("block");
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, isActive, chainId]);

  const { pathname } = useLocation();

  const bgPath = useMemo(() => {
    switch (pathname) {
      case "/staking":
        return {
          mobile: "bg-[url('img/bg-staking-mobile.jpg')]",
          desktop: "lg:bg-[url('img/bg-staking-desktop.jpg')]",
        };
      default:
        return {
          mobile: "bg-[url('img/bg-landing-mobile.jpg')]",
          desktop: "lg:bg-[url('img/bg-landing-desktop.jpg')]",
        };
    }
  }, [pathname]);

  // const tradingCompetitionEndpoint = useMemo(() => {
  //   try {
  //     return `${getConstant(chainId, "eventApiServerUrl")}/event`;
  //   } catch (e) {
  //     return `${getConstant(DEFAULT_CHAIN_ID, "eventApiServerUrl")}/event`;
  //   }
  // }, [chainId]);

  // const currentTime = useAtomValue(currentTimeAtom);

  // const { data: eventData } = useSWR<IEventInfo>(
  //   `${tradingCompetitionEndpoint}/weekly/info?target=MAIN`,
  //   {
  //     fetcher: swrFetcher,
  //     shouldRetryOnError: true,
  //     errorRetryInterval: 1000,
  //     refreshInterval: 1000 * 60 * 5, // 5분
  //     // refreshInterval: 1000,
  //   }
  // );

  // const showTradingEventPopup = useMemo(() => {
  //   if (process.env.REACT_APP_MODE === APP_MODES.LOCAL) return false;

  //   if (
  //     !eventData ||
  //     !currentTime ||
  //     !eventData.startTimestamp ||
  //     !eventData.endTimestamp
  //   )
  //     return;

  //   if (
  //     +eventData.startTimestamp <= +currentTime.unix() &&
  //     +currentTime.unix() <= +eventData.endTimestamp
  //   ) {
  //     return true;
  //   }

  //   return false;
  // }, [currentTime, eventData]);

  return (
    <>
      <div
        className={cx(
          "App",
          currentLanguage === "ko" ? "font-pretendard" : "font-space-grotesk"
        )}
      >
        <div
          className={cx(
            "App-content bg-cover bg-bottom md:bg-center min-h-screen",
            `${bgPath.mobile}`,
            `${bgPath.desktop}`
          )}
        >
          <Header />

          <Routes>
            {/* <Route path="/" element={<Navigate to="/trade" />} /> */}
            <Route path="/" element={<Landing />} />
            {/* <Route path="/event-popup" element={<Landing1 />} /> */}
            <Route path="/trade" element={<ExchangeV2 />} />
            <Route path="/referrals" element={<ReferralsV2 />} />
            <Route path="/referrals/:account" element={<ReferralsV2 />} />
            <Route path="/vault" element={<Vault />} />
            {/* <Route path="/trading-competition" element={<TradingCompetition />} /> */}
            {/* <Route
              path="/trading-competition"
              element={<ShortTermTradingCompetition />}
            /> */}
            <Route path="/staking" element={<Staking />} />
            {/* {process.env.REACT_APP_MODE !== APP_MODES.PRODUCTION && (
              <Route path="/trading-modal-test" element={<TradingModalTest />} />
            )} */}
            {process.env.REACT_APP_MODE !== APP_MODES.PRODUCTION && (
              <>
                <Route
                  path="/version"
                  element={
                    <div className="flex justify-center h-[calc(100dvh-100px)] items-center">
                      {getConstantVersion()}
                    </div>
                  }
                />
                <Route path="/approve-revoke" element={<ApproveRevoke />} />
              </>
            )}

            {process.env.REACT_APP_MODE === APP_MODES.LOCAL && (
              <Route path="/sentry-test" element={<SentryTest />} />
            )}
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </div>
      </div>
      <EventToastContainer />

      <SignInModal />
      <WalletAccount />
      <ConfirmWithdraw />
      <EmailWalletModal />

      {/* {showTradingEventPopup && <ShortTermTradingEvent />} */}
      {/* <ShortTermTradingEvent /> */}

      <TermsAgreementModal />
    </>
  );
}

export default FullAppV2;
