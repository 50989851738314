import { Chain } from "@rainbow-me/rainbowkit";
import { APP_MODES } from "futures-domain/types";
import { NetworkMetadata, NewNetworkMetadata } from "futures-lib/wallets";
import { sample } from "lodash";
// import WemixTestnetFees from "@changerio/futures-contracts/data/gambit/wemixTestnet/GambitPairsStorageV1-fees.json";
// import ZKsyncEraFees from "@changerio/futures-contracts/data/gambit/zksyncEra/GambitPairsStorageV1-fees.json";
// import ZKsyncEraGoerliFees from "@changerio/futures-contracts/data/gambit/zksyncEraGoerli/GambitPairsStorageV1-fees.json";
// import LocalhostFees from "@changerio/futures-contracts/data/gambit/localhost/GambitPairsStorageV1-fees.json";

export const ETH_MAINNET = 1;

export const POLYGON = 137;
export const POLYGON_MUMBAI = 80001;
export const ZKSYNC = 324;
export const ZKSYNC_GOERLI = 280;
export const ZKSYNC_SEPOLIA = 300;
export const HARDHAT = 31337;
export const WEMIX_TESTNET = 1112;
export const ARBITRUM = 42161;
export const ARBITRUM_GOERLI = 421613;
export const ARBITRUM_SEPOLIA = 421614;

export const TESTNETS = [ZKSYNC_SEPOLIA, ARBITRUM_SEPOLIA];

// export const DEFAULT_CHAIN_ID = process.env.REACT_APP_MODE === APP_MODES.STAGING ? ARBITRUM_SEPOLIA : ARBITRUM;
export const DEFAULT_CHAIN_ID = ZKSYNC;

// export const SUPPORTED_CHAIN_IDS = process.env.REACT_APP_MODE === APP_MODES.STAGING ? [] : [DEFAULT_CHAIN_ID, ZKSYNC];
export const SUPPORTED_CHAIN_IDS = [DEFAULT_CHAIN_ID, ARBITRUM];

if (process.env.REACT_APP_MODE === APP_MODES.PRODUCTION) {
  // SUPPORTED_CHAIN_IDS.push(ARBITRUM_GOERLI);
  SUPPORTED_CHAIN_IDS.push(ARBITRUM_SEPOLIA);
}

if (process.env.REACT_APP_MODE === APP_MODES.STAGING) {
  // SUPPORTED_CHAIN_IDS.push(...TESTNETS);
  SUPPORTED_CHAIN_IDS.push(ARBITRUM_SEPOLIA);
  SUPPORTED_CHAIN_IDS.push(ZKSYNC_SEPOLIA);
}

if (process.env.REACT_APP_MODE === APP_MODES.LOCAL) {
  SUPPORTED_CHAIN_IDS.push(...TESTNETS);
  SUPPORTED_CHAIN_IDS.push(HARDHAT);
}

// export const IS_NETWORK_DISABLED = {
//   [ARBITRUM]: false,
//   [AVALANCHE]: false,
// };

export const CHAIN_NAMES_MAP = {
  [POLYGON]: "Polygon",
  [POLYGON_MUMBAI]: "Polygon Mumbai",
  [ZKSYNC]: "zkSync Era",
  [ZKSYNC_GOERLI]: "zkSync Era Goerli",
  [ZKSYNC_SEPOLIA]: "zkSync Era Sepolia",
  [HARDHAT]: "Hardhat",
  [WEMIX_TESTNET]: "Wemix Testnet",
  [ARBITRUM]: "Arbitrum",
  [ARBITRUM_GOERLI]: "Arbitrum Goerli",
  [ARBITRUM_SEPOLIA]: "Arbitrum Sepolia",
};

export const CHAIN_NAMES_MAP_FOR_CONTRACT = {
  [ZKSYNC]: "zksyncEra",
  [ZKSYNC_GOERLI]: "zksyncEraGoerli",
  [ZKSYNC_SEPOLIA]: "zksyncEraSepolia",
  [HARDHAT]: "hardhat",
  [WEMIX_TESTNET]: "wemixTestnet",
  [ARBITRUM]: "arbitrumOne",
  [ARBITRUM_GOERLI]: "arbitrumGoerli",
  [ARBITRUM_SEPOLIA]: "arbitrumSepolia",
};

export const GAS_PRICE_ADJUSTMENT_MAP = {
  // [ARBITRUM]: "0",
  // [AVALANCHE]: "3000000000", // 3 gwei
  [POLYGON]: "0",
  [POLYGON_MUMBAI]: "0",
  [ZKSYNC]: "0",
  [ZKSYNC_GOERLI]: "0",
  [ZKSYNC_SEPOLIA]: "0",
  [HARDHAT]: "0",
  [WEMIX_TESTNET]: "0",
  [ARBITRUM]: "0",
  [ARBITRUM_GOERLI]: "0",
  [ARBITRUM_SEPOLIA]: "0",
};

export const MAX_GAS_PRICE_MAP = {
  // [AVALANCHE]: "200000000000", // 200 gwei
};

export const HIGH_EXECUTION_FEES_MAP = {
  [POLYGON]: 3, // 3 USD
  [POLYGON_MUMBAI]: 3, // 3 USD
  [ZKSYNC]: 3, // 3 USD
  [ZKSYNC_GOERLI]: 3, // 3 USD
  [ZKSYNC_SEPOLIA]: 3, // 3 USD
  [HARDHAT]: 3, // 3 USD
  [WEMIX_TESTNET]: 3, // 3 USD
  [ARBITRUM]: 3, // 3 USD
  [ARBITRUM_GOERLI]: 3, // 3 USD
  [ARBITRUM_SEPOLIA]: 3, // 3 USD
};

// * pyth network api swagger: https://hermes.pyth.network/docs/
const constants = {
  [POLYGON]: {
    nativeTokenSymbol: "MATIC",
    wrappedTokenSymbol: "WMATIC",
    defaultCollateralSymbol: "DAI",

    priceServerEndPoint: "https://hermes-beta.pyth.network/api", // Pyth-Network
    // priceFeedIds: pythNetworkTestnetPriceFeedIds,
    numOfL1BlocksIn1Hour: 30,

    // Valut
    epochLong: 72, // Each epoch is 72 hours long.
    requestToWithdrawLong: 48, //You can make a request to withdraw your assets during the first 48 hours of any epoch.
    withdrawLong: 48, // You must withdraw your assets in the first 48 hours of your withdraw epoch, otherwise a new request is required.
  },
  [POLYGON_MUMBAI]: {
    nativeTokenSymbol: "MATIC",
    wrappedTokenSymbol: "WMATIC",
    defaultCollateralSymbol: "DAI",

    priceServerEndPoint: "https://hermes-beta.pyth.network/api", // Pyth-Network
    // priceFeedIds: pythNetworkTestnetPriceFeedIds,
    numOfL1BlocksIn1Hour: 30,

    // Vault
    epochLong: 4, // Each epoch is 4 hours long.
    requestToWithdrawLong: 1, //You can make a request to withdraw your assets during the first 1 hours of any epoch.
    withdrawLong: 1, // You must withdraw your assets in the first 1 hours of your withdraw epoch, otherwise a new request is required.
  },
  [ZKSYNC]: {
    nativeTokenSymbol: "ETH",
    wrappedTokenSymbol: "WETH",
    defaultCollateralSymbol: "USDC",

    priceServerEndPoint: "https://hermes.pyth.network/api", // Pyth-Network
    numOfL1BlocksIn1Hour: 3600, // 3600 / oneBatchBlockTime

    marketOrdersTimeout: 180, // 180 batch blocks
    limitOrdersTimelock: 180, // 180 batch blocks
    oneBatchBlockTime: 1, // 1 batch block 올라가는데 걸리는 시간 1s

    // Trading Competition
    eventApiServerUrl: "https://service.gambit.trade:7443",
  },
  [HARDHAT]: {
    nativeTokenSymbol: "ETH",
    wrappedTokenSymbol: "WETH",
    defaultCollateralSymbol: "USDC",

    priceServerEndPoint: "https://hermes-beta.pyth.network/api", // Pyth-Network
    numOfL1BlocksIn1Hour: 30,

    marketOrdersTimeout: 1, // "1 batch for testnet, 3 batch for mainnet",
    limitOrdersTimelock: 1, // "1 batch for testnet, 3 batch for mainnet",
    oneBatchBlockTime: 120, // 1 batch block 올라가는데 걸리는 시간 120s, mainnet은 60s

    // Trading Competition
    eventApiServerUrl: "https://service.gambit.trade:7443",

    vaultAPR: 25,
    cngAPR: 7,
  },
  [ZKSYNC_GOERLI]: {
    nativeTokenSymbol: "ETH",
    wrappedTokenSymbol: "WETH",
    defaultCollateralSymbol: "USDC",

    priceServerEndPoint: "https://hermes-beta.pyth.network/api", // Pyth-Network
    numOfL1BlocksIn1Hour: 1376.29409876, // 3600 / oneBatchBlockTime

    marketOrdersTimeout: 23, // "44 batch for testnet, 3 batch for mainnet",
    limitOrdersTimelock: 23, // "44 batch for testnet, 3 batch for mainnet",
    oneBatchBlockTime: 2.61572, // 1 batch block 올라가는데 걸리는 시간 120s

    // Trading Competition
    eventApiServerUrl: "https://service.gambit.trade:7443",
  },
  [ZKSYNC_SEPOLIA]: {
    nativeTokenSymbol: "ETH",
    wrappedTokenSymbol: "WETH",
    defaultCollateralSymbol: "USDC",

    priceServerEndPoint: "https://hermes.pyth.network/api", // Pyth-Network
    numOfL1BlocksIn1Hour: 3600, // 3600 / oneBatchBlockTime

    marketOrdersTimeout: 180, // 180 batch blocks
    limitOrdersTimelock: 180, // 180 batch blocks
    oneBatchBlockTime: 1, // 1 batch block 올라가는데 걸리는 시간 1s

    // Trading Competition
    eventApiServerUrl: "https://service.gambit.trade:7443",
  },
  [WEMIX_TESTNET]: {
    nativeTokenSymbol: "tWEMIX",
    wrappedTokenSymbol: "",
    defaultCollateralSymbol: "WEMIX$",

    priceServerEndPoint: "https://hermes-beta.pyth.network/api", // Pyth-Network
    numOfL1BlocksIn1Hour: 3600,

    marketOrdersTimeout: 180, // "180 block for testnet, ? block for mainnet",
    limitOrdersTimelock: 180, // "180 block for testnet, ? block for mainnet",
    oneBatchBlockTime: 1, // 1 batch block 올라가는데 걸리는 시간 1s

    // Trading Competition
    eventApiServerUrl: "https://service.gambit.trade:7443",
  },
  [ARBITRUM]: {
    nativeTokenSymbol: "ETH",
    wrappedTokenSymbol: "WETH",
    defaultCollateralSymbol: "USDC",

    priceServerEndPoint: "https://hermes.pyth.network/api", // Pyth-Network
    numOfL1BlocksIn1Hour: 300, // 3600 / oneBatchBlockTime

    marketOrdersTimeout: 15, // 15 batch blocks
    limitOrdersTimelock: 15, // 15 batch blocks
    oneBatchBlockTime: 12, // 1 batch block 올라가는데 걸리는 시간 12s

    // Trading Competition
    eventApiServerUrl: "https://service.gambit.trade:7443",
  },
  [ARBITRUM_GOERLI]: {
    nativeTokenSymbol: "ETH",
    wrappedTokenSymbol: "WETH",
    defaultCollateralSymbol: "USDC",

    priceServerEndPoint: "https://hermes-beta.pyth.network/api", // Pyth-Network
    numOfL1BlocksIn1Hour: 240, // 3600 / oneBatchBlockTime

    marketOrdersTimeout: 12, // "44 batch for testnet, 3 batch for mainnet",
    limitOrdersTimelock: 12, // "44 batch for testnet, 3 batch for mainnet",
    oneBatchBlockTime: 15, // 1 batch block 올라가는데 걸리는 시간 120s

    // Trading Competition
    eventApiServerUrl: "https://service.gambit.trade:7443",
  },
  [ARBITRUM_SEPOLIA]: {
    nativeTokenSymbol: "ETH",
    wrappedTokenSymbol: "WETH",
    defaultCollateralSymbol: "USDC",

    priceServerEndPoint: "https://hermes.pyth.network/api", // Pyth-Network
    numOfL1BlocksIn1Hour: 300, // 3600 / oneBatchBlockTime

    marketOrdersTimeout: 15, // 15 batch blocks
    limitOrdersTimelock: 15, // 15 batch blocks
    oneBatchBlockTime: 12, // 1 batch block 올라가는데 걸리는 시간 12s

    // Trading Competition
    eventApiServerUrl: "https://service.gambit.trade:7443",
  },
};

// const ALCHEMY_WHITELISTED_DOMAINS = ["gmx.io", "app.gmx.io"];
export type ChainIdType = keyof typeof constants;
export type ChainPropertyType = keyof typeof constants[typeof HARDHAT];

export const RPC_PROVIDERS = {
  [ETH_MAINNET]: ["https://eth.llamarpc.com"],
  [POLYGON]: ["https://polygon-rpc.com"],
  [POLYGON_MUMBAI]: ["https://rpc-mumbai.maticvigil.com"],
  [ZKSYNC]: ["https://mainnet.era.zksync.io"],
  [ZKSYNC_GOERLI]: ["https://testnet.era.zksync.dev"],
  [ZKSYNC_SEPOLIA]: ["https://sepolia.era.zksync.dev"],
  [HARDHAT]: ["http://127.0.0.1:8545"],
  [WEMIX_TESTNET]: ["https://api.test.wemix.com"],
  [ARBITRUM]: ["https://arb1.arbitrum.io/rpc"],
  [ARBITRUM_GOERLI]: [
    // "https://endpoints.omniatech.io/v1/arbitrum/goerli/public",
    "https://goerli-rollup.arbitrum.io/rpc",
  ],
  [ARBITRUM_SEPOLIA]: ["https://sepolia-rollup.arbitrum.io/rpc"],
};

export const FALLBACK_PROVIDERS = {
  // [ARBITRUM]: [getAlchemyHttpUrl()],
  // [AVALANCHE]: ["https://avax-mainnet.gateway.pokt.network/v1/lb/626f37766c499d003aada23b"],
};

export const NETWORK_METADATA: { [chainId: number]: NetworkMetadata } = {
  [POLYGON]: {
    chainId: "0x" + POLYGON.toString(16),
    chainName: "Polygon",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: RPC_PROVIDERS[POLYGON],
    blockExplorerUrls: [getExplorerUrl(POLYGON)],
  },
  [POLYGON_MUMBAI]: {
    chainId: "0x" + POLYGON_MUMBAI.toString(16),
    chainName: "Polygon Mumbai",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: RPC_PROVIDERS[POLYGON_MUMBAI],
    blockExplorerUrls: [getExplorerUrl(POLYGON_MUMBAI)],
  },
  [ZKSYNC]: {
    chainId: "0x" + ZKSYNC.toString(16),
    chainName: "zkSync Era",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: RPC_PROVIDERS[ZKSYNC],
    blockExplorerUrls: [getExplorerUrl(ZKSYNC)],
  },
  [ZKSYNC_GOERLI]: {
    chainId: "0x" + ZKSYNC_GOERLI.toString(16),
    chainName: "zkSync Era Goerli",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: RPC_PROVIDERS[ZKSYNC_GOERLI],
    blockExplorerUrls: [getExplorerUrl(ZKSYNC_GOERLI)],
  },
  [ZKSYNC_SEPOLIA]: {
    chainId: "0x" + ZKSYNC_SEPOLIA.toString(16),
    chainName: "zkSync Era Sepolia",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: RPC_PROVIDERS[ZKSYNC_SEPOLIA],
    blockExplorerUrls: [getExplorerUrl(ZKSYNC_SEPOLIA)],
  },
  [HARDHAT]: {
    chainId: "0x" + HARDHAT.toString(16),
    chainName: "Hardhat",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: RPC_PROVIDERS[HARDHAT],
    blockExplorerUrls: [getExplorerUrl(ZKSYNC_GOERLI)],
  },
  [WEMIX_TESTNET]: {
    chainId: "0x" + WEMIX_TESTNET.toString(16),
    chainName: "WEMIX Testnet",
    nativeCurrency: {
      name: "tWEMIX",
      symbol: "tWEMIX",
      decimals: 18,
    },
    rpcUrls: RPC_PROVIDERS[WEMIX_TESTNET],
    blockExplorerUrls: [getExplorerUrl(WEMIX_TESTNET)],
  },
  [ARBITRUM]: {
    chainId: "0x" + ARBITRUM.toString(16),
    chainName: "Arbitrum One",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: RPC_PROVIDERS[ARBITRUM],
    blockExplorerUrls: [getExplorerUrl(ARBITRUM)],
  },
  [ARBITRUM_GOERLI]: {
    chainId: "0x" + ARBITRUM_GOERLI.toString(16),
    chainName: "Arbitrum Goerli",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: RPC_PROVIDERS[ARBITRUM_GOERLI],
    blockExplorerUrls: [getExplorerUrl(ARBITRUM_GOERLI)],
  },
  [ARBITRUM_SEPOLIA]: {
    chainId: "0x" + ARBITRUM_SEPOLIA.toString(16),
    chainName: "Arbitrum Sepolia",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: RPC_PROVIDERS[ARBITRUM_SEPOLIA],
    blockExplorerUrls: [getExplorerUrl(ARBITRUM_SEPOLIA)],
  },
};

// for web3-react v8
export const NEW_NETWORK_METADATA: { [chainId: number]: NewNetworkMetadata } = {
  [POLYGON]: {
    chainId: POLYGON,
    chainName: "Polygon",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: RPC_PROVIDERS[POLYGON],
    blockExplorerUrls: [getExplorerUrl(POLYGON)],
  },
  [POLYGON_MUMBAI]: {
    chainId: POLYGON_MUMBAI,
    chainName: "Polygon Mumbai",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: RPC_PROVIDERS[POLYGON_MUMBAI],
    blockExplorerUrls: [getExplorerUrl(POLYGON_MUMBAI)],
  },
  [ZKSYNC]: {
    chainId: ZKSYNC,
    chainName: "zkSync Era",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: RPC_PROVIDERS[ZKSYNC],
    blockExplorerUrls: [getExplorerUrl(ZKSYNC)],
  },
  [ZKSYNC_GOERLI]: {
    chainId: ZKSYNC_GOERLI,
    chainName: "zkSync Era Goerli",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: RPC_PROVIDERS[ZKSYNC_GOERLI],
    blockExplorerUrls: [getExplorerUrl(ZKSYNC_GOERLI)],
  },
  [ZKSYNC_SEPOLIA]: {
    chainId: ZKSYNC_SEPOLIA,
    chainName: "zkSync Era Sepolia",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: RPC_PROVIDERS[ZKSYNC_SEPOLIA],
    blockExplorerUrls: [getExplorerUrl(ZKSYNC_SEPOLIA)],
  },
  [HARDHAT]: {
    chainId: HARDHAT,
    chainName: "Hardhat",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: RPC_PROVIDERS[ZKSYNC_GOERLI],
    blockExplorerUrls: [getExplorerUrl(ZKSYNC_GOERLI)],
  },
  [WEMIX_TESTNET]: {
    chainId: WEMIX_TESTNET,
    chainName: "Wemix Testnet",
    nativeCurrency: {
      name: "tWEMIX",
      symbol: "tWEMIX",
      decimals: 18,
    },
    rpcUrls: RPC_PROVIDERS[WEMIX_TESTNET],
    blockExplorerUrls: [getExplorerUrl(WEMIX_TESTNET)],
  },
  [ARBITRUM]: {
    chainId: ARBITRUM,
    chainName: "Arbitrum One",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: RPC_PROVIDERS[ARBITRUM],
    blockExplorerUrls: [getExplorerUrl(ARBITRUM)],
  },
  [ARBITRUM_GOERLI]: {
    chainId: ARBITRUM_GOERLI,
    chainName: "Arbitrum Goerli",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: RPC_PROVIDERS[ARBITRUM_GOERLI],
    blockExplorerUrls: [getExplorerUrl(ARBITRUM_GOERLI)],
  },
  [ARBITRUM_SEPOLIA]: {
    chainId: ARBITRUM_SEPOLIA,
    chainName: "Arbitrum Sepolia",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: RPC_PROVIDERS[ARBITRUM_SEPOLIA],
    blockExplorerUrls: [getExplorerUrl(ARBITRUM_SEPOLIA)],
  },
};

export const getConstant = (chainId: number, key: ChainPropertyType) => {
  if (!constants[chainId]) {
    throw new Error(`Unsupported chainId ${chainId}`);
  }

  if (!(key in constants[chainId])) {
    throw new Error(`Key ${key} does not exist for chainId ${chainId}`);
  }

  return constants[chainId][key];
};

export const getLimitTimeLockMinutes = (chainId: number) => {
  const oneBatchBlockTime: number = getConstant(chainId, "oneBatchBlockTime");
  const limitOrdersTimelock: number = getConstant(
    chainId,
    "limitOrdersTimelock"
  );

  return (limitOrdersTimelock * oneBatchBlockTime) / 60;
};

// export const getWaitTimeOutMinutes = (chainId: number) => {
//   const oneBatchBlockTime: number = getConstant(chainId, "oneBatchBlockTime");
//   // const marketOrdersTimeout: number = getConstant(chainId, "marketOrdersTimeout");

//   return (marketOrdersTimeout * oneBatchBlockTime) / 60;
// };

export function getChainName(chainId: number): string {
  return CHAIN_NAMES_MAP[chainId];
}

// 프론트 화면에서 사용자가 볼 네트워크 이름을 반환
export function getChainNameForMenu(chainId: number): string {
  return CHAIN_NAMES_MAP[chainId];
}

// changer-futures-contract의 네트워크에 맞는 컨트랙트를 찾기 위해 사용하는 네트워크 이름을 반환
export function getChainNameForContract(chainId: number): string {
  return CHAIN_NAMES_MAP_FOR_CONTRACT[chainId];
}

export function getRpcUrl(chainId: number): string {
  return sample(RPC_PROVIDERS[chainId]);
}

export function getFallbackRpcUrl(chainId: number): string | undefined {
  return sample(FALLBACK_PROVIDERS[chainId]);
}

// export const getWsUrl = (chainId: number) => {
//   if (chainId === POLYGON_MUMBAI) {
//     // return "wss://polygon-mumbai.g.alchemy.com/v2/r5gDJcVI6on2Q7qWcMjcI4-SZp1jkBdt";
//     return "wss://polygon-mumbai.g.alchemy.com/v2/8GZCiG-1fomRBmWopEcZNodbvu7suHtO";
//   }
//   return;
// };

// !NOTE: 마지막에 '/' 붙여줘야함 - tx 연결 링크 만들 때 바로 hash 붙일 수 있게
export function getExplorerUrl(chainId: number) {
  if (chainId === POLYGON) {
    return "https://polygonscan.com/";
  } else if (chainId === POLYGON_MUMBAI) {
    return "https://mumbai.polygonscan.com/";
  } else if (chainId === ZKSYNC_GOERLI) {
    return "https://goerli.explorer.zksync.io/";
  } else if (chainId === ZKSYNC_SEPOLIA) {
    return "https://sepolia.explorer.zksync.io/";
  } else if (chainId === ZKSYNC) {
    return "https://explorer.zksync.io/";
  } else if (chainId === WEMIX_TESTNET) {
    return "https://explorer.test.wemix.com/";
  } else if (chainId === ARBITRUM) {
    return "https://arbiscan.io/";
  } else if (chainId === ARBITRUM_GOERLI) {
    return "https://goerli.arbiscan.io/";
  } else if (chainId === ARBITRUM_SEPOLIA) {
    return "https://sepolia-explorer.arbitrum.io/";
  }
  return "https://etherscan.io/";
}

export function getBrigeUrl(chainId: number) {
  if (chainId === ZKSYNC_GOERLI) {
    return "https://portal.zksync.io/bridge/?network=era-goerli";
  } else if (chainId === ZKSYNC_SEPOLIA) {
    return "https://portal.zksync.io/bridge/?network=sepolia";
  } else if (chainId === ZKSYNC) {
    return "https://portal.zksync.io/bridge/?network=era-mainnet";
  } else if (chainId === WEMIX_TESTNET) {
    return "https://wallet.test.wemix.com/faucet";
  } else if (chainId === ARBITRUM_GOERLI) {
    return "https://bridge.arbitrum.io/?l2ChainId=421613";
  } else if (chainId === ARBITRUM_SEPOLIA) {
    return "https://bridge.arbitrum.io/?l2ChainId=421614";
  } else if (chainId === ARBITRUM) {
    return "https://bridge.arbitrum.io/?l2ChainId=42161";
  }
  return;
}

export function getHighExecutionFee(chainId) {
  return HIGH_EXECUTION_FEES_MAP[chainId] || 3;
}

export function isSupportedChain(chainId) {
  return SUPPORTED_CHAIN_IDS.includes(chainId);
}

// * for rainbowkit
export const wemixChainInfo: Chain = {
  id: WEMIX_TESTNET,
  name: "Wemix Testnet",
  network: "wemix testnet",
  iconUrl:
    "https://assets.coingecko.com/coins/images/12998/standard/wemixcoin_color_200.png",
  iconBackground: "#fff",
  nativeCurrency: {
    decimals: 18,
    name: "Wemix",
    symbol: "WEMIX",
  },
  rpcUrls: {
    public: { http: [getRpcUrl(WEMIX_TESTNET)] },
    default: { http: [getRpcUrl(WEMIX_TESTNET)] },
  },
  blockExplorers: {
    default: { name: "Wemix Explorer", url: getExplorerUrl(WEMIX_TESTNET) },
    etherscan: { name: "Wemix Explorer", url: getExplorerUrl(WEMIX_TESTNET) },
  },
  contracts: {
    // multicall3: {
    //   address: '0xca11bde05977b3631167028862be2a173976ca11',
    //   blockCreated: 11_907_934,
    // },
  },
  testnet: true,
};

// * for hardhat
export const hardhatChainInfo: Chain = {
  id: HARDHAT,
  name: "Hardhat",
  network: "Hardhat",
  iconUrl:
    "https://assets.coingecko.com/coins/images/279/standard/ethereum.png",
  iconBackground: "#fff",
  nativeCurrency: {
    decimals: 18,
    name: "Ethereum",
    symbol: "ETH",
  },
  rpcUrls: {
    public: { http: [getRpcUrl(HARDHAT)] },
    default: { http: [getRpcUrl(HARDHAT)] },
  },
  blockExplorers: {
    default: { name: "", url: getExplorerUrl(HARDHAT) },
    etherscan: { name: "", url: getExplorerUrl(HARDHAT) },
  },
  contracts: {
    // multicall3: {
    //   address: '0xca11bde05977b3631167028862be2a173976ca11',
    //   blockCreated: 11_907_934,
    // },
  },
  testnet: false,
};

export const zkSyncSepoliaTestnet: Chain = {
  id: ZKSYNC_SEPOLIA,
  name: "zkSync Sepolia Testnet",
  network: "zksync-sepolia-testnet",
  iconUrl:
    "https://assets.coingecko.com/coins/images/279/standard/ethereum.png",
  iconBackground: "#fff",
  nativeCurrency: { name: "Ether", symbol: "ETH", decimals: 18 },
  rpcUrls: {
    public: { http: [getRpcUrl(ZKSYNC_SEPOLIA)] },
    default: { http: [getRpcUrl(ZKSYNC_SEPOLIA)] },
  },
  blockExplorers: {
    default: { name: "zkExplorer", url: getExplorerUrl(ZKSYNC_SEPOLIA) },
    etherscan: { name: "zkExplorer", url: getExplorerUrl(ZKSYNC_SEPOLIA) },
  },
  contracts: {
    // multicall3: {
    //   address: '0xca11bde05977b3631167028862be2a173976ca11',
    //   blockCreated: 11_907_934,
    // },
  },
  testnet: true,
};
