import { documentVisibleAtom } from "atoms";
import { useSetAtom } from "jotai";
import { useEffect } from "react";

export const useDocumentVisible = (documentElement = document) => {
  const setDocumentVisible = useSetAtom(documentVisibleAtom);

  useEffect(() => {
    setDocumentVisible(documentElement.visibilityState === "visible");

    const handleVisibilityChange = () =>
      setDocumentVisible(documentElement.visibilityState === "visible");

    documentElement.addEventListener(
      "visibilitychange",
      handleVisibilityChange
    );

    return () =>
      documentElement.removeEventListener(
        "visibilitychange",
        handleVisibilityChange
      );
  }, [documentElement, setDocumentVisible]);
};
