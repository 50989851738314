import { useEffect } from "react";

const SentryTest = () => {
  useEffect(() => {
    setTimeout(() => {
      throw Error("sentry test");
    }, 1000);
  }, []);

  return <div>Sentry Test</div>;
};

export default SentryTest;
