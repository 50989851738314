import cx from "classnames";
import { useMemo, useRef } from "react";

import { SWAP } from "futures-lib/legacy";

import { formatUnits } from "@ethersproject/units";
import { i18n } from "@lingui/core";
import { Trans } from "@lingui/macro";
import { I18nProvider } from "@lingui/react";
import {
  pairsPricesAtom,
  selectedPairAtom,
  selectedPairPriceChangeAtom,
} from "atoms/exchange";
import BN from "bignumber.js";
import { AnimatedNumber } from "components/AnimatedNumber";
import { TVAdvancedChartContainer } from "components/TVAdvancedChartContainer/TVAdvancedChartContainer";
import { getConstant } from "config/chains";
import { getContract } from "config/contracts";
import { getToken, getTokens } from "config/tokens";
import { BigNumber } from "ethers";
import { trimPriceString } from "futures-domain/trades/utils";
import { useChainId } from "futures-lib/chains";
import { formatAmount, numberWithCommas } from "futures-lib/numbers";
import { useAtomValue } from "jotai";
import ReactDOMServer from "react-dom/server";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import ChartTokenSelectorV3 from "./ChartTokenSelectorV3";
import { GAMBIT_USD_DECIMALS } from "./constants";

export function getChartToken(swapOption, fromToken, toToken, chainId) {
  if (!fromToken || !toToken) {
    return;
  }

  if (swapOption !== SWAP) {
    return toToken;
  }

  if (fromToken.isUsdg && toToken.isUsdg) {
    return getTokens(chainId).find((t) => t.isStable);
  }
  if (fromToken.isUsdg) {
    return toToken;
  }
  if (toToken.isUsdg) {
    return fromToken;
  }

  if (fromToken.isStable && toToken.isStable) {
    return toToken;
  }
  if (fromToken.isStable) {
    return toToken;
  }
  if (toToken.isStable) {
    return fromToken;
  }

  return toToken;
}

export default function ExchangeTVChartV2() {
  const { chainId } = useChainId();

  const ref = useRef(null);

  const selectedPair = useAtomValue(selectedPairAtom);
  const pairsPrices = useAtomValue(pairsPricesAtom);

  const fromTokenAddress = getContract(chainId, "CollateralToken");
  const fromToken = getToken(chainId, fromTokenAddress);

  const selectedPairPrice = useMemo(() => {
    if (!selectedPair || !pairsPrices[selectedPair.priceFeedId!]) return;

    const { price, expo } = pairsPrices[selectedPair.priceFeedId!];
    return formatUnits(BigNumber.from(price), Math.abs(expo));
  }, [pairsPrices, selectedPair]);

  const oiLong = useMemo(() => {
    if (
      !selectedPair ||
      !selectedPair.openInterest ||
      !selectedPair.openInterest.long ||
      !selectedPair.openInterest.max
    ) {
      return "-";
    }
    const numerator = new Intl.NumberFormat("en", {
      notation: "compact",
      compactDisplay: "short",
    }).format(
      +formatAmount(selectedPair.openInterest.long, fromToken.decimals)
    );
    const denominator = new Intl.NumberFormat("en", {
      notation: "compact",
      compactDisplay: "short",
    }).format(+formatAmount(selectedPair.openInterest.max, fromToken.decimals));
    return `${numerator}/${denominator}`;
  }, [fromToken.decimals, selectedPair]);

  const oiShort = useMemo(() => {
    if (
      !selectedPair ||
      !selectedPair.openInterest ||
      !selectedPair.openInterest.short ||
      !selectedPair.openInterest.max
    ) {
      return "-";
    }
    const numerator = new Intl.NumberFormat("en", {
      notation: "compact",
      compactDisplay: "short",
    }).format(
      +formatAmount(selectedPair.openInterest.short, fromToken.decimals)
    );
    const denominator = new Intl.NumberFormat("en", {
      notation: "compact",
      compactDisplay: "short",
    }).format(+formatAmount(selectedPair.openInterest.max, fromToken.decimals));
    return `${numerator}/${denominator}`;
  }, [fromToken.decimals, selectedPair]);

  const fundingLong = useMemo(() => {
    const numOfL1BlocksIn1Hour = getConstant(chainId, "numOfL1BlocksIn1Hour");

    if (
      !selectedPair?.openInterest?.long ||
      !selectedPair?.openInterest?.short ||
      !selectedPair?.param?.fundingFeePerBlockP ||
      !numOfL1BlocksIn1Hour
    ) {
      return "-";
    }
    const {
      openInterest: { long: oiLong, short: oiShort },
      param: { fundingFeePerBlockP },
    } = selectedPair;

    if (!BigNumber.isBigNumber(oiLong)) return "-";
    if (oiLong.isZero()) return "0%";

    // const fundingFeePerBlockP = 1000000;

    const fundingFeePerBlock = new BN(oiLong.toString())
      .minus(oiShort.toString())
      .multipliedBy(formatUnits(fundingFeePerBlockP, GAMBIT_USD_DECIMALS))
      .dividedBy(oiLong.toString());

    if (fundingFeePerBlock.toString() === "NaN") {
      return "-";
    }

    const result = fundingFeePerBlock.multipliedBy(numOfL1BlocksIn1Hour);

    return `${result.toFixed(4)}%`;
  }, [chainId, selectedPair]);

  const fundingShort = useMemo(() => {
    const numOfL1BlocksIn1Hour = getConstant(chainId, "numOfL1BlocksIn1Hour");

    if (
      !selectedPair?.openInterest?.long ||
      !selectedPair?.openInterest?.short ||
      !selectedPair?.param?.fundingFeePerBlockP ||
      !numOfL1BlocksIn1Hour
    ) {
      return "-";
    }
    const {
      openInterest: { long: oiLong, short: oiShort },
      param: { fundingFeePerBlockP },
    } = selectedPair;

    if (!BigNumber.isBigNumber(oiShort)) return "-";
    if (oiShort.isZero()) return "0%";
    // const fundingFeePerBlockP = 1000000;

    const fundingFeePerBlock = new BN(formatUnits(oiLong, fromToken.decimals))
      .minus(formatUnits(oiShort, fromToken.decimals))
      .multipliedBy(-1)
      .multipliedBy(formatUnits(fundingFeePerBlockP, GAMBIT_USD_DECIMALS))
      .dividedBy(formatUnits(oiShort, fromToken.decimals));

    if (fundingFeePerBlock.toString() === "NaN") {
      return "-";
    }

    const result = fundingFeePerBlock.multipliedBy(numOfL1BlocksIn1Hour);

    return `${result.toFixed(4)}%`;
  }, [chainId, fromToken.decimals, selectedPair]);

  // const rolloverFee = useMemo(() => {
  //   const numOfL1BlocksIn1Hour = getConstant(chainId, "numOfL1BlocksIn1Hour");

  //   if (!selectedPair || !selectedPair.param?.rolloverFeePerBlockP) {
  //     return "-";
  //   }

  //   return `${new BN(
  //     formatUnits(selectedPair.param?.rolloverFeePerBlockP, GAMBIT_USD_DECIMALS)
  //   )
  //     .multipliedBy(numOfL1BlocksIn1Hour)
  //     .toFixed(4)}%`;
  // }, [chainId, selectedPair]);

  // const [oneDayAgoTimestamp, setOneDayAgoTimestamp] = useState("");

  // useEffect(() => {
  //   const value = new Date().getTime() - 24 * 60 * 60 * 1000;
  //   const result = new BN(value).dividedBy(1000).toFixed(0);
  //   setOneDayAgoTimestamp(result);
  // }, [selectedPairPrice]);

  // console.log(oneDayAgoTimestamp, new BN(oneDayAgoTimestamp).dividedBy(1000).toFixed(0));

  // const publicPriceEndpoint = useMemo(() => {
  //   return getConstant(chainId, "priceServerEndPoint");
  // }, [chainId]);

  // const [storedOneDayAgoPricesData, setStoredOneDayAgoPricesData] = useState<any>();

  // const isPythMainnet = useMemo(() => {
  //   return publicPriceEndpoint === "https://hermes.pyth.network/api";
  // }, [publicPriceEndpoint]);

  // * 현재 시간 기준 24시간 전 가격(UTC 00:00:00 가격 아님)
  // const {
  //   data: oneDayAgoPricesData,
  //   // isLoading: oneDayAgoPricesDataIsLoading,
  //   // error: oneDayAgoPricesDataError,
  // } = useSWR(
  //   () => {
  //     return selectedPair?.priceFeedId && oneDayAgoTimestamp && isPythMainnet
  //       ? `${publicPriceEndpoint}/get_price_feed?id=${selectedPair.priceFeedId}&publish_time=${oneDayAgoTimestamp}`
  //       : null;
  //   },
  //   {
  //     fetcher: swrFetcher,
  //     refreshInterval: 1000 * 10,
  //     shouldRetryOnError: true,
  //     errorRetryInterval: 3000,
  //   }
  // );

  // useEffect(() => {
  //   setStoredOneDayAgoPricesData(undefined);
  // }, [selectedPair?.pairIndex]);

  // useEffect(() => {
  //   if (!oneDayAgoPricesData) return;

  //   setStoredOneDayAgoPricesData(oneDayAgoPricesData);
  // }, [oneDayAgoPricesData]);

  // const oneDayAgoPrice = useMemo(() => {
  //   if (!storedOneDayAgoPricesData) return;

  //   const { price, expo } = storedOneDayAgoPricesData.price;
  //   return formatUnits(BigNumber.from(price), Math.abs(expo));
  // }, [storedOneDayAgoPricesData]);

  // const oneDayPriceChange = useMemo(() => {
  //   if (!oneDayAgoPrice || !selectedPairPrice) return;

  //   const result = new BN(selectedPairPrice)
  //     .minus(oneDayAgoPrice)
  //     .dividedBy(oneDayAgoPrice)
  //     .multipliedBy(100)
  //     .toFixed(2);

  //   if (new BN(result).eq(0)) return "0.00";

  //   return result;
  // }, [oneDayAgoPrice, selectedPairPrice]);
  const selectedPairPriceChange = useAtomValue(selectedPairPriceChangeAtom);

  const isPlus = useMemo(() => {
    if (!selectedPairPriceChange) return "";

    if (new BN(selectedPairPriceChange).gt(0)) return "+";

    return "";
  }, [selectedPairPriceChange]);

  return (
    <div className="ExchangeChart tv" ref={ref}>
      <div className="absolute z-[2] w-full flex">
        <div className="flex shrink-0 pl-[1.6rem] xs:px-0">
          {/* <ChartTokenSelectorV2 /> */}
          <ChartTokenSelectorV3 />
        </div>
        <div className="w-px border-l border-white border-opacity-10 my-[0.8rem] mx-[2.4rem]" />

        <div className="flex gap-[3.2rem] overflow-x-auto lg-:overflow-x-visible items-center pr-[1rem]">
          <div className="shrink-0 basis-[8.2rem]">
            <div className="text-[1.1rem] text-gray-30 font-medium">
              <Trans id="msg.header / Price">Price</Trans>
            </div>
            {selectedPairPrice !== undefined && selectedPair && (
              <div
                className={cx(
                  "font-space-grotesk text-[1.4rem] text-white font-medium"
                  // { "!text-green-2": isIncreasedFromPrevPrice === true },
                  // { "!text-red-2": isIncreasedFromPrevPrice === false }
                )}
              >
                <div className="flex">
                  <span>
                    {["CNH", "JPY"].includes(selectedPair.to) ? "¥" : "$"}
                  </span>
                  {numberWithCommas(trimPriceString(selectedPairPrice))}
                  {/* <AnimatedNumber to={+selectedPairPrice} /> */}
                </div>
              </div>
            )}
          </div>

          {/* {oneDayPriceChange && (
            <div className="shrink-0">
              <div className="text-[1.1rem] text-gray-30 font-medium">
                <Trans id="msg.header / PriceChange">24h Change</Trans>
              </div>
              <div
                className={cx(
                  "font-space-grotesk text-[1.4rem] text-white font-medium",
                  { "!text-green-2": new BN(oneDayPriceChange).gt(0) },
                  { "!text-red-2": new BN(oneDayPriceChange).lt(0) }
                )}
              >{`${isPlus}${oneDayPriceChange}%`}</div>
            </div>
          )} */}

          {selectedPairPriceChange && (
            <div className="shrink-0 basis-[5.75rem]">
              <div className="text-[1.1rem] text-gray-30 font-medium">
                <Trans id="msg.header / PriceChange">Price Change</Trans>
              </div>

              <div
                className={cx(
                  "font-space-grotesk text-[1.4rem] text-white font-medium",
                  { "!text-green-2": new BN(selectedPairPriceChange).gt(0) },
                  { "!text-red-2": new BN(selectedPairPriceChange).lt(0) }
                )}
              >
                <div className="flex items-center">
                  <span>{isPlus}</span>
                  <AnimatedNumber
                    to={+selectedPairPriceChange}
                    trim={false}
                    decimals={2}
                  />
                  <span>%</span>
                </div>
              </div>
            </div>
          )}

          <div
            className="shrink-0"
            data-tooltip-id="tooltip-open-interest(l)"
            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
              <div className="flex flex-col">
                <span className="text-[1.3rem] font-bold">
                  <I18nProvider i18n={i18n}>
                    <Trans>Open Interest (L)</Trans>
                  </I18nProvider>
                </span>
                <span className="text-[1.3rem]">
                  <I18nProvider i18n={i18n}>
                    <Trans>
                      Sum of open {selectedPair?.from}/{selectedPair?.to} long
                      positions.
                    </Trans>
                  </I18nProvider>
                </span>
              </div>
            )}
          >
            <div className="text-[1.1rem] text-gray-30 font-medium underline">
              <Trans>Open Interest (L)</Trans>
            </div>
            <div
              className={cx(
                "font-space-grotesk text-[1.4rem] text-white font-medium"
              )}
            >
              {selectedPair &&
              oiLong !== undefined &&
              ["CNH", "JPY"].includes(selectedPair.to)
                ? "¥"
                : "$"}
              {oiLong}
            </div>
          </div>

          <div
            className="shrink-0"
            data-tooltip-id="tooltip-open-interest(s)"
            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
              <div className="flex flex-col">
                <span className="text-[1.3rem] font-bold">
                  <I18nProvider i18n={i18n}>
                    <Trans>Open Interest (S)</Trans>
                  </I18nProvider>
                </span>
                <span className="text-[1.3rem]">
                  <I18nProvider i18n={i18n}>
                    <Trans>
                      Sum of open {selectedPair?.from}/{selectedPair?.to} short
                      positions.
                    </Trans>
                  </I18nProvider>
                </span>
              </div>
            )}
          >
            <div className="text-[1.1rem] text-gray-30 font-medium underline">
              <Trans>Open Interest (S)</Trans>
            </div>
            <div className="font-space-grotesk text-[1.4rem] text-white font-medium">
              {selectedPair &&
              oiShort !== undefined &&
              ["CNH", "JPY"].includes(selectedPair.to)
                ? "¥"
                : "$"}
              {oiShort}
            </div>
          </div>

          <div
            className="shrink-0"
            data-tooltip-id="tooltip-funding(l)"
            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
              <div className="flex flex-col">
                <span className="text-[1.3rem] font-bold">
                  <I18nProvider i18n={i18n}>
                    <Trans>Funding (L) - Hourly rate</Trans>
                  </I18nProvider>
                </span>
                <span className="text-[1.3rem]">
                  <I18nProvider i18n={i18n}>
                    <Trans>
                      Funding Fee (longs) hourly rate, charged per block on the
                      position size.
                    </Trans>
                  </I18nProvider>
                </span>
              </div>
            )}
          >
            <div className="text-[1.1rem] text-gray-30 font-medium underline">
              <Trans>Funding (L)</Trans>
            </div>
            <div className="font-space-grotesk text-[1.4rem] text-white font-medium">
              {fundingLong}
            </div>
          </div>

          <div
            className="shrink-0"
            data-tooltip-id="tooltip-funding(s)"
            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
              <div className="flex flex-col">
                <span className="text-[1.3rem] font-bold">
                  <I18nProvider i18n={i18n}>
                    <Trans>Funding (S) - Hourly rate</Trans>
                  </I18nProvider>
                </span>
                <span className="text-[1.3rem]">
                  <I18nProvider i18n={i18n}>
                    <Trans>
                      Funding Fee (shorts) hourly rate, charged per block on the
                      position size.
                    </Trans>
                  </I18nProvider>
                </span>
              </div>
            )}
          >
            <div className="text-[1.1rem] text-gray-30 font-medium underline">
              <Trans>Funding (S)</Trans>
            </div>
            <div className="font-space-grotesk text-[1.4rem] text-white font-medium">
              {fundingShort}
            </div>
          </div>

          {/* TODO: 모든 네트워크 적용 */}
          {/* {!TESTNETS.includes(chainId) && (
            <div
              className="shrink-0"
              data-tooltip-id="tooltip-rollover"
              data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                <div className="flex flex-col">
                  <span className="text-[1.3rem] font-bold">
                    <I18nProvider i18n={i18n}>
                      <Trans>Rollover - Hourly rate</Trans>
                    </I18nProvider>
                  </span>
                  <span className="text-[1.3rem]">
                    <I18nProvider i18n={i18n}>
                      <Trans>
                        Rollover Fee hourly rate, charged per block on the
                        collateral only.
                      </Trans>
                    </I18nProvider>
                  </span>
                </div>
              )}
            >
              <div className="text-[1.1rem] text-gray-30 font-medium underline">
                <Trans>Rollover</Trans>
              </div>
              <div className="font-space-grotesk text-[1.4rem] text-white font-medium">
                {rolloverFee}
              </div>
            </div>
          )} */}
        </div>

        <ReactTooltip
          className="!bg-gray-70 !bg-opacity-95 !font-space-grotesk !rounded-[6px] !text-white !border !border-white !border-opacity-10"
          id="tooltip-open-interest(l)"
          noArrow={true}
          place="bottom"
        />
        <ReactTooltip
          className="!bg-gray-70 !bg-opacity-95 !font-space-grotesk !rounded-[6px] !text-white !border !border-white !border-opacity-10"
          id="tooltip-open-interest(s)"
          noArrow={true}
          place="bottom"
        />
        <ReactTooltip
          className="!bg-gray-70 !bg-opacity-95 !font-space-grotesk !rounded-[6px] !text-white !border !border-white !border-opacity-10 !max-w-[30rem]"
          id="tooltip-funding(l)"
          noArrow={true}
          place="bottom"
        />
        <ReactTooltip
          className="!bg-gray-70 !bg-opacity-95 !font-space-grotesk !rounded-[6px] !text-white !border !border-white !border-opacity-10 !max-w-[30rem]"
          id="tooltip-funding(s)"
          noArrow={true}
          place="bottom"
        />
        {/* <ReactTooltip
          className="!bg-gray-70 !bg-opacity-95 !font-space-grotesk !rounded-[6px] !text-white !border !border-white !border-opacity-10 !max-w-[30rem]"
          id="tooltip-rollover"
          noArrow={true}
          place="bottom"
        /> */}
      </div>
      <div className="ExchangeChart-bottom App-box App-box-border !bg-tvDark !h-auto !top-[5.3rem] lg:!top-[6.975rem]">
        {/* <p className="ExchangeChart-error">Sorry, chart is not supported on this network yet.</p> */}
        {/* <TradingViewWidget /> */}
        {/* <TradingViewWidgetV2 /> */}
        <TVAdvancedChartContainer />
      </div>
    </div>
  );
}
