import { Menu } from "@headlessui/react";
import cx from "classnames";
import verticalDotsImage from "img/ic-vertica_dots.svg";
// import { currentLanguageAtom } from "atoms";
// import { useAtomValue } from "jotai";

const LinksDropdown = () => {
  // const currentLanguage = useAtomValue(currentLanguageAtom);

  return (
    <Menu>
      <Menu.Button as="div">
        <button className="flex items-center justify-center h-[3.6rem] lg:w-[3.6rem] lg:bg-black-3 lg:hover:bg-gray-70 lg:border lg:border-white lg:border-opacity-10 lg:rounded-full">
          <img className="w-[1.8rem] h-[1.8rem]" src={verticalDotsImage} alt="vertical dots for links" />
        </button>
      </Menu.Button>

      <div className="relative">
        <Menu.Items
          className={cx(
            "absolute z-[1000] left-[-10rem] top-[0.6rem] space-y-[2px] bg-gray-70",
            "min-w-[13rem]",
            "bg-opacity-95 px-[.5rem] py-[.7rem] border border-white border-opacity-10 rounded-[6px]",
            "text-white text-[1.3rem] font-medium cursor-pointer"
          )}
          as="div"
        >
          <Menu.Item>
            <a
              className="!block px-[.5rem] py-[.5rem] hover:bg-white hover:bg-opacity-10 hover:rounded-[4px]"
              // href={currentLanguage === "ko" ? "https://docs.gambit.trade/v/kr" : "https://docs.gambit.trade"}
              href="https://docs.gambit.trade"
              target="_blank"
              rel="ugc"
            >
              About
            </a>
          </Menu.Item>
          {/* <Menu.Item>
            <a
              className="!block px-[.5rem] py-[.5rem] hover:bg-white hover:bg-opacity-10 hover:rounded-[4px]"
              href="https://blog.cng.vc"
              target="_blank"
              rel="ugc"
            >
              Medium
            </a>
          </Menu.Item> */}
          <Menu.Item>
            <a
              className="!block px-[.5rem] py-[.5rem] hover:bg-white hover:bg-opacity-10 hover:rounded-[4px]"
              href="https://twitter.com/Gambit_Trade"
              target="_blank"
              rel="ugc"
            >
              Twitter
            </a>
          </Menu.Item>
          <Menu.Item>
            <a
              className="!block px-[.5rem] py-[.5rem] hover:bg-white hover:bg-opacity-10 hover:rounded-[4px]"
              href="https://t.me/Gambit_Trade"
              target="_blank"
              rel="ugc"
            >
              Telegram
            </a>
          </Menu.Item>
          <Menu.Item>
            <a
              className="!block px-[.5rem] py-[.5rem] hover:bg-white hover:bg-opacity-10 hover:rounded-[4px]"
              href="https://discord.gg/HB8GSJbyut"
              target="_blank"
              rel="ugc"
            >
              Discord
            </a>
          </Menu.Item>
        </Menu.Items>
      </div>
    </Menu>
  );
};

export default LinksDropdown;
