import { t } from "@lingui/macro";
import * as Dialog from "@radix-ui/react-dialog";
import * as Popover from "@radix-ui/react-popover";
import { currentTimeAtom } from "atoms";
import { pairsAtom, pairsPricesAtom, selectedPairAtom } from "atoms/exchange";
import cx from "classnames";
import "components/Common/radix-ui-dialog.scss";
import { FAVORITE_PAIRS_KEY } from "config/localStorage";
import { Pair } from "futures-domain/trades/types";
import { importTokenImage } from "futures-lib/legacy";
import { ReactComponent as CaretIcon } from "img/ic-caret.svg";
import EmptyStar from "img/ic-star-empty.svg";
import HoveredStar from "img/ic-star-hover.svg";
import FilledStar from "img/ic-start-fill.svg";
import searchIcon from "img/search.svg";
import { useAtom, useAtomValue } from "jotai";
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHoverDirty, useLocalStorage, useMedia } from "react-use";
import { FOREX_CLOSED_DECISION_TIME } from "./constants";

export const WHITE_BACKGROUND_PAIRS = ["SOL/USD", "MATIC/USD", "LINK/USD", "NEAR/USD", "XRP/USD"];

const sortFavoritePairs = (favoritePairs: Pair[], favoriteOrders: string[]) => {
  try {
    return [...favoritePairs].sort((a, b) => {
      const aPairIndex = favoriteOrders.indexOf(`${a.from}/${a.to}`);
      const bPairIndex = favoriteOrders.indexOf(`${b.from}/${b.to}`);

      return aPairIndex - bPairIndex;
    });
  } catch (e) {
    return favoritePairs;
  }
};

const SELECTOR_CATEGORY = {
  ALL: "ALL",
  CRYPTO: "Crypto",
  FOREX: "Forex",
} as const;

type TSelectorCategory = typeof SELECTOR_CATEGORY[keyof typeof SELECTOR_CATEGORY];

const SELECTOR_CATEGORY_ARRAY = Object.values(SELECTOR_CATEGORY);

const ChartTokenSelectorV3 = () => {
  const [activeCategory, setActiveCategory] = useState<TSelectorCategory>(SELECTOR_CATEGORY.ALL);

  const _pairs = useAtomValue(pairsAtom);
  const [favoritePairs, setFavoritePairs] = useLocalStorage(FAVORITE_PAIRS_KEY, DEFAULT_FAVORITE_PAIRS);

  const pairs = useMemo(() => {
    if (!favoritePairs || favoritePairs.length === 0) return _pairs;

    const favorites = _pairs.filter((pair) => favoritePairs.includes(`${pair.from}/${pair.to}`));
    const notFavorites = _pairs.filter((pair) => !favoritePairs.includes(`${pair.from}/${pair.to}`));
    return [...sortFavoritePairs(favorites, favoritePairs), ...notFavorites];

    // return _pairs.toSorted((pairA, pairB) => {
    //   if (favoritePairs.includes(`${pairA.from}/${pairA.to}`) && !favoritePairs.includes(`${pairB.from}/${pairB.to}`)) {
    //     return -1;
    //   } else if (
    //     !favoritePairs.includes(`${pairA.from}/${pairA.to}`) &&
    //     favoritePairs.includes(`${pairB.from}/${pairB.to}`)
    //   ) {
    //     return 1;
    //   }
    //   return 0;
    // });
  }, [_pairs, favoritePairs]);

  const [selectedPair, setSelectedPair] = useAtom(selectedPairAtom);
  // const searchRef = useRef<HTMLInputElement>(null);
  const [searchInput, setSearchInput] = useState("");
  const pairsPrices = useAtomValue(pairsPricesAtom);
  const [open, setOpen] = useState(false);

  const searchedPairs = useMemo(() => {
    if (!pairs) return [];

    let _pairs = [...pairs];

    if (activeCategory === SELECTOR_CATEGORY.CRYPTO) {
      _pairs = _pairs.filter((pair) => pair.groupIndex === 0);
    } else if (activeCategory === SELECTOR_CATEGORY.FOREX) {
      _pairs = _pairs.filter((pair) => pair.groupIndex === 1);
    }

    if (_pairs && !searchInput) return _pairs;

    _pairs = _pairs.filter(
      (_pair) => _pair.from.includes(searchInput.toUpperCase()) || _pair.to.includes(searchInput.toUpperCase())
    );

    return _pairs;
  }, [activeCategory, pairs, searchInput]);

  const selectedPairToIsNotUsd = useMemo(() => {
    return selectedPair?.to !== "USD";
  }, [selectedPair?.to]);

  const currentTime = useAtomValue(currentTimeAtom);

  const checkForexClosed = useCallback(
    (pair: Pair) => {
      const pairPriceInfo = pairsPrices[pair.priceFeedId];
      if (pair.groupIndex !== 1 || !pairPriceInfo?.publish_time) return false;

      const _currentTime = currentTime?.unix();
      const publish_time = pairPriceInfo?.publish_time;

      if (!currentTime || !publish_time) return false;

      if (_currentTime && Math.abs(_currentTime - publish_time) > FOREX_CLOSED_DECISION_TIME) {
        return true;
      }

      return false;
    },
    [currentTime, pairsPrices]
  );

  useEffect(() => {
    if (!open) {
      setSearchInput("");
    }
  }, [open]);

  const xxsPlus = useMedia("(max-width: 398px)");

  return (
    <>
      {/* Desktop */}
      {!xxsPlus && (
        <DesktopSelector
          open={open}
          setOpen={setOpen}
          selectedPair={selectedPair}
          selectedPairToIsNotUsd={selectedPairToIsNotUsd}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          searchedPairs={searchedPairs}
          checkForexClosed={checkForexClosed}
          setSelectedPair={setSelectedPair}
          favoritePairs={favoritePairs}
          setFavoritePairs={setFavoritePairs}
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
        />
      )}

      {/* * Mobile */}
      {xxsPlus && (
        <MobileSelector
          open={open}
          setOpen={setOpen}
          selectedPair={selectedPair}
          selectedPairToIsNotUsd={selectedPairToIsNotUsd}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          searchedPairs={searchedPairs}
          checkForexClosed={checkForexClosed}
          setSelectedPair={setSelectedPair}
          favoritePairs={favoritePairs}
          setFavoritePairs={setFavoritePairs}
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
        />
      )}
    </>
  );
};

export default ChartTokenSelectorV3;

interface ITokenSelector {
  open: boolean;
  setOpen: (oepn: boolean) => void;
  selectedPair: Pair | undefined;
  selectedPairToIsNotUsd: boolean;
  searchInput: string;
  setSearchInput: (input: string) => void;
  searchedPairs: Pair[];
  checkForexClosed: (pair: Pair) => boolean;
  setSelectedPair: (pair: Pair) => void;
  favoritePairs: string[] | undefined;
  setFavoritePairs: Dispatch<SetStateAction<string[] | undefined>>;
  activeCategory: TSelectorCategory;
  setActiveCategory: Dispatch<SetStateAction<TSelectorCategory>>;
}

interface IActiveCategoryState {
  activeCategory: TSelectorCategory;
  setActiveCategory: Dispatch<SetStateAction<TSelectorCategory>>;
}

const DesktopSelector = ({
  open,
  setOpen,
  selectedPair,
  selectedPairToIsNotUsd,
  searchInput,
  setSearchInput,
  searchedPairs,
  checkForexClosed,
  setSelectedPair,
  favoritePairs,
  setFavoritePairs,
  activeCategory,
  setActiveCategory,
}: ITokenSelector) => {
  return (
    <div>
      <Popover.Root modal={false} open={open} onOpenChange={setOpen}>
        <Popover.Trigger asChild>
          <button
            className={cx(
              "group min-w-[14.8rem] min-h-[4.6rem]",
              "bg-black-3 flex items-center border border-white border-opacity-5 rounded-[6px]",
              "py-[0.8rem] px-[1.2rem]"
            )}
          >
            {selectedPair &&
              selectedPair?.groupIndex === 0 &&
              !selectedPairToIsNotUsd &&
              WHITE_BACKGROUND_PAIRS.includes(
                `${selectedPair?.from.toUpperCase()}/${selectedPair?.to.toUpperCase()}`
              ) && (
                <div className="flex items-center justify-center min-w-[2.8rem] max-w-[2.8rem] h-[2.8rem] bg-white rounded-full">
                  <img
                    className="min-w-[2rem] max-w-[2rem] h-[2rem]"
                    src={importTokenImage(selectedPair?.from.toLowerCase(), 24)}
                    alt="selected token"
                  />
                </div>
              )}
            {selectedPair &&
              selectedPair?.groupIndex === 0 &&
              !selectedPairToIsNotUsd &&
              !WHITE_BACKGROUND_PAIRS.includes(
                `${selectedPair?.from.toUpperCase()}/${selectedPair?.to.toUpperCase()}`
              ) && (
                <img
                  className="min-w-[2.8rem] max-w-[2.8rem] h-[2.8rem] bg-white rounded-full"
                  src={importTokenImage(selectedPair?.from.toLowerCase(), 24)}
                  alt="selected token"
                />
              )}
            {((selectedPair && selectedPair?.groupIndex === 1) || selectedPairToIsNotUsd) && (
              <div className="flex mr-[9px]">
                {selectedPair && (
                  <img
                    className="min-w-[2.8rem] h-[2.8rem] bg-white rounded-full"
                    src={importTokenImage(selectedPair?.from.toLocaleLowerCase(), 24)}
                    alt="selected token"
                  />
                )}
                {selectedPair && (
                  <img
                    className="min-w-[2.5rem] h-[2.5rem] bg-white rounded-full ml-[-12px] border border-black-1"
                    src={importTokenImage(selectedPair?.to.toLocaleLowerCase(), 24)}
                    alt="selected token"
                  />
                )}
              </div>
            )}
            <span className="font-space-grotesk heading4 ml-[6px] mr-[4px] md:ml-[8px] md:mr-[6px]">
              {selectedPair && `${selectedPair?.from}/${selectedPair?.to}`}
            </span>
            {/* <img className={cx("!fill-gray-40")} src={caretIcon} alt="selected token" /> */}
            <CaretIcon className={cx("fill-gray-40 group-hover:fill-gray-50")} />
          </button>
        </Popover.Trigger>

        <Popover.Portal>
          <Popover.Content asChild className={cx("min-w-[30rem] w-full max-w-[39.8rem] max-h-[39.8rem] bg-gray-70")}>
            <div
              className={cx(
                "z-[10000] absolute left-[-7.6rem] top-[1.5rem] min-w-[30rem] bg-gray-80 bg-opacity-95 border rounded-[1.2rem] pb-[1.6rem] flex flex-col"
              )}
            >
              <div className="flex justify-between items-center bg-white bg-opacity-5 rounded-[6px] mt-[1.6rem] mx-[2rem] xxs+:mx-[1.6rem] py-[1.6rem] xxs+:py-[.9rem] pr-[1.6rem] text-[1.4rem]">
                <input
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  placeholder={t`Search Pair`}
                  className="text-[1.6rem] scale-[0.875] ml-[0.45rem]"
                />
                <img className="w-[1.8rem] h-[1.8rem]" src={searchIcon} alt="search icon" />
              </div>

              <SelectorCategory activeCategory={activeCategory} setActiveCategory={setActiveCategory} />

              <div className={cx("flex flex-col", "overflow-y-auto h-[30rem]")}>
                {searchedPairs.map((pair, index) => (
                  <PairInSelector
                    pair={pair}
                    key={index}
                    searchedPairs={searchedPairs}
                    checkForexClosed={checkForexClosed}
                    setSelectedPair={setSelectedPair}
                    setOpen={setOpen}
                    favoritePairs={favoritePairs}
                    setFavoritePairs={setFavoritePairs}
                  />
                ))}
                {searchedPairs.length === 0 && (
                  <div className="flex justify-center items-center flex-1 pb-[3rem] heading5 text-gray-30">
                    Nothing Found
                  </div>
                )}
              </div>
            </div>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  );
};

const MobileSelector = ({
  open,
  setOpen,
  selectedPair,
  selectedPairToIsNotUsd,
  searchInput,
  setSearchInput,
  searchedPairs,
  checkForexClosed,
  setSelectedPair,
  favoritePairs,
  setFavoritePairs,
  activeCategory,
  setActiveCategory,
}: ITokenSelector) => {
  return (
    <div>
      <Dialog.Root open={open} onOpenChange={setOpen}>
        <Dialog.Trigger asChild>
          <button
            className={cx(
              "group min-w-[14.8rem] min-h-[4.6rem]",
              "bg-black-3 flex items-center border border-white border-opacity-5 rounded-[6px]",
              "py-[0.8rem] px-[1.2rem]"
            )}
          >
            {selectedPair &&
              selectedPair?.groupIndex === 0 &&
              !selectedPairToIsNotUsd &&
              WHITE_BACKGROUND_PAIRS.includes(
                `${selectedPair?.from.toUpperCase()}/${selectedPair?.to.toUpperCase()}`
              ) && (
                <div className="flex items-center justify-center min-w-[2.8rem] max-w-[2.8rem] h-[2.8rem] bg-white rounded-full">
                  <img
                    className="min-w-[2rem] max-w-[2rem] h-[2rem]"
                    src={importTokenImage(selectedPair?.from.toLowerCase(), 24)}
                    alt="selected token"
                  />
                </div>
              )}
            {selectedPair &&
              selectedPair?.groupIndex === 0 &&
              !selectedPairToIsNotUsd &&
              !WHITE_BACKGROUND_PAIRS.includes(
                `${selectedPair?.from.toUpperCase()}/${selectedPair?.to.toUpperCase()}`
              ) && (
                <img
                  className="min-w-[2.8rem] max-w-[2.8rem] h-[2.8rem] bg-white rounded-full"
                  src={importTokenImage(selectedPair?.from.toLowerCase(), 24)}
                  alt="selected token"
                />
              )}
            {((selectedPair && selectedPair?.groupIndex === 1) || selectedPairToIsNotUsd) && (
              <div className="flex mr-[9px]">
                {selectedPair && (
                  <img
                    className="min-w-[2.8rem] h-[2.8rem] bg-white rounded-full"
                    src={importTokenImage(selectedPair?.from.toLocaleLowerCase(), 24)}
                    alt="selected token"
                  />
                )}
                {selectedPair && (
                  <img
                    className="min-w-[2.5rem] h-[2.5rem] bg-white rounded-full ml-[-12px] border border-black-1"
                    src={importTokenImage(selectedPair?.to.toLocaleLowerCase(), 24)}
                    alt="selected token"
                  />
                )}
              </div>
            )}
            <span className="font-space-grotesk heading4 ml-[6px] mr-[4px] md:ml-[8px] md:mr-[6px]">
              {selectedPair && `${selectedPair?.from}/${selectedPair?.to}`}
            </span>
            <CaretIcon className={cx("fill-gray-40 group-hover:fill-gray-50")} />
          </button>
        </Dialog.Trigger>

        <Dialog.Portal>
          <Dialog.Overlay className="DialogOverlay" />
          <div className="fixed w-full z-[10001]">
            <Dialog.Content
              asChild
              className={cx("min-w-[30rem] w-full max-w-[39.8rem] max-h-[39.8rem] bg-gray-70")}
              onOpenAutoFocus={(e) => e.preventDefault()}
            >
              <div
                className={cx(
                  "absolute z-[10001] left-0 bottom-0 min-w-[30rem] bg-gray-70 rounded-t-[1.2rem] pb-[1.6rem] flex flex-col"
                )}
              >
                <div className="flex justify-between items-center bg-gray-60 rounded-[6px] mt-[1.6rem] mx-[2rem] xxs+:mx-[1.6rem] py-[1.6rem] xxs+:py-[.9rem] pr-[1.6rem] text-[1.4rem]">
                  <input
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    placeholder={t`Search Pair`}
                    className="text-[1.6rem] scale-[0.875] ml-[0.45rem]"
                  />
                  <img className="w-[1.8rem] h-[1.8rem]" src={searchIcon} alt="search icon" />
                </div>

                <SelectorCategory activeCategory={activeCategory} setActiveCategory={setActiveCategory} />

                <div className={cx("flex flex-col", "overflow-y-auto h-[30rem]")}>
                  {searchedPairs.map((pair, index) => (
                    <PairInSelector
                      pair={pair}
                      key={index}
                      searchedPairs={searchedPairs}
                      checkForexClosed={checkForexClosed}
                      setSelectedPair={setSelectedPair}
                      setOpen={setOpen}
                      favoritePairs={favoritePairs}
                      setFavoritePairs={setFavoritePairs}
                      isMobile={true}
                    />
                  ))}
                  {searchedPairs.length === 0 && (
                    <div className="flex justify-center items-center flex-1 pb-[3rem] heading5 text-gray-30">
                      Nothing Found
                    </div>
                  )}
                </div>
              </div>
            </Dialog.Content>
          </div>
        </Dialog.Portal>
      </Dialog.Root>
    </div>
  );
};

interface IPairInSelector {
  pair: Pair;
  searchedPairs: Pair[];
  checkForexClosed: (pair: Pair) => boolean;
  setSelectedPair: (pair: Pair) => void;
  setOpen: (oepn: boolean) => void;
  favoritePairs: string[] | undefined;
  setFavoritePairs: Dispatch<SetStateAction<string[] | undefined>>;
  isMobile?: boolean;
}

const DEFAULT_FAVORITE_PAIRS: string[] = [];

const PairInSelector = ({
  pair,
  searchedPairs,
  checkForexClosed,
  setSelectedPair,
  setOpen,
  favoritePairs,
  setFavoritePairs,
  isMobile = false,
}: IPairInSelector) => {
  const ref = useRef(null);
  const isHovering = useHoverDirty(ref);
  // const [favoritePairs, setFavoritePairs] = useState();
  // const [favoritePairs, setFavoritePairs] = useLocalStorage(FAVORITE_PAIRS_KEY, DEFAULT_FAVORITE_PAIRS);

  const isFavorite = useCallback(
    (pair: Pair) => {
      return favoritePairs?.includes(`${pair.from}/${pair.to}`);
    },
    [favoritePairs]
  );

  const handleStarClick = useCallback(
    (e, pair: Pair) => {
      e.stopPropagation();

      if (isFavorite(pair)) {
        if (!favoritePairs) return;
        setFavoritePairs(favoritePairs.filter((favoritePair) => favoritePair !== `${pair.from}/${pair.to}`));
      } else {
        if (favoritePairs) {
          setFavoritePairs([...favoritePairs, `${pair.from}/${pair.to}`]);
        } else {
          setFavoritePairs([`${pair.from}/${pair.to}`]);
        }
      }
    },
    [favoritePairs, isFavorite, setFavoritePairs]
  );

  const toIsNotUsd = pair.to !== "USD";
  const forexClosed = checkForexClosed(pair);

  return (
    <div>
      <div
        className={cx(
          "flex items-center justify-between w-full",
          "pr-[2rem] xxs+:pr-[1.6rem] heading5",
          "hover:bg-white hover:bg-opacity-5"
        )}
      >
        <button
          ref={ref}
          onClick={(e) => {
            handleStarClick(e, pair);
          }}
          className="pr-[1.6rem] py-[1.2rem] cursor-pointer pl-[2rem] xxs+:pl-[1.6rem]"
        >
          <img
            className={cx(!isMobile && isHovering ? "transform scale-125" : " transform scale-100")}
            src={isFavorite(pair) ? FilledStar : isHovering ? HoveredStar : EmptyStar}
            alt="favorite-star"
          />
        </button>
        <button
          onClick={() => {
            setSelectedPair(pair);
            setOpen(false);
          }}
          className="flex items-center w-full py-[1.2rem] cursor-pointer"
        >
          {pair.groupIndex === 0 &&
            !toIsNotUsd &&
            WHITE_BACKGROUND_PAIRS.includes(`${pair.from.toUpperCase()}/${pair.to.toUpperCase()}`) && (
              <div className="flex items-center justify-center w-[2rem] h-[2rem] mr-[9px] bg-white rounded-full">
                <img
                  className="w-[1.4rem] h-[1.4rem]"
                  src={importTokenImage(pair.from.toLocaleLowerCase(), 24)}
                  alt="selected token"
                />
              </div>
            )}

          {pair.groupIndex === 0 &&
            !toIsNotUsd &&
            !WHITE_BACKGROUND_PAIRS.includes(`${pair.from.toUpperCase()}/${pair.to.toUpperCase()}`) && (
              <img
                className="w-[2rem] h-[2rem] mr-[9px] bg-white rounded-full"
                src={importTokenImage(pair.from.toLocaleLowerCase(), 24)}
                alt="selected token"
              />
            )}
          {(pair.groupIndex === 1 || toIsNotUsd) && (
            <div className="flex mr-[9px]">
              <img
                className="w-[2rem] h-[2rem] bg-white rounded-full"
                src={importTokenImage(pair.from.toLocaleLowerCase(), 24)}
                alt="selected token"
              />
              <img
                className="w-[1.8rem] h-[1.8rem] bg-white rounded-full ml-[-8px] border border-black-1"
                src={importTokenImage(pair.to.toLocaleLowerCase(), 24)}
                alt="selected token"
              />
            </div>
          )}
          <span className="font-space-grotesk heading5">
            {pair.from}/{pair.to}
          </span>
        </button>
        {forexClosed && <span className="text-[1.25rem] text-red-2">CLOSED</span>}
      </div>
    </div>
  );
};

const SelectorCategory = ({ activeCategory, setActiveCategory }: IActiveCategoryState) => {
  return (
    <div className="flex space-x-[6px] px-[1.6rem] mt-[1.6rem] mb-[1.2rem] font-space-grotesk">
      {SELECTOR_CATEGORY_ARRAY.map((category) => (
        <div
          key={category}
          onClick={() => {
            setActiveCategory(category);
          }}
          className={cx(
            "px-[0.8rem] h-[2.3rem] flex items-center border border-gray-60 rounded-[2px] cursor-pointer heading9 text-gray-15",
            { "text-white bg-white bg-opacity-10": category === activeCategory }
          )}
        >
          {category}
        </div>
      ))}
    </div>
  );
};
