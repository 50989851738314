import { Trans } from "@lingui/macro";
import { currentLanguageAtom } from "atoms";
import { useAtomValue } from "jotai";
import cx from "classnames";

const ZeroFeeTooltipForHeader = () => {
  const currentLanguage = useAtomValue(currentLanguageAtom);

  return (
    <div
      className={cx(
        "absolute mt-[-1.2rem] animate-small-bounce hidden headerScreen:block",
        currentLanguage === "en" ? "ml-[-3.55rem]" : "ml-[-3.35rem]"
      )}
    >
      <div className="w-full flex justify-center">
        <div
          className="w-0 h-0 
          border-l-[7px] border-l-transparent
          border-b-[10px] border-b-gray-70 border-opacity-95
          border-r-[7px] border-r-transparent"
        />
      </div>
      <div className="bg-gray-70 bg-opacity-95 rounded-[999px]">
        <div className="cursor-default text-[1.3rem] text-brand-1 leading-[12px] font-medium px-[1.2rem] py-[0.8rem]">
          <Trans>Gas Free Trading</Trans>
        </div>
      </div>
    </div>
  );
};

export default ZeroFeeTooltipForHeader;
