import { arrayify } from "@ethersproject/bytes";
import { formatUnits, parseUnits } from "@ethersproject/units";
import { t } from "@lingui/macro";
import BN from "bignumber.js";
import {
  ARBITRUM,
  ARBITRUM_GOERLI,
  ARBITRUM_SEPOLIA,
  HARDHAT,
  POLYGON,
  POLYGON_MUMBAI,
  WEMIX_TESTNET,
  ZKSYNC,
  ZKSYNC_GOERLI,
  ZKSYNC_SEPOLIA,
} from "config/chains";
import { randomBytes as _randomBytes } from "crypto-browserify";
import { BigNumber, constants, ethers } from "ethers";
import { hexValue, hexlify } from "ethers/lib/utils";
import { Token } from "futures-domain/tokens";
import {
  OpenLimitOrderType,
  Order,
  Pair,
  PairPrices,
  ParsedTrade,
  SubgraphPair,
  SubgraphTrade,
} from "futures-domain/trades/types";
import { getDelegationFeeThreshhold } from "futures-lib/contracts/contract";
import { formatAmount, formatStringAmount } from "futures-lib/numbers";
import {
  ADDED_DECIMALS,
  GAMBIT_USD_DECIMALS,
  PRICE_DECIMAL_STANDARD,
  confMultiplierDecimals,
} from "../../components/Exchange/constants";

// import priceFeedIds from "@changerio/futures-contracts/data/pyth-network/price-ids-mainnet.json";

/**
 * * if we want to close at 15% profit:
 * Open price + (0.01 * Open price * (Take Profit Percentage / Leverage As Percentage)) = TP Price
 * $46,420.35 + (0.01*46420.35*(15/10) = $47,116.65 , so 471166500000000
 
 * @param openPrice   formatting된 자산 openPrice
 * @param tpPercent   사용자가 선택한 Take Profit %
 * @param leverage    사용자가 선택한 leverage
 * @returns tpPrice   parseUnits(, GAMBIT_USD_DECIMALS).toFixed(0); 한 것과 동일한 결과이므로 사람이 읽을 때는 formatUnits(tpPrice, GAMBIT_USD_DECIMALS)로 변환해야함
 */
export const getTpPrice = (
  openPrice: string,
  tpPercent: string,
  leverage: number,
  buy: boolean
) => {
  // console.log(openPrice, tpPercent, leverage);

  if (buy) {
    return new BN(openPrice)
      .plus(
        new BN(0.01)
          .multipliedBy(openPrice)
          .multipliedBy(new BN(tpPercent).dividedBy(leverage))
      )
      .multipliedBy(new BN(10).pow(GAMBIT_USD_DECIMALS)) // parseUnits(, 10) 해서 decimals 반영하는 것과 동일
      .toFixed(0);
  } else {
    return new BN(openPrice)
      .minus(
        new BN(0.01)
          .multipliedBy(openPrice)
          .multipliedBy(new BN(tpPercent).dividedBy(leverage))
      )
      .multipliedBy(new BN(10).pow(GAMBIT_USD_DECIMALS)) // parseUnits(, 10) 해서 decimals 반영하는 것과 동일
      .toFixed(0);
  }
};

/**
 * if we want to close at 25% loss:
 * Open price - (0.01 * Open price * (Stop Loss Percentage / Leverage Percentage)) = SL Price
 * $46,420.35 - (0.01*46420.35*(25/10) = $45,259.84 , so 45259840000000

 * @param openPrice   formatting된 자산 openPrice
 * @param slPercent   사용자가 선택한 Stop Loss %
 * @param leverage    사용자가 선택한 leverage
 * @returns
 */
export const getSlPrice = (
  openPrice: string,
  slPercent: string,
  leverage: number,
  buy: boolean
) => {
  if (!slPercent) return "0";

  // console.log(openPrice, slPercent, leverage);

  if (buy) {
    // slPercent에 -가 포함되어 있어서, openPrice에 plus함
    return new BN(openPrice)
      .plus(
        new BN(0.01)
          .multipliedBy(openPrice)
          .multipliedBy(new BN(slPercent).dividedBy(leverage))
      )
      .multipliedBy(new BN(10).pow(GAMBIT_USD_DECIMALS)) // foramtUnits(, 10) 해서 decimals 반영하는 것과 동일
      .toFixed(0);
  } else {
    // slPercent에 -가 포함되어 있어서, openPrice에 minus함
    return new BN(openPrice)
      .minus(
        new BN(0.01)
          .multipliedBy(openPrice)
          .multipliedBy(new BN(slPercent).dividedBy(leverage))
      )
      .multipliedBy(new BN(10).pow(GAMBIT_USD_DECIMALS)) // foramtUnits(, 10) 해서 decimals 반영하는 것과 동일
      .toFixed(0);
  }
};

/**
 * Take Profit Percentage  = (TP Price - Open price) * 100 / open price * leverage
 *
 * @param openPrice
 * @param tpPrice
 * @param leverage
 */
export const getTpPercent = (
  openPrice: string,
  tpPrice: string,
  leverage: number,
  buy: boolean
) => {
  if (buy) {
    return new BN(tpPrice)
      .minus(openPrice)
      .multipliedBy(100)
      .dividedBy(openPrice)
      .multipliedBy(leverage);
  } else {
    return new BN(openPrice)
      .minus(tpPrice)
      .multipliedBy(100)
      .dividedBy(openPrice)
      .multipliedBy(leverage);
  }
};

/**
 * Stop Loss Percentage = (open price - sl price) * 100 / open price * leverage
 *
 * @param openPrice
 * @param slPrice
 * @param leverage
 */
export const getSlPercent = (
  openPrice: string,
  slPrice: string,
  leverage: number,
  buy: boolean
) => {
  if (buy) {
    return new BN(slPrice)
      .minus(openPrice)
      .multipliedBy(100)
      .dividedBy(openPrice)
      .multipliedBy(leverage);
  } else {
    return new BN(openPrice)
      .minus(slPrice)
      .multipliedBy(100)
      .dividedBy(openPrice)
      .multipliedBy(leverage);
  }
};

export const getLiqPrice = (
  openPrice: string,
  positionSizeUsdc: string,
  leverage: number,
  buy: boolean,
  rollOverFee: string = "0",
  fundingFee: string = "0"
) => {
  const liqPriceDistance = new BN(openPrice)
    .multipliedBy(
      new BN(positionSizeUsdc)
        .multipliedBy(0.9)
        .minus(rollOverFee)
        .minus(fundingFee)
    )
    .dividedBy(positionSizeUsdc)
    .dividedBy(leverage);

  if (buy) {
    const result = trimPriceString(
      new BN(openPrice).minus(liqPriceDistance).toFixed()
    );
    return isNaN(+result) ? undefined : result;
  } else {
    const result = trimPriceString(
      new BN(openPrice).plus(liqPriceDistance).toFixed()
    );
    return isNaN(+result) ? undefined : result;
  }
};

export function getWsProvider(active: boolean, chainId: number) {
  if (!active) {
    return;
  }

  if (chainId === POLYGON_MUMBAI) {
    // const wsProvider = new ethers.providers.WebSocketProvider(
    //   // "wss://polygon-mumbai.g.alchemy.com/v2/r5gDJcVI6on2Q7qWcMjcI4-SZp1jkBdt"
    //   "wss://polygon-mumbai.g.alchemy.com/v2/8GZCiG-1fomRBmWopEcZNodbvu7suHtO"
    // );

    const wsProvider = new ethers.providers.JsonRpcProvider(
      "https://rpc-mumbai.maticvigil.com/"
    );
    wsProvider.pollingInterval = 1000;

    return wsProvider;
  } else if (chainId === POLYGON) {
    const wsProvider = new ethers.providers.JsonRpcProvider(
      "https://polygon-rpc.com/"
    );
    wsProvider.pollingInterval = 1000;

    return wsProvider;
  } else if (chainId === ZKSYNC) {
    const wsProvider = new ethers.providers.WebSocketProvider(
      "wss://mainnet.era.zksync.io/ws"
    );

    return wsProvider;
  } else if (chainId === ZKSYNC_GOERLI) {
    // const wsProvider = new ethers.providers.WebSocketProvider("wss://testnet.era.zksync.dev/ws");

    const wsProvider = new ethers.providers.JsonRpcProvider(
      "https://testnet.era.zksync.dev"
    );
    wsProvider.pollingInterval = 1000;

    return wsProvider;
  } else if (chainId === ZKSYNC_SEPOLIA) {
    // const wsProvider = new ethers.providers.WebSocketProvider("wss://testnet.era.zksync.dev/ws");

    const wsProvider = new ethers.providers.JsonRpcProvider(
      "https://sepolia.era.zksync.dev"
    );
    wsProvider.pollingInterval = 1000;

    return wsProvider;
  } else if (chainId === HARDHAT) {
    // const wsProvider = new ethers.providers.WebSocketProvider("wss://testnet.era.zksync.dev/ws");

    const wsProvider = new ethers.providers.JsonRpcProvider(
      "http://127.0.0.1:8545"
    );
    wsProvider.pollingInterval = 1000;

    return wsProvider;
  } else if (chainId === WEMIX_TESTNET) {
    const wsProvider = new ethers.providers.JsonRpcProvider(
      "https://api.test.wemix.com"
    );
    wsProvider.pollingInterval = 2000;

    return wsProvider;
  } else if (chainId === ARBITRUM) {
    // const wsProvider = new ethers.providers.WebSocketProvider("wss://testnet.era.zksync.dev/ws");

    const wsProvider = new ethers.providers.JsonRpcProvider(
      "https://arb1.arbitrum.io/rpc"
    );
    wsProvider.pollingInterval = 2000;

    return wsProvider;
  } else if (chainId === ARBITRUM_GOERLI) {
    // const wsProvider = new ethers.providers.WebSocketProvider("wss://testnet.era.zksync.dev/ws");

    // const wsProvider = new ethers.providers.JsonRpcProvider("https://arbitrum-goerli.publicnode.com");
    // const wsProvider = new ethers.providers.JsonRpcProvider("https://arbitrum-goerli-rpc.allthatnode.com");

    const wsProvider = new ethers.providers.JsonRpcProvider(
      "https://goerli-rollup.arbitrum.io/rpc"
    );

    // const wsProvider = new ethers.providers.JsonRpcProvider("https://endpoints.omniatech.io/v1/arbitrum/goerli/public");
    // const wsProvider = new ethers.providers.JsonRpcProvider("https://arbitrum-goerli.blockpi.network/v1/rpc/public");

    wsProvider.pollingInterval = 2000;

    return wsProvider;
  } else if (chainId === ARBITRUM_SEPOLIA) {
    // const wsProvider = new ethers.providers.WebSocketProvider("wss://testnet.era.zksync.dev/ws");

    const wsProvider = new ethers.providers.JsonRpcProvider(
      "https://sepolia-rollup.arbitrum.io/rpc"
    );
    wsProvider.pollingInterval = 300;

    return wsProvider;
  }

  return;
}

export const getSlOrLiqPrice = (trade: ParsedTrade) => {
  if (
    trade.liqPrice &&
    trade.sl.gt(constants.Zero) &&
    trade.liqPrice.gt(constants.Zero)
  ) {
    if (trade.buy) {
      // LONG -> 더 높은 값 반환
      if (trade.liqPrice.gte(trade.sl)) {
        return { type: t`LIQ`, value: trade.liqPrice };
      } else {
        return { type: t`SL`, value: trade.sl };
      }
    } else {
      // SHORT -> 더 낮은 값 반환
      if (trade.liqPrice.lte(trade.sl)) {
        return { type: t`LIQ`, value: trade.liqPrice };
      } else {
        return { type: t`SL`, value: trade.sl };
      }
    }
  } else if (trade.sl.gt(constants.Zero)) {
    return { type: t`SL`, value: trade.sl };
  } else if (trade.liqPrice && trade.liqPrice.gt(constants.Zero)) {
    return { type: t`LIQ`, value: trade.liqPrice };
  }

  // LIQ.PRICE가 없는 경우 -> waitingOpenTrade인 경우
  return { type: null, value: constants.Zero };
};

export async function retryPromise(promise, nthTry = 100) {
  try {
    // try to resolve the promise
    const data = await promise;
    // if resolved simply return the result back to the caller
    return data;
  } catch (e) {
    // if the promise fails and we are down to 1 try we reject
    if (nthTry === 1) {
      return Promise.reject(e);
    }
    // if the promise fails and the current try is not equal to 1
    // we call this function again from itself but this time
    // we reduce the no. of tries by one
    // so that eventually we reach to "1 try left" where we know we have to stop and reject
    // console.log("retrying", nthTry, "time");
    // we return whatever is the result of calling the same function
    return retryPromise(promise, nthTry - 1);
  }
}

/**
 * Trade를 close할 때 몇 % 손익인지 계산
 *
 * @param openUsdcSize        오픈할 때 담보 USDC 사이즈
 * @param closeUsdcSize       클로즈할 때 되돌려 받은 USDC 사이즈
 * @param buy                LONG / SHORT
 * @param decimals           USDC decimals
 * @returns                  손익률
 */
export const getClosePnL = (
  openUsdcSize: BigNumber,
  closeUsdcSize: BigNumber,
  buy: boolean,
  decimals: number
) => {
  if (buy) {
    return new BN(formatUnits(closeUsdcSize, decimals))
      .minus(formatUnits(openUsdcSize, decimals))
      .dividedBy(formatUnits(openUsdcSize, decimals))
      .multipliedBy(100)
      .toFixed(2);
  } else {
    return new BN(formatUnits(closeUsdcSize, decimals))
      .minus(formatUnits(openUsdcSize, decimals))
      .dividedBy(formatUnits(openUsdcSize, decimals))
      .multipliedBy(100)
      .toFixed(2);
  }
};

// 수익이면 true, 손실이면 false 반환
export const profitable = (
  openUsdcSize: BigNumber,
  closeUsdcSize: BigNumber,
  buy: boolean
) => {
  // long, short 모두 동일하게 판단
  return openUsdcSize.lt(closeUsdcSize);
};

/**
 * (레버리지를 곱한) positionSize 기준으로 priceChange 만큼 변했을 때 손익
 *
 * @param trade
 * @param priceChange   openPrice ~ currentPrice 간 증감률 (소수점 이하 2자리로 표현)
 * @returns             BigNumber, USDC 사이즈로 반환
 */
export const getNetPnL = (
  trade: ParsedTrade,
  priceChange: string,
  decimals: number,
  openAndNetworkFee?: string
) => {
  const _positionSize = BigNumber.from(
    new BN(trade.positionSizeUsdc.toString())
      .multipliedBy(trade.leverage)
      .toFixed(0)
  );

  const positionSize = formatUnits(_positionSize, decimals);

  // positionSize * 요율
  // let closingFee = new BN(positionSize).multipliedBy(0.0008).toFixed(2);
  const _closingFee = getClosingFee(+trade.pair.fee.closeFeeP, _positionSize);
  const closingFee = formatAmount(_closingFee, decimals, 2, false);
  // console.log(closingFee); // 단위: fromToken

  if (
    priceChange &&
    positionSize &&
    trade.rolloverFee &&
    trade.fundingFee &&
    closingFee
  ) {
    const rolloverFee = formatUnits(trade.rolloverFee, decimals);
    const fundingFee = formatUnits(trade.fundingFee, decimals);

    const direction = trade.buy ? 1 : -1;

    const result = new BN(positionSize)
      .multipliedBy(+priceChange * direction)
      .dividedBy(100)
      // .minus(openAndNetworkFee || 0)
      .minus(rolloverFee)
      .minus(fundingFee)
      .minus(closingFee);

    return result.toFixed(2);
  }

  return undefined;
};

// * fee 포함 안하고 price change에 의한 손익
export const getPnL = (
  trade: ParsedTrade,
  priceChange: string,
  decimals: number
) => {
  const _positionSize = BigNumber.from(
    new BN(trade.positionSizeUsdc.toString())
      .multipliedBy(trade.leverage)
      .toFixed(0)
  );

  const positionSize = formatUnits(_positionSize, decimals);

  if (priceChange && positionSize) {
    const direction = trade.buy ? 1 : -1;

    const result = new BN(positionSize)
      .multipliedBy(+priceChange * direction)
      .dividedBy(100);

    return result.toFixed(2);
  }

  return undefined;
};

/**
 *
 * @param netPnL                   (BigNumber) getNetPnL 결과, 손익 USDC 개수
 * @param positionSizeUsdcValue    (레버리지 곱하지 않은) 담보 USDC 사이즈
 * @returns
 */

export const getNetPnLPercent = (
  netPnL: string,
  positionSizeUsdcValue: string
) => {
  return new BN(netPnL)
    .dividedBy(positionSizeUsdcValue)
    .multipliedBy(100)
    .toFixed(2);
};

export const getPnLPercent = (pnl: string, positionSizeUsdcValue: string) => {
  return new BN(pnl)
    .dividedBy(positionSizeUsdcValue)
    .multipliedBy(100)
    .toFixed(2);
};

/**
 * DEPRECATED
 * 오프닝피는 포지션 사이즈의 요율
 *
 * @param positionSize    positionSizeUsdc * leverage 값
 * @returns               USDC 개수
 */
// export const getOpeningFees = (positionSize: BigNumber) => {
//   return positionSize.mul(8).div(BigNumber.from(10000));
// };

export const getPriceChange = (
  currentPriceValue: string,
  openPriceValue: string
) => {
  return new BN(currentPriceValue)
    .minus(openPriceValue)
    .dividedBy(openPriceValue)
    .multipliedBy(100)
    .toFixed(6);
};

export const getPair = (pairs: Pair[], pairIndex: number) => {
  const pair = pairs.find((pair) => pair.pairIndex === pairIndex);
  if (!pair) {
    return {} as Pair;
  }
  return pair;
};

export const getPairFromSubgraphPairs = (
  pairs: SubgraphPair[],
  pairIndex: string,
  fromToken: Token
) => {
  const _pair = pairs.find((pair) => pair.id === pairIndex);
  if (!_pair) {
    return {} as Pair;
  }

  // const priceFeedId = priceFeedIds.find((priceFeedId) => {
  //   const symbolPieces = priceFeedId.Symbol.split(".");
  //   const simpleSymbol = symbolPieces[symbolPieces.length - 1]; // "Crypto.BTC/USD" -> BTC/USD 로 만들어서 찾으려고

  //   return simpleSymbol === `${_pair.from}/${_pair.to}`;
  // });

  const pair: Pair = {
    groupIndex: +_pair.group.id,
    pairIndex: +_pair.id,
    from: _pair.from,
    to: _pair.to,
    priceFeedId: _pair.feed.priceId1
      ? _pair.feed.priceId1
      : ethers.constants.AddressZero,
    tvSymbol: `PYTH:${_pair.from}${_pair.to}`,
    fee: {
      openFeeP: formatUnits(_pair.fee.openFeeP, GAMBIT_USD_DECIMALS),
      closeFeeP: formatUnits(_pair.fee.closeFeeP, GAMBIT_USD_DECIMALS),
      minLevPosUsdc: formatUnits(_pair.fee.minLevPosUsdc, fromToken.decimals),
      oracleFee: formatUnits(_pair.fee.oracleFee, fromToken.decimals),
    },
    group: {
      minLeverage: formatUnits(_pair.group.minLeverage, ADDED_DECIMALS),
      maxLeverage: formatUnits(_pair.group.maxLeverage, ADDED_DECIMALS),
    },
    confMultiplierP: +formatUnits(
      _pair.confMultiplierP,
      confMultiplierDecimals
    ),
  };
  return pair;
};

export const addPlusGt0 = (numberString: string, decimals = 2) => {
  const input = new BN(numberString).toFixed(decimals);
  if (new BN(numberString).gt(0)) {
    return `+${input}`;
  }
  return input;
};

/**
 * 소수점 자리 수 표기 helper
 * @param price
 * @returns
 */
export const trimPriceString = (price: string | undefined, comma = false) => {
  if (!price) return "";

  // price < 0.01 인 경우 모든 소수점 노출
  if (new BN(price).lt(PRICE_DECIMAL_STANDARD / 1000)) {
    // return makeNdigits(price);
    return price;
  }
  // price < 50 인 경우 소수점 4자리까지 노출
  else if (new BN(price).lt(PRICE_DECIMAL_STANDARD * 5)) {
    return unpadZero(formatStringAmount(price, 4, comma));
  }
  // price >= 100 인 경우 소수점 2자리까지 노출
  return unpadZero(formatStringAmount(price, 2, comma));
};

/**
 * 소수점 자리 수 표기 helper
 * @param price
 * @returns
 */
export const trimPriceBN = (price: BigNumber, comma = false) => {
  if (!price) return "";

  // price < 0.01 인 경우 모든 소수점 노출
  if (
    price.lt(
      parseUnits(
        (PRICE_DECIMAL_STANDARD / 1000).toString(),
        GAMBIT_USD_DECIMALS
      )
    )
  ) {
    return makeNdigits(formatUnits(price, GAMBIT_USD_DECIMALS));
  }
  // price < 50 인 경우 소수점 4자리까지 노출
  else if (
    price.lt(
      parseUnits((PRICE_DECIMAL_STANDARD * 5).toString(), GAMBIT_USD_DECIMALS)
    )
  ) {
    return formatAmount(price, GAMBIT_USD_DECIMALS, 4, comma);
  }
  // 1 <= price < 100 인 경우 소수점 4자리까지 노출
  // else if (price.lt(parseUnits((PRICE_DECIMAL_STANDARD * 10).toString(), GAMBIT_USD_DECIMALS))) {
  //   return formatAmount(price, GAMBIT_USD_DECIMALS, 4, comma);
  // }
  // price >= 100 인 경우 소수점 2자리까지 노출
  return formatAmount(price, GAMBIT_USD_DECIMALS, 2, comma);
};

/**
 * groupId
 * 0: crypto
 * 1: forex
 */
// export const feeP = {
//   0: {
//     opening: 0.0004, // 0.04%
//     closing: 0.0004, // 0.04%
//     openingNumerator: 4,
//     openingDenominator: 10000,
//     closingNumerator: 4,
//     closingDenominator: 10000,
//   },
//   1: {
//     opening: 0.00006, // 0.006%
//     closing: 0.00006, // 0.006%
//     openingNumerator: 6,
//     openingDenominator: 100000,
//     closingNumerator: 6,
//     closingDenominator: 100000,
//   },
// };

// * deprecated
export const getOpeningFee = (openFeeP: number, positionSize: BigNumber) => {
  // * openFeeP는 dev, gov 각각에게 가는 요율이기 때문에 실제 사용자는 2배한 요율을 지불, 100으로 나누는 이유는 % -> 소수
  const result = new BN(positionSize.toString()).multipliedBy(
    (+openFeeP * 2) / 100
  );
  return BigNumber.from(result.toFixed(0));
};

/**
 * * 변경 전: PAY x 레버리지 x OpenFeePx2
 * * 변경 후: (PAY - 네트워크 피) x 레버리지 x OpenFeePx2(for dev, gov) + 네트워크 피
 * * PAY = collateral + openingFee
 * * openFeeP는 dev, gov 각각에게 가는 요율이기 때문에 실제 사용자는 2배한 요율을 지불, 100으로 나누는 이유는 % -> 소수
 *
 * @param collateral: formatUnits 형식 (not parseUnits) e.g) 100
 * @param leverage: formatUnits 형식 (not parseUnits) e.g) 20x
 * @param networkFee: formatUnits 형식 (not parseUnits) e.g) $0.5
 */
export const getOpeningFeeWithNetworkFee = (
  openFeeP: number,
  collateral: string,
  leverage: number,
  networkFee: number | undefined
) => {
  // * openFeeP는 dev, gov 각각에게 가는 요율이기 때문에 실제 사용자는 2배한 요율을 지불, 100으로 나누는 이유는 % -> 소수
  const result = new BN(collateral)
    .minus(networkFee ?? 0)
    .multipliedBy(leverage)
    .multipliedBy((+openFeeP * 2) / 100)
    .plus(networkFee ?? 0);
  return result.toFixed();
};

/**
 * * 담보금(collateral)이 아니라 Pay만 가지고 오프닝피 계산
 * * 오프닝피 계산 from Pay for closedTrade
 */
export const getOpeningFeeFromPay = (
  pair: SubgraphPair,
  trade: SubgraphTrade,
  fromToken: Token,
  delegationFeeThresholdMultiplier: BigNumber | undefined
) => {
  /**
   * * (networkFee가 0인 경우) -> 포지션 사이즈가 threshhold(e.g. 7,500 USDC(crypto) 50,000 USDC(forx)) 보다 커서
   *              leverage x positionSizeUsdc x openFeeP x 2
   * openingFee = -------------------------------------------
   *                     1 - leverage x openFeeP x 2
   *
   * getOpeningFee 사용 못함
   * getOpeningFee에 넘기는 positionSize는 collateral x leverage 인데,
   * 이미 체결된 주문의 positionSizeUsdc는 collateral이 아니라 collateral - openingFee 이기 때문
   *
   *
   * * (networkFee가 포함된 경우) -> 포지션 사이즈가 threshhold(e.g. 7,500 USDC(crypto) 50,000 USDC(forx)) 보다 작아서
   *
   *               positionSizeUsdc x (leverage x openFeeP x 2) - (networkFee x leverage x openFeeP x 2) + networkFee
   * openingFee = ----------------------------------------------------------------------------------------------------
   *                                      1 - leverage x openFeeP x 2
   *
   */

  const delegationFeeThreshhold = getDelegationFeeThreshhold(
    formatUnits(pair.fee.minLevPosUsdc, fromToken.decimals),
    delegationFeeThresholdMultiplier
  );

  let openingFeeWithNetworkFee = "";
  let openingFeeWithoutNetworkFee = "";
  let openingFeeNumerator: BN;
  let openingFeeDenominator: BN;
  const _positionSizeUsdc = formatUnits(
    BigNumber.from(trade.positionSizeUsdc),
    fromToken.decimals
  );
  const formattedOpenFeeP = +formatUnits(
    pair.fee.openFeeP,
    GAMBIT_USD_DECIMALS
  );
  const leverage = +formatUnits(trade.leverage, ADDED_DECIMALS);

  if (pair) {
    // * networkFee가 0인 경우
    // console.log(delegationFeeThreshhold, _positionSizeUsdc);
    // if (
    //   delegationFeeThreshhold !== undefined &&
    //   delegationFeeThreshhold <= +_positionSizeUsdc)
    // ) {
    // console.log(trade);
    openingFeeNumerator = new BN(leverage)
      .multipliedBy(_positionSizeUsdc)
      .multipliedBy((formattedOpenFeeP * 2) / 100); // openFeeP는 dev, gov 각각에게 가는 요율이기 때문에 실제 사용자는 2배한 요율을 지불, 100으로 나누는 이유는 % -> 소수
    openingFeeDenominator = new BN(1).minus(
      new BN(leverage).multipliedBy((formattedOpenFeeP * 2) / 100) // openFeeP는 dev, gov 각각에게 가는 요율이기 때문에 실제 사용자는 2배한 요율을 지불, 100으로 나누는 이유는 % -> 소수
    );
    // console.log(_positionSizeUsdc);
    openingFeeWithoutNetworkFee = openingFeeNumerator
      .dividedBy(openingFeeDenominator)
      .toFixed(3);
    // console.log(`formattedOpenFeeP:     ${formattedOpenFeeP}`);
    // console.log(`formattedCloseFeeP:    ${formattedCloseFeeP}`);
    // console.log(`openingFeeNumerator:   ${openingFeeNumerator}`);
    // console.log(`openingFeeDenominator: ${openingFeeDenominator}`);
    // console.log(`openingFeeWithoutNetworkFee:            ${openingFeeWithoutNetworkFee}`);
    // console.log(`closingFee:            ${closingFee}`);
    // }
    // * networkFee가 0이 아닌 경우
    const networkFee = formatUnits(pair.fee.oracleFee, fromToken.decimals);
    // console.log(trade, networkFee, formattedOpenFeeP);

    // console.log(_positionSizeUsdc, leverage, formattedOpenFeeP);
    openingFeeNumerator = new BN(_positionSizeUsdc)
      .plus(networkFee)
      .multipliedBy(leverage)
      .multipliedBy((formattedOpenFeeP * 2) / 100)
      .minus(
        new BN(networkFee)
          .multipliedBy(leverage)
          .multipliedBy((formattedOpenFeeP * 2) / 100)
      ) // openFeeP는 dev, gov 각각에게 가는 요율이기 때문에 실제 사용자는 2배한 요율을 지불, 100으로 나누는 이유는 % -> 소수
      .plus(networkFee);
    openingFeeDenominator = new BN(1).minus(
      new BN(leverage).multipliedBy((formattedOpenFeeP * 2) / 100) // openFeeP는 dev, gov 각각에게 가는 요율이기 때문에 실제 사용자는 2배한 요율을 지불, 100으로 나누는 이유는 % -> 소수
    );
    // console.log(_positionSizeUsdc);
    openingFeeWithNetworkFee = openingFeeNumerator
      .dividedBy(openingFeeDenominator)
      .toFixed(3);
    // console.log(`formattedOpenFeeP:     ${formattedOpenFeeP}`);
    // console.log(`formattedCloseFeeP:    ${formattedCloseFeeP}`);
    // console.log(`openingFeeNumerator:   ${openingFeeNumerator}`);
    // console.log(`openingFeeDenominator: ${openingFeeDenominator}`);
    // console.log(`openingFeeWithNetworkFee:            ${openingFeeWithNetworkFee}`);
    // console.log(`closingFee:            ${closingFee}`);

    const includeNetworkFee =
      delegationFeeThreshhold !== undefined &&
      delegationFeeThreshhold >
        (+_positionSizeUsdc + +openingFeeWithoutNetworkFee) * leverage;
    // console.log(includeNetworkFee);
    // console.log((+_positionSizeUsdc + +openingFeeWithoutNetworkFee) * leverage);

    return {
      openingFee: includeNetworkFee
        ? openingFeeWithNetworkFee
        : openingFeeWithoutNetworkFee,
      includeNetworkFee,
    };
  }
  return {
    openingFee: "",
    includeNetworkFee: false,
  };

  // console.log(trade.leverage);
  // console.log(formattedCloseFeeP);
  // console.log(formattedOpenFeeP);
};

export const getClosingFee = (closeFeeP: number, positionSize: BigNumber) => {
  // return new BN(positionSize).multipliedBy(feeP[groupId].closing).toFixed(2);
  // return positionSize.mul(feeP[groupId].closingNumerator).div(BigNumber.from(feeP[groupId].closingDenominator));
  // console.log(positionSize.toString());
  const result = new BN(positionSize.toString()).multipliedBy(closeFeeP / 100);

  return BigNumber.from(result.toFixed(0));
};

export const comma1000 = (num: string | number) => {
  if (typeof num === "string") {
    if (new BN(num).gte(1000)) {
      return (+num).toLocaleString();
    }
  } else if (typeof num === "number") {
    if (new BN(num).gte(1000)) {
      return num.toLocaleString();
    }
  }
  return num;
};

export const getFulfillPrice = (
  order: Order,
  pairs: Pair[],
  pairsPrices: PairPrices
) => {
  const pair = pairs.find((pair) => pair.pairIndex === order.pairIndex);

  if (!pair || !pairsPrices[pair.priceFeedId]) return;

  const { conf, expo } = pairsPrices[pair.priceFeedId!];

  const _confidence = formatUnits(conf, Math.abs(expo)); // 단위: 달러

  const confMultiplier = order.pair.confMultiplierP;

  let spread = new BN(_confidence).multipliedBy(confMultiplier).toString();

  // INFO: minPrice는 Ask Price(요청 진입가격) 의미
  const askPrice = formatUnits(order.minPrice, GAMBIT_USD_DECIMALS);
  if (!new BN(askPrice).lt(10)) {
    spread = formatStringAmount(spread, 4, true);
  }

  // const confidenceSpreadValue = formatAmount(BigNumber.from(conf).div(BigNumber.from(2)), Math.abs(expo), 2, false);
  // const AskPrice = formatUnits(order.maxPrice, GAMBIT_USD_DECIMALS);

  // * NOTE: https://chainpartners.slack.com/archives/C055TJGGCCB/p1697615558766109
  if (order.buy) {
    return trimPriceString(new BN(askPrice).plus(spread).toString(), true);
  } else {
    return trimPriceString(new BN(askPrice).minus(spread).toString(), true);
  }
};

export const getReason = (reason: string) => {
  switch (reason) {
    case "TAKE_PROFIT":
      return t({
        id: `msg.reason / TAKE PROFIT`,
        message: `TAKE PROFIT`,
      });
    case "STOP_LOSS":
      return t({
        id: `msg.reason / STOP LOSS`,
        message: `STOP LOSS`,
      });
    case "LIQUIDATION":
      return t({
        id: `msg.reason / LIQUIDATION`,
        message: `LIQUIDATION`,
      });
    case "CLOSE":
      return t({
        id: `msg.reason / CLOSE`,
        message: `CLOSE`,
      });
    case "MARKET_OPEN_CANCELED":
      return t({
        id: `msg.reason / CANCELED`,
        message: `CANCELED`,
      });
    default:
      return reason;
  }
};

export const getLimitType = (
  typeNum: number
): OpenLimitOrderType | undefined => {
  switch (typeNum) {
    case 0:
      return "LEGACY";
    case 1:
      return "REVERSAL";
    case 2:
      return "MOMENTUM";
    default:
      return undefined;
  }
};

/**
 * 20.00 -> 20
 * 9.96 -> 9.96
 * @param numberString
 */
export const unpadZero = (numberString: string | undefined) => {
  if (numberString === undefined) return "";

  const splitted = numberString.split(".");
  if (!splitted[1]) return numberString;

  if (new BN(splitted[1]).isZero()) {
    return splitted[0];
  } else {
    return numberString;
  }
};

export const threeDotsToZero = (stringPrice: string, decimals = 2) => {
  if (stringPrice === "...") return new BN(0).toFixed(decimals);

  return stringPrice;
};

export const threeDotsToEmptyString = (stringPrice: string) => {
  if (stringPrice === "...") return "";

  return stringPrice;
};

/**
 * 숫자 N(디폴트 4)개만 보여주도록
 * e.g 1) 0.000055632 -> 0.00005563
 * e.g 2) 0.00005506032 -> 0.00005506
 * e.g 3) 0.00005500632 -> 0.00005500 -> 0.00005500
 * 0. .기준으로 split
 * 1. split[1]에서 0000 / 55632 구분
 * 2. 4자리로 반올림해서 구분[1]을 55632 -> 55630 만들기
 * 3. 오른쪽 0 제거 55630 -> 5563
 * 4. 구분[0] + 4자리로 반올림된 구분[1] 문자열 이어 붙이기
 */
export const makeNdigits = (numberString: string, digits = 4) => {
  const splitted = numberString.split(".");
  if (!splitted[1]) return numberString;

  try {
    const regexr = /^0+/g;
    const matchedArray = splitted[1].match(regexr);
    const rightZeros = matchedArray ? matchedArray[0] : "";

    // 이렇게 구하면 안됨
    const leftNumber = splitted[1].replace(rightZeros, "");

    const e = leftNumber.length - digits - 1;
    const roundedLeftNumber = new BN(
      Math.round(new BN(leftNumber).dividedBy(`10e${e}`).toNumber())
    )
      .multipliedBy(`10e${e}`)
      .toFixed();

    const removedRightZeroNumber = roundedLeftNumber.replace(/(\d)0+$/gm, "$1");

    if (isNaN(+removedRightZeroNumber)) {
      return numberString;
    }

    return `${splitted[0]}.${rightZeros}${removedRightZeroNumber}`;
  } catch (e) {
    return numberString;
  }
};

export function randomBytes(length: number): Uint8Array {
  return arrayify(_randomBytes(length));
}

const toHexString = (bytes: Uint8Array) => {
  return Array.from(bytes, (byte) => {
    return ("0" + (byte & 0xff).toString(16)).slice(-2);
  }).join("");
};

// const result = randomBytes(32);
// console.log(toHexString(result));
// console.log(Buffer.from(result).toString("hex"));

export const getSalt = () => {
  return "0x" + toHexString(randomBytes(32));
};

export const _toHexString = (str: string) => {
  return Buffer.from(str).toString("hex");
};

export const toDataHexString = (num: number) => {
  return hexlify(hexValue(num).padEnd(30, "0"));
};
