import { ethers } from "ethers";
import {
  POLYGON,
  POLYGON_MUMBAI,
  SUPPORTED_CHAIN_IDS,
  WEMIX_TESTNET,
  ZKSYNC,
  HARDHAT,
  ZKSYNC_GOERLI,
  ARBITRUM_GOERLI,
  ARBITRUM,
  ZKSYNC_SEPOLIA,
  ARBITRUM_SEPOLIA,
} from "./chains";
import { Token } from "futures-domain/tokens";
import ContractAddress from "@changerio/futures-contracts/contract-deployment.json";
import { tokens as TokenAddress } from "@changerio/futures-contracts/dist/lib/constants";

// TODO: Arbitrum 추가 주소 확인
export const TOKENS: { [chainId: number]: Token[] } = {
  [POLYGON]: [
    {
      name: "Polygon",
      symbol: "MATIC",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isNative: true,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/4713/small/matic-token-icon.png?1624446912",
    },
    {
      name: "Wrapped Matic",
      symbol: "WMATIC",
      decimals: 18,
      address: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
      isWrapped: true,
      baseSymbol: "MATIC",
      imageUrl: "https://assets.coingecko.com/coins/images/4713/small/matic-token-icon.png?1624446912",
    },
    {
      name: "USD Coin",
      symbol: "USDC",
      address: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
      decimals: 6,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "DAI",
      symbol: "DAI",
      address: "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
      decimals: 18,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/9956/small/4943.png?1636636734",
    },
    {
      name: "DAI Vault",
      symbol: "gDAI",
      address: "0x91993f2101cc758D0dEB7279d41e880F7dEFe827",
      decimals: 18,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/9956/small/4943.png?1636636734",
    },
  ],
  [POLYGON_MUMBAI]: [
    {
      name: "Polygon",
      symbol: "MATIC",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isNative: true,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/4713/small/matic-token-icon.png?1624446912",
    },
    {
      name: "Wrapped Matic",
      symbol: "WMATIC",
      decimals: 18,
      address: "0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889",
      isWrapped: true,
      baseSymbol: "MATIC",
      imageUrl: "https://assets.coingecko.com/coins/images/4713/small/matic-token-icon.png?1624446912",
    },
    {
      name: "USD Coin",
      symbol: "USDC",
      address: "0xe6b8a5CF854791412c1f6EFC7CAf629f5Df1c747",
      decimals: 6,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "GFARM DAI",
      symbol: "DAI",
      address: "0x04B2A6E51272c82932ecaB31A5Ab5aC32AE168C3",
      decimals: 18,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/9956/small/4943.png?1636636734",
    },
    {
      name: "DAI Vault",
      symbol: "gDAI",
      address: "0x5215C8B3e76D493c8bcb9A7352F7afe18A6bb091",
      decimals: 18,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/9956/small/4943.png?1636636734",
    },
  ],
  [ZKSYNC]: [
    {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      address: ethers.constants.AddressZero,
      coingeckoUrl: "https://www.coingecko.com/en/coins/ethereum",
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png",
    },
    {
      name: "USDC",
      symbol: "USDC",
      address: TokenAddress[ZKSYNC].USDC.address,
      decimals: 6,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "USDC Vault",
      symbol: "gUSDC",
      address: ContractAddress.zksyncEra.contracts.SimpleGToken_Proxy,
      decimals: 6,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "Changer",
      symbol: "CNG",
      address: TokenAddress[ZKSYNC].CNG.address,
      decimals: 18,
      isStable: true,
      imageUrl: "",
    },
  ],
  [ZKSYNC_GOERLI]: [
    {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      address: ethers.constants.AddressZero,
      coingeckoUrl: "https://www.coingecko.com/en/coins/ethereum",
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png",
    },
    {
      name: "USDC",
      symbol: "USDC",
      address: TokenAddress[ZKSYNC_GOERLI].USDC.address,
      decimals: 6,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "USDC Vault",
      symbol: "gUSDC",
      address: ContractAddress.zksyncEraGoerli.contracts.SimpleGToken_Proxy,
      decimals: 6,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "Changer",
      symbol: "CNG",
      address: TokenAddress[ZKSYNC_GOERLI].CNG.address,
      decimals: 18,
      isStable: true,
      imageUrl: "",
    },
  ],
  [ZKSYNC_SEPOLIA]: [
    {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      address: ethers.constants.AddressZero,
      coingeckoUrl: "https://www.coingecko.com/en/coins/ethereum",
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png",
    },
    {
      name: "USDC",
      symbol: "USDC",
      address: TokenAddress[ZKSYNC_SEPOLIA].USDC.address,
      decimals: 6,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "USDC Vault",
      symbol: "gUSDC",
      address: ContractAddress.zksyncEraSepolia.contracts.SimpleGToken_Proxy,
      decimals: 6,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "Changer",
      symbol: "CNG",
      address: TokenAddress[ZKSYNC_SEPOLIA].CNG.address,
      decimals: 18,
      isStable: true,
      imageUrl: "",
    },
  ],
  [HARDHAT]: [
    {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      address: ethers.constants.AddressZero,
      coingeckoUrl: "https://www.coingecko.com/en/coins/ethereum",
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png",
    },
    {
      name: "Mock USDC",
      symbol: "USDC",
      address: ContractAddress.hardhat.contracts.MockUSDC,
      decimals: 6,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/standard/usdc.png?1696506694",
    },
    {
      name: "USDC Vault",
      symbol: "gUSDC",
      address: ContractAddress.hardhat.contracts.SimpleGToken_Proxy,
      decimals: 6,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/standard/usdc.png?1696506694",
    },
    // {
    //   name: "Mock DAI",
    //   symbol: "DAI",
    //   address: ContractAddress.hardhat.contracts.MockDAI,
    //   decimals: 18,
    //   isStable: true,
    //   imageUrl: "https://assets.coingecko.com/coins/images/9956/small/Badge_Dai.png?1687143508",
    // },
    // {
    //   name: "DAI Vault",
    //   symbol: "gDAI",
    //   address: ContractAddress.hardhat.contracts.SimpleGToken_Proxy,
    //   decimals: 18,
    //   isStable: true,
    //   imageUrl: "https://assets.coingecko.com/coins/images/9956/small/Badge_Dai.png?1687143508",
    // },
    {
      name: "Changer",
      symbol: "CNG",
      address: ContractAddress.hardhat.contracts.MockCNG,
      decimals: 18,
      isStable: true,
    },
  ],
  [WEMIX_TESTNET]: [
    {
      name: "WEMIX",
      symbol: "WEMIX",
      decimals: 18,
      address: ethers.constants.AddressZero,
      coingeckoUrl: "https://www.coingecko.com/en/coins/wemix",
      imageUrl: "https://assets.coingecko.com/coins/images/12998/small/wemixcoin_color_200.png",
    },
    {
      name: "WEMIX$",
      symbol: "WEMIX$",
      // address: TokenAddress[WEMIX_TESTNET].USDC.address,
      address: "0xae81b9ffcde5ab7673dd4b2f5c648a5579430b17",
      decimals: 18,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/28637/small/wemix_dollar.png?1678677565",
    },
    {
      name: "WEMIX$ Vault",
      symbol: "gWEMIX$",
      address: ContractAddress.wemixTestnet.contracts.SimpleGToken_Proxy,
      decimals: 18,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/28637/small/wemix_dollar.png?1678677565",
    },
    {
      name: "Changer",
      symbol: "CNG",
      address: ContractAddress.wemixTestnet.contracts.MockCNG,
      decimals: 18,
      isStable: true,
      imageUrl: "",
    },
  ],
  [ARBITRUM]: [
    {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      address: ethers.constants.AddressZero,
      coingeckoUrl: "https://www.coingecko.com/en/coins/ethereum",
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png",
    },
    {
      name: "USDC",
      symbol: "USDC",
      address: TokenAddress[ARBITRUM].USDC.address,
      decimals: 6,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "USDC Vault",
      symbol: "gUSDC",
      address: ContractAddress.arbitrumOne.contracts.SimpleGToken_Proxy,
      decimals: 6,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "Changer",
      symbol: "CNG",
      address: TokenAddress[ARBITRUM].CNG.address,
      decimals: 18,
      isStable: true,
      imageUrl: "",
    },
  ],
  [ARBITRUM_GOERLI]: [
    {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      address: ethers.constants.AddressZero,
      coingeckoUrl: "https://www.coingecko.com/en/coins/ethereum",
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png",
    },
    {
      name: "USDC",
      symbol: "USDC",
      address: TokenAddress[ARBITRUM_GOERLI].USDC.address || ContractAddress.arbitrumGoerli.contracts.MockUSDC,
      decimals: 6,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "USDC Vault",
      symbol: "gUSDC",
      address: ContractAddress.arbitrumGoerli.contracts.SimpleGToken_Proxy,
      decimals: 6,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "Changer",
      symbol: "CNG",
      address: ContractAddress.arbitrumGoerli.contracts.MockCNG || TokenAddress[ARBITRUM_GOERLI].CNG.address,
      decimals: 18,
      isStable: true,
      imageUrl: "",
    },
  ],
  [ARBITRUM_SEPOLIA]: [
    {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      address: ethers.constants.AddressZero,
      coingeckoUrl: "https://www.coingecko.com/en/coins/ethereum",
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png",
    },
    {
      name: "USDC",
      symbol: "USDC",
      address: TokenAddress[ARBITRUM_SEPOLIA].USDC.address || ContractAddress.arbitrumSepolia.contracts.MockUSDC,
      decimals: 6,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "USDC Vault",
      symbol: "gUSDC",
      address: ContractAddress.arbitrumSepolia.contracts.SimpleGToken_Proxy,
      decimals: 6,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "Changer",
      symbol: "CNG",
      address: TokenAddress[ARBITRUM_SEPOLIA].CNG.address,
      decimals: 18,
      isStable: true,
      imageUrl: "",
    },
  ],
};

export const TOKENS_MAP: { [chainId: number]: { [address: string]: Token } } = {};
export const TOKENS_BY_SYMBOL_MAP: { [chainId: number]: { [symbol: string]: Token } } = {};
export const WRAPPED_TOKENS_MAP: { [chainId: number]: Token } = {};
export const NativeTokenS_MAP: { [chainId: number]: Token } = {};

const CHAIN_IDS = SUPPORTED_CHAIN_IDS;

for (let j = 0; j < CHAIN_IDS.length; j++) {
  const chainId = CHAIN_IDS[j];
  TOKENS_MAP[chainId] = {};
  TOKENS_BY_SYMBOL_MAP[chainId] = {};
  let tokens = TOKENS[chainId];
  // if (ADDITIONAL_TOKENS[chainId]) {
  //   tokens = tokens.concat(ADDITIONAL_TOKENS[chainId]);
  // }

  for (let i = 0; i < tokens.length; i++) {
    const token = tokens[i];
    TOKENS_MAP[chainId][token.address] = token;
    TOKENS_BY_SYMBOL_MAP[chainId][token.symbol] = token;
  }
}

for (const chainId of CHAIN_IDS) {
  for (const token of TOKENS[chainId]) {
    if (token.isWrapped) {
      WRAPPED_TOKENS_MAP[chainId] = token;
    } else if (token.isNative) {
      NativeTokenS_MAP[chainId] = token;
    }
  }
}

export function getWrappedToken(chainId: number) {
  return WRAPPED_TOKENS_MAP[chainId];
}

export function getNativeToken(chainId: number) {
  return NativeTokenS_MAP[chainId];
}

export function getTokens(chainId: number) {
  return TOKENS[chainId];
}

export function isValidToken(chainId: number, address: string) {
  if (!TOKENS_MAP[chainId]) {
    throw new Error(`Incorrect chainId ${chainId}`);
  }
  return address in TOKENS_MAP[chainId];
}

export function getToken(chainId: number, address: string) {
  if (!TOKENS_MAP[chainId]) {
    throw new Error(`Incorrect chainId ${chainId}`);
  }
  if (!TOKENS_MAP[chainId][address]) {
    throw new Error(`Incorrect address "${address}" for chainId ${chainId}`);
  }
  return TOKENS_MAP[chainId][address];
}

export function getTokenBySymbol(chainId: number, symbol: string) {
  const token = TOKENS_BY_SYMBOL_MAP[chainId][symbol];
  if (!token) {
    throw new Error(`Incorrect symbol "${symbol}" for chainId ${chainId}`);
  }
  return token;
}

export function getWhitelistedTokens(chainId: number) {
  return TOKENS[chainId].filter((token) => token.symbol !== "USDG");
}

export function getVisibleTokens(chainId: number) {
  return getWhitelistedTokens(chainId).filter((token) => !token.isWrapped && !token.isTempHidden);
}

export function getNormalizedTokenSymbol(tokenSymbol) {
  if (["WBTC", "WETH", "WAVAX"].includes(tokenSymbol)) {
    return tokenSymbol.substr(1);
  } else if (tokenSymbol === "BTC.b") {
    return "BTC";
  }
  return tokenSymbol;
}

// const AVAILABLE_CHART_TOKENS = {
//   [ARBITRUM]: ["ETH", "BTC", "LINK", "UNI"],
//   [AVALANCHE]: ["AVAX", "ETH", "BTC"],
// };

export function isChartAvailabeForToken(chainId: number, tokenSymbol: string) {
  const token = getTokenBySymbol(chainId, tokenSymbol);
  if (!token) return false;
  // return (token.isStable || AVAILABLE_CHART_TOKENS[chainId]?.includes(getNormalizedTokenSymbol(tokenSymbol))) ?? false;
}

export const ETH_CNG_ADDRESS = "0x5c1d9aa868a30795f92fae903edc9eff269044bf";
export const ETH_USDC_ADDRESS = "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48";
