import { formatUnits } from "@ethersproject/units";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  // gtTp900Atom,
  selectedPairAtom,
  slPercentAtom,
  slPriceAtom,
  tpPercentAtom,
  tpPriceAtom,
  tpValidationAtom,
} from "atoms/exchange";
import BN from "bignumber.js";
import cx from "classnames";
import { getSlPercent, getSlPrice, getTpPercent, getTpPrice, trimPriceString } from "futures-domain/trades/utils";
import { numberWithCommas } from "futures-lib/numbers";
import { ReactComponent as InfoCircleSvg } from "img/ic-info-circle.svg";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useEffect, useMemo, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { usePrevious } from "react-use";
import { GAMBIT_USD_DECIMALS, MAX_GAIN_P, SL_OPTIONS, TP_OPTIONS } from "./constants";

const initialFocused = {
  sl: false,
  tp: false,
};

const StopLossAndTakeProfit = ({
  // estimatedExecutionPriceValue,
  openPriceForSlTp,
  leverageOption,
  fromValue,
  buy,
  symbol,
}: {
  // estimatedExecutionPriceValue: string | undefined;
  openPriceForSlTp: string;
  leverageOption: number | undefined;
  fromValue: string;
  buy: boolean;
  symbol: string;
}) => {
  const { i18n } = useLingui();

  const [slPercent, setSlPercent] = useAtom(slPercentAtom);
  const [tpPercent, setTpPercent] = useAtom(tpPercentAtom);
  const [slPrice, setSlPrice] = useAtom(slPriceAtom);
  const [tpPrice, setTpPrice] = useAtom(tpPriceAtom);
  const selectedPair = useAtomValue(selectedPairAtom);
  const prevSelectedPair = usePrevious(selectedPair);
  const prevLeverageOption = usePrevious(leverageOption);
  const prevBuy = usePrevious(buy);

  // const setGtTp900 = useSetAtom(gtTp900Atom);
  const setTpValidation = useSetAtom(tpValidationAtom);

  useEffect(() => {
    // 마운트될 때 900% tpValue를 구하고
    // case 1) 0보다 크면 return;
    // case 2) 0보다 작으면 setTpPercent(""), setTpPrice(0)

    // 업데이트 타이밍 -> selectedPair / leverageOption / buy 변경 시
    if (!leverageOption) return;
    // const tp900Price = formatUnits(
    //   getTpPrice(openPriceForSlTp, MAX_GAIN_P.toString(), leverageOption, buy),
    //   GAMBIT_USD_DECIMALS
    // );
    // const tp900Pricelt0 = new BN(tp900Price).lt(0);

    if (
      prevBuy === buy &&
      prevLeverageOption === leverageOption &&
      prevSelectedPair &&
      prevSelectedPair.from === selectedPair?.from &&
      prevSelectedPair.to === selectedPair?.to
    )
      return;

    if (tpPrice && tpPrice !== "0") return;

    for (const tpOption of TP_OPTIONS) {
      const tpPrice = formatUnits(
        getTpPrice(openPriceForSlTp, tpOption.value, leverageOption, buy),
        GAMBIT_USD_DECIMALS
      );
      const tpPricelt0 = new BN(tpPrice).lt(0);
      if (!tpPricelt0) {
        setTpPercent(tpOption.value);
        setTpPrice("");
      } else {
        return;
      }
    }

    // if (tp900Pricelt0) {
    //   setTpPercent("");
    //   setTpPrice("0");
    // } else {
    //   if (!tpPercent) {
    //     setTpPercent("900");
    //     setTpPrice("");
    //   }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPair, leverageOption, buy]);

  // 좌측 상단에 보여질 계산 값 - 1
  const displayedSlPrice = useMemo(() => {
    if (!openPriceForSlTp || !leverageOption) return "";

    if (slPercent) {
      // Price 반환
      // return comma1000(trimPriceString(getSlPrice(openPriceForSlTp, slPercent, leverageOption, buy)));
      return trimPriceString(
        formatUnits(getSlPrice(openPriceForSlTp, slPercent, leverageOption, buy), GAMBIT_USD_DECIMALS)
      );
    } else if (slPrice) {
      // Percent 반환
      const result = getSlPercent(openPriceForSlTp, slPrice, leverageOption, buy);
      return +result.toFixed(2);
    }

    return t`None`;
  }, [buy, leverageOption, openPriceForSlTp, slPrice, slPercent]);

  // 좌측 상단에 보여질 계산 값 - 2(포맷팅)
  const displayedSlPriceFormat = useMemo(() => {
    if (!displayedSlPrice) return "";

    if (displayedSlPrice === t`None`) return t`(None)`;

    if (slPercent) {
      return (
        <span className="font-space-grotesk">
          <div className="flex">
            <span>(</span>
            {/* <AnimatedNumber to={+displayedSlPrice} /> */}
            {numberWithCommas(displayedSlPrice)}
            <span>)</span>
          </div>
        </span>
      );
    } else if (slPrice) {
      return (
        <span className="font-space-grotesk">
          {typeof displayedSlPrice === "string" ? (
            displayedSlPrice
          ) : (
            <div className="flex">
              <span>(</span>
              {/* {numberWithCommas(displayedSlPrice)} */}
              {/* <AnimatedNumber to={displayedSlPrice} /> */}
              {numberWithCommas(displayedSlPrice)}
              <span>%</span>
              <span>)</span>
            </div>
          )}
        </span>
      );
    }

    return "";
  }, [displayedSlPrice, slPercent, slPrice]);

  // 우측 상단에 보여질 계산 값
  const displayedSlValue = useMemo(() => {
    if (!openPriceForSlTp || !leverageOption || !displayedSlPrice || !fromValue) return "";

    if (slPercent) {
      return (
        <span className="font-space-grotesk">
          {numberWithCommas(new BN(fromValue).multipliedBy(slPercent).dividedBy(100).toFixed(2))}
        </span>
      );
    } else if (slPrice) {
      return (
        <span className="font-space-grotesk">
          {numberWithCommas(new BN(fromValue).multipliedBy(displayedSlPrice).dividedBy(100).toFixed(2))}
        </span>
      );
    }

    return t`None`;
  }, [displayedSlPrice, fromValue, leverageOption, openPriceForSlTp, slPrice, slPercent]);

  // 좌측 상단에 보여질 계산 값 - 1
  const displayedTpPrice = useMemo(() => {
    if (!openPriceForSlTp || !leverageOption) return "";

    if (tpPercent) {
      // Price 반환
      if (!openPriceForSlTp || isNaN(+openPriceForSlTp)) return;
      // return comma1000(trimPriceString(getTpPrice(openPriceForSlTp, tpPercent, leverageOption, buy)));
      return trimPriceString(
        formatUnits(getTpPrice(openPriceForSlTp, tpPercent, leverageOption, buy), GAMBIT_USD_DECIMALS)
      );
    } else if (tpPrice) {
      if (tpPrice === "0") {
        return t`None`;
      }

      const result = getTpPercent(openPriceForSlTp, tpPrice, leverageOption, buy);
      // Percent 반환
      return +result.toFixed(2);
    }

    return t`None`;
  }, [buy, leverageOption, openPriceForSlTp, tpPrice, tpPercent]);

  // 좌측 상단에 보여질 계산 값 - 2(포맷팅)
  const displayedTpPriceFormat = useMemo(() => {
    if (!displayedTpPrice) return "";

    if (displayedTpPrice === t`None`) return t`(None)`;

    if (tpPercent) {
      return (
        <span className="font-space-grotesk">
          <div className="flex">
            <span>(</span>
            {/* <AnimatedNumber to={+displayedTpPrice} /> */}
            {numberWithCommas(displayedTpPrice)}
            <span>)</span>
          </div>
        </span>
      );
    } else if (tpPrice) {
      return (
        <span className="font-space-grotesk">
          {typeof displayedTpPrice === "string" ? (
            displayedTpPrice
          ) : (
            <div className="flex">
              <span>(</span>
              {/* <AnimatedNumber to={displayedTpPrice} /> */}
              {numberWithCommas(displayedTpPrice)}
              <span>%</span>
              <span>)</span>
            </div>
          )}
        </span>
      );
    }

    return "";
  }, [displayedTpPrice, tpPercent, tpPrice]);

  // useEffect(() => {
  //   if (!displayedTpPrice) return;

  //   if (+displayedTpPrice > 900) {
  //     setGtTp900(true);
  //   } else {
  //     setGtTp900(false);
  //   }
  // }, [displayedTpPrice, setGtTp900]);

  // 우측 상단에 보여질 계산 값
  const displayedTpValue = useMemo(() => {
    if (!openPriceForSlTp || !leverageOption || !displayedTpPrice || !fromValue) return "";

    if (tpPercent) {
      const result = new BN(fromValue).multipliedBy(tpPercent).dividedBy(100).toFixed(2);
      return (
        <span className="font-space-grotesk">
          {new BN(result).gte(0) ? `+${numberWithCommas(result)}` : numberWithCommas(result)}
        </span>
      );
    } else if (tpPrice) {
      if (tpPrice === "0") {
        return t`None`;
      }
      const result = new BN(fromValue).multipliedBy(displayedTpPrice).dividedBy(100).toFixed(2);
      return (
        <span className="font-space-grotesk">
          {new BN(result).gte(0) ? `+${numberWithCommas(result)}` : numberWithCommas(result)}
        </span>
      );
    }

    return t`None`;
  }, [displayedTpPrice, fromValue, leverageOption, openPriceForSlTp, tpPrice, tpPercent]);

  // TP validation check
  useEffect(() => {
    if (openPriceForSlTp === undefined || !leverageOption) return;

    const tp900Price = trimPriceString(
      formatUnits(getTpPrice(openPriceForSlTp, MAX_GAIN_P.toString(), leverageOption, buy), GAMBIT_USD_DECIMALS)
    );
    // console.log(openPriceForSlTp);
    let validation = {
      valid: true,
      message: ``,
    };

    // LONG인 경우
    if (buy && tpPrice) {
      if (new BN(tpPrice).lte(openPriceForSlTp) || new BN(tpPrice).gt(tp900Price)) {
        validation = {
          valid: false,
          message: t`Take Profit Wrong, It Must Be Greater Than ${trimPriceString(
            openPriceForSlTp
          )} and Less Than ${tp900Price}.`,
        };
      }
    }

    // SHORT인 경우
    if (!buy && tpPrice) {
      if (new BN(tpPrice).lte(0) || new BN(tpPrice).lt(tp900Price) || new BN(tpPrice).gte(openPriceForSlTp)) {
        validation = {
          valid: false,
          message: t`Take Profit Wrong, It Must Be Greater Than ${tp900Price} and Less Than ${trimPriceString(
            openPriceForSlTp
          )}.`,
        };
      }
    }
    setTpValidation(validation);
  }, [buy, leverageOption, openPriceForSlTp, setTpValidation, tpPrice]);

  const [focused, setFocused] = useState(initialFocused);

  return (
    <>
      {/* Stop Loss Box */}
      <div className="px-[1.6rem] py-[1.2rem] border-b border-b-gray-70">
        {/* Stop Loss Top */}
        <div className="flex justify-between mb-[1.55rem] text-[1.4rem] font-medium">
          <div className={cx("flex items-center text-gray-20 text-[1.4rem] font-medium")}>
            <Trans>Stop Loss</Trans>
            <span className={cx("ml-[3.5px] text-red-2", "max-w-[10rem] xs:max-w-[12.5rem] overflow-x-auto")}>
              {displayedSlPriceFormat}
            </span>

            <InfoCircleSvg className="stop-loss-info-icon fill-gray-30 ml-[4px] min-w-[1.4rem] min-h-[1.4rem]" />
            <ReactTooltip
              className="gambit-react-tooltip !max-w-[32rem] !w-[32rem]"
              anchorSelect=".stop-loss-info-icon"
              noArrow={true}
              place="top"
            >
              <span className="text-[1.3rem] text-white">
                <Trans>
                  Stop-Loss is an order that automatically sells your asset when its price drops to a predetermined
                  level, limiting your loss.
                </Trans>
              </span>
            </ReactTooltip>
            {/* <Tooltip
              handle={<InfoCircleSvg className="fill-gray-30 ml-[4px] min-w-[1.4rem] min-h-[1.4rem]" />}
              disableHandleStyle={true}
              position="left-top"
              tooltipClassName="w-[30rem]"
              renderContent={() => (
                <span className="text-[1.3rem] text-white">
                  <Trans>
                    Stop-Loss is an order that automatically sells your asset when its price drops to a predetermined
                    level, limiting your loss.
                  </Trans>
                </span>
              )}
            /> */}
          </div>

          <div className={cx("whitespace-nowrap	text-red-2", "max-w-[12rem] xs:max-w-[15rem] overflow-x-auto")}>
            {displayedSlValue} {displayedSlValue !== t`None` && <span className="font-space-grotesk">{symbol}</span>}
          </div>
        </div>
        {/* Stop Loss Bottom */}
        <div className="flex w-full gap-[8px] heading9">
          <div className="flex w-full bg-black-2 p-[4px] rounded-[4px]">
            {SL_OPTIONS.map((sl) => {
              return (
                <div
                  onClick={() => {
                    setSlPrice("");
                    setSlPercent(sl.value);
                  }}
                  className={cx("flex-1 rounded-[2px] py-[2.5px] text-center cursor-pointer", {
                    "bg-gray-70 rounded-[2px]": sl.value === slPercent,
                    "font-space-grotesk": sl.value,
                  })}
                  key={sl.value}
                >
                  {i18n._(sl.text)}
                </div>
              );
            })}
          </div>
          <div
            className={cx(
              "rounded-[4px]",
              "border border-1 border-gray-40",
              "hover:bg-gradient-to-r hover:from-white/50 hover:to-white hover:border-0 hover:p-[1px]",
              {
                "bg-gradient-to-r from-white/50 to-white border-0 p-[1px]": focused.sl,
              }
            )}
          >
            <input
              type="text"
              pattern="^([0-9]+(?:[.,][0-9]*)?)$"
              inputMode="decimal"
              className="h-full font-space-grotesk text-center rounded-[3.6px] min-w-[6.7rem] max-w-[6.7rem] text-gray-05 placeholder:text-gray-05 bg-gray-90"
              placeholder={t({ id: `msg.sltp input PRICE`, message: `PRICE` })}
              value={slPrice}
              onFocus={(e) => {
                setFocused({ ...focused, sl: true });
                setSlPercent(null);
              }}
              onBlur={() => setFocused({ ...focused, sl: false })}
              onChange={(e) => {
                if (!/^([0-9]*(?:[.,][0-9]*)?)$/.test(e.target.value)) {
                  return;
                }
                setSlPrice(e.target.value);
              }}
            />
          </div>
        </div>
      </div>

      {/* Take Profit Box */}
      <div className="px-[1.6rem] pt-[1.2rem]">
        {/* Take Profit Top */}
        <div className="flex justify-between mb-[1.55rem] text-[1.4rem] font-medium">
          <div className="flex items-center text-gray-20 text-[1.4rem] font-medium">
            <Trans>Take Profit</Trans>
            <span className={cx("ml-[3.5px] text-green-2", "max-w-[10rem] xs:max-w-[12.5rem] overflow-x-auto")}>
              {displayedTpPriceFormat}
            </span>

            <InfoCircleSvg className="take-profit-info-icon fill-gray-30 ml-[4px] min-w-[1.4rem] min-h-[1.4rem]" />

            <ReactTooltip
              className="gambit-react-tooltip !max-w-[32rem] !w-[32rem]"
              anchorSelect=".take-profit-info-icon"
              noArrow={true}
              place="top"
            >
              <span className="text-[1.3rem] text-white">
                <Trans>
                  Take-Profit is an order that closes your position when the asset reaches a certain price, securing
                  your profit.
                </Trans>
              </span>
            </ReactTooltip>

            {/* <Tooltip
              handle={<InfoCircleSvg className="fill-gray-30 ml-[4px] min-w-[1.4rem] min-h-[1.4rem]" />}
              disableHandleStyle={true}
              position="left-top"
              tooltipClassName="w-[30rem]"
              renderContent={() => (
                <span className="text-[1.3rem] text-white">
                  <Trans>
                    Take-Profit is an order that closes your position when the asset reaches a certain price, securing
                    your profit.
                  </Trans>
                </span>
              )}
            /> */}
          </div>

          <div className={cx("whitespace-nowrap text-green-2", "max-w-[12rem] xs:max-w-[15rem] overflow-x-auto")}>
            {displayedTpValue} {displayedTpValue !== t`None` && <span className="font-space-grotesk">{symbol}</span>}
          </div>
        </div>

        {/* Take Profit Bottom */}
        <div className="flex w-full gap-[8px] heading9">
          <div className="flex w-full bg-black-2 p-[4px] rounded-[4px]">
            {TP_OPTIONS.map((tp) => {
              let disabled = false;
              if (leverageOption) {
                const tpPrice = getTpPrice(openPriceForSlTp, tp.value, leverageOption, buy);
                disabled = new BN(tpPrice).lte(0);
              }

              return (
                <div
                  onClick={() => {
                    if (disabled) return;
                    setTpPrice("");
                    setTpPercent(tp.value);
                  }}
                  className={cx(
                    "flex-1 rounded-[2px] py-[2.5px] text-center cursor-pointer",
                    {
                      "bg-gray-70 rounded-[2px]": tp.value === tpPercent,
                      "font-space-grotesk": tp.value,
                    },
                    { "bg-gray-80 text-gray-40 line-through cursor-not-allowed": disabled }
                  )}
                  key={tp.value}
                >
                  {tp.text}
                </div>
              );
            })}
          </div>
          <div
            className={cx(
              "rounded-[4px]",
              "border border-1 border-gray-40",
              "hover:bg-gradient-to-r hover:from-white/50 hover:to-white hover:border-0 hover:p-[1px]",
              {
                "bg-gradient-to-r from-white/50 to-white border-0 p-[1px]": focused.tp,
              }
            )}
          >
            <input
              type="text"
              pattern="^([0-9]+(?:[.,][0-9]*)?)$"
              inputMode="decimal"
              className="h-full font-space-grotesk text-center rounded-[3.6px] min-w-[6.7rem] max-w-[6.7rem] text-gray-05 placeholder:text-gray-05 bg-gray-90"
              placeholder={t({ id: `msg.sltp input PRICE`, message: `PRICE` })}
              value={tpPrice}
              onFocus={(e) => {
                setFocused({ ...focused, tp: true });
                setTpPercent(null);
              }}
              onBlur={(e) => {
                setFocused({ ...focused, tp: false });
              }}
              onChange={(e) => {
                if (!/^([0-9]*(?:[.,][0-9]*)?)$/.test(e.target.value)) {
                  return;
                }
                setTpPrice(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default StopLossAndTakeProfit;
