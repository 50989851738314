import { useMemo } from "react";
import { useConnect } from "wagmi";

const useWeb3AuthWallet = () => {
  const { connectors } = useConnect();

  const googleWeb3authConnector = useMemo(() => {
    return connectors.find(
      (connector) => connector.id === "web3auth" && connector.options.loginParams.loginProvider === "google"
    );
  }, [connectors]);

  const kakaoWeb3authConnector = useMemo(() => {
    return connectors.find(
      (connector) => connector.id === "web3auth" && connector.options.loginParams.loginProvider === "kakao"
    );
  }, [connectors]);

  const appleWeb3authConnector = useMemo(() => {
    return connectors.find(
      (connector) => connector.id === "web3auth" && connector.options.loginParams.loginProvider === "apple"
    );
  }, [connectors]);

  // const facebookWeb3authConnector = useMemo(() => {
  //   return connectors.find(
  //     (connector) => connector.id === "web3auth" && connector.options.loginParams.loginProvider === "facebook"
  //   );
  // }, [connectors]);

  const discordWeb3authConnector = useMemo(() => {
    return connectors.find(
      (connector) => connector.id === "web3auth" && connector.options.loginParams.loginProvider === "discord"
    );
  }, [connectors]);

  const twitterWeb3authConnector = useMemo(() => {
    return connectors.find(
      (connector) => connector.id === "web3auth" && connector.options.loginParams.loginProvider === "twitter"
    );
  }, [connectors]);

  const emailWeb3authConnector = useMemo(() => {
    return connectors.find(
      (connector) => connector.id === "web3auth" && connector.options.loginParams.loginProvider === "email_passwordless"
    );
  }, [connectors]);

  return {
    googleWeb3authConnector,
    kakaoWeb3authConnector,
    appleWeb3authConnector,
    discordWeb3authConnector,
    twitterWeb3authConnector,
    emailWeb3authConnector,
  };
};

export default useWeb3AuthWallet;
