import { Trans, t } from "@lingui/macro";
import cx from "classnames";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { ToastifyDebug } from "components/ToastifyDebug/ToastifyDebug";
import {
  getChainName,
  getConstant,
  getExplorerUrl,
  getLimitTimeLockMinutes,
  isSupportedChain,
} from "config/chains";
import { SELECTED_WALLET_LOCALSTORAGE_KEY } from "config/localStorage";
import { BigNumber, BigNumberish, Contract, ContractTransaction } from "ethers";
import { Pair, ParsedTrade } from "futures-domain/trades/types";
import { WALLET_TYPES } from "futures-domain/types";
import {
  showUnsupportedNetworkToast,
  switchNetwork,
} from "futures-lib/wallets";
import { ReactNode } from "react";
import { helperToast } from "../helperToast";
import {
  NETWORK_CHANGED,
  NOT_ENOUGH_FUNDS,
  SLIPPAGE,
  USER_DENIED,
  extractError,
} from "./transactionErrors";
import { getGasLimit, setGasPrice } from "./utils";

export interface Opts {
  value?: BigNumber | number;
  gasLimit?: BigNumber | number;
  sentMsg?: string;
  successMsg?: string | ReactNode;
  hideSuccessMsg?: boolean;
  failMsg?: string;
  setPendingTxns?: (txns: any) => void;
  pairs?: Pair[];
  pairIndex?: number;
  trade?: ParsedTrade;
  chainId?: number;
  isWeb3AuthAccount: boolean | undefined;
}

export interface OptionsT {
  setPendingTxns?: (txns: any) => void;
  hideSuccessMsg?: boolean;
  successMsg?: string | ReactNode;
  sentMsg?: string;
  failMsg?: string;
}

// NOTE: 필요 시 callContract -> callTypeChainContract 마이그레이션
// NOTE: 앞으로 추가하는 컨트랙트 인터렉션은 callTypeChainContract 으로 진행
export async function callContract(
  chainId: number,
  contract: Contract,
  method: string,
  params: any,
  opts: Opts
) {
  try {
    if (!isSupportedChain(chainId)) {
      const IsWalletConnectV2 =
        localStorage.getItem(SELECTED_WALLET_LOCALSTORAGE_KEY) ===
        WALLET_TYPES.WALLET_CONNECT_V2;
      showUnsupportedNetworkToast(IsWalletConnectV2, opts.isWeb3AuthAccount);
      return;
    }

    if (
      !Array.isArray(params) &&
      typeof params === "object" &&
      opts === undefined
    ) {
      opts = params;
      params = [];
    }

    if (!opts) {
      opts = {
        isWeb3AuthAccount: false,
      };
    }

    const txnOpts: TxOptions = {};

    if (opts.value) {
      txnOpts.value = opts.value;
    }

    txnOpts.gasLimit = opts.gasLimit
      ? opts.gasLimit
      : await getGasLimit(contract, method, params, opts.value);

    await setGasPrice(txnOpts, contract.provider, chainId);

    // console.log("before res");
    // console.log(method, params, txnOpts);
    const res = await contract[method](...params, txnOpts);

    // console.log(res);
    const txUrl = getExplorerUrl(chainId) + "tx/" + res.hash;
    const sentMsg = opts.sentMsg || t`Transaction Sent`;
    const pairs = opts.pairs;
    const pairIndex = opts.pairIndex;
    const pair = pairs?.find((pair) => pair.pairIndex === pairIndex);

    // helperToast.info(
    //   <div>
    //     <div className="text-[1.3rem]">Pending Trade</div>
    //     <div className="text-[1.5rem]">ICP/USD</div>
    //     <ExternalLink href={txUrl}>
    //       <Trans>View Status</Trans>
    //     </ExternalLink>
    //   </div>
    // );

    if (["closeTradeMarket", "openTrade"].includes(method)) {
      if (method === "openTrade" && params[1] === 0) {
        const [, pairIndex, , , , , buy] = params[0];

        // const pair = pairs?.find((pair) => pair.pairIndex === pairIndex);
        const toastId = `open:${buy}:${pair?.from}:${pair?.to}`;
        // console.log(`(openTrade) toastId: ${toastId}`);
        setTimeout(() => {
          helperToast.info(
            <div>
              <div className="toastify-title">
                <Trans>Opening Order</Trans>
              </div>
              <div>
                {pairs && pairIndex !== undefined && pair && (
                  <div className="toastify-body">
                    <span>
                      {pair.from}/{pair.to}
                    </span>
                    <span
                      className={cx(
                        buy ? "text-green-2" : "text-red-2",
                        "ml-[4px]"
                      )}
                    >
                      {buy ? t`LONG` : t`SHORT`}
                    </span>
                  </div>
                )}
              </div>
            </div>,
            {
              // autoClose: 7000,
              toastId,
            }
          );
        }, 200);
      } else if (method === "closeTradeMarket") {
        const buy = opts.trade?.buy;
        // const toastId = `${buy}:${pairs?.[opts.pairIndex!].from}:${pairs?.[opts.pairIndex!].to}`;
        const toastId = `close:${buy}:${pair?.from}:${pair?.to}`;
        // console.log(`(closeTradeMarket) toastId: ${toastId}`);

        setTimeout(() => {
          helperToast.info(
            <div>
              <div className="toastify-title">
                <Trans>Closing Order</Trans>
              </div>
              <div>
                {pairs && pairIndex !== undefined && pair && (
                  <div className="toastify-body">
                    <span>
                      {pair.from}/{pair.to}
                    </span>
                    <span
                      className={cx(
                        buy ? "text-green-2" : "text-red-2",
                        "ml-[4px]"
                      )}
                    >
                      {buy ? t`LONG` : t`SHORT`}
                    </span>
                  </div>
                )}
              </div>
            </div>,
            {
              // autoClose: 7000,
              toastId,
            }
          );
        }, 200);
      }
    }

    helperToast.success(
      <div>
        <div
          className={cx(
            pairs && pairIndex !== undefined && pair
              ? "toastify-title"
              : "toastify-body-only"
          )}
        >
          {sentMsg}
        </div>
        <div>
          {pairs && pairIndex !== undefined && pair && (
            <div className="toastify-body">
              {pair?.from}/{pair?.to}
            </div>
          )}
          <ExternalLink href={txUrl}>
            <Trans>View Status</Trans>
          </ExternalLink>
        </div>
      </div>,
      {
        autoClose: 7000,
      }
    );

    if (opts.setPendingTxns) {
      const message = opts.hideSuccessMsg
        ? undefined
        : opts.successMsg || t`Transaction completed`;
      const pendingTxn = {
        hash: res.hash,
        message,
      };
      opts.setPendingTxns((pendingTxns) => [...pendingTxns, pendingTxn]);
    }

    return res;
  } catch (e: any) {
    try {
      await contract.callStatic[method](...params, {});
    } catch (e) {
      handleContractError(e, chainId, opts, method);
      throw e;
    }

    handleContractError(e, chainId, opts, method);
  }
}

export const handleContractResult = (res: ContractTransaction, opts: Opts) => {
  const txUrl = opts.chainId
    ? getExplorerUrl(opts.chainId) + "tx/" + res.hash
    : "";
  const pair = opts.pairs?.find((pair) => pair.pairIndex === opts.pairIndex);
  const sentMsg = opts.sentMsg || t`Transaction Sent`;

  helperToast.success(
    <div>
      <div
        className={cx(
          opts.pairs && opts.pairIndex !== undefined && pair
            ? "toastify-title"
            : "toastify-body-only"
        )}
      >
        {sentMsg}
      </div>
      <div>
        {opts.pairs && opts.pairIndex !== undefined && pair && (
          <div className="toastify-body">
            {pair?.from}/{pair?.to}
          </div>
        )}
        <ExternalLink href={txUrl}>
          <Trans>View Status</Trans>
        </ExternalLink>
      </div>
    </div>,
    {
      autoClose: 7000,
    }
  );

  if (opts.setPendingTxns) {
    const message = opts.hideSuccessMsg
      ? undefined
      : opts.successMsg || t`Transaction completed`;
    const pendingTxn = {
      hash: res.hash,
      message,
    };
    opts.setPendingTxns((pendingTxns) => [...pendingTxns, pendingTxn]);
  }

  return res;
};

export interface TxOptions {
  gasLimit?: BigNumberish;
  value?: BigNumber | number;
  maxPriorityFeePerGas?: BigNumber;
  maxFeePerGas?: BigNumber;
  gasPrice?: BigNumber;
}

export const callTypeChainContract = async (
  chainId: number,
  // contractCall: any,
  contract: Contract,
  method: string,
  params: any,
  options: OptionsT,
  walletChainId: number | undefined,
  txOptions?: TxOptions,
  isWeb3AuthAccount?: boolean | undefined
) => {
  try {
    // const res = await contractCall(...params, txOptions);
    if (!isSupportedChain(walletChainId)) {
      const IsWalletConnectV2 =
        localStorage.getItem(SELECTED_WALLET_LOCALSTORAGE_KEY) ===
        WALLET_TYPES.WALLET_CONNECT_V2;
      showUnsupportedNetworkToast(IsWalletConnectV2, isWeb3AuthAccount);
      return;
    }

    let txOptions: TxOptions = {};
    // console.log(txOptions);
    // console.log(contract);
    // console.log(method);
    // console.log(params);
    txOptions.gasLimit = await getGasLimit(
      contract,
      method,
      params,
      txOptions.value ?? null
    );
    // console.log(txOptions);
    await setGasPrice(txOptions, contract.provider, chainId);

    const res = await contract[method](...params, txOptions);
    const txUrl = getExplorerUrl(chainId) + "tx/" + res.hash;

    helperToast.success(
      <div>
        <div className="toastify-body-only">{options.sentMsg}</div>
        <div>
          <ExternalLink href={txUrl}>
            <Trans>View Status</Trans>
          </ExternalLink>
        </div>
      </div>,
      {
        autoClose: 7000,
      }
    );

    // console.log(typeof options.successMsg);
    // console.log(typeof options.successMsg === "string");
    // console.log(typeof options.successMsg === "object");

    if (options.setPendingTxns) {
      const message = options.hideSuccessMsg
        ? undefined
        : options.successMsg || t`Transaction completed`;
      const pendingTxn = {
        hash: res.hash,
        message,
      };
      options.setPendingTxns((pendingTxns) => [...pendingTxns, pendingTxn]);
    }

    return res;
  } catch (e: any) {
    try {
      // console.log(e);
      await contract.callStatic[method](...params, txOptions);
    } catch (e) {
      // console.log(params);
      // console.log(e);
      handleContractError(e, chainId, {
        ...options,
        isWeb3AuthAccount,
      } as Opts);
      throw e;
    }
    handleContractError(e, chainId, { ...options, isWeb3AuthAccount } as Opts);
  }
};

export const handleContractError = (
  e: any,
  chainId: number,
  opts: Opts,
  method?: string
) => {
  let failMsg;

  let autoCloseToast: number | boolean = 5000;

  // const [message, type, errorData] = extractError(e);
  const [message, type] = extractError(e);
  // console.log(message);
  // console.log(type);
  // console.log(type);
  switch (type) {
    case NOT_ENOUGH_FUNDS:
      failMsg = (
        <Trans>
          {/* There is not enough ETH in your account on Arbitrum to send this transaction. */}
          There is not enough {getConstant(chainId, "nativeTokenSymbol")} in
          your account to send this transaction.
          {/* <br />
            <br />
            <ExternalLink href="https://arbitrum.io/bridge-tutorial/">Bridge ETH to Arbitrum</ExternalLink> */}
        </Trans>
      );
      break;
    case NETWORK_CHANGED:
      // * web3auth 연결 시 네트워크 변경 토스트 생략
      // * TODO: web3auth-wagmi-connector에서 네트워크 변경 지원하면 필요 여부 재검토
      if (opts.isWeb3AuthAccount) return;

      failMsg = (
        <div>
          <div className="toastify-title">
            <Trans>
              Your wallet is not connected to {getChainName(chainId)}.
            </Trans>
          </div>
          <div
            className="clickable underline toastify-body"
            onClick={() => switchNetwork(chainId, true, opts.isWeb3AuthAccount)}
          >
            <Trans>Switch to {getChainName(chainId)}</Trans>
          </div>
        </div>
      );
      break;
    case USER_DENIED:
      failMsg = (
        <div className="toastify-body-only">
          <Trans>Transaction was cancelled</Trans>
        </div>
      );
      break;
    case SLIPPAGE:
      failMsg = t`The mark price has changed, consider increasing your Allowed Slippage by clicking on the "..." icon next to your address`;
      break;

    // INFO: 여기서 RPC_ERROR를 잡으면 아래 default까지 내려가지 않아서 message로 에러를 잡을 수 없음
    // case RPC_ERROR:
    //   autoCloseToast = false;

    //   const originalError = errorData?.error?.message || errorData?.message || message;

    //   failMsg = (
    //     <div>
    //       <Trans>
    //         Transaction Failed due to RPC error.
    //         <br />
    //         <br />
    //         Please try changing the RPC url in your wallet settings.{" "}
    //         {/* <ExternalLink href="https://gmxio.gitbook.io/gmx/trading#backup-rpc-urls">More info</ExternalLink> */}
    //       </Trans>
    //       <br />
    //       {originalError && <ToastifyDebug>{originalError}</ToastifyDebug>}
    //     </div>
    //   );
    //   break;
    default:
      autoCloseToast = false;

      // if (message?.includes("nonce has already been used")) {
      //   throw e;
      // }

      if (message?.includes("Request of type 'transaction' already pending")) {
        failMsg = (
          <div>
            <div className="toastify-title">
              <Trans>Request of type 'transaction' already pending.</Trans>
            </div>
            {message && (
              <Trans>
                <ToastifyDebug>
                  Please reject all transactions waiting to be signed in your
                  wallet and make a new request.
                </ToastifyDebug>
              </Trans>
            )}
          </div>
        );
      } else if (message?.includes("user rejected transaction")) {
        autoCloseToast = 5000;
        failMsg = (
          <div>
            <div className="toastify-body-only">
              <Trans>User Rejected Transaction</Trans>
            </div>
          </div>
        );
      } else if (
        message?.includes("MaxTradesPerPair") ||
        message?.includes("MAX_TRADES_PER_PAIR")
      ) {
        failMsg = (
          <div>
            <div className="toastify-title">
              <Trans>Transaction Failed</Trans>
            </div>
            {message && (
              <Trans>
                <ToastifyDebug>
                  You have reached the maximum tradeable limit (OpenTrades +
                  OpenOrders) for that pair.
                </ToastifyDebug>
              </Trans>
            )}
          </div>
        );
      } else if (message?.includes("AlreadyBeingClosed")) {
        failMsg = (
          <div>
            <div className="toastify-title">
              <Trans>Already Being Closed</Trans>
            </div>
            {/* {message && (
              <Trans>
                <ToastifyDebug>
                  You can only change your TP/SL price about once {minutesTime}. Please try again in a few minutes.
                </ToastifyDebug>
              </Trans>
            )} */}
          </div>
        );
      } else if (
        (message?.includes("LimitTimelock") ||
          message?.includes("LIMIT_TIMELOCK")) &&
        (method === "updateSl" || method === "updateTp")
      ) {
        const _minutesTime = getLimitTimeLockMinutes(chainId); // 2 or 1
        const minutesTime =
          _minutesTime === 1 ? t`a minute` : t`${_minutesTime} minutes`;

        failMsg = (
          <div>
            <div className="toastify-title">
              <Trans>Transaction Failed</Trans>
            </div>
            {message && (
              <Trans>
                <ToastifyDebug>
                  You can only change your TP/SL price about once {minutesTime}.
                  Please try again in a few minutes.
                </ToastifyDebug>
              </Trans>
            )}
          </div>
        );
      } else if (
        message?.includes("LimitTimelock") ||
        message?.includes("LIMIT_TIMELOCK")
      ) {
        const _minutesTime = getLimitTimeLockMinutes(chainId); // 2 or 1
        const minutesTime =
          _minutesTime === 1 ? t`a minute` : t`${_minutesTime} minutes`;

        failMsg = (
          <div>
            <div className="toastify-title">
              <Trans>Transaction Failed</Trans>
            </div>
            {message && (
              <Trans>
                <ToastifyDebug>
                  Not enough time(approximately {minutesTime}) has passed since
                  the open order or TP/SL update transaction. Please try again
                  in a few minutes.
                </ToastifyDebug>
              </Trans>
            )}
          </div>
        );
      } else if (
        message?.includes("WaitTimeout") ||
        message?.includes("WAIT_TIMEOUT")
      ) {
        const _minutesTime = getLimitTimeLockMinutes(chainId); // 2 or 1
        const minutesTime =
          _minutesTime === 1 ? t`a minute` : t`${_minutesTime} minutes`;

        failMsg = (
          <div>
            <div className="toastify-title">
              <Trans>Transaction Failed</Trans>
            </div>
            {message && (
              <Trans>
                <ToastifyDebug>
                  Not enough time({minutesTime}) has passed since the open order
                  transaction. Please try again in a few minutes.
                </ToastifyDebug>
              </Trans>
            )}
          </div>
        );
      } else if (
        message?.includes("MaxPendingOrders") ||
        message?.includes("MAX_PENDING_ORDERS")
      ) {
        failMsg = (
          <div>
            <div className="toastify-title">
              <Trans>Transaction Failed</Trans>
            </div>
            {message && (
              <Trans>
                <ToastifyDebug>
                  Orders that are currently pending have reached their maximum.
                  Please wait until the pending order is completed in the Orders
                  tab, or cancel the pending order and try again.
                </ToastifyDebug>
              </Trans>
            )}
          </div>
        );
      } else if (
        message?.includes("max fee per gas less than block base fee")
      ) {
        failMsg = (
          <div>
            <div className="toastify-title">
              <Trans>Transaction Failed</Trans>
            </div>
            {message && (
              <Trans>
                <ToastifyDebug>
                  Please increase the gas price and try again.
                </ToastifyDebug>
              </Trans>
            )}
          </div>
        );
      } else if (message?.includes("NO_PENDING_REWARDS")) {
        failMsg = (
          <div>
            <div className="toastify-title">
              <Trans>Transaction Failed</Trans>
            </div>
            {message && (
              <Trans>
                <ToastifyDebug>No claimable rewards exist.</ToastifyDebug>
              </Trans>
            )}
          </div>
        );
      }
      // TODO: errorName 정규표현식으로 잘라서 보여주기
      // else if (message?.includes("errorName")) {
      //   failMsg = (
      //     <div>
      //       <div className="toastify-title">
      //         <Trans>Transaction Failed</Trans>
      //       </div>
      //       {message && (
      //         <Trans>
      //           <ToastifyDebug>No claimable rewards exist.</ToastifyDebug>
      //         </Trans>
      //       )}
      //     </div>
      //   );
      // }
      else {
        failMsg = (
          <div>
            <div className="toastify-title">
              {opts.failMsg || t`Transaction Failed`}
            </div>
            {message && <ToastifyDebug>{message}</ToastifyDebug>}
          </div>
        );
      }
  }

  helperToast.error(failMsg, { autoClose: autoCloseToast });
  throw e;
};

export const handleTradeApiError = (
  title: string,
  _message: string,
  chainId: number,
  customError: string
) => {
  let message = _message;

  if (customError === "Deadline") {
    message =
      "Due to network congestion, the transaction was unable to be processed within the specified deadline. Please try again later.";
  } else if (
    customError ===
    "Arithmetic operation underflowed or overflowed outside of an unchecked block"
  ) {
    message =
      "Due to network congestion, there is a delay in updating the latest price feed.";
  }

  const failMsg = (
    <div>
      <div className="toastify-title">
        <Trans>{title}</Trans>
      </div>

      <div className="toastify-body">{message}</div>
    </div>
  );

  helperToast.error(failMsg, { autoClose: 7000 });
};

export const sentRequest = (requestName: string, body: string) => {
  const toastId = `${requestName}:${new Date().getTime()}`;

  helperToast.success(
    <div>
      <div className="toastify-title">{`${requestName} Requested`}</div>

      {body && <div className="toastify-body">{body}</div>}
    </div>,
    {
      autoClose: 7000,
      toastId,
    }
  );

  return toastId;
};
