import { ApolloProvider } from "@apollo/client";
import {
  apolloClientAtom,
  connectedSubgraphUrlAtom,
  // currentBlockNumberAtom,
  subgraphConnectionErrorAtom,
} from "atoms";
import { SUBGRAPH_URLS } from "config/subgraph";
import { useChainId } from "futures-lib/chains";
import { getGraphClient } from "futures-lib/subgraph";
import { useAtom } from "jotai";
import { useEffect } from "react";
// import axios from "axios";

// const blockQuery = `
//   query getBlock {
//     _meta {
//       block {
//         number
//       }
//     }
//   }
// `;

// const BLOCK_DIFF = 10;

// chainId가 변경 됨에 따라서 graphQL endpoint도 변경되는 Provider
// chainId를 가져올 수 있도록 Web3ReactProvider(App.tsx) 밑에 별도 컴포넌트로 존재
const DynamicApolloProvider = ({ children }) => {
  const [apolloClient, setApolloClient] = useAtom(apolloClientAtom);
  const [connectedSubgraphUrl, setConnectedSubgraphUrl] = useAtom(connectedSubgraphUrlAtom);
  const { chainId } = useChainId();
  const [subgraphConnectionError, setSubgraphConnectionError] = useAtom(subgraphConnectionErrorAtom);
  // const connectedSubgraphBlockNumber = useAtomValue(connectedSubgraphBlockNumberAtom);
  // const currentBlockNumber = useAtomValue(currentBlockNumberAtom);

  /**
   * 1) 앱 마운트 시 subgraph 연결
   * 2) 현재 연결된 subgraph에서 에러 응답을 받았을 때 다른 subgraph에 연결
   * 3) chainId가 변경되면 해당 네트워크의 subgraph 주소 중에서 하나에 연결
   */
  useEffect(() => {
    let subgraphUrl: string;

    if (subgraphConnectionError) {
      const TARGET_SUBGRAPH_URLS = SUBGRAPH_URLS[chainId].filter((url) => ![connectedSubgraphUrl].includes(url));
      const targetIndex = Date.now() % TARGET_SUBGRAPH_URLS.length;

      subgraphUrl = TARGET_SUBGRAPH_URLS[targetIndex];
    } else {
      const TARGET_SUBGRAPH_URLS = SUBGRAPH_URLS[chainId];

      if (!TARGET_SUBGRAPH_URLS) return;

      const targetIndex = Date.now() % TARGET_SUBGRAPH_URLS.length;

      subgraphUrl = TARGET_SUBGRAPH_URLS[targetIndex];
    }

    if ((!apolloClient || subgraphConnectionError) && subgraphUrl) {
      setConnectedSubgraphUrl(subgraphUrl);
      setApolloClient(getGraphClient(chainId, subgraphUrl, setSubgraphConnectionError));
      setSubgraphConnectionError(false);
    }
  }, [
    apolloClient,
    chainId,
    connectedSubgraphUrl,
    setApolloClient,
    setConnectedSubgraphUrl,
    setSubgraphConnectionError,
    subgraphConnectionError,
  ]);

  // chainId가 변경되면 apolloClient를 null로 변경해서
  // 위에 있는 useEffect 로직에서 새로운 네트워크에 맞는 subgraph에 연결
  useEffect(() => {
    setApolloClient(null);
  }, [chainId, setApolloClient]);

  // const getBlocksFromSubgraphs = useCallback(async () => {
  //   if (!currentBlockNumber) return;
  //   // console.log("getBlocksFromSubgraphs");

  //   const blockNumbers = await Promise.all<number[]>(
  //     SUBGRAPH_URLS[chainId].map((url: string, index: number) => {
  //       return axios
  //         .post(url, {
  //           query: blockQuery,
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //         })
  //         .then((value) => {
  //           // console.log(value);
  //           const {
  //             data: {
  //               data: {
  //                 _meta: {
  //                   block: { number: blockNumber },
  //                 },
  //               },
  //             },
  //           } = value;
  //           return blockNumber;
  //         })
  //         .catch(() => {
  //           return 0;
  //         });
  //     })
  //   );

  //   // console.log(blockNumbers);

  //   // blockNumber가 BLOCK_DIFF 보다 큰 차이가 나는 경우 해당 서브그래프의 index를 null로 변경
  //   const validBlockNumbers = blockNumbers.map((blockNumber: number) =>
  //     currentBlockNumber < blockNumber + BLOCK_DIFF ? blockNumber : null
  //   );
  //   // console.log(validBlockNumbers);

  //   // validBlockNumbers가 null[]인지 확인
  //   // null[]이면 모든 서브그래프의 blockNumber가 현재 네트워크의 blockNumber와 비교했을 때
  //   // BLOCK_DIFF 보다 큰 차이가 난다는 뜻으로 동기화가 되지 않았음을 의미
  //   const syncSubgraphNotExist = validBlockNumbers.every((blockNumber) => blockNumber === null);

  //   let subgraphUrl: string;

  //   // if) syncSubgraphNotExist === true (모든 서브그래프의 blockNumber가 낮음 (동기화가 모두 안되어 있는 경우))
  //   // -> blockNumber가 가장 높은 서브그래프에 연결
  //   if (syncSubgraphNotExist) {
  //     // targetIndex는 blockNumber가 가장 높은 서브그래프의 index
  //     const targetIndex = validBlockNumbers.indexOf(Math.max(...blockNumbers));

  //     const TARGET_SUBGRAPH_URLS = SUBGRAPH_URLS[chainId];

  //     subgraphUrl = TARGET_SUBGRAPH_URLS[targetIndex];
  //   }
  //   // else) syncSubgraphNotExist === false (동기화된 서브그래프가 존재하는 경우)
  //   // -> valid subgraph[] 중 하나를 랜덤하게 연결
  //   else {
  //     const validIndexes = validBlockNumbers
  //       .map((blockNumber: number | null, index: number) => (blockNumber === null ? null : index))
  //       .filter((index: number | null) => index !== null);
  //     // console.log(validIndexes);
  //     const TARGET_SUBGRAPH_URLS = SUBGRAPH_URLS[chainId].filter((url: string, index: number) =>
  //       validIndexes.includes(index)
  //     );
  //     const targetIndex = Date.now() % TARGET_SUBGRAPH_URLS.length;

  //     subgraphUrl = TARGET_SUBGRAPH_URLS[targetIndex];
  //   }

  //   if (subgraphUrl) {
  //     setConnectedSubgraphUrl(subgraphUrl);
  //     setApolloClient(getGraphClient(chainId, subgraphUrl, setSubgraphConnectionError));
  //     setSubgraphConnectionError(false);
  //   }
  // }, [chainId, currentBlockNumber, setApolloClient, setConnectedSubgraphUrl, setSubgraphConnectionError]);

  /**
   * 현재 연결된 서브그래프의 동기화가 일정 수준 이하(10 block 초과 차이)라면
   * 동기화가 높은 쪽으로 재연결
   */
  // useEffect(() => {
  //   if (!currentBlockNumber || !connectedSubgraphBlockNumber) return;

  //   if (currentBlockNumber > connectedSubgraphBlockNumber + BLOCK_DIFF) {
  //     getBlocksFromSubgraphs();
  //   }
  // }, [connectedSubgraphBlockNumber, currentBlockNumber, getBlocksFromSubgraphs]);

  return apolloClient ? <ApolloProvider client={apolloClient}>{children}</ApolloProvider> : <></>;
};

export default DynamicApolloProvider;
