import {
  isVisibleEditLiqPriceModalAtom,
  isVisibleSlTpModalAtom,
  pairsAtom,
  pairsPricesAtom,
  pendingTxnsAtom,
  selectedTradeAtom,
} from "atoms/exchange";
import ModalSkeletonWithPortal from "components/Modal/ModalSkeletonWithPortal";
import { BigNumber, ContractTransaction, ethers } from "ethers";
import { useChainId } from "futures-lib/chains";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useCallback, useEffect, useMemo, useState } from "react";

import { GambitTradingV1Facet3 } from "@changerio/futures-contracts/dist/typechain-types";
import { EIP712_TYPES_BY_FUNCTION } from "@changerio/futures-contracts/lib/eip-712-types";
import { formatUnits, parseUnits } from "@ethersproject/units";
import { Trans, t } from "@lingui/macro";
import pendingAnimation from "animation/pending.json";
import axios from "axios";
import BN from "bignumber.js";
import cx from "classnames";
import {
  GAMBIT_USD_DECIMALS,
  MAX_GAIN_P,
  MAX_SL_P,
  getAPIUrl,
  getTradeDeadline,
} from "components/Exchange/constants";
import {
  IUpdateSlApiFailResponse,
  IUpdateSlRequest,
  IUpdateTpApiFailResponse,
  IUpdateTpRequest,
} from "futures-domain/trades/api-types";
import {
  comma1000,
  getSalt,
  getSlPercent,
  getTpPercent,
  getTpPrice,
  trimPriceBN,
  trimPriceString,
} from "futures-domain/trades/utils";
import {
  Opts,
  handleContractResult,
  handleTradeApiError,
} from "futures-lib/contracts";
import { useGetTypedDomain } from "futures-lib/contracts/contract";
import { helperToast } from "futures-lib/helperToast";
import useWallet from "futures-lib/wallets/useWallet";
import Lottie from "lottie-react";
import { usePrevious } from "react-use";

import * as Sentry from "@sentry/react";
import { handleApiResult } from "components/Exchange/TradeBoxV2";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { sentryCaptureException } from "utils/sentry";

interface IsTxsubmitting {
  updateSl: boolean;
  updateTp: boolean;
}

const initialIsTxsubmitting = {
  updateSl: false,
  updateTp: false,
};

export const UpdateSlTpModal = () => {
  const selectedTrade = useAtomValue(selectedTradeAtom);
  const prevSelectedTrade = usePrevious(selectedTrade);

  const setIsEditLiqPriceVisible = useSetAtom(isVisibleEditLiqPriceModalAtom);

  const [isVisible, setIsVisible] = useAtom(isVisibleSlTpModalAtom);
  const [slPrice, setSlPrice] = useState("");
  const [tpPrice, setTpPrice] = useState("");
  const [isFocusedSlPrice, setIsFocusedSlPrice] = useState(false);
  const [isFocusedTpPrice, setIsFocusedTpPrice] = useState(false);

  const { chainId } = useChainId();
  const { signer, account } = useWallet();
  const setPendingTxns = useSetAtom(pendingTxnsAtom);
  const pairsPrices = useAtomValue(pairsPricesAtom);

  const [isTxSubmitting, setIsTxSubmitting] = useState<IsTxsubmitting>(
    initialIsTxsubmitting
  );

  const selectedTradeParsedCurrentPrice = useMemo(() => {
    if (!pairsPrices[selectedTrade?.pair.priceFeedId!]) return;

    const { price, expo } = pairsPrices[selectedTrade?.pair.priceFeedId!];
    return parseUnits(
      formatUnits(BigNumber.from(price), Math.abs(expo)),
      GAMBIT_USD_DECIMALS
    );
  }, [pairsPrices, selectedTrade?.pair]);

  const disabledUpdateSl = useMemo(() => {
    if (!selectedTrade?.sl) return true;

    if (!slPrice) return true;

    if ((+trimPriceBN(selectedTrade?.sl)).toString() === slPrice) {
      return true;
    }

    return false;
  }, [selectedTrade?.sl, slPrice]);

  const disabledUpdateTp = useMemo(() => {
    if (!selectedTrade?.tp) return true;

    if (!tpPrice) return true;

    if ((+trimPriceBN(selectedTrade?.tp)).toString() === tpPrice) {
      return true;
    }

    return false;
  }, [selectedTrade?.tp, tpPrice]);

  useEffect(() => {
    if (isVisible) {
      if (
        (selectedTrade?.sl && slPrice === "" && !isFocusedSlPrice) ||
        (selectedTrade?.sl &&
          prevSelectedTrade?.sl.toString() !== selectedTrade.sl.toString())
      ) {
        setSlPrice((+trimPriceBN(selectedTrade?.sl)).toString());
      }
      if (
        (selectedTrade?.tp && tpPrice === "" && !isFocusedTpPrice) ||
        (selectedTrade?.tp &&
          prevSelectedTrade?.tp.toString() !== selectedTrade.tp.toString())
      ) {
        setTpPrice((+trimPriceBN(selectedTrade?.tp)).toString());
      }
    } else {
      setTpPrice("");
      setSlPrice("");
    }
  }, [
    isFocusedSlPrice,
    isFocusedTpPrice,
    isVisible,
    prevSelectedTrade?.sl,
    prevSelectedTrade?.tp,
    selectedTrade?.sl,
    selectedTrade?.tp,
    slPrice,
    tpPrice,
  ]);

  const pairs = useAtomValue(pairsAtom);
  // const tradingContract = useGetContract("GambitTradingV1") as GambitTradingV1;

  const updateSlOpts = useMemo(() => {
    const successMsg = t`Stop Loss Price updated`;
    const sentMsg = t`Stop Loss Price Update Requested`;
    const failMsg = t`Stop Loss Price Update Failed`;

    return {
      sentMsg,
      failMsg,
      successMsg,
      pairs,
      chainId,
    } as Opts;
  }, [chainId, pairs]);

  const checkSlValidation = useCallback(() => {
    if (!selectedTrade) return;

    const parsedSlPrice = parseUnits(slPrice, GAMBIT_USD_DECIMALS);

    // uint maxSlDist = (t.openPrice * MAX_SL_P) / 100 / t.leverage;
    // const maxSlDist = selectedTrade.openPrice
    //   .mul(BigNumber.from(MAX_SL_P))
    //   .div(BigNumber.from(100))
    //   .div(BigNumber.from(selectedTrade.leverage));
    // console.log(`maxSlDist: ${maxSlDist}`);

    const maxSlDist = BigNumber.from(
      new BN(selectedTrade.openPrice.toString())
        .multipliedBy(MAX_SL_P)
        .dividedBy(100)
        .dividedBy(selectedTrade.leverage)
        .toFixed(0)
    );
    // console.log(`_maxSlDist: ${_maxSlDist}`);

    if (!parsedSlPrice.eq(ethers.constants.Zero)) {
      // INFO: SL input 가이드
      // INFO: LONG인 경우
      if (selectedTrade.buy && selectedTradeParsedCurrentPrice) {
        // case 1) SL input이 0보다 작거나 t.openPrice - maxSlDist보다 작은 경우
        if (
          parsedSlPrice.lt(0) ||
          parsedSlPrice.lt(selectedTrade.openPrice.sub(maxSlDist))
        ) {
          helperToast.error(
            <div className="toastify-body-only">
              {t`Stop Loss Wrong, It Must Be >= ${comma1000(
                trimPriceBN(selectedTrade.openPrice.sub(maxSlDist))
              )}`}
            </div>,
            { autoClose: 7000 }
          );
          setIsTxSubmitting({
            ...isTxSubmitting,
            updateSl: false,
          });
          return false;
        }
        // case 2) SL input이 현재가격보다 큰 경우
        if (parsedSlPrice.gt(selectedTradeParsedCurrentPrice)) {
          helperToast.error(
            <div className="toastify-body-only">
              {t`Stop Loss Wrong, It Must Be <= ${comma1000(
                trimPriceBN(selectedTradeParsedCurrentPrice)
              )}`}
            </div>,
            { autoClose: 7000 }
          );
          setIsTxSubmitting({
            ...isTxSubmitting,
            updateSl: false,
          });
          return false;
        }
      }
      // INFO: SHORT인 경우
      else if (!selectedTrade.buy && selectedTradeParsedCurrentPrice) {
        // case 1) SL input이 t.openPrice + maxSlDist보다 큰 경우
        if (parsedSlPrice.gt(selectedTrade.openPrice.add(maxSlDist))) {
          helperToast.error(
            <div className="toastify-body-only">
              {t`Stop Loss Wrong, It Must Be <= ${comma1000(
                trimPriceBN(selectedTrade.openPrice.add(maxSlDist))
              )}`}
            </div>,
            { autoClose: 7000 }
          );
          setIsTxSubmitting({
            ...isTxSubmitting,
            updateSl: false,
          });
          return false;
        }
        // case 2) SL input이 0보다 작거나 현재가격보다 작은 경우
        if (
          parsedSlPrice.lt(0) ||
          parsedSlPrice.lt(selectedTradeParsedCurrentPrice)
        ) {
          helperToast.error(
            <div className="toastify-body-only">
              {t`Stop Loss Wrong, It Must Be >= ${comma1000(
                trimPriceBN(selectedTradeParsedCurrentPrice)
              )}`}
            </div>,
            { autoClose: 7000 }
          );
          setIsTxSubmitting({
            ...isTxSubmitting,
            updateSl: false,
          });
          return false;
        }
      }
    }

    return true;
  }, [isTxSubmitting, selectedTrade, selectedTradeParsedCurrentPrice, slPrice]);

  const { tradingContractTypedDomain } = useGetTypedDomain();

  const updateTpOpts = useMemo(() => {
    const successMsg = t`Take Profit Price Updated`;
    const sentMsg = t`Take Profit Price Update Requested`;
    const failMsg = t`Take Profit Price Update Failed`;

    return {
      sentMsg,
      failMsg,
      successMsg,
      pairs,
      chainId,
      setPendingTxns,
    } as Opts;
  }, [chainId, pairs, setPendingTxns]);

  // const { data: delegationFeeThresholdMultiplier } = useSWR<BigNumber>(
  //   tradingContract && [tradingContract, "delegationFeeThresholdMultiplier", []],
  //   {
  //     fetcher: newContractFetcher,
  //     shouldRetryOnError: true,
  //     errorRetryInterval: 500,
  //     refreshInterval: 1000,
  //   }
  // );

  // const delegationFeeThreshhold = useDelegationFeeThreshhold(
  //   selectedTrade?.pair?.fee.minLevPosUsdc,
  //   delegationFeeThresholdMultiplier
  // );

  // const fromTokenAddress = getContract(chainId, "CollateralToken");
  // const fromToken = getToken(chainId, fromTokenAddress);

  const updateSlApiCall = useCallback(async () => {
    if (!selectedTrade || !signer || !account) return;

    setIsTxSubmitting({
      ...isTxSubmitting,
      updateSl: true,
    });

    const parsedSlPrice = parseUnits(slPrice, GAMBIT_USD_DECIMALS);

    const valid = checkSlValidation();
    if (!valid) {
      return;
    }

    const deadline = getTradeDeadline(chainId);

    const params: GambitTradingV1Facet3.UpdateSlParamsStruct = {
      pairIndex: selectedTrade?.pairIndex,
      index: selectedTrade?.positionIndex,
      newSl: parsedSlPrice.toString(),
      deadline: deadline.toString(),
      salt: getSalt(),
    };

    try {
      const signature = await signer._signTypedData(
        tradingContractTypedDomain,
        EIP712_TYPES_BY_FUNCTION.updateSl,
        params
      );

      try {
        const { data } = await axios.post<string | IUpdateSlApiFailResponse>(
          `${getAPIUrl(
            chainId
          )}/v1/gasless/trade/sl?trader=${account}&signature=${signature}`,
          params as IUpdateSlRequest
        );
        let status, hash, code;

        // * data가 string이 아니면 API 호출 실패한 것
        // * API 성공 / 실패 시 응답 형태가 달라서
        if (typeof data !== "string") {
          status = data.status;
          code = data.code;
        }
        // * data가 string이 아니면 API 호출 성공 케이스
        else {
          hash = data;
          status = 200;
        }

        if (status === 200) {
          handleContractResult({ hash } as ContractTransaction, updateSlOpts);
          setIsVisible(false);
        } else {
          handleApiResult(status, code);
        }
      } catch (e: any) {
        // * API 에러 처리
        const { response } = e;

        const { data } = response;

        handleTradeApiError(
          data.name || "something went wrong",
          data.details?.customError
            ? `${data.message}(${data.details?.customError})`
            : data.message || "something went wrong",
          chainId,
          data.details?.customError ? data.details?.customError : ""
        );

        Sentry.setContext("/gasless/trade/sl API Request Params", {
          signature,
          params,
        });

        Sentry.setContext("/gasless/trade/sl API Error Response", response);

        sentryCaptureException({
          error: new Error("/gasless/trade/sl API Error"),
          name: "Error Object",
          context: e,
        });
      }
    } catch (e: any) {
      // * 서명 에러 처리
      sentryCaptureException({
        error: new Error("Sign Error in updateSlApiCall"),
        name: "Error Object",
        context: e,
      });
    } finally {
      setIsTxSubmitting({
        ...isTxSubmitting,
        updateSl: false,
      });
    }
  }, [
    account,
    chainId,
    checkSlValidation,
    isTxSubmitting,
    selectedTrade,
    setIsVisible,
    signer,
    slPrice,
    tradingContractTypedDomain,
    updateSlOpts,
  ]);

  // const updateSlContractCall = useCallback(async () => {
  //   if (!selectedTrade || !signer || !account) return;

  //   setIsTxSubmitting({
  //     ...isTxSubmitting,
  //     updateSl: true,
  //   });

  //   const parsedSlPrice = parseUnits(slPrice, GAMBIT_USD_DECIMALS);

  //   const valid = checkSlValidation();
  //   if (!valid) {
  //     return;
  //   }

  //   const params: GambitTradingV1Facet3.UpdateSlParamsStruct = {
  //     pairIndex: selectedTrade?.pairIndex,
  //     index: selectedTrade?.positionIndex,
  //     newSl: parsedSlPrice,
  //     deadline: ethers.constants.MaxUint256,
  //     salt: randomBytes(32),
  //   };

  //   const updateSlParams: [string, GambitTradingV1Facet3.UpdateSlParamsStruct, string, string] = [
  //     account,
  //     params,
  //     "0x",
  //     "0x",
  //   ];

  //   try {
  //     const res = await tradingContract.updateSl(...updateSlParams);
  //     handleContractResult(res, updateSlOpts);
  //     setIsVisible(false);
  //   } catch (e) {
  //     try {
  //       await tradingContract.callStatic.updateSl(...updateSlParams, {} as TxOptions);
  //     } catch (e) {
  //       handleContractError(e, chainId, updateSlOpts, "updateSl");
  //       throw e;
  //     }
  //     handleContractError(e, chainId, updateSlOpts, "updateSl");
  //   } finally {
  //     setIsTxSubmitting({
  //       ...isTxSubmitting,
  //       updateSl: false,
  //     });
  //   }
  // }, [
  //   selectedTrade,
  //   signer,
  //   account,
  //   isTxSubmitting,
  //   slPrice,
  //   checkSlValidation,
  //   tradingContract,
  //   updateSlOpts,
  //   setIsVisible,
  //   chainId,
  // ]);

  const updateSl = useCallback(() => {
    // if (!selectedTrade || !delegationFeeThreshhold) return;
    // const collateral = formatUnits(selectedTrade.positionSizeUsdc, fromToken.decimals);
    // const leverage = selectedTrade.leverage;
    // const positionSize = new BN(collateral).multipliedBy(leverage).toNumber();

    // * 포지션 사이즈와 상관없이 gasless
    updateSlApiCall();

    // * 포지션 사이즈에 따라 gasless 분기
    // if (+positionSize >= delegationFeeThreshhold) {
    //   // console.log("updateSlApiCall");
    //   updateSlApiCall();
    // } else {
    //   // console.log("updateSlContractCall");
    //   updateSlContractCall();
    // }
  }, [updateSlApiCall]);

  // * Legacy
  // const updateTp = useCallback(() => {
  //   if (!selectedTrade || !signer) return;

  //   setIsTxSubmitting({
  //     ...isTxSubmitting,
  //     updateTp: true,
  //   });

  //   const parsedTpPrice = parseUnits(tpPrice, GAMBIT_USD_DECIMALS);

  //   // machine readable
  //   const parsedTp900Price = getTpPrice(
  //     formatUnits(selectedTrade.openPrice, GAMBIT_USD_DECIMALS),
  //     MAX_GAIN_P.toString(),
  //     selectedTrade.leverage,
  //     selectedTrade.buy
  //   );
  //   // console.log(parsedTp900Price);
  //   const tp900Price = trimPriceString(formatUnits(parsedTp900Price, GAMBIT_USD_DECIMALS));

  //   // TP input 가이드
  //   // LONG인 경우
  //   if (selectedTrade.buy && selectedTradeParsedCurrentPrice) {
  //     // case 1) TP input이 0보다 작거나 현재가격보다 작거나 같은 경우
  //     if (parsedTpPrice.lt(0) || parsedTpPrice.lte(selectedTradeParsedCurrentPrice)) {
  //       helperToast.error(
  //         <div className="toastify-body-only">
  //           {t`Take Profit Wrong, It Must Be >= ${comma1000(trimPriceBN(selectedTradeParsedCurrentPrice))}`}
  //         </div>,
  //         { autoClose: 7000 }
  //       );
  //       setIsTxSubmitting({
  //         ...isTxSubmitting,
  //         updateTp: false,
  //       });
  //       return;
  //     }
  //     // case 2) TP input이 900% 보다 크거나 openPrice 보다 작은 경우
  //     else if (parsedTpPrice.gt(parsedTp900Price) || parsedTpPrice.lte(selectedTrade.openPrice)) {
  //       helperToast.error(
  //         <div className="toastify-body-only">
  //           {/* {t`Take Profit Wrong, It Must Be <= ${formatAmount(parsedTp900Price, GAMBIT_USD_DECIMALS, 3, true)}`} */}
  //           {t`Take Profit Wrong, It Must Be Greater Than ${trimPriceBN(
  //             selectedTrade.openPrice
  //           )} and Less than ${tp900Price}.`}
  //         </div>,
  //         { autoClose: 7000 }
  //       );
  //       setIsTxSubmitting({
  //         ...isTxSubmitting,
  //         updateTp: false,
  //       });
  //       return;
  //     }
  //   }
  //   // SHORT인 경우
  //   else if (!selectedTrade.buy && selectedTradeParsedCurrentPrice) {
  //     // case 1) TP input이 현재가격보다 크거나 같은 경우
  //     if (parsedTpPrice.gte(selectedTradeParsedCurrentPrice)) {
  //       helperToast.error(
  //         <div className="toastify-body-only">
  //           {t`Take Profit Wrong, It Must Be <= ${comma1000(trimPriceBN(selectedTradeParsedCurrentPrice))}`}
  //         </div>,
  //         { autoClose: 7000 }
  //       );
  //       setIsTxSubmitting({
  //         ...isTxSubmitting,
  //         updateTp: false,
  //       });
  //       return;
  //     }
  //     // case 2) TP input이 0보다 작거나 같거나 900% price 보다 작은 경우
  //     else if (parsedTpPrice.lte(0) || parsedTpPrice.lt(parsedTp900Price)) {
  //       // console.log(parsedTp900Price);
  //       helperToast.error(
  //         <div className="toastify-body-only">{t`Take Profit Wrong, It Must Be > ${tp900Price}`}</div>,
  //         { autoClose: 7000 }
  //       );
  //       setIsTxSubmitting({
  //         ...isTxSubmitting,
  //         updateTp: false,
  //       });
  //       return;
  //     }
  //   }

  //   let params = [selectedTrade?.pairIndex, selectedTrade?.positionIndex, parsedTpPrice];

  //   const contractAddress = getContract(chainId, "Trading");
  //   const contract = new ethers.Contract(contractAddress, getAbi(chainId, "Trading").abi, signer);
  //   const successMsg = t`Take Profit Price Updated`;

  //   callContract(
  //     chainId,
  //     contract,
  //     "updateTp",
  //     params,
  //     {
  //       value: bigNumberify(0),
  //       setPendingTxns,
  //       sentMsg: t`Take Profit Price Update Requested`,
  //       failMsg: t`Take Profit Price Update Failed`,
  //       successMsg,
  //     },
  //     walletChainId
  //   )
  //     .then(() => {
  //       setIsVisible(false);
  //     })
  //     .finally(() => {
  //       setIsTxSubmitting({
  //         ...isTxSubmitting,
  //         updateTp: false,
  //       });
  //     });
  // }, [
  //   chainId,
  //   isTxSubmitting,
  //   signer,
  //   selectedTrade,
  //   selectedTradeParsedCurrentPrice,
  //   setIsVisible,
  //   setPendingTxns,
  //   tpPrice,
  //   walletChainId,
  // ]);

  const checkTpValidation = useCallback(() => {
    if (!selectedTrade) return;

    const parsedTpPrice = parseUnits(tpPrice, GAMBIT_USD_DECIMALS);

    // machine readable
    const parsedTp900Price = getTpPrice(
      formatUnits(selectedTrade.openPrice, GAMBIT_USD_DECIMALS),
      MAX_GAIN_P.toString(),
      selectedTrade.leverage,
      selectedTrade.buy
    );
    // console.log(parsedTp900Price);
    const tp900Price = trimPriceString(
      formatUnits(parsedTp900Price, GAMBIT_USD_DECIMALS)
    );

    // TP input 가이드
    // LONG인 경우
    if (selectedTrade.buy && selectedTradeParsedCurrentPrice) {
      // case 1) TP input이 0보다 작거나 현재가격보다 작거나 같은 경우
      if (
        parsedTpPrice.lt(0) ||
        parsedTpPrice.lte(selectedTradeParsedCurrentPrice)
      ) {
        helperToast.error(
          <div className="toastify-body-only">
            {t`Take Profit Wrong, It Must Be >= ${comma1000(
              trimPriceBN(selectedTradeParsedCurrentPrice)
            )}`}
          </div>,
          { autoClose: 7000 }
        );
        setIsTxSubmitting({
          ...isTxSubmitting,
          updateTp: false,
        });
        return false;
      }
      // case 2) TP input이 900% 보다 크거나 openPrice 보다 작은 경우
      else if (
        parsedTpPrice.gt(parsedTp900Price) ||
        parsedTpPrice.lte(selectedTrade.openPrice)
      ) {
        helperToast.error(
          <div className="toastify-body-only">
            {/* {t`Take Profit Wrong, It Must Be <= ${formatAmount(parsedTp900Price, GAMBIT_USD_DECIMALS, 3, true)}`} */}
            {t`Take Profit Wrong, It Must Be Greater Than ${trimPriceBN(
              selectedTrade.openPrice
            )} and Less than ${tp900Price}.`}
          </div>,
          { autoClose: 7000 }
        );
        setIsTxSubmitting({
          ...isTxSubmitting,
          updateTp: false,
        });
        return false;
      }
    }
    // SHORT인 경우
    else if (!selectedTrade.buy && selectedTradeParsedCurrentPrice) {
      // case 1) TP input이 현재가격보다 크거나 같은 경우
      if (parsedTpPrice.gte(selectedTradeParsedCurrentPrice)) {
        helperToast.error(
          <div className="toastify-body-only">
            {t`Take Profit Wrong, It Must Be <= ${comma1000(
              trimPriceBN(selectedTradeParsedCurrentPrice)
            )}`}
          </div>,
          { autoClose: 7000 }
        );
        setIsTxSubmitting({
          ...isTxSubmitting,
          updateTp: false,
        });
        return false;
      }
      // case 2) TP input이 0보다 작거나 같거나 900% price 보다 작은 경우
      else if (parsedTpPrice.lte(0) || parsedTpPrice.lt(parsedTp900Price)) {
        // console.log(parsedTp900Price);
        helperToast.error(
          <div className="toastify-body-only">{t`Take Profit Wrong, It Must Be > ${tp900Price}`}</div>,
          { autoClose: 7000 }
        );
        setIsTxSubmitting({
          ...isTxSubmitting,
          updateTp: false,
        });
        return false;
      }
    }

    return true;
  }, [isTxSubmitting, selectedTrade, selectedTradeParsedCurrentPrice, tpPrice]);

  const updateTpApiCall = useCallback(async () => {
    if (!selectedTrade || !signer || !account) return;

    setIsTxSubmitting({
      ...isTxSubmitting,
      updateTp: true,
    });

    const valid = checkTpValidation();
    if (!valid) return;

    const parsedTpPrice = parseUnits(tpPrice, GAMBIT_USD_DECIMALS);

    const deadline = getTradeDeadline(chainId);

    const params: GambitTradingV1Facet3.UpdateTpParamsStruct = {
      pairIndex: selectedTrade?.pairIndex,
      index: selectedTrade?.positionIndex,
      newTp: parsedTpPrice.toString(),
      deadline: deadline.toString(),
      salt: getSalt(),
    };

    try {
      const signature = await signer._signTypedData(
        tradingContractTypedDomain,
        EIP712_TYPES_BY_FUNCTION.updateTp,
        params
      );

      try {
        const { data } = await axios.post<string | IUpdateTpApiFailResponse>(
          `${getAPIUrl(
            chainId
          )}/v1/gasless/trade/tp?trader=${account}&signature=${signature}`,
          params as IUpdateTpRequest
        );
        // console.log(hash);

        let status, hash, code;

        // * data가 string이 아니면 API 호출 실패한 것
        // * API 성공 / 실패 시 응답 형태가 달라서
        if (typeof data !== "string") {
          status = data.status;
          code = data.code;
        }
        // * data가 string이 아니면 API 호출 성공 케이스
        else {
          hash = data;
          status = 200;
        }

        if (status === 200) {
          handleContractResult({ hash } as ContractTransaction, updateTpOpts);
          setIsVisible(false);
        } else {
          handleApiResult(status, code);
        }
      } catch (e: any) {
        // * API 에러 처리
        const { response } = e;

        const { data } = response;

        handleTradeApiError(
          data.name || "something went wrong",
          data.details?.customError
            ? `${data.message}(${data.details?.customError})`
            : data.message || "something went wrong",
          chainId,
          data.details?.customError ? data.details?.customError : ""
        );

        Sentry.setContext("/gasless/trade/tp API Request Params", {
          signature,
          params,
        });

        Sentry.setContext("/gasless/trade/tp API Error Response", response);

        sentryCaptureException({
          error: new Error("/gasless/trade/tp API Error"),
          name: "Error Object",
          context: e,
        });
      }
    } catch (e: any) {
      // * 서명 에러 처리
      sentryCaptureException({
        error: new Error("Sign Error in updateTpApiCall"),
        name: "Error Object",
        context: e,
      });
    } finally {
      setIsTxSubmitting({
        ...isTxSubmitting,
        updateTp: false,
      });
    }
  }, [
    account,
    chainId,
    checkTpValidation,
    isTxSubmitting,
    selectedTrade,
    setIsVisible,
    signer,
    tpPrice,
    tradingContractTypedDomain,
    updateTpOpts,
  ]);

  // const updateTpContractCall = useCallback(async () => {
  //   if (!selectedTrade || !signer || !account) return;

  //   setIsTxSubmitting({
  //     ...isTxSubmitting,
  //     updateTp: true,
  //   });

  //   checkTpValidation();

  //   const parsedTpPrice = parseUnits(tpPrice, GAMBIT_USD_DECIMALS);

  //   const params: GambitTradingV1Facet3.UpdateTpParamsStruct = {
  //     pairIndex: selectedTrade?.pairIndex,
  //     index: selectedTrade?.positionIndex,
  //     newTp: parsedTpPrice,
  //     deadline: ethers.constants.MaxUint256,
  //     salt: randomBytes(32),
  //   };

  //   const updateTpParams: [string, GambitTradingV1Facet3.UpdateTpParamsStruct, string] = [account, params, "0x"];

  //   try {
  //     const res = await tradingContract.updateTp(...updateTpParams);
  //     handleContractResult(res, updateTpOpts);
  //     setIsVisible(false);
  //   } catch (e) {
  //     try {
  //       await tradingContract.callStatic.updateTp(...updateTpParams, {} as TxOptions);
  //     } catch (e) {
  //       handleContractError(e, chainId, updateTpOpts, "updateTp");
  //       throw e;
  //     }
  //     handleContractError(e, chainId, updateTpOpts, "updateTp");
  //   } finally {
  //     setIsTxSubmitting({
  //       ...isTxSubmitting,
  //       updateTp: false,
  //     });
  //   }
  // }, [
  //   selectedTrade,
  //   signer,
  //   account,
  //   isTxSubmitting,
  //   checkTpValidation,
  //   tpPrice,
  //   tradingContract,
  //   updateTpOpts,
  //   setIsVisible,
  //   chainId,
  // ]);

  const updateTp = useCallback(() => {
    // if (!selectedTrade || !delegationFeeThreshhold) return;
    // const collateral = formatUnits(selectedTrade.positionSizeUsdc, fromToken.decimals);
    // const leverage = selectedTrade.leverage;
    // const positionSize = new BN(collateral).multipliedBy(leverage).toNumber();

    // * 포지션 사이즈와 상관없이 gasless
    updateTpApiCall();

    // * 포지션 사이즈에 따라 gasless 분기
    // if (+positionSize >= delegationFeeThreshhold) {
    //   // console.log("updateTpApiCall");
    //   updateTpApiCall();
    // } else {
    //   // console.log("updateTpContractCall");
    //   updateTpContractCall();
    // }
  }, [updateTpApiCall]);

  const updatingSlPercent = useMemo(() => {
    if (!selectedTrade || !slPrice) return;
    if (selectedTrade.sl.isZero() && new BN(slPrice).eq(0)) return null;
    if (new BN(slPrice).eq(0)) return null;

    const openPrice = formatUnits(selectedTrade.openPrice, GAMBIT_USD_DECIMALS);
    const leverage = selectedTrade.leverage;

    return getSlPercent(
      openPrice,
      slPrice,
      leverage,
      selectedTrade.buy
    ).toFixed(1);
  }, [selectedTrade, slPrice]);

  // TODO: fix short인 경우
  const updatingTpPercent = useMemo(() => {
    if (!selectedTrade || !tpPrice) return;

    if (selectedTrade?.tp.isZero() || new BN(tpPrice).eq(0)) return null;

    const openPrice = formatUnits(selectedTrade.openPrice, GAMBIT_USD_DECIMALS);
    const leverage = selectedTrade.leverage;

    const tpPercent = getTpPercent(
      openPrice,
      tpPrice,
      leverage,
      selectedTrade.buy
    );
    return tpPercent.toFixed(1);
    // return selectedTrade.buy ? tpPercent.toFixed(1) : tpPercent.multipliedBy(-1).toFixed(1);
  }, [selectedTrade, tpPrice]);

  const oracleFee = useMemo(() => {
    if (!selectedTrade) return;
    const tradePair = pairs.find(
      (pair) =>
        pair.from === selectedTrade.pair.from &&
        pair.to === selectedTrade.pair.to
    );
    if (!tradePair) return;
    return tradePair.fee?.oracleFee;
  }, [pairs, selectedTrade]);

  return (
    <ModalSkeletonWithPortal isVisible={isVisible} setIsVisible={setIsVisible}>
      {selectedTrade && (
        <div className="min-w-[30rem]">
          {/* Title */}
          <div className="text-center mt-[4.4rem]">
            <div className="text-[2.4rem] font-bold">
              {selectedTrade.pair.from}/{selectedTrade.pair.to}
            </div>
            <div
              className={cx(
                "text-[1.6rem] font-medium",
                selectedTrade.buy ? "text-green-2" : "text-red-2"
              )}
            >
              <Trans>UPDATE POSITION</Trans>
            </div>
          </div>

          <hr className="h-px mt-[1.6rem] bg-gray-70 border-0" />

          {/* Content */}
          <div>
            {/* Update Input */}
            <div className="px-[2rem] mb-[0.2rem]">
              <div className="flex items-center justify-between gap-4 py-[1.2rem]">
                <div className="flex flex-col w-[8rem] items-center text-[1.4rem]">
                  <span className="self-start text-gray-20">
                    <Trans>Stop Loss</Trans>
                  </span>
                  <span
                    className={cx(
                      "small3 self-start font-space-grotesk",
                      updatingSlPercent && +updatingSlPercent >= 0
                        ? "text-green-2"
                        : "text-red-2"
                    )}
                  >
                    ({updatingSlPercent ? `${updatingSlPercent}%` : t`None`})
                  </span>
                </div>
                <div
                  className={cx(
                    "border border-1 border-gray-70 rounded-[4px]",
                    "hover:bg-gradient-to-r hover:from-white/50 hover:to-white hover:border-0 hover:p-[1px]",
                    {
                      "bg-gradient-to-r from-white/50 to-white border-0 p-[1px]":
                        isFocusedSlPrice,
                    }
                  )}
                >
                  <div className="flex gap-[1.6rem] rounded-[3.6px] p-[4px] bg-gray-80">
                    <input
                      type="text"
                      pattern="^([0-9]+(?:[.,][0-9]*)?)$"
                      inputMode="decimal"
                      value={slPrice}
                      placeholder="NONE"
                      onChange={(e) => {
                        if (!/^([0-9]*(?:[.,][0-9]*)?)$/.test(e.target.value)) {
                          return;
                        }
                        setSlPrice(e.target.value);
                      }}
                      onFocus={(e) => {
                        e.target.select();
                        setIsFocusedSlPrice(true);
                      }}
                      onBlur={() => {
                        setIsFocusedSlPrice(false);
                      }}
                      className="font-space-grotesk w-[11rem] text-white text-[1.8rem] font-medium text-right"
                    />
                    <button
                      disabled={disabledUpdateSl}
                      onClick={() => {
                        if (isTxSubmitting.updateSl) return;
                        updateSl();
                      }}
                      className={cx(
                        "flex justify-center w-[6.624rem] py-[3px] px-[8px] rounded-[2px]",
                        "bg-white bg-opacity-5 text-[1.3rem] text-gray-20 font-medium",
                        isTxSubmitting.updateSl
                          ? "cursor-not-allowed"
                          : "cursor-pointer",
                        { "update-tooltip-anchor": disabledUpdateSl }
                      )}
                    >
                      {isTxSubmitting.updateSl ? (
                        <Lottie
                          className="w-[2rem] h-[2rem]"
                          animationData={pendingAnimation}
                        />
                      ) : (
                        <span>
                          <Trans>UPDATE</Trans>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-between gap-4 py-[1.2rem]">
                <div className="flex flex-col w-[8rem] items-center text-[1.4rem]">
                  <span className="self-start text-gray-20">
                    <Trans>Take Profit</Trans>
                  </span>
                  <span
                    className={cx(
                      "small3 self-start font-space-grotesk",
                      !updatingTpPercent ||
                        (updatingTpPercent && +updatingTpPercent >= 0)
                        ? "text-green-2"
                        : "text-red-2"
                    )}
                  >
                    ({updatingTpPercent ? `${updatingTpPercent}%` : t`None`})
                  </span>
                </div>
                <div
                  className={cx(
                    "border border-1 border-gray-70 rounded-[4px]",
                    "hover:bg-gradient-to-r hover:from-white/50 hover:to-white hover:border-0 hover:p-[1px]",
                    {
                      "bg-gradient-to-r from-white/50 to-white border-0 p-[1px]":
                        isFocusedTpPrice,
                    }
                  )}
                >
                  <div className="flex gap-[1.6rem] rounded-[3.6px] p-[4px] bg-gray-80">
                    <input
                      type="text"
                      pattern="^([0-9]+(?:[.,][0-9]*)?)$"
                      inputMode="decimal"
                      value={tpPrice}
                      onChange={(e) => {
                        if (!/^([0-9]*(?:[.,][0-9]*)?)$/.test(e.target.value)) {
                          return;
                        }
                        setTpPrice(e.target.value);
                      }}
                      onFocus={(e) => {
                        e.target.select();
                        setIsFocusedTpPrice(true);
                      }}
                      onBlur={() => {
                        setIsFocusedTpPrice(false);
                      }}
                      className="font-space-grotesk w-[11rem] text-white text-[1.8rem] font-medium text-right"
                    />
                    <button
                      disabled={disabledUpdateTp}
                      onClick={() => {
                        if (isTxSubmitting.updateTp) return;
                        updateTp();
                      }}
                      className={cx(
                        "flex justify-center w-[6.624rem] py-[3px] px-[8px] rounded-[2px]",
                        "bg-white bg-opacity-5 text-[1.3rem] text-gray-20 font-medium",
                        isTxSubmitting.updateTp
                          ? "cursor-not-allowed"
                          : "cursor-pointer",

                        { "update-tooltip-anchor": disabledUpdateTp }
                      )}
                    >
                      {isTxSubmitting.updateTp ? (
                        <Lottie
                          className="w-[2rem] h-[2rem]"
                          animationData={pendingAnimation}
                        />
                      ) : (
                        <span>
                          <Trans>UPDATE</Trans>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>

              <ReactTooltip
                className="gambit-react-tooltip-opacity-100 z-[100]"
                anchorSelect=".update-tooltip-anchor"
                noArrow={true}
                place="top-start"
              >
                <div>Change the number on the left</div>
              </ReactTooltip>
            </div>

            {/* Liq.Price */}
            <div className="px-[2rem] py-[1.2rem]">
              <div className="flex justify-between items-center text-[1.4rem] text-gray-20 mb-[0.8rem]">
                <span>
                  <Trans>Liquidation Price</Trans>
                </span>{" "}
                {selectedTrade.liqPrice && (
                  <span className="font-space-grotesk heading3 text-red-2">
                    ${comma1000(trimPriceBN(selectedTrade.liqPrice))}
                  </span>
                )}
              </div>

              <div
                className="text-black-1 bg-brand-1 rounded-[0.8rem] py-[0.8rem] heading7 font-medium text-center cursor-pointer h-[4rem]"
                onClick={() => {
                  setIsEditLiqPriceVisible(true);
                  setIsVisible(false);
                }}
              >
                <Trans>Adjust Margin Position</Trans>
              </div>
            </div>

            {/* Information */}
            <div className="mb-[2.4rem] pt-[0.4rem] text-[1.2rem] text-gray-30 text-center">
              {/* <Trans>SLs are guaranteed, updating costs 0.015%.</Trans> <br /> */}
              <div>
                ${oracleFee} network fee is charged for position updates.
              </div>
              <Trans>To remove stop loss, set the value to 0.</Trans>
            </div>
          </div>
        </div>
      )}
    </ModalSkeletonWithPortal>
  );
};
