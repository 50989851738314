import { Trans, t } from "@lingui/macro";
import "rc-slider/assets/index.css";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "./SwapBox.css";

import * as Sentry from "@sentry/react";
import BN from "bignumber.js";
import cx from "classnames";
import { BigNumber, ContractTransaction, ethers } from "ethers";
import useSWR from "swr";

import {
  ARBITRUM_GOERLI,
  ARBITRUM_SEPOLIA,
  HARDHAT,
  ZKSYNC_GOERLI,
  ZKSYNC_SEPOLIA,
  getConstant,
  isSupportedChain,
} from "config/chains";
import {
  LIMIT,
  LONG,
  MARKET,
  SHORT,
  STOP,
  getTradeTypeNumber,
  swrFetcher,
} from "futures-lib/legacy";

import { getAbi, getContract } from "config/contracts";

import Token from "abis/Token.json";

import { formatUnits, parseUnits } from "@ethersproject/units";
import { useLingui } from "@lingui/react";
import {
  currentLanguageAtom,
  currentTimeAtom,
  showSignInModalAtom,
} from "atoms";
import {
  activeLongShortTabAtom,
  activeOrderTabAtom,
  fromValueAtom,
  isTradingDoneAtom,
  isTradingPausedAtom,
  isTxSubmittingAtom,
  maxTradesPerPairAtom,
  openOrdersAtom,
  openTradesAtom,
  pairsAtom,
  pairsPricesAtom,
  pendingTxnsAtom,
  selectedPairAtom,
  slPercentAtom,
  slPriceAtom,
  tooltipContentsAtom,
  tpPercentAtom,
  tpPriceAtom,
  tpValidationAtom,
} from "atoms/exchange";
import { referrerAddressAtom } from "atoms/referral";
import LongShortTab from "components/Tab/LongShortTab";
import OrderTab from "components/Tab/OrderTab";
import Tooltip from "components/Tooltip/Tooltip";
import { getToken } from "config/tokens";
import { approveToken } from "futures-domain/tokens/approveTokenV2";
import {
  ILimitFailResponse,
  IOpenLimitOrderRequest,
  IOpenTradeApiFailResponse,
  IOpenTradeOrOrderRequest,
  IPermitSignatureForLocalStorage,
} from "futures-domain/trades/api-types";
import { Pair } from "futures-domain/trades/types";
import {
  getSalt,
  getSlPrice,
  getTpPrice,
  randomBytes,
  trimPriceString,
  unpadZero,
} from "futures-domain/trades/utils";
import {
  Opts,
  contractFetcher,
  handleContractResult,
  handleTradeApiError,
  newContractFetcher,
  sentRequest,
} from "futures-lib/contracts";
import { helperToast } from "futures-lib/helperToast";
import { useLocalStorageSerializeKey } from "futures-lib/localStorage";
import {
  formatAmount,
  numberWithCommas,
  parseValue,
} from "futures-lib/numbers";
import { usePrevious } from "futures-lib/usePrevious";
import { ReactComponent as InfoCircleSvg } from "img/ic-info-circle.svg";
import InvalidImage from "img/ic-invalid.svg";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import StopLossAndTakeProfit from "./StopLossAndTakeProfit";
import {
  ADDED_DECIMALS,
  FOREX_CLOSED_DECISION_TIME,
  GAMBIT_USD_DECIMALS,
  MAX_SLIPPAGE,
  // PRODUCTION_OR_STAGING_ENV,
  getAPIUrl,
  getOrderDeadline,
  getTradeDeadline,
  makeLeverageMarks,
} from "./constants";

import { useChainId } from "futures-lib/chains";
import loader from "img/ic-loader.svg";
import { usePrevious as usePrevious2 } from "react-use";
// import { ReactComponent as PythSvg } from "img/ic-pyth.svg";
import {
  GambitTradingV1,
  GambitTradingV1Facet2,
  MockUSDC,
} from "@changerio/futures-contracts/dist/typechain-types";
import {
  EIP712_TYPES,
  EIP712_TYPES_BY_FUNCTION,
} from "@changerio/futures-contracts/lib/eip-712-types";
import { commonVaultAtom } from "atoms/vault";
import axios from "axios";
import { getPermitLocalstorageKey } from "config/localStorage";
import dayjs from "dayjs";
import {
  useDelegationFeeThreshhold,
  useGetContract,
  useGetTypedDomain,
} from "futures-lib/contracts/contract";
import useWallet from "futures-lib/wallets/useWallet";
import { toast } from "react-toastify";
import "react-tooltip/dist/react-tooltip.css";
import { sentryCaptureException } from "utils/sentry";
import { CollateralBox } from "./CollateralBox";
import { LeverageSlider } from "./LeverageSlider";
import { TradeInformation } from "./TradeInformation";

// * fromToken(collateral token)이 겜빗에서 배포한 경우 사용자가 mint할 수 있도록
export const MINT_NETWORKS = [
  ZKSYNC_GOERLI,
  HARDHAT,
  ARBITRUM_GOERLI,
  ARBITRUM_SEPOLIA,
  ZKSYNC_SEPOLIA,
];

const { AddressZero } = ethers.constants;

export interface Focused {
  slippage: boolean;
  limitPrice: boolean;
  leverage: boolean;
}

const initialFocused = {
  slippage: false,
  limitPrice: false,
  leverage: false,
};

export const PERMIT_DEADLINE_BUFFER = 1;

const CALL_TYPE = {
  API: "API",
  CONTRACT: "CONTRACT",
} as const;
type CallType = typeof CALL_TYPE[keyof typeof CALL_TYPE];

export default function TradeBoxV2() {
  const { isActive, signer, account, isWeb3AuthAccount } = useWallet();
  const { chainId } = useChainId();

  const currentLanguage = useAtomValue(currentLanguageAtom);

  const { i18n } = useLingui();

  const [pendingTxns, setPendingTxns] = useAtom(pendingTxnsAtom);

  const fromTokenAddress = getContract(chainId, "CollateralToken");
  const fromToken = getToken(chainId, fromTokenAddress);
  const collateralContract = useGetContract("MockUSDC") as MockUSDC;

  // console.log(fromTokenAddress);
  // console.log(fromToken);

  const tradingContractAddress = getContract(chainId, "Trading");
  const tradingContract = useGetContract("GambitTradingV1") as GambitTradingV1;

  const referrerAddress = useAtomValue(referrerAddressAtom);

  // human readable
  const [fromValue, setFromValue] = useAtom(fromValueAtom);
  const existFromValue = useMemo(() => {
    return fromValue && fromValue !== "0";
  }, [fromValue]);

  // * BigNumber(wei) format of fromValue(input value)
  const fromAmount = useMemo(() => {
    return parseValue(fromValue, fromToken.decimals);
  }, [fromToken.decimals, fromValue]);

  const [slippage, setSlippage] = useState("1");

  // const [toValue, setToValue] = useState("10");

  const [isApproving, setIsApproving] = useState(false);
  const [isWaitingForApproval, setIsWaitingForApproval] = useState(false);
  // const [isTxSubmitting, setIsTxSubmitting] = useState(false);
  const [isTxSubmitting, setIsTxSubmitting] = useAtom(isTxSubmittingAtom);

  const tradingStorageAddress = useMemo(() => {
    return getContract(chainId, "Storage");
  }, [chainId]);

  const tpValidation = useAtomValue(tpValidationAtom);

  // console.log(signer);
  const { data: collateralBalance } = useSWR<BigNumber>(
    isActive && [isActive, chainId, fromTokenAddress, "balanceOf", account],
    {
      fetcher: contractFetcher(signer, Token),
      shouldRetryOnError: true,
      errorRetryInterval: 2000,
      refreshInterval: 2000,
    }
  );
  // console.log(collateralBalance);

  const tradingContractInfo = getAbi(chainId, "Trading");
  const setIsTradingPaused = useSetAtom(isTradingPausedAtom);
  const setIsTradingDone = useSetAtom(isTradingDoneAtom);

  const { data: _isTradingPaused } = useSWR<boolean>(
    isActive && [isActive, chainId, tradingContractAddress, "isPaused"],
    {
      fetcher: contractFetcher(signer, tradingContractInfo),
      shouldRetryOnError: true,
      errorRetryInterval: 500,
      refreshInterval: 1000 * 5,
    }
  );

  useEffect(() => {
    if (_isTradingPaused !== undefined) {
      setIsTradingPaused(_isTradingPaused);
    }
  }, [_isTradingPaused, setIsTradingPaused]);

  const { data: _isTradingDone } = useSWR<boolean>(
    isActive && [isActive, chainId, tradingContractAddress, "isDone"],
    {
      fetcher: contractFetcher(signer, tradingContractInfo),
      shouldRetryOnError: true,
      errorRetryInterval: 500,
      refreshInterval: 1000 * 5,
    }
  );

  useEffect(() => {
    if (_isTradingDone !== undefined) {
      setIsTradingDone(_isTradingDone);
    }
  }, [_isTradingDone, setIsTradingDone]);

  const activeLongShortTab = useAtomValue(activeLongShortTabAtom);

  const isLong = useMemo(() => {
    return activeLongShortTab === LONG;
  }, [activeLongShortTab]);
  const isShort = useMemo(() => {
    return activeLongShortTab === SHORT;
  }, [activeLongShortTab]);

  const [leverageOption, setLeverageOption] =
    useLocalStorageSerializeKey<number>([chainId, "leverage-option"], 2);

  const activeOrderTab = useAtomValue(activeOrderTabAtom);

  const { data: fromTokenAllowance } = useSWR<BigNumber>(
    isActive && [
      isActive,
      chainId,
      fromTokenAddress,
      "allowance",
      account,
      tradingStorageAddress,
    ],
    {
      fetcher: contractFetcher(signer, Token),
      refreshInterval: 5000,
      errorRetryInterval: 5000,
    }
  );

  useEffect(() => {
    if (!account) return;

    if (
      fromTokenAllowance?.toString() === ethers.constants.MaxUint256.toString()
    ) {
      // * 사용한 permit은 로컬 스토리지에서 삭제
      localStorage.removeItem(getPermitLocalstorageKey(chainId, account));
    }
  }, [account, chainId, fromTokenAllowance]);

  // useEffect(() => {
  //   console.log(fromTokenAllowance);
  // }, [fromTokenAllowance]);

  const selectedPair = useAtomValue(selectedPairAtom);

  const { data: delegationFeeThresholdMultiplier } = useSWR<BigNumber>(
    tradingContract && [
      tradingContract,
      "delegationFeeThresholdMultiplier",
      [],
    ],
    {
      fetcher: newContractFetcher,
      shouldRetryOnError: true,
      errorRetryInterval: 500,
      refreshInterval: 3000,
    }
  );

  // * 담보x레버리지
  const positionSize = useMemo(() => {
    if (fromValue && leverageOption) {
      return new BN(fromValue).multipliedBy(leverageOption).toFixed(2);
    }
    return "";
  }, [fromValue, leverageOption]);

  // * currentTime은 1초 마다 갱신되는 state
  const currentTime = useAtomValue(currentTimeAtom);

  // * minLevPosUsdc * delegationFeeThresholdMultiplier
  // * e.g) 2500 * 3 = 7500
  const delegationFeeThreshhold = useDelegationFeeThreshhold(
    selectedPair?.fee.minLevPosUsdc,
    delegationFeeThresholdMultiplier
  );

  // * 즉 !approved랑 같음
  const needStorageApproval = useMemo(() => {
    // if (delegationFeeThreshhold && +positionSize >= delegationFeeThreshhold) return false;
    return (
      fromTokenAddress !== AddressZero &&
      fromTokenAllowance &&
      fromAmount &&
      fromAmount.gt(fromTokenAllowance)
    );
  }, [fromAmount, fromTokenAddress, fromTokenAllowance]);

  // * currentTime 업데이트 때(1s 간격)마다 체크
  const needNewPermitSignature = useMemo(() => {
    if (!delegationFeeThreshhold || !account) return;

    // * approve 상태면 permit 받을 필요 없음
    if (!needStorageApproval) {
      return false;
    }

    const permitSignature = localStorage.getItem(
      getPermitLocalstorageKey(chainId, account)
    );
    if (!permitSignature) {
      return true;
    }
    const { a: _account, d: deadline } = JSON.parse(
      permitSignature
    ) as IPermitSignatureForLocalStorage;

    if (account !== _account) {
      return true;
    }

    if (!deadline) {
      return true;
    }

    const currentTimeStamp = new Date().getTime() / 1000; // 단위 ms -> s 로 변환

    // * deadline이 5분 이상 남았을 때는 skip, 5분 이하로 남으면 다시 permit sign 받도록
    if (+deadline > currentTimeStamp + 60 * 5) {
      return false;
    }

    return true;

    // * 1초마다 체크해야해서 currentTime을 dependencies에 넣어줘야 함
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    account,
    delegationFeeThreshhold,
    needStorageApproval,
    positionSize,
    currentTime,
  ]);

  const { tradingContractTypedDomain, collateralContractTypedDomain } =
    useGetTypedDomain();

  const setPermitSignature = useCallback(async () => {
    if (
      !account ||
      !signer ||
      !collateralContractTypedDomain ||
      !collateralContract
    )
      return;

    const deadline = dayjs()
      .add(PERMIT_DEADLINE_BUFFER, "week")
      .unix()
      .toString(); // seconds 단위

    try {
      setIsTxSubmitting(true);
      const permitParams = {
        owner: account, // .toLowerCase()?
        spender: tradingStorageAddress, //.toLowerCase()?
        value: ethers.constants.MaxUint256.toString(),
        nonce: await collateralContract.nonces(account),
        deadline,
      };

      const permitSignature = await signer._signTypedData(
        collateralContractTypedDomain,
        EIP712_TYPES_BY_FUNCTION.ERC20Permit,
        permitParams
      );

      localStorage.setItem(
        getPermitLocalstorageKey(chainId, account),
        JSON.stringify({ s: permitSignature, d: deadline, a: account })
      );
    } catch (e: any) {
      sentryCaptureException({
        error: new Error("Sign Error in setPermitSignature"),
        name: "error object",
        context: e,
      });
    } finally {
      setTimeout(() => setIsTxSubmitting(false), 1000);
    }
  }, [
    account,
    chainId,
    collateralContract,
    collateralContractTypedDomain,
    setIsTxSubmitting,
    signer,
    tradingStorageAddress,
  ]);

  const prevNeedApproval = usePrevious(needStorageApproval);

  useEffect(() => {
    if (!needStorageApproval && prevNeedApproval && isWaitingForApproval) {
      setIsWaitingForApproval(false);
      // helperToast.success(<div>{fromToken.symbol} approved!</div>);
    }
  }, [
    fromToken.symbol,
    isWaitingForApproval,
    needStorageApproval,
    prevNeedApproval,
  ]);

  const pairs = useAtomValue(pairsAtom);

  const prevSelectedPair: Pair | undefined = usePrevious2(selectedPair);

  const publicPriceEndpoint = useMemo(() => {
    return getConstant(chainId, "priceServerEndPoint");
  }, [chainId]);

  const getPriceEndpointWithQueryParams = useCallback(() => {
    const params = new URLSearchParams();
    pairs
      .filter((pair) => pair?.priceFeedId !== ethers.constants.AddressZero)
      .forEach((pair) => {
        if (pair) {
          params.append("ids[]", pair.priceFeedId!);
        }
      });

    return `${publicPriceEndpoint}/latest_price_feeds?${params}`;
  }, [pairs, publicPriceEndpoint]);

  const {
    data: realTimePricesData,
    isLoading: realTimePricesDataIsLoading,
    error: realTimePricesDataError,
  } = useSWR(pairs.length > 0 && getPriceEndpointWithQueryParams(), {
    fetcher: swrFetcher,
    refreshInterval: 0,
  });

  const [pairsPrices, setPairsPrices] = useAtom(pairsPricesAtom);

  const selectedPairPriceValue = useMemo(() => {
    if (!selectedPair || !pairsPrices[selectedPair.priceFeedId!]) return;

    const { price, expo } = pairsPrices[selectedPair.priceFeedId!];
    return formatUnits(BigNumber.from(price), Math.abs(expo));
  }, [pairsPrices, selectedPair]);

  const prevSelectedPairPriceValue = usePrevious(selectedPairPriceValue);

  const confidenceSpreadValue = useMemo(() => {
    if (!selectedPair || !pairsPrices[selectedPair.priceFeedId!]) return;

    const { conf, expo } = pairsPrices[selectedPair.priceFeedId!];

    const _confidence = formatUnits(conf, Math.abs(expo)); // 단위: 달러

    const confMultiplier = selectedPair.confMultiplierP;

    const spread = new BN(_confidence).multipliedBy(confMultiplier).toFixed();
    if (!selectedPairPriceValue) return;

    return trimPriceString(spread);
  }, [pairsPrices, selectedPair, selectedPairPriceValue]);

  const confidenceSpreadPercent = useMemo(() => {
    if (!selectedPair || !pairsPrices[selectedPair.priceFeedId!]) return;

    if (!confidenceSpreadValue || !selectedPairPriceValue) return;

    const result = new BN(confidenceSpreadValue)
      .multipliedBy(100)
      .dividedBy(selectedPairPriceValue);
    // .toFixed(fromToken.decimals);
    // console.log(fromToken.decimals);
    // console.log(result);

    return result;

    // return new BN(confidenceSpreadValue).multipliedBy(100).dividedBy(selectedPairPriceValue).toFixed(2);
  }, [
    confidenceSpreadValue,
    pairsPrices,
    selectedPair,
    selectedPairPriceValue,
  ]);

  const [selectedPairLimitOrStopPrice, setSelectedPairLimitOrStopPrice] =
    useState(
      selectedPairPriceValue ? new BN(selectedPairPriceValue).toFixed(2, 0) : ""
    );

  useEffect(() => {
    if (
      !selectedPairLimitOrStopPrice &&
      !prevSelectedPairPriceValue &&
      selectedPairPriceValue
    ) {
      setSelectedPairLimitOrStopPrice(trimPriceString(selectedPairPriceValue));
    }
  }, [
    prevSelectedPairPriceValue,
    selectedPairLimitOrStopPrice,
    selectedPairPriceValue,
  ]);

  const isPositionSizeSmall = useMemo(() => {
    if (
      !positionSize ||
      !leverageOption ||
      !selectedPair ||
      (!selectedPair.groupIndex && selectedPair.groupIndex !== 0)
    )
      return true;

    return new BN(fromValue)
      .multipliedBy(leverageOption)
      .lt(selectedPair.fee?.minLevPosUsdc);
  }, [fromValue, leverageOption, positionSize, selectedPair]);

  useEffect(() => {
    if (
      selectedPairPriceValue &&
      prevSelectedPair &&
      prevSelectedPair.tvSymbol !== selectedPair?.tvSymbol
    ) {
      setSelectedPairLimitOrStopPrice(trimPriceString(selectedPairPriceValue));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPair, activeOrderTab]);

  useEffect(() => {
    if (
      !realTimePricesDataIsLoading &&
      !realTimePricesDataError &&
      realTimePricesData
    ) {
      const priceObjs = {};

      // console.log(pairsPrices);
      // console.log(realTimePricesData);

      realTimePricesData.forEach((priceObj) => {
        const { price, expo, conf, publish_time } = priceObj.price;
        // const formattedPrice = ethers.utils.formatUnits(ethers.BigNumber.from(price), Math.abs(expo));

        // 요청을 보낼 때는 0x가 붙은 id 값을 사용하는데,
        // 실제 price 응답을 받으면, 0x가 빠진 id 값이 들어가 있음
        // 요청을 보낼 때의 id 값과 통일시키기 위해서 응답 id에도 0x를 붙여 줌
        // priceObjs[`0x${priceObj.id}`] = formattedPrice;
        priceObjs[`0x${priceObj.id}`] = {
          price,
          expo,
          conf,
          publish_time,
        };
      });
      setPairsPrices(priceObjs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realTimePricesData, realTimePricesDataIsLoading]);

  const tpPercent = useAtomValue(tpPercentAtom);
  const slPercent = useAtomValue(slPercentAtom);
  const tpPrice = useAtomValue(tpPriceAtom);
  const slPrice = useAtomValue(slPriceAtom);

  const openTrades = useAtomValue(openTradesAtom);
  const openOrders = useAtomValue(openOrdersAtom);

  const maxTradesPerPair = useAtomValue(maxTradesPerPairAtom);

  const [withinExposureLimits, setWithinExposureLimits] = useState(true);

  // const gtTp900 = useAtomValue(gtTp900Atom);

  const insufficientBalance = useMemo(() => {
    if (!fromValue || !collateralBalance) return false;

    return new BN(fromValue).gt(
      new BN(formatAmount(collateralBalance, fromToken.decimals))
    );
  }, [collateralBalance, fromToken.decimals, fromValue]);

  // 페어 당 trades + orders 개수는 maxTradesPerPair 보다 클 수 없음
  // TODO: 수정 필요 => pending 중인 것도 있을 수 있음
  const numOfPositionsOfSelectedPair = useMemo<number>(() => {
    if (openOrders?.length && openTrades?.length) {
      return [...openTrades, ...openOrders].filter(
        (obj) => obj.pairIndex === selectedPair?.pairIndex
      ).length;
    } else if (openTrades?.length || openOrders?.length) {
      return openTrades?.length
        ? openTrades.filter((t) => t.pairIndex === selectedPair?.pairIndex)
            .length
        : openOrders!.filter((o) => o.pairIndex === selectedPair?.pairIndex)
            .length;
    }
    return 0;
  }, [openOrders, openTrades, selectedPair?.pairIndex]);

  const possibleLimitPrice = useMemo(() => {
    if (!selectedPairPriceValue || !selectedPairLimitOrStopPrice) return;
    const currentPrice = new BN(selectedPairPriceValue);
    const limitPrice = new BN(selectedPairLimitOrStopPrice);

    let result = {
      value: true,
      message: "",
      tooltip: "",
    };

    if (activeOrderTab === LIMIT) {
      if (isLong && currentPrice.lte(limitPrice)) {
        result = {
          value: false,
          message: t`Wrong Limit Price`,
          tooltip: t`Price must be < ${selectedPairPriceValue}`,
        };
      } else if (!isLong && currentPrice.gte(limitPrice)) {
        result = {
          value: false,
          message: t`Wrong Limit Price`,
          tooltip: t`Price must be > ${selectedPairPriceValue}`,
        };
      }
    } else if (activeOrderTab === STOP) {
      if (isLong && currentPrice.gte(limitPrice)) {
        result = {
          value: false,
          message: t`Wrong Stop Price`,
          tooltip: t`Price must be > ${selectedPairPriceValue}`,
        };
      } else if (!isLong && currentPrice.lte(limitPrice)) {
        result = {
          value: false,
          message: t`Wrong Stop Price`,
          tooltip: t`Price must be < ${selectedPairPriceValue}`,
        };
      }
    }

    return result;
  }, [
    selectedPairPriceValue,
    selectedPairLimitOrStopPrice,
    activeOrderTab,
    isLong,
  ]);

  const minLeverage: number | undefined = useMemo(() => {
    if (
      !selectedPair ||
      selectedPair.groupIndex === undefined ||
      selectedPair.group?.minLeverage === undefined
    )
      return;

    // return LEVERAGE[selectedPair.groupIndex].min;
    return +selectedPair.group.minLeverage;
  }, [selectedPair]);

  const maxLeverage = useMemo(() => {
    if (
      !selectedPair ||
      selectedPair.groupIndex === undefined ||
      selectedPair.group?.maxLeverage === undefined
    )
      return;

    // return (LEVERAGE[selectedPair.groupIndex].max * BASIS_POINTS_DIVISOR) / BASIS_POINTS_DIVISOR;
    return +selectedPair.group.maxLeverage;
  }, [selectedPair]);

  useEffect(() => {
    if (!minLeverage) return;

    if (leverageOption && leverageOption < minLeverage) {
      setLeverageOption(minLeverage);
    }
    if (leverageOption && maxLeverage && leverageOption > maxLeverage) {
      setLeverageOption(maxLeverage);
    }
    // INFO: selectedPair가 바뀌었을 때만 체크 / 입력받을 때 마다 체크하면 1이 입력이 안됨
    // pair가 crypto -> forex or forex -> crypto 로 변경되었을 때 최소 / 최대 레버리지 범위가 달라지는 것 보정 용도
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPair, setLeverageOption]);

  const validLeverage = useMemo(() => {
    if (!leverageOption || !minLeverage || !maxLeverage) return false;

    return leverageOption >= minLeverage && leverageOption <= maxLeverage;
  }, [leverageOption, maxLeverage, minLeverage]);

  const pairPriceInfo = useMemo(() => {
    if (!selectedPair) return;

    try {
      return pairsPrices[selectedPair?.priceFeedId];
    } catch (e) {
      return;
    }
  }, [selectedPair, pairsPrices]);

  const forexClosed = useMemo(() => {
    if (selectedPair?.groupIndex !== 1 || !pairPriceInfo?.publish_time)
      return false;
    // console.log(currentTime?.unix());
    // console.log(pairPriceInfo?.publish_time);

    const _currentTime = currentTime?.unix();
    const publish_time = pairPriceInfo?.publish_time;

    if (!currentTime || !publish_time) return false;

    if (
      _currentTime &&
      Math.abs(_currentTime - publish_time) > FOREX_CLOSED_DECISION_TIME
    ) {
      return true;
    }

    return false;
  }, [currentTime, selectedPair?.groupIndex, pairPriceInfo]);

  const isPrimaryEnabled = useCallback(() => {
    if (!isActive) {
      return true;
    }

    if (_isTradingPaused) {
      return false;
    }

    if (_isTradingDone) {
      return false;
    }

    // Trade + Order 개수는 maxTradesPerPair보다 크거나 같으면 더 이상 openTrade 불가
    if (numOfPositionsOfSelectedPair >= maxTradesPerPair) {
      return false;
    }

    if (!existFromValue || !collateralBalance) {
      return false;
    }

    if (insufficientBalance) {
      return false;
    }

    if (possibleLimitPrice && !possibleLimitPrice.value) {
      return false;
    }

    if (forexClosed) {
      return false;
    }

    if (slPercent === null && !slPrice) {
      return false;
    }

    if (!tpPercent && !tpPrice) {
      return false;
    }

    if (isPositionSizeSmall) {
      return false;
    }

    if (isApproving) {
      return false;
    }

    if (isTxSubmitting) {
      return false;
    }

    if (needNewPermitSignature) {
      return true;
    }

    if (needStorageApproval && isWaitingForApproval) {
      return false;
    }

    if (needNewPermitSignature && needStorageApproval) {
      return true;
    }

    if (activeOrderTab !== MARKET && !selectedPairLimitOrStopPrice) {
      return false;
    }

    if (!withinExposureLimits) {
      return false;
    }

    if (!validLeverage) {
      return false;
    }

    if (!slippage || !leverageOption) {
      return false;
    }

    // if (gtTp900) {
    //   return false;
    // }

    if (!tpValidation.valid) {
      return false;
    }

    return true;
  }, [
    _isTradingDone,
    _isTradingPaused,
    activeOrderTab,
    collateralBalance,
    existFromValue,
    forexClosed,
    insufficientBalance,
    isActive,
    isApproving,
    isPositionSizeSmall,
    isTxSubmitting,
    isWaitingForApproval,
    leverageOption,
    maxTradesPerPair,
    needNewPermitSignature,
    needStorageApproval,
    numOfPositionsOfSelectedPair,
    possibleLimitPrice,
    selectedPairLimitOrStopPrice,
    slPercent,
    slPrice,
    slippage,
    tpPercent,
    tpPrice,
    tpValidation.valid,
    validLeverage,
    withinExposureLimits,
  ]);

  const [tooltipContents, setTooltipContents] = useAtom(tooltipContentsAtom);

  // * getPrimaryTooltip 대체 로직 in useEffect
  // * disabled와 순서 반대
  useEffect(() => {
    let result = "";

    if (!tpValidation.valid) {
      result = tpValidation.message;
    }

    // if (gtTp900) {
    //   result = t`The maximum Take Profit is 900%.`;
    // }

    if (!slippage || !leverageOption) {
      result = t`Check slippage or leverage`;
    }

    if (!validLeverage) {
      result = t`Wrong Leverage, ${minLeverage} <= leverage <= ${maxLeverage}`;
    }

    if (!withinExposureLimits) {
      let direction;
      if (activeLongShortTab === LONG) {
        direction = t`Long`;
      } else if (activeLongShortTab === SHORT) {
        direction = t`Short`;
      }

      // result = t`The open interest(${direction}) limit for the pair has been reached.`;
      result = t`Currently, the vault funds for that pair(${direction}) have been used up and new trades are being restricted.`;
    }

    if (numOfPositionsOfSelectedPair >= maxTradesPerPair) {
      result = t`Maximum trades per pair is ${maxTradesPerPair}`;
    }

    if (!existFromValue || !collateralBalance) {
      result = "";
    }

    if (insufficientBalance) {
      result = t`Insufficient Balance`;
    }

    if (isPositionSizeSmall) {
      result = t`Position size must be >= ${unpadZero(
        selectedPair?.fee?.minLevPosUsdc
      )} ${fromToken.symbol}`;
    }

    if (possibleLimitPrice && !possibleLimitPrice.value) {
      result = t`${possibleLimitPrice.tooltip}`;
    }

    if (forexClosed) {
      result = t`The market is currently closed`;
    }

    setTooltipContents(result);
  }, [
    activeLongShortTab,
    collateralBalance,
    existFromValue,
    forexClosed,
    fromToken.symbol,
    fromValue,
    insufficientBalance,
    isPositionSizeSmall,
    leverageOption,
    maxLeverage,
    maxTradesPerPair,
    minLeverage,
    numOfPositionsOfSelectedPair,
    possibleLimitPrice,
    selectedPair?.fee?.minLevPosUsdc,
    selectedPair?.groupIndex,
    setTooltipContents,
    slippage,
    tpValidation.message,
    tpValidation.valid,
    validLeverage,
    withinExposureLimits,
  ]);

  // useEffect(() => {
  //   console.log(selectedPairPriceValue, selectedPairLimitOrStopPrice);
  // }, [selectedPairLimitOrStopPrice, selectedPairPriceValue]);

  const commonVaultInfo = useAtomValue(commonVaultAtom);

  const getPrimaryText = useCallback(() => {
    if (!isSupportedChain(chainId)) {
      return t`Incorrect Network`;
    }

    if (!isActive) {
      return t`Sign In`;
    }

    if (
      commonVaultInfo?.collateralizationP &&
      _isTradingPaused &&
      new BN(commonVaultInfo.collateralizationP.toString()).eq(0)
    ) {
      return `Trading is paused until the vault is filled`;
    }

    if (_isTradingPaused) {
      return t`The service is undergoing maintenance`;
    }

    if (_isTradingDone) {
      return t`Temporarily Unavailable`;
    }

    if (numOfPositionsOfSelectedPair >= maxTradesPerPair) {
      return t`Max Trades Reached`;
    }

    if (!existFromValue) {
      return t`Please Input Collateral`;
    }

    if (insufficientBalance) {
      return t`Trade`;
    }

    if (possibleLimitPrice && !possibleLimitPrice.value) {
      return t`${possibleLimitPrice.message}`;
    }

    if (slPercent === null && !slPrice) {
      return t`Please Input Stop Loss`;
    }

    if (!tpPercent && !tpPrice) {
      return t`Please Input Take Profit`;
    }

    if (needNewPermitSignature) {
      return t`One-time Approve`;
    }

    if (isApproving) {
      return t`Approving...`;
    }

    if (needStorageApproval && isWaitingForApproval) {
      return t`Waiting for Approval`;
    }

    if (needNewPermitSignature && needStorageApproval) {
      return t`${fromToken.symbol} Approve`;
    }

    if (activeOrderTab !== MARKET && !selectedPairLimitOrStopPrice) {
      return t`Please Input Limit or Stop Price`;
    }

    if (isLong) {
      return t`${i18n._(activeOrderTab)} ${selectedPair?.from}/${
        selectedPair?.to
      } Long`;
    }
    if (isShort) {
      return t`${i18n._(activeOrderTab)} ${selectedPair?.from}/${
        selectedPair?.to
      } Short`;
    }

    return t`...`;
  }, [
    chainId,
    isActive,
    commonVaultInfo.collateralizationP,
    _isTradingPaused,
    _isTradingDone,
    numOfPositionsOfSelectedPair,
    maxTradesPerPair,
    existFromValue,
    insufficientBalance,
    possibleLimitPrice,
    slPercent,
    slPrice,
    tpPercent,
    tpPrice,
    needNewPermitSignature,
    isApproving,
    needStorageApproval,
    isWaitingForApproval,
    activeOrderTab,
    selectedPairLimitOrStopPrice,
    isLong,
    isShort,
    fromToken.symbol,
    i18n,
    selectedPair?.from,
    selectedPair?.to,
  ]);

  // const selectedPairOiAndGroupCollateral = useAtomValue(selectedPairOiAndGroupCollateralAtom);

  // INFO: 포지션을 열기 전에 담보가 충분한 지 확인
  useEffect(() => {
    const buy = activeLongShortTab === LONG;
    if (!selectedPair || !fromValue || !leverageOption) return;
    const { openInterest, collateral } = selectedPair;

    if (
      !openInterest ||
      !collateral ||
      !openInterest.long._isBigNumber ||
      !openInterest.short._isBigNumber
    )
      return;
    const positionSizeUsdc = parseUnits(fromValue, fromToken.decimals);
    const newInterestUsdc = (
      buy ? openInterest?.long : openInterest?.short
    ).add(positionSizeUsdc.mul(BigNumber.from(leverageOption)));

    const newCollateralUsdc = (
      buy ? collateral?.collateralLong : collateral?.collateralShort
    ).add(positionSizeUsdc);

    const maxInterestUsdc = openInterest?.max;
    const maxCollateralUsdc = collateral?.collateralMax;

    if (
      newInterestUsdc.lte(maxInterestUsdc) &&
      newCollateralUsdc.lte(maxCollateralUsdc)
    ) {
      // console.log("거래 가능");
      setWithinExposureLimits(true);
    } else {
      // console.log("거래 불가");
      setWithinExposureLimits(false);
    }
  }, [
    activeLongShortTab,
    fromToken.decimals,
    fromValue,
    leverageOption,
    selectedPair,
  ]);

  const tradeOrOrderOpts = useMemo(() => {
    if (!selectedPair) return;

    return {
      sentMsg: "",
      failMsg: "",
      successMsg: "",
      hideSuccessMsg: true, // 이벤트를 받아서 따로 토스트 메세징 처리하기 때문에
      pairIndex: selectedPair.pairIndex,
      pairs,
      chainId,
    } as Opts;
  }, [chainId, pairs, selectedPair]);

  const toastTradeOrOrder = useCallback(() => {
    if (!selectedPair) return;

    const toastId = `open:${isLong}:${selectedPair?.from}:${selectedPair?.to}`;

    const orderType = getTradeTypeNumber(activeOrderTab!);

    if (orderType === 0) {
      setTimeout(() => {
        helperToast.info(
          <div>
            <div className="toastify-title">
              <Trans>Opening Order</Trans>
            </div>
            <div>
              <div className="toastify-body">
                <span>
                  {selectedPair.from}/{selectedPair.to}
                </span>
                <span
                  className={cx(
                    isLong ? "text-green-2" : "text-red-2",
                    "ml-[4px]"
                  )}
                >
                  {isLong ? t`LONG` : t`SHORT`}
                </span>
              </div>
            </div>
          </div>,
          {
            // autoClose: 7000,
            toastId,
          }
        );
      }, 200);
    }
  }, [activeOrderTab, isLong, selectedPair]);

  const toastLimitOrStopOrder = useCallback(() => {
    if (!selectedPair) return;

    const toastId = `open:${isLong}:${selectedPair?.from}:${selectedPair?.to}`;

    const orderType = getTradeTypeNumber(activeOrderTab!);

    if (orderType === 1 || orderType === 2) {
      setTimeout(() => {
        helperToast.success(
          <div>
            <div className="toastify-title">
              <Trans>Order Request Sent</Trans>
            </div>
            <div>
              <div className="toastify-body">
                <span>
                  {selectedPair.from}/{selectedPair.to}
                </span>
                <span
                  className={cx(
                    isLong ? "text-green-2" : "text-red-2",
                    "ml-[4px]"
                  )}
                >
                  {isLong ? t`LONG` : t`SHORT`}
                </span>
              </div>
            </div>
          </div>,
          {
            autoClose: 7000,
            toastId,
          }
        );
      }, 200);
    }
  }, [activeOrderTab, isLong, selectedPair]);

  const checkTpAndSl = useCallback(() => {
    if (!selectedPairPriceValue || !selectedPairLimitOrStopPrice) return;

    const tp = tpPrice
      ? new BN(tpPrice)
          .multipliedBy(new BN(10).pow(GAMBIT_USD_DECIMALS))
          .toString()
      : getTpPrice(
          activeOrderTab === MARKET
            ? selectedPairPriceValue
            : selectedPairLimitOrStopPrice,
          tpPercent!,
          leverageOption!,
          isLong
        );
    const sl = slPrice
      ? new BN(slPrice)
          .multipliedBy(new BN(10).pow(GAMBIT_USD_DECIMALS))
          .toString()
      : getSlPrice(
          activeOrderTab === MARKET
            ? selectedPairPriceValue
            : selectedPairLimitOrStopPrice,
          slPercent!,
          leverageOption!,
          isLong
        );

    if (tp === "0" || (tpPrice && tpPercent) || (slPrice && slPercent)) {
      helperToast.error(
        <div className="toastify-body-only">
          <Trans>Check Stop Loss And Take Profit</Trans>
        </div>
      );

      return;
    }

    return { tp, sl };
  }, [
    activeOrderTab,
    isLong,
    leverageOption,
    selectedPairLimitOrStopPrice,
    selectedPairPriceValue,
    slPercent,
    slPrice,
    tpPercent,
    tpPrice,
  ]);

  const getParamsForOpenTrade = useCallback(
    (callType: CallType, isLimitOrder = false) => {
      if (
        !account ||
        !selectedPair ||
        !leverageOption ||
        !fromAmount ||
        !selectedPairPriceValue
      )
        return;

      const tpAndSl = checkTpAndSl();
      if (!tpAndSl) return;
      const { tp, sl } = tpAndSl;

      const orderType = getTradeTypeNumber(activeOrderTab!);

      // * 슬리피지 = 사용자 지정 슬리피지 + SPREAD(%)
      const _slippage = confidenceSpreadPercent
        ? new BN(slippage)
            .plus(confidenceSpreadPercent)
            .toFixed(GAMBIT_USD_DECIMALS)
        : slippage;

      // * deadline은 blocknumber가 아니라 timestamp(seconds, not ms)
      const deadline = isLimitOrder
        ? getOrderDeadline(chainId)
        : getTradeDeadline(chainId);
      // console.log(`isLimitOrder: ${isLimitOrder}`);
      // console.log(`deadline: ${deadline}`);

      const params: GambitTradingV1Facet2.OpenTradeParamsStruct = {
        t: {
          trader: account,
          buy: isLong,
          pairIndex: selectedPair.pairIndex,
          index: 0,
          // * NOTE: '23.10.11) Contract에 물타기 기능이 추가되면서 레버리지의 decimal이 +18 되었음
          leverage: parseUnits(
            leverageOption.toString(),
            ADDED_DECIMALS
          ).toString(),
          initialPosToken: "0",
          positionSizeUsdc: fromAmount.toString(),
          openPrice: parseUnits(
            activeOrderTab === MARKET
              ? selectedPairPriceValue
              : selectedPairLimitOrStopPrice,
            GAMBIT_USD_DECIMALS
          ).toString(),
          tp,
          sl,
        },

        orderType, // orderType
        spreadReductionId: 0, // spreadReductionId // * no spread reduction (고정)
        // * _slippage가 1.05% 라면 -> 1.05*1e10을 컨트랙트에 전달
        slippageP:
          activeOrderTab === MARKET
            ? parseUnits(_slippage, GAMBIT_USD_DECIMALS).toString()
            : ethers.constants.Zero.toString(), // slippageP
        referrer: referrerAddress as string, // referrer
        deadline: +deadline,
        salt: callType === CALL_TYPE.API ? getSalt() : randomBytes(32),
      };

      return params;
    },
    [
      account,
      activeOrderTab,
      chainId,
      checkTpAndSl,
      confidenceSpreadPercent,
      fromAmount,
      isLong,
      leverageOption,
      referrerAddress,
      selectedPair,
      selectedPairLimitOrStopPrice,
      selectedPairPriceValue,
      slippage,
    ]
  );

  // const [weiGasFee, setWeiGasFee] = useState<BigNumber>();

  // const getGasFee = useCallback(async () => {
  //   if (!fromAmount || !fromTokenAllowance || !tradingContract) return;
  //   if (fromAmount.gt(fromTokenAllowance)) return;

  //   if (!account) return;
  //   const params = getParamsForOpenTrade(CALL_TYPE.CONTRACT);
  //   if (!params) return;

  //   const openTradeParams: [string, GambitTradingV1Facet2.OpenTradeParamsStruct, string, string] = [
  //     account,
  //     params,
  //     "0x", // paramsSignature
  //     "0x", // encodedPythPrice
  //   ];

  //   if (selectedPair?.fee.minLevPosUsdc && +positionSize < +selectedPair?.fee.minLevPosUsdc) {
  //     return;
  //   }

  //   try {
  //     const gas = await tradingContract.estimateGas.openTrade(...openTradeParams);
  //     const signerOrProvider = getProvider(signer, chainId);
  //     const gasPrice = await signerOrProvider.getGasPrice();
  //     const weiGasFee = gas.mul(gasPrice);
  //     if (weiGasFee) {
  //       setWeiGasFee(weiGasFee);
  //     }
  //   } catch (e) {
  //     // eslint-disable-next-line no-console
  //     console.log(e);
  //   }
  // }, [
  //   account,
  //   chainId,
  //   fromAmount,
  //   fromTokenAllowance,
  //   getParamsForOpenTrade,
  //   positionSize,
  //   selectedPair?.fee.minLevPosUsdc,
  //   signer,
  //   tradingContract,
  // ]);

  // useEffect(() => {
  //   getGasFee();
  // }, [getGasFee]);

  const getPermitParams = useCallback(async () => {
    if (!account) return;

    const permitSigString = localStorage.getItem(
      getPermitLocalstorageKey(chainId, account)
    );
    if (!permitSigString && needStorageApproval) return;

    let permitSignature, deadline;

    if (permitSigString) {
      const permitSigData = JSON.parse(
        permitSigString
      ) as IPermitSignatureForLocalStorage;
      permitSignature = permitSigData.s;
      deadline = permitSigData.d;
    }

    const nonce = (await collateralContract.nonces(account)).toNumber();

    return needStorageApproval
      ? {
          amount: ethers.constants.MaxUint256.toString(),
          deadline,
          signature: permitSignature,
          nonce,
        }
      : undefined;
  }, [account, chainId, collateralContract, needStorageApproval]);

  // * order open은 별도 api 존재
  const openTradeApiCall = useCallback(async () => {
    if (
      !selectedPair ||
      !leverageOption ||
      !fromAmount ||
      !tradeOrOrderOpts ||
      !selectedPairPriceValue ||
      !selectedPairLimitOrStopPrice ||
      !signer ||
      !account
    )
      return;

    // console.log(`nonces: ${await collateralContract.nonces(account)}`);

    const params = getParamsForOpenTrade(CALL_TYPE.API);
    if (!params) return;

    setIsTxSubmitting(true);
    let sentRequestToastId;

    try {
      const signature = await signer._signTypedData(
        tradingContractTypedDomain,
        {
          Trade: EIP712_TYPES.Trade,
          OpenTradeParams: EIP712_TYPES.OpenTradeParams,
        },
        params
      );

      const permitParams = await getPermitParams();

      sentRequestToastId = sentRequest(
        "OpenTrade",
        `${selectedPair.from}/${selectedPair.to}`
      );

      try {
        const { data } = await axios.post<string | IOpenTradeApiFailResponse>(
          `${getAPIUrl(
            chainId
          )}/v1/gasless/market/open?trader=${account}&signature=${signature}`,
          {
            permit: permitParams,
            params,
          } as IOpenTradeOrOrderRequest
        );
        toast.dismiss(sentRequestToastId);

        let status, hash, code;

        // * data가 string(hash)이 아니면 API 호출 실패한 것
        // * API 성공 / 실패 시 응답 형태가 달라서
        if (typeof data !== "string") {
          status = data.status;
          hash = data.hash;
          code = data.code;
        }
        // * data가 string이면 API 호출 성공 케이스
        else {
          hash = data;
          status = 200;
        }

        if (status === 200) {
          handleContractResult(
            { hash } as ContractTransaction,
            tradeOrOrderOpts
          );
          toastTradeOrOrder(); // * Opening Order toast
        } else {
          handleApiResult(status, code);
        }
      } catch (e: any) {
        toast.dismiss(sentRequestToastId);

        // * API 에러 처리
        const { response } = e;

        const { data } = response;

        handleTradeApiError(
          data.name || "something went wrong",
          data.details?.customError
            ? `${data.message}(${data.details?.customError})`
            : data.message || "something went wrong",
          chainId,
          data.details?.customError ? data.details?.customError : ""
        );

        Sentry.setContext("/gasless/market/open API Request Params", {
          signature,
          permit: permitParams,
          params,
        });

        Sentry.setContext("/gasless/market/open API Error Response", response);

        sentryCaptureException({
          error: new Error("/gasless/market/open API Error"),
          name: "Error Object",
          context: e,
        });
      }
    } catch (e: any) {
      // * 서명 에러 처리
      sentryCaptureException({
        error: new Error("Sign Error in openTradeApiCall"),
        name: "Error Object",
        context: e,
      });
    } finally {
      setIsTxSubmitting(false);
    }
  }, [
    account,
    chainId,
    fromAmount,
    getParamsForOpenTrade,
    getPermitParams,
    leverageOption,
    selectedPair,
    selectedPairLimitOrStopPrice,
    selectedPairPriceValue,
    setIsTxSubmitting,
    signer,
    toastTradeOrOrder,
    tradeOrOrderOpts,
    tradingContractTypedDomain,
  ]);

  /**
   * * 온체인에 바로 올리는 limit, stop order를 오프체인에 올리는 것으로 변경
   * * orderType이 1이면 Limit Order (0이면 Market)
   * * orderType이 2이면 Stop Order (0이면 Market)
   */
  const openOrderApiCall = useCallback(async () => {
    if (!account || !signer) return;

    // * trade / order params가 동일해서 재사용 (orderType만 다름)
    const params = getParamsForOpenTrade(CALL_TYPE.API, true);
    if (!params) return;

    try {
      setIsTxSubmitting(true);

      const signature = await signer._signTypedData(
        tradingContractTypedDomain,
        {
          Trade: EIP712_TYPES.Trade,
          OpenTradeParams: EIP712_TYPES.OpenTradeParams,
        },
        params
      );

      let permitParams;
      if (needStorageApproval) {
        permitParams = await getPermitParams();
      }

      const { data } = await axios.put<string | ILimitFailResponse>(
        `${getAPIUrl(
          chainId
        )}/v1/gasless/limit?trader=${account}&signature=${signature}`,
        {
          permit: permitParams,
          params,
        } as IOpenLimitOrderRequest
      );
      let status, code, message;

      if (typeof data !== "string") {
        status = data.status;
        code = data.code;
        message = data.message;
      } // * data가 string이면 API 호출 성공 케이스
      else {
        status = 200;
      }

      if (status === 200) {
        toastLimitOrStopOrder();
      } else {
        handleApiResult(status, code, message);
      }
    } catch (e: any) {
      try {
        const {
          response: { data },
        } = e;

        handleTradeApiError(
          data.name,
          data.details?.customError
            ? `${data.message}(${data.details?.customError})`
            : data.message,
          chainId,
          data.details?.customError ? data.details?.customError : ""
        );
      } catch (e: any) {
        throw new Error(e);
      }
      throw new Error(e);
    } finally {
      setTimeout(() => setIsTxSubmitting(false), 1000);
    }
  }, [
    account,
    chainId,
    getParamsForOpenTrade,
    getPermitParams,
    needStorageApproval,
    setIsTxSubmitting,
    signer,
    toastLimitOrStopOrder,
    tradingContractTypedDomain,
  ]);

  const openTradeOrOrder = useCallback(() => {
    const orderType = getTradeTypeNumber(activeOrderTab!);

    // * orderType === 1은 Limit Order 요청을 의미, orderType === 2는 Stop Order 요청을 의미
    if (orderType === 1 || orderType === 2) {
      // console.log("openOrderApiCall");
      openOrderApiCall();
    } else {
      // * 포지션 사이즈와 상관없이 gasless
      openTradeApiCall();
    }
  }, [activeOrderTab, openOrderApiCall, openTradeApiCall]);

  const approveFromToken = useCallback(() => {
    if (!signer) return;

    approveToken({
      setIsApproving,
      signer,
      tokenAddress: fromToken.address,
      spender: tradingStorageAddress,
      chainId,
      onApproveSubmitted: () => {
        setIsWaitingForApproval(true);
      },
      pendingTxns,
      setPendingTxns,
      successMsg: t`${fromToken.symbol} Approval Submitted`,
    });
  }, [
    signer,
    fromToken.address,
    fromToken.symbol,
    tradingStorageAddress,
    chainId,
    pendingTxns,
    setPendingTxns,
  ]);

  const setShowSignInModal = useSetAtom(showSignInModalAtom);

  const onClickPrimary = useCallback(() => {
    if (!isActive) {
      setShowSignInModal(true);
      return;
    }

    if (needNewPermitSignature) {
      setPermitSignature();
      return;
    }

    if (needNewPermitSignature && needStorageApproval) {
      approveFromToken();
      return;
    }

    if (!slippage || !leverageOption) {
      helperToast.error(
        <div className="toastify-body-only">
          <Trans>Check Slippage And Leverage</Trans>
        </div>,
        {
          autoClose: 5000,
        }
      );

      return;
    }

    openTradeOrOrder();
  }, [
    approveFromToken,
    isActive,
    leverageOption,
    needNewPermitSignature,
    needStorageApproval,
    openTradeOrOrder,
    setPermitSignature,
    setShowSignInModal,
    slippage,
  ]);

  const renderPrimaryButton = useCallback(() => {
    const primaryTextMessage = getPrimaryText();

    return (
      <button
        className={cx("brand-btn")}
        onClick={onClickPrimary}
        disabled={!isPrimaryEnabled()}
      >
        {isTxSubmitting ? (
          <img className="mx-auto h-[0.8rem]" src={loader} alt="loader" />
        ) : (
          primaryTextMessage
        )}
      </button>
    );
  }, [getPrimaryText, isPrimaryEnabled, isTxSubmitting, onClickPrimary]);

  const slippageRef = useRef<HTMLInputElement>(null);
  const limitPriceInputRef = useRef<HTMLInputElement>(null);

  const [focused, setFocused] = useState(initialFocused);

  const openPriceForSlTp = useMemo(() => {
    if (!selectedPairPriceValue) return "";

    if (activeOrderTab === MARKET) {
      return selectedPairPriceValue;
    } else {
      return selectedPairLimitOrStopPrice;
    }
  }, [activeOrderTab, selectedPairLimitOrStopPrice, selectedPairPriceValue]);

  const leverageMarks = useMemo(() => {
    if (!minLeverage || !maxLeverage) return;

    return makeLeverageMarks(minLeverage, maxLeverage);
  }, [maxLeverage, minLeverage]);

  // const tradingStorageContract = useGetContract("GambitTradingStorageV1") as GambitTradingStorageV1;

  // console.log(tradingContract);
  // console.log(selectedPair?.fee?.minLevPosUsdc);

  // * 포지션 사이즈와 상관없이 gasless
  // const isGasLess = useMemo(() => {
  //   return true;
  // }, []);

  // * 포지션 사이즈에 따라 gasless 여부 분기
  // const isGasLess = useMemo(() => {
  //   if (!delegationFeeThreshhold) return;

  //   if (activeOrderTab === LIMIT) {
  //     return true;
  //   }
  //   if (activeOrderTab === STOP) {
  //     return true;
  //   }
  //   if (activeOrderTab === MARKET) {
  //     if (+positionSize >= delegationFeeThreshhold) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   }
  // }, [activeOrderTab, delegationFeeThreshhold, positionSize]);

  useEffect(() => {
    const scope = Sentry.getCurrentScope();

    scope.setContext("User States", {
      chainId,
      account,
      isWeb3AuthAccount,
      selectedPair,
    });
  }, [account, chainId, isWeb3AuthAccount, selectedPair]);

  return (
    <div className="Exchange-swap-box bg-black-3 border border-gray-70 xs:rounded-[1.2rem]">
      <div className="relative max-w-none xl+:max-w-[41.65rem] xs:rounded-[1.2rem]">
        <div className="bg-gray-80 pt-[1.6rem] px-[1.6rem] pb-[1.2rem] border-b border-b-gray-70 xs:rounded-t-[1.2rem]">
          <LongShortTab />
          <OrderTab />

          {/* Collateral Box */}
          <CollateralBox
            collateralBalance={collateralBalance}
            fromToken={fromToken}
            fromTokenAddress={fromTokenAddress}
            fromValue={fromValue}
            setFromValue={setFromValue}
            setPendingTxns={setPendingTxns}
          />

          {/* Leverage Slider */}
          {minLeverage && maxLeverage && leverageMarks && (
            <LeverageSlider
              leverageOption={leverageOption}
              setLeverageOption={setLeverageOption}
              minLeverage={minLeverage}
              maxLeverage={maxLeverage}
              isLong={isLong}
              isShort={isShort}
              leverageMarks={leverageMarks}
              focused={focused}
              setFocused={setFocused}
            />
          )}

          <div className="h-px border-0 bg-gray-70 mx-[-1.6rem]" />

          {/* Price & Slippage Box */}
          <div className="flex items-center justify-between gap-[1rem] mt-[1.2rem]">
            {/* Price */}
            <div className="flex flex-col flex-1">
              <span className="heading9 text-white text-opacity-50 mr-[1.6rem] mb-[4px] flex gap-[6px] items-center justify-between">
                <Trans id="msg.tradeBox / PriceFrom">Price</Trans>
                <a
                  href={`https://pyth.network/price-feeds/${
                    selectedPair?.groupIndex === 0 ? "crypto" : "fx"
                  }-${selectedPair?.from.toLowerCase()}-${selectedPair?.to.toLowerCase()}`}
                  target="_blank"
                  rel="ugc"
                >
                  {/* <PythSvg /> */}
                  <span className="bg-gradient-to-r from-[#e6dafe80] via-[#E6DAFE] to-[#e6dafe80] text-transparent bg-clip-text">
                    Powered by PYTH
                  </span>
                </a>
              </span>
              <div
                className={cx(
                  {
                    "border border-1 border-gray-60 rounded-[0.8rem]":
                      activeOrderTab !== MARKET,
                  },
                  {
                    "hover:bg-gradient-to-r hover:from-white/50 hover:to-white hover:border-0 hover:p-[1px]":
                      activeOrderTab !== MARKET,
                  },
                  {
                    "bg-gradient-to-r from-white/50 to-white border-0 p-[1px]":
                      focused.limitPrice && activeOrderTab !== MARKET,
                  }
                )}
              >
                <div
                  className={cx(
                    "max-w-[25rem] xs:max-w-none xl+:max-w-[28rem]",
                    "flex gap-[4px] justify-between items-center p-[1rem]",
                    "rounded-[0.72rem]",
                    activeOrderTab === MARKET ? "bg-black-3" : "bg-black-2"
                  )}
                  onClick={() => {
                    limitPriceInputRef.current?.focus();
                  }}
                >
                  {activeOrderTab === MARKET ? (
                    <span className="font-space-grotesk flex-1 text-gray-15 heading7">
                      {/* {selectedPair && ["CNH", "JPY"].includes(selectedPair.to) ? "¥" : "$"} */}
                      {selectedPairPriceValue !== undefined ? (
                        <div className="flex">
                          <span>$</span>
                          {/* <AnimatedNumber to={+selectedPairPriceValue} /> */}
                          {numberWithCommas(
                            trimPriceString(selectedPairPriceValue)
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </span>
                  ) : (
                    <>
                      <div className="flex max-w-[50%]">
                        {/* {selectedPair && selectedPairLimitOrStopPrice && (
                        <span>{["CNH", "JPY"].includes(selectedPair.to) ? "¥" : "$"}</span>
                      )} */}
                        {selectedPairLimitOrStopPrice && <span>$</span>}
                        <input
                          ref={limitPriceInputRef}
                          type="text"
                          pattern="^([0-9]+(?:[.,][0-9]*)?)$"
                          inputMode="decimal"
                          value={selectedPairLimitOrStopPrice}
                          onBlur={(e) => {
                            setFocused({ ...focused, limitPrice: false });
                            if (!e.target.value && selectedPairPriceValue) {
                              setSelectedPairLimitOrStopPrice(
                                trimPriceString(selectedPairPriceValue)
                              );
                            }
                          }}
                          onChange={(e) => {
                            if (
                              !/^([0-9]*(?:[.,][0-9]*)?)$/.test(e.target.value)
                            ) {
                              return;
                            }
                            setSelectedPairLimitOrStopPrice(e.target.value);
                          }}
                          onFocus={(e) => {
                            setFocused({ ...focused, limitPrice: true });
                            e.target.select();
                          }}
                          className="font-space-grotesk flex-1 whitespace-nowrap overflow-x-auto text-ellipsis heading7 text-white"
                        />
                      </div>
                      <Tooltip
                        className="max-w-[50%] whitespace-nowrap"
                        handle={
                          selectedPairPriceValue !== undefined && (
                            <span className="flex tiny text-gray-15 text-right">
                              <span>
                                <Trans id="msg.tradeBox / Current">
                                  Current
                                </Trans>
                                {":"}
                              </span>
                              <span className="ml-[2px] font-space-grotesk flex-[1.5] overflow-x-auto text-ellipsis">
                                {/* {selectedPair && selectedPairPriceValue && ["CNH", "JPY"].includes(selectedPair.to)
                              ? "¥"
                              : "$"} */}

                                <div className="flex">
                                  <span>$</span>
                                  {/* <AnimatedNumber to={+selectedPairPriceValue} /> */}
                                  {numberWithCommas(
                                    trimPriceString(selectedPairPriceValue)
                                  )}
                                </div>
                              </span>
                            </span>
                          )
                        }
                        disableHandleStyle={true}
                        position="left-top"
                        tooltipClassName="w-[22rem]"
                        renderContent={() => (
                          <span className="font-space-grotesk text-white font-medium text-[1.3rem]">
                            {trimPriceString(selectedPairPriceValue)}
                          </span>
                        )}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>

            {/* Slippage */}
            <div
              className="flex flex-col basis-[9.4rem]"
              onClick={() => {
                slippageRef.current?.focus();
              }}
            >
              <Tooltip
                handle={
                  <span className="flex items-center mr-[0.8rem] heading9 text-white text-opacity-50 mb-[4px]">
                    <Trans id="msg.tradeBox / Slippage">Slippage</Trans>
                    <InfoCircleSvg className="fill-gray-30 ml-[2px]" />
                  </span>
                }
                disableHandleStyle={true}
                position="right-top"
                tooltipClassName={
                  currentLanguage === "ko" ? "!min-w-[21rem]" : "!min-w-[16rem]"
                }
                renderContent={() => (
                  <span className="text-[1.3rem]">
                    <Trans>Max Slippage (10%)</Trans>
                  </span>
                )}
              />

              <div
                className={cx(
                  {
                    "border border-1 border-gray-60 rounded-[0.8rem]":
                      activeOrderTab === MARKET,
                  },
                  {
                    "hover:bg-gradient-to-r hover:from-white/50 hover:to-white hover:border-0 hover:p-[1px]":
                      activeOrderTab === MARKET,
                  },
                  {
                    "bg-gradient-to-r from-white/50 to-white border-0 p-[1px]":
                      focused.slippage && activeOrderTab === MARKET,
                  }
                )}
              >
                <div
                  className={cx(
                    "max-w-[9.2rem] md:max-w-[9.4rem]",
                    "flex-1 flex items-center justify-end",
                    "rounded-[0.72rem] p-[1rem] heading7",
                    activeOrderTab === MARKET ? "bg-black-2" : "bg-black-3"
                  )}
                >
                  {activeOrderTab === MARKET ? (
                    <>
                      <input
                        ref={slippageRef}
                        value={slippage}
                        onChange={(e) => {
                          if (
                            !/^([0-9]*(?:[.,][0-9]*)?)$/.test(e.target.value)
                          ) {
                            return;
                          }
                          setSlippage(e.target.value);

                          if (+e.target.value > MAX_SLIPPAGE) {
                            setSlippage(MAX_SLIPPAGE.toString());
                          }
                        }}
                        onFocus={(e) => {
                          setFocused({ ...focused, slippage: true });
                          e.target.select();
                        }}
                        onBlur={(e) => {
                          setFocused({ ...focused, slippage: false });
                        }}
                        className="font-space-grotesk text-white text-right"
                      />
                      <span className="">%</span>
                    </>
                  ) : (
                    <div className="text-gray-15">0%</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {openPriceForSlTp && (
          <StopLossAndTakeProfit
            // estimatedExecutionPriceValue={estimatedExecutionPriceValue}
            openPriceForSlTp={openPriceForSlTp}
            leverageOption={leverageOption}
            fromValue={fromValue}
            buy={isLong}
            symbol={fromToken.symbol}
          />
        )}

        <div className="px-[1.6rem] pt-[1.95rem]">{renderPrimaryButton()}</div>
        {tooltipContents && (
          <div className="flex mt-[0.8rem] px-[1.6rem]">
            <img
              className="mr-[4px] self-start mt-[2.5px]"
              src={InvalidImage}
              alt="invalid"
            />
            <span className="heading9 text-gray-15 tracking-normal">
              {tooltipContents}
            </span>
          </div>
        )}

        <div className="h-px border-0 bg-gray-70 mt-[1.2rem]" />

        <TradeInformation
          positionSize={positionSize}
          fromToken={fromToken}
          confidenceSpreadPercent={confidenceSpreadPercent}
          confidenceSpreadValue={confidenceSpreadValue}
          selectedPair={selectedPair}
          delegationFeeThreshhold={delegationFeeThreshhold}
          leverageOption={leverageOption}
          selectedPairPriceValue={selectedPairPriceValue}
          fromValue={fromValue}
          isLong={isLong}
          activeOrderTab={activeOrderTab}
          selectedPairLimitOrStopPrice={selectedPairLimitOrStopPrice}
          // weiGasFee={weiGasFee}
          // isGasLess={isGasLess}
        />
      </div>
    </div>
  );
}

export const handleApiResult = (
  status: number,
  code: string,
  _message?: string
) => {
  let message;
  if (_message) {
    message = `${code}(${_message})`;
  } else {
    message = `${code}`;
  }

  if (status !== 200) {
    helperToast.error(
      <div className="toastify-body-only">
        <Trans>{message}</Trans>
      </div>
    );
  }
};
