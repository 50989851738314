import useTVAdvancedStreaming from "./useTVAdvancedStreaming";

const API_ENDPOINT = "https://benchmarks.pyth.network/v1/shims/tradingview";
// Use it to keep a record of the most recent bar on the chart
const lastBarsCache = new Map();

export default function useTVAdvancedDatafeed() {
  const { subscribeOnStream, unsubscribeFromStream } = useTVAdvancedStreaming();
  const datafeed = (
    publicPriceEndpoint: string,
    priceApiParams: string,
    priceFeedIdOfSelectedPair: string,
    myWorker: Worker
  ) => {
    return {
      onReady: (callback) => {
        // console.log('[onReady]: Method call')
        fetch(`${API_ENDPOINT}/config`)
          .then((response) => {
            response.json().then((configurationData) => {
              setTimeout(() => callback(configurationData));
            });
          })
          .catch((e) => {
            // eslint-disable-next-line no-console
            console.log(e);
            callback({});
          });
      },
      searchSymbols: (
        userInput,
        exchange,
        symbolType,
        onResultReadyCallback
      ) => {
        // console.log('[searchSymbols]: Method call')
        fetch(`${API_ENDPOINT}/search?query=${userInput}`).then((response) => {
          response.json().then((data) => {
            onResultReadyCallback(data);
          });
        });
      },
      resolveSymbol: (
        symbolName,
        onSymbolResolvedCallback,
        onResolveErrorCallback
      ) => {
        // console.log('[resolveSymbol]: Method call', symbolName)
        fetch(`${API_ENDPOINT}/symbols?symbol=${symbolName}`).then(
          (response) => {
            response
              .json()
              .then((symbolInfo) => {
                //   console.log('[resolveSymbol]: Symbol resolved', symbolInfo)
                onSymbolResolvedCallback(symbolInfo);
              })
              .catch((error) => {
                //   console.log('[resolveSymbol]: Cannot resolve symbol', symbolName)
                onResolveErrorCallback("Cannot resolve symbol");
                return;
              });
          }
        );
      },
      getBars: (
        symbolInfo,
        resolution,
        periodParams,
        onHistoryCallback,
        onErrorCallback
      ) => {
        const { firstDataRequest } = periodParams;
        // console.log('[getBars]: Method call', symbolInfo, resolution, from, to)
        fetch(
          `${API_ENDPOINT}/history?symbol=${symbolInfo.ticker}&from=${periodParams.from}&to=${periodParams.to}&resolution=${resolution}`
        ).then((response) => {
          response
            .json()
            .then((data) => {
              if (data.t.length === 0) {
                onHistoryCallback([], { noData: true });
                return;
              }
              const bars = [] as any[];
              for (let i = 0; i < data.t.length; ++i) {
                bars.push({
                  time: data.t[i] * 1000,
                  low: data.l[i],
                  high: data.h[i],
                  open: data.o[i],
                  close: data.c[i],
                });
              }
              if (firstDataRequest) {
                lastBarsCache.set(symbolInfo.ticker, {
                  ...bars[bars.length - 1],
                });
              }
              onHistoryCallback(bars, { noData: false });
            })
            .catch((error) => {
              //   console.log('[getBars]: Get error', error)
              onErrorCallback(error);
            });
        });
      },
      subscribeBars: (
        symbolInfo,
        resolution,
        onRealtimeCallback,
        subscriberUID,
        onResetCacheNeededCallback
      ) => {
        // console.log(
        //   '[subscribeBars]: Method call with subscriberUID:',
        //   subscriberUID
        // )

        subscribeOnStream(
          symbolInfo,
          resolution,
          onRealtimeCallback,
          subscriberUID,
          onResetCacheNeededCallback,
          lastBarsCache.get(symbolInfo.ticker),
          publicPriceEndpoint,
          priceApiParams,
          priceFeedIdOfSelectedPair,
          myWorker
        );
      },
      unsubscribeBars: (subscriberUID) => {
        // console.log(
        //   '[unsubscribeBars]: Method call with subscriberUID:',
        //   subscriberUID
        // )
        unsubscribeFromStream(subscriberUID);
      },
    };
  };

  return { datafeed };
}
