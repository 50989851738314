import {
  activePositionTabAtom,
  openApiOrdersAtom,
  pairsAtom,
} from "atoms/exchange";
import { IGetLimitResponse } from "futures-domain/trades/api-types";
import { POSITION_TYPE } from "futures-domain/trades/types";
import { useOpenTrades } from "futures-domain/trades/useOpenTrades";
import { swrFetcher } from "futures-lib/legacy";
import { useAtomValue, useSetAtom } from "jotai";
import { useEffect } from "react";
import useSWR from "swr";
import CloseTradesList from "./CloseTradesList";
import OrdersListV2 from "./OrdersListV2";
import TradesListV2 from "./TradesListV2";

import { useChainId } from "futures-lib/chains";
import useWallet from "futures-lib/wallets/useWallet";
import { getAPIUrl } from "./constants";

const PositionListV2 = () => {
  const activePositionTab = useAtomValue(activePositionTabAtom);

  useOpenTrades();

  const setOpenApiOrders = useSetAtom(openApiOrdersAtom);

  const { account } = useWallet();
  const { chainId } = useChainId();

  const { data } = useSWR<IGetLimitResponse>(
    account && `${getAPIUrl(chainId)}/v1/gasless/limit?trader=${account}`,
    {
      fetcher: swrFetcher,
      shouldRetryOnError: true,
      errorRetryInterval: 1000,
      refreshInterval: 4000, // 8s
    }
  );

  const pairs = useAtomValue(pairsAtom);

  useEffect(() => {
    if (!data) {
      return;
    }
    setOpenApiOrders(data);
  }, [data, pairs, setOpenApiOrders]);

  return (
    <>
      {activePositionTab === POSITION_TYPE.TRADES && <TradesListV2 />}
      {activePositionTab === POSITION_TYPE.ORDERS && <OrdersListV2 />}
      {activePositionTab === POSITION_TYPE.HISTORY && <CloseTradesList />}
    </>
  );
};

export default PositionListV2;
