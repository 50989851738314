import { Trans, t } from "@lingui/macro";
import { useCallback, useEffect, useMemo } from "react";

import { LONG, SHORT } from "futures-lib/legacy";
import { bigNumberify, formatAmount } from "futures-lib/numbers";

import {
  closingOrdersAtom,
  closingPendingOrdersAtom,
  isOpenOrdersLoadingAtom,
  isPendingOrdersLoadingAtom,
  isVisibleDetailOrderModalAtom,
  openApiOrdersAtom,
  openOrdersAtom,
  pairsAtom,
  pairsPricesAtom,
  parsedOpenTradesAtom,
  pendingOrdersAtom,
  pendingTxnsAtom,
  selectedOrderAtom,
  tooltipContentsAtom,
} from "atoms/exchange";
import { getAbi, getContract } from "config/contracts";
import { ethers } from "ethers";
import { callContract, handleTradeApiError } from "futures-lib/contracts";
import { useAtom, useAtomValue, useSetAtom } from "jotai";

import { useChainId } from "futures-lib/chains";

import { EIP712_TYPES } from "@changerio/futures-contracts/lib/eip-712-types";
import * as Sentry from "@sentry/react";
import pendingAnimation from "animation/pending.json";
import axios from "axios";
import cx from "classnames";
import Tooltip from "components/Tooltip/Tooltip";
import GasFreeTextForPositionTable from "components/ZeroFeeEvent/GasFreeTextForPositionTable";
import { isSupportedChain } from "config/chains";
import { SELECTED_WALLET_LOCALSTORAGE_KEY } from "config/localStorage";
import { getToken } from "config/tokens";
import { ILimitFailResponse } from "futures-domain/trades/api-types";
import { Order, PendingOrder } from "futures-domain/trades/types";
import {
  getFulfillPrice,
  trimPriceBN,
  unpadZero,
} from "futures-domain/trades/utils";
import { WALLET_TYPES } from "futures-domain/types";
import { useGetTypedDomain } from "futures-lib/contracts/contract";
import { helperToast } from "futures-lib/helperToast";
import { showUnsupportedNetworkToast } from "futures-lib/wallets";
import useWallet from "futures-lib/wallets/useWallet";
import CloseBtnImage from "img/ic-close-btn.svg";
import Lottie from "lottie-react";
import { sentryCaptureException } from "utils/sentry";
import { handleApiResult } from "./TradeBoxV2";
import { getAPIUrl } from "./constants";

// order에 type이 있으면 -> Limit / Stop
// type이 없으면 -> Long / Short
export const getOrderType = (order: Order) => {
  if (order.type) {
    switch (order.type) {
      case "REVERSAL":
        return t`Limit`;
      case "MOMENTUM":
        return t`Stop`;
      default:
        return "";
    }
  } else {
    return order.buy ? LONG : SHORT;
  }
};

export default function OrdersListV2() {
  const { chainId } = useChainId();
  const { isWeb3AuthAccount, account, signer } = useWallet();

  const setPendingTxns = useSetAtom(pendingTxnsAtom);

  const setSelectedOrder = useSetAtom(selectedOrderAtom);

  const openOrders = useAtomValue(openOrdersAtom);
  const isOpenOrdersLoading = useAtomValue(isOpenOrdersLoadingAtom);

  const pendingOrders = useAtomValue(pendingOrdersAtom);
  const isPendingOrdersLoading = useAtomValue(isPendingOrdersLoadingAtom);

  // order, pendingOrder 상태를 합쳐서 관리
  const isOrdersLoading = useMemo(() => {
    return isOpenOrdersLoading && isPendingOrdersLoading;
  }, [isOpenOrdersLoading, isPendingOrdersLoading]);

  // order, pendingOrder 상태를 합쳐서 관리
  const orders = useMemo(() => {
    if (
      openOrders &&
      openOrders.length > 0 &&
      pendingOrders &&
      pendingOrders.length > 0
    ) {
      return [...openOrders, ...pendingOrders];
    } else if (openOrders && openOrders.length > 0) {
      return openOrders;
    } else if (pendingOrders && pendingOrders.length > 0) {
      return pendingOrders;
    } else {
      return [];
    }
  }, [openOrders, pendingOrders]);

  const getOrderKey = (order: Order) => {
    if (order.block) {
      return `${order.pairIndex}:${order.positionIndex}:${
        order.buy
      }:${order.positionSize.toString()}:${
        order.leverage
      }:${order.sl.toString()}:${order.tp.toString()}:${order.block.toString()}`;
    }

    return `${order.pairIndex}:${order.positionIndex}:${
      order.buy
    }:${order.positionSize.toString()}:${
      order.leverage
    }:${order.sl.toString()}:${order.tp.toString()}:${order.apiOrderKey}`;
  };

  const [closingOrders, setClosingOrders] = useAtom(closingOrdersAtom);

  const isClosingOrder = (order: Order) => {
    return closingOrders
      ?.map((closingTrade) => getOrderKey(closingTrade))
      .includes(getOrderKey(order));
  };

  useEffect(() => {
    if (closingOrders && closingOrders.length > 0) {
      let updatedClosingOrders = [...closingOrders];
      updatedClosingOrders = updatedClosingOrders.filter((closingTrade) =>
        openOrders
          ?.map((openOrder) => getOrderKey(openOrder))
          ?.includes(getOrderKey(closingTrade))
      );

      setClosingOrders(updatedClosingOrders);
    }
    // TODO: 예외 처리 제거 setClosingOrders(prev => {}) 사용해서
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openOrders, setClosingOrders]);

  const [closingPendingOrders, setClosingPendingOrders] = useAtom(
    closingPendingOrdersAtom
  );
  const isClosingPendingOrder = (pendingOrder: PendingOrder) => {
    return closingPendingOrders
      ?.map((closingTrade) => getPendingOrderKey(closingTrade))
      .includes(getPendingOrderKey(pendingOrder));
  };

  const getPendingOrderKey = (pendingOrder: PendingOrder) => {
    return `${pendingOrder.trade.pairIndex}:${
      pendingOrder.trade.positionIndex
    }:${
      pendingOrder.trade.buy
    }:${pendingOrder.trade.positionSizeUsdc.toString()}:${
      pendingOrder.trade.leverage
    }:${pendingOrder.trade.sl.toString()}:${pendingOrder.trade.tp.toString()}:${pendingOrder.block.toString()}`;
  };

  useEffect(() => {
    if (closingPendingOrders && closingPendingOrders.length > 0) {
      let updatedClosingPendingOrders = [...closingPendingOrders];
      updatedClosingPendingOrders = updatedClosingPendingOrders.filter(
        (closingPendingOrder) =>
          pendingOrders
            ?.map((pendingOrder) => getPendingOrderKey(pendingOrder))
            ?.includes(getPendingOrderKey(closingPendingOrder))
      );

      setClosingPendingOrders(updatedClosingPendingOrders);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingOrders, setClosingPendingOrders]);

  const handleMarketTimeout = useCallback(
    (pendingOrder: PendingOrder) => {
      if (!signer) return;

      if (!isSupportedChain(chainId)) {
        const IsWalletConnectV2 =
          localStorage.getItem(SELECTED_WALLET_LOCALSTORAGE_KEY) ===
          WALLET_TYPES.WALLET_CONNECT_V2;
        showUnsupportedNetworkToast(IsWalletConnectV2, isWeb3AuthAccount);
        return;
      }

      setClosingPendingOrders((prev) => {
        if (prev) {
          return [...prev, pendingOrder];
        }
        return [pendingOrder];
      });

      let method: string;
      // close TIMEOUT -> closeTradeMarketTimeout 호출
      if (pendingOrder.trade.leverage === 0) {
        method = "closeTradeMarketTimeout";
      }
      // open TIMEOUT -> openTradeMarketTimeout 호출
      else {
        method = "openTradeMarketTimeout";
      }

      const contractAddress = getContract(chainId, "Trading");
      const contract = new ethers.Contract(
        contractAddress,
        getAbi(chainId, "Trading").abi,
        signer
      );

      callContract(chainId, contract, method, [pendingOrder.orderId], {
        value: bigNumberify(0),
        setPendingTxns,
        sentMsg: "",
        failMsg: "",
        successMsg: "",
        hideSuccessMsg: false,
        isWeb3AuthAccount,
      })
        .then(async () => {})
        .catch((e) => {
          setClosingPendingOrders((prev) => {
            if (prev) {
              return prev.filter(
                (closingPendingOrder) =>
                  getPendingOrderKey(pendingOrder) !==
                  getPendingOrderKey(closingPendingOrder)
              );
            }
            return prev;
          });
        })
        .finally(() => {
          // setIsTxSubmitting(false);
          // setIsPendingConfirmation(false);
        });
    },
    [
      signer,
      setClosingPendingOrders,
      chainId,
      setPendingTxns,
      isWeb3AuthAccount,
    ]
  );

  const setIsVisibleDetailOrderModal = useSetAtom(
    isVisibleDetailOrderModalAtom
  );

  const { tradingContractTypedDomain } = useGetTypedDomain();

  const apiOrders = useAtomValue(openApiOrdersAtom);

  const toastLimitOrStopOrder = useCallback((order: Order) => {
    helperToast.success(
      <div>
        <div className="toastify-title">
          <Trans>Order Cancelled</Trans>
        </div>
        <div>
          <div className="toastify-body">
            <span>
              {order.pair.from}/{order.pair.to}
            </span>
            <span
              className={cx(
                order.buy ? "text-green-2" : "text-red-2",
                "ml-[4px]"
              )}
            >
              {order.buy ? t`LONG` : t`SHORT`}
            </span>
          </div>
        </div>
      </div>,
      {
        autoClose: 7000,
        // toastId,
      }
    );
  }, []);

  const cancelApiOrder = useCallback(
    async (order: Order) => {
      if (!signer || !apiOrders || !order.apiOrderKey) return;

      const params = apiOrders[order.apiOrderKey];

      setClosingOrders((prev) => {
        if (prev) {
          return [...prev, order];
        }
        return [order];
      });

      try {
        const signature = await signer._signTypedData(
          tradingContractTypedDomain,
          {
            Trade: EIP712_TYPES.Trade,
            OpenTradeParams: EIP712_TYPES.OpenTradeParams,
          },
          params
        );

        try {
          const { data } = await axios.delete<string | ILimitFailResponse>(
            `${getAPIUrl(
              chainId
            )}/v1/gasless/limit?trader=${account}&signature=${signature}`,
            {
              data: {
                openLimitOrderId: order.apiOrderKey,
              },
            }
          );
          let status, code, message;

          if (typeof data !== "string") {
            status = data.status;
            code = data.code;
            message = data.message;
          } // * data가 string이면 API 호출 성공 케이스
          else {
            status = 200;
          }
          if (status === 200) {
            toastLimitOrStopOrder(order);
          } else {
            handleApiResult(status, code, message);
          }
        } catch (e: any) {
          // * API 에러 처리
          const { response } = e;

          const { data } = response;

          handleTradeApiError(
            data.name || "something went wrong",
            data.details?.customError
              ? `${data.message}(${data.details?.customError})`
              : data.message || "something went wrong",
            chainId,
            data.details?.customError ? data.details?.customError : ""
          );

          Sentry.setContext("/gasless/limit(delete) API Request Params", {
            signature,
            params,
            openLimitOrderId: order.apiOrderKey,
          });

          Sentry.setContext(
            "/gasless/limit(delete) API Error Response",
            response
          );

          sentryCaptureException({
            error: new Error("/gasless/limit(delete) API Error"),
            name: "Error Object",
            context: e,
          });
        }
      } catch (e: any) {
        // * 서명 에러 처리
        sentryCaptureException({
          error: new Error("Sign Error in cancelApiOrder"),
          name: "Error Object",
          context: e,
        });
      } finally {
        setClosingOrders((prev) => {
          if (prev) {
            return prev.filter(
              (closingOrder) => getOrderKey(order) !== getOrderKey(closingOrder)
            );
          }
          return prev;
        });
      }
    },
    [
      account,
      apiOrders,
      chainId,
      setClosingOrders,
      signer,
      toastLimitOrStopOrder,
      tradingContractTypedDomain,
    ]
  );

  const cancelOrder = useCallback(
    async (order: Order) => {
      if (order.source === "API") {
        // console.log("cancelApiOrder");
        cancelApiOrder(order);
      }
    },
    [cancelApiOrder]
  );

  const handleClickDetailOrder = (order: Order) => {
    setSelectedOrder(order);
    setIsVisibleDetailOrderModal(true);
  };

  const fromTokenAddress = getContract(chainId, "CollateralToken");
  const fromToken = getToken(chainId, fromTokenAddress);

  // leverage === 0   -> Closing
  // leverage > 0     -> Opening
  const getPendingOrderType = (pendingOrder: PendingOrder) => {
    if (pendingOrder.trade.leverage === 0) return t`Closing`;
    else return t`Opening`;
  };

  const pairsPrices = useAtomValue(pairsPricesAtom);
  const pairs = useAtomValue(pairsAtom);

  const tooltipContents = useAtomValue(tooltipContentsAtom);

  // const closingTrades = useAtomValue(closingTradesAtom);
  const openTrades = useAtomValue(parsedOpenTradesAtom);

  return (
    <div className="PositionsList">
      {/* {openOrders && ( */}
      <div className="Exchange-list">
        <div
          className={cx(
            "block bg-black-3 overflow-y-auto border border-white border-opacity-10 xs:rounded-[1.2rem]",
            tooltipContents
              ? "min-h-[26rem] max-h-[26rem]"
              : "min-h-[23.4rem] max-h-[23.4rem]"
          )}
        >
          <table className="Exchange-list whitespace-nowrap bg-black-3 text-[1.2rem] xs:rounded-[1.2rem]">
            <tbody>
              <tr className="z-[1] sticky top-0 bg-gray-80 rouned-[1.2rem] text-gray-15 font-medium">
                <th className="">
                  <Trans id="msg.positionList / Pair">Pair</Trans>
                </th>
                <th className="">
                  <Trans id="msg.orderList / Type">Type</Trans>
                </th>
                <th className="table-cell">
                  <Trans id="msg.tradeBox / Pay">Pay</Trans>
                </th>
                <th className="table-cell">
                  <Trans>Leverage</Trans>
                </th>
                <th className="table-cell">
                  <Trans>Ask Price</Trans>
                </th>
                <th className="">
                  <Trans>Fulfill Price</Trans>
                </th>
                <th className="table-cell">
                  <Trans>Stop Loss</Trans>
                </th>
                <th className="">
                  <Trans>Take Profit</Trans>
                </th>
                <th>
                  <Trans>State</Trans>
                </th>
                <th className=" ">
                  <Trans id="msg.orderList / Cancel">Cancel</Trans>
                </th>
              </tr>
              {!orders && isOrdersLoading && (
                <tr className="text-gray-30">
                  <td colSpan={15}>
                    <div className="pt-[7rem] pb-[8.5rem] text-center">
                      Loading...
                    </div>
                  </td>
                </tr>
              )}
              {(!account || orders?.length === 0) && (
                <tr className="text-gray-30">
                  <td colSpan={15}>
                    {/* <div className="pt-[7rem] pb-[8.5rem] text-center">
                      <Trans>Start your first trade!</Trans>
                    </div> */}
                    <div
                      className={cx(
                        "text-center",
                        tooltipContents ? "py-[4.3rem]" : "py-[3rem]"
                      )}
                    >
                      <GasFreeTextForPositionTable />
                    </div>
                  </td>
                </tr>
              )}
              {openOrders?.map((order, index) => {
                return (
                  <tr
                    className="text-gray-00 cursor-pointer hover:bg-white hover:bg-opacity-5"
                    onClick={() => handleClickDetailOrder(order)}
                    key={`${index}:${order.positionSize.toString()}`}
                  >
                    {/* Pair */}
                    <td className="font-space-grotesk">
                      {order.pair?.from}/{order.pair?.to}
                    </td>

                    {/* Type */}
                    <td className={order.buy ? "text-green-2" : "text-red-2"}>
                      {getOrderType(order)}
                    </td>

                    {/* Collateral */}
                    <td className="font-space-grotesk table-cell">
                      {formatAmount(
                        order.positionSize,
                        fromToken.decimals,
                        2,
                        true
                      )}{" "}
                      {fromToken.symbol}
                    </td>

                    {/* Leverage */}
                    <td className="font-space-grotesk table-cell">
                      {unpadZero(order.leverage.toFixed(2))}x
                    </td>

                    {/* Ask Price */}
                    <td className="font-space-grotesk table-cell">
                      ${trimPriceBN(order.minPrice, true)}
                    </td>

                    {/* Limit type일 때 예상체결가는 진입요청가격 */}
                    {/* Stop type일 때 예상체결가는 진입요청가격 + Spread */}
                    {/* Fulfill Price */}
                    <td className="font-space-grotesk table-cell">
                      {`$${getFulfillPrice(order, pairs, pairsPrices)}`}
                    </td>

                    {/* SL */}
                    <td className="font-space-grotesk table-cell">
                      {order.sl.isZero() ? (
                        "-"
                      ) : (
                        <div>${trimPriceBN(order.sl, true)}</div>
                      )}
                    </td>

                    {/* TP */}
                    <td className="font-space-grotesk">
                      {order.tp.isZero()
                        ? "-"
                        : `$${trimPriceBN(order.tp, true)}`}
                    </td>

                    {/* 지정가 / 스탑 주문의 경우 Empty, Pending 중일 때만 Opening / Closing 노출 */}
                    {/* State */}
                    <td>
                      <span>
                        <Trans>Normal</Trans>
                      </span>
                    </td>

                    {/* Cancel */}
                    <td
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      {isClosingOrder(order) ? (
                        <Lottie
                          className="w-[2rem] h-[2rem]"
                          animationData={pendingAnimation}
                        />
                      ) : (
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            cancelOrder(order);
                          }}
                          className="cursor-pointer align-sub"
                        >
                          <img src={CloseBtnImage} alt="close button" />
                        </button>
                      )}
                    </td>
                  </tr>
                );
              })}

              {pendingOrders?.map(
                (pendingOrder: PendingOrder, index: number) => {
                  /**
                   * INFO
                   *
                   * 서브그래프가 응답으로 반환하는 closing pendingOrder는 데이터가 0(leverage, collateral, price 등)으로 들어가 있어서
                   * Trade 목록에서 pairIndex, positionIndex가 같은 Trade를 찾아서 해당 정보 노출
                   */
                  const closingTrade = openTrades?.find(
                    (trade) =>
                      trade.pairIndex === pendingOrder.trade.pairIndex &&
                      trade.positionIndex === pendingOrder.trade.positionIndex
                  );

                  return (
                    <tr
                      className="text-gray-00 cursor-pointer font-normal hover:bg-white hover:bg-opacity-5"
                      onClick={() => {}}
                      key={`${index}${getPendingOrderKey(pendingOrder)}`}
                    >
                      {/* Pair */}
                      <td className="font-space-grotesk">
                        {pendingOrder.trade.pair.from}/
                        {pendingOrder.trade.pair.to}
                      </td>

                      {/* Type */}
                      <td
                        className={
                          pendingOrder.trade.buy ? "text-green-2" : "text-red-2"
                        }
                      >
                        <Trans>Pending</Trans>
                      </td>

                      {/* Pay */}
                      <td className="font-space-grotesk">
                        {closingTrade
                          ? formatAmount(
                              closingTrade.positionSizeUsdc,
                              fromToken.decimals,
                              2,
                              true
                            )
                          : formatAmount(
                              pendingOrder.trade.positionSizeUsdc,
                              fromToken.decimals,
                              2,
                              true
                            )}{" "}
                        {fromToken.symbol}
                      </td>

                      {/* Leverage */}
                      <td className="font-space-grotesk">
                        {closingTrade
                          ? unpadZero(closingTrade.leverage.toFixed(2))
                          : unpadZero(pendingOrder.trade.leverage.toFixed(2))}
                        x
                      </td>

                      <td>-</td>
                      <td>-</td>

                      {/* Stop Loss */}
                      <td className="font-space-grotesk">
                        $
                        {closingTrade
                          ? trimPriceBN(closingTrade.sl, true)
                          : trimPriceBN(pendingOrder.trade.sl, true)}
                      </td>

                      {/* Take Profit */}
                      <td className="font-space-grotesk">
                        $
                        {closingTrade
                          ? trimPriceBN(closingTrade.tp, true)
                          : trimPriceBN(pendingOrder.trade.tp, true)}
                      </td>

                      {/* State */}
                      <td>
                        <Tooltip
                          handle={
                            <span className="underline">
                              {getPendingOrderType(pendingOrder)}
                            </span>
                          }
                          position="center-bottom"
                          tooltipClassName="w-full text-center"
                          disableHandleStyle={true}
                          renderContent={() => (
                            <span>
                              <Trans>Your order is being executed.</Trans>
                            </span>
                          )}
                        />
                      </td>

                      {/* Cancel */}
                      <td>
                        {isClosingPendingOrder(pendingOrder) ? (
                          <Lottie
                            className="w-[2rem] h-[2rem]"
                            animationData={pendingAnimation}
                          />
                        ) : (
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleMarketTimeout(pendingOrder);
                            }}
                            className="cursor-pointer"
                          >
                            <img src={CloseBtnImage} alt="close button" />
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        </div>
      </div>
      {/* )} */}
    </div>
  );
}
