import { existingRequestsAtom } from "atoms/vault";
import { getAbi, getContract } from "config/contracts";
import { useChainId } from "futures-lib/chains";
import { gambitContractFetcherForExistingRequestsVault } from "futures-lib/contracts/gambitContractFetcher";
import useWallet from "futures-lib/wallets/useWallet";
import { useSetAtom } from "jotai";
import { useEffect } from "react";
import useSWR from "swr";

/**
 * Vault에서 현재 연결된 지갑의 인출과 관련된 정보를 가져온다.
 */
export const useExistingReuqestsVault = (currentEpoch: number, withdrawEpochsTimeLock: number) => {
  const { isActive, account, signer } = useWallet();
  const { chainId } = useChainId();
  const vaultAddress = getContract(chainId, "Vault");
  const setExistingRequests = useSetAtom(existingRequestsAtom);

  /**
   ** !== 이 아니라 !=를 사용한 이유
   * null도 아니고 undefined도 아닌 걸 확인하고 싶고
   * 0인 경우는 허용하고 싶어서
   */
  const { data, isLoading, error } = useSWR(
    currentEpoch != null &&
      withdrawEpochsTimeLock != null &&
      isActive && [isActive, chainId, vaultAddress, "withdrawRequests", account],
    {
      fetcher: gambitContractFetcherForExistingRequestsVault(signer, getAbi(chainId, "Vault"), [
        currentEpoch,
        withdrawEpochsTimeLock,
      ]),
      refreshInterval: 2000,
      errorRetryInterval: 500,
      errorRetryCount: 100,
    }
  );

  useEffect(() => {
    if (!error && data && !isLoading) {
      setExistingRequests(data);
    }
  }, [data, isLoading, error, setExistingRequests, chainId]);
};
