import { GambitStakingV1 } from "@changerio/futures-contracts/dist/typechain-types";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import pendingAnimation from "animation/pending.json";
import { showSignInModalAtom } from "atoms";
import { pendingTxnsAtom } from "atoms/exchange";
import BN from "bignumber.js";
import cx from "classnames";
import SEO from "components/Common/SEO";
import Tooltip from "components/Tooltip/Tooltip";
import {
  ARBITRUM,
  ARBITRUM_GOERLI,
  getChainNameForContract,
} from "config/chains";
import { getContract } from "config/contracts";
import { getToken } from "config/tokens";
import { BigNumber, constants } from "ethers";
import { approveToken } from "futures-domain/tokens/approveTokenV2";
import { STAKING_TAB_TYPE, StakingTabType } from "futures-domain/trades/types";
import {
  threeDotsToEmptyString,
  threeDotsToZero,
} from "futures-domain/trades/utils";
import { APP_MODES } from "futures-domain/types";
import { useChainId } from "futures-lib/chains";
import {
  OptionsT,
  callTypeChainContract,
  newContractFetcher,
} from "futures-lib/contracts";
import { getCngContract, useGetContract } from "futures-lib/contracts/contract";
import { getPageTitle, swrFetcher } from "futures-lib/legacy";
import {
  formatAmount,
  numberWithCommas,
  parseValue,
} from "futures-lib/numbers";
import { usePrevious } from "futures-lib/usePrevious";
import { switchNetwork } from "futures-lib/wallets";
import useWallet from "futures-lib/wallets/useWallet";
import { ReactComponent as AvatarSvg } from "img/ic-avatar.svg";
import { ReactComponent as CalculatorSvg } from "img/ic-calculator.svg";
import cngSvg from "img/ic-cng.svg";
import { ReactComponent as CoinBundleSvg } from "img/ic-coin-bundle.svg";
import { ReactComponent as DollarSignSvg } from "img/ic-dollar-sign.svg";
import { ReactComponent as InfoCircleSvg } from "img/ic-info-circle.svg";
import InvalidImage from "img/ic-invalid.svg";
import loader from "img/ic-loader.svg";
import { ReactComponent as StakingCngSvg } from "img/ic-staking-vault-cng.svg";
import { useAtom, useSetAtom } from "jotai";
import Lottie from "lottie-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import useSWR from "swr";

// import { APP_MODES } from "futures-domain/types";

interface CNGUser {
  stakedTokens?: BigNumber;
  harvestedRewardsUsdc?: BigNumber;
  pendingRewardUsdc?: BigNumber;
}

const Staking = () => {
  const { i18n } = useLingui();

  const { chainId } = useChainId();
  const setShowSignInModal = useSetAtom(showSignInModalAtom);

  const unSupportedChain = useMemo(() => {
    // if (process.env.REACT_APP_MODE === APP_MODES.LOCAL) return false;
    if (process.env.REACT_APP_MODE !== APP_MODES.PRODUCTION) {
      if (![ARBITRUM, ARBITRUM_GOERLI].includes(chainId)) return true;
    } else if (process.env.REACT_APP_MODE === APP_MODES.PRODUCTION) {
      if (chainId !== ARBITRUM) return true;
    }

    return false;
  }, [chainId]);

  const {
    isActive,
    account,
    signer,
    chainId: walletChainId,
    isWeb3AuthAccount,
  } = useWallet();
  const shortAddress = useMemo(() => {
    if (!account || !isActive) return;

    return `${account?.slice(0, 6)}...${account.slice(-4)}`;
  }, [account, isActive]);

  const [depositValue, setDepositValue] = useState("");
  const [withdrawValue, setWithdrawValue] = useState("");

  const [activeTab, setActiveTab] = useState<StakingTabType>(
    STAKING_TAB_TYPE.DEPOSIT
  );

  const fromTokenAddress = getContract(chainId, "CollateralToken");
  const fromToken = getToken(chainId, fromTokenAddress);

  const cngTokenAddress = getContract(chainId, "CNG");
  const cngToken = getToken(chainId, cngTokenAddress);

  const cngContract = useMemo(() => {
    try {
      if (!signer) return;

      return getCngContract(getChainNameForContract(chainId), signer, chainId);
    } catch (e) {}
  }, [chainId, signer]);

  const cngStakingContract = useGetContract(
    "GambitStakingV1"
  ) as GambitStakingV1;

  // const cngStakingContract = useMemo(() => {
  //   try {
  //     if (!isActive) {
  //       const rpcUrl = getRpcUrl(chainId);
  //       const _provider = new JsonRpcProvider(rpcUrl);
  //       return getCngStakingContract(getChainNameForContract(chainId), _provider);
  //     }

  //     if (!signer) return;

  //     if (isActive) {
  //       return getCngStakingContract(getChainNameForContract(chainId), signer);
  //     }
  //   } catch (e) {}
  // }, [chainId, isActive, signer]);

  const { data: cngBalance } = useSWR<BigNumber>(
    isActive && account && cngContract && [cngContract, "balanceOf", [account]],
    {
      fetcher: newContractFetcher,
      shouldRetryOnError: true,
      errorRetryInterval: 2000,
      refreshInterval: 2000,
    }
  );

  const { data: cngAllowance } = useSWR<BigNumber>(
    isActive &&
      account &&
      cngContract &&
      cngStakingContract && [
        cngContract,
        "allowance",
        [account, cngStakingContract?.address],
      ],
    {
      fetcher: newContractFetcher,
      shouldRetryOnError: true,
      errorRetryInterval: 500,
    }
  );
  // console.log(cngAllowance);
  const [cngUserData, setCngUserData] = useState<CNGUser>();

  const { data: userStakingData } = useSWR(
    isActive &&
      account &&
      cngStakingContract && [cngStakingContract, "users", [account]],
    {
      fetcher: newContractFetcher,
      refreshInterval: 2000,
      shouldRetryOnError: true,
      errorRetryInterval: 2000,
    }
  );

  // useEffect(() => {
  //   if (userStakingData) {
  //     console.log("userStakingData");
  //     console.log(`stakedTokens:         ${formatUnits(userStakingData[0], 18)}`);
  //     console.log(`harvestedRewardsUsdc: ${formatUnits(userStakingData[2], 6)}`);
  //   }
  // }, [userStakingData]);

  const { data: pendingRewardUsdc } = useSWR<BigNumber>(
    isActive &&
      account &&
      cngStakingContract && [
        cngStakingContract,
        "pendingRewardUsdc",
        [account],
      ],
    {
      fetcher: newContractFetcher,
      refreshInterval: 2000,
      shouldRetryOnError: true,
      errorRetryInterval: 2000,
    }
  );
  // console.log(`pendingRewardUsdc: ${pendingRewardUsdc}`);

  const { data: tokenBalance } = useSWR<BigNumber>(
    cngStakingContract && [cngStakingContract, "tokenBalance", []],
    {
      fetcher: newContractFetcher,
      refreshInterval: 2000,
      shouldRetryOnError: true,
      errorRetryInterval: 2000,
    }
  );

  const accRewardUsdc = useMemo(() => {
    if (!cngUserData || !pendingRewardUsdc) return;
    return formatAmount(
      cngUserData.harvestedRewardsUsdc?.add(pendingRewardUsdc),
      fromToken.decimals,
      2,
      true
    );
  }, [cngUserData, fromToken.decimals, pendingRewardUsdc]);
  // console.log(`accRewardUsdc: ${accRewardUsdc}`);

  useEffect(() => {
    if (userStakingData) {
      const [stakedTokens, , harvestedRewardsUsdc] = userStakingData;
      setCngUserData((prev) => ({
        ...prev,
        stakedTokens,
        harvestedRewardsUsdc,
      }));
    }

    if (pendingRewardUsdc) {
      setCngUserData((prev) => ({
        ...prev,
        pendingRewardUsdc,
      }));
    }
  }, [pendingRewardUsdc, userStakingData]);

  // console.log(userStakingData);

  const [isApproving, setIsApproving] = useState(false);
  const [isWaitingForApproval, setIsWaitingForApproval] = useState(false);

  const depositAmount = useMemo(() => {
    return parseValue(depositValue, cngToken.decimals);
  }, [cngToken.decimals, depositValue]);

  const withdrawAmount = useMemo(() => {
    return parseValue(withdrawValue, cngToken.decimals);
  }, [cngToken.decimals, withdrawValue]);

  const needVaultApproval = useMemo(() => {
    const result =
      cngTokenAddress !== constants.AddressZero &&
      cngAllowance &&
      depositAmount &&
      depositAmount.gt(cngAllowance);

    return result;
  }, [depositAmount, cngTokenAddress, cngAllowance]);

  const prevNeedVaultApproval = usePrevious(needVaultApproval);

  useEffect(() => {
    if (!needVaultApproval && prevNeedVaultApproval && isWaitingForApproval) {
      setIsWaitingForApproval(false);
    }
  }, [isWaitingForApproval, needVaultApproval, prevNeedVaultApproval]);

  const [pendingTxns, setPendingTxns] = useAtom(pendingTxnsAtom);

  interface IsTxsubmitting {
    depositTxIsSubmitting: boolean;
    withdrawTxIsSubmitting: boolean;
    harvestTxIsSubmitting: boolean;
  }

  const initialIsTxsubmitting = {
    depositTxIsSubmitting: false,
    withdrawTxIsSubmitting: false,
    harvestTxIsSubmitting: false,
  };

  const [isTxSubmitting, setIsTxSubmitting] = useState<IsTxsubmitting>(
    initialIsTxsubmitting
  );

  const handleDepositMaxBtn = useCallback(() => {
    if (!isActive) return;

    const maxAmount = formatAmount(cngBalance, cngToken.decimals, 2, false);
    setDepositValue(maxAmount);
  }, [isActive, cngBalance, cngToken.decimals]);

  const handleWithdrawMaxBtn = useCallback(() => {
    if (!isActive || !cngUserData?.stakedTokens) return;

    const maxAmount = formatAmount(
      cngUserData.stakedTokens,
      cngToken.decimals,
      2,
      false
    );
    setWithdrawValue(maxAmount);
  }, [isActive, cngUserData?.stakedTokens, cngToken.decimals]);

  const depositButtonText = useCallback(() => {
    // Sign In | Approve | Approving... | Waiting for Approval | Submit
    // if (!isActive) {
    //   return t`Sign In`;
    // }

    if (isApproving) {
      return t`Approving...`;
    }

    if (needVaultApproval && isWaitingForApproval) {
      return t`Waiting for Approval`;
    }

    if (needVaultApproval) {
      return t`Approve`;
    }

    return t`Stake`;
  }, [isApproving, isWaitingForApproval, needVaultApproval]);

  const withdrawButtonText = useCallback(() => {
    // Sign In | Approve | Approving... | Waiting for Approval | Submit
    // if (!isActive) {
    //   return t`Sign In`;
    // }

    return t`Withdraw`;
  }, []);

  const deposit = useCallback(async () => {
    if (!signer || !depositAmount || !cngStakingContract) return;

    setIsTxSubmitting({
      ...isTxSubmitting,
      depositTxIsSubmitting: true,
    });

    const options: OptionsT = {
      setPendingTxns,
      sentMsg: t`Deposit Submitted`,
      failMsg: t`Deposit Failed`,
      successMsg: t`Deposit Success`,
    };

    // const cngStakingAddress = getContract(chainId, "CngStaking");
    // const _cngStakingContract = new ethers.Contract(
    //   cngStakingAddress,
    //   getAbi(chainId, "CngStaking").abi,
    //   signer
    // );

    // callContract(chainId, _cngStakingContract, "stakeTokens", [depositAmount], options, walletChainId)
    //   .then()
    //   .finally(() => {
    //     setIsTxSubmitting({
    //       ...isTxSubmitting,
    //       depositTxIsSubmitting: false,
    //     });
    //     setDepositValue("");
    //   });

    callTypeChainContract(
      chainId,
      cngStakingContract,
      "stakeTokens",
      [depositAmount],
      options,
      walletChainId
    )
      .then()
      .finally(() => {
        setIsTxSubmitting({
          ...isTxSubmitting,
          depositTxIsSubmitting: false,
        });
        setDepositValue("");
      });
  }, [
    chainId,
    cngStakingContract,
    depositAmount,
    isTxSubmitting,
    signer,
    setPendingTxns,
    walletChainId,
  ]);

  const withdraw = useCallback(async () => {
    if (!signer || !withdrawAmount || !cngStakingContract) return;

    setIsTxSubmitting({
      ...isTxSubmitting,
      withdrawTxIsSubmitting: true,
    });

    const options = {
      setPendingTxns,
      sentMsg: t`Withdraw Submitted`,
      failMsg: t`Withdraw Failed`,
      successMsg: t`Withdraw Success`,
    };

    callTypeChainContract(
      chainId,
      cngStakingContract,
      "unstakeTokens",
      [withdrawAmount.toString()],
      options,
      walletChainId
    )
      .then()
      .finally(() => {
        setIsTxSubmitting({
          ...isTxSubmitting,
          withdrawTxIsSubmitting: false,
        });
        setWithdrawValue("");
      });
  }, [
    chainId,
    cngStakingContract,
    isTxSubmitting,
    signer,
    setPendingTxns,
    walletChainId,
    withdrawAmount,
  ]);

  const harvest = useCallback(async () => {
    if (!signer || !cngStakingContract) return;

    setIsTxSubmitting({
      ...isTxSubmitting,
      harvestTxIsSubmitting: true,
    });

    const options = {
      setPendingTxns,
      sentMsg: t`Claim Submitted`,
      failMsg: t`Claim Failed`,
      successMsg: t`Claim Success`,
    };

    callTypeChainContract(
      chainId,
      cngStakingContract,
      "harvest",
      [],
      options,
      walletChainId
    )
      .then()
      .finally(() => {
        setIsTxSubmitting({
          ...isTxSubmitting,
          harvestTxIsSubmitting: false,
        });
        setWithdrawValue("");
      });
  }, [
    chainId,
    cngStakingContract,
    isTxSubmitting,
    signer,
    setPendingTxns,
    walletChainId,
  ]);

  const approveCngToken = useCallback(() => {
    if (!signer || !cngStakingContract) return;

    approveToken({
      setIsApproving,
      signer,
      tokenAddress: cngToken.address,
      spender: cngStakingContract?.address,
      chainId,
      onApproveSubmitted: () => {
        setIsWaitingForApproval(true);
      },
      pendingTxns,
      setPendingTxns,
    });
  }, [
    signer,
    cngStakingContract,
    cngToken.address,
    chainId,
    pendingTxns,
    setPendingTxns,
  ]);

  const handleDepositButton = useCallback(() => {
    // approve / deposit
    if (!isActive) {
      setShowSignInModal(true);
      return;
    }

    if (needVaultApproval) {
      approveCngToken();
      return;
    }

    deposit();
    return;
  }, [
    isActive,
    needVaultApproval,
    deposit,
    setShowSignInModal,
    approveCngToken,
  ]);

  const handleWithdrawButton = useCallback(() => {
    if (!isActive) {
      setShowSignInModal(true);
      return;
    }

    withdraw();
    return;
  }, [isActive, setShowSignInModal, withdraw]);

  const disabledHarvestButton = useMemo(() => {
    if (!isActive || !pendingRewardUsdc) return true;
    if (pendingRewardUsdc.isZero()) return true;
    if (unSupportedChain) return true;

    return false;
  }, [isActive, pendingRewardUsdc, unSupportedChain]);

  const handleHarvestButton = useCallback(() => {
    if (!isActive) {
      setShowSignInModal(true);
      return;
    }

    harvest();
    return;
  }, [isActive, harvest, setShowSignInModal]);

  const disabledDepositMaxButton = useMemo(() => {
    if (!isActive) {
      return true;
    }

    if (unSupportedChain) return true;

    return !cngBalance || cngBalance.isZero();
  }, [cngBalance, isActive, unSupportedChain]);

  const disabledDepositButton = useMemo(() => {
    if (!isActive) {
      return true;
    }

    if (unSupportedChain) return true;

    return (
      isApproving ||
      isWaitingForApproval ||
      !depositAmount ||
      depositAmount?.isZero() ||
      !cngBalance ||
      cngBalance.isZero() ||
      isTxSubmitting.depositTxIsSubmitting ||
      depositAmount.gt(cngBalance)
    );
  }, [
    isActive,
    unSupportedChain,
    isApproving,
    isWaitingForApproval,
    depositAmount,
    cngBalance,
    isTxSubmitting.depositTxIsSubmitting,
  ]);

  // * coingekco 쪽 public API가 간간히 죽어서 CeFi팀 API로 변경
  // const {
  //   data: cngPriceData,
  //   isLoading: isCngPriceLoading,
  //   error: cngPriceError,
  // } = useSWR(
  //   "https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=0x5c1d9aa868a30795f92fae903edc9eff269044bf&vs_currencies=usd",
  //   {
  //     fetcher: swrFetcher,
  //     refreshInterval: 1000 * 15,
  //   }
  // );

  // useEffect(() => {
  //   if (!isCngPriceLoading && cngPriceData && !cngPriceError) {
  //     const {
  //       "0x5c1d9aa868a30795f92fae903edc9eff269044bf": { usd: _cngPrice },
  //     } = cngPriceData;

  //     setCngPrice(_cngPrice);
  //   }
  // }, [cngPriceData, cngPriceError, isCngPriceLoading]);

  const {
    data: cngPriceData,
    isLoading: isCngPriceLoading,
    error: cngPriceError,
  } = useSWR("https://api.alt.pro/v1/common/coin/63db0dba8a1c309f1e81318a", {
    fetcher: swrFetcher,
    refreshInterval: 1000 * 20,
  });

  const [cngPrice, setCngPrice] = useState<string>();

  useEffect(() => {
    if (!isCngPriceLoading && cngPriceData && !cngPriceError) {
      const {
        currency: { usdPrice },
      } = cngPriceData;
      if (usdPrice) {
        setCngPrice(usdPrice);
      }
    }
  }, [cngPriceData, cngPriceError, isCngPriceLoading]);

  const tokenBalancePriceValue = useMemo(() => {
    if (!cngPrice || !tokenBalance) return;
    const _tokenBalance = formatAmount(tokenBalance, cngToken.decimals);
    const result = new BN(cngPrice).multipliedBy(_tokenBalance).toFixed(2);
    return numberWithCommas(result);
  }, [cngPrice, cngToken.decimals, tokenBalance]);

  const stakedCngTokens = useMemo(() => {
    if (!cngUserData?.stakedTokens) return;

    return formatAmount(cngUserData?.stakedTokens, cngToken.decimals, 2, false);
  }, [cngToken.decimals, cngUserData?.stakedTokens]);

  const disabledWithdrawMaxButton = useMemo(() => {
    if (!isActive) {
      return true;
    }

    if (unSupportedChain) return true;

    return (
      !cngUserData?.stakedTokens ||
      !stakedCngTokens ||
      new BN(stakedCngTokens).lte(0)
    );
  }, [cngUserData?.stakedTokens, isActive, stakedCngTokens, unSupportedChain]);

  const disabledWithdrawButton = useMemo(() => {
    if (!isActive) {
      return true;
    }

    if (unSupportedChain) return true;

    return (
      isApproving ||
      isWaitingForApproval ||
      !withdrawAmount ||
      withdrawAmount?.isZero() ||
      isTxSubmitting.withdrawTxIsSubmitting ||
      !cngUserData?.stakedTokens ||
      !stakedCngTokens ||
      new BN(stakedCngTokens).lte(0) ||
      withdrawAmount.gt(cngUserData?.stakedTokens)
    );
  }, [
    cngUserData?.stakedTokens,
    isActive,
    isApproving,
    isTxSubmitting.withdrawTxIsSubmitting,
    isWaitingForApproval,
    stakedCngTokens,
    unSupportedChain,
    withdrawAmount,
  ]);

  useEffect(() => {
    if (!isActive) {
      setCngUserData({});
    }
  }, [isActive]);

  const stakedCngPriceValue = useMemo(() => {
    if (!cngPrice || !stakedCngTokens) return;

    const result = new BN(cngPrice).multipliedBy(stakedCngTokens).toFixed(2);
    return numberWithCommas(result);
  }, [cngPrice, stakedCngTokens]);

  const { data: aprData } = useSWR(
    chainId === ARBITRUM &&
      "https://service.gambit.trade:7443/query/vault/apr?network=arbitrum",
    {
      fetcher: swrFetcher,
      refreshInterval: 60 * 1000 * 5, // * 5분
    }
  );

  const cngAPR = useMemo(() => {
    if (!chainId) return;
    if (!aprData) return 10;
    const apr = aprData.cng_apr as string;

    return (+apr).toFixed(2) ?? 7;
  }, [aprData, chainId]);

  return (
    <SEO
      title={i18n._(
        getPageTitle(
          t({
            id: "msg.title / Staking",
            message: "Staking",
          })
        )
      )}
    >
      <div className="max-w-[108rem] mx-auto sm:pt-[3.5rem] px-[1.6rem] pb-[1.6rem]">
        <div className="flex items-center justify-center mt-[2.6rem]">
          <StakingCngSvg className="w-[3.2rem] h-[3.2rem] mr-[8px] md+:w-[4.8rem] md+:h-[4.8rem]" />
          <div className="text-[2.8rem] md+:text-[4.8rem] font-bold">
            <Trans>CNG Staking Vault</Trans>
          </div>
        </div>

        {unSupportedChain && (
          <div className="bg-red-400 bg-opacity-50 text-gray-200 text-[1.6rem] py-[1rem] px-[1rem] flex justify-center items-center text-center">
            {/* <img className="h-[2.8rem] w-[2.8rem] mr-[4px]" src={InvalidImage} alt="invalid" /> */}
            <span>
              <Trans>
                CNG Staking is only supported on the{" "}
                <button
                  onClick={() =>
                    switchNetwork(ARBITRUM, isActive, isWeb3AuthAccount)
                  }
                  className="underline hover:opacity-70"
                >
                  Arbitrum <Trans>network</Trans>
                </button>
              </Trans>
            </span>
          </div>
        )}

        <div className="flex items-start justify-between md+:gap-[2rem] md+:justify-center mt-[2.4rem] mb-[3.6rem]">
          <div className="flex items-center gap-[1.2rem]">
            <div className="hidden md+:flex items-center justify-center rounded-[4px] w-[3.6rem] h-[3.6rem] bg-gray-80">
              <DollarSignSvg />
            </div>
            <div className="flex flex-col md+:gap-[4px]">
              <div className="flex items-center heading9 text-gray-15">
                <span>
                  CNG <Trans id="msg.staking / Price">Price</Trans>
                </span>
              </div>
              <div className="heading5 md+:heading4 text-gray-00 font-space-grotesk">
                <span className="mr-[2px]">
                  {!cngPrice ? "" : `$${new BN(cngPrice).toFixed(4)}`}
                </span>
                {/* <span>{!cngPrice ? "" : cngPrice}</span> */}
              </div>
            </div>
          </div>
          <div>
            <div className="flex items-center gap-[1.2rem]">
              <div className="hidden md+:flex items-center justify-center rounded-[4px] w-[3.6rem] h-[3.6rem] bg-gray-80">
                <CoinBundleSvg />
              </div>
              <div className="flex flex-col md+:gap-[4px]">
                <Tooltip
                  handle={
                    <div className="flex items-center heading9 text-gray-15">
                      <span>
                        <Trans id="msg.staking / CNG Vault">CNG Vault</Trans>
                      </span>
                      <InfoCircleSvg className="ml-[4px] fill-gray-50" />
                    </div>
                  }
                  disableHandleStyle={true}
                  position="center-top"
                  tooltipClassName="w-[28rem] md+:w-[33rem]"
                  renderContent={() => (
                    <span className="text-[1.4rem]">
                      <Trans>
                        Indicates how many CNG tokens are in the Gambit staking
                        vault
                      </Trans>
                    </span>
                  )}
                />
                <span className="heading5 md+:heading4 text-gray-00 font-space-grotesk">
                  {threeDotsToEmptyString(
                    formatAmount(tokenBalance, cngToken.decimals, 2, true)
                  )}{" "}
                  CNG
                </span>
              </div>
            </div>
            <div className="flex gap-[1.2rem]">
              <div className="hidden md+:block w-[3.6rem]" />
              <span className="tiny font-space-grotesk text-gray-40">
                {tokenBalancePriceValue && `($${tokenBalancePriceValue})`}
              </span>
            </div>
          </div>
          <div className="flex items-center gap-[1.2rem]">
            <div className="hidden md+:flex items-center justify-center rounded-[4px] w-[3.6rem] h-[3.6rem] bg-gray-80">
              <CalculatorSvg />
            </div>
            <div className="flex flex-col md+:gap-[4px]">
              <Tooltip
                handle={
                  <div className="flex items-center heading9 text-gray-15">
                    <span>
                      <Trans>CNG Vault APR</Trans>
                    </span>
                    <InfoCircleSvg className="ml-[4px] fill-gray-50" />
                  </div>
                }
                disableHandleStyle={true}
                position="right-top"
                tooltipClassName="w-[28rem] md+:w-[33rem]"
                renderContent={() => (
                  <span className="text-[1.4rem]">
                    <Trans>
                      The expected yield gained from staking CNG tokens
                    </Trans>
                  </span>
                )}
              />
              <span className="heading5 md+:heading4 text-gray-00 font-space-grotesk">
                {cngAPR}%
              </span>
            </div>
          </div>
        </div>

        <div className="max-w-[98rem] mx-auto bg-gray-80 rounded-[1.2rem] p-[1.6rem] md+:p-[2.4rem]">
          <div
            className={cx("flex justify-between items-center", {
              "mb-[2.4rem]": isActive,
            })}
          >
            {shortAddress ? (
              <div className="flex items-center">
                <AvatarSvg className="w-[2.4rem] h-[2.4rem] mr-[8px]" />
                <span className="font-space-grotesk heading7 text-gray-05">
                  {shortAddress}
                </span>
              </div>
            ) : (
              <div
                onClick={() => {
                  setShowSignInModal(true);
                }}
                // className="bg-white rounded-[6px] py-[0.5rem] px-[1rem] heading7 text-gray-90 cursor-pointer"
                className="brand-btn w-auto h-auto py-[0.5rem] px-[1rem] heading7 rounded-[6px]"
              >
                <Trans>Sign In</Trans>
              </div>
            )}
            <button
              onClick={handleHarvestButton}
              className="flex items-center bg-white rounded-[6px] h-[2.8rem] md+:h-[3.2rem] py-[0.5rem] md+:py-[0.7rem] px-[1rem] heading7 text-gray-90 cursor-pointer"
              disabled={disabledHarvestButton}
            >
              {isTxSubmitting.harvestTxIsSubmitting ? (
                <Lottie
                  className="w-[2rem] h-[2rem]"
                  animationData={pendingAnimation}
                />
              ) : (
                <Trans>Claim Reward</Trans>
              )}
            </button>
          </div>
          {isActive && (
            <div className="flex flex-col gap-[2.8rem] items-center md+:items-start md+:flex-row md+:justify-around lg-:justify-between lg-:px-[11rem]">
              <div className="space-y-[6px]">
                <div className="heading7 text-gray-15 text-center">
                  <Trans>Accumulated Reward</Trans>
                </div>
                <div className="heading1 text-center font-space-grotesk">
                  {accRewardUsdc ? accRewardUsdc : ""} {fromToken.symbol}
                </div>
              </div>

              <div className="space-y-[6px]">
                <div className="heading7 text-gray-15 text-center">
                  <Trans>Pending Reward</Trans>
                </div>
                <div className="heading1 text-center font-space-grotesk">
                  {threeDotsToEmptyString(
                    formatAmount(pendingRewardUsdc, fromToken.decimals, 2, true)
                  )}{" "}
                  {fromToken.symbol}
                </div>
              </div>

              <div className="space-y-[6px]">
                <div className="heading7 text-gray-15 text-center">
                  <Trans>Staked CNG</Trans>
                </div>
                <div className="text-center font-space-grotesk">
                  <span className="heading1 block">
                    {stakedCngTokens ? numberWithCommas(stakedCngTokens) : ""}{" "}
                    CNG
                  </span>
                  <span className="heading7 text-gray-30 block">
                    {stakedCngPriceValue && `($${stakedCngPriceValue})`}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Deposit / Withdraw Tabs */}
        <div className="flex text-center text-[1.4rem] mt-[1.6rem] lg-:hidden">
          <button
            onClick={() => setActiveTab(STAKING_TAB_TYPE.DEPOSIT)}
            className={cx(
              "flex-1 rounded-tl-[0.6rem] rounded-bl-[0.6rem] py-[0.9rem] font-medium cursor-pointer",
              activeTab === STAKING_TAB_TYPE.DEPOSIT
                ? "bg-white text-black-2"
                : "bg-gray-80 text-gray-15"
            )}
          >
            <Trans id="msg.staking / Deposit tab">Deposit</Trans>
          </button>
          <button
            onClick={() => setActiveTab(STAKING_TAB_TYPE.WITHDRAW)}
            className={cx(
              "flex-1 rounded-tr-[0.6rem] rounded-br-[0.6rem] py-[0.9rem] font-medium cursor-pointer",
              activeTab === STAKING_TAB_TYPE.WITHDRAW
                ? "bg-white text-black-2"
                : "bg-gray-80 text-gray-15"
            )}
          >
            <Trans id="msg.staking / Withdraw tab">Withdraw</Trans>
          </button>
        </div>

        <div className="max-w-[98rem] mx-auto bg-gray-80 rounded-[1.2rem] p-[1.6rem] md+:p-[2.4rem] mt-[1.6rem] md+:mt-[2.4rem] lg-:flex lg-:gap-[2.4rem]">
          <div
            className={cx(
              "flex-1",
              activeTab === STAKING_TAB_TYPE.DEPOSIT
                ? "block"
                : "hidden lg-:block"
            )}
          >
            <div className="flex justify-center heading4">
              <Trans>Deposit CNG</Trans>
            </div>
            <div className="my-[2.4rem] space-y-[8px]">
              <div className="flex items-center h-[6rem] justify-between bg-black-2 rounded-[1.2rem] pt-[1.6rem] pb-[1.8rem] px-[1.6rem]">
                <div className="flex items-center">
                  <img
                    className="w-[2.6rem] h-[2.6rem]"
                    src={cngSvg}
                    alt="changer token"
                  />
                  <span className="ml-[6px] mr-[8px] heading3 font-space-grotesk">
                    CNG
                  </span>
                  <button
                    disabled={disabledDepositMaxButton}
                    onClick={handleDepositMaxBtn}
                    className="bg-green-3 mr-[8px] rounded-[0.4rem] py-[0.4rem] px-[0.6rem] text-[1.2rem] text-green-1 font-medium cursor-pointer self-center"
                  >
                    <Trans>MAX</Trans>
                  </button>
                </div>
                <input
                  className="flex-1 font-space-grotesk text-right heading2 text-white"
                  value={depositValue}
                  onChange={(e) => {
                    if (!/^([0-9]*(?:[.,][0-9]*)?)$/.test(e.target.value)) {
                      return;
                    }
                    // INFO: 소수점 6자리까지만 입력 허용
                    if (/^[\d]*\.?[\d]{0,6}$/.test(e.target.value)) {
                      setDepositValue(e.target.value);
                    } else {
                      return;
                    }
                  }}
                  placeholder="0"
                />
              </div>
              <div className="text-right">
                <span className="heading9 text-gray-30">
                  <Trans>Balance</Trans>
                </span>
                <span className="heading9 ml-[4px] font-space-grotesk">
                  {threeDotsToZero(
                    formatAmount(cngBalance, cngToken.decimals, 2, true)
                  )}{" "}
                  CNG
                </span>
              </div>
            </div>
            <button
              onClick={handleDepositButton}
              disabled={disabledDepositButton}
              className={cx(
                "flex items-center justify-center brand-btn h-[4.9rem]"
              )}
            >
              {isTxSubmitting.depositTxIsSubmitting ? (
                <img className="mx-auto h-[0.8rem]" src={loader} alt="loader" />
              ) : (
                depositButtonText()
              )}
            </button>
            {!isActive && (
              <div className="flex mt-[0.8rem]">
                <img className="mr-[4px]" src={InvalidImage} alt="invalid" />
                <span className="heading9 text-gray-15 tracking-normal">
                  <Trans id="msg.staking / Sign In Message">Sign In</Trans>
                </span>
              </div>
            )}
          </div>

          <div
            className={cx(
              "flex-1",
              activeTab === STAKING_TAB_TYPE.WITHDRAW
                ? "block"
                : "hidden lg-:block"
            )}
          >
            <div className="flex justify-center heading4">
              <Trans>Extract CNG</Trans>
            </div>
            <div className="my-[2.4rem] space-y-[8px]">
              <div className="flex justify-between items-center h-[6rem] bg-black-2 rounded-[1.2rem] pt-[1.6rem] pb-[1.8rem] px-[1.6rem]">
                <div className="flex items-center">
                  <img
                    className="w-[2.6rem] h-[2.6rem]"
                    src={cngSvg}
                    alt="changer token"
                  />
                  <span className="ml-[6px] mr-[8px] heading3 font-space-grotesk">
                    CNG
                  </span>
                  <button
                    disabled={disabledWithdrawMaxButton}
                    onClick={handleWithdrawMaxBtn}
                    className="bg-green-3 mr-[8px] rounded-[0.4rem] py-[0.4rem] px-[0.6rem] text-[1.2rem] text-green-1 font-medium cursor-pointer self-center"
                  >
                    <Trans>MAX</Trans>
                  </button>
                </div>
                <input
                  className="flex-1 font-space-grotesk text-right heading2 text-white"
                  value={withdrawValue}
                  onChange={(e) => {
                    if (!/^([0-9]*(?:[.,][0-9]*)?)$/.test(e.target.value)) {
                      return;
                    }
                    // INFO: 소수점 6자리까지만 입력 허용
                    if (/^[\d]*\.?[\d]{0,6}$/.test(e.target.value)) {
                      setWithdrawValue(e.target.value);
                    } else {
                      return;
                    }
                  }}
                  placeholder="0"
                />
              </div>
              <div className="text-right">
                <span className="heading9 text-gray-30">
                  <Trans>Staked</Trans>
                </span>
                <span className="heading9 ml-[4px] font-space-grotesk">
                  {stakedCngTokens ? numberWithCommas(stakedCngTokens) : "0.00"}{" "}
                  CNG
                </span>
              </div>
            </div>
            <button
              onClick={handleWithdrawButton}
              disabled={disabledWithdrawButton}
              className={cx(
                "flex items-center justify-center brand-btn h-[4.9rem]"
              )}
            >
              {isTxSubmitting.withdrawTxIsSubmitting ? (
                <img className="mx-auto h-[0.8rem]" src={loader} alt="loader" />
              ) : (
                withdrawButtonText()
              )}
            </button>
            {!isActive && (
              <div className="flex mt-[0.8rem]">
                <img className="mr-[4px]" src={InvalidImage} alt="invalid" />
                <span className="heading9 text-gray-15 tracking-normal">
                  <Trans id="msg.staking / Sign In Message">Sign In</Trans>
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </SEO>
  );
};

export default Staking;
