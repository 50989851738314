import { isVisibleEditLiqPriceModalAtom } from "atoms/exchange";
import cx from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { useAtomValue } from "jotai";
import { ReactNode, useEffect, useRef } from "react";
import { MdClose } from "react-icons/md";
import { RemoveScroll } from "react-remove-scroll";
import "./Modal.css";

export interface ModalStyleProps {
  closeBtn?: string;
  closeBtnColor?: string;
}
export interface ModalProps {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  children: ReactNode;
  zIndex?: string;
  allowCloseBtn?: boolean;
  searchPairTopCss?: string;
  mobileBottom?: boolean; // mobile에서 모달 하단에 고정
  shareTrade?: boolean;
  styles?: ModalStyleProps;
}

export default function Modal(props: ModalProps) {
  const {
    isVisible,
    setIsVisible,
    zIndex,
    allowCloseBtn = true,
    searchPairTopCss = "",
    mobileBottom = false,
    shareTrade = false,
  } = props;

  const modalRef = useRef(null);

  // esc 눌렀을 때 모달 닫는 기능
  useEffect(() => {
    function close(e: any) {
      // e.keyCode 27은 esc
      if (e.keyCode === 27 && setIsVisible) {
        setIsVisible(false);
      }
    }
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, [setIsVisible]);

  const fadeVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  const isVisibleEditLiqPriceModalVisible = useAtomValue(isVisibleEditLiqPriceModalAtom);

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          className={cx(
            "fixed z-[1001] top-0 left-0 right-0 bottom-0",
            "flex justify-center",
            mobileBottom ? "items-end xxs+:items-center" : "items-center",
            "text-left"
          )}
          style={{ zIndex }}
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={fadeVariants}
          transition={{ duration: 0.2 }}
        >
          {/* Modal Backdrop */}
          <div
            className="fixed z-[10] top-0 bottom-0 left-0 right-0 bg-black-1 opacity-60"
            style={{
              overflow: isVisible ? "hidden" : "visible",
              position: "fixed",
            }}
            onClick={() => setIsVisible(false)}
          />

          {/* Modal Content */}
          <RemoveScroll className="w-full xxs+:w-[auto] overflow-x-auto">
            <div
              className={cx(
                "z-[20] relative max-h-[90vh]",
                mobileBottom
                  ? "max-w-full rounded-t-[1.2rem]"
                  : isVisibleEditLiqPriceModalVisible
                  ? "max-w-[36rem] rounded-[1.2rem]" // for EditLiqPriceModal
                  : "max-w-[30.2rem] rounded-[1.2rem]", // for DetailTradeModal
                "mx-auto xxs+:max-w-full xxs+:min-w-[36rem] ",
                { "!min-w-[36.2rem]": shareTrade },
                "bg-gray-80",
                "border border-white border-opacity-10 text-[1.6rem]",
                shareTrade ? "overflow-hidden" : "overflow-auto" // shareTrade인 경우, 스크롤 숨기고, background-image 때문에 생기는 white rounded border 숨김
              )}
            >
              <div className={cx("relative", { "overflow-y-auto": !shareTrade }, searchPairTopCss)} ref={modalRef}>
                {allowCloseBtn && (
                  <div
                    className={cx(
                      "absolute right-[2.4rem] top-[1.6rem] cursor-pointer",
                      props.styles?.closeBtn ? props.styles?.closeBtn : ""
                    )}
                    onClick={() => setIsVisible(false)}
                  >
                    <MdClose
                      color={props.styles?.closeBtnColor ? props.styles?.closeBtnColor : "#8B8D92"}
                      fontSize={24}
                    />
                  </div>
                )}
                {props.children}
              </div>
            </div>
          </RemoveScroll>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
