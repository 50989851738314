import { i18n } from "@lingui/core";
import { en, ko } from "make-plural/plurals";

// uses BCP-47 codes from https://unicode-org.github.io/cldr-staging/charts/latest/supplemental/language_plural_rules.html
export const locales = {
  en: "English",
  ko: "한글",
  // es: "Spanish",
  // zh: "Chinese",
  // ru: "Russian",
  // ja: "Japanese",
  // fr: "French",
  // de: "German",
  // ...(isDevelopment() && { pseudo: "Test" }),
};

// const localeFromBrowser = navigator.language.split("-")[0];

// export const defaultLocale = prevLanguage
//   ? prevLanguage
//   : Object.keys(locales).includes(localeFromBrowser.toLocaleLowerCase())
//   ? localeFromBrowser.toLocaleLowerCase()
//   : "en";

// TODO: 다국어 지원 시 Default Locale 고정 해제
// const prevLanguage = localStorage.getItem("language");
// export const defaultLocale = prevLanguage ? prevLanguage : "en";
export const defaultLocale = "en";

i18n.loadLocaleData({
  en: { plurals: en },
  ko: { plurals: ko },
  // es: { plurals: es },
  // zh: { plurals: zh },
  // ru: { plurals: ru },
  // ja: { plurals: ja },
  // fr: { plurals: fr },
  // de: { plurals: de },
  // ...(isDevelopment() && { pseudo: { plurals: en } }),
});
i18n.load(defaultLocale, {});
i18n.activate(defaultLocale);

// export function isTestLanguage(locale: string) {
//   return locale === "pseudo";
// }

async function importMessages(locale: string) {
  switch (locale) {
    // case "ja":
    //   // @ts-ignore
    //   // eslint-disable-next-line import/no-webpack-loader-syntax
    //   return await import("messages.js!=!@lingui/loader!locales/ja/messages.po");
    // case "de":
    //   // @ts-ignore
    //   // eslint-disable-next-line import/no-webpack-loader-syntax
    //   return await import("messages.js!=!@lingui/loader!locales/de/messages.po");
    // case "es":
    //   // @ts-ignore
    //   // eslint-disable-next-line import/no-webpack-loader-syntax
    //   return await import("messages.js!=!@lingui/loader!locales/es/messages.po");
    // case "fr":
    //   // @ts-ignore
    //   // eslint-disable-next-line import/no-webpack-loader-syntax
    //   return await import("messages.js!=!@lingui/loader!locales/fr/messages.po");
    // case "ru":
    //   // @ts-ignore
    //   // eslint-disable-next-line import/no-webpack-loader-syntax
    //   return await import("messages.js!=!@lingui/loader!locales/ru/messages.po");
    // case "zh":
    //   // @ts-ignore
    //   // eslint-disable-next-line import/no-webpack-loader-syntax
    //   return await import("messages.js!=!@lingui/loader!locales/zh/messages.po");
    case "en":
      // @ts-ignore
      // eslint-disable-next-line import/no-webpack-loader-syntax
      return await import("messages.js!=!@lingui/loader!locales/en/messages.po");
    // case "ko":
    //   // @ts-ignore
    //   // eslint-disable-next-line import/no-webpack-loader-syntax
    //   return await import("messages.js!=!@lingui/loader!locales/ko/messages.po");
    default:
      // @ts-ignore
      // eslint-disable-next-line import/no-webpack-loader-syntax
      return await import("messages.js!=!@lingui/loader!locales/en/messages.po");
  }
}

export async function dynamicActivate(locale: string) {
  // react v18, webpack v5(react-scripts)로 버전 올리고, @lingui/loader를 이용해서 po 파일들 import가 안되서
  // importMessages로 대체
  // const { messages } = await import(`@lingui/loader!locales/${locale}/messages.po`);
  const { messages } = await importMessages(locale);

  // localStorage.setItem(LANGUAGE_LOCALSTORAGE_KEY, locale);
  // if (!isTestLanguage(locale)) {
  //   localStorage.setItem(LANGUAGE_LOCALSTORAGE_KEY, locale);
  // }
  i18n.load(locale, messages || {});
  i18n.activate(locale);
}
