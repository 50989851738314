import useScrollToTop from "futures-lib/useScrollToTop";
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { SWRConfig } from "swr";

import "react-toastify/dist/ReactToastify.css";

import "styles/Font.css";
import "styles/Input.css";
import "styles/Shared.css";
import "./App.scss";

import SEO from "components/Common/SEO";

import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { currentLanguageAtom, currentTimeAtom } from "atoms";
import { dynamicActivate } from "futures-lib/i18n";
import { useAtom, useSetAtom } from "jotai";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import FullAppV2 from "./FullAppV2";

import DynamicApolloProvider from "components/DynamicApolloProvider";
import { hotjar } from "react-hotjar";

// import { Web3ReactHooks, Web3ReactProvider } from "@web3-react/core";
// import type { MetaMask } from "@web3-react/metamask";
// import type { Network } from "@web3-react/network";
// import type { WalletConnect as WalletConnectV2 } from "@web3-react/walletconnect-v2";

// import type { CoinbaseWallet } from "@web3-react/coinbase-wallet";
// import { coinbaseWallet, hooks as coinbaseWalletHooks } from "connectors/coinbaseWallet";
// import { hooks as metaMaskHooks, metaMask } from "connectors/metaMask";
// import { hooks as networkHooks, network } from "connectors/network";
// import { hooks as walletConnectV2Hooks, walletConnectV2 } from "connectors/walletConnectV2";
import { NextUIProvider } from "@nextui-org/system";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { APP_MODES } from "futures-domain/types";
import WalletProvider from "futures-lib/wallets/WalletProvider";
import { CookiesProvider } from "react-cookie";
import TagManager from "react-gtm-module";
import { useDocumentVisible } from "utils/useDocumentVisible";

if (window?.ethereum?.autoRefreshOnNetworkChange) {
  window.ethereum.autoRefreshOnNetworkChange = false;
}

const STAGING_VERSION = "2024/04/29 10:40 GMT+9";
const PRODUCTION_VERSION = "2024/04/29 10:40 GMT+9";

export const getConstantVersion = () => {
  switch (process.env.REACT_APP_MODE) {
    case APP_MODES.STAGING:
      return STAGING_VERSION;
    case APP_MODES.PRODUCTION:
      return PRODUCTION_VERSION;
    default:
      // eslint-disable-next-line no-console
      // console.log("Default Version Loaded");
      return STAGING_VERSION;
  }
};

function App() {
  dayjs.extend(localizedFormat);
  dayjs.extend(duration);

  useDocumentVisible();

  useScrollToTop();
  const [gaInitialized, setGaInitialized] = useState(false);
  const location = useLocation();
  const [currentLanguage, setCurrentLanguage] = useAtom(currentLanguageAtom);

  useEffect(() => {
    setCurrentLanguage(currentLanguage);
    dynamicActivate(currentLanguage);

    if (
      process.env.REACT_APP_MODE === APP_MODES.PRODUCTION &&
      process.env.REACT_APP_GA_ID
    ) {
      ReactGA.initialize(process.env.REACT_APP_GA_ID);
      setGaInitialized(true);

      if (
        process.env.REACT_APP_MODE === APP_MODES.PRODUCTION &&
        process.env.REACT_APP_GTM_ID
      ) {
        TagManager.initialize({
          gtmId: process.env.REACT_APP_GTM_ID,
        });
      }
    }

    if (
      process.env.REACT_APP_MODE === APP_MODES.PRODUCTION &&
      process.env.REACT_APP_HJID
    ) {
      hotjar.initialize(+process.env.REACT_APP_HJID, 6);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (gaInitialized) {
      ReactGA.set({ page: location.pathname });
      ReactGA.send("pageview");
    }
  }, [gaInitialized, location]);

  const setCurrentTime = useSetAtom(currentTimeAtom);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(dayjs());
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [setCurrentTime]);

  return (
    <SWRConfig value={{ refreshInterval: 3000 }}>
      <I18nProvider i18n={i18n}>
        {/* <Web3ReactProvider connectors={connectors}> */}

        <WalletProvider>
          <DynamicApolloProvider>
            <CookiesProvider>
              <SEO>
                <NextUIProvider>
                  <>
                    <ToastContainer autoClose={false} closeOnClick={false} />
                    <FullAppV2 />
                  </>
                </NextUIProvider>
              </SEO>
            </CookiesProvider>
          </DynamicApolloProvider>
        </WalletProvider>

        {/* </Web3ReactProvider> */}
      </I18nProvider>
    </SWRConfig>
  );
}

export default App;
