import * as Sentry from "@sentry/react";

export const sentryCaptureException = ({
  error,
  name,
  context,
}: {
  error: any;
  name?: string;
  context?: {
    [key: string]: any;
  } | null;
}) => {
  if (name && context) {
    Sentry.setContext(name, context);
  }

  Sentry.captureException(error);
};
