import { Signer, ethers } from "ethers";
import { getFallbackProvider, getProvider } from "../rpc";
// import futuresPairInfos from "futures-abis/PairInfos.json";
import { parseUnits } from "@ethersproject/units";
import { ADDED_DECIMALS, MAX_POSITIONS } from "components/Exchange/constants";
import { Trade } from "futures-domain/trades/types";
import { targerEpochs } from "futures-domain/vault/utils";

export const gambitContractFetcher =
  <T>(library: Signer | undefined, contractInfo: any, additionalArgs?: any[]) =>
  (args: any): Promise<T> => {
    // eslint-disable-next-line
    const [id, chainId, arg0, arg1, ...params] = args;
    const provider = getProvider(library, chainId);

    const method = ethers.utils.isAddress(arg0) ? arg1 : arg0;

    const contractCall = getContractCall({
      provider,
      contractInfo,
      arg0,
      arg1,
      method,
      params,
      additionalArgs,
    });

    let shouldCallFallback = true;

    const handleFallback = (resolve, reject, error) => {
      if (!shouldCallFallback) {
        return;
      }
      // prevent fallback from being called twice
      shouldCallFallback = false;

      const fallbackProvider = getFallbackProvider(chainId);
      if (!fallbackProvider) {
        reject(error);
        return;
      }

      // eslint-disable-next-line no-console
      console.info("using fallbackProvider for", method);
      const fallbackContractCall = getContractCall({
        provider: fallbackProvider,
        contractInfo,
        arg0,
        arg1,
        method,
        params,
        additionalArgs,
      });

      fallbackContractCall
        .then((result) => resolve(result))
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.error(
            "fallback fetcher error",
            id,
            contractInfo.contractName,
            method,
            e
          );
          reject(e);
        });
    };

    return new Promise((resolve, reject) => {
      contractCall
        .then((result) => {
          shouldCallFallback = false;
          resolve(result);
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.error(
            "fetcher error",
            id,
            contractInfo.contractName,
            method,
            e
          );
          handleFallback(resolve, reject, e);
        });

      setTimeout(() => {
        handleFallback(resolve, reject, "contractCall timeout");
      }, 2000);
    });
  };

export const gambitContractFetcherForOpenTrades =
  <T>(library: Signer | undefined, contractInfo: any, additionalArgs?: any[]) =>
  (args: any): Promise<any[] | T> => {
    // eslint-disable-next-line
    const [id, chainId, arg0, arg1, pairs, ...params] = args;
    const provider = getProvider(library, chainId);

    const method = ethers.utils.isAddress(arg0) ? arg1 : arg0;

    return Promise.all(
      pairs.map((_, pairIndex) => {
        return new Promise((resolve, reject) => {
          // 특정 pair에 대해 몇 개 포지션이 열려있는 지 호출
          getContractCall({
            provider,
            contractInfo,
            arg0,
            arg1,
            method, // "openTradesCount"
            params: [...params, pairIndex],
            additionalArgs,
          }).then((counts) => {
            // console.log(pairIndex, counts.toString());
            // 2 => [index, 0], [index, 1]
            if (counts.toString() === "0") {
              resolve(null);
            } else {
              // 포지션이 0개가 아닌 페어에 대해서 openTrades 정보 확인
              Promise.all(
                // 현재 3으로 고정되어 있는데, 페어 당 열 수 있는 포지션 개수가 늘어나면 반영해야 함
                Array(MAX_POSITIONS)
                  .fill(0)
                  .map((_, index) => {
                    // console.log(pairIndex, index);
                    return new Promise((resolve, reject) => {
                      // console.log(pairIndex, index);
                      getContractCall({
                        provider,
                        contractInfo,
                        arg0,
                        arg1,
                        method: "openTrades",
                        params: [...params, pairIndex, index], // params = [account]
                        additionalArgs: [],
                      })
                        .then((trade) => {
                          if (trade[0] === ethers.constants.AddressZero) {
                            resolve(null);
                          } else {
                            resolve(trade);
                          }
                        })
                        .catch((e) => reject(e));
                    });
                  })
              )
                .then((values) => {
                  resolve(values.filter((trade) => trade));
                })
                .catch((e) => reject(e));
            }
          });
        });
      })
    )
      .then((values) => {
        return values.filter((value) => value).flat();
      })
      .catch((e) => {
        throw Error();
      });
  };

export const gambitContractFetcherForDerivedOpenTrades =
  <T>(library: Signer | undefined, contractInfo: any, additionalArgs?: any[]) =>
  (args: any): Promise<any[] | T> => {
    // eslint-disable-next-line
    const [id, chainId, arg0, arg1, openTrades, address] = args;
    const provider = getProvider(library, chainId);

    return Promise.all(
      openTrades.map((openTrade: Trade) => {
        return Promise.all([
          // Liq.Price
          new Promise((resolve, reject) => {
            getContractCall({
              provider,
              contractInfo, // : pairInfos,
              arg0, // : pairInfosAddress,
              arg1: "getTradeLiquidationPrice",
              method: "getTradeLiquidationPrice",
              params: [
                address,
                openTrade.pairIndex.toString(),
                openTrade.positionIndex.toString(),
                openTrade.openPrice.toString(),
                openTrade.buy,
                openTrade.positionSizeUsdc.toString(),
                parseUnits(openTrade.leverage.toString(), ADDED_DECIMALS),
              ],
              additionalArgs: null,
            })
              .then((value) => {
                // console.log(value);
                resolve(value);
              })
              .catch((e) => reject(e));
          }),
          // Funding Fee
          new Promise((resolve, reject) => {
            // console.log([address, pairIndex, positionIndex, buy, positionSizeUsdc, leverage]);

            getContractCall({
              provider,
              contractInfo, // : pairInfos,
              arg0, // : pairInfosAddress,
              arg1: "getTradeFundingFee",
              method: "getTradeFundingFee",
              params: [
                address,
                openTrade.pairIndex,
                openTrade.positionIndex,
                openTrade.buy,
                openTrade.positionSizeUsdc,
                parseUnits(openTrade.leverage.toString(), ADDED_DECIMALS),
              ],
              additionalArgs: null,
            })
              .then((value) => {
                // console.log(value);
                resolve(value);
              })
              .catch((e) => reject(e));
          }),
          // Rollover Fee
          new Promise((resolve, reject) => {
            // console.log([address, pairIndex, positionIndex, positionSizeUsdc]);

            getContractCall({
              provider,
              contractInfo, // : pairInfos,
              arg0, // : pairInfosAddress,
              arg1: "getTradeRolloverFee",
              method: "getTradeRolloverFee",
              params: [
                address,
                openTrade.pairIndex,
                openTrade.positionIndex,
                openTrade.positionSizeUsdc,
              ],
              additionalArgs: null,
            })
              .then((value) => {
                // console.log(value);
                resolve(value);
              })
              .catch((e) => reject(e));
          }),
        ]).then((values) => [
          openTrade.pairIndex,
          openTrade.positionIndex,
          ...values,
        ]);
      })
    )
      .then((values) => {
        return values;
      })
      .catch((e) => {
        throw Error();
      });
  };

export const gambitContractFetcherForAccountVault =
  <T>(library: Signer | undefined, contractInfo: any, additionalArgs?: any[]) =>
  (args: any): Promise<any[] | T> => {
    // eslint-disable-next-line
    const [id, chainId, arg0, arg1, ...params] = args;
    const provider = getProvider(library, chainId);

    // const method = ethers.utils.isAddress(arg0) ? arg1 : arg0;
    // console.log([...params, currentEpoch! - 1]);

    return Promise.all([
      // new Promise( (resolve, reject) => {
      //   getContractCall({
      //     provider,
      //     contractInfo,
      //     arg0,
      //     arg1,
      //     // gUSDC price, gUSDC 개수 * gUSDC price = USDC 개수
      //     method: "shareToAssetsPrice",
      //     params: [],
      //     additionalArgs: null,
      //   })
      //     .then((value) => resolve(value))
      //     .catch((e) => reject(e));
      // }),
      new Promise((resolve, reject) => {
        getContractCall({
          provider,
          contractInfo,
          arg0,
          arg1,
          // shares(gUSDC)의 balance === available
          method: "balanceOf",
          params,
          additionalArgs: null,
        })
          .then((value) => resolve(value))
          .catch((e) => reject(e));
      }),
      new Promise((resolve, reject) => {
        getContractCall({
          provider,
          contractInfo,
          arg0,
          arg1,
          // GToken(gUSDC)의 decimals
          method: "decimals",
          params: [],
          additionalArgs: null,
        })
          .then((value) => resolve(value))
          .catch((e) => reject(e));
      }),
      new Promise((resolve, reject) => {
        getContractCall({
          provider,
          contractInfo,
          arg0,
          arg1,
          // 현재 withdraw 요청으로 넘어가 있는 shares(gUSDC) 개수
          method: "totalSharesBeingWithdrawn",
          params,
          additionalArgs: null,
        })
          .then((value) => resolve(value))
          .catch((e) => reject(e));
      }),
      new Promise((resolve, reject) => {
        getContractCall({
          provider,
          contractInfo,
          arg0,
          arg1,
          method: "maxRedeem",
          params,
          additionalArgs: null,
        })
          .then((value) => resolve(value))
          .catch((e) => reject(e));
      }),
    ])
      .then((values) => {
        return values.filter((value) => value).flat();
      })
      .catch((e) => {
        throw Error();
      });
  };

export const gambitContractFetcherForCommonVault =
  <T>(library: Signer | undefined, contractInfo: any, additionalArgs?: any[]) =>
  (args: any): Promise<any[] | T> => {
    // eslint-disable-next-line
    const [id, chainId, arg0, arg1, ...params] = args;
    const provider = getProvider(library, chainId);

    return Promise.all([
      new Promise((resolve, reject) => {
        getContractCall({
          provider,
          contractInfo,
          arg0,
          arg1,
          method: "currentEpoch",
          params: [],
          additionalArgs,
        })
          .then((value) => resolve(value))
          .catch((e) => {
            reject(e);
          });
      }),
      new Promise((resolve, reject) => {
        getContractCall({
          provider,
          contractInfo,
          arg0,
          arg1,
          method: "currentEpochStart",
          params: [],
          additionalArgs,
        })
          .then((value) => resolve(value))
          .catch((e) => {
            reject(e);
          });
      }),
      new Promise((resolve, reject) => {
        getContractCall({
          provider,
          contractInfo,
          arg0,
          arg1,
          method: "withdrawEpochsTimelock",
          params: [],
          additionalArgs,
        })
          .then((value) => {
            // console.log(value);
            resolve(value);
          })
          .catch((e) => {
            resolve(null);
            // reject(e);
          });
      }),
      new Promise((resolve, reject) => {
        getContractCall({
          provider,
          contractInfo,
          arg0,
          arg1,
          // TVL
          method: "currentBalanceUsdc",
          params: [],
          additionalArgs,
        })
          .then((value) => resolve(value))
          .catch((e) => {
            reject(e);
          });
      }),
      new Promise((resolve, reject) => {
        getContractCall({
          provider,
          contractInfo,
          arg0,
          arg1,
          // 지급 준비율
          method: "collateralizationP",
          params: [],
          additionalArgs,
        })
          .then((value) => {
            // console.log(value);
            resolve(value);
          })
          .catch((e) => {
            resolve(null);
            // reject(e);
          });
      }),
      new Promise((resolve, reject) => {
        getContractCall({
          provider,
          contractInfo,
          arg0,
          arg1,
          // gUSDC price, gUSDC 개수 * gUSDC price = USDC 개수
          method: "shareToAssetsPrice",
          params: [],
          additionalArgs: null,
        })
          .then((value) => resolve(value))
          .catch((e) => reject(e));
      }),
    ])
      .then((values) => {
        return values.filter((value) => value).flat();
      })
      .catch((e) => {
        throw Error();
      });
  };

export const gambitContractFetcherForExistingRequestsVault =
  <T>(library: Signer | undefined, contractInfo: any, additionalArgs?: any[]) =>
  (args: any): Promise<any[] | T> => {
    // eslint-disable-next-line
    const [id, chainId, arg0, arg1, ...params] = args;
    const provider = getProvider(library, chainId);
    const currentEpoch = additionalArgs![0];
    const withdrawEpochsTimeLock = additionalArgs![1];

    const targetEpochs = targerEpochs(currentEpoch, withdrawEpochsTimeLock);

    return Promise.all(
      targetEpochs.map((targetEpoch) => {
        return new Promise((resolve, reject) => {
          getContractCall({
            provider,
            contractInfo,
            arg0,
            arg1,
            method: "withdrawRequests",
            params: [...params, targetEpoch],
            additionalArgs: null,
          })
            .then((value) => resolve(value))
            .catch((e) => reject(e));
        });
      })
    )
      .then((values) => {
        return values;
      })
      .catch((e) => {
        throw Error();
      });
  };

export const gambitContractFetcherForReferral =
  <T>(library: Signer | undefined, contractInfo: any, additionalArgs?: any[]) =>
  (args: any): Promise<any[] | T> => {
    // eslint-disable-next-line
    const [id, chainId, arg0, arg1, ...params] = args;
    const provider = getProvider(library, chainId);

    return Promise.all([
      new Promise((resolve, reject) => {
        getContractCall({
          provider,
          contractInfo,
          arg0,
          arg1,
          method: "referrerDetails",
          params,
          additionalArgs: null,
        })
          .then((value) => {
            resolve(value);
          })
          .catch((e) => reject(e));
      }),
      new Promise((resolve, reject) => {
        getContractCall({
          provider,
          contractInfo,
          arg0,
          arg1,
          method: "getTradersReferred",
          params,
          additionalArgs: null,
        })
          .then((value) => {
            resolve(value);
          })
          .catch((e) => reject(e));
      }),
    ])
      .then((values) => {
        // console.log(values);
        return values;
        // return values.filter((value) => value).flat();
      })
      .catch((e) => {
        throw Error();
      });
  };

export function getContractCall({
  provider,
  contractInfo,
  arg0,
  arg1,
  method,
  params,
  additionalArgs,
}) {
  if (ethers.utils.isAddress(arg0)) {
    const address = arg0;
    const contract = new ethers.Contract(address, contractInfo.abi, provider);

    if (additionalArgs) {
      // if (method === "openTradesCount") {
      //   console.log(params.concat(additionalArgs));
      // }
      return contract[method](...params.concat(additionalArgs));
    }
    // if (method === "openTradesCount") {
    //   console.log(params);
    // }
    try {
      return contract[method](...params);
    } catch (e) {
      // console.log(method);
    }
  }

  if (!provider) {
    return;
  }

  return provider[method](arg1, ...params);
}
