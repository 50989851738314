import "@rainbow-me/rainbowkit/styles.css";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { connectorsForWallets, darkTheme, RainbowKitProvider, Theme, WalletList } from "@rainbow-me/rainbowkit";
import { arbitrum, arbitrumSepolia, zkSync, arbitrumGoerli, zkSyncSepoliaTestnet } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";
import merge from "lodash/merge";
import { APP_MODES } from "futures-domain/types";
import {
  ledgerWallet,
  metaMaskWallet,
  walletConnectWallet,
  coinbaseWallet,
  rainbowWallet,
  trustWallet,
  safepalWallet,
} from "@rainbow-me/rainbowkit/wallets";
import { DEFAULT_CHAIN_ID, hardhatChainInfo, wemixChainInfo } from "config/chains";
import Web3AuthConnectorInstance from "./gambit-web3-auth/Web3AuthConnectorInstance";
import { SELECTED_NETWORK_LOCAL_STORAGE_KEY } from "config/localStorage";

const WALLET_CONNECT_PROJECT_ID = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID!;
const APP_NAME = "GAMBIT";

const walletTheme = merge(darkTheme(), {
  colors: {
    modalBackground: "#171B26", // black-3
    accentColor: "#FEBF01", // brand-0
    menuItemBackground: "#2E323C", // gray-70
  },
  radii: {
    modal: "4px",
    menuButton: "4px",
  },
} as Theme);

const chainArray = [
  arbitrumGoerli,
  arbitrumSepolia,
  arbitrum,
  zkSync,
  ...(process.env.REACT_APP_MODE !== APP_MODES.PRODUCTION
    ? [zkSyncSepoliaTestnet, wemixChainInfo, hardhatChainInfo]
    : []),
];

const initialChainId = window.localStorage.getItem(SELECTED_NETWORK_LOCAL_STORAGE_KEY) || DEFAULT_CHAIN_ID;

const { chains, publicClient } = configureChains(
  [
    ...chainArray.filter((chain) => chain.id === initialChainId),
    ...chainArray.filter((chain) => chain.id !== initialChainId),
  ],
  [publicProvider()]
);

const recommendedWalletList: WalletList = [
  {
    groupName: "Recommended",
    wallets: [
      // injectedWallet({ chains }),
      // safeWallet({ chains }),
      // rabbyWallet({ chains }),
      metaMaskWallet({ chains, projectId: WALLET_CONNECT_PROJECT_ID }),
      coinbaseWallet({ appName: APP_NAME, chains }),
      walletConnectWallet({ chains, projectId: WALLET_CONNECT_PROJECT_ID }),
    ],
  },
];

const othersWalletList: WalletList = [
  {
    groupName: "Others",
    wallets: [
      ledgerWallet({ chains, projectId: WALLET_CONNECT_PROJECT_ID }),
      trustWallet({ chains, projectId: WALLET_CONNECT_PROJECT_ID }),
      // mewWallet({ chains }),
      // bitgetWallet({ chains, projectId: WALLET_CONNECT_PROJECT_ID }),
      // coreWallet({ chains, projectId: WALLET_CONNECT_PROJECT_ID }),
      safepalWallet({ chains, projectId: WALLET_CONNECT_PROJECT_ID }),
      rainbowWallet({ chains, projectId: WALLET_CONNECT_PROJECT_ID }),
    ],
  },
];

const getConnectors = () => {
  const connectors = [
    ...connectorsForWallets([...recommendedWalletList, ...othersWalletList])(),
    ...Web3AuthConnectorInstance(chains),
  ];

  return connectors;
};

const connectors = getConnectors();

let wagmiConfig;
try {
  wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient,
  });
} catch (e: any) {
  // console.log(e);

  wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient,
  });
}

const WalletProvider = ({ children }) => {
  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider locale="en-US" theme={walletTheme} chains={chains} modalSize="compact">
        {children}
      </RainbowKitProvider>
    </WagmiConfig>
  );
};

export default WalletProvider;
