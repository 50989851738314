import { constants } from "ethers";
import { DEFAULT_CHAIN_ID } from "config/chains";
import { SELECTED_NETWORK_LOCAL_STORAGE_KEY } from "config/localStorage";
import { Referral } from "futures-domain/referral/types";
import { atom } from "jotai";
import { atomWithDefault } from "jotai/utils";

export const referralAtom = atom<Referral | null>(null);

export const _referrerAddressAtom = atomWithDefault(() => {
  const chainId = window.localStorage.getItem(SELECTED_NETWORK_LOCAL_STORAGE_KEY) || DEFAULT_CHAIN_ID;
  const key = JSON.stringify([chainId, "Referrer-address"]);

  return window.localStorage.getItem(key) ? JSON.parse(window.localStorage.getItem(key)!) : constants.AddressZero;
});

export const referrerAddressAtom = atom(
  (get) => get(_referrerAddressAtom),
  (get, set, referrerAddress) => {
    const chainId = window.localStorage.getItem(SELECTED_NETWORK_LOCAL_STORAGE_KEY) || DEFAULT_CHAIN_ID;
    const key = JSON.stringify([chainId, "Referrer-address"]);
    const value = JSON.stringify(referrerAddress);
    window.localStorage.setItem(key, value);

    set(_referrerAddressAtom, referrerAddress);
  }
);
