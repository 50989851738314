import { t } from "@lingui/macro";
import { BigNumber } from "ethers";

export interface Trade {
  pairIndex: number;
  positionIndex: string;
  initialPosUsdc: BigNumber;
  positionSizeUsdc: BigNumber; // leverage 뺀 담보 USDC 사이즈
  openPrice: BigNumber;
  buy: boolean;
  leverage: number;
  tp: BigNumber;
  sl: BigNumber;
  pair: Pair;
  isWaitingTrade?: boolean; // 실제 Trade 값을 가져오기 전에 MarketExecuted 이벤트로 가져온 결과를 보여주는 동안 true
  activeTradeStatus?: "SHOWN" | "HIDDEN"; // 차트에 표시되는지 안되는지 확인
}

export interface SubgraphTrade {
  pairIndex: string;
  index: string; // positionIndex
  initialPosUsdc: string;
  positionSizeUsdc: string; // leverage 뺀 담보 USDC 사이즈
  openPrice: string;
  buy: boolean;
  leverage: string;
  tp: string;
  sl: string;
}

// subgraph에서 가져오지 못하는 Trade 관련 정보로 온체인에서 가져 옴
export interface DerivedTrade {
  pairIndex?: number;
  positionIndex?: string;
  liqPrice?: BigNumber;
  fundingFee?: BigNumber;
  rolloverFee?: BigNumber;
}

// Trade + DerivedTrade
export interface ParsedTrade {
  pairIndex: number;
  positionIndex: string;
  initialPosUsdc: BigNumber;
  positionSizeUsdc: BigNumber; // * leverage 뺀 담보 USDC 사이즈
  openPrice: BigNumber;
  buy: boolean;
  leverage: number;
  tp: BigNumber;
  sl: BigNumber;
  pair: Pair;
  isWaitingTrade?: boolean; // 실제 Trade 값을 가져오기 전에 MarketExecuted 이벤트로 가져온 결과를 보여주는 동안 true
  liqPrice?: BigNumber;
  fundingFee?: BigNumber;
  rolloverFee?: BigNumber;
  activeTradeStatus?: "SHOWN" | "HIDDEN"; // 차트에 표시되는지 안되는지 확인
}

export interface CloseTrade {
  closePrice: BigNumber;
  usdcSentToTrader: BigNumber;
  percentProfit: BigNumber;
  reason: string; // TODO: CLOSE || ??
  timestamp: string;
  trade: {
    pairIndex: number;
    positionIndex: string;
    initialPosUsdc: BigNumber;
    positionSizeUsdc: BigNumber; // leverage 뺀 담보 USDC 사이즈
    openPrice: BigNumber;
    buy: boolean;
    leverage: number;
    tp: BigNumber;
    sl: BigNumber;
    pair: Pair;
  };
  openingFee: string;
  closingFee: string;
  includeNetworkFee: boolean;
}

export interface DerivedCloseTrade extends CloseTrade {
  initialPosUsdcString: string;
  positionSizeUsdcString: string;
  openFeeAndNetworkFee: string;
  closeFeeAndFundingFee: string;
  profitValue: string;
  profitPercent: string;
  // pnl: string;
  netPnL: string;
}

export interface SubgraphCloseTrade {
  closePrice: BigNumber;
  usdcSentToTrader: BigNumber;
  percentProfit: BigNumber;
  reason: string; // TODO: CLOSE || ??
  timestamp: string;
  trade: SubgraphTrade;
}

export interface SubgraphPendingOrder {
  trade: {
    index: string;
    leverage: string;
    openPrice: string;
    pairIndex: string;
    buy: boolean;
    positionSizeUsdc: string;
    sl: string;
    tp: string;
    // initialPosToken: string;

    id: string;
    trader: {
      id: string; // address
    };
  };
  block: string;
}

export interface PendingOrder {
  trade: Trade;
  block: BigNumber;
  orderId: string;
}

export type OpenLimitOrderType = "LEGACY" | "REVERSAL" | "MOMENTUM";

export interface SubgraphOrder {
  block: string;
  maxPrice: string;
  minPrice: string;
  trade: SubgraphTrade; // TODO: 확인 필요: SubgraphOrder 응답에는 openPrice가 없는데, SubgraphTrade에는 openPrice가 있음
  type: OpenLimitOrderType;
}

export interface Order {
  pairIndex: number;
  positionIndex: string;
  positionSize: BigNumber; // * positionSizeUsdc (leverage 미포함)
  buy: boolean;
  leverage: number; // * decimal 제거한 값(e18 제거한 값) ex) 100000000000000000000 -> 100
  tp: BigNumber;
  sl: BigNumber;
  pair: Pair;
  minPrice: BigNumber;
  maxPrice: BigNumber;
  block?: BigNumber; // * 실제 컨트랙트에 올라가면 값이 나오는 것 같은데 API call 결과에는 없어서 optional 처리
  type?: OpenLimitOrderType;
  source: "API" | "CONTRACT";
  apiOrderKey?: string; // * api order delete method 호출 과정에서 signature 만들 때 사용
}

export interface SubgraphPair {
  id: string;
  from: string;
  to: string;
  fundingFee: {
    accPerOiLong: string;
    accPerOiShort: string;
  };
  name: string; // BTC/USD
  openInterest: {
    long: string;
    short: string;
    max: string;
  };
  group: {
    id: string;
    minLeverage: string; // * NOTE: subgraph에서 받은 값을 fomatting 처리하지 않은 raw minLeverage 값
    maxLeverage: string; // * NOTE: subgraph에서 받은 값을 fomatting 처리하지 않은 raw minLeverage 값
  };
  param: {
    rolloverFeePerBlockP: string;
    fundingFeePerBlockP: string;
  };
  fee: ISubgraphFee;
  feed: {
    priceId1: string;
  };
  confMultiplierP: string;
}

export interface SubgraphCollateral {
  id: string;
  collateralLong: string;
  collateralShort: string;
  collateralMax: string;
}

export interface Pair {
  groupIndex: number;

  pairIndex: number;
  from: string;
  to: string;
  priceFeedId: string;
  tvSymbol: string;

  openInterest?: {
    long: BigNumber;
    short: BigNumber;
    max: BigNumber;
  };
  fundingFee?: {
    accPerOiLong: string;
    accPerOiShort: string;
  };

  collateral?: {
    collateralLong: BigNumber;
    collateralShort: BigNumber;
    collateralMax: BigNumber;
  };

  param?: PairParam;

  fee: ISubgraphFee;

  group: {
    minLeverage: string; // * NOTE: subgraph에서 받은 값을 fomatting 처리한 minLeverage 값
    maxLeverage: string; // * NOTE: subgraph에서 받은 값을 fomatting 처리한 minLeverage 값
  };
  confMultiplierP: number; // formatted 값 1200000000000 -> 1.2

  // spreadP?: string;
  // groupIndex?: string;
  // feeIndex?: string;
}

// * NOTE: subgraph에서 받은 값을 formatting 처리되지 않은 raw 값
export interface ISubgraphFee {
  openFeeP: string;
  closeFeeP: string;
  minLevPosUsdc: string;
  oracleFee: string;
}

export interface PairParam {
  rolloverFeePerBlockP: BigNumber | undefined;
  fundingFeePerBlockP: BigNumber | undefined;
}

export type Trades = Trade[];

/**
 * [pairIndex, positionIndex]들의 배열
 *
 * [
 *   [0, 0], [0, 1],
 *   [1, 0],
 *   [3, 0], [3, 1]
 * ]
 *
 * pairIndex: 0 -> BTC/USD
 * pairIndex 0에 대해 position을 2개 열었으면,
 * [0, 0], [0, 1] 두 개를 파라미터로 openTrades() 호출
 */
export type OpenTradesParameters = number[][];

export const POSITION_TYPE = {
  TRADES: t`TRADES`,
  ORDERS: t`ORDERS`,
  HISTORY: t`HISTORY`,
} as const;

export type PositionType = typeof POSITION_TYPE[keyof typeof POSITION_TYPE];

export const VAULT_TAB_TYPE = {
  DEPOSIT: "Deposit",
  WITHDRAW: "Withdraw",
} as const;

export type VaultTabType = typeof VAULT_TAB_TYPE[keyof typeof VAULT_TAB_TYPE];

export const STAKING_TAB_TYPE = {
  DEPOSIT: "Deposit",
  WITHDRAW: "Withdraw",
} as const;

export type StakingTabType = typeof STAKING_TAB_TYPE[keyof typeof STAKING_TAB_TYPE];

/**
 * price    formatting 전 자산 가격
 * expo     formatting 위한 decimals(음수로 표현)
 * conf     formatting 전 confidence 가격
 *
 * e.g)
 * price: "209043250000" -> $2090.4325
 * expo: -8
 * conf: "59250000" -> $592.5
 */
export interface PairPrice {
  price: string;
  expo: number;
  conf: string;
  publish_time: number;
}

export interface PairPrices {
  [key: string]: PairPrice;
}

export interface PairOiAndGroupCollateral {
  openInterest: {
    long: BigNumber;
    max: BigNumber;
    short: BigNumber;
  };
  collateral: {
    collateralLong: BigNumber;
    collateralShort: BigNumber;
    collateralMax: BigNumber;
  };
}

export interface Validation {
  valid: boolean;
  message: string;
}
