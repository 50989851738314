import {
  POLYGON,
  POLYGON_MUMBAI,
  WEMIX_TESTNET,
  ZKSYNC,
  HARDHAT,
  ZKSYNC_GOERLI,
  ARBITRUM,
  ARBITRUM_GOERLI,
  ARBITRUM_SEPOLIA,
  ZKSYNC_SEPOLIA,
} from "./chains";
import polygonImg from "img/ic_polygon.svg";
import polygonTestnetImg from "img/ic_polygon_testnet.svg";
import zkSyncImg from "img/ic-zksync.svg";
import zkSyncMainnetImg from "img/ic-zksync-mainnet.svg";
import wemixImg from "img/ic-wemix.svg";
// import hardhatImg from "img/ic-hardhat.svg";
import hardhatImg from "img/ic-hardhat.png";
import arbitrumImg from "img/ic-arbitrum.svg";
import arbitrumTestnetImg from "img/ic-arbitrum-testnet.png";

const ICONS = {
  [POLYGON]: {
    network: polygonImg,
  },
  [POLYGON_MUMBAI]: {
    network: polygonTestnetImg,
  },
  [ZKSYNC]: {
    network: zkSyncMainnetImg,
  },
  [ZKSYNC_GOERLI]: {
    network: zkSyncImg,
  },
  [ZKSYNC_SEPOLIA]: {
    network: zkSyncImg,
  },
  [HARDHAT]: {
    network: hardhatImg,
  },
  [WEMIX_TESTNET]: {
    network: wemixImg,
  },
  [ARBITRUM]: {
    network: arbitrumImg,
  },
  [ARBITRUM_GOERLI]: {
    network: arbitrumTestnetImg,
  },
  [ARBITRUM_SEPOLIA]: {
    network: arbitrumTestnetImg,
  },
};

export function getIcon(chainId: number | "common", label: string) {
  if (chainId in ICONS) {
    if (label in ICONS[chainId]) {
      return ICONS[chainId][label];
    }
  }
}
export function getIcons(chainId: number | "common") {
  if (!chainId) return;
  if (chainId in ICONS) {
    return ICONS[chainId];
  }
}
