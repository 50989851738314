import { formatUnits } from "@ethersproject/units";
import { IOrderLineAdapter } from "charting_library";
import {
  ADDED_DECIMALS,
  MAX_TRADES_PER_PAIR,
  SL_OPTIONS,
  TP_OPTIONS,
} from "components/Exchange/constants";
import { DEFAULT_CHAIN_ID } from "config/chains";
import { SELECTED_NETWORK_LOCAL_STORAGE_KEY } from "config/localStorage";
import { BigNumber } from "ethers";
import { IGetLimitResponse } from "futures-domain/trades/api-types";
import {
  CloseTrade,
  // PairOiAndGroupCollateral,
  DerivedCloseTrade,
  DerivedTrade,
  Order,
  POSITION_TYPE,
  Pair,
  PairPrices,
  ParsedTrade,
  PendingOrder,
  Trade,
  Validation,
} from "futures-domain/trades/types";
import { LONG, MARKET } from "futures-lib/legacy";
import { atom } from "jotai";
import { atomWithDefault } from "jotai/utils";
// export const publicePriceEndpointAtom = atom("https://hermes.pyth.network/api/latest_price_feeds");

export const pairsAtom = atom<Pair[]>([]);
export const pairsPricesAtom = atom<PairPrices>({});
export const selectedPairPriceAtom = atom("");

export const fromValueAtom = atom("100");

export const slPercentAtom = atom<string | null>(SL_OPTIONS[0].value);
export const tpPercentAtom = atom<string | null>(
  TP_OPTIONS[TP_OPTIONS.length - 1].value
);
export const slPriceAtom = atom("");
export const tpPriceAtom = atom("");

export const isTxSubmittingAtom = atom(false);

export const _selectedPairAtom = atomWithDefault<Pair>(() => {
  const chainId =
    window.localStorage.getItem(SELECTED_NETWORK_LOCAL_STORAGE_KEY) ||
    DEFAULT_CHAIN_ID;
  const key = JSON.stringify([chainId, "Selected-pair"]);

  try {
    return window.localStorage.getItem(key)
      ? JSON.parse(window.localStorage.getItem(key)!)
      : null;
  } catch (e) {
    return null;
  }
});

// export const localStoragePairAtom = atom<Pair>((get) => {
//   const chainId = window.localStorage.getItem(SELECTED_NETWORK_LOCAL_STORAGE_KEY) || DEFAULT_CHAIN_ID;
//   const key = JSON.stringify([chainId, "Selected-pair"]);
//   const localStoragePair = window.localStorage.getItem(key);
//   if (localStoragePair) {
//     return JSON.parse(localStoragePair);
//   }
// });

export const selectedPairAtom = atom(
  (get) => {
    const pairs = get(pairsAtom);
    const localStoragePair = get(_selectedPairAtom);
    if (pairs.length === 0 && !localStoragePair) return;

    if (localStoragePair) return localStoragePair;

    if (pairs) return pairs[0];
  },
  (get, set, pair: Pair, chainId?: number) => {
    if (pair) {
      const _chainId =
        chainId ||
        window.localStorage.getItem(SELECTED_NETWORK_LOCAL_STORAGE_KEY) ||
        DEFAULT_CHAIN_ID;

      const key = JSON.stringify([_chainId.toString(), "Selected-pair"]);
      const value = JSON.stringify(pair);
      window.localStorage.setItem(key, value);

      set(_selectedPairAtom, pair);
    }
  }
);

// * openTrades는 subgraph에서 가져옴
export const openTradesAtom = atom<Trade[] | null>(null);
export const isOpenTradesLoadingAtom = atom(false);
export const waitingOpenTradesAtom = atom<Trade[] | null>(null);
export const closingTradesAtom = atom<Trade[] | null>(null);

// closeTrades means history of trades
export const closeTradesAtom = atom<CloseTrade[] | null>(null);
export const isCloseTradesLoadingAtom = atom(false);

// * derivedOpenTrades는 openTrades의 각 openTrade에 liqPrice, fundingFee, rolloverFee에 추가 (liqPrice, fundingFee, rolloverFee는 contract에서 가져옴)
export const derivedOpenTradesAtom = atom<DerivedTrade[] | null>(null);
export const isDerivedOpenTradesLoadingAtom = atom(false);

export const parsedOpenTradesAtom = atom<ParsedTrade[] | null>((get) => {
  const trades = get(openTradesAtom);
  const derivedTrades = get(derivedOpenTradesAtom);
  if (!trades) return null;

  if (!derivedTrades) return trades;

  return trades.map((trade) => {
    const pairDerivedTrade = derivedTrades.find(
      (derivedTrade) =>
        trade.pairIndex === derivedTrade.pairIndex &&
        trade.positionIndex === derivedTrade.positionIndex
    );

    return {
      ...trade,
      ...pairDerivedTrade,
    };
  });
});

// * Subgraphdp 주는 Order(컨트랙트에 이미 올라간)
export const openContractOrdersAtom = atom<Order[] | null>(null);
// * Subgraph에서 주는 Order(컨트랙트에 이미 올라간)와 구분해서 API로 받아오는 Order(컨트랙트에 올라가지 않고 백엔드에서 가지고 있는)를 위한 state
export const openApiOrdersAtom = atom<IGetLimitResponse | null>(null);
// * openContractOrders + openApiOrders
export const openOrdersAtom = atom((get) => {
  const contractOrders = get(openContractOrdersAtom);
  const apiOrdersResponse = get(openApiOrdersAtom);
  const pairs = get(pairsAtom);

  let apiOrders: Order[] = [];
  if (apiOrdersResponse && Object.keys(apiOrdersResponse).length > 0) {
    const _apiOrders = Object.entries(apiOrdersResponse);

    apiOrders = _apiOrders.map(([key, value]) => {
      return {
        pairIndex: value.t.pairIndex,
        positionIndex: value.t.index.toString(),
        positionSize: BigNumber.from(value.t.positionSizeUsdc),
        buy: value.t.buy,
        leverage: +formatUnits(value.t.leverage, ADDED_DECIMALS),
        tp: BigNumber.from(value.t.tp),
        sl: BigNumber.from(value.t.sl),
        pair: pairs.find((pair) => pair.pairIndex === value.t.pairIndex),
        minPrice: BigNumber.from(value.t.openPrice),
        maxPrice: BigNumber.from(value.t.openPrice),
        // block: "", // * 실제 컨트랙트에 올라가면 값이 나오는 것 같은데 API call 결과에는 없으니까 optional로 변경
        type: value.orderType === 1 ? "REVERSAL" : "MOMENTUM",
        source: "API",
        apiOrderKey: key,
      } as Order;
    });
  }

  if (
    contractOrders &&
    apiOrders &&
    contractOrders.length > 0 &&
    apiOrders.length > 0
  ) {
    return [...contractOrders, ...apiOrders];
  } else if (contractOrders && contractOrders.length > 0) {
    return contractOrders;
  } else if (apiOrders && apiOrders.length > 0) {
    return apiOrders;
  } else {
    return [];
  }
});

export const chartLinesAtom = atom<{
  [x: string]: (IOrderLineAdapter | undefined)[] | null;
}>({});

export const isOpenOrdersLoadingAtom = atom(false);
export const closingOrdersAtom = atom<Order[] | null>(null);

export const pendingOrdersAtom = atom<PendingOrder[] | null>(null);
export const isPendingOrdersLoadingAtom = atom(false);
export const closingPendingOrdersAtom = atom<PendingOrder[] | null>(null);

export const _activePositionTabAtom = atomWithDefault(() => {
  const chainId =
    window.localStorage.getItem(SELECTED_NETWORK_LOCAL_STORAGE_KEY) ||
    DEFAULT_CHAIN_ID;
  const key = JSON.stringify([chainId, "Active-position-tab"]);

  return window.localStorage.getItem(key)
    ? JSON.parse(window.localStorage.getItem(key)!)
    : POSITION_TYPE.TRADES;
});

export const activePositionTabAtom = atom(
  (get) => get(_activePositionTabAtom),
  (get, set, activePositionTab) => {
    const chainId =
      window.localStorage.getItem(SELECTED_NETWORK_LOCAL_STORAGE_KEY) ||
      DEFAULT_CHAIN_ID;
    const key = JSON.stringify([chainId, "Active-position-tab"]);
    const value = JSON.stringify(activePositionTab);
    window.localStorage.setItem(key, value);

    set(_activePositionTabAtom, activePositionTab);
  }
);

export const _activeOrderTabAtom = atomWithDefault((get) => {
  const chainId =
    window.localStorage.getItem(SELECTED_NETWORK_LOCAL_STORAGE_KEY) ||
    DEFAULT_CHAIN_ID;
  const key = JSON.stringify([chainId, "Active-order-tab"]);

  return window.localStorage.getItem(key)
    ? JSON.parse(window.localStorage.getItem(key)!)
    : MARKET;
});

export const activeOrderTabAtom = atom(
  (get) => get(_activeOrderTabAtom),
  (get, set, activeOrderTab) => {
    const chainId =
      window.localStorage.getItem(SELECTED_NETWORK_LOCAL_STORAGE_KEY) ||
      DEFAULT_CHAIN_ID;
    const key = JSON.stringify([chainId, "Active-order-tab"]);
    const value = JSON.stringify(activeOrderTab);
    window.localStorage.setItem(key, value);

    set(_activeOrderTabAtom, activeOrderTab);
  }
);

export const _activeLongShortTabAtom = atomWithDefault(() => {
  const chainId =
    window.localStorage.getItem(SELECTED_NETWORK_LOCAL_STORAGE_KEY) ||
    DEFAULT_CHAIN_ID;
  const key = JSON.stringify([chainId, "Active-long-short-tab"]);

  return window.localStorage.getItem(key)
    ? JSON.parse(window.localStorage.getItem(key)!)
    : LONG;
});

export const activeLongShortTabAtom = atom(
  (get) => get(_activeLongShortTabAtom),
  (get, set, activeOrderTab) => {
    const chainId =
      window.localStorage.getItem(SELECTED_NETWORK_LOCAL_STORAGE_KEY) ||
      DEFAULT_CHAIN_ID;
    const key = JSON.stringify([chainId, "Active-long-short-tab"]);
    const value = JSON.stringify(activeOrderTab);
    window.localStorage.setItem(key, value);

    set(_activeLongShortTabAtom, activeOrderTab);
  }
);

export const pendingTxnsAtom = atom<any[]>([]);

export const isVisibleSlTpModalAtom = atom(false);
export const isVisibleDetailTradeModalAtom = atom(false);
export const isVisibleDetailOrderModalAtom = atom(false);
export const isVisibleShareTradeModalAtom = atom(false);

export const isVisibleCloseTradeModalAtom = atom(false);
export const isVisibleEditLiqPriceModalAtom = atom(false);

export const selectedTradeAtom = atom<ParsedTrade | null>(null);
export const selectedOrderAtom = atom<Order | null>(null);

export const selectedCloseTradeAtom = atom<DerivedCloseTrade | null>(null);
export const selectedCloseTradeFeeAtom = atom<string>("");
export const selectedOpenTradeFeeAtom = atom<string>("");

export const maxTradesPerPairAtom = atom(MAX_TRADES_PER_PAIR);
export const eventedAtom = atom(false);
export const tradeMutationAtom = atom<any>([]);

export const errorGetTraderFromSubgraphAtom = atom(false);

export const tooltipContentsAtom = atom("");

// export const selectedPairOiAndGroupCollateralAtom = atom<PairOiAndGroupCollateral | null>(null);

export const gtTp900Atom = atom(false);
export const tpValidationAtom = atom<Validation>({ valid: true, message: "" });

export const isTradingPausedAtom = atom(false); // * contract 상태
export const isTradingDoneAtom = atom(false); // * contract 상태

export const selectedPairPriceChangeAtom = atom("");
export const midnightPairsPricesAtom = atom<any>(null);
