// import { useLocalStorage } from "react-use";
import toast from "react-hot-toast";
import { appEventsData } from "config/events";
import { useEffect } from "react";
import EventToast from "./EventToast";
import { isFuture, parse } from "date-fns";
import { useChainId } from "futures-lib/chains";

function useEventToast() {
  // const [visited, setVisited] = useLocalStorage<string[]>("visited-announcements", []);

  const { chainId } = useChainId();

  useEffect(() => {
    toast.dismiss();

    const eventsData = appEventsData;

    eventsData
      .filter((event) => event.isActive)
      .filter((event) => event.chainIds.includes(chainId))
      .filter((event) => isFuture(parse(event.validTill + ", +00", "d MMM yyyy, H:mm, x", new Date())))
      // .filter((event) => Array.isArray(visited) && !visited.includes(event.id))
      .forEach((event) => {
        toast.custom(
          (t) => (
            <EventToast
              event={event}
              id={event.id}
              t={t}
              onClick={() => {
                toast.dismiss(event.id);
                // setVisited((prev) => {
                //   if (prev) {
                //     return [...prev, event.id];
                //   }
                //   return [event.id];
                // });
              }}
            />
          ),
          {
            id: event.id,
            style: {},
          }
        );
      });
    // }, [visited, setVisited, chainId]);

    // * NOTE: 공지 팝업 닫더라도 재접속 시 다시 띄우기 위해서
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId]);
}

export default useEventToast;
