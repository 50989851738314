import { referrerAddressAtom } from "atoms/referral";
import { ethers } from "ethers";
import { isAddress } from "ethers/lib/utils";
import { useSetAtom } from "jotai";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

/**
 * query parameter로 들어온 referrer-address 처리 hook
 *
 */
const useReferrerAddress = () => {
  const [searchParams] = useSearchParams();

  const newReferrerAddress = searchParams.get("referrer-address");

  const setReferrerAddress = useSetAtom(referrerAddressAtom);

  useEffect(() => {
    if (
      newReferrerAddress !== ethers.constants.AddressZero &&
      newReferrerAddress !== null &&
      isAddress(newReferrerAddress)
    ) {
      setReferrerAddress(newReferrerAddress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useReferrerAddress;
