import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import { getSubgraphHttpLink } from "config/subgraph";

import { onError } from "@apollo/client/link/error";

export function getGraphClient(chainId: number, subgraphUrl: string, setSubgraphConnectionError) {
  return new ApolloClient({
    link: ApolloLink.from([errorLink(setSubgraphConnectionError), getSubgraphHttpLink(subgraphUrl)]),
    cache: new InMemoryCache(),
  });

  // throw new Error(`Unsupported chain ${chainId}`);
}

const errorLink = (setSubgraphConnectionError) => {
  return onError(({ graphQLErrors, networkError }) => {
    // if (graphQLErrors)
    //   graphQLErrors.forEach(({ message, locations, path }) =>
    //     console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    //   );
    // if (networkError) console.log(`[Network error]: ${networkError}`);
    if (graphQLErrors || networkError) {
      setSubgraphConnectionError(true);
    }
  });
};
