import SEO from "components/Common/SEO";
// import Footer from "components/Footer/Footer";
import { getPageTitle } from "futures-lib/legacy";
import "./PageNotFound.css";
import { Trans } from "@lingui/macro";

function PageNotFound() {
  return (
    <SEO title={getPageTitle("Page not found")}>
      <div className="page-layout">
        <div className="page-not-found-container">
          <div className="page-not-found">
            <h2>
              <Trans>Page not found</Trans>
            </h2>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </SEO>
  );
}

export default PageNotFound;
