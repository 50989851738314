import { Trans } from "@lingui/macro";
import cx from "classnames";
import { Link } from "react-router-dom";
import CertikImg from "img/ic-certik.png";
import Secure3Img from "img/ic-secure3.svg";
import SlidePartners from "components/Landing/SlidePartners";
import { useTrader } from "futures-domain/trades/useTrader";

const Landing = () => {
  useTrader();

  return (
    <div
      className={cx(
        "min-h-[calc(100vh-62px)]",
        "lg:min-h-[calc(100vh-67px)]",
        "flex flex-col justify-between md:justify-start"
      )}
    >
      <div
        className={cx(
          // "bg-[url('img/bg-landing-mobile.jpg')]",
          // "lg:bg-[url('img/bg-landing-desktop.jpg')]",
          "min-w-[37.5rem]",
          "max-w-[155rem] mx-auto",
          "w-full",
          // "min-h-[calc(100vh-62px)]",
          // "lg:min-h-[calc(100vh-67px)]",
          "px-[1.6rem]",
          "md:px-[6rem] xl:px-[12.4rem]",
          "flex flex-col",
          "pb-[5.4rem] md:pb-[9.9rem]"
        )}
      >
        <div>
          <div
            className={cx(
              "pt-[11.6rem] pb-[2rem] font-bold text-[4rem] leading-[1.1]",
              "pt-[8rem]",
              "md:pt-[12rem] md:text-[6.4rem] md:leading-[1.2]",
              "lg:pt-[14.9rem] lg:text-[9.6rem] lg:leading-[1.3]"
            )}
          >
            {/* 화면에 보여주지 않고 SEO 용도 */}
            <h1 className="hidden-for-seo">
              Gambit: Revolutionizing Decentralized Finance with Premier Leveraged Trading Platform and Advanced Oracle
              Integration
            </h1>
            <h2 className="hidden-for-seo">Gambit Decentralized Perpetual Exchange(Lowest Fees Highest Profits)</h2>
            <div className="leading-[1.1]">
              <Trans>Lowest Fees</Trans>
            </div>
            <div className="flex items-center leading-[1.1]">
              <Trans>Perpetual DEX</Trans>
            </div>
          </div>

          <div
            className={cx(
              "flex flex-col text-[1.6rem] xs+:text-[1.8rem] text-white text-opacity-80",
              "md:text-[2rem]",
              "lg:text-[2.4rem]"
            )}
          >
            <div className="flex flex-col tracking-[-0.001em]">
              {/* <Trans>0% Opening Fees to Maximize Your Trading Journey</Trans> */}
              <Trans>Enjoy 50% off fees and spreads</Trans>
            </div>
          </div>

          {/* <div className={cx("xs-:hidden flex flex-col text-[1.5rem] text-white text-opacity-80", "md:text-[2rem]")}>
            <div className="flex flex-col">
              <Trans>Enjoy 50% off</Trans>
            </div>
            <div className="flex flex-col">
              <Trans>on closing fees and spreads</Trans>
            </div>
          </div> */}

          <Link className="inline-flex items-center mt-[3.6rem]" to="/trade">
            <button
              className={cx(
                "bg-brand-1 w-[20.4rem] h-[6.4rem] flex items-center justify-center rounded-[999px]",
                "hover:text-brand-1 hover:bg-brand-1 hover:bg-opacity-10 hover:border hover:border-brand-1",
                "text-black-1 heading6",
                "md:heading3"
                // "mb-[21.6rem] lg:mb-[23.05rem]"
              )}
            >
              <Trans>TRADE NOW</Trans>
            </button>
          </Link>
        </div>

        <div className="mt-[16rem] md:mt-[18rem] mx-[-1.6rem] md:mx-[-6rem] xl:mx-[-12.4rem] lg:px-[2.8rem]">
          <SlidePartners />
        </div>

        <div className="mt-[4rem] md:mt-[8rem]">
          <div className="mb-[1.2rem] text-white text-opacity-56 text-center font-medium text-[1.5rem] md:text-[2rem] font-space-grotesk">
            <Trans>Audited by</Trans>
          </div>

          <div className="flex justify-center gap-[2.4rem] md:gap-[4.8rem]">
            <a
              className="cursor-pointer"
              href="https://skynet.certik.com/projects/gambit-exchange"
              target="_blank"
              rel="ugc"
            >
              <img className={cx("h-[5.6rem] md:h-[7.5rem]")} src={CertikImg} alt="certik logo" />
            </a>

            <a
              className="cursor-pointer"
              href="https://github.com/Secure3Audit/Secure3Academy/blob/main/audit_reports/Gambit/Gambit_final_Secure3_Audit_Report.pdf"
              target="_blank"
              rel="ugc"
            >
              <img className={cx("h-[5.6rem] md:h-[7.5rem]")} src={Secure3Img} alt="secure3 logo" />
            </a>
          </div>
        </div>
      </div>
      {/* <div className="bg-[url('img/bg-landing-bottom.jpg')] bg-cover flex items-center justify-center mt-[7.2rem] md:mt-[7.9rem] py-[2.4rem] md:py-[2.6rem] h-full mb-[4rem] md:mb-[8rem]">
        <div className="flex items-center justify-center">
          <div className="mr-[1.4rem] md:mr-[20rem]">
            <div className="text-brand-1 font-medium text-[2rem] md:text-[3.2rem] leading-[1.1]">
              <Trans>Trading Competition</Trans>
            </div>
            <div className="font-medium text-[1.2rem] md:text-[1.5rem] mt-[0.4rem] md:mt-[0.6rem] mb-[1.2rem]">
              <Trans>with a total prize pool of $65,000 up for grabs</Trans>
            </div>
            <Link
              className="inline-flex items-center justify-center bg-brand-1 font-semibold text-[1.2rem] md:text-[1.4rem] text-black-1 rounded-[6px] h-[2.3rem] md:h-[2.8rem] cursor-pointer px-[1.2rem] py-[0.4rem]"
              to="/trading-competition"
            >
              <Trans>JOIN NOW</Trans>
            </Link>
          </div>
          <div>
            <img className="w-[4.9rem] h-[7.8rem] md:w-[7.05rem] md:h-[11.454rem]" src={trophy} alt="trophy" />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Landing;
