import { APP_MODES } from "futures-domain/types";
import { createSupabaseClient } from "./supabase";

export const getVersion = async () => {
  if (process.env.REACT_APP_MODE === APP_MODES.LOCAL) return null;

  const supabase = createSupabaseClient();

  const versionKey = process.env.REACT_APP_MODE === APP_MODES.PRODUCTION ? "production_version" : "stage_version";

  const { data: version } = await supabase.from("config_map").select("*").eq("key", versionKey);

  if (version) {
    return version[0].value;
  }
  return null;
};
