import { useEffect, useState } from "react";
import { ethers } from "ethers";

import { ETH_MAINNET, getExplorerUrl, getRpcUrl } from "config/chains";
import { bigNumberify, expandDecimals } from "./numbers";
import { GAMBIT_USD_DECIMALS } from "components/Exchange/constants";
import defaultCoinIcon from "img/ic-default_coin.svg";
import { t } from "@lingui/macro";

// use a random placeholder account instead of the zero address as the zero address might have tokens
export const PLACEHOLDER_ACCOUNT = ethers.Wallet.createRandom().address;

export const MIN_PROFIT_TIME = 0;

// export const USDG_ADDRESS = getContract(CHAIN_ID, "USDG");

export const BASIS_POINTS_DIVISOR = 10000;
// export const MAX_LEVERAGE = 100 * BASIS_POINTS_DIVISOR;
// export const MAX_ALLOWED_LEVERAGE = 50 * BASIS_POINTS_DIVISOR;
// export const CRYPTO_MAX_ALLOWED_LEVERAGE = 200 * BASIS_POINTS_DIVISOR;
// export const FOREX_MAX_ALLOWED_LEVERAGE = 1000 * BASIS_POINTS_DIVISOR;

export const MAX_PRICE_DEVIATION_BASIS_POINTS = 750;
export const DEFAULT_GAS_LIMIT = 1 * 1000 * 1000;
export const SECONDS_PER_YEAR = 31536000;
// export const USDG_DECIMALS = 18;
// export const USD_DECIMALS = 30;
export const DEPOSIT_FEE = 30;
// export const DUST_BNB = "2000000000000000";
// export const DUST_USD = expandDecimals(1, USD_DECIMALS);
export const PRECISION = expandDecimals(1, 30);
// export const GLP_DECIMALS = 18;
// export const GMX_DECIMALS = 18;
export const DEFAULT_MAX_USDG_AMOUNT = expandDecimals(200 * 1000 * 1000, 18);

export const TAX_BASIS_POINTS = 60;
export const STABLE_TAX_BASIS_POINTS = 5;
export const MINT_BURN_FEE_BASIS_POINTS = 25;
export const SWAP_FEE_BASIS_POINTS = 25;
export const STABLE_SWAP_FEE_BASIS_POINTS = 1;
export const MARGIN_FEE_BASIS_POINTS = 10;

export const LIQUIDATION_FEE = expandDecimals(5, GAMBIT_USD_DECIMALS);

export const TRADES_PAGE_SIZE = 100;

export const GLP_COOLDOWN_DURATION = 0;
export const THRESHOLD_REDEMPTION_VALUE = expandDecimals(993, 27); // 0.993
export const FUNDING_RATE_PRECISION = 1000000;

export const SWAP = "Swap";
export const INCREASE = "Increase";
export const DECREASE = "Decrease";
export const LONG = t`Long`;
export const SHORT = t`Short`;

export const MARKET = t`Market`;
export const LIMIT = t`Limit`;
export const STOP = t`Stop`;

export function getAccountUrl(chainId, account) {
  if (!account) {
    return getExplorerUrl(chainId);
  }
  return getExplorerUrl(chainId) + "address/" + account;
}

export const getTradeTypeNumber = (trade: string) => {
  switch (trade) {
    case MARKET:
      return 0;
    case LIMIT:
      return 1;
    case STOP:
      return 2;
    default:
      throw Error("wrong trade type");
  }
};

export const LONG_SHORT_OPTIONS = [LONG, SHORT];
export const ORDER_OPTIONS = [MARKET, LIMIT, STOP];

export const LEVERAGE_ORDER_OPTIONS = [MARKET, LIMIT, STOP];
export const SWAP_ORDER_OPTIONS = [MARKET, LIMIT];
export const SWAP_OPTIONS = [LONG, SHORT, SWAP];
export const DEFAULT_SLIPPAGE_AMOUNT = 30;
export const DEFAULT_HIGHER_SLIPPAGE_AMOUNT = 100;

export const REFERRAL_CODE_QUERY_PARAM = "ref";
export const MAX_REFERRAL_CODE_LENGTH = 20;

export const MIN_PROFIT_BIPS = 0;

export function getLeverage({
  size,
  sizeDelta,
  increaseSize,
  collateral,
  collateralDelta,
  increaseCollateral,
  entryFundingRate,
  cumulativeFundingRate,
  hasProfit,
  delta,
  includeDelta,
}) {
  if (!size && !sizeDelta) {
    return;
  }
  if (!collateral && !collateralDelta) {
    return;
  }

  let nextSize = size ? size : bigNumberify(0);
  if (sizeDelta) {
    if (increaseSize) {
      nextSize = size.add(sizeDelta);
    } else {
      if (sizeDelta.gte(size)) {
        return;
      }
      nextSize = size.sub(sizeDelta);
    }
  }

  let remainingCollateral = collateral ? collateral : bigNumberify(0);
  if (collateralDelta) {
    if (increaseCollateral) {
      remainingCollateral = collateral.add(collateralDelta);
    } else {
      if (collateralDelta.gte(collateral)) {
        return;
      }
      remainingCollateral = collateral.sub(collateralDelta);
    }
  }

  if (delta && includeDelta) {
    if (hasProfit) {
      remainingCollateral = remainingCollateral.add(delta);
    } else {
      if (delta.gt(remainingCollateral)) {
        return;
      }

      remainingCollateral = remainingCollateral.sub(delta);
    }
  }

  if (remainingCollateral.eq(0)) {
    return;
  }

  remainingCollateral = sizeDelta
    ? remainingCollateral.mul(BASIS_POINTS_DIVISOR - MARGIN_FEE_BASIS_POINTS).div(BASIS_POINTS_DIVISOR)
    : remainingCollateral;
  if (entryFundingRate && cumulativeFundingRate) {
    const fundingFee = size.mul(cumulativeFundingRate.sub(entryFundingRate)).div(FUNDING_RATE_PRECISION);
    remainingCollateral = remainingCollateral.sub(fundingFee);
  }

  return nextSize.mul(BASIS_POINTS_DIVISOR).div(remainingCollateral);
}

export function getSwapFeeBasisPoints(isStable) {
  return isStable ? STABLE_SWAP_FEE_BASIS_POINTS : SWAP_FEE_BASIS_POINTS;
}

export function shortenAddress(address, length) {
  if (!length) {
    return "";
  }
  if (!address) {
    return address;
  }
  if (address.length < 10) {
    return address;
  }
  let left = Math.floor((length - 3) / 2) + 1;
  return address.substring(0, left) + "..." + address.substring(address.length - (length - (left + 3)), address.length);
}

export function useENS(address) {
  const [ensName, setENSName] = useState<string | undefined>();

  useEffect(() => {
    async function resolveENS() {
      try {
        if (address) {
          const provider = new ethers.providers.JsonRpcProvider(getRpcUrl(ETH_MAINNET));
          const name = await provider.lookupAddress(address.toLowerCase());
          if (name) {
            setENSName(name);
          } else {
            setENSName(undefined);
          }
        }
      } catch (e) {
        setENSName(undefined);
      }
    }
    resolveENS();
  }, [address]);

  return { ensName };
}

export function isMobileDevice(navigator) {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

/**
 * 각 각의 period를 seconds 단위(timestamp)로 변경한 값
 *
 * CHART_PERIODS["5m"] => 60 * 5 => 300초
 * CHART_PERIODS["1h"] => 60 * 60 => 3600초
 */
export const CHART_PERIODS = {
  "1": 60,
  "2": 60 * 2,
  "5": 60 * 5,
  "15": 60 * 15,
  "30": 60 * 30,
  "60": 60 * 60,
  "120": 60 * 60 * 2,
  "240": 60 * 60 * 4,
  "360": 60 * 60 * 6,
  "720": 60 * 60 * 12,
  "1D": 60 * 60 * 24,
  "1W": 60 * 60 * 24 * 7,
};

export function getPageTitle(data) {
  const first = data;
  const second = t`Gambit`;
  const third = t({
    id: "msg.title / Lowest Fees Perpetual DEX on Arbitrum & zkSync Era",
    message: `Lowest Fees Perpetual DEX on Arbitrum & zkSync Era`,
  });
  return first ? `${first} | ${second} | ${third}` : `${second} | ${third}`;
}

export function isHashZero(value) {
  return value === ethers.constants.HashZero;
}
export function isAddressZero(value) {
  return value === ethers.constants.AddressZero;
}

// https://stackoverflow.com/questions/62192049/how-do-i-dynamically-import-images-in-react
export function importImage(name) {
  let tokenImage: string | undefined = undefined;

  try {
    tokenImage = require("img/" + name);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  return tokenImage;
}

export function getTwitterIntentURL(text, url = "", hashtag = "") {
  let finalURL = "https://twitter.com/intent/tweet?text=";
  if (text.length > 0) {
    finalURL += Array.isArray(text) ? text.map((t) => encodeURIComponent(t)).join("%0a%0a") : encodeURIComponent(text);
    if (hashtag.length > 0) {
      finalURL += "&hashtags=" + encodeURIComponent(hashtag.replace(/#/g, ""));
    }
    if (url.length > 0) {
      finalURL += "&url=" + encodeURIComponent(url);
    }
  }
  return finalURL;
}

export function arrayURLFetcher(urlArr) {
  const fetcher = (url) => fetch(url).then((res) => res.json());
  return Promise.all(urlArr.map(fetcher));
}

interface FetcherError extends Error {
  info: any;
  status: number;
}

export async function swrFetcher<JSON = any>(input: RequestInfo, init?: RequestInit): Promise<JSON> {
  const res = await fetch(input, init);
  if (!res.ok) {
    const error = new Error("An error occurred while fetching the data.") as FetcherError;
    // Attach extra info to the error object.
    error.info = await res.json();
    error.status = res.status;
    throw error;
  }

  return res.json();
}

export async function swrPairsPricesFetcher(input: RequestInfo, init?: RequestInit) {
  const res = await fetch(input, init);
  if (!res.ok) {
    const error = new Error("An error occurred while fetching the data.") as FetcherError;
    // Attach extra info to the error object.
    error.info = await res.json();
    error.status = res.status;
    throw error;
  }

  const priceObjs = {};

  const pairsPrices = await res.json();
  pairsPrices.forEach((priceObj) => {
    const { price, expo, conf, publish_time } = priceObj.price;
    // const formattedPrice = ethers.utils.formatUnits(ethers.BigNumber.from(price), Math.abs(expo));

    // 요청을 보낼 때는 0x가 붙은 id 값을 사용하는데,
    // 실제 price 응답을 받으면, 0x가 빠진 id 값이 들어가 있음
    // 요청을 보낼 때의 id 값과 통일시키기 위해서 응답 id에도 0x를 붙여 줌
    // priceObjs[`0x${priceObj.id}`] = formattedPrice;
    priceObjs[`0x${priceObj.id}`] = {
      price,
      expo,
      conf,
      publish_time,
    };
  });

  return priceObjs;
}

// https://stackoverflow.com/questions/62192049/how-do-i-dynamically-import-images-in-react
export function importTokenImage(name: string, size: number = 40) {
  let tokenImage: string | undefined = undefined;

  try {
    tokenImage = require(`img/ic_${name}_${size}.svg`);
  } catch (error) {
    // eslint-disable-next-line no-console
    tokenImage = defaultCoinIcon;
  }

  return tokenImage;
}
