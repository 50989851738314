import { toast, ToastContent, ToastOptions } from "react-toastify";
import error from "img/toast-error.svg";
import success from "img/toast-success.svg";
import Lottie from "lottie-react";
import pendingAnimation from "animation/pending.json";

export const helperToast = {
  success: (content: ToastContent, opts?: ToastOptions) => {
    toast.success(typeof content === "string" ? capitalize(content) : content, {
      ...opts,
      // autoClose: 7000,
      icon: () => <img className="w-[1.6rem] h-[1.6rem]" src={success} alt="success toast icon" />,
    });
  },
  error: (content: ToastContent, opts?: ToastOptions) => {
    toast.error(typeof content === "string" ? capitalize(content) : content, {
      ...opts,
      icon: () => <img className="w-[1.6rem] h-[1.6rem]" src={error} alt="error toast icon" />,
    });
  },
  info: (content: ToastContent, opts?: ToastOptions) => {
    toast.info(typeof content === "string" ? capitalize(content) : content, {
      ...opts,
      icon: () => <Lottie className="w-[2rem] h-[2rem]" animationData={pendingAnimation} />,
    });
  },
};

const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const capitalizeString = (str: string) => {
  try {
    return str
      .split(" ")
      .map((word) => capitalize(word))
      .join(" ");
  } catch (e) {
    return str;
  }
};
