import { MockUSDC } from "@changerio/futures-contracts/dist/typechain-types";
import { formatUnits } from "@ethersproject/units";
import { i18n as i18nCore } from "@lingui/core";
import { Trans, t } from "@lingui/macro";
import { I18nProvider } from "@lingui/react";
import BN from "bignumber.js";
import cx from "classnames";
import Tooltip from "components/Tooltip/Tooltip";
import { getContract } from "config/contracts";
import { BigNumber } from "ethers";
import { Token } from "futures-domain/tokens";
import { Pair } from "futures-domain/trades/types";
import {
  getLiqPrice,
  getOpeningFeeWithNetworkFee,
  makeNdigits,
  trimPriceString,
} from "futures-domain/trades/utils";
import { useChainId } from "futures-lib/chains";
import { newContractFetcher } from "futures-lib/contracts";
import { useGetContract } from "futures-lib/contracts/contract";
import { MARKET } from "futures-lib/legacy";
import { formatAmount, numberWithCommas } from "futures-lib/numbers";
import { ReactComponent as InfoCircleSvg } from "img/ic-info-circle.svg";
import { ReactComponent as NoColorInvalidSvg } from "img/ic-no-color-invalid.svg";
import { ReactComponent as PresentSvg } from "img/ic-present.svg";
import { useMemo } from "react";
import ReactDOMServer from "react-dom/server";
import { Tooltip as ReactTooltip } from "react-tooltip";
import useSWR from "swr";
import { zeroAddress } from "viem";

export const TradeInformation = ({
  positionSize,
  fromToken,
  confidenceSpreadPercent,
  confidenceSpreadValue,
  selectedPair,
  delegationFeeThreshhold,
  leverageOption,
  selectedPairPriceValue,
  fromValue,
  isLong,
  activeOrderTab,
  selectedPairLimitOrStopPrice,
}: // isGasLess,
{
  positionSize: string;
  fromToken: Token;
  confidenceSpreadPercent: BN | undefined;
  confidenceSpreadValue: string | undefined;
  selectedPair: Pair | undefined;
  delegationFeeThreshhold: number | undefined;
  leverageOption: number | undefined;
  selectedPairPriceValue: string | undefined;
  fromValue: string;
  isLong: boolean;
  activeOrderTab: any;
  selectedPairLimitOrStopPrice: string;
}) => {
  const { chainId } = useChainId();

  const incentiveVaultContractAddress = getContract(chainId, "IncentiveVault");
  // console.log(incentiveVaultContractAddress);
  // const incentiveVaultContract = useGetContract("GambitIncentiveVault") as GambitIncentiveVault;
  const collateralContract = useGetContract("MockUSDC") as MockUSDC;
  // console.log(collateralContract);

  const { data: incentiveVaultBalance } = useSWR<BigNumber>(
    collateralContract && [
      collateralContract,
      "balanceOf",
      [incentiveVaultContractAddress],
    ],
    {
      fetcher: newContractFetcher,
      shouldRetryOnError: true,
      errorRetryInterval: 2000,
      refreshInterval: 2000,
    }
  );

  // * Incentive Vault balance >= OpenFee
  // const isOpenFeeFree = useMemo(() => {}, []);
  // console.log(incentiveVaultBalance?.toString());

  const networkFee = useMemo(() => {
    if (!delegationFeeThreshhold || !positionSize) return;

    if (+positionSize < +delegationFeeThreshhold && selectedPair) {
      return +selectedPair?.fee?.oracleFee;
    }
    return 0;
  }, [delegationFeeThreshhold, positionSize, selectedPair]);
  // console.log(networkFee); // $0.1
  // console.log(selectedPair?.fee?.openFeeP); // 0.02
  /**
   * * 변경 전: 담보금 x 레버리지 x OpenFeeP
   * * 변경 후: (담보금 - 네트워크피) x 레버리지 x OpenFeeP
   */
  const openingFee = useMemo(() => {
    // positionSize * 요율
    if (
      !fromValue ||
      !fromToken.decimals ||
      !leverageOption ||
      !selectedPair ||
      fromValue === "0"
    )
      return;

    try {
      return getOpeningFeeWithNetworkFee(
        +selectedPair.fee?.openFeeP,
        fromValue,
        leverageOption,
        networkFee
      );
    } catch (e) {}
  }, [networkFee, fromToken.decimals, fromValue, leverageOption, selectedPair]);

  /**
   * * openingFee - Incentive Vault balance
   *
   * * 만약 opening fee > balance 일 경우
   * * Incentive Vault가 balance 만큼 opening fee 지불
   * * trader가 opening fee - balance 만큼 opening fee 지불 (지불한 만큼 posUsdc 삭감)
   */
  const openingFeeWithIncentiveVault = useMemo(() => {
    if (openingFee == null) return;

    if (incentiveVaultBalance == null) return openingFee;

    if (new BN(incentiveVaultBalance?.toString()).eq(0)) return openingFee;

    const formattedIncentiveVaultBalance = formatUnits(
      incentiveVaultBalance,
      fromToken.decimals
    );

    if (new BN(formattedIncentiveVaultBalance).gte(openingFee)) {
      return 0;
    } else {
      return new BN(openingFee)
        .minus(formattedIncentiveVaultBalance)
        .toFixed(2);
    }
  }, [fromToken.decimals, incentiveVaultBalance, openingFee]);

  // * spread * leverage 값
  const spreadXLeverage = useMemo(() => {
    if (!confidenceSpreadPercent || !leverageOption) return;

    return new BN(confidenceSpreadPercent)
      .multipliedBy(leverageOption)
      .toFixed(2);
  }, [confidenceSpreadPercent, leverageOption]);

  const spreadXLeverageWarningMessage = useMemo(() => {
    if (!spreadXLeverage || +spreadXLeverage < 10) return;

    if (+spreadXLeverage >= 20) {
      return <Trans>[Warning] The spread is very high</Trans>;
    } else if (+spreadXLeverage >= 10) {
      return <Trans>[Warning] The spread is high</Trans>;
    } else {
      return;
    }
  }, [spreadXLeverage]);

  const liqPriceValue = useMemo(() => {
    if (!selectedPairPriceValue || !leverageOption) return;

    return getLiqPrice(
      selectedPairPriceValue,
      fromValue,
      leverageOption,
      isLong
    );
  }, [fromValue, isLong, leverageOption, selectedPairPriceValue]);

  const estimatedExecutionPriceValue = useMemo(() => {
    if (activeOrderTab === MARKET) {
      if (!selectedPairPriceValue || !confidenceSpreadValue) return;

      if (isLong) {
        return trimPriceString(
          new BN(selectedPairPriceValue).plus(confidenceSpreadValue).toFixed(),
          false
        );
      } else {
        // return trimPriceString((+selectedPairPriceValue - +confidenceSpreadValue).toString());
        return trimPriceString(
          new BN(selectedPairPriceValue).minus(confidenceSpreadValue).toFixed(),
          false
        );
      }
    }
    // * Stop, LIMIT type일 때 예상체결가는 진입요청가격 + or - Spread
    // else if (activeOrderTab === STOP) {
    if (!selectedPairLimitOrStopPrice || !confidenceSpreadValue) return;

    if (isLong) {
      return trimPriceString(
        new BN(selectedPairLimitOrStopPrice)
          .plus(confidenceSpreadValue)
          .toFixed(),
        false
      );
    } else {
      return trimPriceString(
        new BN(selectedPairLimitOrStopPrice)
          .minus(confidenceSpreadValue)
          .toFixed(),
        false
      );
    }
    // }
    // * Limit type일 때 예상체결가는 진입요청가격
    // else if (activeOrderTab === LIMIT) {
    //   if (!selectedPairLimitOrStopPrice || !confidenceSpreadValue) return;
    //   return trimPriceString(new BN(selectedPairLimitOrStopPrice).toFixed(), true);
    // }
  }, [
    activeOrderTab,
    confidenceSpreadValue,
    isLong,
    selectedPairLimitOrStopPrice,
    selectedPairPriceValue,
  ]);

  // const {
  //   data: ethPriceFromAltPro,
  //   isLoading: isEthPriceLoadingFromAltPro,
  //   error: ethPriceErrorFromAltPro,
  // } = useSWR(PRODUCTION_OR_STAGING_ENV && "https://api.alt.pro/v1/common/coin/5cdaab544fee8b2a6dd64e7e", {
  //   fetcher: swrFetcher,
  //   refreshInterval: 1000 * 20,
  // });
  // const {
  //   data: ethPriceFromCoingecko,
  //   isLoading: isEthPriceLoadingFromCoingecko,
  //   error: ethPriceErrorFromCoingecko,
  // } = useSWR(
  //   !PRODUCTION_OR_STAGING_ENV && "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd",
  //   {
  //     fetcher: swrFetcher,
  //     refreshInterval: 1000 * 30,
  //   }
  // );
  // const [ethPrice, setEthPrice] = useState<string>();
  // useEffect(() => {
  //   if (!isEthPriceLoadingFromAltPro && ethPriceFromAltPro && !ethPriceErrorFromAltPro) {
  //     const {
  //       currency: { usdPrice },
  //     } = ethPriceFromAltPro;
  //     if (usdPrice) {
  //       setEthPrice(usdPrice);
  //     }
  //   }
  // }, [ethPriceFromAltPro, ethPriceErrorFromAltPro, isEthPriceLoadingFromAltPro]);
  // useEffect(() => {
  //   if (!isEthPriceLoadingFromCoingecko && ethPriceFromCoingecko && !ethPriceErrorFromCoingecko) {
  //     const {
  //       ethereum: { usd },
  //     } = ethPriceFromCoingecko;
  //     if (usd) {
  //       setEthPrice(usd);
  //     }
  //   }
  // }, [ethPriceFromCoingecko, ethPriceErrorFromCoingecko, isEthPriceLoadingFromCoingecko]);
  // const gasFee = useMemo(() => {
  //   if (!ethPrice || !weiGasFee) return;
  //   return new BN(ethPrice).multipliedBy(formatUnits(weiGasFee, "ether"));
  // }, [ethPrice, weiGasFee]);
  return (
    <div className="px-[1.6rem] py-[1.6rem] text-[1.3rem] space-y-[0.8rem] font-medium">
      <div className="flex justify-between items-center px-[1.6rem] bg-gray-70 py-[0.55rem] rounded-[4px]">
        <Tooltip
          handle={
            <div className="text-gray-15 flex items-center">
              <Trans>POSITION SIZE</Trans>
              <InfoCircleSvg className="fill-gray-30 ml-[2px]" />
            </div>
          }
          disableHandleStyle={true}
          position="left-top"
          tooltipClassName="w-[30rem]"
          renderContent={() => (
            <span>
              <Trans id="msg.tradeBox / Pay x Leverage">Pay x Leverage</Trans>
            </span>
          )}
        />
        {positionSize && (
          <div className="font-space-grotesk text-gray-00 overflow-x-auto max-w-[21rem] whitespace-nowrap">
            <div className="flex">
              {numberWithCommas(positionSize)}
              {/* <AnimatedNumber to={+positionSize} /> */}
              <span className="ml-[3px]">{fromToken.symbol}</span>
            </div>
          </div>
        )}
      </div>
      <div className="flex justify-between items-center px-[1.6rem]">
        <Tooltip
          handle={
            <div className="text-gray-15 flex items-center">
              <Trans>EST. EXECUTION PRICE</Trans>
              <InfoCircleSvg className="fill-gray-30 ml-[2px]" />
            </div>
          }
          disableHandleStyle={true}
          position="left-top"
          tooltipClassName="w-[30rem]"
          renderContent={() => (
            <span>
              <Trans>
                The estimated price including spread at which your trade will
                execute.
              </Trans>
            </span>
          )}
        />

        {estimatedExecutionPriceValue !== undefined && (
          <div className="font-space-grotesk flex text-gray-00">
            {/* {["CNH", "JPY"].includes(selectedPair.to) ? "¥" : "$"} */}
            {/* $<AnimatedNumber to={+estimatedExecutionPriceValue} /> */}
            {numberWithCommas(trimPriceString(estimatedExecutionPriceValue))}
          </div>
        )}
      </div>
      <div className="flex justify-between items-center px-[1.6rem]">
        <Tooltip
          handle={
            <div className="text-gray-15 flex items-center">
              <Trans>SPREAD</Trans>
              <InfoCircleSvg className="fill-gray-30 ml-[2px]" />
            </div>
          }
          disableHandleStyle={true}
          position="left-top"
          tooltipClassName="w-[30rem]"
          renderContent={() => (
            <span>
              <Trans>
                Spreads are constantly changing depending on market conditions.
              </Trans>
            </span>
          )}
        />

        <div
          data-tooltip-id="tooltip-spread-warning"
          data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
            <span className="text-[1.3rem] font-bold">
              <I18nProvider i18n={i18nCore}>
                {spreadXLeverageWarningMessage}
              </I18nProvider>
            </span>
          )}
        >
          <div
            className={cx("flex items-center font-space-grotesk text-gray-00")}
          >
            {spreadXLeverage && +spreadXLeverage >= 10 && (
              <NoColorInvalidSvg
                className={cx(
                  "w-[1.75rem] h-[1.75rem] mr-[3px]",
                  { "fill-brand-3": +spreadXLeverage >= 10 },
                  { "fill-red-2": +spreadXLeverage >= 20 }
                )}
              />
            )}
            {confidenceSpreadValue !== undefined && (
              <span
                className={cx(
                  { "text-brand-3": spreadXLeverage && +spreadXLeverage >= 10 },
                  { "text-red-2": spreadXLeverage && +spreadXLeverage >= 20 }
                )}
              >
                {/* {confidenceSpreadPercent?.toFixed(3)}% (${confidenceSpreadValue}) */}
                {confidenceSpreadValue === "0" ? (
                  <span className="font-space-grotesk text-brand-1 animate-pulse">
                    ZERO
                  </span>
                ) : (
                  // `${confidenceSpreadPercent?.toFixed(3)}% ($${confidenceSpreadValue})`
                  <div className="flex items-center">
                    {confidenceSpreadPercent !== undefined && (
                      <span className="flex items-center">
                        {/* <AnimatedNumber to={+confidenceSpreadPercent.toFixed(3)} decimals={3} />% */}
                        {confidenceSpreadPercent?.toFixed(3)}%
                      </span>
                    )}
                    {confidenceSpreadValue && (
                      <span className="flex items-center ml-[4px]">
                        ($
                        {/* <AnimatedNumber
                                      to={+makeNdigits(confidenceSpreadValue, 4)}
                                      decimals={makeNdigits(confidenceSpreadValue, 4).split(".")[1].length || 0}
                                    /> */}
                        {makeNdigits(confidenceSpreadValue, 4)})
                      </span>
                    )}
                  </div>
                )}
              </span>
            )}
          </div>
        </div>

        {spreadXLeverage && +spreadXLeverage >= 10 && (
          <ReactTooltip
            className="!bg-gray-70 !bg-opacity-95 !font-space-grotesk !rounded-[6px] !text-white !border !border-white !border-opacity-10"
            id="tooltip-spread-warning"
            noArrow={true}
            place="bottom"
          />
        )}
      </div>
      <div className="flex justify-between items-center px-[1.6rem]">
        <Tooltip
          handle={
            <div className="text-gray-15 flex items-center">
              <Trans>FEES</Trans>
              <InfoCircleSvg className="fill-gray-30 ml-[2px]" />
            </div>
          }
          disableHandleStyle={true}
          position="left-top"
          tooltipClassName="w-[31rem]"
          renderContent={() => (
            <span className="">
              {selectedPair && (
                <span className="">
                  {/* // * NOTE: openFeeP는 dev, gov에게 각각 가는 요율이고 실제 사용자는 dev, gov 두 곳에 지불해야 하기 때문에 2배 해준다. */}
                  {/* <Trans>The opening fee is {+selectedPair.fee?.openFeeP * 2}%.</Trans> */}
                  {selectedPair?.fee?.openFeeP === "0.0000000001" ? (
                    <Trans>0% Event Opening Fee Applied</Trans>
                  ) : (
                    <>
                      Opening fee ({+selectedPair?.fee?.openFeeP * 2})%
                      {networkFee !== undefined && networkFee > 0 ? (
                        ` + Network fee (${`$${networkFee}`})`
                      ) : (
                        <span className="">
                          {" "}
                          + Network fee(
                          <span className="font-space-grotesk text-brand-1 animate-pulse">
                            FREE
                          </span>
                          )
                        </span>
                      )}
                    </>
                  )}
                </span>
              )}
              <span className="ml-[3px]">
                (
                <a
                  className="font-bold text-blue-500 underline"
                  href="https://docs.gambit.trade/fees"
                  target="_blank"
                  rel="ugc"
                >
                  <Trans id="msg.tradeBox / FEES Learn more">Learn more</Trans>
                </a>
                )
              </span>
              {incentiveVaultContractAddress &&
                incentiveVaultContractAddress !== zeroAddress && (
                  <div className="flex flex-col mt-[8px]">
                    <span>
                      • Incentive Vault Balances:{" "}
                      {formatAmount(
                        incentiveVaultBalance,
                        fromToken.decimals,
                        2,
                        true
                      )}{" "}
                      {fromToken.symbol}
                    </span>
                    <span>
                      • If our Incentive Vault has a balance, we'll pay the FEES
                      for you
                    </span>
                    <span>
                      • Incentive Vault balances are periodically topped up
                    </span>
                  </div>
                )}
            </span>
          )}
        />
        <div
          className={cx("flex items-center", {
            "animate-pulse !text-brand-1":
              selectedPair?.fee?.openFeeP === "0.0000000001",
          })}
        >
          {openingFeeWithIncentiveVault != null &&
            new BN(openingFeeWithIncentiveVault).eq(0) && (
              <>
                {/* <PresentSvg className="present-svg mr-[1.5px]" /> */}
                <ReactTooltip
                  className="gambit-react-tooltip"
                  anchorSelect=".present-tooltip-contents"
                  noArrow={true}
                  place="top"
                >
                  <span>We'll pay the FEES for you</span>
                </ReactTooltip>
              </>
            )}
          {openingFeeWithIncentiveVault != null &&
            new BN(openingFeeWithIncentiveVault).gt(0) &&
            networkFee === 0 &&
            delegationFeeThreshhold && (
              <>
                <PresentSvg className="present-svg mr-[1.5px]" />
                <ReactTooltip
                  className="gambit-react-tooltip"
                  anchorSelect=".present-svg"
                  noArrow={true}
                  place="top"
                >
                  <span>
                    <span className="font-bold">Network fees waived</span> for
                    positions exceeding $
                    {numberWithCommas(delegationFeeThreshhold)}!
                  </span>
                </ReactTooltip>
              </>
            )}
          <div
            className={cx(
              "font-space-grotesk text-gray-00 overflow-x-auto max-w-[21rem] whitespace-nowrap heading8 !leading-[16.59px]",
              {
                "!text-brand-1": selectedPair?.fee?.openFeeP === "0.0000000001",
              }
            )}
          >
            {openingFeeWithIncentiveVault != null ? (
              selectedPair?.fee?.openFeeP === "0.0000000001" ? ( // * contract에서 opening fee zero 세팅 컨디션
                t`FREE`
              ) : (
                <div
                  className={cx("flex", {
                    "present-tooltip-contents":
                      openingFee != null &&
                      !new BN(openingFee).eq(openingFeeWithIncentiveVault),
                  })}
                >
                  {openingFee != null &&
                    !new BN(openingFee).eq(openingFeeWithIncentiveVault) && (
                      <div>
                        <span className="line-through">{`${openingFee} ${fromToken.symbol}`}</span>
                        <span className="mx-[4px]">→</span>
                      </div>
                    )}
                  {new BN(openingFeeWithIncentiveVault).eq(0) ? (
                    <div className="flex items-center">
                      {/* <PresentSvg className="mr-[1.5px]" /> */}
                      <span className="text-brand-1 animate-pulse">FREE</span>
                    </div>
                  ) : (
                    `${openingFeeWithIncentiveVault} ${fromToken.symbol}`
                  )}
                </div>
              )
            ) : (
              ``
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center px-[1.6rem]">
        <Tooltip
          handle={
            <div className="text-gray-15 flex items-center">
              <Trans>LIQ. PRICE</Trans>
              <InfoCircleSvg className="fill-gray-30 ml-[2px]" />
            </div>
          }
          disableHandleStyle={true}
          position="left-top"
          tooltipClassName="w-[30rem]"
          renderContent={() => (
            <span>
              <Trans>
                If this price is reached, your position will be closed and your
                collateral lost.
              </Trans>
            </span>
          )}
        />

        {liqPriceValue !== undefined && (
          <div className="font-space-grotesk flex text-gray-00">
            {/* {["CNH", "JPY"].includes(selectedPair.to) ? "¥" : "$"} */}
            <span>$</span>
            {/* <AnimatedNumber to={+liqPriceValue} /> */}
            {liqPriceValue}
          </div>
        )}
      </div>
      {/* <div className="flex justify-between items-center px-[1.6rem] bg-black-1 py-[0.55rem] rounded-[4px]">
              <div className="text-gray-15 flex items-center">
                <Trans>GAS FEE</Trans>
              </div>
      
              <div className="flex items-center">
                {gasFee && (
                  <div className={cx("font-space-grotesk text-gray-15", { "line-through": isGasLess })}>
                    ${numberWithCommas(gasFee.toFixed(2))}
                  </div>
                )}
                {!isGasLess && !gasFee && (
                  <div className="font-space-grotesk text-gray-00">Can be calculated after at least 1 trade</div>
                )}
                {isGasLess && <div className="font-space-grotesk ml-[4px] text-brand-1 animate-pulse">FREE</div>}
              </div>
            </div> */}
    </div>
  );
};
