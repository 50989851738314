import { Trans } from "@lingui/macro";
import * as Dialog from "@radix-ui/react-dialog";
import { Web3AuthNoModal } from "@web3auth/no-modal";
import Token from "abis/Token.json";
import {
  currentWalletAccountTabAtom,
  showConfirmWithdrawAtom,
  showWalletAccountAtom,
  withdrawRecipientAddressAtom,
} from "atoms";
import { pairsAtom } from "atoms/exchange";
import BN from "bignumber.js";
import cx from "classnames";
import "components/Common/radix-ui-dialog.scss";
import SignInModalLayout from "components/SocialLogin/SignInModalLayout";
import { getChainNameForMenu } from "config/chains";
import { getContract } from "config/contracts";
import { SELECTED_WALLET_LOCALSTORAGE_KEY } from "config/localStorage";
import { getToken } from "config/tokens";
import { BigNumber, ethers } from "ethers";
import { useChainId } from "futures-lib/chains";
import { contractFetcher } from "futures-lib/contracts";
import { useGetContract } from "futures-lib/contracts/contract";
import { helperToast } from "futures-lib/helperToast";
import { getAccountUrl, importImage } from "futures-lib/legacy";
import { formatAmount, numberWithCommas } from "futures-lib/numbers";
import useWallet from "futures-lib/wallets/useWallet";
import InvalidImage from "img/ic-invalid.svg";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useCallback, useEffect, useMemo } from "react";
import { IoIosArrowBack } from "react-icons/io";
import QRCode from "react-qr-code";
import { useCopyToClipboard, useMedia } from "react-use";
import useSWR from "swr";
import { useAccount, useDisconnect, useEnsName } from "wagmi";

export const TAB_TYPES = {
  FUND: "FUND",
  WITHDRAW: "WITHDRAW",
} as const;
export type TabType = typeof TAB_TYPES[keyof typeof TAB_TYPES];

const WalletAccount = () => {
  const [showWalletAccount, setShowWalletAccount] = useAtom(
    showWalletAccountAtom
  );
  const { isActive, account, signer, isWeb3AuthAccount, web3authEmail } =
    useWallet();
  const { data: ensName } = useEnsName({ address: account });
  const { disconnect } = useDisconnect();
  const { chainId } = useChainId();
  const accountUrl = getAccountUrl(chainId, account);
  const [, copyToClipboard] = useCopyToClipboard();
  const xxsPlus = useMedia("(max-width: 398px)");
  const { connector } = useAccount();

  const fromTokenAddress = getContract(chainId, "CollateralToken");
  const fromToken = getToken(chainId, fromTokenAddress);

  const fromTokenImage = useMemo(
    () => importImage(`ic_${fromToken.symbol.toLowerCase()}_24.svg`),
    [fromToken.symbol]
  );

  const { data: collateralBalance } = useSWR<BigNumber>(
    isActive && [isActive, chainId, fromTokenAddress, "balanceOf", account],
    {
      fetcher: contractFetcher(signer, Token),
      shouldRetryOnError: true,
      errorRetryInterval: 2000,
      refreshInterval: 2000,
    }
  );

  const [currentTab, setCurrentTab] = useAtom(currentWalletAccountTabAtom);
  const [withdrawRecipientAddress, setWithdrawRecipientAddress] = useAtom(
    withdrawRecipientAddressAtom
  );

  useEffect(() => {
    if (!showWalletAccount) {
      setCurrentTab("");
      // setWithdrawRecipientAddress("");
    }
  }, [setCurrentTab, showWalletAccount]);

  const setShowConfirmWithdraw = useSetAtom(showConfirmWithdrawAtom);

  const copyAddress = useCallback(() => {
    if (!account) return;

    copyToClipboard(account);
    helperToast.success(
      <div className="toastify-body-only">
        <Trans>Address Copied</Trans>
      </div>,
      { autoClose: 5000 }
    );
  }, [account, copyToClipboard]);

  const tokenWithdrawalContract = useGetContract("TokenWithdrawal");

  useEffect(() => {
    if (!isActive) {
      setShowWalletAccount(false);
    }
  }, [isActive, setShowWalletAccount]);

  useSWR<BigNumber>(
    showWalletAccount &&
      isActive &&
      tokenWithdrawalContract && [
        isActive,
        chainId,
        fromTokenAddress,
        "allowance",
        account,
        tokenWithdrawalContract?.address,
      ],
    {
      fetcher: contractFetcher(signer, Token),
      refreshInterval: 5000,
      errorRetryInterval: 5000,
    }
  );

  const pairs = useAtomValue(pairsAtom);

  const delegationFee = useMemo(() => {
    if (!showWalletAccount) return;

    let delegationFee: string;
    const btcPair = pairs.find(
      (pair) => pair.from === "BTC" && pair.to === "USD"
    );
    if (btcPair) {
      delegationFee = btcPair.fee.oracleFee;
      return delegationFee;
    } else {
      return;
    }
  }, [pairs, showWalletAccount]);

  // const getPrivateKey = useCallback(async () => {
  //   if (!signer || !connector) return;

  //   // await signer.provider.request({ method: "eth_getEncryptionPublicKey", params: [account] });
  //   const result = (await (connector as Web3AuthConnector).getProvider()).request({
  //     method: "eth_private_key",
  //   });

  //   console.log(result);
  // }, [connector, signer]);

  // console.log(getPrivateKey());

  const amountToSend = useMemo(() => {
    if (!collateralBalance || !delegationFee) return;
    const balance = formatAmount(
      collateralBalance?.toNumber(),
      fromToken.decimals,
      2,
      false
    );

    return numberWithCommas(new BN(+balance - +delegationFee).toString());
  }, [collateralBalance, delegationFee, fromToken.decimals]);

  // const [withdrawValidationMessage, setWithdrawValidationMessage] = useState("Input recipient's wallet address");

  const getWithdrawValidationMessage = useCallback(() => {
    if (!withdrawRecipientAddress) {
      return "Input recipient's wallet address";
    }
    if (!ethers.utils.isAddress(withdrawRecipientAddress)) {
      return "Invalid recipient's wallet address";
    }

    if (amountToSend === undefined) {
      return "Invalid amount";
    }

    if (new BN(amountToSend).lte(0)) {
      return "Insufficient balance";
    }

    return ""; // * validation 통과
  }, [amountToSend, withdrawRecipientAddress]);

  // useEffect(() => {
  //   if (!withdrawRecipientAddress) {
  //     setWithdrawValidationMessage("Input recipient's wallet address");
  //     return;
  //   }
  //   if (!ethers.utils.isAddress(withdrawRecipientAddress)) {
  //     setWithdrawValidationMessage("Invalid recipient's wallet address");
  //     return;
  //   }

  //   if (amountToSend === undefined) {
  //     setWithdrawValidationMessage("Invalid recipient's wallet address");
  //     return;
  //   }

  //   if (new BN(amountToSend).lte(0)) {
  //     setWithdrawValidationMessage("Insufficient balance");
  //     return;
  //   }
  // }, [amountToSend, withdrawRecipientAddress]);

  return (
    <Dialog.Root open={showWalletAccount} onOpenChange={setShowWalletAccount}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <SignInModalLayout>
          <Dialog.Content
            className={cx(
              "font-space-grotesk",
              "w-full bottom-0",
              "z-[99] fixed  focus-visible:outline-none",
              "xxs+:w-[39.8rem] xxs+:!top-[6.8rem] xxs+:!bottom-auto xxs+:!right-auto xxs+:rounded-[1rem] xxs+:bg-gray-90",
              "xs-:!right-0",
              "mx-0 xs:mx-[1.5rem]"
            )}
          >
            <div className="flex w-full xxs+:w-[39.8rem] pt-[1.6rem] px-[2rem] pb-[2.4rem] flex-col gap-[2rem] items-center bg-gray-70 xxs+:bg-gray-90 xxs+:rounded-[1.2rem] rounded-t-[1.2rem] border-solid border border-[rgba(255,255,255,0.1)] mx-auto my-0">
              <div className="flex w-full justify-between items-center">
                <span className="flex items-center h-[2.6rem] xxs+:text-[2rem] text-[1.7rem] font-medium leading-[25.52px] text-white text-left">
                  {currentTab !== "" && (
                    <IoIosArrowBack
                      onClick={() => {
                        setCurrentTab("");
                      }}
                      className="xxs+:hidden text-gray-10 w-[2rem] h-[2rem] mr-[0.4rem]"
                    />
                  )}
                  <span>My Account</span>
                </span>
                <div
                  onClick={() => setShowWalletAccount(false)}
                  className="cursor-pointer w-[2.4rem] h-[2.4rem] bg-[url(img/ic-modal-close.png)] bg-cover overflow-hidden"
                />
              </div>

              {/*
               ** 조건: 모바일 && currentTab !== "" -> hidden
               */}
              <div
                className={cx("w-full flex flex-col gap-[2rem]", {
                  hidden: xxsPlus && currentTab !== "",
                })}
              >
                {/* Account Info & Balance Box */}
                <div
                  className={cx(
                    "flex w-full flex-col gap-[1.2rem] items-center"
                  )}
                >
                  <div className="flex flex-col gap-[8px] items-center self-stretch">
                    <div className="flex flex-col gap-[1.2rem] items-center self-stretch">
                      <div className="flex gap-[1.2rem] items-center self-stretch">
                        <div className="flex gap-[4px] items-center grow basis-0">
                          <div className="w-[1.8rem] h-[1.8rem] bg-[url(img/ic-gray-wallet.png)] bg-cover " />
                          <span className="flex items-center h-[1.7rem] text-[1.3rem] font-medium text-white text-left">
                            {ensName ||
                              `${account?.slice(0, 5)}...${account?.slice(-5)}`}
                          </span>
                        </div>
                        <div className="flex gap-[8px]">
                          <a
                            href={accountUrl}
                            target="_blank"
                            rel="ugc"
                            className="cursor-pointer group flex w-[7.1rem] h-[2.3rem] pt-[3px] px-[8px] pb-[3px] gap-[4px] justify-center items-center bg-white bg-opacity-5 hover:text-white hover:bg-opacity-10 rounded-[2px] "
                          >
                            <span className="flex items-center h-[1.7rem] text-[1.3rem] font-medium text-gray-20 group-hover:text-white">
                              About
                            </span>

                            <div className="w-[1.2rem] h-[1.2rem] bg-[url(img/ic-extenral-link.png)] bg-cover  " />
                          </a>
                          <button
                            onClick={copyAddress}
                            className="cursor-pointer group flex w-[49px] pt-[3px] px-[8px] pb-[3px] flex-col gap-[8px] bg-white bg-opacity-5 hover:text-white hover:bg-opacity-10 rounded-[2px] "
                          >
                            <span className="flex items-center h-[1.7rem] text-[1.3rem] font-medium text-gray-20 group-hover:text-white">
                              COPY
                            </span>
                          </button>
                          <button
                            onClick={() => {
                              if (isWeb3AuthAccount) {
                                (
                                  (connector as any)
                                    .web3AuthInstance as Web3AuthNoModal
                                ).logout();
                                connector?.disconnect();
                              }
                              disconnect();
                              localStorage.removeItem(
                                SELECTED_WALLET_LOCALSTORAGE_KEY
                              );
                              setShowWalletAccount(false);
                            }}
                            className="cursor-pointer group flex w-[6.6rem] pt-[3px] px-[8px] pb-[3px] flex-col gap-[8px] bg-white bg-opacity-5 hover:text-white hover:bg-opacity-10 rounded-[2px] "
                          >
                            <span className="h-[1.7rem] text-[1.3rem] font-medium text-gray-20 group-hover:text-white flex items-center">
                              LOGOUT
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                    {web3authEmail && (
                      <div className="flex gap-[1.2rem] justify-center items-center self-stretch ">
                        <div className="flex gap-[4px] justify-center items-center grow basis-0 ">
                          <div className="w-[1.8rem] h-[1.8rem] bg-[url(img/ic-person.png)] bg-cover" />
                          <span className="h-[1.7rem] grow text-[1.3rem] font-medium text-gray-30 flex items-center">
                            {web3authEmail}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="divide-y h-[1px] w-full bg-white bg-opacity-10" />
                  <div className="flex w-full pt-[1.6rem] pr-[1.6rem] pb-[1.8rem] pl-[1.6rem] flex-col gap-[1.6rem] bg-black-2 rounded-[1.2rem] ">
                    <div className="flex flex-col gap-[11px] self-stretch ">
                      <div className="flex justify-between items-center self-stretch ">
                        <span className="h-[1.7rem] text-[1.3rem] font-medium flex items-center text-gray-20 text-left ">
                          Balance
                        </span>
                      </div>
                      <div className="flex justify-between items-center self-stretch ">
                        <div className="flex w-[79px] gap-[1.2rem] items-center">
                          <div className="flex w-[79px] gap-[6px] items-center ">
                            <img
                              src={fromTokenImage}
                              alt="from token logo"
                              width="26"
                            />
                            <div className="flex w-[47px] gap-[4px] items-center ">
                              <span className="h-[25px] text-[1.8rem] font-medium flex items-center text-white text-left ">
                                {fromToken.symbol}
                              </span>
                            </div>
                          </div>
                        </div>
                        <span className="flex justify-end items-center text-[2.4rem] font-bold text-white text-right max-w-[20rem]">
                          {formatAmount(
                            collateralBalance,
                            fromToken.decimals,
                            2,
                            true
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Buy & Fund Tab & Withdraw Tab */}
                {isWeb3AuthAccount && (
                  <div
                    className={cx(
                      "cursor-pointer flex w-full flex-col gap-[1.2rem] items-center"
                    )}
                  >
                    {/* TODO: onramp 검증 */}
                    {/* <div className="flex w-full h-[4.6rem] pt-[8px] pr-[1.6rem] pb-[8px] pl-[1.6rem] flex-col gap-[1rem] justify-center items-center bg-white rounded-[1.2rem] ">
                  <div className="flex gap-[8px] items-center self-stretch ">
                    <div className="flex gap-[8px] justify-center items-center grow basis-0 ">
                      <span className="h-[2rem] text-[1.5rem] xxs+:text-[1.6rem] font-semibold leading-[2rem] text-gray-90 text-left ">
                        Buy {fromToken.symbol}
                      </span>
                    </div>
                  </div>
                </div> */}
                    <div className="flex w-full gap-[1.2rem] justify-center">
                      <div
                        onClick={() => {
                          if (currentTab !== TAB_TYPES.FUND) {
                            setCurrentTab(TAB_TYPES.FUND);
                          } else {
                            setCurrentTab("");
                          }
                        }}
                        className={cx(
                          "cursor-pointer flex w-[173px] h-[46px] pt-[8px] pr-[1.6rem] pb-[8px] pl-[1.6rem] flex-col gap-[10px] justify-center items-center border-solid border  border-gray-50 rounded-[1.2rem]",
                          "hover:border-0 xxs+:hover:bg-gray-80 hover:bg-gray-60",
                          currentTab === TAB_TYPES.FUND
                            ? "border-0 xxs+:bg-gray-80 bg-gray-60"
                            : ""
                        )}
                      >
                        <div className="flex gap-[8px] items-center self-stretch ">
                          <div className="flex gap-[8px] justify-center items-center grow basis-0 ">
                            <span className="h-[2rem] text-[1.5rem] xxs+:text-[1.6rem] font-semibold flex items-center text-white text-left ">
                              Fund
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          if (currentTab !== TAB_TYPES.WITHDRAW) {
                            setCurrentTab(TAB_TYPES.WITHDRAW);
                          } else {
                            setCurrentTab("");
                          }
                        }}
                        className={cx(
                          "cursor-pointer flex w-[173px] h-[46px] pt-[8px] pr-[1.6rem] pb-[8px] pl-[1.6rem] flex-col gap-[10px] justify-center items-center rounded-[1.2rem] border-solid border border-gray-50",
                          "hover:border-0 xxs+:hover:bg-gray-80 hover:bg-gray-60",
                          currentTab === TAB_TYPES.WITHDRAW
                            ? "border-0 xxs+:bg-gray-80 bg-gray-60"
                            : ""
                        )}
                      >
                        <div className="flex gap-[8px] items-center self-stretch ">
                          <div className="flex gap-[8px] justify-center items-center grow basis-0 ">
                            <span className="h-[2rem] text-[1.5rem] xxs+:text-[1.6rem] font-semibold flex items-center text-white text-left ">
                              Withdraw All
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {/*
               ** 조건: 모바일 -> hidden
               */}
              <div
                className={cx(
                  "divide-y h-[1px] w-full bg-white bg-opacity-10",
                  {
                    hidden: xxsPlus || currentTab === "",
                  }
                )}
              />

              {currentTab === TAB_TYPES.FUND && (
                <>
                  <div className="flex pt-[1.2rem] pr-[1.6rem] pb-[1.2rem] pl-[1.6rem] gap-[8px] items-center bg-gray-60 xxs+:bg-gray-80 rounded-[1.2rem]">
                    <span className="flex h-[51px] justify-start grow basis-0 text-[1.2rem] xxs+:text-[1.3rem] font-medium items-center text-white text-left z-50">
                      Please send {fromToken.symbol} to the address below from
                      your wallet or exchange. Only {fromToken.symbol} on the{" "}
                      {getChainNameForMenu(chainId)} Network is accepted.
                    </span>
                  </div>
                  <div className="w-[14.6rem] h-[14.6rem] border-solid border border-[rgba(255,255,255,0.08)] bg-white flex items-center justify-center rounded-[1.2rem]">
                    {account && <QrcodeWallet account={account} />}
                  </div>
                  <div className="flex flex-col gap-[2rem] xxs+:gap-[1.2rem] items-center ">
                    <div className="px-[3.75rem] break-all text-[1.5rem] xxs+:text-[1.4rem] font-medium text-center">
                      <span className="font-medium text-white text-center">
                        {account?.slice(0, 5)}
                      </span>
                      <span className="font-medium text-[rgba(255,255,255,0.56)] text-center">
                        {account?.slice(5, account.length - 5)}
                      </span>
                      <span className="font-medium text-white text-center">
                        {account?.slice(-5)}
                      </span>
                    </div>
                    <div
                      onClick={copyAddress}
                      className="hidden cursor-pointer xxs+:flex pt-[3px] pr-[8px] pb-[3px] pl-[8px] flex-col gap-[8px] bg-[rgba(255,255,255,0.1)] rounded-[2px] "
                    >
                      <div className="flex gap-[4px] justify-center items-center ">
                        <div className="w-[1.7rem] h-[1.7rem] bg-[url(img/ic-external-link.png)] bg-cover bg-no-repeat " />
                        <span className="h-[1.7rem] text-[1.3rem] font-medium flex items-center text-gray-05 text-left">
                          Copy Address
                        </span>
                      </div>
                    </div>
                    <div
                      onClick={copyAddress}
                      className="w-full cursor-pointer xxs+:hidden bg-white text-black-3 text-[1.5rem] font-bold py-[1.65rem] text-center rounded-[1.2rem]"
                    >
                      Copy Address
                    </div>
                  </div>
                </>
              )}
              {currentTab === TAB_TYPES.WITHDRAW && (
                <>
                  <div className="flex w-full flex-col gap-[1.2rem] items-center ">
                    <div className="flex w-full pt-[1.2rem] pr-[1.6rem] pb-[1.2rem] pl-[1.6rem] gap-[8px] items-center bg-gray-60 xxs+:bg-gray-80 rounded-[1.2rem] z-50">
                      <span className="flex w-[326px] justify-start grow basis-0 text-[1.3rem] font-medium items-center text-white text-left ">
                        Please enter your wallet address to receive{" "}
                        {fromToken.symbol}. Ensure it is an{" "}
                        {getChainNameForMenu(chainId)} network address.
                      </span>
                    </div>
                    <div className="flex w-full flex-col gap-[1.2rem] items-center ">
                      <div className="flex w-full h-[52px] pt-[1.6rem] pr-[1.6rem] pb-[1.8rem] pl-[1.6rem] flex-col gap-[8px] justify-center bg-black-2 rounded-[1.2rem] overflow-hidden ">
                        <span className="h-[1.7rem] self-stretch text-[1.3rem] xxs+:text-[1.4rem] font-medium flex items-center text-gray-40 text-left">
                          {/* <span className={cx({ hidden: hiddenRecipientInput })}>
                      Recipient’s {getChainNameForMenu(chainId)} Wallet Address
                    </span> */}
                          <input
                            spellCheck="false"
                            placeholder={`Recipient’s ${getChainNameForMenu(
                              chainId
                            )} Wallet Address`}
                            className="w-full text-white placeholder-gray-40"
                            onChange={(e) => {
                              setWithdrawRecipientAddress(e.target.value);
                            }}
                            value={withdrawRecipientAddress}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="w-full">
                    <button
                      disabled={Boolean(getWithdrawValidationMessage())}
                      className={cx(
                        "xxs+:h-[4.9rem] h-[5.2rem] cursor-pointer flex w-full pt-[14.5px] pr-[2.4rem] pb-[14.5px] pl-[2.4rem]  justify-center items-center bg-brand-1 rounded-[1.2rem]"
                      )}
                      onClick={() => {
                        setShowWalletAccount(false);
                        setShowConfirmWithdraw(true);
                      }}
                    >
                      <span className="h-[2rem] text-[1.5rem] xxs+:text-[1.6rem] font-bold flex items-center text-gray-90">
                        Withdraw All
                      </span>
                    </button>
                    {getWithdrawValidationMessage() && (
                      <div className="flex mt-[0.8rem]">
                        <img
                          className="mr-[4px] self-start mt-[2.5px]"
                          src={InvalidImage}
                          alt="invalid"
                        />
                        <span className="heading9 text-gray-15 tracking-normal">
                          {getWithdrawValidationMessage()}
                        </span>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </Dialog.Content>
        </SignInModalLayout>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default WalletAccount;

const QrcodeWallet = ({ account }: { account: string }) => {
  return (
    <QRCode
      value={account}
      size={256}
      style={{ height: "auto", maxWidth: "90%", width: "90%" }}
      viewBox={`0 0 256 256`}
    />
  );
};
