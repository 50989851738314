import { formatUnits } from "@ethersproject/units";
import { Trans, t } from "@lingui/macro";
import {
  isVisibleCloseTradeModalAtom,
  selectedCloseTradeAtom,
} from "atoms/exchange";
import BN from "bignumber.js";
import cx from "classnames";
import { GAMBIT_USD_DECIMALS } from "components/Exchange/constants";
import ModalSkeletonWithPortal from "components/Modal/ModalSkeletonWithPortal";
import { getContract } from "config/contracts";
import { getToken } from "config/tokens";
import dayjs from "dayjs";
import { trimPriceString, unpadZero } from "futures-domain/trades/utils";
import { useChainId } from "futures-lib/chains";
import { formatStringAmount, numberWithCommas } from "futures-lib/numbers";
import { ReactComponent as PresentSvg } from "img/ic-present.svg";
import { useAtom, useAtomValue } from "jotai";
import { useMemo } from "react";

export const CloseTradeModal = () => {
  const [isVisible, setIsVisible] = useAtom(isVisibleCloseTradeModalAtom);

  const { chainId } = useChainId();
  const fromTokenAddress = getContract(chainId, "CollateralToken");
  const fromToken = getToken(chainId, fromTokenAddress);

  const selectedCloseTrade = useAtomValue(selectedCloseTradeAtom);

  const longOrShort = useMemo(() => {
    if (isVisible) {
      return selectedCloseTrade?.trade?.buy ? t`LONG` : t`SHORT`;
    } else {
      return "None";
    }
  }, [isVisible, selectedCloseTrade?.trade?.buy]);

  // const pay = useMemo(() => {
  //   if (!selectedCloseTrade) return;

  //   if (selectedCloseTrade.trade.leverage === 20) {
  //   }

  //   if (selectedCloseTrade.includeNetworkFee) {
  //     return new BN(selectedCloseTrade.positionSizeUsdcString)
  //       .plus(selectedCloseTrade.openingFee)
  //       .plus(selectedCloseTrade.trade.pair.fee.oracleFee)
  //       .toFixed(2);
  //   }

  //   return new BN(selectedCloseTrade.positionSizeUsdcString).plus(selectedCloseTrade.openingFee).toFixed(2);
  // }, [selectedCloseTrade]);

  const doesExistPay = useMemo(() => {
    // * selectedCloseTrade.initialPosUsdcString가 0이면 false
    if (
      selectedCloseTrade?.initialPosUsdcString &&
      new BN(selectedCloseTrade?.initialPosUsdcString).gt(0)
    ) {
      return true;
    }

    return false;
  }, [selectedCloseTrade?.initialPosUsdcString]);

  const mountTimeOffset = useMemo(() => {
    if (selectedCloseTrade?.timestamp) {
      return dayjs(+selectedCloseTrade?.timestamp * 1000).diff(
        dayjs("2024-04-22") // * Incentive Vault 가동(zksync sepolia) 날짜
      );
    }
  }, [selectedCloseTrade?.timestamp]);

  return (
    <ModalSkeletonWithPortal isVisible={isVisible} setIsVisible={setIsVisible}>
      {selectedCloseTrade && (
        <div className="min-w-[30rem]">
          {/* Title */}
          <div className="text-center mt-[3.8rem]">
            <div className="font-space-grotesk text-[2.4rem] font-bold">
              {selectedCloseTrade?.trade?.pair.from}/
              {selectedCloseTrade?.trade?.pair.to}
            </div>
            <div
              className={cx(
                "text-[1.6rem] text-gray-15 font-medium",
                longOrShort === t`LONG` ? "text-green-2" : "text-red-2"
              )}
            >
              {longOrShort}
            </div>
          </div>

          <hr className="h-px mt-[1.25rem] mb-[2.25rem] bg-gray-70 border-0" />

          {/* Content */}
          <ul className="px-[2.4rem] pb-[2.4rem] text-[1.4rem] space-y-[1.6rem] overflow-y-auto">
            {/* <li className="flex justify-between">
              <span className="text-gray-15">
                <Trans id="msg.tradeBox / Pay">Pay</Trans>
              </span>
              <span className="font-space-grotesk font-medium">
                {pay} {fromToken.symbol}
              </span>
            </li> */}
            {/* <li className="flex justify-between">
              <span className="flex items-center text-gray-15">
                <Trans>Opening Fee</Trans>
                {selectedCloseTrade.includeNetworkFee && (
                  <InfoCircleSvg className="opening-fee-tooltip fill-gray-30 ml-[2px]" />
                )}
                <ReactTooltip
                  className="gambit-react-tooltip"
                  anchorSelect=".opening-fee-tooltip"
                  noArrow={true}
                  place="top-end"
                >
                  <span className="text-[1.4rem] text-white font-medium">
                    Opening Fee includes network fee(${selectedCloseTrade.trade.pair.fee.oracleFee}).
                  </span>
                </ReactTooltip>
              </span>
              <span className="font-space-grotesk font-medium">
                {selectedCloseTrade.openingFee} {fromToken.symbol}
              </span>
            </li> */}
            {doesExistPay && (
              <li className="flex justify-between">
                <span className="text-gray-15">Pay</span>
                <span className="font-space-grotesk font-medium">
                  {numberWithCommas(
                    (+selectedCloseTrade.initialPosUsdcString).toFixed(2)
                  )}{" "}
                  {fromToken.symbol}
                </span>
              </li>
            )}
            {/*
             * 과거 Trade Data는 서브그래프에서 openFeeAndNetworkFee를 읽을 수 없었음(리턴 0)
             * 과거 데이터에 대해서는 Open Fee + Network Fee를 보여주지 않는 목적
             *
             * Incentive Vault가 추가되면서 openFeeAndNetworkFee가 0인 케이스가 추가되었음
             * 이 때는 과거 openFeeAndNetworkFee 0인 경우와 달리 Open Fee + Network Fee를 보여주고 무료라고 표기해야함
             * 그래서 Incentive Vault 가동 날짜(mountTimeOffset) 기준 그 이후 Trade 데이터에 대해서는 openFeeAndNetworkFee가 0이어도 보여주고 FREE 표기
             */}
            {(new BN(selectedCloseTrade.openFeeAndNetworkFee).gt(0) ||
              (mountTimeOffset != null && mountTimeOffset >= 0)) && (
              <li className="flex justify-between">
                <span className="flex items-center text-gray-15">
                  <Trans>Open Fee + Network Fee</Trans>
                  {/* <InfoCircleSvg className="fill-gray-30 ml-[2px]" /> */}
                </span>
                <span
                  className={cx("font-space-grotesk font-medium text-nowrap", {
                    "text-red-2": new BN(
                      selectedCloseTrade.openFeeAndNetworkFee
                    ).gt(0),
                  })}
                >
                  {new BN(selectedCloseTrade.openFeeAndNetworkFee).gt(0) ? (
                    `${selectedCloseTrade.openFeeAndNetworkFee} ${fromToken.symbol}`
                  ) : (
                    <div className="flex items-center">
                      <PresentSvg className="present-svg mr-[1.5px]" />
                      <span className="text-brand-1 animate-pulse">FREE</span>
                    </div>
                  )}
                </span>
              </li>
            )}
            <li className="flex justify-between">
              <span className="text-gray-15">
                <Trans>Collateral</Trans>
              </span>
              <span className="font-space-grotesk font-medium">
                {numberWithCommas(
                  (+selectedCloseTrade.positionSizeUsdcString).toFixed(2)
                )}{" "}
                {fromToken.symbol}
              </span>
            </li>
            <li className="flex justify-between">
              <span className="text-gray-15">
                <Trans>Leverage</Trans>
              </span>
              <span className="font-space-grotesk font-medium">
                {unpadZero(selectedCloseTrade.trade.leverage.toFixed(2))}x
              </span>
            </li>
            <li className="flex justify-between">
              <span className="text-gray-15">Open Price</span>
              <div className="font-space-grotesk font-medium">
                {selectedCloseTrade.reason !== "MARKET_OPEN_CANCELED" && (
                  <div>
                    $
                    {trimPriceString(
                      formatUnits(
                        selectedCloseTrade.trade.openPrice,
                        GAMBIT_USD_DECIMALS
                      ),
                      true
                    )}
                  </div>
                )}
              </div>
            </li>
            <li className="flex justify-between">
              <span className="text-gray-15">Close Price</span>
              <div className="font-space-grotesk font-medium">
                {selectedCloseTrade.reason !== "MARKET_OPEN_CANCELED" && (
                  <div>
                    $
                    {trimPriceString(
                      formatUnits(
                        selectedCloseTrade.closePrice,
                        GAMBIT_USD_DECIMALS
                      ),
                      true
                    )}
                  </div>
                )}
              </div>
            </li>
            <li className="flex justify-between">
              <span className="text-gray-15">Reason</span>
              <div className="font-space-grotesk font-medium">
                {selectedCloseTrade.reason}
              </div>
            </li>
            <li className="flex justify-between">
              <span className="text-gray-15">
                <Trans>Profit</Trans>
              </span>
              <div
                className={cx(
                  "font-space-grotesk font-medium",
                  selectedCloseTrade.profitValue.includes("-")
                    ? "text-red-2"
                    : "text-green-2"
                )}
              >
                <span>
                  {selectedCloseTrade.profitValue} {fromToken.symbol}{" "}
                </span>
                <span>
                  ({formatStringAmount(selectedCloseTrade.profitPercent, 2)}%)
                </span>
              </div>
            </li>

            <li className="flex justify-between">
              {/* <Tooltip
                handle={
                  <span className="flex items-center text-gray-15">
                    <Trans>Close Fee + Funding Fee</Trans>
                    <InfoCircleSvg className="fill-gray-30 ml-[2px]" />
                  </span>
                }
                disableHandleStyle={true}
                position="left-top"
                tooltipClassName="w-[26rem] sm:w-[31rem]"
                renderContent={() => (
                  <span className="text-[1.4rem] text-white font-medium">
                    <Trans>Fee includes closing fee and funding fee.</Trans>
                  </span>
                )}
              /> */}
              <span className="flex items-center text-gray-15">
                <Trans>Close Fee + Funding Fee</Trans>
                {/* <InfoCircleSvg className="fill-gray-30 ml-[2px]" /> */}
              </span>
              <span className="font-space-grotesk font-medium text-red-2 text-nowrap">
                {selectedCloseTrade.closeFeeAndFundingFee} {fromToken.symbol}
              </span>
            </li>
            <div className="h-[1px] w-full bg-gray-70" />
            <li className="flex justify-between font-bold">
              <span className="text-gray-15">
                <Trans id="msg.CloseTradeModal Net PnL">Net PnL</Trans>
              </span>
              <span
                className={cx(
                  "font-space-grotesk",
                  selectedCloseTrade.netPnL.includes("-")
                    ? "text-red-2"
                    : "text-green-2"
                )}
              >
                {formatStringAmount(selectedCloseTrade.netPnL, 2, true)}{" "}
                {fromToken.symbol}
              </span>
            </li>
          </ul>
        </div>
      )}
    </ModalSkeletonWithPortal>
  );
};
