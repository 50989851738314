import { animated, useSpring } from "@react-spring/web";
import BN from "bignumber.js";
import cx from "classnames";
import { trimPriceString } from "futures-domain/trades/utils";
import { numberWithCommas } from "futures-lib/numbers";
import { ReactNode, useCallback, useMemo } from "react";
import { usePrevious } from "react-use";

export const COLOR_MODES = {
  PNL: "pnl", // * 0보다 크면 초록색, 0보다 작으면 빨간색
  INCREASED: "increased", // * 이전 값보다 커졌으면 초록색, 작아졌으면 빨간색
} as const;

type ColorMode = typeof COLOR_MODES[keyof typeof COLOR_MODES];

export function AnimatedNumber({
  to,
  comma = true,
  trim = true,
  colorMode,
  decimals,
  precedingContents,
  followingContents,
}: {
  to: number;
  comma?: boolean;
  trim?: boolean;
  colorMode?: ColorMode | undefined;
  decimals?: number;
  precedingContents?: ReactNode;
  followingContents?: ReactNode;
}) {
  const from = usePrevious(to);

  const _from = useMemo(() => {
    if (!from) return from ? +from : 0;

    return +from;
  }, [from]);

  const _decimals = useMemo(() => {
    if (decimals === undefined && to < 0) {
      return 2;
    }

    return decimals;
  }, [decimals, to]);

  const isIncreased = useMemo(() => {
    if (!from || !to) return false;

    return new BN(to).gt(from);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [to]);

  const { number } = useSpring({ number: +to, from: { number: _from } });

  const parsedNumber = useCallback(
    (number: number) => {
      let result = number.toString();
      // let result0, result1, result2, result3;
      // result0 = result;

      if (trim) {
        result = trimPriceString(result);
        // result1 = result;
      }

      if (_decimals !== undefined) {
        result = (+result).toFixed(_decimals);
        // result2 = result;
      }

      if (comma) {
        result = numberWithCommas(result);
        // result3 = result;
      }

      return result;
    },
    [trim, _decimals, comma]
  );

  const colorModeStyle = useCallback(() => {
    if (!colorMode) return;

    if (colorMode === COLOR_MODES.PNL) {
      if (+to === 0) return "text-white";

      return +to > 0 ? "text-green-2" : "text-red-2";
    } else if (colorMode === COLOR_MODES.INCREASED) {
      return isIncreased ? "text-green-2" : "text-red-2";
    }

    return;
  }, [colorMode, isIncreased, to]);

  return (
    <div className={cx("flex items-center", colorModeStyle())}>
      {precedingContents}
      <animated.div>{number.to(parsedNumber)}</animated.div>
      {followingContents}
    </div>
  );
}
