import {
  AnimatePresence as FramerAnimatePresence,
  motion,
} from "framer-motion";
import React, { ReactNode, memo, useEffect, useMemo, useState } from "react";
import { RiMenuLine } from "react-icons/ri";
import { AppHeaderLinks } from "./AppHeaderLinks";
import { AppHeaderUser } from "./AppHeaderUser";
// import GambitLogoImage from "img/ic-gambit_logo.svg";
import GambitLogo from "img/logo-mobile.svg";
// import { ReactComponent as GambitLogoWtihText } from "img/logo-desktop.svg";
import GambitLogoWtihText from "img/logo-desktop.svg";

import { isDrawerVisibleAtom } from "atoms";
import { useAtom } from "jotai";
import { Link } from "react-router-dom";
import { useWindowSize } from "react-use";
import "./Header.css";

// INFO: 304는 drawer의 width size
const DRAWER_WIDTH = 304;

// Fix framer-motion old React FC type (solved in react 18)
const AnimatePresence = (
  props: React.ComponentProps<typeof FramerAnimatePresence> & {
    children: ReactNode;
  }
) => <FramerAnimatePresence {...props} />;

const fadeVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const Header = () => {
  const [isDrawerVisible, setIsDrawerVisible] = useAtom(isDrawerVisibleAtom);
  const [isNativeSelectorModalVisible, setIsNativeSelectorModalVisible] =
    useState(false);

  const { width } = useWindowSize();
  const value = useMemo(() => {
    return width - DRAWER_WIDTH;
  }, [width]);

  const slideVariants = useMemo(() => {
    return {
      hidden: { x: width },
      visible: { x: value },
    };
  }, [value, width]);

  useEffect(() => {
    if (isDrawerVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isDrawerVisible]);

  return (
    <>
      {isDrawerVisible && (
        <AnimatePresence>
          {isDrawerVisible && (
            <motion.div
              className="App-header-backdrop"
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={fadeVariants}
              transition={{ duration: 0.2 }}
              onClick={() => setIsDrawerVisible(!isDrawerVisible)}
            ></motion.div>
          )}
        </AnimatePresence>
      )}
      {isNativeSelectorModalVisible && (
        <AnimatePresence>
          {isNativeSelectorModalVisible && (
            <motion.div
              className="selector-backdrop"
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={fadeVariants}
              transition={{ duration: 0.2 }}
              onClick={() =>
                setIsNativeSelectorModalVisible(!isNativeSelectorModalVisible)
              }
            ></motion.div>
          )}
        </AnimatePresence>
      )}
      <header>
        {/* .App-header.large */}
        <div className="flex max-w-[155rem] mx-auto h-[6.2rem] relative justify-between items-center bg-[#0F1018] px-[1.6rem] headerScreen:px-[1.5rem] headerScreen:pt-[1.55rem] headerScreen:bg-transparent headerScreen:h-[6.7rem] App-header-after">
          <div className="flex">
            <Link
              className="flex flex-none items-center mr-[1rem] xs:mr-[3.4rem]"
              to="/"
            >
              {/* <img className="w-[2.5rem] h-[2.5rem] mr-[4px]" src={GambitLogoImage} alt="GAMBIT Logo" />
              <span className="text-[1.8rem] text-brand-1 font-bold">GAMBIT</span> */}

              {/* <GambitLogoWtihText /> */}
              <img
                className="hidden md:block min-w-[15.8rem]"
                src={GambitLogoWtihText}
                alt="gambit logo"
              />
              <img
                className="block md:hidden"
                src={GambitLogo}
                alt="gambit logo"
              />
            </Link>
            <div className="hidden headerScreen:block shrink">
              <AppHeaderLinks />
            </div>
          </div>
          <div className="App-header-container-right">
            <AppHeaderUser />
            <div
              className="cursor-pointer ml-[1.2rem] block headerScreen:hidden"
              onClick={() => setIsDrawerVisible(!isDrawerVisible)}
            >
              <RiMenuLine className="text-[2.4rem]" />
            </div>
          </div>
        </div>
      </header>
      <AnimatePresence>
        {isDrawerVisible && (
          <motion.div
            onClick={() => setIsDrawerVisible(false)}
            className="App-header-drawer bg-[url('img/bg-mobile_menu.jpg')] bg-cover"
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={slideVariants}
            transition={{ duration: 0.2 }}
          >
            <AppHeaderLinks
              small
              clickCloseIcon={() => setIsDrawerVisible(false)}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default memo(Header);
