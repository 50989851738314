import { Signer, ethers } from "ethers";
import Token from "abis/Token.json";
import { WEMIX_TESTNET, getConstant, getExplorerUrl } from "config/chains";
import { helperToast } from "futures-lib/helperToast";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { t, Trans } from "@lingui/macro";
import { getToken } from "config/tokens";
import { parseUnits } from "@ethersproject/units";

type Params = {
  setIsApproving: (val: boolean) => void;
  signer: Signer;
  tokenAddress: string;
  spender: string;
  chainId: number;
  onApproveSubmitted: () => void;
  pendingTxns: any[];
  setPendingTxns: (txns: any[]) => void;
  includeMessage?: boolean;
  successMsg?: string;
};

export function approveToken({
  setIsApproving,
  signer,
  tokenAddress,
  spender,
  chainId,
  onApproveSubmitted,
  pendingTxns,
  setPendingTxns,
  includeMessage,
  successMsg,
}: Params) {
  setIsApproving(true);

  const contract = new ethers.Contract(tokenAddress, Token.abi, signer);

  const txOptions = [WEMIX_TESTNET].includes(chainId)
    ? {
        maxPriorityFeePerGas: parseUnits("100", "gwei"),
        maxFeePerGas: parseUnits("101", "gwei"),
      }
    : null;

  contract
    .approve(spender, ethers.constants.MaxUint256, txOptions)
    .then(async (res) => {
      const txUrl = getExplorerUrl(chainId) + "tx/" + res.hash;
      helperToast.success(
        <div>
          <div className="toastify-body-only">
            <Trans>{successMsg ? successMsg : `Approval Submitted`}</Trans>
          </div>
          <ExternalLink href={txUrl}>
            <Trans>View Status</Trans>
          </ExternalLink>
        </div>,
        {
          autoClose: 7000,
        }
      );
      if (onApproveSubmitted) {
        onApproveSubmitted();
      }
      if (tokenAddress && pendingTxns && setPendingTxns) {
        const token = getToken(chainId, tokenAddress);
        const pendingTxn = {
          hash: res.hash,
          message: includeMessage ? t`${token.symbol} Approved` : false,
        };
        setPendingTxns([...pendingTxns, pendingTxn]);
      }
    })
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e);
      let failMsg;
      if (
        ["not enough funds for gas", "failed to execute call with revert code InsufficientGasFunds"].includes(
          e.data?.message
        )
      ) {
        failMsg = (
          <div>
            <Trans>
              {/* There is not enough ETH in your account on Arbitrum to send this transaction. */}
              There is not enough {getConstant(chainId, "nativeTokenSymbol")} in your account to send this transaction.
            </Trans>
          </div>
        );
      } else if (e.message?.includes("User denied transaction signature")) {
        failMsg = t`Approval Was Cancelled`;
      } else {
        failMsg = t`Approval Failed`;
      }
      helperToast.error(<div className="toastify-body-only">{failMsg}</div>, {
        autoClose: 5000,
      });
    })
    .finally(() => {
      setIsApproving(false);
    });
}

export function revokeToken({
  setIsApproving,
  signer,
  tokenAddress,
  spender,
  chainId,
  onApproveSubmitted,
  pendingTxns,
  setPendingTxns,
  includeMessage,
}: Params) {
  setIsApproving(true);

  const contract = new ethers.Contract(tokenAddress, Token.abi, signer);

  const txOptions = [WEMIX_TESTNET].includes(chainId)
    ? {
        maxPriorityFeePerGas: parseUnits("100", "gwei"),
        maxFeePerGas: parseUnits("101", "gwei"),
      }
    : null;

  contract
    .approve(spender, ethers.constants.Zero, txOptions)
    .then(async (res) => {
      const txUrl = getExplorerUrl(chainId) + "tx/" + res.hash;
      helperToast.success(
        <div>
          <div className="toastify-body-only">
            <Trans>Approval Submitted</Trans>
          </div>
          <ExternalLink href={txUrl}>
            <Trans>View Status</Trans>
          </ExternalLink>
        </div>,
        {
          autoClose: 7000,
        }
      );
      if (onApproveSubmitted) {
        onApproveSubmitted();
      }
      if (tokenAddress && pendingTxns && setPendingTxns) {
        const token = getToken(chainId, tokenAddress);
        const pendingTxn = {
          hash: res.hash,
          message: includeMessage ? t`${token.symbol} Approved` : false,
        };
        setPendingTxns([...pendingTxns, pendingTxn]);
      }
    })
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e);
      let failMsg;
      if (
        ["not enough funds for gas", "failed to execute call with revert code InsufficientGasFunds"].includes(
          e.data?.message
        )
      ) {
        failMsg = (
          <div>
            <Trans>
              {/* There is not enough ETH in your account on Arbitrum to send this transaction. */}
              There is not enough {getConstant(chainId, "nativeTokenSymbol")} in your account to send this transaction.
            </Trans>
          </div>
        );
      } else if (e.message?.includes("User denied transaction signature")) {
        failMsg = t`Approval was cancelled`;
      } else {
        failMsg = t`Approval failed`;
      }
      helperToast.error(<div className="toastify-body-only">{failMsg}</div>, {
        autoClose: 5000,
      });
    })
    .finally(() => {
      setIsApproving(false);
    });
}
