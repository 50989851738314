import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { TabType } from "components/Wallet/WalletAccount";
import { LANGUAGE_LOCALSTORAGE_KEY } from "config/localStorage";
import dayjs from "dayjs";
import { AccountVault, CommonVault } from "futures-domain/vault/types";
import { defaultLocale } from "futures-lib/i18n";
import { atom } from "jotai";
import { Connector } from "wagmi";
import {
  closeTradesAtom,
  closingOrdersAtom,
  closingTradesAtom,
  openApiOrdersAtom,
  openContractOrdersAtom,
  openTradesAtom,
  waitingOpenTradesAtom,
} from "./exchange";
import { referralAtom } from "./referral";
import {
  accountVaultAtom,
  commonVaultAtom,
  existingRequestsAtom,
  traderDepositedAtom,
} from "./vault";

export const isDrawerVisibleAtom = atom(false);

export const termsAgreementModalVisibleAtom = atom(false);
export const apolloClientAtom =
  atom<ApolloClient<NormalizedCacheObject> | null>(null);
export const connectedSubgraphUrlAtom = atom<string | null>(null);

// chainId, account, isActive 변경 시
export const resetAtom = atom(null, (_get, set) => {
  set(openTradesAtom, null);
  set(openContractOrdersAtom, null);
  set(openApiOrdersAtom, null);
  set(closeTradesAtom, null);
  set(closingTradesAtom, null);
  set(closingOrdersAtom, null);
  set(waitingOpenTradesAtom, null);
  set(traderDepositedAtom, null);

  set(commonVaultAtom, {} as CommonVault);
  set(accountVaultAtom, {} as AccountVault);
  set(existingRequestsAtom, null);

  set(referralAtom, null);
});

const _currentLanguageAtom = atom(
  localStorage.getItem(LANGUAGE_LOCALSTORAGE_KEY) ?? defaultLocale
);

export const currentLanguageAtom = atom(
  (get) => get(_currentLanguageAtom),
  (get, set, newLanguage: string) => {
    set(_currentLanguageAtom, newLanguage);
    localStorage.setItem("language", newLanguage);
  }
);

export const subgraphConnectionErrorAtom = atom(false);

export const currentBlockNumberAtom = atom<number | null>(null);
export const connectedSubgraphBlockNumberAtom = atom<number | null>(null);

export const web3authAtom = atom(null);
export const currentTimeAtom = atom<dayjs.Dayjs | null>(null);

export const showSignInModalAtom = atom(false);
export const showWalletAccountAtom = atom(false);
export const showConfirmWithdrawAtom = atom(false);
export const showEmailAccountModalAtom = atom(false);
export const emailAccountInputAtom = atom("");
export const currentWalletAccountTabAtom = atom<TabType | "">("");
export const withdrawRecipientAddressAtom = atom("");
export const selectedWeb3authConnectorAtom = atom<
  Connector<any, any> | null | undefined
>(null);

export const documentVisibleAtom = atom(true);
