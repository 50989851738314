import { formatUnits } from "@ethersproject/units";
import { currentTimeAtom } from "atoms";
import {
  midnightPairsPricesAtom,
  pairsPricesAtom,
  selectedPairAtom,
  selectedPairPriceChangeAtom,
} from "atoms/exchange";
import BN from "bignumber.js";
import cx from "classnames";
import { AnimatedNumber, COLOR_MODES } from "components/AnimatedNumber";
import { BigNumber } from "ethers";
import { Pair } from "futures-domain/trades/types";
import { trimPriceString } from "futures-domain/trades/utils";
import { importTokenImage } from "futures-lib/legacy";
import { useAtomValue, useSetAtom } from "jotai";
import { findKey } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import { WHITE_BACKGROUND_PAIRS } from "./ChartTokenSelectorV3";
import { FOREX_CLOSED_DECISION_TIME } from "./constants";

const PairPrice = ({ pair, slickPlay }: { pair: Pair; slickPlay?: any }) => {
  const pairsPrices = useAtomValue(pairsPricesAtom);
  const setSelectedPair = useSetAtom(selectedPairAtom);

  const [pairPrice, setPairPrice] = useState<string>("");
  // const [initialized, setInitialized] = useState(false);

  // const possiblePriceUpdate = useMemo(() => {
  //   if (pairRowIndex !== undefined) {
  //     return changeState === "afterChange" && currentSlide === pairRowIndex;
  //   }
  //   return false;
  // }, [changeState, currentSlide, pairRowIndex]);

  const pairPriceInfo = useMemo(() => {
    try {
      return pairsPrices[pair.priceFeedId];
    } catch (e) {
      return;
    }
  }, [pair.priceFeedId, pairsPrices]);

  useEffect(() => {
    try {
      const { price, expo } = pairsPrices[pair.priceFeedId];
      const result = formatUnits(BigNumber.from(price), Math.abs(expo));

      setPairPrice(trimPriceString(result));
    } catch (e) {}
  }, [pair.priceFeedId, pairsPrices, pair.from, pair.to]);

  // const [midnightPriceData, setMidnightPriceData] = useState<any>();

  // const [utcMidnightTimestamp, setUtcMidnightTimestamp] = useState<number>();

  // const [active, setActive] = useState(false);

  // useEffect(() => {
  //   getUtcMidnightTimestamp();
  // }, []);

  // useEffect(() => {
  //   if (rowIndex !== undefined) {
  //     setTimeout(() => {
  //       setActive(true);
  //     }, pollInterval * rowIndex);
  //   }
  // }, [rowIndex]);

  // const { data, isLoading } = useSWR(
  //   utcMidnightTimestamp && active && isPythMainnet
  //     ? `${publicPriceEndpoint}/get_price_feed?id=${pair.priceFeedId}&publish_time=${utcMidnightTimestamp}`
  //     : null,
  //   {
  //     fetcher: swrFetcher,
  //     revalidateIfStale: false,
  //     revalidateOnFocus: false,
  //     revalidateOnReconnect: false,
  //     refreshInterval: 0,
  //   }
  // );

  // useEffect(() => {
  //   if (data && !isLoading) {
  //     setMidnightPriceData(data);
  //   }
  // }, [data, isLoading]);

  // const getUtcMidnightTimestamp = async () => {
  //   const d = new Date();
  //   const year = d.getUTCFullYear();
  //   const month = d.getUTCMonth();
  //   const date = d.getUTCDate();

  //   const utcMidnightTimestamp = new Date(Date.UTC(year, month, date, 0, 0, 0)).getTime() / 1000;

  //   setUtcMidnightTimestamp(utcMidnightTimestamp);
  // };

  // useEffect(() => {
  //   if (pair.from === "BTC" && pair.to === "USD") {
  //     console.log("----------------3-----------------");
  //     console.log(`pairRowIndex:     ${pairRowIndex}`);
  //     console.log(`currentSlide: ${currentSlide}`);
  //     console.log(`nextSlide:    ${nextSlide}`);
  //     console.log(`changeState:  ${changeState}`);
  //     console.log("----------------3-----------------");
  //   }
  // }, [changeState, currentSlide, nextSlide, pair.from, pair.to, pairRowIndex]);

  const midnightPairsPrices = useAtomValue(midnightPairsPricesAtom);

  const midnightPrice = useMemo(() => {
    if (!midnightPairsPrices) return;

    // * 네트워크마다 pairIndex가 달라서 pairIndex로 페어를 찾지 않고 이름으로 찾음
    const key = findKey(
      midnightPairsPrices,
      (midnightPairsPrice) =>
        midnightPairsPrice.name === `${pair.from}/${pair.to}`
    );
    if (!key) return;

    const { price, expo } = midnightPairsPrices[key].price;
    return formatUnits(BigNumber.from(price), Math.abs(expo));
  }, [midnightPairsPrices, pair.from, pair.to]);

  const [priceChange, setPriceChange] = useState<string>();
  const selectedPair = useAtomValue(selectedPairAtom);
  const setSelectedPairPriceChange = useSetAtom(selectedPairPriceChangeAtom);

  const isSelectedPair = useMemo(() => {
    return pair.pairIndex === selectedPair?.pairIndex;
  }, [pair.pairIndex, selectedPair?.pairIndex]);

  // const priceChange = useMemo(() => {
  //   if (!midnightPrice || !pairPrice) return;

  //   const result = new BN(pairPrice).minus(midnightPrice).dividedBy(midnightPrice).multipliedBy(100).toFixed(2);

  //   if (new BN(result).eq(0)) return "0.00";

  //   return result;
  // }, [midnightPrice, pairPrice]);

  useEffect(() => {
    if (!midnightPrice || !pairPrice) return;

    const result = new BN(pairPrice)
      .minus(midnightPrice)
      .dividedBy(midnightPrice)
      .multipliedBy(100)
      .toFixed(2);

    if (new BN(result).eq(0)) {
      setPriceChange("0.00");
      if (isSelectedPair) {
        setSelectedPairPriceChange("0.00");
      }
    }

    setPriceChange(result);
    if (isSelectedPair) {
      setSelectedPairPriceChange(result);
    }
  }, [midnightPrice, pairPrice, isSelectedPair, setSelectedPairPriceChange]);

  const isPriceChangePlus = useMemo(() => {
    if (!priceChange) return "";

    if (new BN(priceChange).gt(0)) return "+";

    return "";
  }, [priceChange]);

  const toIsNotUsd = useMemo(() => {
    return pair.to !== "USD";
  }, [pair]);

  const currentTime = useAtomValue(currentTimeAtom);

  const forexClosed = useMemo(() => {
    if (pair.groupIndex !== 1 || !pairPriceInfo?.publish_time) return false;
    // console.log(currentTime?.unix());
    // console.log(pairPriceInfo?.publish_time);

    const _currentTime = currentTime?.unix();
    const publish_time = pairPriceInfo?.publish_time;

    if (!currentTime || !publish_time) return false;

    if (
      _currentTime &&
      Math.abs(_currentTime - publish_time) > FOREX_CLOSED_DECISION_TIME
    ) {
      return true;
    }

    return false;
  }, [currentTime, pair.groupIndex, pairPriceInfo]);

  return pairPrice ? (
    <div
      className={cx("flex items-center cursor-pointer", {
        "hover:opacity-50": !isMobile,
      })}
      onClick={() => {
        setSelectedPair(pair);
        if (slickPlay) {
          slickPlay();
        }
      }}
    >
      <div className="">
        {pair.groupIndex === 0 &&
          !toIsNotUsd &&
          WHITE_BACKGROUND_PAIRS.includes(
            `${pair.from.toUpperCase()}/${pair.to.toUpperCase()}`
          ) && (
            <div className="flex items-center justify-center w-[2.4rem] h-[2.4rem] xs-:w-[2rem] xs-:h-[2rem] bg-white rounded-full">
              <img
                className="w-[1.6rem] h-[1.6rem] xs-:w-[1.4rem] xs-:h-[1.4rem]"
                src={importTokenImage(pair.from.toLocaleLowerCase(), 24)}
                alt="selected token"
              />
            </div>
          )}

        {pair.groupIndex === 0 &&
          !toIsNotUsd &&
          !WHITE_BACKGROUND_PAIRS.includes(
            `${pair.from.toUpperCase()}/${pair.to.toUpperCase()}`
          ) && (
            <img
              className={cx(
                "xs-:min-w-[1.8rem] xs-:min-h-[1.8rem] xs-:max-w-[1.8rem] xs-:max-h-[1.8rem] bg-white rounded-full",
                "min-w-[2.4rem] min-h-[2.4rem] max-w-[2.4rem] max-h-[2.4rem] bg-white rounded-full"
              )}
              src={importTokenImage(pair.from.toLocaleLowerCase(), 24)}
              alt="selected token"
            />
          )}

        {(pair.groupIndex === 1 || toIsNotUsd) && (
          <div className="flex mr-[1px]">
            <img
              className={cx(
                "xs-:min-w-[1.8rem] xs-:min-[1.8rem] xs-:max-w-[1.8rem] xs-:max-h-[1.8rem] bg-white rounded-full",
                "min-w-[2.4rem] min-[2.4rem] max-w-[2.4rem] max-h-[2.4rem]"
              )}
              src={importTokenImage(pair.from.toLocaleLowerCase(), 24)}
              alt="selected token"
            />
            <img
              className={cx(
                "min-w-[1.6rem] min-[1.6rem] max-w-[1.6rem] max-h-[1.6rem] bg-white rounded-full ml-[-10px] border border-black-1",
                "min-w-[2rem] min-[2rem] max-w-[2rem] max-h-[2rem]"
              )}
              src={importTokenImage(pair.to.toLocaleLowerCase(), 24)}
              alt="selected token"
            />
          </div>
        )}
      </div>
      <div className="ml-[10px] xs-:ml-[6px]">
        <div className="flex items-center decoration-gray-10 text-[1.3rem] font-space-grotesk">
          <div>
            {pair.from}/{pair.to}
          </div>
          <div
            className={cx(
              "hidden xs-:block",
              "mx-[6px] text-gray-10",
              "w-[62.5px]"
            )}
          >
            {/* {numberWithCommas(pairPrice)} */}

            <div className="flex">
              {/* {["CNH", "JPY"].includes(pair.to) ? "¥" : "$"} */}
              <AnimatedNumber
                colorMode={COLOR_MODES.INCREASED}
                to={+pairPrice}
                precedingContents={["CNH", "JPY"].includes(pair.to) ? "¥" : "$"}
              />
            </div>
          </div>

          {/* <div
            className={cx(
              { "text-green-2": isIncreasedFromPrevPrice === true },
              { "text-red-2": isIncreasedFromPrevPrice === false },
              "hidden xs-:flex items-center",
              "mx-[6px] text-gray-10",
              "w-[13.9rem]"
            )}
          >


            <div className="flex w-[7.9rem]">
              {["CNH", "JPY"].includes(pair.to) ? "¥" : "$"}
              <AnimatedNumber to={pairPrice} />
            </div>
            {priceChange && (
              <div className="ml-[6px] w-[6rem]">
                {isPriceChangePlus}
                {new BN(priceChange).eq(0) ? "0.00" : priceChange}%
              </div>
            )}
          </div> */}

          {!forexClosed && (
            <>
              {priceChange !== undefined ? (
                <div
                  className={cx(
                    // { "!text-green-2": new BN(priceChange).gt(0) },
                    // { "!text-red-2": new BN(priceChange).lt(0) },
                    "font-space-grotesk ml-[6px] xs:ml-0",
                    "w-[50px]",
                    "xs-:hidden"
                  )}
                >
                  <div className="flex items-center">
                    <AnimatedNumber
                      trim={false}
                      to={new BN(priceChange).eq(0) ? 0 : +priceChange}
                      precedingContents={isPriceChangePlus}
                      decimals={2}
                      colorMode={COLOR_MODES.PNL}
                      followingContents={"%"}
                    />
                  </div>
                </div>
              ) : (
                <div className="min-w-[5rem]" />
              )}
            </>
          )}

          {forexClosed && (
            <div
              className={cx("font-space-grotesk ml-[6px] xs:ml-0 text-red-2")}
            >
              CLOSED
            </div>
          )}
        </div>
        <div
          className={cx(
            "text-[1.1rem] block xs-:hidden text-gray-20 font-space-grotesk"
          )}
        >
          {/* {numberWithCommas(pairPrice)} */}

          <div className="flex">
            <AnimatedNumber
              colorMode={COLOR_MODES.INCREASED}
              to={+pairPrice}
              precedingContents={["CNH", "JPY"].includes(pair.to) ? "¥" : "$"}
            />
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default PairPrice;
