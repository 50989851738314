import React, { useMemo } from "react";

import cx from "classnames";

import "./Tab.css";
import {
  activePositionTabAtom,
  closeTradesAtom,
  isCloseTradesLoadingAtom,
  isOpenOrdersLoadingAtom,
  isOpenTradesLoadingAtom,
  isPendingOrdersLoadingAtom,
  openOrdersAtom,
  openTradesAtom,
  pendingOrdersAtom,
} from "atoms/exchange";
import { useAtom, useAtomValue } from "jotai";
import { POSITION_TYPE } from "futures-domain/trades/types";

import { useLingui } from "@lingui/react";
import useWallet from "futures-lib/wallets/useWallet";

export default function PositionTab() {
  const { isActive } = useWallet();

  const openTrades = useAtomValue(openTradesAtom);
  const isOpenTradesLoading = useAtomValue(isOpenTradesLoadingAtom);

  const openOrders = useAtomValue(openOrdersAtom);
  const isOpenOrdersLoading = useAtomValue(isOpenOrdersLoadingAtom);

  const pendingOrders = useAtomValue(pendingOrdersAtom);
  const isPendingOrdersLoading = useAtomValue(isPendingOrdersLoadingAtom);

  const closeTrades = useAtomValue(closeTradesAtom);
  const isCloseTradesLoading = useAtomValue(isCloseTradesLoadingAtom);

  // order, pendingOrder 상태를 합쳐서 관리
  const isOrdersLoading = useMemo(() => {
    return isOpenOrdersLoading && isPendingOrdersLoading;
  }, [isOpenOrdersLoading, isPendingOrdersLoading]);

  // order, pendingOrder 상태를 합쳐서 관리
  const orders = useMemo(() => {
    if (openOrders && openOrders.length > 0 && pendingOrders && pendingOrders.length > 0) {
      return [...openOrders, ...pendingOrders];
    } else if (openOrders && openOrders.length > 0) {
      return openOrders;
    } else if (pendingOrders && pendingOrders.length > 0) {
      return pendingOrders;
    } else {
      return [];
    }
  }, [openOrders, pendingOrders]);

  const [activePositionTab, setActivePositionTab] = useAtom(activePositionTabAtom);

  const { i18n } = useLingui();

  return (
    <div className={cx("flex items-center text-[1.5rem] font-medium")}>
      <div
        onClick={() => setActivePositionTab(POSITION_TYPE.TRADES)}
        className={cx("cursor-pointer", activePositionTab === POSITION_TYPE.TRADES ? "text-white" : "text-gray-40")}
      >
        {i18n._(POSITION_TYPE.TRADES)}

        {/* openTrades 정보 로딩 중 */}
        <span className="ml-[2px]">
          {isActive && POSITION_TYPE.TRADES && !openTrades && isOpenTradesLoading && "(...)"}
        </span>
        {/* openTrades 정보가 있을 때 */}
        <span className="ml-[2px]">{isActive && POSITION_TYPE.TRADES && openTrades && `(${openTrades.length})`}</span>

        {/* openTrades 정보가 null or undefined(지갑 연결 X) -> 아무것도 표시 안함 */}
      </div>

      <div className="border-r border-white border-opacity-10 mx-[1.6rem] w-px h-[1.25rem]" />

      <div
        onClick={() => setActivePositionTab(POSITION_TYPE.ORDERS)}
        className={cx("cursor-pointer", activePositionTab === POSITION_TYPE.ORDERS ? "text-white" : "text-gray-40")}
      >
        {i18n._(POSITION_TYPE.ORDERS)}
        <span className="ml-[2px]">{isActive && POSITION_TYPE.ORDERS && !orders && isOrdersLoading && "(...)"}</span>
        <span className="ml-[2px]">{isActive && POSITION_TYPE.ORDERS && orders && `(${orders.length})`}</span>
      </div>

      <div className="border-r border-white border-opacity-10 mx-[1.6rem] w-px h-[1.25rem]" />

      <div
        onClick={() => setActivePositionTab(POSITION_TYPE.HISTORY)}
        className={cx("cursor-pointer", activePositionTab === POSITION_TYPE.HISTORY ? "text-white" : "text-gray-40")}
      >
        {i18n._(POSITION_TYPE.HISTORY)}
        <span className="ml-[2px]">{POSITION_TYPE.HISTORY && !closeTrades && isCloseTradesLoading && "(...)"}</span>
        <span className="ml-[2px]">
          {isActive && POSITION_TYPE.HISTORY && closeTrades && `(${closeTrades.length})`}
        </span>
      </div>
    </div>
  );
}
