import { GambitTradingV1 } from "@changerio/futures-contracts/dist/typechain-types";
import { formatUnits } from "@ethersproject/units";
import { Trans, t } from "@lingui/macro";
import {
  isVisibleDetailOrderModalAtom,
  pairsAtom,
  pairsPricesAtom,
  selectedOrderAtom,
} from "atoms/exchange";
import cx from "classnames";
import ModalSkeletonWithPortal from "components/Modal/ModalSkeletonWithPortal";
import { getContract } from "config/contracts";
import { getToken } from "config/tokens";
import { BigNumber } from "ethers";
import {
  comma1000,
  getFulfillPrice,
  getOpeningFeeWithNetworkFee,
  trimPriceBN,
} from "futures-domain/trades/utils";
import { useChainId } from "futures-lib/chains";
import { newContractFetcher } from "futures-lib/contracts";
import {
  useDelegationFeeThreshhold,
  useGetContract,
} from "futures-lib/contracts/contract";
import { formatAmount } from "futures-lib/numbers";
import { useAtom, useAtomValue } from "jotai";
import { useMemo } from "react";
import useSWR from "swr";

export const DetailOrderModal = () => {
  const [isVisible, setIsVisible] = useAtom(isVisibleDetailOrderModalAtom);
  const selectedOrder = useAtomValue(selectedOrderAtom);
  // console.log(selectedOrder);

  const { chainId } = useChainId();
  const fromTokenAddress = getContract(chainId, "CollateralToken");
  const fromToken = getToken(chainId, fromTokenAddress);

  const pairs = useAtomValue(pairsAtom);

  const longOrShort = useMemo(() => {
    if (isVisible) {
      return selectedOrder?.buy ? t`LONG` : t`SHORT`;
    } else {
      return;
    }
  }, [isVisible, selectedOrder?.buy]);

  const minLevPosUsdc = useMemo(() => {
    const orderPair = pairs.find(
      (pair) => pair.pairIndex === selectedOrder?.pairIndex
    );
    if (orderPair) {
      return orderPair?.fee?.minLevPosUsdc;
    }
  }, [pairs, selectedOrder?.pairIndex]);

  const tradingContract = useGetContract("GambitTradingV1") as GambitTradingV1;

  const { data: delegationFeeThresholdMultiplier } = useSWR<BigNumber>(
    tradingContract && [
      tradingContract,
      "delegationFeeThresholdMultiplier",
      [],
    ],
    {
      fetcher: newContractFetcher,
      shouldRetryOnError: true,
      errorRetryInterval: 3000,
      refreshInterval: 3000,
    }
  );

  // * minLevPosUsdc * delegationFeeThresholdMultiplier
  // * e.g) 2500 * 3 = 7500
  const delegationFeeThreshhold = useDelegationFeeThreshhold(
    minLevPosUsdc,
    delegationFeeThresholdMultiplier
  );

  const orderPair = useMemo(() => {
    if (!selectedOrder) return;

    const pair = pairs.find(
      (pair) => pair.pairIndex === selectedOrder.pairIndex
    );
    if (pair) return pair;

    return;
  }, [pairs, selectedOrder]);

  const collateral = useMemo(() => {
    if (!selectedOrder || !fromToken) return;
    return formatUnits(
      selectedOrder.positionSize.toString(),
      fromToken.decimals
    );
  }, [fromToken, selectedOrder]);

  const networkFee = useMemo(() => {
    if (!delegationFeeThreshhold || !collateral || !orderPair) return;

    if (+collateral < +delegationFeeThreshhold) {
      return +orderPair.fee?.oracleFee;
    }
    return 0;
  }, [collateral, delegationFeeThreshhold, orderPair]);

  const openingFee = useMemo(() => {
    // positionSize * 요율

    // if (selectedOrder?.positionSize && selectedOrder.leverage) {
    //   const _positionSize = selectedOrder?.positionSize.mul(BigNumber.from(selectedOrder.leverage));
    if (!selectedOrder) return;

    if (!orderPair || !collateral) return;

    //   // console.log(formatAmount(_openingFee, fromToken.decimals, 2, true));
    //   return 0;
    //   // return getOpeningFee(+pair.fee.openFeeP, _positionSize, networkFee);
    // }

    // if (!fromValue || !fromToken.decimals || !leverageOption || !selectedPair) return;

    try {
      return getOpeningFeeWithNetworkFee(
        +orderPair.fee?.openFeeP,
        collateral,
        selectedOrder.leverage,
        networkFee
      );
    } catch (e) {}
  }, [collateral, networkFee, orderPair, selectedOrder]);

  const pairsPrices = useAtomValue(pairsPricesAtom);

  return (
    <ModalSkeletonWithPortal isVisible={isVisible} setIsVisible={setIsVisible}>
      {selectedOrder && (
        <div className="min-w-[30rem]">
          {/* Title */}
          <div className="text-center mt-[3.8rem]">
            <div className="text-[2.4rem] font-bold">
              {selectedOrder?.pair.from}/{selectedOrder?.pair.to}
            </div>
            <div
              className={cx(
                "text-[1.6rem] text-gray-15 font-medium",
                longOrShort === t`LONG` ? "text-green-2" : "text-red-2"
              )}
            >
              {longOrShort}
            </div>
          </div>

          <hr className="h-px mt-[1.25rem] mb-[2.25rem] bg-gray-70 border-0" />

          {/* Content */}
          <ul className="px-[2.4rem] pb-[2.4rem] text-[1.4rem] space-y-[1.6rem] overflow-y-auto">
            <li className="flex justify-between">
              <span className="text-gray-15">
                <Trans id="msg.tradeBox / Pay">Pay</Trans>
              </span>
              <span className="font-medium">
                {formatAmount(
                  selectedOrder?.positionSize,
                  fromToken.decimals,
                  2,
                  true
                )}{" "}
                {fromToken.symbol}
              </span>
            </li>
            <li className="flex justify-between">
              <span className="text-gray-15">
                <Trans>Leverage</Trans>
              </span>
              <span className="font-medium">{selectedOrder?.leverage}x</span>
            </li>
            <li className="flex justify-between">
              <span className="text-gray-15">
                <Trans>Ask Price</Trans>
              </span>
              <span className="font-medium">
                ${comma1000(trimPriceBN(selectedOrder.minPrice))}
              </span>
            </li>
            <li className="flex justify-between">
              <span className="text-gray-15">
                <Trans>Opening Fee</Trans>
              </span>
              <span className="font-medium">
                {openingFee} {fromToken.symbol}
              </span>
            </li>
            <li className="flex justify-between">
              <span className="text-gray-15">
                <Trans>Fulfill Price</Trans>
              </span>
              <span className="font-medium">{`$${getFulfillPrice(
                selectedOrder,
                pairs,
                pairsPrices
              )}`}</span>
            </li>
            <li className="flex justify-between">
              <span className="text-gray-15">
                <Trans>Stop Loss</Trans>
              </span>
              <span className="font-medium">
                ${comma1000(trimPriceBN(selectedOrder.sl))}
              </span>
            </li>
            <li className="flex justify-between">
              <span className="text-gray-15">
                <Trans>Take Profit</Trans>
              </span>
              <span className="font-medium">
                ${comma1000(trimPriceBN(selectedOrder.tp))}
              </span>
            </li>
          </ul>
        </div>
      )}
    </ModalSkeletonWithPortal>
  );
};
