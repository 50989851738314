import { getAbi, getContract } from "config/contracts";
import { useChainId } from "futures-lib/chains";
import { gambitContractFetcherForReferral } from "futures-lib/contracts/gambitContractFetcher";
import { useSetAtom } from "jotai";
import { useEffect } from "react";
import useSWR from "swr";
// import { ZKSYNC, ZKSYNC_GOERLI } from "config/chains";
import { referralAtom } from "atoms/referral";
import useWallet from "futures-lib/wallets/useWallet";

export const useReferral = () => {
  const { isActive, account, signer } = useWallet();
  const { chainId } = useChainId();
  const ReferralAddress = getContract(chainId, "Referral");
  const setReferral = useSetAtom(referralAtom);

  const { data, isLoading, error } = useSWR(
    isActive && [isActive, chainId, ReferralAddress, "", account],
    {
      fetcher: gambitContractFetcherForReferral(
        signer,
        getAbi(chainId, "Referral")
      ),
      refreshInterval: 3000,
      errorRetryInterval: 3000,
    }
  );

  useEffect(() => {
    if (!error && data && !isLoading) {
      // console.log(data);
      const [referrerDetails, getTradersReferred] = data;

      // INFO: referral은 changer contract랑 gns contract의 refererDetails 반환 값이 달라서 분기
      // if ([ZKSYNC].includes(chainId)) {
      const [
        ,
        // ally
        volumeReferredUsdc, // pendingRewardsToken
        ,
        pendingRewardsUsdc, // totalRewardsToken
        ,
        totalRewardsValueUsdc,
        tier,
        active,
      ] = referrerDetails;

      const referralData = {
        numOfTradersReferred: getTradersReferred.length,
        tradeVolumes: volumeReferredUsdc,
        totalProfits: totalRewardsValueUsdc,
        rank: tier.toString(),
        claimedProfits: totalRewardsValueUsdc.sub(pendingRewardsUsdc),
        unclaimedRewards: pendingRewardsUsdc,
        active,
      };
      setReferral(referralData);
      // } else {
      //   const [
      //     ,
      //     //ally
      //     volumeReferredUsdc,
      //     pendingRewardsToken,
      //     totalRewardsToken, // totalRewardsValueUsdc
      //     ,
      //     active,
      //   ] = referrerDetails;

      //   const referralData = {
      //     numOfTradersReferred: getTradersReferred.length,
      //     tradeVolumes: volumeReferredUsdc,
      //     totalProfits: totalRewardsToken,
      //     rank: "",
      //     claimedProfits: totalRewardsToken.sub(pendingRewardsToken),
      //     unclaimedRewards: pendingRewardsToken,
      //     active,
      //   };
      //   setReferral(referralData);
      // }
    }
  }, [data, isLoading, error, chainId, setReferral]);
};
