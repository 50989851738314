// import { DEFAULT_WITHDRAW_EPOCHS_TIME_LOCK } from "components/Vault/constants";
import { AccountVault, CommonVault, ExistingRequests, TradeDeposited } from "futures-domain/vault/types";
import { atom } from "jotai";

export const accountVaultAtom = atom<AccountVault>({} as AccountVault);
export const commonVaultAtom = atom<CommonVault>({
  // withdrawEpochsTimelock: DEFAULT_WITHDRAW_EPOCHS_TIME_LOCK,
} as CommonVault);
export const existingRequestsAtom = atom<ExistingRequests | null>(null);
export const traderDepositedAtom = atom<TradeDeposited | null>(null);
