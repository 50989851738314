import cx from "classnames";
import { activeLongShortTabAtom } from "atoms/exchange";
import { useAtom } from "jotai";
import { LONG, SHORT, LONG_SHORT_OPTIONS } from "futures-lib/legacy";
import LongImage from "img/ic-long.svg";
import ShortImage from "img/ic-short.svg";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";

export default function LongShortTab() {
  const [activeLongShortTab, setActiveTradeTab] = useAtom(activeLongShortTabAtom);
  const { i18n } = useLingui();

  return (
    <div className={cx("flex items-center justify-between")}>
      {LONG_SHORT_OPTIONS.map((tradeTab) => {
        return (
          <div
            className={cx(
              "flex-1 flex justify-center items-center py-[0.9rem] text-center text-[1.4rem] font-medium cursor-pointer",
              tradeTab === activeLongShortTab ? "bg-white text-black-2" : "bg-black-2 text-gray-15",
              { "rounded-tl-[6px] rounded-bl-[6px]": tradeTab === LONG },
              { "rounded-tr-[6px] rounded-br-[6px]": tradeTab === SHORT }
            )}
            onClick={() => setActiveTradeTab(tradeTab)}
            key={tradeTab}
          >
            <img
              className="w-[1.4rem] h-[1.4rem] mr-[4px]"
              src={tradeTab === LONG ? LongImage : ShortImage}
              alt={tradeTab}
            />
            <span>
              <Trans>{i18n._(tradeTab)}</Trans>
            </span>
          </div>
        );
      })}
    </div>
  );
}
