import { derivedOpenTradesAtom, openTradesAtom } from "atoms/exchange";
import { getAbi, getContract } from "config/contracts";
import { useChainId } from "futures-lib/chains";
import { useAtomValue, useSetAtom } from "jotai";

import { gambitContractFetcherForDerivedOpenTrades } from "futures-lib/contracts/gambitContractFetcher";
import useWallet from "futures-lib/wallets/useWallet";
import { useEffect } from "react";
import useSWR from "swr";

export const useOpenTrades = () => {
  const { isActive, account, signer } = useWallet();

  const { chainId } = useChainId();

  const pairInfosAddress = getContract(chainId, "PairInfos");
  const pairInfosAbi = getAbi(chainId, "PairInfos");

  const openTrades = useAtomValue(openTradesAtom);

  const setDerivedOpenTrades = useSetAtom(derivedOpenTradesAtom);

  // errorGetTraderFromSubgraph true일 때만 실행
  // subgraph로 openTrade 정보 가져오는데 실패했을 때만, contract로 opentTrade 정보 가져오기
  // const {
  //   data: openTradesData,
  //   isLoading: isOpenTradesLoading,
  //   error,
  // } = useSWR(
  //   isActive &&
  //     errorGetTraderFromSubgraph && [
  //       isActive,
  //       chainId,
  //       storageAddress,
  //       "openTradesCount",
  //       pairs,
  //       account,
  //     ],
  //   {
  //     fetcher: gambitContractFetcherForOpenTrades(signer, storageAbi),
  //     refreshInterval: 800,
  //     errorRetryInterval: 500,
  //     errorRetryCount: 1000,
  //   }
  // );

  // useEffect(() => {
  //   if (!error && !isOpenTradesLoading && openTradesData) {
  //     // console.log(openTradesData.filter((trade) => trade !== null));

  //     // console.log(
  //     //   formatOpenTrades(
  //     //     pairs,
  //     //     openTradesData.filter((trade) => trade !== null)
  //     //   )
  //     // );

  //     // console.log(openTradesData.filter((trade) => trade !== null));
  //     // console.log("from contract1");
  //     setOpenTrades(
  //       formatOpenTrades(
  //         pairs,
  //         openTradesData.filter((trade) => trade !== null),
  //         chainId
  //       )
  //     );
  //   } else if (error) {
  //     // console.log(error);
  //   }

  //   if (isOpenTradesLoading) {
  //     setIsOpenTradesLoading(true);
  //   } else {
  //     setIsOpenTradesLoading(false);
  //   }
  // }, [
  //   openTradesData,
  //   isOpenTradesLoading,
  //   pairs,
  //   setOpenTrades,
  //   setIsOpenTradesLoading,
  //   error,
  //   chainId,
  // ]);

  // subgraph가 정상 동작하더라도 가져와야 하는 값들
  // liqPrice, fundingFee, rolloverFee는 온체인에서 가져온다.
  // 서브그래프에서는 바로 가져올 수 없고 가져온 값들 가지고 추가적인 계산 필요
  const {
    data: derivedOpenTradesData,
    isLoading: isDerivedOpenTradesLoading,
    error: error1,
  } = useSWR(
    isActive &&
      openTrades && [
        isActive,
        chainId,
        pairInfosAddress,
        "",
        openTrades,
        account,
      ],
    {
      fetcher: gambitContractFetcherForDerivedOpenTrades(signer, pairInfosAbi),
      refreshInterval: 1000 * 5, // 5s
      errorRetryInterval: 500,
      errorRetryCount: 1000,
    }
  );

  useEffect(() => {
    if (!error1 && !isDerivedOpenTradesLoading && derivedOpenTradesData) {
      // console.log("from contract2");
      // setDerivedOpenTrades([]);
      setDerivedOpenTrades(
        derivedOpenTradesData.map((derivedOpenTrade) => {
          const [pairIndex, positionIndex, liqPrice, fundingFee, rolloverFee] =
            derivedOpenTrade;

          // TODO: 포지션 오픈 직후 잠깐(1초 내외) fundingFee, rolloverFee 값이 -로 들어와서 pnl이 엄청 높거나 낮아 보이는 현상 발생

          // console.log(
          //   pairIndex.toString(),
          //   positionIndex.toString(),
          //   formatAmount(liqPrice, 10),
          //   formatAmount(fundingFee, 10),
          //   formatAmount(rolloverFee, 10)
          // );

          return {
            pairIndex,
            positionIndex,
            liqPrice,
            fundingFee,
            rolloverFee,
          };
        })
      );
    }
  }, [
    derivedOpenTradesData,
    error1,
    isDerivedOpenTradesLoading,
    setDerivedOpenTrades,
  ]);
};

// export const formatOpenTrades = (
//   pairs: Pair[],
//   rawOpenTradesArray,
//   chainId: number
// ): Trade[] => {
//   const openTrades: Trade[] = rawOpenTradesArray?.map((rawOpenTrade) => {
//     // eslint-disable-next-line @typescript-eslint/no-unused-vars
//     const [
//       ,
//       pairIndex,
//       positionIndex,
//       ,
//       positionSizeUsdc,
//       openPrice,
//       buy,
//       leverage,
//       tp,
//       sl,
//     ] = rawOpenTrade;

//     const openTrade: Trade = {
//       // address,
//       pairIndex: pairIndex.toNumber(),
//       positionIndex: positionIndex.toString(),
//       positionSizeUsdc,
//       openPrice,
//       buy,
//       leverage: +formatUnits(leverage, ADDED_DECIMALS),
//       tp,
//       sl,
//       pair: getPair(pairs, pairIndex.toNumber()),
//     };
//     return openTrade;
//   });

//   return openTrades;
// };
