export interface NetworkOption {
  label: string;
  value: number; // chainId
  icon: string;
  color: string;
}

export const WALLET_TYPES = {
  METAMASK: "MetaMask",
  COINBASE: "CoinBase",
  WALLET_CONNECT_V1: "WalletConnectV1",
  WALLET_CONNECT_V2: "WalletConnectV2",
} as const;

export type WalletType = typeof WALLET_TYPES[keyof typeof WALLET_TYPES];

export const APP_MODES = {
  PRODUCTION: "production",
  STAGING: "staging",
  LOCAL: "local",
};

export type AppModeType = typeof APP_MODES[keyof typeof APP_MODES];
