import { forwardRef } from "react";

const SignInModalLayout = forwardRef((props: any, ref) => {
  return (
    <div
      ref={ref as any}
      className="fixed top-0 left-1/2 -translate-x-1/2 max-h-[100dvh] h-[100dvh] w-full w-1550:max-w-[155rem] w-1550:w-[155rem] z-[99]"
    >
      {props.children}
    </div>
  );
});

export default SignInModalLayout;
