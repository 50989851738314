import { currentTimeAtom } from "atoms";
import {
  chartLinesAtom,
  derivedOpenTradesAtom,
  openOrdersAtom,
  pairsAtom,
  pairsPricesAtom,
  parsedOpenTradesAtom,
  selectedPairAtom,
} from "atoms/exchange";
import BN from "bignumber.js";
import {
  FOREX_CLOSED_DECISION_TIME,
  PRICE_DECIMAL_STANDARD,
} from "components/Exchange/constants";
import {
  defaultChartProps,
  disabledFeaturesOnMobile,
  enabledFeaturesOnMobile,
} from "components/TVChartContainer/constants";
import { getConstant } from "config/chains";
import { getContract } from "config/contracts";
import { getToken } from "config/tokens";
import { SUPPORTED_RESOLUTIONS_V2 } from "config/tradingview";
import { ethers } from "ethers";
import {
  getSlOrLiqPrice,
  makeNdigits,
  trimPriceBN,
  unpadZero,
} from "futures-domain/trades/utils";
import useTVAdvancedDatafeed from "futures-domain/tradingview/useTVAdvancedDatafeed";
import { getObjectKeyFromValue } from "futures-domain/tradingview/utils";
import { useChainId } from "futures-lib/chains";
import { formatAmount } from "futures-lib/numbers";
import { useAtomValue, useSetAtom } from "jotai";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useNetworkState } from "react-use";
import {
  ChartingLibraryWidgetOptions,
  IChartingLibraryWidget,
  IOrderLineAdapter,
  ResolutionString,
  Timezone,
} from "../../charting_library.d";
import "./index.css";
export interface ChartContainerProps {
  symbol: ChartingLibraryWidgetOptions["symbol"];
  interval: ChartingLibraryWidgetOptions["interval"];
  // BEWARE: no trailing slash is expected in feed URL
  datafeedUrl: string;
  libraryPath: ChartingLibraryWidgetOptions["library_path"];
  chartsStorageUrl: ChartingLibraryWidgetOptions["charts_storage_url"];
  chartsStorageApiVersion: ChartingLibraryWidgetOptions["charts_storage_api_version"];
  clientId: ChartingLibraryWidgetOptions["client_id"];
  userId: ChartingLibraryWidgetOptions["user_id"];
  fullscreen: ChartingLibraryWidgetOptions["fullscreen"];
  autosize: ChartingLibraryWidgetOptions["autosize"];
  studiesOverrides: ChartingLibraryWidgetOptions["studies_overrides"];
  container: ChartingLibraryWidgetOptions["container"];
}

const buyColor = "#08a344";
const sellColor = "#c50d48";

// const SUPPORTED_LANGUAGES = [
//   "ar",
//   "zh",
//   "ca_ES",
//   "en",
//   "fr",
//   "de",
//   "he_IL",
//   "id_ID",
//   "it",
//   "ja",
//   "ko",
//   "pl",
//   "pt",
//   "ru",
//   "es",
//   "sv",
//   "th",
//   "tr",
//   "vi",
//   "ms_MY",
//   "zh_TW",
// ];

// const getLanguageFromURL = (): LanguageCode | null => {
//   const regex = new RegExp("[\\?&]lang=([^&#]*)");
//   const results = regex.exec(window.location.search);
//   return results === null
//     ? null
//     : (decodeURIComponent(results[1].replace(/\+/g, " ")) as LanguageCode);
// };

export const TVAdvancedChartContainer = () => {
  const { datafeed } = useTVAdvancedDatafeed();
  const { chainId } = useChainId();
  const tvWidgetRef = useRef<IChartingLibraryWidget | null>(null);
  const chartContainerRef =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const openTrades = useAtomValue(parsedOpenTradesAtom);
  const openOrders = useAtomValue(openOrdersAtom);
  const derivedOpenTrades = useAtomValue(derivedOpenTradesAtom);
  const selectedPair = useAtomValue(selectedPairAtom);
  const setChartLines = useSetAtom(chartLinesAtom);
  const [chartReady, setChartReady] = useState(false);

  const symbol = useMemo(
    () => selectedPair?.tvSymbol?.split(":")?.[1] || "",
    [selectedPair]
  );
  const fromTokenAddress = getContract(chainId, "CollateralToken");
  const fromToken = getToken(chainId, fromTokenAddress);

  const pairsPrices = useAtomValue(pairsPricesAtom);

  const pairPriceInfo = useMemo(() => {
    if (!selectedPair) return;

    try {
      return pairsPrices[selectedPair?.priceFeedId];
    } catch (e) {
      return;
    }
  }, [selectedPair, pairsPrices]);

  const currentTime = useAtomValue(currentTimeAtom);

  const forexClosed = useMemo(() => {
    if (selectedPair?.groupIndex !== 1 || !pairPriceInfo?.publish_time)
      return false;
    // console.log(currentTime?.unix());
    // console.log(pairPriceInfo?.publish_time);

    const _currentTime = currentTime?.unix();
    const publish_time = pairPriceInfo?.publish_time;

    if (!currentTime || !publish_time) return false;

    if (
      _currentTime &&
      Math.abs(_currentTime - publish_time) > FOREX_CLOSED_DECISION_TIME
    ) {
      return true;
    }

    return false;
  }, [currentTime, selectedPair?.groupIndex, pairPriceInfo]);

  const drawEntryLineOnChart = useCallback(
    (title: string, price: number, size: string, order: number) => {
      if (
        chartReady &&
        tvWidgetRef.current?.activeChart?.().dataReady(() => {})
      ) {
        const chart = tvWidgetRef.current.activeChart();
        const color = title === "LONG" ? buyColor : sellColor;
        const entryPositionLine = chart.createOrderLine();

        return entryPositionLine
          .setText(title + "  ")
          .setPrice(price)
          .setQuantity(size)
          .setLineStyle(1)
          .setLineLength(50 - order * 15)
          .setQuantityBackgroundColor(color)
          .setQuantityBorderColor(color)
          .setBodyTextColor("#fff")
          .setLineColor(color)
          .setBodyBackgroundColor("#3a3e5e")
          .setBodyBorderColor(color);
      }
    },
    [chartReady]
  );
  const drawLiqOrSLLineOnChart = useCallback(
    (type: string, title: string, price: number, leverage: string) => {
      if (
        chartReady &&
        tvWidgetRef.current?.activeChart?.().dataReady(() => {})
      ) {
        const chart = tvWidgetRef.current.activeChart();
        const positionLine = chart.createOrderLine();
        if (type === "LIQ") {
          return positionLine
            .setText(type + " " + title + "  " + leverage + "X  ")
            .setPrice(price)
            .setQuantity("")
            .setBodyFont(`12pt "Relative"`)
            .setLineStyle(1)
            .setLineLength(1)
            .setBodyTextColor("#4094E8")
            .setBodyBackgroundColor("#99000000")
            .setBodyBorderColor("transparent");
        } else {
          return positionLine
            .setText(type + " " + title + "  " + leverage + "X  ")
            .setPrice(price)
            .setQuantity("")
            .setBodyFont(`12pt "Relative"`)
            .setLineStyle(3)
            .setLineLength(1)
            .setQuantityBackgroundColor("#99000000")
            .setQuantityBorderColor("transparent")
            .setBodyTextColor("#fff")
            .setBodyTextColor(sellColor)
            .setLineColor(sellColor)
            .setBodyBackgroundColor("#99000000")
            .setBodyBorderColor("transparent");
        }
      }
    },
    [chartReady]
  );

  const drawTakeProfitLineOnChart = useCallback(
    (title: string, price: number, leverage: string) => {
      if (
        chartReady &&
        tvWidgetRef.current?.activeChart?.().dataReady(() => {})
      ) {
        const chart = tvWidgetRef.current.activeChart();
        const positionLine = chart.createOrderLine();

        return positionLine
          .setText("TP " + title + "  " + leverage + "X  ")
          .setPrice(price)
          .setQuantity("")
          .setBodyFont(`12pt "Relative"`)
          .setLineStyle(3)
          .setLineLength(1)
          .setBodyTextColor("#fff")
          .setBodyTextColor(buyColor)
          .setLineColor(buyColor)
          .setBodyBackgroundColor("#99000000")
          .setBodyBorderColor("transparent");
      }
    },
    [chartReady]
  );

  const drawOrderLineOnChart = useCallback(
    (title: string, price: number, size: string, type?: string) => {
      if (
        chartReady &&
        tvWidgetRef.current?.activeChart?.().dataReady(() => {})
      ) {
        const chart = tvWidgetRef.current.activeChart();
        const color = title === "LONG" ? buyColor : sellColor;
        const orderType = type ? (type === "REVERSAL" ? `LIMIT` : `STOP`) : "";
        const orderPositionLine = chart.createOrderLine();
        return orderPositionLine
          .setText(orderType + " " + title)
          .setPrice(price)
          .setQuantity(size)
          .setLineStyle(1)
          .setLineLength(2)
          .setQuantityBackgroundColor("#99000000")
          .setQuantityBorderColor("transparent")
          .setBodyTextColor("#fff")
          .setBodyTextColor(color)
          .setLineColor(color)
          .setBodyBackgroundColor("#99000000")
          .setBodyBorderColor("transparent");
      }
    },
    [chartReady]
  );

  // trade position line 설정
  useEffect(
    function updatePriceLines() {
      if (!openTrades || openTrades?.length <= 0) {
        setChartLines((chartLines) => {
          for (let lines in chartLines) {
            chartLines[lines]?.forEach((line) => {
              line?.remove();
            });
          }
          return {};
        });

        return;
      }
      openTrades.forEach((trade) => {
        if (selectedPair?.priceFeedId !== trade.pair.priceFeedId) return;
        if (trade?.activeTradeStatus !== "HIDDEN") {
          const entryLine = drawEntryLineOnChart(
            trade.buy ? "LONG" : "SHORT",
            Number(trimPriceBN(trade.openPrice, true).replace(/,/g, "")),
            formatAmount(trade.positionSizeUsdc, fromToken.decimals, 2, true) +
              " " +
              fromToken.symbol,
            Number(trade.positionIndex)
          );
          const liqOrSLLine = drawLiqOrSLLineOnChart(
            getSlOrLiqPrice(trade).type!,
            trade.buy ? "LONG" : "SHORT",
            Number(
              trimPriceBN(getSlOrLiqPrice(trade).value, true).replace(/,/g, "")
            ),
            unpadZero(trade.leverage.toFixed(2))
          );
          const takeprofitLine = drawTakeProfitLineOnChart(
            trade.buy ? "LONG" : "SHORT",
            Number(trimPriceBN(trade.tp, true).replace(/,/g, "")),
            unpadZero(trade.leverage.toFixed(2))
          );

          setChartLines((chartLines) => {
            return {
              ...chartLines,
              [trade.positionIndex]: [entryLine, liqOrSLLine, takeprofitLine],
            };
          });
        } else {
          setChartLines((chartLines) => {
            chartLines[trade.positionIndex]?.forEach((line) => {
              line?.remove();
            });

            return {
              ...chartLines,
              [trade.positionIndex]: null,
            };
          });
        }
      });
      return () => {
        setChartLines((chartLines) => {
          for (let lines in chartLines) {
            chartLines[lines]?.forEach((line) => {
              line?.remove();
            });
          }
          return {};
        });
      };
    },
    // eslint-disable-next-line
    [
      openTrades,
      derivedOpenTrades?.length,
      drawEntryLineOnChart,
      fromToken.decimals,
      fromToken.symbol,
      selectedPair?.priceFeedId,
    ]
  );
  // order position line 설정
  useEffect(
    function updateOrderLines() {
      const lines: (IOrderLineAdapter | undefined)[] = [];

      if (!openOrders || openOrders?.length <= 0) return;
      openOrders.forEach((order) => {
        if (selectedPair?.priceFeedId !== order?.pair?.priceFeedId) return;
        lines.push(
          drawOrderLineOnChart(
            order.buy ? "LONG" : "SHORT",
            Number(trimPriceBN(order.minPrice, true).replace(/,/g, "")),
            formatAmount(order.positionSize, fromToken.decimals, 2, true) +
              " " +
              fromToken.symbol,
            order?.type
          )
        );
      });
      return () => {
        lines?.forEach((line) => line?.remove());
      };
    },
    // eslint-disable-next-line
    [
      openOrders,
      fromToken.decimals,
      fromToken.symbol,
      selectedPair?.priceFeedId,
    ]
  );

  const defaultProps: Omit<ChartContainerProps, "container"> = {
    symbol,
    interval: getObjectKeyFromValue(
      forexClosed ? "1d" : "1m",
      SUPPORTED_RESOLUTIONS_V2
    ) as ResolutionString,
    datafeedUrl: "https://benchmarks.pyth.network/v1/shims/tradingview",
    libraryPath: "/charting_library/",
    chartsStorageUrl: "https://saveload.tradingview.com",
    chartsStorageApiVersion: "1.1",
    clientId: "tradingview.com",
    userId: "public_user_id",
    fullscreen: false,
    autosize: true,
    studiesOverrides: {},
  };

  const publicPriceEndpoint = useMemo(() => {
    return getConstant(chainId, "priceServerEndPoint");
  }, [chainId]);

  const pairs = useAtomValue(pairsAtom);

  const priceFeedIdOfSelectedPair = useMemo(() => {
    return selectedPair?.priceFeedId;
  }, [selectedPair?.priceFeedId]);

  const priceApiParams = useMemo(() => {
    const params = new URLSearchParams();
    pairs
      .filter((pair) => pair?.priceFeedId !== ethers.constants.AddressZero)
      .forEach((pair) => {
        if (pair) {
          params.append("ids[]", pair.priceFeedId!);
        }
      });

    return params.toString();
  }, [pairs]);

  const [readyToChart, setReadyToChart] = useState(false);
  // const documentVisible = useAtomValue(documentVisibleAtom);
  const state = useNetworkState();

  useEffect(() => {
    if (readyToChart) return;

    if (priceApiParams && priceFeedIdOfSelectedPair) {
      setReadyToChart(true);
    }
  }, [priceApiParams, priceFeedIdOfSelectedPair, readyToChart]);

  useEffect(() => {
    if (!priceApiParams) {
      setReadyToChart(false);
    }
  }, [priceApiParams]);

  useEffect(() => {
    if (!readyToChart || !priceFeedIdOfSelectedPair || !state.online) return;

    if (typeof window !== "undefined" && window.TradingView) {
      const myWorker = new Worker("./setTimeout.js");

      // const locale = Intl.NumberFormat().resolvedOptions()
      //   .locale as LanguageCode;

      const timezone = Intl.DateTimeFormat().resolvedOptions()
        .timeZone as Timezone;

      const widgetOptions: ChartingLibraryWidgetOptions = {
        symbol: defaultProps.symbol as string,
        datafeed: datafeed(
          publicPriceEndpoint,
          priceApiParams,
          priceFeedIdOfSelectedPair,
          myWorker
        ),
        interval: defaultProps.interval,
        favorites: {
          chartTypes: defaultChartProps.favorites.chartTypes,
          intervals: defaultChartProps.favorites.intervals,
        },
        container: chartContainerRef.current,
        library_path: defaultProps.libraryPath as string,
        // locale: SUPPORTED_LANGUAGES.includes(locale) ? locale : "en",
        timezone,
        locale: "en",
        disabled_features: isMobile
          ? defaultChartProps.disabled_features.concat(disabledFeaturesOnMobile)
          : defaultChartProps.disabled_features,
        enabled_features: isMobile
          ? defaultChartProps.enabled_features.concat(enabledFeaturesOnMobile)
          : defaultChartProps.enabled_features,
        charts_storage_url: defaultProps.chartsStorageUrl,
        charts_storage_api_version: defaultProps.chartsStorageApiVersion,
        client_id: defaultProps.clientId,
        user_id: defaultProps.userId,
        fullscreen: defaultProps.fullscreen,
        autosize: defaultProps.autosize,
        studies_overrides: defaultProps.studiesOverrides,
        theme: defaultChartProps.theme,
        custom_formatters: {
          /**
           * TODO: 사이드 이펙트 확인 필요
           * * https://www.tradingview.com/charting-library-docs/latest/ui_elements/Price-Scale/#use-custom-formatting
           * * price에 들어오는 값이 어떤 값인지 확인 필요
           * * 현재는 모든 값(price만이 아니라)에 대해 임의로 수정
           */
          priceFormatterFactory: (symbolInfo) => {
            if (!symbolInfo) return null;
            return {
              format: (price) => {
                if (new BN(price).lt(0)) return Number(price).toFixed(8);
                if (new BN(price).lt(PRICE_DECIMAL_STANDARD / 1000))
                  return makeNdigits(price.toString());
                if (new BN(price).lt(PRICE_DECIMAL_STANDARD * 5))
                  return Number(price).toFixed(4);
                return Number(price).toFixed(2);
              },
            };
          },
        },
      };

      if (!widgetOptions.symbol) {
        myWorker.terminate();
        return;
      }

      tvWidgetRef.current = new window.TradingView.widget(widgetOptions);

      tvWidgetRef.current!.onChartReady(() => {
        setChartReady(true);
        tvWidgetRef.current!.headerReady().then(() => {
          /* add new button in TV header toolbar */
          // const button = tvWidget.createButton();
          // button.setAttribute('title', 'Click to show a notification popup');
          // button.classList.add('apply-common-tooltip');
          // button.addEventListener('click', () => tvWidget.showNoticeDialog({
          // 		title: 'Notification',
          // 		body: 'TradingView Charting Library API works correctly',
          // 		callback: () => {
          // 			console.log('Noticed!');
          // 		},
          // 	}));
          // button.innerHTML = 'Check API';
        });
      });
      return () => {
        tvWidgetRef.current?.remove();
        tvWidgetRef.current = null;
        myWorker.terminate();
        setChartReady(false);
      };
    }
    // eslint-disable-next-line
  }, [symbol, chainId, readyToChart, forexClosed, state.online]);

  return <div id="tv_chart_container" ref={chartContainerRef} />;
};
