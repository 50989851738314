import { formatUnits } from "@ethersproject/units";
import { pairsPricesAtom } from "atoms/exchange";
import { BigNumber } from "ethers";
import { trimPriceString } from "futures-domain/trades/utils";
import { CHART_PERIODS } from "futures-lib/legacy";
import { useSetAtom } from "jotai";

const channelToSubscription = new Map();

export default function useTVAdvancedStreaming() {
  const setPairsPrices = useSetAtom(pairsPricesAtom);

  // const intervalRef = useRef<ReturnType<typeof setInterval> | undefined>();
  function handleStreamingData(data) {
    const { id, p, t } = data;
    const tradePrice = p;
    const tradeTime = t * 1000; // Multiplying by 1000 to get milliseconds
    const channelString = id;

    const subscriptionItem = channelToSubscription.get(channelString);
    if (!subscriptionItem) {
      return;
    }

    const lastDailyBar = subscriptionItem.lastDailyBar;
    const nextDailyBarTime = getNextDailyBarTime(
      lastDailyBar.time,
      subscriptionItem.resolution
    );

    let bar;
    if (tradeTime >= nextDailyBarTime) {
      bar = {
        time: nextDailyBarTime,
        open: tradePrice,
        high: tradePrice,
        low: tradePrice,
        close: tradePrice,
      };
      // console.log('[stream] Generate new bar', bar)
    } else {
      bar = {
        ...lastDailyBar,
        high: Math.max(lastDailyBar.high, tradePrice),
        low: Math.min(lastDailyBar.low, tradePrice),
        close: tradePrice,
      };
      // console.log('[stream] Update the latest bar by price', tradePrice)
    }

    subscriptionItem.lastDailyBar = bar;

    // Send data to every subscriber of that symbol
    subscriptionItem.handlers.forEach((handler) => handler.callback(bar));

    channelToSubscription.set(channelString, subscriptionItem);
  }

  function startStreaming(
    ticker,
    publicPriceEndpoint,
    priceApiParams,
    priceFeedIdOfSelectedPair,
    myWorker: Worker
  ) {
    const url = `${publicPriceEndpoint}/latest_price_feeds?${priceApiParams}`;

    // intervalRef.current && clearInterval(intervalRef.current);
    const handleInterval = () => {
      fetch(url)
        .then((response) => response.json())
        .then((response) => {
          const priceObjs = {};
          const selectedPairPrice = response.find((res) =>
            priceFeedIdOfSelectedPair.includes(res.id)
          );
          if (selectedPairPrice) {
            handleStreamingData({
              id: ticker,
              p: trimPriceString(
                formatUnits(
                  BigNumber.from(selectedPairPrice.price.price),
                  Math.abs(selectedPairPrice.price.expo)
                )
              ),
              t: selectedPairPrice.price.publish_time,
            });
          }

          response.forEach((res) => {
            const { price, expo, conf, publish_time } = res.price;
            priceObjs[`0x${res.id}`] = {
              price,
              expo,
              conf,
              publish_time,
            };
          });
          setPairsPrices(priceObjs);
        })
        .catch((error) => {
          attemptReconnect(
            ticker,
            publicPriceEndpoint,
            priceApiParams,
            priceFeedIdOfSelectedPair,
            myWorker,
            3000
          );
          // console.log("fetch error");
          // setIsChartStreamingError(true);
        });
    };

    // intervalRef.current = setInterval(handleInterval, 1000);
    nextInterval(myWorker, handleInterval, 1000);
  }

  function attemptReconnect(
    ticker,
    publicPriceEndpoint,
    priceApiParams,
    priceFeedIdOfSelectedPair,
    myWorker,
    delay
  ) {
    setTimeout(() => {
      startStreaming(
        ticker,
        publicPriceEndpoint,
        priceApiParams,
        priceFeedIdOfSelectedPair,
        myWorker
      );
    }, delay);
  }

  function getNextDailyBarTime(barTime, period) {
    const date = new Date(barTime);

    if (period === "1M") {
      date.setMonth(date.getMonth() + 1);
      date.setDate(1);
      return date.getTime();
    } else {
      date.setTime(date.getTime() + CHART_PERIODS[period] * 1000);
      return date.getTime();
    }
  }

  function subscribeOnStream(
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscriberUID,
    onResetCacheNeededCallback,
    lastDailyBar,
    publicPriceEndpoint,
    priceApiParams,
    priceFeedIdOfSelectedPair,
    myWorker: Worker
  ) {
    const channelString = symbolInfo.ticker;
    const handler = {
      id: subscriberUID,
      callback: onRealtimeCallback,
    };
    let subscriptionItem = channelToSubscription.get(channelString);
    subscriptionItem = {
      subscriberUID,
      resolution,
      lastDailyBar,
      handlers: [handler],
    };
    channelToSubscription.set(channelString, subscriptionItem);
    //   console.log(
    //     '[subscribeBars]: Subscribe to streaming. Channel:',
    //     channelString
    //   )

    // Start streaming when the first subscription is made
    startStreaming(
      channelString,
      publicPriceEndpoint,
      priceApiParams,
      priceFeedIdOfSelectedPair,
      myWorker
    );
  }

  function unsubscribeFromStream(subscriberUID) {
    // Find a subscription with id === subscriberUID
    for (const channelString of channelToSubscription.keys()) {
      const subscriptionItem = channelToSubscription.get(channelString);
      const handlerIndex = subscriptionItem.handlers.findIndex(
        (handler) => handler.id === subscriberUID
      );

      if (handlerIndex !== -1) {
        // Unsubscribe from the channel if it is the last handler
        //   console.log(
        //     '[unsubscribeBars]: Unsubscribe from streaming. Channel:',
        //     channelString
        //   )
        channelToSubscription.delete(channelString);
        // intervalRef.current && clearInterval(intervalRef.current);
        break;
      }
    }
  }

  return {
    subscribeOnStream,
    unsubscribeFromStream,
  };
}

function nextInterval(myWorker: Worker, func: () => void, delay: number) {
  // console.log("nextInterval");

  myWorker.postMessage(delay);
  myWorker.onmessage = function () {
    func();
    // console.log("func() is called");
    nextInterval(myWorker, func, delay); // repeat
  };
}
