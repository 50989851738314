import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function useScrollToTop() {
  const location = useLocation();

  // TODO: 마이그레이션 한 코드 동작 확인
  // 페이지(ROUTE) 이동 시 스크롤이 아래에 있었더라도 새 페이지에서는 최 상단으로 올려줌
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
}
