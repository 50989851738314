import Portal from "../Common/Portal";
import ModalSkeleton, { ModalProps } from "./ModalSkeleton";

export default function ModalSkeletonWithPortal(props: ModalProps) {
  return (
    <Portal>
      <ModalSkeleton {...props} />
    </Portal>
  );
}
