import { ApolloClient, InMemoryCache, gql, useQuery } from "@apollo/client";
import { formatUnits, parseUnits } from "@ethersproject/units";
import {
  closeTradesAtom,
  errorGetTraderFromSubgraphAtom,
  isCloseTradesLoadingAtom,
  isOpenOrdersLoadingAtom,
  isOpenTradesLoadingAtom,
  isPendingOrdersLoadingAtom,
  openContractOrdersAtom,
  openTradesAtom,
  pairsAtom,
  pendingOrdersAtom,
  selectedPairAtom,
} from "atoms/exchange";
import { traderDepositedAtom } from "atoms/vault";
import BN from "bignumber.js";
import {
  ADDED_DECIMALS,
  GAMBIT_USD_DECIMALS,
  confMultiplierDecimals,
} from "components/Exchange/constants";
import { getContract } from "config/contracts";
import { getToken } from "config/tokens";
import { BigNumber, ethers } from "ethers";
import { useChainId } from "futures-lib/chains";
import { formatAmount } from "futures-lib/numbers";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useEffect, useMemo, useState } from "react";
import {
  CloseTrade,
  // Order,
  Pair,
  // PendingOrder,
  SubgraphCloseTrade,
  SubgraphCollateral,
  // SubgraphOrder,
  SubgraphPair,
  SubgraphTrade,
  Trade,
} from "./types";
import {
  comma1000,
  getClosePnL,
  getClosingFee,
  getOpeningFeeFromPay,
  getPairFromSubgraphPairs,
  trimPriceBN,
} from "./utils";
// import pairsFromContract from "@changerio/futures-contracts/data/GambitPairsStorageV1-pairs.json";
import { GambitTradingV1 } from "@changerio/futures-contracts/dist/typechain-types";
import { Trans, t } from "@lingui/macro";
import {
  apolloClientAtom,
  connectedSubgraphBlockNumberAtom,
  connectedSubgraphUrlAtom,
} from "atoms";
import cx from "classnames";
import { profitable as _profitable } from "futures-domain/trades/utils";
import { newContractFetcher } from "futures-lib/contracts";
import { useGetContract } from "futures-lib/contracts/contract";
import { helperToast } from "futures-lib/helperToast";
import useWallet from "futures-lib/wallets/useWallet";
import { toast } from "react-toastify";
import { usePrevious } from "react-use";
import useSWR from "swr";
import { useNetwork } from "wagmi";

// inverted pair(feedCalculation 1)는 컨트랙트에 들어간 from / to를 프론트엔드에서 뒤집어서 보여준다.
// const invertedPairs = pairsFromContract.filter((pair) => pair.feed.feedCalculation === 1);

// NOTE: 주소 문자열을 소문자로 넘겨야 subgraph에서 응답을 준다
export function useTrader() {
  const { chainId } = useChainId();
  const { chain: walletChain } = useNetwork();
  const { account } = useWallet();

  const query = useMemo(() => {
    return account
      ? gql(`
          query GetTrader($account: ID!) {
            _meta {
              block {
                number
              }
            }
            pairs {
              id
              from
              to
              openInterest {
                long
                max
                short
              }
              fundingFee {
                accPerOiLong
                accPerOiShort
              }
              name
              group {
                id
                minLeverage
                maxLeverage
              }
              param {
                rolloverFeePerBlockP
                fundingFeePerBlockP
              }
              fee {
                openFeeP
                closeFeeP
                minLevPosUsdc
                oracleFee
              }
              feed {
                priceId1
              }
              confMultiplierP
            }
            collaterals {
              collateralLong
              collateralMax
              collateralShort
              id
            }

            trader(id: $account) {
              depositShare
              depositAsset
              openTrades {
                trade {
                  buy
                  index
                  leverage
                  openPrice
                  pairIndex
                  positionSizeUsdc
                  initialPosUsdc
                  sl
                  tp
                }
              }
            }
            closeTrades(
              where: {trader: $account}
              orderBy: timestamp
              orderDirection: desc
              first: 30
            ) {
              reason
              trade {
                buy
                index
                leverage
                openPrice
                pairIndex
                positionSizeUsdc
                initialPosUsdc
                tp
                sl
              }
              percentProfit
              closePrice
              usdcSentToTrader
              timestamp
            }
          }
        `)
      : gql(`
          query GetTrader {
            _meta {
              block {
                number
              }
            }
            pairs {
              id
              from
              to
              openInterest {
                long
                max
                short
              }
              fundingFee {
                accPerOiLong
                accPerOiShort
              }
              name
              group {
                id
                minLeverage
                maxLeverage
              }
              param {
                rolloverFeePerBlockP
                fundingFeePerBlockP
              }
              fee {
                openFeeP
                closeFeeP
                minLevPosUsdc
                oracleFee
              }
              feed {
                priceId1
              }
              confMultiplierP
            }
            collaterals {
              collateralLong
              collateralMax
              collateralShort
              id
            }
          }
        `);
  }, [account]);

  // const setPairs = useSetAtom(pairsAtom);
  // const account = "0xF325C25A8bC3c3bc66147e7a0da61371B136a44f".toLowerCase();

  const [pairs, setPairs] = useAtom(pairsAtom);
  const setSelectedPair = useSetAtom(selectedPairAtom);
  const [openTrades, setOpenTrades] = useAtom(openTradesAtom);
  const setOpenContractOrders = useSetAtom(openContractOrdersAtom);
  const setErrorGetTraderFromSubgraph = useSetAtom(
    errorGetTraderFromSubgraphAtom
  );
  const setPendingOrders = useSetAtom(pendingOrdersAtom);
  const setIsOpenTradesLoading = useSetAtom(isOpenTradesLoadingAtom);
  const setIsOpenOrdersLoading = useSetAtom(isOpenOrdersLoadingAtom);
  const setIsPendingOrdersLoading = useSetAtom(isPendingOrdersLoadingAtom);
  const [closeTrades, setCloseTrades] = useAtom(closeTradesAtom);
  const setIsCloseTradesLoading = useSetAtom(isCloseTradesLoadingAtom);

  const setTraderDeposited = useSetAtom(traderDepositedAtom);

  // const [openTradesForNoti, setOpenTradesForNoti] = useState();
  const prevOpenTrades = usePrevious(openTrades);

  // const [closedTradesForNoti, setClosedTradesForNoti] = useState();
  const prevClosedTrades = usePrevious(closeTrades);

  // const selectedPair = useAtomValue(selectedPairAtom);
  // const setSelectedPairOiAndGroupCollateral = useSetAtom(selectedPairOiAndGroupCollateralAtom);

  const connectedSubgraphUrl = useAtomValue(connectedSubgraphUrlAtom);
  const apolloClient = useAtomValue(apolloClientAtom);

  const { loading, error, data } = useQuery(query, {
    skip: connectedSubgraphUrl === null || !apolloClient,
    client: apolloClient
      ? apolloClient
      : new ApolloClient({
          cache: new InMemoryCache(),
        }),
    notifyOnNetworkStatusChange: true, // TODO: check, position 가져올 때 주석처리해도 되는 지 확인
    pollInterval: 1000 * 4, // 4초
    variables: {
      // NOTE: 주소 문자열을 소문자로 넘겨야 subgraph에서 응답을 준다
      account: account ? account.toLowerCase() : null,
    },
    onError: () => {
      // NOTE: errorGetTraderFromSubgraph -> true로 변경해서 자동으로 Contract call
      setErrorGetTraderFromSubgraph(true);
      setCloseTrades([]);
      setPairs([]);
    },
    onCompleted: () => {
      // NOTE: errorGetTraderFromSubgraph -> false로 변경해서 Contract call 방지
      setErrorGetTraderFromSubgraph(false);
    },
    context: { clientName: chainId },
  });

  const fromTokenAddress = getContract(chainId, "CollateralToken");
  const fromToken = getToken(chainId, fromTokenAddress);
  const setConnectedSubgraphBlockNumber = useSetAtom(
    connectedSubgraphBlockNumberAtom
  );

  const tradingContract = useGetContract("GambitTradingV1") as GambitTradingV1;
  const { data: delegationFeeThresholdMultiplier } = useSWR<BigNumber>(
    tradingContract && [
      tradingContract,
      "delegationFeeThresholdMultiplier",
      [],
    ],
    {
      fetcher: newContractFetcher,
      shouldRetryOnError: true,
      errorRetryInterval: 3000,
      refreshInterval: 3000,
    }
  );

  // * minLevPosUsdc * delegationFeeThresholdMultiplier
  // * e.g) 2500 * 3 = 7500
  // const delegationFeeThreshhold = useDelegationFeeThreshhold(
  //   selectedPair?.fee.minLevPosUsdc,
  //   delegationFeeThresholdMultiplier
  // );

  useEffect(() => {
    if (connectedSubgraphUrl === null) {
      // NOTE: errorGetTraderFromSubgraph -> true로 변경해서 자동으로 Contract call
      setErrorGetTraderFromSubgraph(true);
    } else {
      if (!error && !loading && data) {
        const {
          _meta: {
            block: { number: blockNumber },
          },
          pairs: _pairs,
          collaterals,
        } = data;

        // const pairs = [..._pairs].sort((a, b) => {
        //   if (+a.group.id > +b.group.id) {
        //     return 1;
        //   } else if (+a.group.id < +b.group.id) {
        //     return -1;
        //   } else {
        //     // * groupd.id가 같은 경우
        //     if (+a.id > +b.id) {
        //       return 1;
        //     } else {
        //       return -1;
        //     }
        //   }
        // });

        const pairs = sortPairs(_pairs);

        if (blockNumber) {
          // TODO: 테스트, 삭제 예정
          // setConnectedSubgraphBlockNumber(
          //   connectedSubgraphUrl === SUBGRAPH_URLS[chainId][1] ? blockNumber - 300 : blockNumber
          // );
          // setConnectedSubgraphBlockNumber(blockNumber - 300);
          setConnectedSubgraphBlockNumber(blockNumber);
        }
        if (pairs) {
          const parsedPairs: Pair[] = pairs.map((_pair: SubgraphPair) => {
            const from = _pair.from;
            const to = _pair.to;

            // INVERTED pair인 경우에는 from / to를 바꿔준다
            // for (const invertedPair of invertedPairs) {
            //   if (invertedPair.from === _pair.from && invertedPair.to === _pair.to) {
            //     [from, to] = [to, from];
            //   }
            // }

            // pyth-network에서 가격 가져오기 위해 사용할 ID 찾는 로직
            // const priceFeedIds = getConstant(chainId, "priceFeedIds");
            // const priceFeedId = priceFeedIds.find((priceFeedId) => {
            //   const symbolPieces = priceFeedId.Symbol.split(".");
            //   const simpleSymbol = symbolPieces[symbolPieces.length - 1]; // "Crypto.BTC/USD" -> BTC/USD 로 만들어서 찾으려고

            //   return simpleSymbol === `${from}/${to}`;
            // });

            const _collateral: SubgraphCollateral = collaterals.find(
              (collateral: SubgraphCollateral) =>
                collateral.id === _pair.group.id
            );

            const pair: Pair = {
              groupIndex: +_pair.group.id,
              from,
              to,
              pairIndex: +_pair.id,
              priceFeedId: _pair.feed.priceId1
                ? _pair.feed.priceId1
                : ethers.constants.AddressZero,
              tvSymbol: `PYTH:${from}${to}`,
              openInterest: _pair.openInterest
                ? {
                    long: BigNumber.from(_pair.openInterest.long),
                    short: BigNumber.from(_pair.openInterest.short),
                    max: BigNumber.from(_pair.openInterest.max),
                  }
                : undefined,
              fundingFee: {
                accPerOiLong: _pair.fundingFee?.accPerOiLong,
                accPerOiShort: _pair.fundingFee?.accPerOiShort,
              },
              collateral: _collateral
                ? {
                    collateralLong: BigNumber.from(_collateral.collateralLong),
                    collateralShort: BigNumber.from(
                      _collateral.collateralShort
                    ),
                    collateralMax: BigNumber.from(_collateral.collateralMax),
                  }
                : undefined,

              param: {
                rolloverFeePerBlockP: _pair.param?.rolloverFeePerBlockP
                  ? BigNumber.from(_pair.param.rolloverFeePerBlockP)
                  : undefined,
                fundingFeePerBlockP: _pair.param?.fundingFeePerBlockP
                  ? BigNumber.from(_pair.param.fundingFeePerBlockP)
                  : undefined,
              },
              fee: {
                openFeeP: formatUnits(_pair.fee.openFeeP, GAMBIT_USD_DECIMALS),
                closeFeeP: formatUnits(
                  _pair.fee.closeFeeP,
                  GAMBIT_USD_DECIMALS
                ),
                minLevPosUsdc: formatUnits(
                  _pair.fee.minLevPosUsdc,
                  fromToken.decimals
                ),
                oracleFee: formatUnits(_pair.fee.oracleFee, fromToken.decimals),
              },
              group: {
                minLeverage: formatUnits(
                  _pair.group.minLeverage,
                  ADDED_DECIMALS
                ),
                maxLeverage: formatUnits(
                  _pair.group.maxLeverage,
                  ADDED_DECIMALS
                ),
              },
              confMultiplierP: +formatUnits(
                _pair.confMultiplierP,
                confMultiplierDecimals
              ),
            };

            return pair;
          });

          setPairs(
            parsedPairs.filter(
              (pair) => !(pair.from === "USD" && pair.to === "USD")
            )
          );
        } else {
          setPairs([]);
        }

        if (account === null || account === undefined || data.trader === null) {
          setOpenTrades([]);
          setOpenContractOrders([]);
          setCloseTrades([]);
          setPendingOrders([]);
        } else {
          const {
            pairs,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            trader: { depositShare, depositAsset, openTrades },
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            closeTrades,
          } = data;

          // * Pending Noti 제거를 위한 state

          setTraderDeposited({
            depositAsset: BigNumber.from(depositAsset),
            depositShare: BigNumber.from(depositShare),
          });

          setOpenTrades((ot) => {
            return openTrades.map((openTrade: { trade: SubgraphTrade }) => {
              const {
                pairIndex,
                index,
                initialPosUsdc,
                positionSizeUsdc,
                openPrice,
                buy,
                leverage,
                tp,
                sl,
              } = openTrade.trade;

              const pair: SubgraphPair = pairs.find(
                (pair: SubgraphPair) => pair.id === pairIndex
              );
              // console.log(pair.fee.openFeeP);
              // console.log(pair);
              // console.log(getPair(pairs, +pairIndex));

              const result: Trade = {
                pairIndex: +pairIndex,
                positionIndex: index,
                // positionSizeUsdc: BigNumber.from(positionSizeUsdc),
                initialPosUsdc: BigNumber.from(initialPosUsdc || 0),
                positionSizeUsdc:
                  // * pair.fee.openFeeP === "1" 이면 opening fee 0 -> .99 반올림
                  pair.fee.openFeeP === "1"
                    ? parseUnits(
                        new BN(
                          formatUnits(positionSizeUsdc, fromToken.decimals)
                        ).toFixed(2, BN.ROUND_HALF_UP),
                        fromToken.decimals
                      )
                    : BigNumber.from(positionSizeUsdc),
                openPrice: BigNumber.from(openPrice), // * 일반적인 경우
                buy,
                leverage: +formatUnits(leverage, ADDED_DECIMALS),
                tp: BigNumber.from(tp),
                sl: BigNumber.from(sl),
                pair: getPairFromSubgraphPairs(pairs, pairIndex, fromToken),
                activeTradeStatus:
                  ot?.find(
                    (t) =>
                      t.positionIndex === index && t.pairIndex === +pairIndex
                  )?.activeTradeStatus || "SHOWN",
              };

              return result;
            });
          });

          setCloseTrades((prev) => {
            if (
              !prev ||
              prev.length === 0 ||
              (closeTrades && prev[0].timestamp !== closeTrades[0].timestamp)
            ) {
              return closeTrades.map((closeTrade: SubgraphCloseTrade) => {
                const {
                  closePrice,
                  usdcSentToTrader,
                  percentProfit,
                  reason,
                  timestamp,
                  trade,
                } = closeTrade;

                const pair: SubgraphPair = pairs.find(
                  (pair: SubgraphPair) => pair.id === trade.pairIndex
                );

                const positionSize = BigNumber.from(trade.positionSizeUsdc).mul(
                  BigNumber.from(trade.leverage)
                );

                let openingFee = "";
                let closingFee = "";
                let includeNetworkFee = false;
                const formattedCloseFeeP = +formatUnits(
                  pair.fee.closeFeeP,
                  GAMBIT_USD_DECIMALS
                );

                if (pair) {
                  // console.log(_positionSizeUsdc);
                  const {
                    openingFee: _openingFee,
                    includeNetworkFee: _includeNetworkFee,
                  } =
                    getOpeningFeeFromPay(
                      pair,
                      trade,
                      fromToken,
                      delegationFeeThresholdMultiplier
                    ) || "";
                  openingFee = _openingFee;
                  includeNetworkFee = _includeNetworkFee;
                  closingFee = formatAmount(
                    getClosingFee(formattedCloseFeeP, positionSize),
                    fromToken.decimals,
                    2,
                    false
                  );
                  // console.log(`formattedOpenFeeP:     ${formattedOpenFeeP}`);
                  // console.log(`formattedCloseFeeP:    ${formattedCloseFeeP}`);
                  // console.log(`openingFeeNumerator:   ${openingFeeNumerator}`);
                  // console.log(`openingFeeDenominator: ${openingFeeDenominator}`);
                  // console.log(`openingFee:            ${openingFee}`);
                  // console.log(`closingFee:            ${closingFee}`);
                }

                const result: CloseTrade = {
                  closePrice: BigNumber.from(closePrice),
                  usdcSentToTrader: BigNumber.from(usdcSentToTrader),
                  percentProfit: BigNumber.from(percentProfit),
                  reason,
                  timestamp,
                  trade: {
                    pairIndex: +trade.pairIndex,
                    positionIndex: trade.index,
                    initialPosUsdc: BigNumber.from(trade.initialPosUsdc || 0),
                    positionSizeUsdc:
                      // * pair.fee.openFeeP === "1" 이면 opening fee 0 -> .99 반올림
                      pair.fee.openFeeP === "1"
                        ? parseUnits(
                            new BN(
                              formatUnits(
                                trade.positionSizeUsdc,
                                fromToken.decimals
                              )
                            ).toFixed(2, BN.ROUND_HALF_UP),
                            fromToken.decimals
                          )
                        : BigNumber.from(trade.positionSizeUsdc), // * 일반적인 경우
                    openPrice: BigNumber.from(trade.openPrice),
                    buy: trade.buy,
                    leverage: +formatUnits(trade.leverage, ADDED_DECIMALS),
                    tp: BigNumber.from(trade.tp),
                    sl: BigNumber.from(trade.sl),
                    pair: getPairFromSubgraphPairs(
                      pairs,
                      trade.pairIndex,
                      fromToken
                    ),
                  },
                  openingFee,
                  closingFee,
                  includeNetworkFee,
                };
                // console.log(result.trade.positionSizeUsdc.toString());

                return result;
              });
            }

            return prev;
          });
        }
      }
      if (loading) {
        setIsOpenTradesLoading(true);
        setIsOpenOrdersLoading(true);
        setIsPendingOrdersLoading(true);
        setIsCloseTradesLoading(true);
      } else {
        setIsOpenTradesLoading(false);
        setIsOpenOrdersLoading(false);
        setIsPendingOrdersLoading(false);
        setIsCloseTradesLoading(false);
      }
    }
  }, [
    account,
    chainId,
    connectedSubgraphUrl,
    data,
    delegationFeeThresholdMultiplier,
    error,
    fromToken,
    fromToken.decimals,
    loading,
    setCloseTrades,
    setConnectedSubgraphBlockNumber,
    setErrorGetTraderFromSubgraph,
    setIsCloseTradesLoading,
    setIsOpenOrdersLoading,
    setIsOpenTradesLoading,
    setIsPendingOrdersLoading,
    setOpenContractOrders,
    setOpenTrades,
    setPairs,
    setPendingOrders,
    setTraderDeposited,
  ]);

  useEffect(() => {
    // const key = JSON.stringify([`"${chainId}"`, "Selected-pair"]);
    const key = JSON.stringify([walletChain?.id.toString(), "Selected-pair"]);
    const _selectedPair = window.localStorage.getItem(key)
      ? JSON.parse(window.localStorage.getItem(key)!)
      : null;

    const _pair = pairs.find(
      (_pair) => _pair?.pairIndex === _selectedPair?.pairIndex
    );
    if (_pair) {
      setSelectedPair(_pair, chainId);
    }
    // if (_pair && JSON.stringify(_pair) !== JSON.stringify(selectedPair)) {
    //   console.log(2);
    //   setSelectedPair(_pair);
    // }
  }, [chainId, pairs, setSelectedPair, walletChain?.id]);

  const [pendingNotiInitialzed, setPendingNotiInitialzed] = useState(false);

  // * Pending Noti 처리 fallback (event를 못 받는 경우가 있어서)
  useEffect(() => {
    // * case: open
    if (JSON.stringify(openTrades) !== JSON.stringify(prevOpenTrades)) {
      const newOpenTrades = openTrades?.filter((trade) => {
        return !prevOpenTrades
          ?.map((prevOpenTrade) => getTradeKey(prevOpenTrade))
          ?.includes(getTradeKey(trade));
      });
      if (!newOpenTrades) return;

      if (!pendingNotiInitialzed) {
        setPendingNotiInitialzed(true);
        return;
      }

      // console.log("-------------");
      // console.log("openTrades");
      // console.log(openTrades);
      // console.log("prevOpenTrades");
      // console.log(prevOpenTrades);

      // console.log("newOpenTrades");
      // console.log(newOpenTrades);
      // console.log("-------------");

      for (const newTrade of newOpenTrades) {
        if (
          toast.isActive(
            `open:${newTrade.buy}:${newTrade.pair.from}:${newTrade.pair.to}`
          )
        ) {
          toast.dismiss(
            `open:${newTrade.buy}:${newTrade.pair.from}:${newTrade.pair.to}`
          );

          helperToast.success(
            <div>
              <div className="toastify-title">
                <span>
                  <Trans>Open Trade</Trans>
                </span>
                <span> ({newTrade.buy ? t`LONG` : t`SHORT`})</span>
              </div>
              <div className="toastify-body">
                {newTrade.pair.from}/{newTrade.pair.to} @{" "}
                {comma1000(trimPriceBN(newTrade.openPrice))}
              </div>
            </div>,
            {
              autoClose: 7000,
            }
          );
        }
      }
    }

    // * case: close
    if (
      JSON.stringify(closeTrades?.slice(0, 10)) !==
      JSON.stringify(prevClosedTrades?.slice(0, 10))
    ) {
      const newClosedTrades = closeTrades?.filter((trade) => {
        return !prevClosedTrades
          ?.map((prevClosedTrade) => getClosedTradeKey(prevClosedTrade))
          ?.includes(getClosedTradeKey(trade));
      });
      if (!newClosedTrades) return;

      if (!pendingNotiInitialzed) {
        setPendingNotiInitialzed(true);
        return;
      }

      // console.log("-------------");
      // console.log("closeTrades");
      // console.log(closeTrades);
      // console.log("prevClosedTrades");
      // console.log(prevClosedTrades);

      // console.log("newClosedTrades");
      // console.log(newClosedTrades);
      // console.log("-------------");

      for (const newTrade of newClosedTrades) {
        if (
          toast.isActive(
            `close:${newTrade.trade.buy}:${newTrade.trade.pair.from}:${newTrade.trade.pair.to}`
          )
        ) {
          toast.dismiss(
            `close:${newTrade.trade.buy}:${newTrade.trade.pair.from}:${newTrade.trade.pair.to}`
          );

          const profitable = _profitable(
            newTrade.trade.positionSizeUsdc,
            newTrade.usdcSentToTrader,
            newTrade.trade.buy
          );

          helperToast.success(
            <div>
              <div className="toastify-title">
                <Trans>Close Trade</Trans>
              </div>
              <div className="toastify-body">
                {newTrade.trade.pair.from}/{newTrade.trade.pair.to} @{" "}
                {comma1000(trimPriceBN(newTrade.trade.openPrice))}
              </div>
              <div
                className={cx(
                  "text-[1.4rem]",
                  profitable ? "text-green-2" : "text-red-2"
                )}
              >
                <span>
                  {formatAmount(
                    newTrade.usdcSentToTrader.sub(
                      newTrade.trade.positionSizeUsdc
                    ),
                    fromToken.decimals,
                    2,
                    true
                  )}{" "}
                  {fromToken.symbol}{" "}
                </span>
                <span>
                  (
                  {getClosePnL(
                    newTrade.trade.positionSizeUsdc,
                    newTrade.usdcSentToTrader,
                    newTrade.trade.buy,
                    fromToken.decimals
                  )}
                  %)
                </span>
              </div>
            </div>,
            {
              autoClose: 7000,
            }
          );
        }
      }
    }
  }, [
    closeTrades,
    fromToken.decimals,
    fromToken.symbol,
    openTrades,
    pendingNotiInitialzed,
    prevClosedTrades,
    prevOpenTrades,
  ]);
}

const getTradeKey = (trade: Trade) => {
  return `${trade.pairIndex}:${trade.positionIndex}:${
    trade.buy
  }:${trade.openPrice.toString()}`;
};

const getClosedTradeKey = (trade: CloseTrade) => {
  return `${trade.trade.pairIndex}:${trade.trade.positionIndex}:${
    trade.trade.buy
  }:${trade.trade.openPrice.toString()}`;
};

// * 페어 보여 줄 순서
const PAIR_ORDERS = [
  "BTC/USD",
  "ETH/USD",
  "ETH/BTC",
  "ARB/USD",
  "MATIC/USD",
  "SOL/USD",
  "DOGE/USD",
  "LINK/USD",
  "UNI/USD",
  "SEI/USD",
  "WLD/USD",
  "EUR/USD",
  "USD/JPY",
  "GBP/USD",
  "USD/CNH",
];

const sortPairs = (pairs: SubgraphPair[]) => {
  try {
    return [...pairs].sort((a, b) => {
      // * 외환을 뒤로 보냄
      if (a.group.id === "1" || b.group.id === "1") {
        if (a.group.id === "1" && b.group.id === "1") {
          return 0;
        } else if (a.group.id === "1") {
          return 1;
        } else if (b.group.id === "1") {
          return -1;
        }
      }

      const aPairIndex = PAIR_ORDERS.indexOf(`${a.from}/${a.to}`);
      const bPairIndex = PAIR_ORDERS.indexOf(`${b.from}/${b.to}`);

      if (aPairIndex === -1 && bPairIndex === -1) {
        return 0;
      } else if (aPairIndex === -1) {
        return 1;
      } else if (bPairIndex === -1) {
        return -1;
      }

      return aPairIndex - bPairIndex;
    });
  } catch (e) {
    return pairs;
  }
};
