import * as Dialog from "@radix-ui/react-dialog";
import {
  selectedWeb3authConnectorAtom,
  showEmailAccountModalAtom,
  showSignInModalAtom,
  termsAgreementModalVisibleAtom,
} from "atoms";
import cx from "classnames";
import "components/Common/radix-ui-dialog.scss";
import { CHECK_TERMS_AND_CONDITIONS_AGREEMENT } from "config/localStorage";
import useWeb3AuthWallet from "futures-lib/wallets/gambit-web3-auth/useWeb3AuthWallet";
import useTermsConnectModal from "futures-lib/wallets/useTermsConnectModal";
import useWallet from "futures-lib/wallets/useWallet";
import { useAtom, useSetAtom } from "jotai";
import { useCallback, useEffect } from "react";
import { Connector, useConnect } from "wagmi";
import SignInModalLayout from "./SignInModalLayout";

const SignInModal = () => {
  const { account } = useWallet();
  const [showSignInModal, setShowSignInModal] = useAtom(showSignInModalAtom);
  const { openConnectModal } = useTermsConnectModal();
  const { connect } = useConnect();
  const {
    googleWeb3authConnector,
    kakaoWeb3authConnector,
    appleWeb3authConnector,
    discordWeb3authConnector,
    twitterWeb3authConnector,
  } = useWeb3AuthWallet();

  const setShowEmailAccountModal = useSetAtom(showEmailAccountModalAtom);
  const setSelectedWeb3authConnector = useSetAtom(selectedWeb3authConnectorAtom);

  useEffect(() => {
    if (account) {
      setShowSignInModal(false);
    }
  }, [account, setShowSignInModal]);

  const setTermsAgreementModalVisible = useSetAtom(termsAgreementModalVisibleAtom);

  const socialLoginConnect = useCallback(
    (connector: Connector<any, any> | undefined) => {
      // termsAgreementModalCheckAndConnect(connector);
      let checkedTermsAndConditions = window.localStorage.getItem(CHECK_TERMS_AND_CONDITIONS_AGREEMENT);
      if (checkedTermsAndConditions) checkedTermsAndConditions = JSON.parse(checkedTermsAndConditions);

      if (checkedTermsAndConditions) {
        connect({ connector });
        return;
      }
      setShowSignInModal(false);
      setSelectedWeb3authConnector(connector);
      setTermsAgreementModalVisible(true);
    },
    [connect, setSelectedWeb3authConnector, setShowSignInModal, setTermsAgreementModalVisible]
  );

  return (
    <Dialog.Root open={showSignInModal} onOpenChange={setShowSignInModal}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <SignInModalLayout>
          <Dialog.Content
            className={cx(
              "font-space-grotesk",
              "w-full bottom-0",
              "xxs+:w-[39.8rem] xxs+:!top-[6.8rem] xxs+:!bottom-auto xxs+:bg-gray-90",
              "xs-:!right-0",
              "mx-0 xs:mx-[1.5rem]",
              "z-[99] absolute rounded-[1rem] focus-visible:outline-none"
            )}
          >
            <div className="main-container flex w-full xxs+:w-[39.8rem] pt-[1.6rem] pb-[2.4rem] px-[2rem] flex-col gap-[2rem] items-center xxs+:bg-gray-90 bg-gray-70 xxs+:rounded-[1.2rem] rounded-t-[1.2rem] border-solid xxs+:border border-[rgba(255,255,255,0.1)] mx-auto my-0">
              <div className="flex w-full justify-between items-center">
                <span className="h-[2.6rem] xxs+:text-[2rem] text-[1.7rem] font-medium leading-[25.52px] text-[#fff] text-left">
                  Sign in
                </span>
                <div
                  className="w-[2.4rem] h-[2.4rem] bg-[url(img/ic-modal-close.png)] bg-cover overflow-hidden cursor-pointer"
                  onClick={() => {
                    setShowSignInModal(false);
                  }}
                />
              </div>
              <div className="flex w-full flex-col gap-[1.2rem] items-center">
                <span className="h-[1.5rem] text-[1.2rem] font-medium leading-[1.5rem] text-white text-opacity-50 text-left">
                  Continue with
                </span>
                <div className="flex w-full justify-between items-start">
                  <div
                    onClick={() => socialLoginConnect(googleWeb3authConnector)}
                    className="cursor-pointer xxs+:w-[5rem] xxs+:h-[5rem] w-[4.6rem] h-[4.6rem] bg-[url(img/ic-social-google.png)] bg-cover rounded-[36.957px]  "
                  />
                  <div
                    onClick={() => socialLoginConnect(appleWeb3authConnector)}
                    className="cursor-pointer xxs+:w-[5rem] xxs+:h-[5rem] w-[4.6rem] h-[4.6rem] bg-[url(img/ic-social-apple.png)] bg-cover "
                  />
                  <div
                    onClick={() => socialLoginConnect(twitterWeb3authConnector)}
                    className="cursor-pointer xxs+:w-[5rem] xxs+:h-[5rem] w-[4.6rem] h-[4.6rem] bg-[url(img/ic-social-x.png)] bg-cover "
                  />
                  <div
                    onClick={() => socialLoginConnect(discordWeb3authConnector)}
                    className="cursor-pointer xxs+:w-[5rem] xxs+:h-[5rem] w-[4.6rem] h-[4.6rem] bg-[url(img/ic-social-discord.png)] bg-cover "
                  />
                  <div
                    onClick={() => socialLoginConnect(kakaoWeb3authConnector)}
                    className="cursor-pointer xxs+:w-[5rem] xxs+:h-[5rem] w-[4.6rem] h-[4.6rem] relative"
                  >
                    <div className="w-[3.2rem] h-[3.2rem] relative overflow-hidden mt-[1rem] mr-0 mb-0 ml-[9px]">
                      <div className="xxs+:w-[2.8rem] xxs+:h-[2.6rem] w-[2.6rem] h-[2.4rem] bg-[url(img/ic-social-kakao-1/2.png)] bg-[length:100%_100%] relative z-[13] xxs+:mt-[3px] mt-[1.75px] mr-0 mb-0 xxs+:ml-[2px] ml-[1.25px]" />
                    </div>
                    <div className="xxs+:w-[5rem] xxs+:h-[5rem] w-[4.6rem] h-[4.6rem] bg-[url(img/ic-social-kakao-2/2.png)] bg-cover rounded-[50%] absolute top-0 left-0 z-[11]" />
                  </div>
                  <div
                    onClick={() => {
                      // connect({ connector: emailWeb3authConnector })
                      setShowSignInModal(false);
                      setShowEmailAccountModal(true);
                    }}
                    className="cursor-pointer xxs+:w-[5rem] xxs+:h-[5rem] w-[4.6rem] h-[4.6rem] bg-[url(img/ic-social-email.png)] bg-[length:100%_100%]"
                  />
                </div>
              </div>
              <div className="flex w-full flex-col gap-[1.2rem] items-center">
                <span className="h-[1.5rem] text-[1.2rem] font-medium leading-[1.5rem] text-white text-opacity-50 text-left">
                  External Wallet
                </span>
                <div
                  onClick={() => {
                    if (openConnectModal) {
                      setShowSignInModal(false);
                      openConnectModal();
                    }
                  }}
                  className="flex w-full py-[0.8rem] px-[1.6rem] flex-col gap-[1rem] justify-center items-center xxs+:bg-gray-80 bg-gray-50 rounded-[1.2rem] cursor-pointer"
                >
                  <div className="flex justify-center gap-[0.8rem] items-center self-stretch">
                    <div className="flex gap-[0.8rem] justify-center items-center">
                      <div className="w-[3.6rem] h-[3.6rem] bg-[url(img/ic-social-metamask.png)] bg-cover" />
                      <span className="h-[2rem] text-[1.6rem] font-medium leading-[2rem] text-white text-left">
                        Continue with Wallet
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Dialog.Content>
        </SignInModalLayout>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default SignInModal;
