import { Trans } from "@lingui/macro";
import { LONG, SHORT } from "futures-lib/legacy";
import { formatAmount, formatStringAmount } from "futures-lib/numbers";

import {
  closeTradesAtom,
  isCloseTradesLoadingAtom,
  isVisibleCloseTradeModalAtom,
  selectedCloseTradeAtom,
  tooltipContentsAtom,
} from "atoms/exchange";
import { getContract } from "config/contracts";
import { getToken } from "config/tokens";
import {
  CloseTrade,
  DerivedCloseTrade,
  ParsedTrade,
} from "futures-domain/trades/types";
import { useChainId } from "futures-lib/chains";
import { useAtomValue, useSetAtom } from "jotai";

import { formatUnits } from "@ethersproject/units";
import { useLingui } from "@lingui/react";
import BN from "bignumber.js";
import cx from "classnames";
import Tooltip from "components/Tooltip/Tooltip";
import GasFreeTextForPositionTable from "components/ZeroFeeEvent/GasFreeTextForPositionTable";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { Token } from "futures-domain/tokens";
import {
  addPlusGt0,
  getReason,
  trimPriceString,
  unpadZero,
} from "futures-domain/trades/utils";
import useWallet from "futures-lib/wallets/useWallet";
import { ReactComponent as InfoCircleSvg } from "img/ic-info-circle.svg";
import { useCallback, useMemo, useState } from "react";
import { FaSortNumericDown } from "react-icons/fa";
import { GAMBIT_USD_DECIMALS } from "./constants";
// import Tooltip from "components/Tooltip/Tooltip";

export default function CloseTradesList() {
  dayjs.extend(localizedFormat);

  const { chainId } = useChainId();
  const { account } = useWallet();
  const [sortingType, setSortingType] = useState({
    type: "TIMESTAMP",
    direction: "DESC",
  });

  const isCloseTradesLoading = useAtomValue(isCloseTradesLoadingAtom);

  const getTradeKey = (trade: ParsedTrade) => {
    return `${trade.pairIndex}:${trade.positionIndex}:${
      trade.buy
    }:${trade.openPrice.toString()}:${
      trade.leverage
    }:${trade.sl.toString()}:${trade.tp.toString()}`;
  };

  const fromTokenAddress = getContract(chainId, "CollateralToken");
  const fromToken = getToken(chainId, fromTokenAddress);

  const closeTrades = useAtomValue(closeTradesAtom);

  const handleSort = useCallback(() => {
    if (sortingType.type === "PNL") {
      return (a: CloseTrade, b: CloseTrade) => {
        const pnlA = new BN(a.usdcSentToTrader.toString()).minus(
          a.trade.positionSizeUsdc.toString()
        );

        const pnlB = new BN(b.usdcSentToTrader.toString()).minus(
          b.trade.positionSizeUsdc.toString()
        );

        if (sortingType.direction === "DESC") {
          return pnlB.gte(pnlA) ? 1 : -1;
        } else {
          return pnlA.gte(pnlB) ? 1 : -1;
        }
      };
    } else if (sortingType.type === "PROFIT") {
      return (a: CloseTrade, b: CloseTrade) => {
        const profitPercentA = addPlusGt0(
          formatAmount(a.percentProfit, GAMBIT_USD_DECIMALS),
          6
        );
        const profitPercentB = addPlusGt0(
          formatAmount(b.percentProfit, GAMBIT_USD_DECIMALS),
          6
        );

        const positionSizeUsdcStringA = formatAmount(
          a.trade.positionSizeUsdc,
          fromToken.decimals,
          6,
          false
        );
        const positionSizeUsdcStringB = formatAmount(
          b.trade.positionSizeUsdc,
          fromToken.decimals,
          6,
          false
        );

        const profitValueA = new BN(positionSizeUsdcStringA)
          .multipliedBy(profitPercentA)
          .dividedBy(100);

        const profitValueB = new BN(positionSizeUsdcStringB)
          .multipliedBy(profitPercentB)
          .dividedBy(100);

        if (sortingType.direction === "DESC") {
          return profitValueB.gte(profitValueA) ? 1 : -1;
        } else {
          return profitValueA.gte(profitValueB) ? 1 : -1;
        }
      };
    } else if (sortingType.type === "TIMESTAMP") {
      return (a: CloseTrade, b: CloseTrade) => {
        if (sortingType.direction === "DESC") {
          return +b.timestamp - +a.timestamp;
        } else {
          return +a.timestamp - +b.timestamp;
        }
      };
    } else {
      return (a: CloseTrade, b: CloseTrade) => {
        return 0;
      };
    }
  }, [fromToken.decimals, sortingType.direction, sortingType.type]);
  // console.log(closeTrades);

  const sortedCloseTrades = useMemo(() => {
    if (!closeTrades || closeTrades.length === 0) return closeTrades;

    let _closeTrades = [...closeTrades];

    _closeTrades = _closeTrades.sort(handleSort());
    if (["PNL", "PROFIT"].includes(sortingType.type)) {
      return _closeTrades
        .filter((_closeTrade) => _closeTrade.reason !== "MARKET_OPEN_CANCELED")
        .concat(
          _closeTrades.filter(
            (_closeTrade) => _closeTrade.reason === "MARKET_OPEN_CANCELED"
          )
        );
    }
    return _closeTrades;
  }, [closeTrades, handleSort, sortingType.type]);

  const tooltipContents = useAtomValue(tooltipContentsAtom);

  // useEffect(() => {
  //   const prevCloseTrades = [];
  // }, [sortingType]);

  const handleSortingType = useCallback(
    (type: string) => {
      if (type === sortingType.type) {
        const direction = sortingType.direction === "DESC" ? "ASC" : "DESC";
        setSortingType({ type, direction });
      } else {
        setSortingType({ type, direction: "DESC" });
      }
    },
    [sortingType.direction, sortingType.type]
  );

  const setSelectedCloseTrade = useSetAtom(selectedCloseTradeAtom);

  const setIsVisibleCloseTradeModal = useSetAtom(isVisibleCloseTradeModalAtom);

  // Pair // Open Price // Close Price // Leverage // Collateral // PnL // %
  return (
    <div className="PositionsList">
      {/* {openTrades && ( */}
      <div className="Exchange-list">
        <div
          className={cx(
            "block bg-black-3 overflow-y-auto border border-white border-opacity-10 xs:rounded-[1.2rem]",
            tooltipContents
              ? "min-h-[26rem] max-h-[26rem]"
              : "min-h-[23.4rem] max-h-[23.4rem]"
          )}
        >
          <table className="Exchange-list whitespace-nowrap bg-black-3 text-[1.2rem] xs:rounded-[1.2rem]">
            <tbody>
              <tr className="z-[1] sticky top-0 bg-gray-80 rouned-[1.2rem] text-gray-15 font-medium">
                <th className="hover:underline">
                  <div
                    onClick={() => handleSortingType("TIMESTAMP")}
                    className="flex items-center cursor-pointer"
                  >
                    <Trans id="msg.closeList / Date">Date</Trans>
                    <FaSortNumericDown className="ml-[4px]" />
                  </div>
                </th>
                <th className="">
                  <Trans id="msg.positionList / Pair">Pair</Trans>
                </th>
                <th className="">
                  <Trans id="msg.positionList / Type">Type</Trans>
                </th>
                <th className="table-cell ">
                  <Trans>Leverage</Trans>
                </th>
                <th className="table-cell ">
                  <Trans>Open Price</Trans>
                </th>
                <th className="table-cell ">
                  <Trans>Close Price</Trans>
                </th>
                {/* <th className="table-cell ">
                  <Trans>Collateral</Trans>
                </th> */}

                <th className="table-cell hover:underline">
                  <div
                    onClick={() => handleSortingType("PROFIT")}
                    className="flex items-center cursor-pointer"
                  >
                    <Trans>Profit</Trans>
                    <FaSortNumericDown className="ml-[4px]" />
                  </div>
                </th>

                <th className="table-cell">
                  <Tooltip
                    handle={
                      <div className="flex items-center">
                        <Trans>Fee</Trans>
                        <InfoCircleSvg className="fill-gray-30 ml-[2px]" />
                      </div>
                    }
                    disableHandleStyle={true}
                    position="center-bottom"
                    tooltipClassName="w-[31rem]"
                    renderContent={() => (
                      <span className="text-[1.4rem] text-white font-medium">
                        <Trans>Fee includes closing fee and funding fee.</Trans>
                      </span>
                    )}
                  />
                </th>

                <th className="hover:underline">
                  <div
                    onClick={() => handleSortingType("PNL")}
                    className="flex items-center cursor-pointer"
                  >
                    <span>
                      <Trans id="msg.closeTradeList / Net PnL">Net PnL</Trans>
                    </span>
                    <FaSortNumericDown className="ml-[4px]" />
                  </div>
                </th>

                <th className="">
                  <Trans id="msg.closeTradeList / reason">Reason</Trans>
                </th>
              </tr>
              {!closeTrades && isCloseTradesLoading && (
                <tr className="text-gray-30">
                  <td colSpan={15}>
                    <div className="pt-[7rem] pb-[8.5rem] text-center">
                      Loading...
                    </div>
                  </td>
                </tr>
              )}
              {(!account || closeTrades?.length === 0) && (
                <tr className="text-gray-30">
                  <td colSpan={15}>
                    {/* <div className="pt-[7rem] pb-[8.5rem] text-center">
                      <Trans>Start your first trade!</Trans>
                    </div> */}
                    <div
                      className={cx(
                        "text-center",
                        tooltipContents ? "py-[4.3rem]" : "py-[3rem]"
                      )}
                    >
                      <GasFreeTextForPositionTable />
                    </div>
                  </td>
                </tr>
              )}

              {sortedCloseTrades?.map((closeTrade, index) => (
                <CloseTradesRow
                  key={`${index}${getTradeKey(closeTrade.trade)}`}
                  closeTrade={closeTrade}
                  fromToken={fromToken}
                  setSelectedCloseTrade={setSelectedCloseTrade}
                  setIsVisibleCloseTradeModal={setIsVisibleCloseTradeModal}
                />
              ))}
              <tr className="text-center">
                <td colSpan={10}>The last 30 trades are displayed.</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* )} */}
    </div>
  );
}

const CloseTradesRow = ({
  closeTrade,
  fromToken,
  setSelectedCloseTrade,
  setIsVisibleCloseTradeModal,
}: {
  closeTrade: CloseTrade;
  fromToken: Token;
  setSelectedCloseTrade: (arg: DerivedCloseTrade) => void;
  setIsVisibleCloseTradeModal: (arg: boolean) => void;
}) => {
  const trade = useMemo(() => {
    return closeTrade.trade;
  }, [closeTrade.trade]);

  const type = useMemo(() => {
    return trade.buy ? LONG : SHORT;
  }, [trade.buy]);

  const reason = useMemo(() => {
    return getReason(closeTrade.reason);
  }, [closeTrade.reason]);

  const initialPosUsdcString = useMemo(() => {
    return trade.initialPosUsdc.eq(0)
      ? "0"
      : formatAmount(trade.initialPosUsdc, fromToken.decimals, 6, false);
  }, [fromToken.decimals, trade.initialPosUsdc]);

  const positionSizeUsdcString = useMemo(() => {
    return formatAmount(trade.positionSizeUsdc, fromToken.decimals, 6, false);
  }, [fromToken.decimals, trade.positionSizeUsdc]);

  const openFeeAndNetworkFee = useMemo(() => {
    return trade.initialPosUsdc.eq(0)
      ? "0"
      : formatAmount(
          new BN(trade.initialPosUsdc.toString())
            .minus(trade.positionSizeUsdc.toString())
            .toFixed(0),
          fromToken.decimals,
          2,
          false
        );
  }, [fromToken.decimals, trade.initialPosUsdc, trade.positionSizeUsdc]);

  const usdcSentToTraderString = useMemo(() => {
    return formatAmount(
      closeTrade.usdcSentToTrader,
      fromToken.decimals,
      6,
      false
    );
  }, [closeTrade.usdcSentToTrader, fromToken.decimals]);

  const netPnL = useMemo(() => {
    return addPlusGt0(
      new BN(usdcSentToTraderString)
        .minus(
          new BN(initialPosUsdcString).gt(0)
            ? initialPosUsdcString
            : positionSizeUsdcString
        )
        .toFixed(2)
    );
  }, [initialPosUsdcString, positionSizeUsdcString, usdcSentToTraderString]);

  // INFO: 서브그래프에서 주는 closeTrade.percentProfit 값이 -100보다 큰 경우가 존재(청산되는 경우 중 일부)하는데 이 때 최대 손실을 -100%로 표기
  const _percentProfit = useMemo(() => {
    return formatAmount(closeTrade.percentProfit, GAMBIT_USD_DECIMALS);
  }, [closeTrade.percentProfit]);

  const profitPercent = useMemo(() => {
    return addPlusGt0(+_percentProfit < -100 ? "-100" : _percentProfit, 6);
  }, [_percentProfit]);

  const profitValue = useMemo(() => {
    return addPlusGt0(
      formatAmount(
        new BN(trade.positionSizeUsdc.toString())
          .multipliedBy(profitPercent)
          .dividedBy(100)
          .toFixed(0),
        fromToken.decimals,
        2,
        false
      )
    );
  }, [fromToken.decimals, profitPercent, trade.positionSizeUsdc]);

  const closeFeeAndFundingFee = useMemo(() => {
    return new BN(profitValue)
      .minus(netPnL)
      .minus(openFeeAndNetworkFee)
      .toFixed(2);
  }, [netPnL, openFeeAndNetworkFee, profitValue]);

  const date = useMemo(() => {
    return dayjs.unix(+closeTrade.timestamp).format("L LTS");
  }, [closeTrade.timestamp]);

  const openPrice = useMemo(() => {
    return trimPriceString(
      formatUnits(trade.openPrice, GAMBIT_USD_DECIMALS),
      true
    );
  }, [trade.openPrice]);

  const closePrice = useMemo(() => {
    return trimPriceString(
      formatUnits(closeTrade.closePrice, GAMBIT_USD_DECIMALS),
      true
    );
  }, [closeTrade.closePrice]);

  // fee = closing fee + rollover fee + funding fee
  // fee가 - 이면 trader가 -만큼 받았다는 의미, + 이면 trader가 +만큼 지불했다는 의미
  // const closeFeeAndFundingFee = new BN(profitValue)
  //   .minus(pnl)
  //   .toFixed(2);
  // TODO: 수정
  // const closeFeeAndFundingFee = new BN(profitValue0)
  //   .minus(pnl)
  //   .toFixed(2);

  const selectedCloseTrade = useMemo(() => {
    return {
      ...closeTrade,
      positionSizeUsdcString,
      initialPosUsdcString,
      closeFeeAndFundingFee,
      openFeeAndNetworkFee,
      profitValue,
      profitPercent,
      // pnl,
      netPnL,
    };
  }, [
    closeFeeAndFundingFee,
    closeTrade,
    initialPosUsdcString,
    netPnL,
    openFeeAndNetworkFee,
    positionSizeUsdcString,
    profitPercent,
    profitValue,
  ]);

  const { i18n } = useLingui();

  return (
    <tr
      className={cx(
        "text-gray-00 font-normal hover:bg-white hover:bg-opacity-5",
        closeTrade.reason === "MARKET_OPEN_CANCELED"
          ? "cursor-not-allowed"
          : "cursor-pointer"
      )}
      onClick={() => {
        if (closeTrade.reason === "MARKET_OPEN_CANCELED") return;

        setSelectedCloseTrade(selectedCloseTrade);

        setIsVisibleCloseTradeModal(true);
      }}
    >
      {/* Date */}
      <td className="font-space-grotesk">{date}</td>

      {/* Pair */}
      <td className="font-space-grotesk">
        {trade.pair.from}/{trade.pair.to}
      </td>

      {/* Type */}
      <td className={cx(trade.buy ? "text-green-2" : "text-red-2")}>
        {i18n._(type)}
      </td>

      {/* Leverage */}
      <td className="font-space-grotesk table-cell">
        {unpadZero(trade.leverage.toFixed(2))}x
      </td>

      {/* Open Price */}
      <td className="font-space-grotesk">
        {closeTrade.reason !== "MARKET_OPEN_CANCELED" && (
          <div>${openPrice}</div>
        )}
      </td>

      {/* Close Price */}
      <td className="font-space-grotesk">
        {closeTrade.reason !== "MARKET_OPEN_CANCELED" && (
          <div>${closePrice}</div>
        )}
      </td>

      {/* Collateral */}
      {/* <td className="font-space-grotesk table-cell">
        {positionSizeUsdcString} {fromToken.symbol}
      </td> */}

      {/* Profit */}
      <td
        className={cx(
          "font-space-grotesk table-cell",
          profitPercent.includes("-") ? "text-red-2" : "text-green-2"
        )}
      >
        {closeTrade.reason !== "MARKET_OPEN_CANCELED" && (
          <div>
            <span>
              {profitValue} {fromToken.symbol}
            </span>
            <span> ({formatStringAmount(profitPercent, 2)}%)</span>
          </div>
        )}
      </td>

      {/* 최초 포지션 열 때 사용자가 입력한 담보 */}
      {/* <td>{closeTrade.reason !== "MARKET_OPEN_CANCELED" && +positionSizeUsdcString + +closeTrade.openingFee}</td> */}

      {/* Fee ( closing fee + rollover fee + funding fee ) */}
      <td className="font-space-grotesk">
        {closeTrade.reason !== "MARKET_OPEN_CANCELED" && (
          <span>
            {closeFeeAndFundingFee} {fromToken.symbol}
            {/* {new BN(closeTrade.closingFee).plus(closeTrade.openingFee).toString()} {fromToken.symbol} */}
          </span>
        )}
      </td>

      {/* Net PnL */}
      <td
        className={cx(
          "font-space-grotesk table-cell",
          netPnL.includes("-") ? "text-red-2" : "text-green-2"
        )}
      >
        {closeTrade.reason !== "MARKET_OPEN_CANCELED" && (
          <span>
            {formatStringAmount(netPnL, 2, true)} {fromToken.symbol}
          </span>
        )}
      </td>

      {/* Reason */}
      <td>{reason}</td>
    </tr>
  );
};
