export const getReferralRank = (active: boolean, rankNum: string) => {
  if (!active) return "None";

  switch (rankNum) {
    case "0":
      return "Bronze";
    case "1":
      return "Silver";
    case "2":
      return "Gold";
    case "3":
      return "Platinum";
    case "4":
      return "Gambit";
    case "5":
      return "Super Gambit";
    default:
      return "None";
  }
};
