import { parseUnits } from "@ethersproject/units";
import { Trans } from "@lingui/macro";
import pendingAnimation from "animation/pending.json";
import axios from "axios";
import BN from "bignumber.js";
import {
  WEMIX_TESTNET,
  ZKSYNC_GOERLI
} from "config/chains";
import { ContractTransaction } from "ethers";
import { IOpenTradeApiFailResponse } from "futures-domain/trades/api-types";
import { useChainId } from "futures-lib/chains";
import { Opts, handleContractResult } from "futures-lib/contracts";
import { helperToast } from "futures-lib/helperToast";
import { importImage } from "futures-lib/legacy";
import { formatAmount } from "futures-lib/numbers";
import useWallet from "futures-lib/wallets/useWallet";
import Lottie from "lottie-react";
import { useCallback, useMemo, useState } from "react";
import { FiExternalLink } from "react-icons/fi";
import {
  MIN_POS_SIZE,
  // PRODUCTION_OR_STAGING_ENV,
  getAPIUrl
} from "./constants";
import { MINT_NETWORKS, handleApiResult } from "./TradeBoxV2";

export const CollateralBox = ({
  collateralBalance, fromToken, fromTokenAddress, fromValue, setFromValue, setPendingTxns,
}) => {
  const { signer, account, isActive } = useWallet();
  const { chainId } = useChainId();
  // const { chainId: walletChainId } = useWallet();
  const [isGettingFromToken, setIsGettingFromToken] = useState(false);

  const fromTokenImage = useMemo(
    () => importImage(`ic_${fromToken.symbol.toLowerCase()}_24.svg`),
    [fromToken.symbol]
  );

  const disabledMaxButton = useMemo(() => {
    if (!isActive) return true;

    return !collateralBalance || new BN(collateralBalance.toString()).isZero();
  }, [collateralBalance, isActive]);

  const handleMaxBtn = useCallback(() => {
    if (!isActive) return;

    const maxAmount = formatAmount(
      collateralBalance,
      fromToken.decimals,
      2,
      false
    );
    setFromValue(maxAmount);
  }, [isActive, collateralBalance, fromToken.decimals, setFromValue]);

  // const collateralContract = useGetContract("MockUSDC") as MockUSDC;

  // const handleMint = useCallback(async () => {
  //   if (!signer || collateralBalance === undefined || !account) return;

  //   await collateralContract.mint(account, parseUnits("1000000", fromToken.decimals))
  //   return;
  // }, [account, collateralBalance, collateralContract, fromToken.decimals, signer])

  const getFreeCollateralV2 = useCallback(async () => {
    if (!signer || collateralBalance === undefined || !account) return;

    const signature = await signer.signMessage(collateralBalance.toString());

    try {
      setIsGettingFromToken(true);
      const { data } = await axios.post<string | IOpenTradeApiFailResponse>(
        `${getAPIUrl(
          chainId
        )}/v1/gasless/usdc/faucet?trader=${account}&signature=${signature}`,
        {
          remainingBalance: collateralBalance.toString(),
        }
      );

      let status, hash, code;

      // * data가 string(hash)이 아니면 API 호출 실패한 것
      // * API 성공 / 실패 시 응답 형태가 달라서
      if (typeof data !== "string") {
        status = data.status;
        hash = data.hash;
        code = data.code;
      }

      // * data가 string이면 API 호출 성공 케이스
      else {
        hash = data;
        status = 200;
      }

      if (status === 200) {
        handleContractResult({ hash } as ContractTransaction, {} as Opts);
      } else {
        handleApiResult(status, code);
      }
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.log(e);

      try {
        const {
          details: { reason }, response: {
            data: { message },
          },
        } = e;
        helperToast.error(
          <div className="toastify-body-only">
            {message || reason || "Something went wrong"}
          </div>,
          {
            autoClose: 5000,
          }
        );
      } catch (e) {
        helperToast.error(
          <div className="toastify-body-only">Something went wrong</div>,
          {
            autoClose: 5000,
          }
        );
      }
    } finally {
      setIsGettingFromToken(false);
    }
  }, [account, chainId, collateralBalance, signer]);

  // * legacy
  // const getFreeCollateral = useCallback(() => {
  //   if (!signer) return;
  //   // const contractAddress = getContract(chainId, "CollateralToken");
  //   setIsGettingFromToken(true);
  //   const contract = new ethers.Contract(fromTokenAddress, getAbi(chainId, "CollateralToken").abi, signer);
  //   const method = MINT_NETWORKS.includes(chainId) ? "mint" : "getFreeDai";
  //   const params = MINT_NETWORKS.includes(chainId) ? [account, parseUnits("10000", fromToken.decimals)] : [];
  //   callContract(
  //     chainId,
  //     contract,
  //     method,
  //     params,
  //     {
  //       value: bigNumberify(0),
  //       setPendingTxns,
  //       sentMsg: "",
  //       failMsg: "",
  //       successMsg: "",
  //       isWeb3AuthAccount,
  //     } as Opts,
  //     walletChainId
  //   )
  //     .then(async () => {})
  //     .catch((e) => {
  //       // eslint-disable-next-line no-console
  //       console.log(e);
  //     })
  //     .finally(() => {
  //       setIsGettingFromToken(false);
  //       // setIsPendingConfirmation(false);
  //     });
  // }, [
  //   signer,
  //   fromTokenAddress,
  //   chainId,
  //   account,
  //   fromToken.decimals,
  //   setPendingTxns,
  //   isWeb3AuthAccount,
  //   walletChainId,
  // ]);
  const getFreeCollateralButton = useMemo(() => {
    if (!MINT_NETWORKS.includes(chainId)) return null;

    if (collateralBalance &&
      collateralBalance.lt(
        parseUnits(MIN_POS_SIZE.toString(), fromToken.decimals)
      )) {
      if (MINT_NETWORKS.includes(chainId)) {
        return (
          <button
            onClick={getFreeCollateralV2}
            className="brand-small-btn min-w-[10rem] flex justify-center"
            disabled={isGettingFromToken}
          >
            {isGettingFromToken ? (
              <Lottie
                className="w-[2rem] h-[2rem]"
                animationData={pendingAnimation} />
            ) : (
              `GET 10,000 ${fromToken.symbol}`
            )}
          </button>
        );
      } else if ([ZKSYNC_GOERLI].includes(chainId)) {
        return (
          <a href="https://syncswap.xyz/" target="_blank" rel="ugc">
            <button className="flex items-center brand-small-btn">
              <FiExternalLink className="mr-[3px]" />
              <span>GET USDC</span>
            </button>
          </a>
        );
      } else if ([WEMIX_TESTNET].includes(chainId)) {
        return (
          <a href="https://dev.wemix.fi/swap/" target="_blank" rel="ugc">
            <button className="flex items-center brand-small-btn">
              <FiExternalLink className="mr-[3px]" />
              <span>GET WEMIX$</span>
            </button>
          </a>
        );
      }
    }
    return null;
  }, [
    chainId,
    collateralBalance,
    fromToken.decimals,
    fromToken.symbol,
    getFreeCollateralV2,
    isGettingFromToken,
  ]);

  return (
    <div className="border-gray-70 bg-black-2 mb-[0.4rem] pt-[1.6rem] px-[1.6rem] pb-[1.8rem] rounded-[1.2rem]">
      <div className="flex items-center justify-between text-[1.3rem] text-white text-opacity-50 font-medium mb-[0.3rem]">
        <div className="mr-[1rem]">
          <Trans id="msg.tradeBox / Pay">Pay</Trans>
          {/* <div className="h-[8px]" /> */}
        </div>
        {collateralBalance && (
          <div className="flex items-center ">
            <span>
              <Trans>Balance</Trans>:
            </span>
            <span className="font-space-grotesk ml-[4px] overflow-x-auto max-w-[18rem]">
              {formatAmount(collateralBalance, fromToken.decimals, 2, true)}
            </span>

            <button
              disabled={disabledMaxButton}
              onClick={handleMaxBtn}
              className="ml-[4px] cursor-pointer text-green-2 heading9"
            >
              <Trans>Max</Trans>
              {/* <div className="h-[8px]" /> */}
            </button>
            {/* <button onClick={handleMint}>mint</button> */}
          </div>
        )}
        {collateralBalance && getFreeCollateralButton}
      </div>

      <div className="flex">
        <div className="flex items-center">
          <img
            className="mr-[6px]"
            src={fromTokenImage}
            alt="from token logo"
            width="26" />
          <span className="font-space-grotesk mr-[1.2rem] text-[1.8rem] font-medium">
            {fromToken.symbol}
          </span>
        </div>
        {/* <button
          onClick={handleMaxBtn}
          className="self-center bg-green-3 rounded-[4px] py-[4px] px-[6px] text-green-1 text-[1.2rem] font-medium"
        >
          MAX
        </button> */}
        <div className="flex-1 relative overflow-hidden">
          <input
            type="text"
            pattern="^([0-9]+(?:[.,][0-9]*)?)$"
            inputMode="decimal"
            className="font-space-grotesk w-full text-right text-[2.4rem] font-bold"
            placeholder="0.0"
            value={fromValue}
            onChange={(e) => {
              if (!/^([0-9]*(?:[.,][0-9]*)?)$/.test(e.target.value)) {
                return;
              }
              // INFO: 소수점 6자리까지만 입력 허용
              if (/^[\d]*\.?[\d]{0,6}$/.test(e.target.value)) {
                setFromValue(e.target.value);
              } else {
                return;
              }
            }}
            onFocus={(e) => {
              e.target.select();
            }} />
        </div>
      </div>
    </div>
  );
};
