import styled from "@emotion/styled";
import cx from "classnames";

// import ChainPartnersImg from "img/ic-chain-partners.png";
import FalconxImg from "img/ic-falconx.png";
import ZkswapImg from "img/ic-zkswap.png";
import MisesImg from "img/ic-mises-browser.png";
import CwinImg from "img/ic-cwin.png";
import IzumiImg from "img/ic-izumi.png";
import OstrichImg from "img/ic-ostrich.png";
import Mc2Img from "img/ic-mc2-finance.png";
import MesherImg from "img/ic-mesher.png";
import PythImg from "img/ic-pyth.png";
// import SandboxImg from "img/ic-sandbox.png";
// import BlockoImg from "img/ic-blocko.png";
// import DscImg from "img/ic-dsc-investment.png";
// import CapstoneImg from "img/ic-capstone.png";
// import PremierImg from "img/ic-premier-partners.png";
// import SmilegateImg from "img/ic-smilegate-investment.png";
// import NvestorImg from "img/ic-nvestor.png";
// import KolonImg from "img/ic-kolon.png";

/**
 * * 애니메이션 정의: tailwind.config.js
 * * 모바일 애니메이션: partners-mobile-scroll-left
 * * 데스크탑 애니메이션: partners-desktop-scroll-left
 * * 파트너 로고 총 17개
 * * 애니메이션으로 돌리기 위해서 tsx 내부에 img 17개 씩 2세트(총 34개) 존재
 * * 각 이미지 width는 모바일에서 10rem, md 이상 스크린부터 16rem
 * * 각 이미지 사이에 5rem gap 존재 (space-x-[5rem])
 * * 따라서 전체 이미지들의 wrapper width는 모바일에서 (10rem + 5rem) * 34
 * * 따라서 전체 이미지들의 wrapper width는 데스크탑에서 (16rem + 5rem) * 34
 *
 */

/**
 * * 23.12.12 수정
 * * 파트너 로고 총 17개 -> 8개
 * * 애니메이션으로 돌리기 위해서 tsx 내부에 img 8개 씩 2세트(총 16개) 존재
 * * 따라서 전체 이미지들의 wrapper width는 모바일에서 (10rem + 5rem) * 16
 * * 따라서 전체 이미지들의 wrapper width는 데스크탑에서 (16rem + 5rem) * 16
 * * tailwind.config.js에서 mobile-partners-scroll-left, desktop-partners-scroll-left keyframes transform * 8(파트너 갯수) 으로 변경
 */

/**
 * * 23.12.27 수정
 * * 파트너 로고 총 8개 -> 9개
 * * 애니메이션으로 돌리기 위해서 tsx 내부에 img 9개 씩 2세트(총 18개) 존재
 * * 따라서 전체 이미지들의 wrapper width는 모바일에서 (10rem + 5rem) * 18
 * * 따라서 전체 이미지들의 wrapper width는 데스크탑에서 (16rem + 5rem) * 18
 *
 * * Slider 컴포넌트 바로 아래 div의 width 변경
 * * tailwind.config.js에서 mobile-partners-scroll-left, desktop-partners-scroll-left keyframes transform * 9(파트너 갯수) 으로 변경
 */

const SlidePartners = () => {
  return (
    // <Slider className="w-[144rem] mx-[-1.6rem] md:mx-[-6rem] xl:mx-[-12.4rem]">
    <Slider className="w-full">
      <div className="flex h-[8.8rem] items-center bg-white bg-opacity-5 animate-mobile-partners-scroll-left md:animate-desktop-partners-scroll-left space-x-[5rem] w-[calc(15rem*18)] md:w-[calc(21rem*18)]">
        <div className="w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]">
          <img className={cx("w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]")} src={FalconxImg} alt="falconx logo" />
        </div>
        <div className="w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]">
          <img className={cx("w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]")} src={PythImg} alt="pyth logo" />
        </div>
        <div className="w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]">
          <img className={cx("w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]")} src={ZkswapImg} alt="zkswap logo" />
        </div>
        <div className="w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]">
          <img
            className={cx("w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]")}
            src={MisesImg}
            alt="mises browser logo"
          />
        </div>
        <div className="w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]">
          <img className={cx("w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]")} src={CwinImg} alt="cwin logo" />
        </div>
        <div className="w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]">
          <img className={cx("w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]")} src={IzumiImg} alt="izumi logo" />
        </div>
        <div className="w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]">
          <img className={cx("w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]")} src={OstrichImg} alt="ostrich logo" />
        </div>
        <div className="w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]">
          <img className={cx("w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]")} src={Mc2Img} alt="mc2 finance logo" />
        </div>
        <div className="w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]">
          <img className={cx("w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]")} src={MesherImg} alt="mesher logo" />
        </div>
        {/* <div className="w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]">
          <img className={cx("w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]")} src={SandboxImg} alt="sandbox logo" />
        </div> */}
        {/* <div className="w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]">
          <img className={cx("w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]")} src={BlockoImg} alt="blocko logo" />
        </div> */}
        {/* <div className="w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]">
          <img className={cx("w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]")} src={DscImg} alt="dsc ivestment logo" />
        </div> */}
        {/* <div className="w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]">
          <img className={cx("w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]")} src={CapstoneImg} alt="capstone logo" />
        </div> */}
        {/* <div className="w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]">
          <img
            className={cx("w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]")}
            src={PremierImg}
            alt="premier partners logo"
          />
        </div> */}
        {/* <div className="w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]">
          <img
            className={cx("w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]")}
            src={SmilegateImg}
            alt="smilegate investment logo"
          />
        </div> */}
        {/* <div className="w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]">
          <img className={cx("w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]")} src={NvestorImg} alt="nvestor logo" />
        </div> */}
        {/* <div className="w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]">
          <img className={cx("w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]")} src={KolonImg} alt="kolon logo" />
        </div> */}

        {/* <div className="w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]">
          <img
            className={cx("w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]")}
            src={ChainPartnersImg}
            alt="chainpartners logo"
          />
        </div> */}
        <div className="w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]">
          <img className={cx("w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]")} src={FalconxImg} alt="falconx logo" />
        </div>
        <div className="w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]">
          <img className={cx("w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]")} src={PythImg} alt="pyth logo" />
        </div>
        <div className="w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]">
          <img className={cx("w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]")} src={ZkswapImg} alt="zkswap logo" />
        </div>
        <div className="w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]">
          <img
            className={cx("w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]")}
            src={MisesImg}
            alt="mises browser logo"
          />
        </div>
        <div className="w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]">
          <img className={cx("w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]")} src={CwinImg} alt="cwin logo" />
        </div>
        <div className="w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]">
          <img className={cx("w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]")} src={IzumiImg} alt="izumi logo" />
        </div>
        <div className="w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]">
          <img className={cx("w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]")} src={OstrichImg} alt="ostrich logo" />
        </div>
        <div className="w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]">
          <img className={cx("w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]")} src={Mc2Img} alt="mc2 finance logo" />
        </div>
        <div className="w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]">
          <img className={cx("w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]")} src={MesherImg} alt="mesher logo" />
        </div>
        {/* <div className="w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]">
          <img className={cx("w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]")} src={SandboxImg} alt="sandbox logo" />
        </div> */}
        {/* <div className="w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]">
          <img className={cx("w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]")} src={BlockoImg} alt="blocko logo" />
        </div> */}
        {/* <div className="w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]">
          <img className={cx("w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]")} src={DscImg} alt="dsc ivestment logo" />
        </div> */}
        {/* <div className="w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]">
          <img className={cx("w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]")} src={CapstoneImg} alt="capstone logo" />
        </div> */}
        {/* <div className="w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]">
          <img
            className={cx("w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]")}
            src={PremierImg}
            alt="premier partners logo"
          />
        </div> */}
        {/* <div className="w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]">
          <img
            className={cx("w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]")}
            src={SmilegateImg}
            alt="smilegate investment logo"
          />
        </div> */}
        {/* <div className="w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]">
          <img className={cx("w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]")} src={NvestorImg} alt="nvestor logo" />
        </div> */}
        {/* <div className="w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]">
          <img className={cx("w-[10rem] h-[3rem] md:w-[16rem] md:h-[4.8rem]")} src={KolonImg} alt="kolon logo" />
        </div> */}
      </div>
    </Slider>
  );
};

export default SlidePartners;

const Slider = styled.div`
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
  // height: 100px;
  margin: auto;
  overflow: hidden;
  position: relative;

  &::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  &::before {
    left: 0;
    top: 0;
  }
`;
