import React from "react";
import cx from "classnames";
import { Trans } from "@lingui/macro";

const LongShortLable = ({ buy }: { buy: boolean }) => {
  if (buy) {
    return (
      <span
        className={cx(
          "py-[2.5px] px-[5.25px] sm+:py-[4px] sm+:px-[1rem] rounded-[4px] bg-green-2 bg-opacity-20 text-green-2 text-[1rem] sm+:text-[1.5rem] font-medium"
        )}
      >
        <Trans>LONG</Trans>
      </span>
    );
  } else {
    return (
      <span
        className={cx(
          "py-[2.5px] px-[5.25px] sm+:py-[4px] sm+:px-[1rem] rounded-[4px] bg-red-2 bg-opacity-20 text-red-2 text-[1rem] sm+:text-[1.5rem] font-medium"
        )}
      >
        <Trans>SHORT</Trans>
      </span>
    );
  }
};

export default LongShortLable;
