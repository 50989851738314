import { HttpLink } from "@apollo/client";
import ApolloLinkTimeout from "apollo-link-timeout";
import { SUBGRAPH_TIMEOUTS } from "components/Exchange/constants";
import { APP_MODES } from "futures-domain/types";
import {
  ARBITRUM,
  ARBITRUM_GOERLI,
  ARBITRUM_SEPOLIA,
  HARDHAT,
  WEMIX_TESTNET,
  ZKSYNC,
  ZKSYNC_GOERLI,
  ZKSYNC_SEPOLIA,
} from "./chains";

export const SUBGRAPH_URLS = {
  [ZKSYNC]: [
    "https://api.studio.thegraph.com/query/56479/gambit-zksync/version/latest",
    "https://service.gambit.trade/subgraphs/name/changerio/gambit",
  ],
  [ZKSYNC_GOERLI]: ["https://api.studio.thegraph.com/query/56479/gambit-zksync-testnet/version/latest"],
  [ZKSYNC_SEPOLIA]: ["https://api.studio.thegraph.com/query/56479/gambit-zksync-sepolia/version/latest"],
  [HARDHAT]: [
    process.env.REACT_APP_ZKSYNC_LOCAL_GRAPHQL_ENDPOINT,
    // "https://stage-graph.gambit.trade/subgraphs/name/changerio/gambit/",
    // "https://api.studio.thegraph.com/proxy/47348/gambit-zksync-alpha/version/latest",
  ],
  [WEMIX_TESTNET]: ["https://stage-graph.gambit.trade:18443/subgraphs/name/changerio/gambit"],
  [ARBITRUM]:
    process.env.REACT_APP_MODE === APP_MODES.LOCAL
      ? [
          // * 무료 rate limit 존재
          "https://api.studio.thegraph.com/query/56479/gambit-arbitrum/version/latest",
          // "https://api.goldsky.com/api/public/project_clq4q4rz4nonh01rlfuy6928z/subgraphs/gambit-arbitrum/v1.3.3-renew/gn",

          // "https://gateway-arbitrum.network.thegraph.com/api/61cdbdebec01f9991c92289f6d3aabaf/subgraphs/id/ByJX6c22BpfiW2yc8dvhMGSniBhGZJLAscpw4wW28rT8",
          // "https://gateway-arbitrum.network.thegraph.com/api/9a2339f89c09be2d9bed0b5f2c2ccf91/subgraphs/id/ByJX6c22BpfiW2yc8dvhMGSniBhGZJLAscpw4wW28rT8",
          // 도메인 제한 없는 키 9a2339f89c09be2d9bed0b5f2c2ccf91
          // "https://subgraph.satsuma-prod.com/9333525ac32a/s-team--2985234/gambit-arbitrum/api",
        ]
      : [
          // * gambit 도메인만 허용, 비용 지불 subgraph
          // "https://gateway-arbitrum.network.thegraph.com/api/61cdbdebec01f9991c92289f6d3aabaf/subgraphs/id/ByJX6c22BpfiW2yc8dvhMGSniBhGZJLAscpw4wW28rT8",
          // "https://api.studio.thegraph.com/query/56479/gambit-arbitrum/version/latest",
          "https://subgraph.satsuma-prod.com/994b223feb69/s-team--2985234/gambit-arbitrum/api",
        ],
  [ARBITRUM_SEPOLIA]: [
    // "https://subgraph.satsuma-prod.com/54b251a435a9/s-team--2985234/gambit-arbitrum-sepolia/api",
    "https://api.studio.thegraph.com/query/56479/gambit-arbitrum-sepolia/version/latest",
  ],
  [ARBITRUM_GOERLI]: [
    // * 무료 rate limit 존재
    "https://api.studio.thegraph.com/query/56479/gambit-arbitrum-goerli/version/latest",
  ],
};

export const getSubgraphHttpLink = (subgraphUrl: string) => {
  return new ApolloLinkTimeout(SUBGRAPH_TIMEOUTS).concat(
    new HttpLink({
      uri: subgraphUrl,
      // credentials: "include",
    })
  );
};
