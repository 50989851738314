import { Trans } from "@lingui/macro";
import { useMemo } from "react";
import { FiX } from "react-icons/fi";
import { Link } from "react-router-dom";

import cx from "classnames";
import Tooltip from "components/Tooltip/Tooltip";
import { ReactComponent as ExternalLinkSvg } from "img/ic-external-link.svg";
import { NavLink } from "react-router-dom";
import "./Header.css";
// import { currentLanguageAtom } from "atoms";
// import { useAtomValue } from "jotai";
import ZeroFeeTooltipForHeader from "components/ZeroFeeEvent/ZeroFeeTooltipForHeader";
import { getBrigeUrl } from "config/chains";
import { useChainId } from "futures-lib/chains";

type Props = {
  small?: boolean;
  clickCloseIcon?: () => void;
};

export function AppHeaderLinks({ small, clickCloseIcon }: Props) {
  // const currentLanguage = useAtomValue(currentLanguageAtom);
  const { chainId } = useChainId();

  const bridgeUrl = useMemo(() => {
    return getBrigeUrl(chainId);
  }, [chainId]);

  return (
    <div
      className={cx(
        small ? "text-[1.6rem]" : "flex",
        "text-white text-opacity-60 font-medium"
      )}
    >
      {small && (
        <div className="App-header-links-header">
          <Link className="" to="/">
            {/* <img src={logoImg} alt="GMX Logo" /> */}
          </Link>
          <div
            className="App-header-menu-icon-block mobile-cross-menu"
            onClick={() => clickCloseIcon && clickCloseIcon()}
          >
            <FiX className="App-header-menu-icon" />
          </div>
        </div>
      )}

      <div className="">
        <NavLink
          className={({ isActive }) =>
            cx({ "text-white font-bold": isActive }, "block w-full p-[1.4rem]")
          }
          to="/trade"
        >
          <Trans>Trade</Trans>
        </NavLink>
        <ZeroFeeTooltipForHeader />
      </div>
      <div className="">
        <NavLink
          className={({ isActive }) =>
            cx({ "text-white font-bold": isActive }, "block w-full p-[1.4rem]")
          }
          to="/vault"
        >
          <Trans>Vault</Trans>
        </NavLink>
      </div>
      <div className="">
        <NavLink
          className={({ isActive }) =>
            cx({ "text-white font-bold": isActive }, "block w-full p-[1.4rem]")
          }
          to="/staking"
        >
          <Trans>Staking</Trans>
        </NavLink>
      </div>
      <div className="">
        <NavLink
          className={({ isActive }) =>
            cx({ "text-white font-bold": isActive }, "block w-full p-[1.4rem]")
          }
          to="/referrals"
        >
          <Trans>Referrals</Trans>
        </NavLink>
      </div>

      <div className="flex items-center cursor-pointer">
        <a
          className="flex items-center p-[1.4rem] w-full shrink-0 grow-1"
          href="https://dune.com/team_gambit/gambit-dashboard"
          target="_blank"
          rel="ugc"
        >
          <Trans>Dashboard</Trans>
          <ExternalLinkSvg className="ml-[4px]" />
        </a>
      </div>

      <div className="flex items-center cursor-pointer">
        <a
          className="flex items-center p-[1.4rem] w-full shrink-0 grow-1"
          // href={currentLanguage === "ko" ? "https://docs.gambit.trade/v/kr" : "https://docs.gambit.trade"}
          href="https://docs.gambit.trade"
          target="_blank"
          rel="ugc"
        >
          <Trans>Docs</Trans>
          <ExternalLinkSvg className="ml-[4px]" />
        </a>
      </div>
      {bridgeUrl ? (
        <div className="hidden 2xl-:flex items-center cursor-pointer">
          <a
            className="flex items-center p-[1.4rem] w-full"
            href={bridgeUrl}
            target="_blank"
            rel="ugc"
          >
            <Trans>Bridge</Trans>
            <ExternalLinkSvg className="ml-[4px]" />
          </a>
        </div>
      ) : (
        <Tooltip
          handle={
            <div className="relative flex p-[1.4rem] cursor-pointer">
              <Trans>Bridge</Trans>
              {small && (
                <div className="absolute top-[7px] left-[8rem] border rounded-[999px] px-[1.2rem] py-[0.8rem] text-brand-1 text-[1.3rem] font-medium">
                  Coming Soon
                </div>
              )}
            </div>
          }
          disableHandleStyle={true}
          position="center-bottom"
          tooltipClassName="!min-w-[12.8rem] !top-[3.8rem] !rounded-[999px] !px-[8px] !py-[12px] !text-[1.3rem]"
          renderContent={() =>
            small ? null : (
              <span className="flex justify-center text-brand-1 text-[1.3rem] font-medium">
                <Trans>Coming Soon</Trans>
              </span>
            )
          }
        />
      )}

      {/* <div className="p-[1.4rem]">
        <NavLink className={({ isActive }) => cx({ "text-white font-bold": isActive })} to="/trading-competition">
          <Trans>Trading Competition</Trans>
        </NavLink>
      </div> */}

      {/* <Tooltip
        handle={
          <div className="relative flex items-center p-[1.4rem] cursor-pointer">
            <Trans>Statistics</Trans>
            <ExternalLinkSvg className="ml-[4px]" />
            {small && (
              <div
                className={cx(
                  "absolute border rounded-[999px] px-[1.2rem] py-[0.8rem] text-brand-1 text-[1.3rem] font-medium",
                  currentLanguage === "ko" ? "left-[8rem]" : "left-[12.5rem]"
                )}
              >
                Coming Soon
              </div>
            )}
          </div>
        }
        disableHandleStyle={true}
        position="center-bottom"
        tooltipClassName="!min-w-[12.8rem] !top-[3.8rem] !rounded-[999px] !px-[8px] !py-[12px] !text-[1.3rem]"
        renderContent={() =>
          small ? null : (
            <span className="flex justify-center text-brand-1 text-[1.3rem] font-medium">
              <Trans>Coming Soon</Trans>
            </span>
          )
        }
      /> */}
    </div>
  );
}
