import {
  isVisibleEditLiqPriceModalAtom,
  pairsAtom,
  parsedOpenTradesAtom,
  pendingTxnsAtom,
  selectedPairAtom,
  selectedTradeAtom,
} from "atoms/exchange";
import ModalSkeletonWithPortal from "components/Modal/ModalSkeletonWithPortal";
import { getContract } from "config/contracts";
import { BigNumber, ContractTransaction, constants } from "ethers";
import { useChainId } from "futures-lib/chains";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Trans, t } from "@lingui/macro";
import {
  Opts,
  contractFetcher,
  handleContractResult,
  handleTradeApiError,
} from "futures-lib/contracts";
import { formatAmount } from "futures-lib/numbers";
// import { GAMBIT_USD_DECIMALS } from "components/Exchange/constants";
// import { formatUnits, parseUnits } from "@ethersproject/units";
import {
  GambitTradingV1,
  GambitTradingV1Facet3,
} from "@changerio/futures-contracts/dist/typechain-types";
import { EIP712_TYPES_BY_FUNCTION } from "@changerio/futures-contracts/lib/eip-712-types";
import { formatUnits, parseUnits } from "@ethersproject/units";
import * as Sentry from "@sentry/react";
import Token from "abis/Token.json";
import axios from "axios";
import BN from "bignumber.js";
import cx from "classnames";
import { handleApiResult } from "components/Exchange/TradeBoxV2";
import {
  GAMBIT_USD_DECIMALS,
  getAPIUrl,
  getTradeDeadline,
} from "components/Exchange/constants";
import Tooltip from "components/Tooltip/Tooltip";
import { getToken } from "config/tokens";
import {
  IAddCollateralApiFailResponse,
  IEditCollateralRequest,
  IRemoveCollateralApiFailResponse,
} from "futures-domain/trades/api-types";
import {
  getSalt,
  trimPriceBN,
  trimPriceString,
  unpadZero,
} from "futures-domain/trades/utils";
import {
  useGetContract,
  useGetTypedDomain,
} from "futures-lib/contracts/contract";
import useTermsConnectModal from "futures-lib/wallets/useTermsConnectModal";
import useWallet from "futures-lib/wallets/useWallet";
import { ReactComponent as InfoCircleSvg } from "img/ic-info-circle.svg";
import loader from "img/ic-loader.svg";
import useSWR from "swr";
import { sentryCaptureException } from "utils/sentry";

export const EDIT_LIQ_TYPE = {
  INCREASE: "INCREASE",
  DECRERASE: "DECRERASE",
} as const;

export type EditLiqTabType = typeof EDIT_LIQ_TYPE[keyof typeof EDIT_LIQ_TYPE];

export const EditLiqPriceModal = () => {
  const { isActive, account } = useWallet();

  const openTrades = useAtomValue(parsedOpenTradesAtom);

  const [selectedTrade, setSelectedTrade] = useAtom(selectedTradeAtom);
  const [isVisible, setIsVisible] = useAtom(isVisibleEditLiqPriceModalAtom);

  const { openConnectModal } = useTermsConnectModal();

  useEffect(() => {
    if (!openTrades || !selectedTrade) return;

    // * NOTE: liq, fee 등 최신 값을 가진 selectedTrade로 업데이트
    const updatedSelectedTrade = openTrades.find(
      (openTrade) =>
        openTrade.pairIndex === selectedTrade.pairIndex &&
        openTrade.positionIndex === selectedTrade.positionIndex &&
        openTrade.pair.from === selectedTrade.pair.from &&
        openTrade.pair.to === selectedTrade.pair.to
    );
    if (updatedSelectedTrade) {
      setSelectedTrade(updatedSelectedTrade);
    }
  }, [openTrades, selectedTrade, setSelectedTrade]);

  const [activeTab, setActiveTAb] = useState<EditLiqTabType>(
    EDIT_LIQ_TYPE.INCREASE
  );

  const { chainId } = useChainId();
  const { signer } = useWallet();

  const fromTokenAddress = getContract(chainId, "CollateralToken");
  const fromToken = getToken(chainId, fromTokenAddress);

  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (!isVisible) {
      setInputValue("");
    }
  }, [isVisible]);

  const newLeverage = useMemo(() => {
    if (!selectedTrade || !fromToken) return;
    if (!inputValue) return selectedTrade.leverage.toFixed(2);
    const { positionSizeUsdc, leverage } = selectedTrade;

    const oldCollateral = formatUnits(positionSizeUsdc, fromToken.decimals);
    let newLeverage = "";

    if (activeTab === EDIT_LIQ_TYPE.INCREASE) {
      newLeverage = new BN(oldCollateral)
        .multipliedBy(leverage)
        .dividedBy(new BN(oldCollateral).plus(inputValue))
        .toFixed(2);
    }

    if (activeTab === EDIT_LIQ_TYPE.DECRERASE) {
      newLeverage = new BN(oldCollateral)
        .multipliedBy(selectedTrade.leverage)
        .dividedBy(new BN(oldCollateral).minus(inputValue))
        .toFixed(2);
    }

    if (new BN(newLeverage).gte(0)) {
      return newLeverage;
    } else return "";
  }, [activeTab, fromToken, inputValue, selectedTrade]);

  const selectedPair = useAtomValue(selectedPairAtom);

  const decreaseFees = useMemo(() => {
    if (!selectedTrade || !fromToken) return;

    if (selectedPair?.fee.oracleFee) {
      return selectedPair?.fee.oracleFee;
    }
  }, [fromToken, selectedPair?.fee.oracleFee, selectedTrade]);

  const newCollateral = useMemo(() => {
    if (!selectedTrade || !fromToken || !decreaseFees) return;
    const { positionSizeUsdc } = selectedTrade;
    const oldCollateral = formatUnits(positionSizeUsdc, fromToken.decimals);

    if (!inputValue) return oldCollateral;

    let newCollateral;

    if (activeTab === EDIT_LIQ_TYPE.INCREASE) {
      // const newCollateral = +oldCollateral + +inputValue;
      newCollateral = new BN(oldCollateral).plus(inputValue).toFixed(2);
    }

    if (activeTab === EDIT_LIQ_TYPE.DECRERASE) {
      // newCollateral = new BN(oldCollateral).minus(inputValue).minus(decreaseFees).toFixed(2);
      newCollateral = new BN(oldCollateral).minus(inputValue).toFixed(2);
    }

    if (new BN(newCollateral).gte(0)) {
      return newCollateral;
    } else {
      return "";
    }
  }, [activeTab, decreaseFees, fromToken, inputValue, selectedTrade]);

  const newLiqPrice = useMemo(() => {
    if (!selectedTrade || !selectedTrade.liqPrice || !fromToken) return;

    if (!inputValue) return trimPriceBN(selectedTrade.liqPrice, true);

    if (!newCollateral || !newLeverage) return;

    const { openPrice, rolloverFee, fundingFee } = selectedTrade;
    const formattedOpenPrice = formatUnits(openPrice, GAMBIT_USD_DECIMALS);
    let fees: BigNumber; // * NOTE: funding fee + rollover fee

    if (rolloverFee !== undefined && fundingFee !== undefined) {
      fees = fundingFee.add(rolloverFee);
    } else if (rolloverFee !== undefined) {
      fees = rolloverFee;
    } else if (fundingFee !== undefined) {
      fees = fundingFee;
    } else {
      fees = constants.Zero;
    }

    const foramttedFees = new BN(fees.toString()).dividedBy(
      +`1e${fromToken.decimals}`
    );

    // console.log(`formattedOpenPrice: ${formattedOpenPrice}`);
    // console.log(`newCollateral:      ${newCollateral}`);
    // console.log(`newLeverage:        ${newLeverage}`);
    // console.log(`foramttedFees:      ${foramttedFees}`);
    // console.log(`oldCollateral:      ${oldCollateral}`);
    // console.log(`rolloverFee:        ${rolloverFee}`);
    // console.log(`fundingFee:         ${fundingFee}`);
    // console.log(`leverage:           ${leverage}`);
    // console.log(`fees:               ${fees}`);

    const liqPriceDistance = new BN(formattedOpenPrice)
      .multipliedBy(
        new BN(newCollateral).multipliedBy(0.9).minus(foramttedFees)
      )
      .dividedBy(new BN(newCollateral).multipliedBy(newLeverage));

    let newLiqPrice = "";
    if (activeTab === EDIT_LIQ_TYPE.INCREASE) {
      if (selectedTrade.buy) {
        newLiqPrice = trimPriceString(
          new BN(formattedOpenPrice).minus(liqPriceDistance).toFixed()
        );
      } else {
        newLiqPrice = trimPriceString(
          new BN(formattedOpenPrice).plus(liqPriceDistance).toFixed()
        );
      }
    }

    if (activeTab === EDIT_LIQ_TYPE.DECRERASE) {
      if (selectedTrade.buy) {
        // console.log(new BN(formattedOpenPrice).plus(liqPriceDistance).toFixed());
        // console.log(trimPriceString(new BN(formattedOpenPrice).plus(liqPriceDistance).toFixed()));
        newLiqPrice = trimPriceString(
          new BN(formattedOpenPrice).minus(liqPriceDistance).toFixed()
        );
      } else {
        newLiqPrice = trimPriceString(
          new BN(formattedOpenPrice).plus(liqPriceDistance).toFixed()
        );
      }
    }

    if (new BN(newLiqPrice).gte(0)) {
      return newLiqPrice;
    } else {
      // * NOTE: 청산가가 음수가 되는 경우 화면에 청산가 표시 X
      return "";
    }
  }, [
    activeTab,
    fromToken,
    inputValue,
    newCollateral,
    newLeverage,
    selectedTrade,
  ]);

  const { data: collateralBalance } = useSWR<BigNumber>(
    isActive && [isActive, chainId, fromTokenAddress, "balanceOf", account],
    {
      fetcher: contractFetcher(signer, Token),
      shouldRetryOnError: true,
      errorRetryInterval: 500,
      refreshInterval: 2000,
    }
  );

  const tradingContract = useGetContract("GambitTradingV1") as GambitTradingV1;
  interface IsTxsubmitting {
    addCollateralTxIsSubmitting: boolean;
    removeCollateralTxIsSubmitting: boolean;
  }

  const initialIsTxsubmitting = {
    addCollateralTxIsSubmitting: false,
    removeCollateralTxIsSubmitting: false,
  };

  const setPendingTxns = useSetAtom(pendingTxnsAtom);
  const [isTxSubmitting, setIsTxSubmitting] = useState<IsTxsubmitting>(
    initialIsTxsubmitting
  );

  const pairs = useAtomValue(pairsAtom);

  const addCollateralOpts = useMemo(() => {
    if (!selectedTrade) return;

    return {
      setPendingTxns,
      sentMsg: t`Add Collateral Submitted`,
      failMsg: t`Add Collateral Failed`,
      successMsg: (
        <div>
          <div className="toastify-title">
            <Trans>Collateral Added</Trans>
          </div>
          <div>
            <div className="toastify-body">
              <span>
                {inputValue} {fromToken.symbol} @ {selectedTrade.pair.from}/
                {selectedTrade.pair.to}
              </span>
              <span
                className={cx(
                  selectedTrade.buy ? "text-green-2" : "text-red-2",
                  "ml-[4px]"
                )}
              >
                {selectedTrade.buy ? t`LONG` : t`SHORT`}
              </span>
            </div>
          </div>
        </div>
      ),
      pairs,
      chainId,
    } as Opts;
  }, [
    chainId,
    fromToken.symbol,
    inputValue,
    pairs,
    selectedTrade,
    setPendingTxns,
  ]);

  const { tradingContractTypedDomain } = useGetTypedDomain();

  const addCollateralApiCall = useCallback(async () => {
    if (
      !tradingContract ||
      !selectedTrade ||
      !account ||
      !signer ||
      !addCollateralOpts
    )
      return;
    const { pairIndex, positionIndex } = selectedTrade;
    const amount = parseUnits(inputValue, fromToken.decimals).toString();

    const deadline = getTradeDeadline(chainId);

    const params: GambitTradingV1Facet3.AddCollateralParamsStruct = {
      pairIndex,
      index: positionIndex,
      amount,
      deadline: deadline.toString(),
      salt: getSalt(),
    };

    setIsTxSubmitting({
      ...isTxSubmitting,
      addCollateralTxIsSubmitting: true,
    });

    try {
      const signature = await signer._signTypedData(
        tradingContractTypedDomain,
        EIP712_TYPES_BY_FUNCTION.addCollateral,
        params
      );

      try {
        const { data } = await axios.post<
          string | IAddCollateralApiFailResponse
        >(
          `${getAPIUrl(
            chainId
          )}/v1/gasless/trade/collateral?trader=${account}&signature=${signature}`,
          {
            add: true,
            ...params,
          } as IEditCollateralRequest
        );
        let status, hash, code;

        // * data가 string이 아니면 API 호출 실패한 것
        // * API 성공 / 실패 시 응답 형태가 달라서
        if (typeof data !== "string") {
          status = data.status;
          code = data.code;
        }
        // * data가 string이 아니면 API 호출 성공 케이스
        else {
          hash = data;
          status = 200;
        }

        if (status === 200) {
          handleContractResult(
            { hash } as ContractTransaction,
            addCollateralOpts
          );
          setIsVisible(false);
        } else {
          handleApiResult(status, code);
        }
      } catch (e: any) {
        // * API 에러 처리

        const { response } = e;

        const { data } = response;

        handleTradeApiError(
          data.name || "something went wrong",
          data.details?.customError
            ? `${data.message}(${data.details?.customError})`
            : data.message || "something went wrong",
          chainId,
          data.details?.customError ? data.details?.customError : ""
        );

        Sentry.setContext("/gasless/trade/collateral(add) API Request Params", {
          signature,
          params,
        });

        Sentry.setContext(
          "/gasless/trade/collateral(add) API Error Response",
          response
        );

        sentryCaptureException({
          error: new Error("/gasless/trade/collateral(add) API Error"),
          name: "Error Object",
          context: e,
        });
      }
    } catch (e: any) {
      // * 서명 에러 처리
      sentryCaptureException({
        error: new Error("Sign Error in addCollateralApiCall"),
        name: "Error Object",
        context: e,
      });
    } finally {
      setIsTxSubmitting({
        ...isTxSubmitting,
        addCollateralTxIsSubmitting: false,
      });
    }
  }, [
    account,
    addCollateralOpts,
    chainId,
    fromToken.decimals,
    inputValue,
    isTxSubmitting,
    selectedTrade,
    setIsVisible,
    signer,
    tradingContract,
    tradingContractTypedDomain,
  ]);

  // const addCollateralContractCall = useCallback(async () => {
  //   if (!tradingContract || !selectedTrade || !account || !addCollateralOpts) return;

  //   const { pairIndex, positionIndex } = selectedTrade;
  //   const amount = parseUnits(inputValue, fromToken.decimals);

  //   const params: GambitTradingV1Facet3.AddCollateralParamsStruct = {
  //     pairIndex,
  //     index: positionIndex,
  //     amount,
  //     deadline: ethers.constants.MaxUint256,
  //     salt: randomBytes(32),
  //   };

  //   const addCollateralParams: [string, GambitTradingV1Facet3.AddCollateralParamsStruct, string] = [
  //     account,
  //     params,
  //     "0x",
  //   ];

  //   try {
  //     setIsTxSubmitting({
  //       ...isTxSubmitting,
  //       addCollateralTxIsSubmitting: true,
  //     });

  //     const res = await tradingContract.addCollateral(...addCollateralParams);
  //     setIsVisible(false);
  //     handleContractResult(res, addCollateralOpts);
  //   } catch (e) {
  //     try {
  //       await tradingContract.callStatic.addCollateral(...addCollateralParams, {} as TxOptions);
  //     } catch (e) {
  //       handleContractError(e, chainId, addCollateralOpts, "addCollateral");
  //       throw e;
  //     }
  //     handleContractError(e, chainId, addCollateralOpts, "addCollateral");
  //   } finally {
  //     setIsTxSubmitting({
  //       ...isTxSubmitting,
  //       addCollateralTxIsSubmitting: false,
  //     });
  //     setInputValue("");
  //   }
  // }, [
  //   account,
  //   addCollateralOpts,
  //   chainId,
  //   fromToken.decimals,
  //   inputValue,
  //   isTxSubmitting,
  //   selectedTrade,
  //   setIsVisible,
  //   tradingContract,
  // ]);

  // const { data: delegationFeeThresholdMultiplier } = useSWR<BigNumber>(
  //   tradingContract && [tradingContract, "delegationFeeThresholdMultiplier", []],
  //   {
  //     fetcher: newContractFetcher,
  //     shouldRetryOnError: true,
  //     errorRetryInterval: 500,
  //     refreshInterval: 1000,
  //   }
  // );

  // const delegationFeeThreshhold = useDelegationFeeThreshhold(
  //   selectedTrade?.pair?.fee.minLevPosUsdc,
  //   delegationFeeThresholdMultiplier
  // );

  const addCollateral = useCallback(async () => {
    // if (!selectedTrade || !delegationFeeThreshhold) return;

    // const collateral = formatUnits(selectedTrade.positionSizeUsdc, fromToken.decimals);
    // const leverage = selectedTrade.leverage;
    // const positionSize = new BN(collateral).multipliedBy(leverage).toNumber();

    // * 포지션 사이즈와 상관없이 gasless
    addCollateralApiCall();

    // * 포지션 사이즈에 따라 gasless 분기
    // if (+positionSize >= delegationFeeThreshhold) {
    //   // console.log("addCollateralApiCall");
    //   addCollateralApiCall();
    // } else {
    //   // console.log("addCollateralContractCall");
    //   addCollateralContractCall();
    // }
  }, [addCollateralApiCall]);

  const removeCollateralOpts = useMemo(() => {
    if (!selectedTrade) return;

    return {
      setPendingTxns,
      sentMsg: t`Remove Collateral Submitted`,
      failMsg: t`Remove Collateral Failed`,
      successMsg: (
        <div>
          <div className="toastify-title">
            <Trans>Collateral Removed</Trans>
          </div>
          <div>
            <div className="toastify-body">
              <span>
                {inputValue} {fromToken.symbol} @ {selectedTrade.pair.from}/
                {selectedTrade.pair.to}
              </span>
              <span
                className={cx(
                  selectedTrade.buy ? "text-green-2" : "text-red-2",
                  "ml-[4px]"
                )}
              >
                {selectedTrade.buy ? t`LONG` : t`SHORT`}
              </span>
            </div>
          </div>
        </div>
      ),
      pairs,
      chainId,
    } as Opts;
  }, [
    chainId,
    fromToken.symbol,
    inputValue,
    pairs,
    selectedTrade,
    setPendingTxns,
  ]);

  const removeCollateralApiCall = useCallback(async () => {
    if (
      !tradingContract ||
      !selectedTrade ||
      !account ||
      !signer ||
      !removeCollateralOpts
    )
      return;

    const { pairIndex, positionIndex } = selectedTrade;
    const amount = parseUnits(inputValue, fromToken.decimals).toString();

    const deadline = getTradeDeadline(chainId);

    const params: GambitTradingV1Facet3.RemoveCollateralParamsStruct = {
      pairIndex,
      index: positionIndex,
      amount,
      deadline: deadline.toString(),
      salt: getSalt(),
    };

    try {
      setIsTxSubmitting({
        ...isTxSubmitting,
        removeCollateralTxIsSubmitting: true,
      });

      const signature = await signer._signTypedData(
        tradingContractTypedDomain,
        EIP712_TYPES_BY_FUNCTION.removeCollateral,
        params
      );

      try {
        const { data } = await axios.post<
          string | IRemoveCollateralApiFailResponse
        >(
          `${getAPIUrl(
            chainId
          )}/v1/gasless/trade/collateral?trader=${account}&signature=${signature}`,
          {
            add: false,
            ...params,
          } as IEditCollateralRequest
        );
        let status, hash, code;

        // * data가 string이 아니면 API 호출 실패한 것
        // * API 성공 / 실패 시 응답 형태가 달라서
        if (typeof data !== "string") {
          status = data.status;
          code = data.code;
        }
        // * data가 string이 아니면 API 호출 성공 케이스
        else {
          hash = data;
          status = 200;
        }

        if (status === 200) {
          handleContractResult(
            { hash } as ContractTransaction,
            removeCollateralOpts
          );
          setIsVisible(false);
        } else {
          handleApiResult(status, code);
        }
      } catch (e: any) {
        // * API 에러 처리
        const { response } = e;

        const { data } = response;

        handleTradeApiError(
          data.name || "something went wrong",
          data.details?.customError
            ? `${data.message}(${data.details?.customError})`
            : data.message || "something went wrong",
          chainId,
          data.details?.customError ? data.details?.customError : ""
        );

        Sentry.setContext(
          "/gasless/trade/collateral(remove) API Request Params",
          {
            signature,
            params,
          }
        );

        Sentry.setContext(
          "/gasless/trade/collateral(remove) API Error Response",
          response
        );

        sentryCaptureException({
          error: new Error("/gasless/trade/collateral(remove) API Error"),
          name: "Error Object",
          context: e,
        });
      }
    } catch (e: any) {
      // * 서명 에러 처리
      sentryCaptureException({
        error: new Error("Sign Error in removeCollateralApiCall"),
        name: "Error Object",
        context: e,
      });
    } finally {
      setIsTxSubmitting({
        ...isTxSubmitting,
        removeCollateralTxIsSubmitting: false,
      });
    }
  }, [
    account,
    chainId,
    fromToken.decimals,
    inputValue,
    isTxSubmitting,
    removeCollateralOpts,
    selectedTrade,
    setIsVisible,
    signer,
    tradingContract,
    tradingContractTypedDomain,
  ]);

  // const removeCollateralContractCall = useCallback(async () => {
  //   if (!tradingContract || !selectedTrade || !account || !removeCollateralOpts) return;

  //   const { pairIndex, positionIndex } = selectedTrade;
  //   const amount = parseUnits(inputValue, fromToken.decimals);

  //   const params: GambitTradingV1Facet3.AddCollateralParamsStruct = {
  //     pairIndex,
  //     index: positionIndex,
  //     amount,
  //     deadline: ethers.constants.MaxUint256,
  //     salt: randomBytes(32),
  //   };

  //   const removeCollateralParams: [string, GambitTradingV1Facet3.AddCollateralParamsStruct, string, string] = [
  //     account,
  //     params,
  //     "0x",
  //     "0x",
  //   ];

  //   try {
  //     setIsTxSubmitting({
  //       ...isTxSubmitting,
  //       removeCollateralTxIsSubmitting: true,
  //     });

  //     const res = await tradingContract.removeCollateral(...removeCollateralParams);
  //     handleContractResult(res, removeCollateralOpts);
  //     setIsVisible(false);
  //   } catch (e) {
  //     try {
  //       await tradingContract.callStatic.removeCollateral(...removeCollateralParams, {} as TxOptions);
  //     } catch (e) {
  //       handleContractError(e, chainId, removeCollateralOpts, "removeCollateral");
  //       throw e;
  //     }
  //     handleContractError(e, chainId, removeCollateralOpts, "removeCollateral");
  //   } finally {
  //     setIsTxSubmitting({
  //       ...isTxSubmitting,
  //       removeCollateralTxIsSubmitting: false,
  //     });
  //     setInputValue("");
  //   }
  // }, [
  //   account,
  //   chainId,
  //   fromToken.decimals,
  //   inputValue,
  //   isTxSubmitting,
  //   removeCollateralOpts,
  //   selectedTrade,
  //   setIsVisible,
  //   tradingContract,
  // ]);

  const removeCollateral = useCallback(async () => {
    // if (!selectedTrade || !delegationFeeThreshhold) return;
    // const collateral = formatUnits(selectedTrade.positionSizeUsdc, fromToken.decimals);
    // const leverage = selectedTrade.leverage;
    // const positionSize = new BN(collateral).multipliedBy(leverage).toNumber();

    // * 포지션 사이즈와 상관없이 gasless
    removeCollateralApiCall();

    // * 포지션 사이즈에 따라 gasless 분기
    // if (+positionSize >= delegationFeeThreshhold) {
    //   removeCollateralApiCall();
    // } else {
    //   removeCollateralContractCall();
    // }
  }, [removeCollateralApiCall]);

  const handleConfirmButton = useCallback(() => {
    if (!isActive) {
      if (openConnectModal) openConnectModal();
      return;
    }

    if (activeTab === EDIT_LIQ_TYPE.INCREASE) {
      addCollateral();
    }

    if (activeTab === EDIT_LIQ_TYPE.DECRERASE) {
      removeCollateral();
    }

    return;
  }, [isActive, activeTab, openConnectModal, addCollateral, removeCollateral]);

  const longOrShort = useMemo(() => {
    if (isVisible) {
      return selectedTrade?.buy ? t`LONG` : t`SHORT`;
    } else {
      return "None";
    }
  }, [isVisible, selectedTrade?.buy]);

  const disabled = useMemo(() => {
    if (!inputValue) {
      return true;
    }

    if (
      isTxSubmitting.addCollateralTxIsSubmitting ||
      isTxSubmitting.removeCollateralTxIsSubmitting
    ) {
      return true;
    }

    if (
      activeTab === EDIT_LIQ_TYPE.DECRERASE &&
      selectedTrade?.pair.fee.minLevPosUsdc &&
      newLeverage &&
      new BN(newLeverage)
        .multipliedBy(newCollateral)
        .lt(new BN(selectedTrade?.pair.fee.minLevPosUsdc))
    ) {
      return true;
    }

    if (
      (selectedTrade?.pair.group.maxLeverage &&
        newLeverage &&
        +newLeverage >= +selectedTrade?.pair.group.maxLeverage) ||
      (selectedTrade?.pair.group.minLeverage &&
        newLeverage &&
        +newLeverage < +selectedTrade?.pair.group.minLeverage)
    ) {
      return true;
    }

    if (activeTab === EDIT_LIQ_TYPE.INCREASE) {
      if (
        collateralBalance &&
        inputValue &&
        new BN(inputValue).gt(
          formatUnits(collateralBalance, fromToken.decimals)
        )
      )
        return true;
    }
    if (activeTab === EDIT_LIQ_TYPE.DECRERASE) {
      // * 너무 많은 담보를 빼서 leverage, collateral이 -가 되는 경우
      if (!newLeverage || !newCollateral) {
        return true;
      }
    }

    return false;
  }, [
    activeTab,
    collateralBalance,
    fromToken.decimals,
    inputValue,
    isTxSubmitting.addCollateralTxIsSubmitting,
    isTxSubmitting.removeCollateralTxIsSubmitting,
    newCollateral,
    newLeverage,
    selectedTrade?.pair.fee.minLevPosUsdc,
    selectedTrade?.pair.group.maxLeverage,
    selectedTrade?.pair.group.minLeverage,
  ]);

  const disabledMessage = useMemo(() => {
    // if (!inputValue) {
    //   return "Please enter an amount";
    // }

    if (activeTab === EDIT_LIQ_TYPE.INCREASE) {
      if (
        collateralBalance &&
        inputValue &&
        new BN(inputValue).gt(
          formatUnits(collateralBalance, fromToken.decimals)
        )
      )
        return t`Check the balance in your wallet`;
    }

    if (
      (selectedTrade?.pair.group.maxLeverage &&
        newLeverage &&
        +newLeverage >= +selectedTrade?.pair.group.maxLeverage) ||
      (selectedTrade?.pair.group.minLeverage &&
        newLeverage &&
        +newLeverage < +selectedTrade?.pair.group.minLeverage)
    ) {
      return t`You can adjust the collateral as long as the leverage is greater than or equal to ${unpadZero(
        selectedTrade.pair.group.minLeverage
      )} and less than or equal to ${unpadZero(
        selectedTrade.pair.group.maxLeverage
      )}`;
    }

    if (activeTab === EDIT_LIQ_TYPE.DECRERASE) {
      // * 너무 많은 담보를 빼서 leverage, collateral이 -가 되는 경우
      if (!newLeverage || !newCollateral) {
        return t`You can't decrease an amount greater than your collateral`;
      }
    }

    if (
      activeTab === EDIT_LIQ_TYPE.DECRERASE &&
      selectedTrade?.pair.fee.minLevPosUsdc &&
      newLeverage &&
      new BN(newLeverage)
        .multipliedBy(newCollateral)
        .lt(new BN(selectedTrade?.pair.fee.minLevPosUsdc))
    ) {
      return t`Position size must be >= ${selectedTrade?.pair.fee.minLevPosUsdc} ${fromToken.symbol}`;
    }

    return "";
  }, [
    activeTab,
    collateralBalance,
    fromToken.decimals,
    fromToken.symbol,
    inputValue,
    newCollateral,
    newLeverage,
    selectedTrade?.pair.fee.minLevPosUsdc,
    selectedTrade?.pair.group.maxLeverage,
    selectedTrade?.pair.group.minLeverage,
  ]);

  const maxDisabled = useMemo(() => {
    if (activeTab === EDIT_LIQ_TYPE.DECRERASE) return true;
    return false;
  }, [activeTab]);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const oracleFee = useMemo(() => {
    if (!selectedTrade) return;
    const tradePair = pairs.find(
      (pair) =>
        pair.from === selectedTrade.pair.from &&
        pair.to === selectedTrade.pair.to
    );
    if (!tradePair) return;
    return tradePair.fee?.oracleFee;
  }, [pairs, selectedTrade]);

  const [focused, setFocused] = useState(true);

  return (
    <ModalSkeletonWithPortal
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      styles={{ closeBtn: "top-[2.4rem]", closeBtnColor: "white" }}
    >
      {selectedTrade && (
        <div className="max-w-[36rem]">
          {/* Title */}
          <div className="pt-[1.6rem] pl-[2.4rem]">
            <div className="text-[2.4rem] font-bold">
              <Trans>Adjust Margin Position</Trans>
            </div>
          </div>

          <hr className="h-px mt-[1.2rem] mb-[1.6rem] bg-gray-70 border-0" />

          {/* Content */}
          <div className="px-[0.8rem]">
            <ul className="px-[1.6rem] text-[1.4rem] overflow-y-auto space-y-[0.4rem] mb-[1.6rem]">
              <li className="flex justify-between py-[0.5rem]">
                <span className="text-gray-15">
                  <Trans>Pair</Trans>
                </span>
                <span className="font-medium flex gap-[0.4rem]">
                  <div className="font-space-grotesk">
                    {selectedTrade.pair.from}/{selectedTrade.pair.to}
                  </div>
                  <div
                    className={cx(
                      longOrShort === t`LONG` ? "text-green-2" : "text-red-2"
                    )}
                  >
                    ({longOrShort})
                  </div>
                </span>
              </li>
              <li className="flex justify-between py-[0.5rem]">
                <span className="text-gray-15">
                  <Trans>Open Price</Trans>
                </span>

                <span className="font-space-grotesk font-medium">
                  {trimPriceBN(selectedTrade.openPrice, true)} USD
                </span>
              </li>
              <li className="flex justify-between py-[0.5rem]">
                <span className="text-gray-15">
                  <Trans>Leverage</Trans>
                </span>
                <span className="font-space-grotesk font-medium">
                  {unpadZero(selectedTrade.leverage.toFixed(2))}x
                </span>
              </li>
              <li className="flex justify-between py-[0.5rem]">
                <span className="text-gray-15">
                  <Trans>Collateral</Trans>
                </span>
                <span className="font-space-grotesk font-medium">
                  {formatAmount(
                    selectedTrade.positionSizeUsdc,
                    fromToken.decimals,
                    2,
                    true
                  )}{" "}
                  {fromToken.symbol}
                </span>
              </li>
            </ul>
          </div>

          <div className="px-[1.6rem]">
            <div className="flex justify-between heading9 gap-[0.8rem]">
              <div
                className={cx(
                  "py-[0.8rem] border mx-auto w-full text-center rounded-[0.8rem] cursor-pointer",
                  {
                    "border-gray-30 bg-gray-60":
                      activeTab === EDIT_LIQ_TYPE.INCREASE,
                  }
                )}
                onClick={() => {
                  inputRef.current?.select();
                  setActiveTAb(EDIT_LIQ_TYPE.INCREASE);
                }}
              >
                <Trans id={`msg.EditLiqPriceModal / Increase`}>Increase</Trans>
              </div>
              <div
                className={cx(
                  "py-[0.8rem] border mx-auto w-full text-center rounded-[0.8rem] cursor-pointer",
                  {
                    "border-gray-30 bg-gray-60":
                      activeTab === EDIT_LIQ_TYPE.DECRERASE,
                  }
                )}
                onClick={() => {
                  inputRef.current?.select();
                  setActiveTAb(EDIT_LIQ_TYPE.DECRERASE);
                }}
              >
                <Trans id={`msg.EditLiqPriceModal / Decrease`}>Decrease</Trans>
              </div>
            </div>

            {activeTab === EDIT_LIQ_TYPE.INCREASE && (
              <div className="text-right heading9 text-gray-20 mt-[0.8rem] mb-[0.4rem]">
                <span>
                  <Trans>Wallet balance</Trans>:{" "}
                  <span className="font-space-grotesk">
                    {formatAmount(
                      collateralBalance,
                      fromToken.decimals,
                      2,
                      true
                    )}
                  </span>
                </span>
              </div>
            )}

            {activeTab === EDIT_LIQ_TYPE.DECRERASE && (
              <div className="h-[0.8rem]" />
            )}

            <div className="flex justify-between gap-[0.8rem] mb-[0.4rem]">
              <div
                className={cx(
                  "border border-gray-60 rounded-[0.8rem] w-full",
                  "hover:bg-gradient-to-r hover:from-white/50 hover:to-white hover:border-0 hover:p-[1px]",
                  {
                    "bg-gradient-to-r from-white/50 to-white border-0 p-[1px]":
                      focused,
                  }
                )}
              >
                <input
                  ref={inputRef}
                  type="text"
                  pattern="^([0-9]+(?:[.,][0-9]*)?)$"
                  inputMode="decimal"
                  className="font-space-grotesk heading7 py-[1.1rem] px-[1.2rem] w-full rounded-[0.72rem] bg-black-2"
                  autoFocus
                  placeholder="0.0"
                  value={inputValue}
                  onChange={(e) => {
                    if (!/^([0-9]*(?:[.,][0-9]*)?)$/.test(e.target.value)) {
                      return;
                    }
                    // INFO: 소수점 6자리까지만 입력 허용
                    if (/^[\d]*\.?[\d]{0,2}$/.test(e.target.value)) {
                      setInputValue(e.target.value);
                    } else {
                      return;
                    }
                  }}
                  onFocus={(e) => {
                    setFocused(true);
                  }}
                  onBlur={() => {
                    setFocused(false);
                  }}
                />
              </div>
              <button
                disabled={maxDisabled}
                className={cx(
                  "flex items-center justify-center bg-gray-80 heading9 py-[0.8rem] w-[8rem]",
                  "border rounded-[0.8rem] border-gray-60 cursor-pointer text-gray-15"
                )}
                onClick={() => {
                  if (!collateralBalance) return;
                  const maxBalance = formatAmount(
                    collateralBalance,
                    fromToken.decimals,
                    2,
                    false
                  );

                  setInputValue(maxBalance);
                }}
              >
                <Trans>Max</Trans>
              </button>
            </div>
            {disabled && disabledMessage && (
              <div className="flex items-center text-red-2 small3">
                <InfoCircleSvg className="self-start mt-[1.75px] fill-red-2 mr-[0.4rem] min-w-[1.4rem]" />
                <span>{disabledMessage}</span>
                {/* Validation */}
                {/* 1. balance보다 작은 값인가 */}
              </div>
            )}
          </div>

          {/* {activeTab === EDIT_LIQ_TYPE.DECRERASE && (
            <div className="px-[1.6rem] mt-[0.8rem]">
              <div className="flex justify-between items-center px-[0.8rem] py-[0.4rem]">
                <span className="flex items-center heading9 text-gray-20 font-medium">
                  <span className="mr-[2px]">
                    <Trans>Fees Incurred</Trans>
                  </span>
                  {decreaseFeeInfoText}
                </span>
                <span className="heading7 text-gray-05 font-medium">
                  {decreaseFees} {fromToken.symbol}
                </span>
              </div>
            </div>
          )} */}

          {/* <hr className="h-px my-[1.6rem] mx-[1.6rem] bg-gray-70 border-0" /> */}

          <div className="px-[1.6rem]">
            <div className="flex justify-between items-center px-[0.8rem] py-[0.4rem] font-medium">
              <span className="heading9 text-gray-20">
                <Trans>Network Fee</Trans>
              </span>
              <span className="heading7 text-gray-05 font-medium font-space-grotesk">
                {oracleFee} {fromToken.symbol}
              </span>
            </div>

            <div className="flex justify-between items-center px-[0.8rem] py-[0.4rem] font-medium">
              <span className="heading9 text-gray-20">
                <Trans>Adjusted Leverage</Trans>
              </span>
              <span className="heading7 text-gray-05 font-medium font-space-grotesk">
                {newLeverage ? newLeverage : "- "}x
              </span>
            </div>

            <div className="flex justify-between items-center px-[0.8rem] py-[0.4rem] font-medium">
              {/* <span className="heading9 text-gray-20">
                <Trans>Adjusted Collateral</Trans>
              </span> */}

              <Tooltip
                handle={
                  <span className="flex items-center mr-[0.8rem] heading9 text-gray-20">
                    <Trans>Adjusted Collateral</Trans>
                    <InfoCircleSvg className="fill-gray-30 ml-[2px]" />
                  </span>
                }
                disableHandleStyle={true}
                position="left-top"
                tooltipClassName="!min-w-[31.5rem]"
                renderContent={() => (
                  <span className="text-[1.3rem]">
                    <Trans>
                      When decreasing collateral, the fee is subtracted from the
                      collateral.
                    </Trans>
                  </span>
                )}
              />
              <span className="heading7 text-gray-05 font-medium font-space-grotesk">
                {newCollateral ? `${newCollateral} ` : "- "} {fromToken.symbol}
              </span>
            </div>

            <div className="flex justify-between py-[0.4rem] px-[0.8rem]">
              <span className="heading9 text-gray-20">
                <Trans>Original Liq.</Trans>
              </span>
              {selectedTrade.liqPrice && (
                <span className="heading7 text-gray-05 font-medium font-space-grotesk">
                  {trimPriceBN(selectedTrade.liqPrice, true)} USD
                </span>
              )}
            </div>

            <div className="flex justify-between py-[0.95rem] px-[0.8rem] mt-[0.4rem]">
              <span className="heading9 text-gray-10 self-center">
                <Trans>New Expected Liq.</Trans>
              </span>
              <span className="heading2 font-space-grotesk whitespace-nowrap overflow-x-auto">
                {newLiqPrice ? newLiqPrice : "- "} USD
              </span>
            </div>
          </div>

          <div className="mt-[1.6rem] mb-[2.4rem] px-[1.6rem]">
            <button
              onClick={handleConfirmButton}
              className="flex justify-center items-center w-full mx-auto brand-btn h-[5.2rem]"
              disabled={disabled}
            >
              {isTxSubmitting.addCollateralTxIsSubmitting ||
              isTxSubmitting.removeCollateralTxIsSubmitting ? (
                <img className="mx-auto h-[0.8rem]" src={loader} alt="loader" />
              ) : (
                <Trans id={`msg.EditLiqPriceModal / Confirm`}>Confirm</Trans>
              )}
            </button>
            {/* <div className="flex mt-[0.4rem]">
              <img className="mr-[4px]" src={InvalidImage} alt="invalid" />
              <span className="heading9 text-gray-15 tracking-normal">Warning sentence</span>
            </div> */}
          </div>
        </div>
      )}
    </ModalSkeletonWithPortal>
  );
};
