import {
  MockUSDC,
  TokenWithdrawal,
} from "@changerio/futures-contracts/dist/typechain-types";
import { EIP712_TYPES_BY_FUNCTION } from "@changerio/futures-contracts/lib/eip-712-types";
import * as Dialog from "@radix-ui/react-dialog";
import Token from "abis/Token.json";
import {
  currentWalletAccountTabAtom,
  showConfirmWithdrawAtom,
  showWalletAccountAtom,
  withdrawRecipientAddressAtom,
} from "atoms";
import { pairsAtom, pendingTxnsAtom } from "atoms/exchange";
import axios from "axios";
import BN from "bignumber.js";
import cx from "classnames";
import "components/Common/radix-ui-dialog.scss";
import {
  PERMIT_DEADLINE_BUFFER,
  handleApiResult,
} from "components/Exchange/TradeBoxV2";
import { getAPIUrl } from "components/Exchange/constants";
import { getChainNameForMenu } from "config/chains";
import { getContract } from "config/contracts";
import { getToken } from "config/tokens";
import dayjs from "dayjs";
import { BigNumber, ContractTransaction } from "ethers";
import {
  IApiFailResponse,
  IWithdrawRequest,
} from "futures-domain/trades/api-types";
import { useChainId } from "futures-lib/chains";
import {
  Opts,
  contractFetcher,
  handleContractResult,
  handleTradeApiError,
} from "futures-lib/contracts";
import {
  useGetContract,
  useGetTypedDomain,
} from "futures-lib/contracts/contract";
import { formatAmount, numberWithCommas } from "futures-lib/numbers";
import useWallet from "futures-lib/wallets/useWallet";
import loader from "img/ic-loader.svg";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import useSWR from "swr";
import { TAB_TYPES } from "./WalletAccount";

const ConfirmWithdraw = () => {
  const [showConfirmWithdraw, setShowConfirmWithdraw] = useAtom(
    showConfirmWithdrawAtom
  );
  const setShowWalletAccount = useSetAtom(showWalletAccountAtom);
  const setCurrentWalletAccountTab = useSetAtom(currentWalletAccountTabAtom);
  const { chainId } = useChainId();
  const fromTokenAddress = getContract(chainId, "CollateralToken");
  const fromToken = getToken(chainId, fromTokenAddress);
  const [withdrawRecipientAddress, setWithdrawRecipientAddress] = useAtom(
    withdrawRecipientAddressAtom
  );
  const [isTxSubmitting, setIsTxSubmitting] = useState(false);

  useEffect(() => {
    if (!showConfirmWithdraw) {
      setWithdrawRecipientAddress("");
    }
  }, [setWithdrawRecipientAddress, showConfirmWithdraw]);

  const { isActive, account, signer } = useWallet();

  const { data: collateralBalance } = useSWR<BigNumber>(
    isActive && [isActive, chainId, fromTokenAddress, "balanceOf", account],
    {
      fetcher: contractFetcher(signer, Token),
      shouldRetryOnError: true,
      errorRetryInterval: 500,
      refreshInterval: 2000,
    }
  );

  const tokenWithdrawalContract = useGetContract(
    "TokenWithdrawal"
  ) as TokenWithdrawal;
  // console.log(tokenWithdrawalContract);
  const setPendingTxns = useSetAtom(pendingTxnsAtom);

  const { data: fromTokenAllowance } = useSWR<BigNumber>(
    showConfirmWithdraw &&
      isActive &&
      tokenWithdrawalContract && [
        isActive,
        chainId,
        fromTokenAddress,
        "allowance",
        account,
        tokenWithdrawalContract?.address,
      ],
    {
      fetcher: contractFetcher(signer, Token),
      refreshInterval: 5000,
      errorRetryInterval: 5000,
    }
  );
  // useEffect(() => {
  //   console.log(fromTokenAllowance);
  // }, [fromTokenAllowance]);
  const collateralContract = useGetContract("MockUSDC") as MockUSDC;

  const withdrawOpts = useMemo(() => {
    return {
      sentMsg: "",
      failMsg: "",
      successMsg: "",
      hideSuccessMsg: true, // 이벤트를 받아서 따로 토스트 메세징 처리하기 때문에
      chainId,
      setPendingTxns,
    } as Opts;
  }, [chainId, setPendingTxns]);

  const pairs = useAtomValue(pairsAtom);

  const delegationFee = useMemo(() => {
    if (!showConfirmWithdraw) return;

    let delegationFee: string;
    const btcPair = pairs.find(
      (pair) => pair.from === "BTC" && pair.to === "USD"
    );
    if (btcPair) {
      delegationFee = btcPair.fee.oracleFee;
      return delegationFee;
    } else {
      return;
    }
  }, [pairs, showConfirmWithdraw]);

  const { collateralContractTypedDomain } = useGetTypedDomain();

  const withdraw = useCallback(async () => {
    // console.log(
    //   tokenWithdrawalContract,
    //   signer,
    //   account,
    //   collateralBalance?.toString(),
    //   fromTokenAllowance?.toString()
    // );

    if (
      !tokenWithdrawalContract ||
      !collateralContractTypedDomain ||
      !signer ||
      !account ||
      !collateralBalance ||
      fromTokenAllowance === undefined
    )
      return;

    // let permitSignature;

    const deadline = dayjs()
      .add(PERMIT_DEADLINE_BUFFER, "week")
      .unix()
      .toString(); // seconds 단위
    let permitParams, permitSignature;
    try {
      setIsTxSubmitting(true);

      // TODO: withraw amount를 받을 거면 balance가 아니라 amount(value)랑 비교
      if (
        new BN(fromTokenAllowance.toString()).lt(collateralBalance.toString())
      ) {
        // * approve
        permitParams = {
          owner: account,
          spender: tokenWithdrawalContract.address,
          value: collateralBalance.toString(),
          nonce: (await collateralContract.nonces(account)).toNumber(),
          deadline,
        };

        permitSignature = await signer._signTypedData(
          collateralContractTypedDomain,
          EIP712_TYPES_BY_FUNCTION.ERC20Permit,
          permitParams
        );
      }

      // * withdraw
      const params: TokenWithdrawal.WithdrawWithPermitParamsStruct = {
        receiver: withdrawRecipientAddress,
        nonce: (await tokenWithdrawalContract.nonces(account)).toNumber(),
      };

      const paramsSignature = await signer._signTypedData(
        {
          name: "Token Withdrawal",
          version: "1",
          chainId,
          verifyingContract: tokenWithdrawalContract.address,
        },
        EIP712_TYPES_BY_FUNCTION.withdrawWithPermit,
        params
      );

      // * error handling + api result handling
      const { data } = await axios.post<string | IApiFailResponse>(
        `${getAPIUrl(
          chainId
        )}/v1/gasless/usdc/withdraw?trader=${account}&signature=${paramsSignature}`,
        {
          permit:
            // TODO: withraw amount를 받을 거면 balance가 아니라 amount(value)랑 비교
            new BN(fromTokenAllowance.toString()).lt(
              collateralBalance.toString()
            )
              ? {
                  signature: permitSignature,
                  deadline: permitParams.deadline,
                  amount: permitParams.value,
                  nonce: permitParams.nonce,
                }
              : undefined,
          params,
        } as IWithdrawRequest
      );

      let status, hash, code;

      // * data가 string(hash)이 아니면 API 호출 실패한 것
      // * API 성공 / 실패 시 응답 형태가 달라서
      if (typeof data !== "string") {
        status = data.status;
        // hash = data.hash;
        code = data.code;
      }
      // * data가 string이면 API 호출 성공 케이스
      else {
        hash = data;
        status = 200;
      }

      if (status === 200) {
        handleContractResult({ hash } as ContractTransaction, withdrawOpts);
        setShowConfirmWithdraw(false);
      } else {
        handleApiResult(status, code);
      }
    } catch (e: any) {
      try {
        const {
          response: { data },
        } = e;

        handleTradeApiError(
          data.name,
          data.details?.customError
            ? `${data.message}(${data.details?.customError})`
            : data.message,
          chainId,
          data.details?.customError ? data.details?.customError : ""
        );
      } catch (e: any) {
        throw new Error(e);
      }
      throw new Error(e);
    } finally {
      setTimeout(() => setIsTxSubmitting(false), 1000);
    }
  }, [
    account,
    chainId,
    collateralBalance,
    collateralContract,
    collateralContractTypedDomain,
    fromTokenAllowance,
    setShowConfirmWithdraw,
    signer,
    tokenWithdrawalContract,
    withdrawOpts,
    withdrawRecipientAddress,
  ]);

  const amountToSend = useMemo(() => {
    if (!collateralBalance || !delegationFee) return;
    const balance = formatAmount(
      collateralBalance?.toNumber(),
      fromToken.decimals,
      2,
      false
    );

    return numberWithCommas(new BN(+balance - +delegationFee).toString());
  }, [collateralBalance, delegationFee, fromToken.decimals]);

  return (
    <Dialog.Root
      open={showConfirmWithdraw}
      onOpenChange={setShowConfirmWithdraw}
    >
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content
          className={cx(
            "w-full bottom-0 top-auto left-auto transform-none",
            // "DialogContent2",
            "xxs+:!top-1/2 xxs+:!left-1/2 xxs+:!-translate-x-1/2	xxs+:!-translate-y-1/2 xxs+:!transform xxs+:!bottom-auto",
            "font-space-grotesk",
            "z-[99] fixed focus-visible:outline-none",
            "xxs+:w-[39.8rem] xxs+:rounded-[1rem] xxs+:bg-gray-90"
            // "xs-:!right-[3rem]"
          )}
        >
          <div className="flex w-full pt-[1.6rem] pr-0 pb-[2.4rem] pl-0 flex-col gap-[1.2rem] items-center bg-gray-70 xxs+:bg-[rgba(23,27,38,0.95)] rounded-[1.2rem] border-solid border border-[rgba(255,255,255,0.1)] mx-auto my-0">
            <div className="flex w-full justify-between items-center px-[2rem]">
              <div className="flex items-center">
                <IoIosArrowBack
                  onClick={() => {
                    setShowConfirmWithdraw(false);
                    setShowWalletAccount(true);
                    setCurrentWalletAccountTab(TAB_TYPES.WITHDRAW);
                  }}
                  className="xxs+:hidden text-gray-10 w-[2rem] h-[2rem] mr-[0.4rem]"
                />

                <span className="h-[2.6rem] text-[1.7rem] xxs+:text-[2rem] font-medium leading-[25.52px] text-white text-left">
                  Confirm Token Withdrawal
                </span>
              </div>
              <div
                onClick={() => {
                  setShowConfirmWithdraw(false);
                }}
                className="cursor-pointer w-[2.4rem] h-[2.4rem] bg-[url(img/ic-modal-close.png)] bg-cover overflow-hidden"
              />
            </div>
            <div className="w-full flex flex-col gap-[1.2rem] items-center">
              <div className="w-full h-px xxs+:bg-gray-70 bg-gray-60" />
              <div className="w-full flex flex-col gap-[2rem] items-center px-[2rem]">
                <div className="flex flex-col gap-[1.2rem] items-start overflow-hidden">
                  <div className="text-[1.2rem] font-normal leading-[15.312px] text-left">
                    <span className=" text-[1.2rem] font-normal leading-[15.312px] text-gray-10 text-left">
                      You are about to withdraw the following amounts to the
                      specified wallet address. Note that a withdrawal fee of{" "}
                      {delegationFee ? delegationFee : ""} {fromToken.symbol}{" "}
                      will be deducted from the total amount sent. This action
                      is irreversible. Please confirm to proceed.
                    </span>
                    <div className="flex flex-col gap-[.5rem] my-[1rem]">
                      <div>
                        <span className=" text-[1.2rem] font-medium leading-[15.312px] text-gray-00 text-left">
                          Transfer Network
                        </span>
                        <span className=" text-[1.2rem] font-normal leading-[15.312px] text-gray-10 text-left">
                          : {getChainNameForMenu(chainId)}
                          <br />
                        </span>
                      </div>
                      <div>
                        <span className=" text-[1.2rem] font-medium leading-[15.312px] text-gray-00 text-left">
                          Recipient Address
                        </span>
                        <span className=" text-[1.2rem] font-normal leading-[15.312px] text-gray-10 text-left">
                          : {withdrawRecipientAddress}
                          <br />
                        </span>
                      </div>
                      <div>
                        <span className=" text-[1.2rem] font-medium leading-[15.312px] text-gray-00 text-left">
                          Amount to Send
                        </span>
                        <span className=" text-[1.2rem] font-normal leading-[15.312px] text-gray-10 text-left">
                          (after fees): {amountToSend} {fromToken.symbol}
                        </span>
                      </div>
                    </div>
                    <span>
                      Please review the transaction details carefully. <br />
                      Are you sure you want to proceed?
                    </span>
                  </div>
                </div>
                <div className="w-full flex gap-[1.2rem] justify-center items-start">
                  <button
                    onClick={() => {
                      setShowConfirmWithdraw(false);
                    }}
                    className="flex w-[17.3rem] h-[46px] pt-[.8rem] pr-[1.6rem] pb-[.8rem] pl-[1.6rem] flex-col gap-[1rem] justify-center items-center bg-gray-50 xxs+:bg-gray-80 rounded-[1.2rem] border-none pointer"
                  >
                    <div className="flex gap-[.8rem] items-center">
                      <div className="flex gap-[.8rem] justify-center items-center grow basis-0">
                        <span className="h-[2rem] text-[1.6rem] font-semibold leading-[2rem] text-white text-left">
                          Cancel
                        </span>
                      </div>
                    </div>
                  </button>
                  <button
                    disabled={isTxSubmitting}
                    onClick={withdraw}
                    className="flex w-[17.3rem] h-[46px] pt-[.8rem] pr-[1.6rem] pb-[.8rem] pl-[1.6rem] flex-col gap-[1rem] justify-center items-center bg-white rounded-[1.2rem] border-none pointer"
                  >
                    <div className="flex gap-[.8rem] items-center">
                      <div className="flex gap-[.8rem] justify-center items-center grow basis-0">
                        <span className="h-[2rem] text-[1.6rem] font-semibold leading-[2rem] text-[#000] text-left flex items-center">
                          {isTxSubmitting ? (
                            <img
                              className="mx-auto h-[0.8rem]"
                              src={loader}
                              alt="loader"
                            />
                          ) : (
                            "Confirm"
                          )}
                        </span>
                      </div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default ConfirmWithdraw;
