import { MockUSDC, TokenWithdrawal } from "@changerio/futures-contracts/dist/typechain-types";
import Token from "abis/Token.json";
import { getContract } from "config/contracts";
import { BigNumber, ethers } from "ethers";
import { useChainId } from "futures-lib/chains";
import { contractFetcher } from "futures-lib/contracts";
import { useGetContract } from "futures-lib/contracts/contract";
import useWallet from "futures-lib/wallets/useWallet";
import { useCallback, useState } from "react";
import useSWR from "swr";

const ApproveRevoke = () => {
  const { isActive, signer, account } = useWallet();
  const { chainId } = useChainId();
  const [isTxSubmitting, setIsTxSubmitting] = useState(false);

  const fromTokenAddress = getContract(chainId, "CollateralToken");
  const tokenWithdrawalContract = useGetContract("TokenWithdrawal") as TokenWithdrawal;
  const collateralContract = useGetContract("MockUSDC") as MockUSDC;
  const tradingStorageAddress = getContract(chainId, "Storage");

  const { data: fromTokenAllowance } = useSWR<BigNumber>(
    isActive && [isActive, chainId, fromTokenAddress, "allowance", account, tradingStorageAddress],
    {
      fetcher: contractFetcher(signer, Token),
      refreshInterval: 2000,
      errorRetryCount: 1000,
      errorRetryInterval: 500,
    }
  );

  const { data: fromTokenAllowanceForWithdrawal } = useSWR<BigNumber>(
    isActive &&
      tokenWithdrawalContract && [
        isActive,
        chainId,
        fromTokenAddress,
        "allowance",
        account,
        tokenWithdrawalContract.address,
      ],
    {
      fetcher: contractFetcher(signer, Token),
      refreshInterval: 2000,
      errorRetryCount: 1000,
      errorRetryInterval: 500,
    }
  );

  // console.log(fromTokenAllowance?.toString());

  const revoke = useCallback(async () => {
    try {
      setIsTxSubmitting(true);
      await collateralContract.approve(tradingStorageAddress, 0);
      alert("Revoked!");
    } catch (e) {
    } finally {
      setIsTxSubmitting(false);
    }
  }, [collateralContract, tradingStorageAddress]);

  const approve = useCallback(async () => {
    try {
      setIsTxSubmitting(true);
      await collateralContract.approve(tradingStorageAddress, ethers.constants.MaxUint256);
      alert("Approved!");
    } catch (e) {
    } finally {
      setIsTxSubmitting(false);
    }
  }, [collateralContract, tradingStorageAddress]);

  const revokeForWithdrawal = useCallback(async () => {
    try {
      setIsTxSubmitting(true);
      await collateralContract.approve(tokenWithdrawalContract?.address, 0);
      alert("Revoked!");
    } catch (e) {
    } finally {
      setIsTxSubmitting(false);
    }
  }, [collateralContract, tokenWithdrawalContract?.address]);

  const approveForWithdrawal = useCallback(async () => {
    try {
      setIsTxSubmitting(true);
      await collateralContract.approve(tokenWithdrawalContract?.address, ethers.constants.MaxUint256);
      alert("Approved!");
    } catch (e) {
    } finally {
      setIsTxSubmitting(false);
    }
  }, [collateralContract, tokenWithdrawalContract?.address]);

  return (
    <div className="flex flex-col mt-[10rem]">
      {fromTokenAllowance?.toString() === ethers.constants.Zero.toString() && (
        <button onClick={approve} disabled={isTxSubmitting}>
          {isTxSubmitting ? "Loading..." : "Approve for storage contract"}
        </button>
      )}
      {fromTokenAllowance?.toString() !== ethers.constants.Zero.toString() && (
        <button disabled={isTxSubmitting} onClick={revoke}>
          {isTxSubmitting ? "Loading..." : "Revoke for storage contract"}
        </button>
      )}

      {fromTokenAllowanceForWithdrawal?.toString() === ethers.constants.Zero.toString() && (
        <button onClick={approveForWithdrawal} disabled={isTxSubmitting}>
          {isTxSubmitting ? "Loading..." : "Approve for withdrawal contract"}
        </button>
      )}
      {fromTokenAllowanceForWithdrawal?.toString() !== ethers.constants.Zero.toString() && (
        <button disabled={isTxSubmitting} onClick={revokeForWithdrawal}>
          {isTxSubmitting ? "Loading..." : "Revoke for withdrawal contract"}
        </button>
      )}
    </div>
  );
};

export default ApproveRevoke;
