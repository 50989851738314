import { GambitTradingV1 } from "@changerio/futures-contracts/dist/typechain-types";
import { Trans } from "@lingui/macro";
import { selectedPairAtom } from "atoms/exchange";
import BN from "bignumber.js";
import cx from "classnames";
import { BigNumber } from "ethers";
import { newContractFetcher } from "futures-lib/contracts";
import {
  useDelegationFeeThreshhold,
  useGetContract,
} from "futures-lib/contracts/contract";
import { numberWithCommas } from "futures-lib/numbers";
import { useAtomValue } from "jotai";
import { useMemo } from "react";
import { useWindowSize } from "react-use";
import useSWR from "swr";

// * 컨텐츠 width: 96 + 8 + 128 = 232px
const CONTENTS_WIDTH = 232;
const PADDING_LEFT = 20;
const PRESENTS_PADDING = 12;

const GasFreeTextForPositionTable = () => {
  const { width } = useWindowSize();
  // const [ref, { width }] = useMeasure();
  // console.log(width);

  const mobilePaddingLeft = useMemo(() => {
    if (width > 700) {
      return 0;
    } else if (width >= 480) {
      return (
        (width - CONTENTS_WIDTH) / 2 - PADDING_LEFT - PRESENTS_PADDING - 15
      );
    } else {
      return (width - CONTENTS_WIDTH) / 2 - PADDING_LEFT - PRESENTS_PADDING;
    }
  }, [width]);

  const selectedPair = useAtomValue(selectedPairAtom);

  const tradingContract = useGetContract("GambitTradingV1") as GambitTradingV1;

  const { data: delegationFeeThresholdMultiplier } = useSWR<BigNumber>(
    tradingContract && [
      tradingContract,
      "delegationFeeThresholdMultiplier",
      [],
    ],
    {
      fetcher: newContractFetcher,
      shouldRetryOnError: true,
      errorRetryInterval: 3000,
      refreshInterval: 3000,
    }
  );

  // * crypto 7,500
  const delegationFeeThreshhold = useDelegationFeeThreshhold(
    selectedPair?.fee.minLevPosUsdc,
    delegationFeeThresholdMultiplier
  );

  const isEqualMinLevPosUsdcAndDelegationFeeThreshold = useMemo(() => {
    if (
      selectedPair?.fee.minLevPosUsdc === undefined ||
      delegationFeeThreshhold === undefined
    )
      return false;

    return new BN(delegationFeeThreshhold).isEqualTo(
      selectedPair.fee.minLevPosUsdc
    );
  }, [delegationFeeThreshhold, selectedPair?.fee.minLevPosUsdc]);

  return (
    <div
      // ref={ref as any}
      className={cx(`flex items-center sm+:justify-center`)}
      style={{ paddingLeft: `${mobilePaddingLeft}px` }}
    >
      <div>
        <div className="mx-auto w-[10.8rem] rounded-[6px] bg-gradient-to-r from-brand-0 to-brand-1 p-[1px]">
          <div className="flex items-center justify-center bg-gray-90 back rounded-[6px]">
            <div className={cx("pre-animate-shine animate-shine")}>
              <div
                className={cx(
                  "cursor-default",
                  "heading8 font-medium py-[0.35rem]",
                  "bg-gradient-to-r from-brand-0 to-brand-1 inline-block text-transparent bg-clip-text"
                )}
              >
                <Trans>Gambit Event</Trans>
              </div>
            </div>
          </div>
        </div>
        {delegationFeeThreshhold && (
          <div className="cursor-default mt-[1.2rem] heading2 text-white">
            {isEqualMinLevPosUsdcAndDelegationFeeThreshold ? (
              <span>
                Gambit has <b>Zero Gas Fees</b> on all transactions. Trade now!
              </span>
            ) : (
              <Trans>
                ${numberWithCommas(delegationFeeThreshhold)}+ Position Size =
                Zero Network Fee
              </Trans>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default GasFreeTextForPositionTable;
