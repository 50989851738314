import { useConnectModal } from "@rainbow-me/rainbowkit";
import { termsAgreementModalVisibleAtom } from "atoms";
import { CHECK_TERMS_AND_CONDITIONS_AGREEMENT } from "config/localStorage";
import { useSetAtom } from "jotai";
import { useCallback } from "react";
// import { useLocalStorage } from "react-use";

const useTermsConnectModal = () => {
  const setIsVisible = useSetAtom(termsAgreementModalVisibleAtom);
  const { openConnectModal } = useConnectModal();

  // const [checkedTermsAndConditions] = useLocalStorage("CHECK-TERMS-AND-CONDITIONS-AGREEMENT", false);

  const _openConnectModal = useCallback(() => {
    let checkedTermsAndConditions = window.localStorage.getItem(CHECK_TERMS_AND_CONDITIONS_AGREEMENT);
    if (checkedTermsAndConditions) checkedTermsAndConditions = JSON.parse(checkedTermsAndConditions);

    if (checkedTermsAndConditions) {
      if (openConnectModal) openConnectModal();
    } else {
      setIsVisible(true);
    }
  }, [openConnectModal, setIsVisible]);

  return {
    openConnectModal: _openConnectModal,
  };
};

export default useTermsConnectModal;
