import cx from "classnames";
import { activeOrderTabAtom } from "atoms/exchange";
import { useAtom } from "jotai";
import { ORDER_OPTIONS } from "futures-lib/legacy";
import { useLingui } from "@lingui/react";

export default function OrderTab() {
  let [activeOrderTab, setActiveOrderTab] = useAtom(activeOrderTabAtom);
  const { i18n } = useLingui();

  return (
    <div
      className={cx(
        "flex items-center justify-between mt-[2.4rem] mb-[1.6rem] text-gray-15 font-medium text-[1.4rem] text-center cursor-pointer border-b border-gray-50 border-opacity-85"
      )}
    >
      {ORDER_OPTIONS.map((orderTab) => {
        return (
          <div
            className={cx("flex-1 pb-[4px]", { "text-white border-b-[2px] border-white": orderTab === activeOrderTab })}
            onClick={() => setActiveOrderTab(orderTab)}
            key={orderTab}
          >
            {i18n._(orderTab)}
          </div>
        );
      })}
    </div>
  );
}
