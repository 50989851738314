import styled from "@emotion/styled";
import { formatUnits } from "@ethersproject/units";
import {
  midnightPairsPricesAtom,
  pairsAtom,
  pairsPricesAtom,
  selectedPairAtom,
  selectedPairPriceChangeAtom,
} from "atoms/exchange";
import BN from "bignumber.js";
import cx from "classnames";
import { getConstant } from "config/chains";
import { BigNumber } from "ethers";
import { useChainId } from "futures-lib/chains";
import { swrFetcher } from "futures-lib/legacy";
import { useAtomValue, useSetAtom } from "jotai";
import _, { findKey } from "lodash";
import { useEffect, useMemo, useRef } from "react";
import Slider, { Settings } from "react-slick";
import { useMeasure, useWindowSize } from "react-use";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import useSWR from "swr";
import PairPrice from "./PairPrice";

const RollingPairsPrices = () => {
  const pairs = useAtomValue(pairsAtom);
  const [ref, { width }] = useMeasure();

  // const [nextSlide, setNextSlide] = useState<number>();
  // const [changeState, setChangeState] = useState<"beforeChange" | "afterChange">("beforeChange");

  const { chainId } = useChainId();

  const { width: windowWidth } = useWindowSize();

  const WIDTH_PER_PAIR = useMemo(() => {
    // * 가격 변동이 영역이 없는 경우: Pair 박스 하나를 165px 정도로 가정(141px + 24px) - pyth 테스트넷
    // * 가격 변동이 영역이 있는 경우: 200px - pyth 테스트넷

    // return windowWidth > 445 ? 200 : 165;
    // return windowWidth > 445 ? 270 : 165;

    return windowWidth > 445 ? 180 : 165;
  }, [windowWidth]);

  const settings: Settings = useMemo(() => {
    return {
      arrows: false,
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      vertical: true,
      verticalSwiping: true,
      autoplay: true,
      speed: 3000,
      autoplaySpeed: 4000,
    };
  }, []);

  const numOfPairsForRow = useMemo(() => {
    if (!width) return;

    return new BN(width / WIDTH_PER_PAIR).toFixed(0, BN.ROUND_FLOOR);
  }, [WIDTH_PER_PAIR, width]);

  const selectedPair = useAtomValue(selectedPairAtom);

  const pairRows = useMemo(() => {
    if (!numOfPairsForRow || !pairs) return [];
    const _pairs = pairs.filter(
      (pair) => pair.pairIndex !== selectedPair?.pairIndex
    );
    return _.chunk(_pairs, +numOfPairsForRow);
  }, [numOfPairsForRow, pairs, selectedPair?.pairIndex]);

  const sliderRef = useRef(null);

  const slickPlay = () => {
    (sliderRef as any).current?.slickPlay();
  };

  const pairsPricesEndpoint = useMemo(() => {
    return `${getConstant(chainId, "eventApiServerUrl")}/pair/price/midnight`;
  }, [chainId]);

  const setMidnightPairsPrices = useSetAtom(midnightPairsPricesAtom);
  const setSelectedPairPriceChange = useSetAtom(selectedPairPriceChangeAtom);

  const { data, isLoading } = useSWR(`${pairsPricesEndpoint}`, {
    fetcher: swrFetcher,
    refreshInterval: 60 * 1000 * 5,
  });

  useEffect(() => {
    if (data && !isLoading) {
      setMidnightPairsPrices(data);
    }
  }, [data, isLoading, setMidnightPairsPrices]);

  const pairsPrices = useAtomValue(pairsPricesAtom);

  const selectedPairPrice = useMemo(() => {
    if (!selectedPair || !pairsPrices[selectedPair.priceFeedId!]) return;

    const { price, expo } = pairsPrices[selectedPair.priceFeedId!];
    return formatUnits(BigNumber.from(price), Math.abs(expo));
  }, [pairsPrices, selectedPair]);

  const selectedPairMidnightPrice = useMemo(() => {
    try {
      if (!data || !selectedPair) return;

      // * 네트워크마다 pairIndex가 달라서 pairIndex로 페어를 찾지 않고 이름으로 찾음
      const key = findKey(
        data,
        (midnightPairsPrice) =>
          midnightPairsPrice.name === `${selectedPair.from}/${selectedPair.to}`
      );
      if (!key) return;

      const { price, expo } = data[key]?.price;
      return formatUnits(BigNumber.from(price), Math.abs(expo));
    } catch (e) {
      return "";
    }
  }, [data, selectedPair]);

  useEffect(() => {
    if (!selectedPairPrice || !selectedPairMidnightPrice) return;

    const result = new BN(selectedPairPrice)
      .minus(selectedPairMidnightPrice)
      .dividedBy(selectedPairMidnightPrice)
      .multipliedBy(100)
      .toFixed(2);
    if (new BN(result).eq(0)) {
      setSelectedPairPriceChange("0.00");
    } else {
      setSelectedPairPriceChange(result);
    }
  }, [
    selectedPairMidnightPrice,
    selectedPairPrice,
    setSelectedPairPriceChange,
  ]);

  return (
    <div
      className={cx(
        "w-full h-[5.2rem] xs-:h-[4.2rem] px-[1.5rem] bg-black-3 xs:mr-[-3rem]"
      )}
      ref={ref as any}
    >
      {/* {hoverable} */}
      <CSlider ref={sliderRef} className="h-full" {...settings}>
        {pairRows.map((pairRow) => {
          return (
            <div
              className={cx(
                "!flex h-[5.2rem] xs-:h-[4.2rem] items-center gap-[2.4rem]"
              )}
              key={pairRow.map((pair) => pair.pairIndex).toString()}
            >
              {pairRow.map((pair, rowIndex) => {
                return (
                  <div key={`${rowIndex}${pair.pairIndex}`}>
                    <PairPrice slickPlay={slickPlay} pair={pair} />
                  </div>
                );
              })}
            </div>
          );
        })}
      </CSlider>
    </div>
  );
};

export default RollingPairsPrices;

const CSlider = styled(Slider)`
  .slick-slide {
    border: 0px;
  }
  .slick-list {
    height: 100% !important;
  }
`;
