// Web3Auth Libraries
import { Web3AuthConnector } from "@web3auth/web3auth-wagmi-connector";
import { Web3AuthNoModal } from "@web3auth/no-modal";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import { OpenloginAdapter, OPENLOGIN_NETWORK } from "@web3auth/openlogin-adapter";
import { CHAIN_NAMESPACES } from "@web3auth/base";
// import { TorusWalletConnectorPlugin } from "@web3auth/torus-wallet-connector-plugin";
import { Chain, Connector } from "wagmi";
import { SELECTED_NETWORK_LOCAL_STORAGE_KEY } from "config/localStorage";
import { DEFAULT_CHAIN_ID } from "config/chains";

export default function Web3AuthConnectorInstance(chains: Chain[]) {
  const chainId = window.localStorage.getItem(SELECTED_NETWORK_LOCAL_STORAGE_KEY) || DEFAULT_CHAIN_ID;

  const initialChain =
    chains.find((chain) => {
      return chain.id === +chainId;
    }) ?? chains[0];

  const chainConfig = {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: "0x" + initialChain.id.toString(16),
    rpcTarget: initialChain.rpcUrls.default.http[0], // This is the public RPC we have added, please pass on your own endpoint while creating an app
    displayName: initialChain.name,
    tickerName: initialChain.nativeCurrency?.name,
    ticker: initialChain.nativeCurrency?.symbol,
    blockExplorer: initialChain.blockExplorers?.default.url[0] as string,
  };

  const web3AuthInstance = new Web3AuthNoModal({
    // clientId: "BPi5PB_UiIZ-cPz1GtV5i1I2iOSOHuimiXBI0e-Oe_u6X3oVAbCiAZOTEBtTXw4tsluTITPqA8zMsfxIKMjiqNQ",
    clientId: process.env.REACT_APP_WEB3AUTH_CLIENT_ID!,
    /**
     * * sessionTime이 디폴트로 86400이라고는 하는데, 명시적으로 넣어주지 않으면 connector.options.coreOptions.sessionTime이 없음
     * * sessionTime이 없으면, 종종 로그인 시 Invalid login session 에러가 발생하는 것 같음
     * * 지속적으로 확인 필요
     * * https://web3auth.io/community/t/openlogin-api-invalid-login-session-please-restart-the-login-process-again/5992/6
     * * https://web3auth.io/community/search?q=Invalid%20login%20session.
     *
     * * web3auth 서버로 세션 지우라는 요청을 보내는 지 테스트
     * * 테스트 1) Web3AuthNoModal.logout()
     * * -> ((emailWeb3authConnector as any).web3AuthInstance as Web3AuthNoModal).logout();
     * * 테스트 2) Web3AuthConnector.disconnect()
     * * -> (emailWeb3authConnector as any).disconnect();
     */
    sessionTime: 86400,
    chainConfig,
    web3AuthNetwork: OPENLOGIN_NETWORK.SAPPHIRE_MAINNET,
  });

  // Add openlogin adapter for customisations
  const privateKeyProvider = new EthereumPrivateKeyProvider({ config: { chainConfig } });
  const openloginAdapterInstance = new OpenloginAdapter({
    privateKeyProvider,
    adapterSettings: {
      uxMode: "redirect",
      // whiteLabel: {
      //   appName: "GAMBIT",
      // },
    },
  });
  web3AuthInstance.configureAdapter(openloginAdapterInstance);

  // Add Torus Wallet Plugin (optional)
  // const torusPlugin = new TorusWalletConnectorPlugin({
  //   torusWalletOpts: {
  //     buttonPosition: "bottom-left",
  //   },
  //   walletInitOptions: {
  //     whiteLabel: {
  //       theme: {
  //         isDark: true,
  //         colors: {
  //           primary: "#00a8ff",
  //         },
  //       },
  //       logoLight: "https://web3auth.io/images/web3auth-logo.svg",
  //       logoDark: "https://web3auth.io/images/web3auth-logo---Dark.svg",
  //       defaultLanguage: "en",
  //     },
  //     useWalletConnect: true,
  //     // enableLogging: true,
  //   },
  // });
  // web3AuthInstance.addPlugin(torusPlugin);

  const googleWeb3AuthConnector = new Web3AuthConnector({
    chains: chains,
    options: {
      web3AuthInstance,
      loginParams: {
        loginProvider: "google",
        mfaLevel: "none",
      },
    },
  }) as Connector<any, any>;
  const kakaoWeb3AuthConnector = new Web3AuthConnector({
    chains: chains,
    options: {
      web3AuthInstance,
      loginParams: {
        loginProvider: "kakao",
        mfaLevel: "none",
      },
    },
  }) as Connector<any, any>;
  // const facebookWeb3AuthConnector = new Web3AuthConnector({
  //   chains: chains,
  //   options: {
  //     web3AuthInstance,
  //     loginParams: {
  //       loginProvider: "facebook",
  // mfaLevel: "none",
  //     },
  //   },
  // }) as Connector<any, any>;
  const appleWeb3AuthConnector = new Web3AuthConnector({
    chains: chains,
    options: {
      web3AuthInstance,
      loginParams: {
        loginProvider: "apple",
        mfaLevel: "none",
      },
    },
  }) as Connector<any, any>;
  const twitterWeb3AuthConnector = new Web3AuthConnector({
    chains: chains,
    options: {
      web3AuthInstance,
      loginParams: {
        loginProvider: "twitter",
        mfaLevel: "none",
      },
    },
  }) as Connector<any, any>;
  const discordWeb3AuthConnector = new Web3AuthConnector({
    chains: chains,
    options: {
      web3AuthInstance,
      loginParams: {
        loginProvider: "discord",
        mfaLevel: "none",
      },
    },
  }) as Connector<any, any>;
  const emailWeb3AuthConnector = new Web3AuthConnector({
    chains: chains,
    options: {
      web3AuthInstance,
      loginParams: {
        loginProvider: "email_passwordless",
        mfaLevel: "none",
      },
    },
  }) as Connector<any, any>;

  return [
    googleWeb3AuthConnector,
    kakaoWeb3AuthConnector,
    // facebookWeb3AuthConnector,
    appleWeb3AuthConnector,
    twitterWeb3AuthConnector,
    discordWeb3AuthConnector,
    emailWeb3AuthConnector,
  ];
}
