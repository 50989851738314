import gambitLogo from "img/ic-gambit-logo.png";

const ErrorPage = () => {
  const handleRefresh = () => {
    localStorage.clear();

    caches.keys().then((cacheNames) => {
      for (const cacheName of cacheNames) {
        caches.delete(cacheName);
      }

      window.location.reload();
    });
  };

  return (
    <div className="flex flex-col items-center justify-center text-[2.4rem] md:text-[4.8rem] h-[100dvh] font-space-grotesk">
      <div className="flex items-center justify-center">
        <img
          className="w-[2.4rem] h-[2.4rem] md:w-[3.6rem] md:h-[3.6rem] opacity-80"
          src={gambitLogo}
          alt="logo"
        />
        <div className="mx-[1.5rem] opacity-80">Something went wrong</div>
        <img
          className="w-[2.4rem] h-[2.4rem] md:w-[3.6rem] md:h-[3.6rem] opacity-80"
          src={gambitLogo}
          alt="logo"
        />
      </div>
      <div className="text-[1.8rem] md:text-[2rem] mt-[4rem] mb-[2rem]">
        Press the Refresh button
      </div>
      <div>
        <button
          onClick={handleRefresh}
          className="brand-btn px-[2rem] md:px-[2.4rem]"
        >
          REFRESH
        </button>
      </div>
    </div>
  );
};

export default ErrorPage;
