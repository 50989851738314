import { Trans, t } from "@lingui/macro";
import { LONG, SHORT } from "futures-lib/legacy";
import { formatAmount, numberWithCommas } from "futures-lib/numbers";
import { useCallback, useEffect, useMemo, useState } from "react";

import {
  closingTradesAtom,
  isOpenTradesLoadingAtom,
  isTradingDoneAtom,
  isVisibleDetailTradeModalAtom,
  isVisibleSlTpModalAtom,
  openTradesAtom,
  pairsAtom,
  pairsPricesAtom,
  parsedOpenTradesAtom,
  pendingTxnsAtom,
  selectedTradeAtom,
  tooltipContentsAtom,
  waitingOpenTradesAtom,
} from "atoms/exchange";
import { getContract } from "config/contracts";
import { getToken } from "config/tokens";
import { BigNumber, ContractTransaction, ethers } from "ethers";
import {
  ICloseTradeApiFailResponse,
  ICloseTradeMarketRequest,
} from "futures-domain/trades/api-types";
import { ParsedTrade } from "futures-domain/trades/types";
import { useChainId } from "futures-lib/chains";
import {
  Opts,
  handleContractResult,
  handleTradeApiError,
  sentRequest,
} from "futures-lib/contracts";
import { useAtom, useAtomValue, useSetAtom } from "jotai";

import { GambitTradingV1Facet2 } from "@changerio/futures-contracts/dist/typechain-types";
import { EIP712_TYPES_BY_FUNCTION } from "@changerio/futures-contracts/lib/eip-712-types";
import { formatUnits } from "@ethersproject/units";
import { useLingui } from "@lingui/react";
import * as Sentry from "@sentry/react";
import pendingAnimation from "animation/pending.json";
import axios from "axios";
import cx from "classnames";
import GasFreeTextForPositionTable from "components/ZeroFeeEvent/GasFreeTextForPositionTable";
import { HARDHAT } from "config/chains";
import {
  getPnL,
  getPnLPercent,
  getPriceChange,
  getSalt,
  getSlOrLiqPrice,
  trimPriceBN,
  trimPriceString,
  unpadZero,
} from "futures-domain/trades/utils";
import { useGetTypedDomain } from "futures-lib/contracts/contract";
import { helperToast } from "futures-lib/helperToast";
import useWallet from "futures-lib/wallets/useWallet";
import HiddenChartImage from "img/chart_hidden.svg";
import ShowChartImage from "img/chart_show.svg";
import CloseBtnImage from "img/ic-close-btn.svg";
import { isEmpty } from "lodash";
import Lottie from "lottie-react";
import { toast } from "react-toastify";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { sentryCaptureException } from "utils/sentry";
import { handleApiResult } from "./TradeBoxV2";
import { GAMBIT_USD_DECIMALS, getAPIUrl, getTradeDeadline } from "./constants";

export default function TradesListV2() {
  const { chainId } = useChainId();
  const { account } = useWallet();

  // parsedOpenTrades에는 derivedTrades 데이터 포함
  const setSubgraphOpenTrades = useSetAtom(openTradesAtom);
  const openTrades = useAtomValue(parsedOpenTradesAtom);
  const isOpenTradesLoading = useAtomValue(isOpenTradesLoadingAtom);
  // const isOpenTradesLoading = false;

  const [isTotalHide, setIsTotalHide] = useState(false);

  const getTradeKey = (trade: ParsedTrade) => {
    return `${trade.pairIndex}:${trade.positionIndex}:${
      trade.buy
    }:${trade.openPrice.toString()}:${
      trade.leverage
    }:${trade.sl.toString()}:${trade.tp.toString()}}`;
  };

  const [closingTrades, setClosingTrades] = useAtom(closingTradesAtom);

  const pairs = useAtomValue(pairsAtom);

  useEffect(() => {
    if (closingTrades && closingTrades.length > 0) {
      let updatedClosingTrades = [...closingTrades];
      updatedClosingTrades = updatedClosingTrades.filter((closingTrade) =>
        openTrades
          ?.map((openTrade) => getTradeKey(openTrade))
          ?.includes(getTradeKey(closingTrade))
      );

      setClosingTrades(updatedClosingTrades);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openTrades, setClosingTrades]);

  // const tradingContract = useGetContract("GambitTradingV1") as GambitTradingV1;

  // const closeTradeContractCall = useCallback(
  //   async (trade: ParsedTrade) => {
  //     if (!signer || isDone || !account) return;

  //     setClosingTrades((prev) => {
  //       if (prev) {
  //         return [...prev, trade];
  //       }
  //       return [trade];
  //     });
  //     // setIsTxSubmitting(true);

  //     // const size관련 = formatAmount(sizeDelta, USD_DECIMALS, 2);
  //     const params: GambitTradingV1Facet2.CloseTradeMarketParamsStruct = {
  //       pairIndex: trade.pairIndex,
  //       index: trade.positionIndex, // index
  //       deadline: ethers.constants.MaxUint256,
  //       salt: randomBytes(32),
  //       // trade.pairIndex, trade.positionIndex
  //     };

  //     const closeTradeParams: [string, GambitTradingV1Facet2.CloseTradeMarketParamsStruct, string, string] = [
  //       account,
  //       params,
  //       "0x", // paramsSignature
  //       "0x", // encodedPythPrice
  //     ];

  //     const opts = { ...closeTradeOpts, pairIndex: trade.pairIndex, trade } as Opts;

  //     try {
  //       const res = await tradingContract.closeTradeMarket(...closeTradeParams);
  //       handleContractResult(res, opts);

  //       // console.log(`(closeTradeMarket) toastId: ${toastId}`);

  //       toastCloseTradeMarket(trade);
  //     } catch (e) {
  //       setClosingTrades((prev) => {
  //         if (prev) {
  //           return prev.filter((closingTrade) => getTradeKey(trade) !== getTradeKey(closingTrade));
  //         }
  //         return prev;
  //       });

  //       try {
  //         await tradingContract.callStatic.closeTradeMarket(...closeTradeParams, {} as TxOptions);
  //       } catch (e) {
  //         handleContractError(e, chainId, opts, "closeTradeMarket");
  //         throw e;
  //       }
  //       handleContractError(e, chainId, opts, "closeTradeMarket");
  //     }
  //   },
  //   [signer, isDone, account, setClosingTrades, closeTradeOpts, tradingContract, toastCloseTradeMarket, chainId]
  // );

  const [waitingOpenTrades, setWaitingOpenTrades] = useAtom(
    waitingOpenTradesAtom
  );

  useEffect(() => {
    if (waitingOpenTrades && waitingOpenTrades.length > 0) {
      let updatedWaitingOpenTrades = [...waitingOpenTrades];
      updatedWaitingOpenTrades = updatedWaitingOpenTrades.filter(
        (waitingOpenTrade) =>
          !openTrades
            ?.map((openTrade) => getTradeKey(openTrade))
            ?.includes(getTradeKey(waitingOpenTrade))
      );

      setWaitingOpenTrades(updatedWaitingOpenTrades);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openTrades, setWaitingOpenTrades]);

  useEffect(() => {
    setIsTotalHide(
      !!openTrades?.every((ot) => ot.activeTradeStatus === "HIDDEN")
    );
  }, [openTrades]);

  const waitingAndOpenTrades = useMemo(() => {
    // if (pairs.length === 0 || Object.keys(pairsPrices).length === 0) return;
    if (pairs.length === 0) return;

    if (
      chainId !== HARDHAT &&
      waitingOpenTrades &&
      waitingOpenTrades.length > 0
    ) {
      return openTrades?.concat(waitingOpenTrades);
    }
    return openTrades;
  }, [chainId, openTrades, pairs.length, waitingOpenTrades]);

  const tooltipContents = useAtomValue(tooltipContentsAtom);

  // const { data: delegationFeeThresholdMultiplier } = useSWR<BigNumber>(
  //   tradingContract && [tradingContract, "delegationFeeThresholdMultiplier", []],
  //   {
  //     fetcher: newContractFetcher,
  //     shouldRetryOnError: true,
  //     errorRetryInterval: 500,
  //     refreshInterval: 1000,
  //   }
  // );

  return (
    <div className="PositionsList">
      {/* {openTrades && ( */}
      <div className="Exchange-list">
        <div
          className={cx(
            "block bg-black-3 overflow-y-auto border border-white border-opacity-10 xs:rounded-[1.2rem]",
            tooltipContents
              ? "min-h-[26rem] max-h-[26rem]"
              : "min-h-[23.4rem] max-h-[23.4rem]"
          )}
        >
          <table className="Exchange-list whitespace-nowrap bg-black-3 text-[1.2rem] xs:rounded-[1.2rem]">
            <tbody>
              <tr className="z-[1] sticky top-0 bg-gray-80 rouned-[1.2rem] text-gray-15 font-medium">
                <th
                  className={cx(
                    "cursor-pointer",
                    isTotalHide ? "text-white" : "text-gray-15 hover:text-white"
                  )}
                  onClick={(e) => {
                    e.stopPropagation();

                    setSubgraphOpenTrades((openTrades) => {
                      if (!openTrades) return null;
                      return openTrades.map((openTrade) => {
                        return {
                          ...openTrade,
                          activeTradeStatus: isTotalHide ? "SHOWN" : "HIDDEN",
                        };
                      });
                    });
                  }}
                >
                  <div>Chart</div>
                </th>
                <th className="">
                  <Trans id="msg.positionList / Pair">Pair</Trans>
                </th>
                <th className="">
                  <Trans id="msg.positionList / Type">Type</Trans>
                </th>
                <th className="table-cell ">
                  <Trans>Collateral</Trans>
                </th>
                <th className="table-cell ">
                  <Trans>Leverage</Trans>
                </th>
                <th className="table-cell ">
                  <Trans>Entry Price</Trans>
                </th>
                <th className="table-cell ">
                  <Trans id="msg.positionList / Market Price">
                    Market Price
                  </Trans>
                </th>
                <th className="min-w-[19rem]">
                  Profit
                  {/* <Tooltip
                    handle={
                      <div className="flex items-center">
                        <Trans id="msg.positionList / Net PnL">Net PnL</Trans>
                        <InfoCircleSvg className="fill-gray-30 ml-[2px]" />
                      </div>
                    }
                    disableHandleStyle={true}
                    position="left-bottom"
                    tooltipClassName="w-[34rem]"
                    renderContent={() => (
                      <span className={cx("text-[1.4rem] text-white font-medium")}>
                        <Trans>PnL including closing fee.</Trans>
                      </span>
                    )}
                  /> */}
                </th>
                <th className="table-cell ">
                  <Trans>Liq/SL</Trans>
                </th>
                <th className="table-cell ">
                  <Trans>Take Profit</Trans>
                </th>
                <th className=" ">
                  <Trans id="msg.tradeList / Close">Close</Trans>
                </th>
              </tr>
              {!waitingAndOpenTrades && isOpenTradesLoading && (
                <tr className="text-gray-30">
                  <td colSpan={15}>
                    <div className="pt-[7rem] pb-[8.5rem] text-center">
                      Loading...
                    </div>
                  </td>
                </tr>
              )}
              {(!account || waitingAndOpenTrades?.length === 0) && (
                <tr className="text-gray-30">
                  <td colSpan={15}>
                    {/* <div className="pt-[7rem] pb-[8.5rem] text-center">
                      <Trans>Start your first trade!</Trans>
                    </div> */}
                    <div
                      className={cx(
                        "text-center",
                        tooltipContents ? "py-[4.3rem]" : "py-[3rem]"
                      )}
                    >
                      <GasFreeTextForPositionTable />
                    </div>
                  </td>
                </tr>
              )}

              {waitingAndOpenTrades?.map((trade, index) => {
                return (
                  <TradeRow
                    key={`${index}${getTradeKey(trade)}`}
                    trade={trade}
                    index={index}
                    getTradeKey={getTradeKey}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

const TradeRow = ({
  trade,
  index,
  getTradeKey,
}: {
  trade: ParsedTrade;
  index: number;
  getTradeKey: (trade: ParsedTrade) => string;
}) => {
  const { chainId } = useChainId();
  const fromTokenAddress = getContract(chainId, "CollateralToken");
  const fromToken = getToken(chainId, fromTokenAddress);
  const pairsPrices = useAtomValue(pairsPricesAtom);
  const { i18n } = useLingui();
  const { signer, account, isWeb3AuthAccount } = useWallet();
  const pairs = useAtomValue(pairsAtom);

  const setSelectedTrade = useSetAtom(selectedTradeAtom);
  const setIsVisibleSlTpModal = useSetAtom(isVisibleSlTpModalAtom);
  const setIsVisibleDetailTradeModal = useSetAtom(
    isVisibleDetailTradeModalAtom
  );
  const isDone = useAtomValue(isTradingDoneAtom);

  const handleClickDetailTrade = (trade: ParsedTrade) => {
    setSelectedTrade(trade);
    setIsVisibleDetailTradeModal(true);
  };
  const handleClickManageTpSl = (trade: ParsedTrade) => {
    setSelectedTrade(trade);
    setIsVisibleSlTpModal(true);
  };

  const { tradingContractTypedDomain } = useGetTypedDomain();

  const setPendingTxns = useSetAtom(pendingTxnsAtom);

  const closeTradeOpts = useMemo(() => {
    return {
      value: ethers.constants.Zero,
      sentMsg: "",
      failMsg: "",
      successMsg: "",
      hideSuccessMsg: true, // 이벤트를 받아서 따로 토스트 메세징 처리하기 때문에
      setPendingTxns,
      pairs,
    } as Opts;
  }, [pairs, setPendingTxns]);

  const toastCloseTradeMarket = useCallback((trade: ParsedTrade) => {
    const toastId = `close:${trade.buy}:${trade.pair.from}:${trade.pair.to}`;

    setTimeout(() => {
      helperToast.info(
        <div>
          <div className="toastify-title">
            <Trans>Closing Order</Trans>
          </div>
          <div>
            <div className="toastify-body">
              <span>
                {trade.pair.from}/{trade.pair.to}
              </span>
              <span
                className={cx(
                  trade.buy ? "text-green-2" : "text-red-2",
                  "ml-[4px]"
                )}
              >
                {trade.buy ? t`LONG` : t`SHORT`}
              </span>
            </div>
          </div>
        </div>,
        {
          // autoClose: 7000,
          toastId,
        }
      );
    }, 200);
  }, []);

  const [closingTrades, setClosingTrades] = useAtom(closingTradesAtom);

  const closeTradeApiCall = useCallback(
    async (trade: ParsedTrade) => {
      if (!signer || isDone || !account) return;

      setClosingTrades((prev) => {
        if (prev) {
          return [...prev, trade];
        }
        return [trade];
      });

      const deadline = getTradeDeadline(chainId);

      const params: GambitTradingV1Facet2.CloseTradeMarketParamsStruct = {
        pairIndex: trade.pairIndex,
        index: trade.positionIndex, // index
        deadline: deadline.toString(),
        salt: getSalt(),
      };

      let sentRequestToastId;

      try {
        const signature = await signer._signTypedData(
          tradingContractTypedDomain,
          EIP712_TYPES_BY_FUNCTION.closeTradeMarket,
          params
        );

        sentRequestToastId = sentRequest(
          "CloseTrade",
          `${trade.pair.from}/${trade.pair.to}`
        );

        try {
          const opts = {
            ...closeTradeOpts,
            pairIndex: trade.pairIndex,
            trade,
          } as Opts;

          const { data } = await axios.post<
            string | ICloseTradeApiFailResponse
          >(
            `${getAPIUrl(
              chainId
            )}/v1/gasless/market/close?trader=${account}&signature=${signature}`,
            params as ICloseTradeMarketRequest
          );
          toast.dismiss(sentRequestToastId);

          let status, hash, code;

          // * data가 string이 아니면 API 호출 실패한 것
          // * API 성공 / 실패 시 응답 형태가 달라서
          if (typeof data !== "string") {
            status = data.status;
            code = data.code;
          }
          // * data가 string이 아니면 API 호출 성공 케이스
          else {
            hash = data;
            status = 200;
          }

          if (status === 200) {
            handleContractResult({ hash } as ContractTransaction, opts);
            toastCloseTradeMarket(trade);
          } else {
            setClosingTrades((prev) => {
              if (prev) {
                return prev.filter(
                  (closingTrade) =>
                    getTradeKey(trade) !== getTradeKey(closingTrade)
                );
              }
              return prev;
            });
            handleApiResult(status, code);
          }
        } catch (e: any) {
          toast.dismiss(sentRequestToastId);

          setClosingTrades((prev) => {
            if (prev) {
              return prev.filter(
                (closingTrade) =>
                  getTradeKey(trade) !== getTradeKey(closingTrade)
              );
            }
            return prev;
          });

          // * API 에러 처리
          const { response } = e;

          const { data } = response;

          handleTradeApiError(
            data.name || "something went wrong",
            data.details?.customError
              ? `${data.message}(${data.details?.customError})`
              : data.message || "something went wrong",
            chainId,
            data.details?.customError ? data.details?.customError : ""
          );

          Sentry.setContext("/gasless/market/close API Request Params", {
            signature,
            params,
          });

          Sentry.setContext(
            "/gasless/market/close API Error Response",
            response
          );

          sentryCaptureException({
            error: new Error("/gasless/market/close API Error"),
            name: "Error Object",
            context: e,
          });
        }
      } catch (e: any) {
        setClosingTrades((prev) => {
          if (prev) {
            return prev.filter(
              (closingTrade) => getTradeKey(trade) !== getTradeKey(closingTrade)
            );
          }
          return prev;
        });

        // * 서명 에러 처리
        sentryCaptureException({
          error: new Error("Sign Error in closeTradeApiCall"),
          name: "Error Object",
          context: e,
        });
      } finally {
        // setClosingTrades((prev) => {
        //   if (prev) {
        //     return prev.filter(
        //       (closingTrade) => getTradeKey(trade) !== getTradeKey(closingTrade)
        //     );
        //   }
        //   return prev;
        // });
      }
    },
    [
      account,
      chainId,
      closeTradeOpts,
      getTradeKey,
      isDone,
      setClosingTrades,
      signer,
      toastCloseTradeMarket,
      tradingContractTypedDomain,
    ]
  );

  const closeTradeMarket = useCallback(
    (trade: ParsedTrade) => {
      // const collateral = formatUnits(trade.positionSizeUsdc, fromToken.decimals);
      // const leverage = trade.leverage;
      // const delegationFeeThreshhold = getDelegationFeeThreshhold(trade.pair.fee.minLevPosUsdc, delegationFeeThresholdMultiplier);
      // const positionSize = new BN(collateral).multipliedBy(leverage).toNumber();

      // if (!delegationFeeThreshhold) return;

      // * 포지션 사이즈와 상관없이 gasless
      closeTradeApiCall(trade);

      // * 포지션 사이즈에 따라 gasless 분기
      // if (+positionSize >= delegationFeeThreshhold) {
      //   // console.log("closeTradeApiCall");
      //   closeTradeApiCall(trade);
      // } else {
      //   // console.log("closeTradeContractCall");
      //   closeTradeContractCall(trade);
      // }
    },
    [closeTradeApiCall]
  );

  const isClosingTrade = (trade: ParsedTrade) => {
    return closingTrades
      ?.map((closingTrade) => getTradeKey(closingTrade))
      .includes(getTradeKey(trade));
  };

  const currentPriceValue = useMemo(() => {
    if (!pairsPrices || isEmpty(pairsPrices)) return;

    if (!trade.pair.priceFeedId || !pairsPrices[trade.pair.priceFeedId]) return;

    const { price, expo } = pairsPrices[trade.pair.priceFeedId];

    return formatUnits(BigNumber.from(price), Math.abs(expo));
  }, [pairsPrices, trade.pair.priceFeedId]);

  const openPriceValue = useMemo(() => {
    return formatUnits(trade.openPrice, GAMBIT_USD_DECIMALS);
  }, [trade.openPrice]);

  const priceChange = useMemo(() => {
    if (!currentPriceValue) return;

    return getPriceChange(currentPriceValue, openPriceValue);
  }, [currentPriceValue, openPriceValue]);

  const pnl = useMemo(() => {
    if (!priceChange) return;

    return getPnL(trade, priceChange, fromToken.decimals);
  }, [fromToken.decimals, priceChange, trade]);

  const pnlPercent = useMemo(() => {
    const positionSizeUsdcValue = formatUnits(
      trade.positionSizeUsdc,
      fromToken.decimals
    );

    if (pnl && positionSizeUsdcValue) {
      return getPnLPercent(pnl, positionSizeUsdcValue);
    }

    return;
  }, [fromToken.decimals, pnl, trade.positionSizeUsdc]);

  const setSubgraphOpenTrades = useSetAtom(openTradesAtom);

  return (
    <tr
      className="text-gray-00 cursor-pointer font-normal hover:bg-white hover:bg-opacity-5"
      onClick={() => {
        handleClickDetailTrade(trade);
      }}
    >
      {/* Hide */}
      <td
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <button
          disabled={isDone}
          onClick={(e) => {
            e.stopPropagation();
            setSubgraphOpenTrades((openTrades) => {
              if (!openTrades) return null;
              return openTrades.map((openTrade) => {
                if (openTrade.pairIndex !== trade.pairIndex) return openTrade;

                if (openTrade.positionIndex === trade.positionIndex)
                  return {
                    ...openTrade,
                    activeTradeStatus:
                      openTrade.activeTradeStatus === "SHOWN"
                        ? "HIDDEN"
                        : "SHOWN",
                  };
                else {
                  return openTrade;
                }
              });
            });
          }}
          className="cursor-pointer ml-[6px] flex items-center"
        >
          {trade.activeTradeStatus !== "HIDDEN" ? (
            <img
              src={ShowChartImage}
              alt="show chart button"
              className="w-[14px] h-[14px]"
            />
          ) : (
            <img
              src={HiddenChartImage}
              alt="show chart button"
              className="w-[14px] h-[14px]"
            />
          )}
        </button>
      </td>
      {/* Pair */}
      <td className="font-space-grotesk">
        {trade.pair.from}/{trade.pair.to}
      </td>

      {/* Type */}
      <td className={cx(trade.buy ? "text-green-2" : "text-red-2")}>
        {trade.buy ? i18n._(LONG) : i18n._(SHORT)}
      </td>

      {/* Collateral */}
      <td className="font-space-grotesk table-cell">
        {formatAmount(trade.positionSizeUsdc, fromToken.decimals, 2, true)}{" "}
        {fromToken.symbol}
      </td>

      {/* Leverage */}
      <td className="font-space-grotesk table-cell">
        {unpadZero(trade.leverage.toFixed(2))}x
      </td>

      {/* Entry Price */}
      <td className="font-space-grotesk table-cell">
        {/* {formatAmount(trade.openPrice, GAMBIT_USD_DECIMALS, 2, true)} */}$
        {trimPriceBN(trade.openPrice, true)}
      </td>

      {/* Market Price */}
      <td className="font-space-grotesk table-cell">
        {currentPriceValue !== undefined &&
          // <AnimatedNumber to={+currentPriceValue} />
          numberWithCommas(trimPriceString(currentPriceValue))}
      </td>

      {/* Profit */}
      <td
        className={cx(
          "font-space-grotesk",
          // !netPnL ? "" : netPnL && +netPnL >= 0 ? "text-green-2" : "text-red-2"
          !pnl ? "" : pnl && +pnl >= 0 ? "text-green-2" : "text-red-2"
        )}
      >
        {/* {pnl ? `${pnl} ${fromToken.symbol} (${pnlPercent}%)` : ""} */}
        {pnl && (
          <div className="flex items-center space-x-[3.5px]">
            {/* <AnimatedNumber to={+pnl} decimals={2} /> */}
            <span>{numberWithCommas((+pnl).toFixed(2))}</span>
            <span>{` ${fromToken.symbol}`}</span>
            {pnlPercent && (
              <span className="flex items-center">
                ({/* <AnimatedNumber to={+pnlPercent} decimals={2} /> */}
                {numberWithCommas((+pnlPercent).toFixed(2))}
                %)
              </span>
            )}
          </div>
        )}
      </td>

      {/* SL / LIQ Price */}
      <td
        className="table-cell underline"
        onClick={(e) => {
          e.stopPropagation();
          handleClickManageTpSl(trade);
        }}
      >
        {getSlOrLiqPrice(trade).type
          ? `${i18n._(getSlOrLiqPrice(trade).type!)}:`
          : ""}{" "}
        {/* {getSlOrLiqPrice(trade).type && $} */}
        <span className="font-space-grotesk">
          {getSlOrLiqPrice(trade).type
            ? // ? formatAmount(getSlOrLiqPrice(trade).value, GAMBIT_USD_DECIMALS, 2, true)
              `$${trimPriceBN(getSlOrLiqPrice(trade).value, true)}`
            : ""}
        </span>
      </td>

      {/* TP */}
      <td
        className={cx("font-space-grotesk table-cell", {
          underline: !trade.tp.eq(ethers.constants.Zero),
        })}
        onClick={(e) => {
          e.stopPropagation();
          handleClickManageTpSl(trade);
        }}
      >
        <span className="mr-[.15rem]">
          {trade.tp.eq(ethers.constants.Zero)
            ? "-"
            : `$${trimPriceBN(trade.tp, true)}`}
        </span>
      </td>
      <td
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {isClosingTrade(trade) ? (
          <Lottie
            className="w-[2rem] h-[2rem]"
            animationData={pendingAnimation}
          />
        ) : trade?.isWaitingTrade ? (
          ""
        ) : (
          <>
            <button
              disabled={isDone}
              onClick={(e) => {
                e.stopPropagation();
                closeTradeMarket(trade);
              }}
              className="cursor-pointer position-close-button align-sub"
            >
              <img src={CloseBtnImage} alt="close button" />
            </button>
            {isWeb3AuthAccount && (
              <ReactTooltip
                className="gambit-react-tooltip z-[100]"
                anchorSelect=".position-close-button"
                noArrow={true}
                place="top"
              >
                <div>[Note] Instant position closure</div>
                <div>without confirmation on click</div>
              </ReactTooltip>
            )}
          </>
        )}
      </td>
    </tr>
  );
};
