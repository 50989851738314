import { Trans, t } from "@lingui/macro";
import { useCallback, useEffect, useMemo, useState } from "react";
// import Footer from "components/Footer/Footer";
// import "./Vault.css";
// import TokenCard from "components/TokenCard/TokenCard";

import { formatUnits, parseUnits } from "@ethersproject/units";
import { useLingui } from "@lingui/react";
import Token from "abis/Token.json";
import pendingAnimation from "animation/pending.json";
import { currentLanguageAtom, showSignInModalAtom } from "atoms";
import { pendingTxnsAtom } from "atoms/exchange";
import {
  accountVaultAtom,
  commonVaultAtom,
  existingRequestsAtom,
  traderDepositedAtom,
} from "atoms/vault";
import BN from "bignumber.js";
import cx from "classnames";
import SEO from "components/Common/SEO";
import { ADDED_DECIMALS } from "components/Exchange/constants";
import Tooltip from "components/Tooltip/Tooltip";
import { BEGINING_NUM_FOR_EXISTING_REQUESTS } from "components/Vault/constants";
import { ARBITRUM, ZKSYNC } from "config/chains";
import { getAbi, getContract } from "config/contracts";
import { getToken } from "config/tokens";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { BigNumber, ethers } from "ethers";
import { approveToken } from "futures-domain/tokens/approveTokenV2";
import { VAULT_TAB_TYPE, VaultTabType } from "futures-domain/trades/types";
import { useTrader } from "futures-domain/trades/useTrader";
import {
  threeDotsToEmptyString,
  threeDotsToZero,
} from "futures-domain/trades/utils";
import { useAccountVault } from "futures-domain/vault/useAccountVault";
import { useExistingReuqestsVault } from "futures-domain/vault/useExistingRequestsVault";
import { useChainId } from "futures-lib/chains";
import {
  Opts,
  callContract,
  contractFetcher,
  newContractFetcher,
} from "futures-lib/contracts";
import { useGetContract } from "futures-lib/contracts/contract";
import { getPageTitle, importImage, swrFetcher } from "futures-lib/legacy";
import {
  bigNumberify,
  formatAmount,
  formatStringAmount,
  numberWithCommas,
} from "futures-lib/numbers";
import { usePrevious } from "futures-lib/usePrevious";
import useWallet from "futures-lib/wallets/useWallet";
import loader from "img/ic-loader.svg";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import Lottie from "lottie-react";
import useSWR from "swr";

BN.config({ ROUNDING_MODE: 1 });
const { AddressZero } = ethers.constants;

interface IsTxsubmitting {
  depositTxIsSubmitting: boolean;
  requestWithdrawTxIsSubmitting: boolean;
  forceNewEpochTxIsSubmitting: boolean;
  redeemTxIsSubmitting: boolean;
}

export default function Vault() {
  dayjs.extend(localizedFormat);
  dayjs.extend(duration);

  useTrader();

  const { i18n } = useLingui();

  const { isActive, account, signer, isWeb3AuthAccount } = useWallet();
  const { chainId } = useChainId();
  const currentLanguage = useAtomValue(currentLanguageAtom);

  const setShowSignInModal = useSetAtom(showSignInModalAtom);

  const fromTokenAddress = getContract(chainId, "CollateralToken");
  const fromToken = getToken(chainId, fromTokenAddress);
  const fromTokenImage = importImage(
    `ic_${fromToken.symbol.toLowerCase()}_24.svg`
  );

  const vaultTokenAddress = getContract(chainId, "Vault");
  const vaultToken = getToken(chainId, vaultTokenAddress);

  // human readable
  const [depositValue, setDepositValue] = useState("");
  const [withdrawUsdcValue, setWithdrawUsdcValue] = useState(""); // gUSDC 개수를 입력받는 게 아니라 USDC 개수를 입력 받음
  const [activeTab, setActiveTab] = useState<VaultTabType>(
    VAULT_TAB_TYPE.DEPOSIT
  );

  // BigNumber format of depositValue
  const depositAmount = useMemo(() => {
    if (depositValue) {
      return parseUnits(depositValue, fromToken.decimals);
    }

    return null;
  }, [fromToken.decimals, depositValue]);

  const commonVaultInfo = useAtomValue(commonVaultAtom);
  // const { commonVaultInfo.currentEpoch, commonVaultInfo.currentEpochStart } = useAtomValue(commonVaultAtom);

  useAccountVault(commonVaultInfo.currentEpoch);
  useExistingReuqestsVault(
    commonVaultInfo.currentEpoch,
    commonVaultInfo.withdrawEpochsTimelock
  );

  const {
    available,
    decimals: gTokenDecimals,
    totalSharesBeingWithdrawn,
  } = useAtomValue(accountVaultAtom);

  const { data: collateralBalance } = useSWR<BigNumber>(
    isActive && [isActive, chainId, fromTokenAddress, "balanceOf", account],
    {
      fetcher: contractFetcher(signer, Token),
      shouldRetryOnError: true,
      errorRetryInterval: 500,
      refreshInterval: 2000,
    }
  );

  const { data: fromTokenAllowance } = useSWR<BigNumber>(
    isActive && [
      isActive,
      chainId,
      fromTokenAddress,
      "allowance",
      account,
      vaultTokenAddress,
    ],
    {
      fetcher: contractFetcher(signer, Token),
      shouldRetryOnError: true,
      errorRetryInterval: 500,
    }
  );

  const gTokenOpenPnlFeedContract = useGetContract("GTokenOpenPnlFeed");

  // requestsStart 반환 값 초
  const { data: requestsStart } = useSWR<BigNumber>(
    gTokenOpenPnlFeedContract && [
      gTokenOpenPnlFeedContract,
      "requestsStart",
      [],
    ],
    {
      fetcher: newContractFetcher,
      shouldRetryOnError: true,
      errorRetryInterval: 500,
      refreshInterval: 1000 * 60,
    }
  );

  const { data: requestsEvery } = useSWR<BigNumber>(
    gTokenOpenPnlFeedContract && [
      gTokenOpenPnlFeedContract,
      "requestsEvery",
      [],
    ],
    {
      fetcher: newContractFetcher,
      shouldRetryOnError: true,
      errorRetryInterval: 500,
      refreshInterval: 1000 * 60,
    }
  );

  const { data: requestsCount } = useSWR<BigNumber>(
    gTokenOpenPnlFeedContract && [
      gTokenOpenPnlFeedContract,
      "requestsCount",
      [],
    ],
    {
      fetcher: newContractFetcher,
      shouldRetryOnError: true,
      errorRetryInterval: 500,
      refreshInterval: 1000 * 60,
    }
  );

  const [isApproving, setIsApproving] = useState(false);
  const [isWaitingForApproval, setIsWaitingForApproval] = useState(false);

  const needVaultApproval = useMemo(() => {
    const result =
      fromTokenAddress !== AddressZero &&
      fromTokenAllowance &&
      depositAmount &&
      depositAmount.gt(fromTokenAllowance);

    return result;
  }, [depositAmount, fromTokenAddress, fromTokenAllowance]);

  const prevNeedVaultApproval = usePrevious(needVaultApproval);

  useEffect(() => {
    if (!needVaultApproval && prevNeedVaultApproval && isWaitingForApproval) {
      setIsWaitingForApproval(false);
    }
  }, [isWaitingForApproval, needVaultApproval, prevNeedVaultApproval]);

  const [pendingTxns, setPendingTxns] = useAtom(pendingTxnsAtom);

  const initialIsTxsubmitting = {
    depositTxIsSubmitting: false,
    requestWithdrawTxIsSubmitting: false,
    forceNewEpochTxIsSubmitting: false,
    redeemTxIsSubmitting: false,
  };

  const [isTxSubmitting, setIsTxSubmitting] = useState<IsTxsubmitting>(
    initialIsTxsubmitting
  );

  const deposit = useCallback(() => {
    if (!signer) return;

    setIsTxSubmitting({
      ...isTxSubmitting,
      depositTxIsSubmitting: true,
    });

    const params = [depositAmount, account];

    const successMsg = (
      <div>
        <div className="toastify-title">
          <Trans>Deposit Success</Trans>
        </div>
        <div>
          <div className="toastify-body">
            <span>
              {depositValue} {fromToken.symbol} @ {fromToken.symbol} Vault
            </span>
          </div>
        </div>
      </div>
    );

    const vaultContract = new ethers.Contract(
      vaultTokenAddress,
      getAbi(chainId, "Vault").abi,
      signer
    );

    callContract(chainId, vaultContract, "deposit", params, {
      value: bigNumberify(0),
      setPendingTxns,
      sentMsg: t`Deposit Submitted`,
      failMsg: t`Deposit Failed`,
      successMsg,
      isWeb3AuthAccount,
    } as Opts)
      .then(() => {})
      .finally(() => {
        setIsTxSubmitting({
          ...isTxSubmitting,
          depositTxIsSubmitting: false,
        });
        setDepositValue("");
        // setIsPendingConfirmation(false);
      });
  }, [
    signer,
    isTxSubmitting,
    depositAmount,
    account,
    depositValue,
    fromToken.symbol,
    vaultTokenAddress,
    chainId,
    setPendingTxns,
    isWeb3AuthAccount,
  ]);

  const withdrawUsdcAmount = useMemo(() => {
    if (withdrawUsdcValue) {
      const result = parseUnits(withdrawUsdcValue, fromToken.decimals);
      return result;
    }

    return null;
  }, [fromToken.decimals, withdrawUsdcValue]);

  // available - totalSharesBeingWithdrawn
  // 실제 출금 요청 가능한 shares 수량
  const availableWithdrawalShares = useMemo(() => {
    if (!available || !totalSharesBeingWithdrawn) return;
    return available.sub(totalSharesBeingWithdrawn);
  }, [available, totalSharesBeingWithdrawn]);

  const availableWithdrawalAssets = useMemo(() => {
    // available - totalSharesBeingWithdrawn
    if (!availableWithdrawalShares || !commonVaultInfo.gUsdcPrice) return "...";
    const result = availableWithdrawalShares.mul(commonVaultInfo.gUsdcPrice);

    return new BN(
      formatAmount(result, gTokenDecimals * 2 + ADDED_DECIMALS, 2, false)
    ).toFixed(6);
  }, [availableWithdrawalShares, commonVaultInfo.gUsdcPrice, gTokenDecimals]);

  const requestWithdraw = useCallback(() => {
    if (!signer) return;

    setIsTxSubmitting({
      ...isTxSubmitting,
      requestWithdrawTxIsSubmitting: true,
    });

    const vaultTokenAddress = getContract(chainId, "Vault");
    const vaultContract = new ethers.Contract(
      vaultTokenAddress,
      getAbi(chainId, "Vault").abi,
      signer
    );

    if (!withdrawUsdcAmount) {
      return;
    }

    // 사용자에게 입력받은 withdrawUsdcValue -> gUsdcValue -> gUsdcAmount로 변환
    const params: any[] = [];

    // MAX 출금 요청인 경우 shares 그대로 파라미터로 전달
    if (
      availableWithdrawalAssets &&
      withdrawUsdcValue === availableWithdrawalAssets
    ) {
      params.push(availableWithdrawalShares, account);
    } else {
      const gUsdcValue = new BN(withdrawUsdcValue)
        .dividedBy(
          formatUnits(
            commonVaultInfo.gUsdcPrice,
            fromToken.decimals + ADDED_DECIMALS
          )
        )
        .toFixed(6);
      const gUsdcAmount = parseUnits(gUsdcValue, fromToken.decimals);

      params.push(gUsdcAmount, account);
    }

    const successMsg = t`Withdrawal Request Success`;

    callContract(chainId, vaultContract, "makeWithdrawRequest", params, {
      value: bigNumberify(0),
      setPendingTxns,
      sentMsg: t`Withdrawal Request Submitted`,
      failMsg: t`Withdrawal Request Failed`,
      successMsg,
      isWeb3AuthAccount,
    } as Opts)
      .then(async () => {})
      .finally(() => {
        setIsTxSubmitting({
          ...isTxSubmitting,
          requestWithdrawTxIsSubmitting: false,
        });
        setWithdrawUsdcValue("");
      });
  }, [
    signer,
    isTxSubmitting,
    chainId,
    withdrawUsdcAmount,
    availableWithdrawalAssets,
    withdrawUsdcValue,
    setPendingTxns,
    isWeb3AuthAccount,

    availableWithdrawalShares,
    account,
    commonVaultInfo.gUsdcPrice,
    fromToken.decimals,
  ]);

  const [currentTime, setCurrentTime] = useState<dayjs.Dayjs>();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(dayjs());
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const epochLong = useMemo(() => {
    if (!requestsStart || !requestsEvery || !requestsCount) return;
    // 초 -> 시간, 2 days(contract) -> 172800 sec (응답) -> 48 hours
    const _requestsStart = new BN(requestsStart.toString())
      .dividedBy(60)
      .dividedBy(60)
      .toNumber(); // 2 days(contract) -> 48 hours

    // requestsEvery: 21600 sec -> 6 hours
    // requestsCount: 4
    // 6 hours * 4 = 24 hours
    const result = new BN(requestsEvery.toString())
      .multipliedBy(requestsCount.toString())
      .dividedBy(60)
      .dividedBy(60)
      .toNumber();

    return _requestsStart + result;
  }, [requestsCount, requestsEvery, requestsStart]);

  const currentEpochEndDayjs = useMemo(() => {
    if (!epochLong) return;

    return dayjs
      .unix(commonVaultInfo.currentEpochStart)
      .add(epochLong, "hours"); // TODO: epoch 시간에 맞춰 변경 or 온체인에서 가져오기
  }, [commonVaultInfo.currentEpochStart, epochLong]);

  // durationTime
  const remainingThisEpochTime = useMemo(() => {
    if (
      !currentEpochEndDayjs ||
      (currentTime && currentEpochEndDayjs < currentTime)
    ) {
      return "";
    }
    const remainingThisEpochTime = dayjs.duration(
      currentEpochEndDayjs.diff(currentTime)
    );

    // console.log(remainingThisEpochTime);
    return remainingThisEpochTime;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commonVaultInfo.currentEpochStart, currentTime, currentEpochEndDayjs]);

  const currentEpochEndPlus1HourEndDayjs = useMemo(() => {
    if (!epochLong) return;

    const result = dayjs
      .unix(commonVaultInfo.currentEpochStart)
      .add(1, "hours")
      .add(epochLong, "hours"); // TODO: epoch 시간에 맞춰 변경 or 온체인에서 가져오기

    return result;
  }, [commonVaultInfo.currentEpochStart, epochLong]);

  // durationTime
  const remainingThisEpochEndTimePlus1Hour = useMemo(() => {
    if (!currentEpochEndPlus1HourEndDayjs) return;

    if (currentTime && currentEpochEndPlus1HourEndDayjs < currentTime) {
      return "";
    }
    const remainingThisEpochEndTimePlus1Hour = dayjs.duration(
      currentEpochEndPlus1HourEndDayjs.diff(currentTime)
    );

    return remainingThisEpochEndTimePlus1Hour;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    commonVaultInfo.currentEpochStart,
    currentTime,
    currentEpochEndPlus1HourEndDayjs,
  ]);

  const withdrawLong = useMemo(() => {
    if (!requestsStart) return;
    // 초 -> 시간, 2 days(contract) -> 172800 sec (응답) -> 48 hours
    return new BN(requestsStart.toString())
      .dividedBy(60)
      .dividedBy(60)
      .toNumber();
  }, [requestsStart]);

  const remainingToWithdrawTime = useMemo(() => {
    if (!commonVaultInfo.currentEpochStart || !withdrawLong) return;

    const currentEpochStartAfterWithdrawLong = dayjs
      .unix(commonVaultInfo.currentEpochStart)
      .add(withdrawLong, "hours");

    if (currentTime && currentEpochStartAfterWithdrawLong < currentTime) {
      return "";
    }
    const remainingToWithdrawTime = dayjs.duration(
      currentEpochStartAfterWithdrawLong.diff(currentTime)
    );

    return remainingToWithdrawTime;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commonVaultInfo.currentEpochStart, currentTime]);

  const requestToWithdrawLong = useMemo(() => {
    if (!requestsStart) return;
    // 초 -> 시간, 2 days(contract) -> 172800 sec (응답) -> 48 hours
    return new BN(requestsStart.toString())
      .dividedBy(60)
      .dividedBy(60)
      .toNumber();
  }, [requestsStart]);

  const remainingToRequestWithdrawTime = useMemo(() => {
    if (!commonVaultInfo.currentEpochStart || !requestToWithdrawLong) return;

    const currentEpochStartAfterWithdrawLong = dayjs
      .unix(commonVaultInfo.currentEpochStart)
      .add(requestToWithdrawLong, "hours");

    if (currentTime && currentEpochStartAfterWithdrawLong < currentTime) {
      return "";
    }
    const remainingToRequestWithdrawTime = dayjs.duration(
      currentEpochStartAfterWithdrawLong.diff(currentTime)
    );

    return remainingToRequestWithdrawTime;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commonVaultInfo.currentEpochStart, currentTime]);

  const forceNewEpoch = useCallback(() => {
    if (!signer) return;

    setIsTxSubmitting({
      ...isTxSubmitting,
      forceNewEpochTxIsSubmitting: true,
    });

    const GTokenOpenPnLFeedContractAddress = getContract(
      chainId,
      "GTokenOpenPnLFeed"
    );
    const GTokenOpenPnLFeedContract = new ethers.Contract(
      GTokenOpenPnLFeedContractAddress,
      getAbi(chainId, "GTokenOpenPnLFeed").abi,
      signer
    );

    const successMsg = t`Force New Epoch Success`;

    callContract(chainId, GTokenOpenPnLFeedContract, "forceNewEpoch", [], {
      value: bigNumberify(0),
      setPendingTxns,
      sentMsg: t`Force New Epoch Submitted`,
      failMsg: t`Force New Epoch Failed`,
      successMsg,
      isWeb3AuthAccount,
    } as Opts)
      .then(() => {})
      .finally(() => {
        setIsTxSubmitting({
          ...isTxSubmitting,
          forceNewEpochTxIsSubmitting: false,
        });
        // setIsPendingConfirmation(false);
      });
  }, [signer, isTxSubmitting, chainId, setPendingTxns, isWeb3AuthAccount]);

  // 실제 Withdraw, USDC를 볼트에서 빼서 사용자 지갑으로 이동
  const redeem = useCallback(
    (amount: BigNumber, formattedAmount: string) => {
      if (!signer) return;

      setIsTxSubmitting({
        ...isTxSubmitting,
        redeemTxIsSubmitting: true,
      });

      const vaultTokenAddress = getContract(chainId, "Vault");
      const vaultContract = new ethers.Contract(
        vaultTokenAddress,
        getAbi(chainId, "Vault").abi,
        signer
      );

      // shares가 gUSDC, assets이 USDC
      const params = [amount, account, account]; // shares, receiver, owner

      const successMsg = (
        <div>
          <div className="toastify-title">
            <Trans>Withdrawal Success</Trans>
          </div>
          <div>
            <div className="toastify-body">
              <span>
                {formattedAmount} {fromToken.symbol} @ {fromToken.symbol} Vault
              </span>
            </div>
          </div>
        </div>
      );

      callContract(chainId, vaultContract, "redeem", params, {
        value: bigNumberify(0),
        setPendingTxns,
        sentMsg: t`Withdrawal Submitted`,
        failMsg: t`Withdrawal Failed`,
        successMsg,
        isWeb3AuthAccount,
      } as Opts)
        .then(() => {})
        .finally(() => {
          setIsTxSubmitting({
            ...isTxSubmitting,
            redeemTxIsSubmitting: false,
          });
          // setIsPendingConfirmation(false);
        });
    },
    [
      signer,
      isTxSubmitting,
      chainId,
      account,
      fromToken.symbol,
      setPendingTxns,
      isWeb3AuthAccount,
    ]
  );

  const requestWithdrawPossibleTime = useMemo(() => {
    if (!requestToWithdrawLong) return;

    return dayjs
      .unix(commonVaultInfo.currentEpochStart)
      .add(requestToWithdrawLong, "hours");
  }, [commonVaultInfo.currentEpochStart, requestToWithdrawLong]);

  const possibleRequestWithdraw = useMemo(() => {
    if (!currentTime || !requestWithdrawPossibleTime) return false;

    return requestWithdrawPossibleTime.valueOf() > currentTime?.valueOf();
  }, [currentTime, requestWithdrawPossibleTime]);

  const _existingRequests = useAtomValue(existingRequestsAtom);

  const existingRequestsNum = useMemo(() => {
    if (!_existingRequests) return "";
    return _existingRequests.filter((v) => !v.isZero()).length;
  }, [_existingRequests]);

  const existingRequests = useMemo(() => {
    if (!currentTime || !epochLong) return [];
    if (!_existingRequests) return [];

    return _existingRequests
      .map((bn, i) => {
        const epoch =
          commonVaultInfo.currentEpoch + i - BEGINING_NUM_FOR_EXISTING_REQUESTS;
        let withdrawDate: null | dayjs.Dayjs;
        let possibleWithdraw = false;

        if (epoch < commonVaultInfo.currentEpoch) {
          withdrawDate = null; // 두 가지 케이스, 작으면 Canceld / 같으면 withdraw 가능한 상황

          // TODO: possibleWithdraw 로직
        } else if (epoch === commonVaultInfo.currentEpoch) {
          withdrawDate = null;
          if (
            requestWithdrawPossibleTime &&
            currentTime.valueOf() < requestWithdrawPossibleTime.valueOf()
          ) {
            possibleWithdraw = true;
          }
        } else {
          const remainEpoch = epoch - commonVaultInfo.currentEpoch;
          withdrawDate = dayjs
            .unix(commonVaultInfo.currentEpochStart)
            .add(epochLong * remainEpoch, "hours");
        }

        return {
          epoch,
          amount: bn,
          withdrawDate, // null / dayjs
          possibleWithdraw,
        };
      })
      .filter((existingRequest) => {
        return !existingRequest.amount.isZero();
      });
  }, [
    _existingRequests,
    commonVaultInfo.currentEpoch,
    commonVaultInfo.currentEpochStart,
    currentTime,
    epochLong,
    requestWithdrawPossibleTime,
  ]);

  const withdrawDate = useMemo(() => {
    if (
      !epochLong ||
      !commonVaultInfo.withdrawEpochsTimelock ||
      !currentEpochEndDayjs
    )
      return;

    const later = epochLong * (commonVaultInfo.withdrawEpochsTimelock - 1);
    return currentEpochEndDayjs.add(later, "hours");
  }, [commonVaultInfo.withdrawEpochsTimelock, currentEpochEndDayjs, epochLong]);

  const approveFromToken = useCallback(() => {
    if (!signer) return;

    approveToken({
      setIsApproving,
      signer,
      tokenAddress: fromToken.address,
      spender: vaultTokenAddress,
      chainId: chainId,
      onApproveSubmitted: () => {
        setIsWaitingForApproval(true);
      },
      pendingTxns,
      setPendingTxns,
    });
  }, [
    chainId,
    fromToken.address,
    signer,
    pendingTxns,
    setPendingTxns,
    vaultTokenAddress,
  ]);

  const disabledDepositMaxButton = useMemo(() => {
    if (!isActive) {
      return true;
    }

    return !collateralBalance || collateralBalance.isZero();
  }, [collateralBalance, isActive]);

  const disabledDepositButton = useMemo(() => {
    if (!isActive) {
      // * Sign In 버튼을 띄워야 해서 isActive 상태일 때 disabled를 false로 유지
      return false;
    }

    return (
      isApproving ||
      isWaitingForApproval ||
      !depositAmount ||
      depositAmount?.isZero() ||
      !collateralBalance ||
      collateralBalance.isZero() ||
      isTxSubmitting.depositTxIsSubmitting ||
      depositAmount.gt(collateralBalance)
    );
  }, [
    isActive,
    collateralBalance,
    depositAmount,
    isApproving,
    isTxSubmitting.depositTxIsSubmitting,
    isWaitingForApproval,
  ]);

  const depositButtonText = useCallback(() => {
    // Sign In | Approve | Approving... | Waiting for Approval | Submit
    if (!isActive) {
      return t`Sign In`;
    }

    if (isApproving) {
      return t`Approving...`;
    }

    if (needVaultApproval && isWaitingForApproval) {
      return t`Waiting for Approval`;
    }

    if (needVaultApproval) {
      return t`Approve`;
    }

    return t({ id: `Deposit Button Text`, message: `Submit` });
  }, [isActive, isApproving, isWaitingForApproval, needVaultApproval]);

  const handleDepositButton = useCallback(() => {
    // approve / deposit
    if (!isActive) {
      setShowSignInModal(true);
      return;
    }

    if (needVaultApproval) {
      approveFromToken();
      return;
    }

    deposit();
    return;
  }, [
    isActive,
    needVaultApproval,
    deposit,
    setShowSignInModal,
    approveFromToken,
  ]);

  const disabledRequestWithdrawMaxButton = useMemo(() => {
    if (!isActive) {
      return true;
    }

    return (
      !availableWithdrawalAssets ||
      new BN(availableWithdrawalAssets).isZero() ||
      availableWithdrawalAssets === "..."
    );
  }, [availableWithdrawalAssets, isActive]);

  const disabledRequestWithdrawButton = useMemo(() => {
    if (
      remainingThisEpochTime &&
      commonVaultInfo.currentEpochStart &&
      currentTime
    ) {
      if (isTxSubmitting.requestWithdrawTxIsSubmitting) {
        return true;
      }

      if (
        availableWithdrawalAssets &&
        withdrawUsdcAmount &&
        possibleRequestWithdraw &&
        withdrawUsdcAmount.lte(
          parseUnits(availableWithdrawalAssets, fromToken.decimals)
        )
      ) {
        return false;
      }
    }
    return true;
  }, [
    availableWithdrawalAssets,
    commonVaultInfo.currentEpochStart,
    currentTime,
    fromToken.decimals,
    isTxSubmitting.requestWithdrawTxIsSubmitting,
    possibleRequestWithdraw,
    remainingThisEpochTime,
    withdrawUsdcAmount,
  ]);

  const requestWithdrawButtonText = useCallback(() => {
    // Sign In | Submit
    if (!isActive) {
      return t`Sign In`;
    }

    return t({ id: `Request Withdraw Button Text`, message: `Submit` });
  }, [isActive]);

  const handleRequestWithdrawButton = () => {
    // withdraw
    if (!isActive) {
      setShowSignInModal(true);
      return;
    }

    requestWithdraw();
    return;
  };

  const handleDepositMaxBtn = useCallback(() => {
    if (!isActive) return;

    const maxAmount = formatAmount(
      collateralBalance,
      fromToken.decimals,
      2,
      false
    );
    setDepositValue(maxAmount);
  }, [isActive, collateralBalance, fromToken.decimals]);

  const handleWithdrawMaxBtn = useCallback(() => {
    if (!isActive || !availableWithdrawalAssets) return;

    // console.log(maxAmount);
    setWithdrawUsdcValue(
      formatStringAmount(availableWithdrawalAssets, 2, false)
    );
  }, [isActive, availableWithdrawalAssets]);

  // useEffect(() => {
  //   const closeTradeToastContents = (
  //     <div>
  //       <div className="text-[1.3rem]">Market close</div>
  //       <div className="text-[1.5rem]">ICP/USD @32.00</div>
  //       <div>8.41 USDC(-24.15%)</div>
  //     </div>
  //   );

  //   const pendingTxToastContents = (
  //     <div>
  //       <div className="text-[1.3rem]">Pending Trade</div>
  //       <div className="text-[1.5rem]">ICP/USD</div>
  //       <ExternalLink href="#">
  //         <Trans>View Status</Trans>
  //       </ExternalLink>
  //     </div>
  //   );

  //   helperToast.success(closeTradeToastContents);
  //   helperToast.error("error");
  //   helperToast.info(pendingTxToastContents);
  // }, []);

  const traderDeposited = useAtomValue(traderDepositedAtom);

  // INFO: USDC 소유: depositShare * gUsdc 가격
  // const usdcOwnership = useMemo(() => {
  //   if (!traderDeposited) return "...";
  //   const { depositAsset, depositShare } = traderDeposited;
  //   if (!depositAsset || !depositShare || !commonVaultInfo.gUsdcPrice) return "...";

  //   const shares = formatUnits(depositShare, gTokenDecimals);
  //   const _gUsdcPrice = formatUnits(commonVaultInfo.gUsdcPrice, gTokenDecimals);
  //   const result = new BN(shares).multipliedBy(_gUsdcPrice);

  //   return result;
  // }, [gTokenDecimals, commonVaultInfo.gUsdcPrice, traderDeposited]);

  // INFO: gDAI market value + total withdrawn - total deposited
  // INFO: 예상수익: depositShare * gUsdc 가격 + (deposits[]에서 -인 asset의 합) - (deposits[]에서  +인 asset의 합)
  // const _estimatedEarnings = useMemo(() => {
  //   if (!traderDeposited) return "...";
  //   const { deposits } = traderDeposited;

  //   if (!usdcOwnership || usdcOwnership === "..." || !deposits || !commonVaultInfo.gUsdcPrice) return "...";

  //   const depositAssets = deposits.map((deposit) => deposit.asset);

  //   const positiveAssets = formatUnits(
  //     depositAssets
  //       .filter((asset) => asset.gte(ethers.constants.Zero))
  //       .reduce((acc, curValue) => acc.add(curValue), ethers.constants.Zero),
  //     gTokenDecimals
  //   );

  //   // console.log(usdcOwnership.toFixed(2));
  //   // console.log(positiveAssets);
  //   const negativeAssets = formatUnits(
  //     depositAssets
  //       .filter((asset) => asset.lt(ethers.constants.Zero))
  //       .reduce((acc, curValue) => acc.add(curValue), ethers.constants.Zero),
  //     gTokenDecimals
  //   );
  //   // console.log(negativeAssets);

  //   // INFO: negativeAssets이 음수이기 때문에 마이너스 연산을 통해 최종적으로 플러스 연산
  //   return usdcOwnership.minus(negativeAssets).minus(positiveAssets).toFixed(2);
  // }, [traderDeposited, usdcOwnership, commonVaultInfo.gUsdcPrice, gTokenDecimals]);

  const estimatedEarnings = useMemo(() => {
    if (!traderDeposited) return "0";
    const { depositAsset, depositShare } = traderDeposited;

    if (
      !account ||
      !depositAsset ||
      !depositShare ||
      !commonVaultInfo.gUsdcPrice ||
      !gTokenDecimals
    )
      return "0";
    const shares = formatUnits(depositShare, gTokenDecimals);
    const assets = formatUnits(depositAsset, fromToken.decimals);
    const _gUsdcPrice = formatUnits(
      commonVaultInfo.gUsdcPrice,
      gTokenDecimals + ADDED_DECIMALS
    );
    // const result = depositShare.mul(commonVaultInfo.gUsdcPrice).div(1e18).sub(depositAsset);
    const result = new BN(shares).multipliedBy(_gUsdcPrice).minus(assets);

    if (result.toFixed(2) === "-0.00") return "0.00";

    return result.toFixed(2);
  }, [
    traderDeposited,
    account,
    commonVaultInfo.gUsdcPrice,
    gTokenDecimals,
    fromToken.decimals,
  ]);

  const currentEpochStart = useMemo(() => {
    if (!commonVaultInfo.currentEpochStart) return "";
    return dayjs.unix(commonVaultInfo.currentEpochStart).format("L LTS");
  }, [commonVaultInfo.currentEpochStart]);

  const currentEpochEnd = useMemo(() => {
    if (!commonVaultInfo.currentEpochStart || !epochLong) return "";
    return dayjs
      .unix(commonVaultInfo.currentEpochStart)
      .add(epochLong, "hours")
      .format("L LTS");
  }, [commonVaultInfo.currentEpochStart, epochLong]);

  const { data: aprData } = useSWR(
    "https://service.gambit.trade:7443/query/vault/apr?network=all",
    {
      fetcher: swrFetcher,
      refreshInterval: 60 * 1000 * 5, // * 5분
    }
  );

  const vaultAPR = useMemo(() => {
    if (!chainId) return;
    if (!aprData) return 10;
    // const apr = aprData.usdc_apr as string;

    let apr = "10";

    try {
      if (chainId === ARBITRUM) {
        apr = aprData.arbitrum.usdc_apr as string;
      } else if (chainId === ZKSYNC) {
        apr = aprData.zksync.usdc_apr as string;
      }
      return (+apr).toFixed(2) ?? 10;
    } catch (e) {
      return 10;
    }
  }, [aprData, chainId]);

  return (
    <SEO
      title={i18n._(
        getPageTitle(
          // t({
          //   id: "msg.title / Vault",
          //   message: `${fromToken.symbol} Vault`,
          // })
          t`${fromToken.symbol} Vault`
        )
      )}
    >
      <div className="max-w-[108rem] mx-auto py-[1.4rem] lg:pt-[3.5rem]">
        {/* Content */}
        <div className="flex flex-col gap-[2.4rem] lg:flex-row lg:px-[1.6rem]">
          {/* Left */}
          <div className="flex-1 min-w-[53%] space-y-[2.4rem]">
            {/* Left Top */}
            <div className="bg-black-3 border-gray-70 p-[1.6rem] sm:p-[2.4rem] lg:border lg:rounded-[1.2rem]">
              {/* Left Top Header */}
              <div className="flex flex-col sm:items-center sm:flex-row">
                <h1 className="text-[2rem] font-medium mr-[1.6rem]">
                  <span
                    className={cx(
                      "font-space-grotesk tracking-tight xs-:tracking-normal lg:tracking-tight xl:tracking-normal"
                    )}
                  >
                    {fromToken.symbol}
                  </span>{" "}
                  <Trans>Vault</Trans>
                </h1>

                <div className="flex items-center mt-[8px] sm:mt-0">
                  <div
                    className={cx(
                      "bg-white bg-opacity-10 py-[0.55rem] px-[0.8rem] flex items-center min-h-[2.6rem]",
                      "text-gray-05 rounded-[2rem] text-[1.2rem] font-medium"
                    )}
                  >
                    <span
                      className={cx(
                        "heading9 tracking-tight xs-:tracking-normal lg:tracking-tight xl:tracking-normal"
                      )}
                    >
                      <Trans>Est.APR</Trans>
                      <span>
                        {" "}
                        {vaultAPR}% {fromToken.symbol}
                      </span>
                    </span>
                  </div>

                  {/* {[ZKSYNC].includes(chainId) && (
                    <>
                      <span className="heading9 mx-[2.5px] xs-:mx-[4px] text-gray-15">+</span>
                      <div
                        className={cx(
                          "py-[0.55rem] px-[0.8rem] flex items-center min-h-[2.6rem]",
                          "text-gray-05 rounded-[2rem] text-[1.2rem] font-medium",
                          "bg-brand-1 bg-opacity-10",
                          "border border-brand-1"
                        )}
                      >
                        <span
                          className={cx(
                            "heading9 text-brand-1 tracking-tight xs-:tracking-normal lg:tracking-tight xl:tracking-normal"
                          )}
                        >
                          <Trans>Event Reward up to APY</Trans>
                          <span> 52% CNG</span>
                        </span>
                      </div>
                    </>
                  )} */}
                </div>
              </div>

              {/* Left Top Content */}
              <div className="bg-black-2 mt-[2.4rem] mb-[2rem] p-[1.6rem] sm:p-[2.4rem] rounded-[1.2rem]">
                <h3 className="text-[1.7rem] font-medium">
                  <Trans>Your Current Standings</Trans>
                </h3>
                <div className="mt-[3.2rem] space-y-[1.2rem] text-[1.5rem] font-medium">
                  <div className="">
                    <span className="text-gray-15">
                      <Trans id="msg.vault / ownership">
                        {fromToken.symbol} ownership:{" "}
                      </Trans>
                    </span>
                    <span className="font-space-grotesk text-gray-00 ml-[1rem]">
                      {traderDeposited?.depositAsset
                        ? formatAmount(
                            traderDeposited?.depositAsset,
                            gTokenDecimals,
                            2,
                            true
                          )
                        : "0"}{" "}
                      {fromToken.symbol}
                    </span>

                    {/* <span className="font-space-grotesk text-gray-00 ml-[1rem]">
                      {usdcOwnership !== "..." && usdcOwnership.toFixed(2)}
                      {fromToken.symbol}
                    </span> */}
                  </div>
                  <div>
                    <span className="text-gray-15">
                      <Trans>Estimated Earnings: </Trans>
                    </span>
                    <span className="font-space-grotesk text-gray-00 ml-[1rem]">
                      {numberWithCommas(estimatedEarnings)}{" "}
                      {fromToken.symbol.toUpperCase()}
                    </span>
                    {/* <span className="text-gray-00 ml-[1rem]">{_estimatedEarnings} USDC</span> */}
                  </div>
                </div>
              </div>

              {/* Left Top Bottom */}
              <div className="flex flex-wrap gap-[1rem]">
                <div className="flex-1">
                  <Tooltip
                    handle={
                      <div className="mb-[0.4rem] text-[1.4rem] text-gray-15 font-medium underline">
                        <Trans>Platform TVL</Trans>
                      </div>
                    }
                    disableHandleStyle={true}
                    position="left-top"
                    tooltipClassName="w-[33rem]"
                    renderContent={() => (
                      <span className="text-[1.4rem]">
                        <Trans>
                          Total {fromToken.symbol} staked with Gambit
                        </Trans>
                      </span>
                    )}
                  />
                  <div className="flex items-center">
                    <span className="font-space-grotesk text-[2rem] text-gray-00 font-medium">
                      {threeDotsToEmptyString(
                        formatAmount(
                          commonVaultInfo.tvl,
                          vaultToken.decimals,
                          2,
                          true
                        )
                      )}
                    </span>
                    {threeDotsToEmptyString(
                      formatAmount(
                        commonVaultInfo.tvl,
                        vaultToken.decimals,
                        2,
                        true
                      )
                    ) && (
                      <img
                        className="ml-[0.4rem]"
                        src={fromTokenImage}
                        alt="from token logo"
                        width="24"
                      />
                    )}
                  </div>
                </div>

                <div className="flex-1">
                  <Tooltip
                    handle={
                      <div className="mb-[0.4rem] text-[1.4rem] text-gray-15 font-medium underline">
                        <Trans>Collateral Ratio</Trans>
                      </div>
                    }
                    disableHandleStyle={true}
                    position="left-top"
                    tooltipClassName="w-[33rem]"
                    renderContent={() => (
                      <div className="text-[1.4rem]">
                        <div>
                          <Trans>
                            Ratio of open positive and negative PnL relative to
                            the {fromToken.symbol} vault.
                          </Trans>
                        </div>
                        {/* <a className="text-blue-500" href="https://docs.gambit.trade" target="_blank" rel="noreferrer">
                          <Trans>Learn more</Trans>
                        </a> */}
                      </div>
                    )}
                  />
                  <span className="font-space-grotesk text-[2rem] text-gray-00 font-medium">
                    {threeDotsToEmptyString(
                      formatAmount(
                        commonVaultInfo.collateralizationP,
                        10,
                        2,
                        true
                      )
                    ) &&
                      `${threeDotsToEmptyString(
                        formatAmount(
                          commonVaultInfo.collateralizationP,
                          10,
                          2,
                          true
                        )
                      )} %`}
                  </span>
                </div>
              </div>
            </div>

            {/* Left Bottom */}
            <div className="bg-black-3 border-gray-70 p-[1.6rem] sm:p-[2.4rem] lg:border lg:rounded-[1.2rem]">
              <div className="flex items-center mb-[2.5rem]">
                <h2 className="mr-[1.2rem] text-[1.8rem] font-medium">
                  <Trans id="msg.vault / current epoch">Epoch</Trans>

                  {currentLanguage === "ko" ? ": " : " "}
                  {commonVaultInfo.currentEpoch}
                  {currentLanguage === "ko" ? "회차" : ""}
                </h2>
                {!commonVaultInfo.currentEpochStart && (
                  <span className="text-[1.2rem] text-gray-10">
                    Remaining:{" "}
                  </span>
                )}

                {commonVaultInfo.currentEpochStart && remainingThisEpochTime && (
                  <Trans>
                    <span className="text-[1.2rem] text-gray-10">
                      Remaining:{" "}
                      <span>
                        {
                          remainingThisEpochTime
                            .format("D H mm ss")
                            .split(" ")[0]
                        }
                        d
                      </span>{" "}
                      <span>
                        {
                          remainingThisEpochTime
                            .format("D H mm ss")
                            .split(" ")[1]
                        }
                        h
                      </span>{" "}
                      <span>
                        {
                          remainingThisEpochTime
                            .format("D H mm ss")
                            .split(" ")[2]
                        }
                        m
                      </span>{" "}
                      <span>
                        {
                          remainingThisEpochTime
                            .format("D H mm ss")
                            .split(" ")[3]
                        }
                        s
                      </span>
                    </span>
                  </Trans>
                )}

                {commonVaultInfo.currentEpochStart &&
                  currentEpochEndPlus1HourEndDayjs &&
                  !remainingThisEpochEndTimePlus1Hour &&
                  (isTxSubmitting.forceNewEpochTxIsSubmitting ? (
                    <Lottie
                      className="w-[2rem] h-[2rem]"
                      animationData={pendingAnimation}
                    />
                  ) : (
                    <button
                      onClick={forceNewEpoch}
                      className="border border-gray-40 rounded-[0.2rem] py-[0.4rem] px-[0.8rem] text-[1.2rem] text-gray-10 font-medium"
                    >
                      <Trans>Force new epoch</Trans>
                    </button>
                  ))}
              </div>
              <div className="mb-[1.2rem] text-[1.4rem] font-medium">
                <span className="text-gray-20">
                  <Trans id="msg.vault / epoch start">Start:</Trans>
                </span>
                <span className="font-space-grotesk ml-[1.2rem] text-gray-00">
                  {currentEpochStart}
                </span>
              </div>
              <div className="text-[1.4rem] font-medium">
                <span className="text-gray-20">
                  <Trans id="msg.vault / epoch end">End:</Trans>
                </span>
                <span className="font-space-grotesk ml-[1.2rem] text-gray-00">
                  {currentEpochEnd}
                </span>
              </div>

              <div className="mt-[1rem] text-[1.2rem]">
                <Trans>Remaining time to withdraw in current epoch</Trans>:{" "}
                {remainingToWithdrawTime ? (
                  <Trans>
                    <span>
                      {
                        remainingToWithdrawTime
                          .format("D H mm ss")
                          .split(" ")[0]
                      }
                      d
                    </span>{" "}
                    <span>
                      {
                        remainingToWithdrawTime
                          .format("D H mm ss")
                          .split(" ")[1]
                      }
                      h
                    </span>{" "}
                    <span>
                      {
                        remainingToWithdrawTime
                          .format("D H mm ss")
                          .split(" ")[2]
                      }
                      m
                    </span>{" "}
                    <span>
                      {
                        remainingToWithdrawTime
                          .format("D H mm ss")
                          .split(" ")[3]
                      }
                      s
                    </span>
                  </Trans>
                ) : (
                  <span className="text-red-2">
                    <Trans id="msg.vault / ended">ended</Trans>
                  </span>
                )}
              </div>
            </div>
          </div>

          {/* Right */}
          <div className="flex-1 min-w-[44%] flex flex-col gap-[2.4rem]">
            {/* Right Top */}
            <div className="bg-black-3 border-gray-70 p-[1.6rem] sm:p-[2.4rem] lg:border lg:rounded-[1.2rem]">
              {/* Deposit / Withdraw Tabs */}
              <div className="flex text-center text-[1.4rem]">
                <button
                  onClick={() => setActiveTab(VAULT_TAB_TYPE.DEPOSIT)}
                  className={cx(
                    "flex-1 rounded-tl-[0.6rem] rounded-bl-[0.6rem] py-[0.9rem] font-medium cursor-pointer",
                    activeTab === VAULT_TAB_TYPE.DEPOSIT
                      ? "bg-white text-black-2"
                      : "bg-black-2 text-gray-15"
                  )}
                >
                  <Trans id="msg.vault / Deposit tab">Deposit</Trans>
                </button>
                <button
                  onClick={() => setActiveTab(VAULT_TAB_TYPE.WITHDRAW)}
                  className={cx(
                    "flex-1 rounded-tr-[0.6rem] rounded-br-[0.6rem] py-[0.9rem] font-medium cursor-pointer",
                    activeTab === VAULT_TAB_TYPE.WITHDRAW
                      ? "bg-white text-black-2"
                      : "bg-black-2 text-gray-15"
                  )}
                >
                  <Trans id="msg.vault / Withdraw tab">Withdraw</Trans>
                </button>
              </div>

              {/* Deposit Tab Contents */}
              {activeTab === VAULT_TAB_TYPE.DEPOSIT && (
                <div>
                  <h3 className="mt-[2.4rem] mb-[1.3rem] text-[1.8rem] font-medium">
                    <Trans>
                      Stake
                      <span
                        className={cx(
                          "font-space-grotesk",
                          currentLanguage === "ko" ? "inline" : "hidden"
                        )}
                      >
                        {fromToken.symbol.toUpperCase()}
                      </span>
                    </Trans>
                  </h3>

                  <div className="flex justify-between items-center tiny sm:heading9">
                    <div className="">
                      <span className="text-gray-30">
                        <Trans id="msg.vault / Deposit - Current Wait Period">
                          Current Wait Period
                        </Trans>
                        :{" "}
                      </span>
                      {commonVaultInfo.withdrawEpochsTimelock && (
                        <span>
                          {currentLanguage === "ko"
                            ? `총 ${commonVaultInfo.withdrawEpochsTimelock}`
                            : `${commonVaultInfo.withdrawEpochsTimelock} `}
                          {commonVaultInfo.withdrawEpochsTimelock === 1 ? (
                            <Trans id="msg.vault / epoch time lock">
                              epoch
                            </Trans>
                          ) : (
                            <Trans id="msg.vault / epochs time lock">
                              epochs
                            </Trans>
                          )}
                        </span>
                      )}
                    </div>
                    <div>
                      <span className="font-space-grotesk text-gray-30">
                        <Trans>Withdraw Date</Trans>:{" "}
                      </span>
                      {withdrawDate && (
                        <Tooltip
                          handle={
                            <span className="font-space-grotesk underline">{`${withdrawDate.format(
                              "L"
                            )}`}</span>
                          }
                          disableHandleStyle={true}
                          position="center-top"
                          renderContent={() => (
                            <span className="font-space-grotesk">
                              {withdrawDate.format("L LTS")}
                            </span>
                          )}
                        />
                      )}
                    </div>
                  </div>

                  <div>
                    <div className="flex my-[0.8rem] bg-black-2 border-b border-gray-70 rounded-[1.2rem] p-[1.6rem]">
                      <div className="flex items-center">
                        <img
                          className="mr-[0.6rem]"
                          src={fromTokenImage}
                          alt="from token logo"
                          width="24"
                        />
                        <span className="font-space-grotesk text-[1.8rem] font-medium">
                          {fromToken.symbol}
                        </span>
                      </div>
                      <button
                        disabled={disabledDepositMaxButton}
                        onClick={handleDepositMaxBtn}
                        className="bg-green-3 mx-[1.2rem] rounded-[0.4rem] py-[0.4rem] px-[0.6rem] text-[1.2rem] text-green-1 font-medium cursor-pointer self-center"
                      >
                        <Trans>MAX</Trans>
                      </button>
                      {/* <button
                        onClick={() => {
                          if (!provider) return;

                          revokeToken({
                            setIsApproving,
                            provider,
                            tokenAddress: fromToken.address,
                            spender: vaultTokenAddress,
                            chainId: chainId,
                            onApproveSubmitted: () => {
                              setIsWaitingForApproval(true);
                            },
                            pendingTxns,
                            setPendingTxns,
                          });
                        }}
                      >
                        Revoke
                      </button> */}
                      <input
                        className="font-space-grotesk flex-1 text-right text-[2.4rem] text-white font-bold"
                        value={depositValue}
                        onChange={(e) => {
                          if (
                            !/^([0-9]*(?:[.,][0-9]*)?)$/.test(e.target.value)
                          ) {
                            return;
                          }
                          // INFO: 소수점 6자리까지만 입력 허용
                          if (/^[\d]*\.?[\d]{0,6}$/.test(e.target.value)) {
                            setDepositValue(e.target.value);
                          } else {
                            return;
                          }
                        }}
                        placeholder="0"
                      />
                    </div>
                    <div className="text-[1.2rem] font-medium text-right">
                      <Trans>Balance</Trans>:{" "}
                      <span className="font-space-grotesk">
                        {threeDotsToZero(
                          formatAmount(
                            collateralBalance,
                            fromToken.decimals,
                            2,
                            true
                          )
                        )}{" "}
                        {fromToken.symbol}
                      </span>
                    </div>
                  </div>
                  <button
                    onClick={handleDepositButton}
                    disabled={disabledDepositButton}
                    className={cx("brand-btn mt-[2.4rem]")}
                  >
                    {isTxSubmitting.depositTxIsSubmitting ? (
                      <img
                        className="mx-auto h-[0.8rem]"
                        src={loader}
                        alt="loader"
                      />
                    ) : (
                      depositButtonText()
                    )}
                  </button>
                </div>
              )}

              {/* Withdraw Tab Contents */}
              {activeTab === VAULT_TAB_TYPE.WITHDRAW && (
                <div>
                  <h3 className="mt-[2.4rem] mb-[1.3rem] text-[1.8rem] font-medium">
                    <Trans>
                      Unstake
                      <span
                        className={cx(
                          "font-space-grotesk",
                          currentLanguage === "ko" ? "inline" : "hidden"
                        )}
                      >
                        {fromToken.symbol.toUpperCase()}
                      </span>
                    </Trans>
                  </h3>

                  <div className="flex justify-between items-center text-[1.2rem] font-medium">
                    <div className="">
                      <span className="text-gray-30">
                        <Trans>Current Wait Period</Trans>:{" "}
                      </span>
                      {commonVaultInfo.withdrawEpochsTimelock && (
                        <span>
                          {currentLanguage === "ko"
                            ? `총 ${commonVaultInfo.withdrawEpochsTimelock}`
                            : `${commonVaultInfo.withdrawEpochsTimelock} `}
                          <Trans>
                            {commonVaultInfo.withdrawEpochsTimelock === 1
                              ? t({
                                  id: `msg.vault / Current Wait Period epoch`,
                                  message: `epoch`,
                                })
                              : t({
                                  id: `msg.vault / Current Wait Period epochs`,
                                  message: `epochs`,
                                })}
                          </Trans>
                        </span>
                      )}
                    </div>
                    <div>
                      <span className="text-gray-30">
                        <Trans>Withdraw Date</Trans>:{" "}
                      </span>
                      {withdrawDate && (
                        <Tooltip
                          handle={
                            <span className="font-space-grotesk underline">{`${withdrawDate.format(
                              "L"
                            )}`}</span>
                          }
                          disableHandleStyle={true}
                          position="center-top"
                          renderContent={() => (
                            <span className="font-space-grotesk">
                              {withdrawDate.format("L LTS")}
                            </span>
                          )}
                        />
                      )}
                    </div>
                  </div>

                  <div>
                    <div className="flex my-[0.8rem] bg-black-2 border-b border-gray-70 rounded-[1.2rem] p-[1.6rem]">
                      <div className="flex items-center">
                        <img
                          className="mr-[0.6rem]"
                          src={fromTokenImage}
                          alt="from token logo"
                          width="24"
                        />
                        <span className="font-space-grotesk text-[1.8rem] font-medium">
                          {fromToken.symbol}
                        </span>
                      </div>
                      <button
                        disabled={disabledRequestWithdrawMaxButton}
                        onClick={handleWithdrawMaxBtn}
                        className="bg-green-3 mx-[1.2rem] rounded-[0.4rem] py-[0.4rem] px-[0.6rem] text-[1.2rem] text-green-1 font-medium cursor-pointer self-center"
                      >
                        <Trans>MAX</Trans>
                      </button>
                      <input
                        className="font-space-grotesk flex-1 text-right text-[2.4rem] text-white font-bold"
                        value={withdrawUsdcValue}
                        onChange={(e) => {
                          if (
                            !/^([0-9]*(?:[.,][0-9]*)?)$/.test(e.target.value)
                          ) {
                            return;
                          } // INFO: 소수점 6자리까지만 입력 허용
                          if (/^[\d]*\.?[\d]{0,6}$/.test(e.target.value)) {
                            setWithdrawUsdcValue(e.target.value);
                          } else {
                            return;
                          }
                        }}
                        placeholder="0"
                      />
                    </div>
                    <div className="text-[1.2rem] font-medium text-right">
                      <Trans>Available for Withdrawal</Trans>:{" "}
                      <span className="font-space-grotesk">
                        {threeDotsToZero(
                          formatStringAmount(availableWithdrawalAssets, 2, true)
                        )}{" "}
                        {fromToken.symbol}
                      </span>
                    </div>
                  </div>

                  <div className="mt-[1rem] text-[1.2rem]">
                    {remainingToRequestWithdrawTime ? (
                      <div>
                        <span>
                          <Trans>
                            Remaining time to withdraw in current epoch:
                          </Trans>
                        </span>{" "}
                        <Trans>
                          <span>
                            {
                              remainingToRequestWithdrawTime
                                .format("D H mm ss")
                                .split(" ")[0]
                            }
                            d
                          </span>{" "}
                          <span>
                            {
                              remainingToRequestWithdrawTime
                                .format("D H mm ss")
                                .split(" ")[1]
                            }
                            h
                          </span>{" "}
                          <span>
                            {
                              remainingToRequestWithdrawTime
                                .format("D H mm ss")
                                .split(" ")[2]
                            }
                            m
                          </span>{" "}
                          <span>
                            {
                              remainingToRequestWithdrawTime
                                .format("D H mm ss")
                                .split(" ")[3]
                            }
                            s
                          </span>
                        </Trans>
                      </div>
                    ) : (
                      <span className="text-red-2">
                        <Trans>
                          You may only submit withdraw requests in the first{" "}
                          {requestToWithdrawLong}{" "}
                          {requestToWithdrawLong === 1 ? `hour` : `hours`} of
                          the epoch
                        </Trans>
                      </span>
                    )}
                  </div>

                  <button
                    onClick={handleRequestWithdrawButton}
                    disabled={disabledRequestWithdrawButton}
                    className={cx("brand-btn mt-[2.4rem]")}
                  >
                    {isTxSubmitting.requestWithdrawTxIsSubmitting ? (
                      <img
                        className="mx-auto h-[0.8rem]"
                        src={loader}
                        alt="loader"
                      />
                    ) : (
                      requestWithdrawButtonText()
                    )}
                  </button>
                </div>
              )}
            </div>

            {/* Right Bottom */}
            <div
              className={cx(
                "bg-black-3 border-gray-70 lg:border lg:rounded-[1.2rem]",
                "px-[2.4rem] pt-[2.4rem] pb-[0.8rem]",
                { "pb-[2.4rem]": existingRequestsNum === 0 }
              )}
            >
              <h3 className="text-[1.8rem] font-medium">
                <Trans>Existing Requests</Trans>{" "}
                {isActive &&
                  existingRequestsNum !== null &&
                  existingRequestsNum !== undefined &&
                  existingRequestsNum !== "" &&
                  `(${existingRequestsNum})`}
              </h3>
              {typeof existingRequestsNum === "number" &&
              existingRequestsNum > 0 ? (
                <table className="w-full mt-[2.5rem]">
                  <thead>
                    <tr className="text-[1.2rem] text-gray-15 font-medium text-left">
                      <th className="pb-[1.2rem] pr-[1.2rem] w-[30%]">
                        <Trans id="msg.vault.existingRequests / Amount">
                          Amount
                        </Trans>
                      </th>
                      <th className="pb-[1.2rem] pr-[1.2rem] w-[28%]">
                        <Trans id="msg.vault.existingRequests / Epoch">
                          Epoch
                        </Trans>
                      </th>
                      <th className="pb-[1.2rem] pr-[1.2rem] w-[42%]">
                        <Trans id="msg.vault.existingRequests / Withdraw">
                          Withdraw
                        </Trans>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {commonVaultInfo.gUsdcPrice &&
                      existingRequests.map((existingRequest) => {
                        const formattedAmount = formatAmount(
                          existingRequest.amount.mul(
                            commonVaultInfo.gUsdcPrice
                          ),
                          vaultToken.decimals +
                            vaultToken.decimals +
                            ADDED_DECIMALS,
                          2,
                          true
                        );

                        return (
                          <tr key={existingRequest.epoch} className="heading7">
                            <td className="pb-[1.6rem]">
                              {/* 
                            gUsdc -> Usdc 변환해서 보여 줌
                            gUsdc * commonVaultInfo.gUsdcPrice 했기 때문에, decimals 2번 처리
                          */}
                              <span className="font-space-grotesk">
                                {formattedAmount}
                              </span>
                              <img
                                className="inline-block ml-[0.4rem]"
                                src={fromTokenImage}
                                alt="from token logo"
                                width="18"
                              />
                            </td>

                            <td className="pb-[1.6rem]">
                              {existingRequest.epoch}
                              {currentLanguage === "ko" && "회차"}
                            </td>

                            {existingRequest.withdrawDate ? (
                              <td className="font-space-grotesk pb-[1.6rem]">
                                {existingRequest.withdrawDate?.format("L LTS")}
                              </td>
                            ) : existingRequest.possibleWithdraw ? (
                              isTxSubmitting.redeemTxIsSubmitting ? (
                                <Lottie
                                  className="w-[2rem] h-[2rem]"
                                  animationData={pendingAnimation}
                                />
                              ) : (
                                <td className="pb-[1.6rem]">
                                  <button
                                    className="brand-small-btn heading8"
                                    onClick={() => {
                                      redeem(
                                        existingRequest.amount,
                                        formattedAmount
                                      );
                                    }}
                                  >
                                    <Trans>Withdraw</Trans>
                                  </button>
                                </td>
                              )
                            ) : (
                              <td className="pb-[1.6rem]">
                                <button
                                  disabled
                                  className="brand-small-btn heading8 cursor-auto"
                                >
                                  Expired
                                </button>
                              </td>
                            )}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              ) : (
                <div className="pt-[3.2rem] pb-[4.9rem] text-[1.2rem] font-medium text-gray-30 text-center">
                  <Trans id="msg.vault / no data">
                    There is no data to show
                  </Trans>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </SEO>
  );
}
