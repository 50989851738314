// date format: d MMM yyyy, H:mm, time should be specifed based on UTC time

// import { ZKSYNC } from "./chains";

export type EventData = {
  id: string;
  title: string | any;
  isActive?: boolean;
  validTill: string;
  bodyText: string | string[] | any;
  buttons: {
    text: string;
    link: string;
    koLink?: string;
    newTab: boolean;
  }[];
  chainIds: number[];
};

export const homeEventsData: EventData[] = [];

// const getTimezoneOffset = () => {
//   try {
//     const offset = dayjs().utcOffset() / 60;
//     return `${offset >= 0 ? "+" : ""}${offset}`;
//   } catch (e) {
//     return;
//   }
// };

// const timezoneOffset = getTimezoneOffset();

export const appEventsData: EventData[] = [
  // {
  //   id: "Maintenance",
  //   title: "Maintenance",
  //   isActive: true,
  //   validTill: "30 Dec 2024, 12:00",
  //   // bodyText: `GAMBIT AIRDROP EVENT BODY TEXT`,
  //   bodyText: [
  //     `Trading will be paused for service maintenance on April 26 from ${dayjs(
  //       "2024/4/26 10:00:00 UTC+9"
  //     ).format("HH:mma")} to ${dayjs("2024/4/26 12:00:00 UTC+9").format(
  //       "HH:mma"
  //     )}${timezoneOffset ? `(UTC${timezoneOffset})` : ""}.`,
  //     `Once the maintenance is over, you will see an "Update Available" button to the left of your wallet address (or Sign In) button at the top of the screen, please click on it.`,
  //   ],
  //   buttons: [
  //     // {
  //     //   text: "Read More",
  //     //   link: "https://event.gambit.trade",
  //     //   koLink: "https://event.gambit.trade/v/ko",
  //     //   newTab: true,
  //     // },
  //   ],
  //   // chainIds: [ZKSYNC],
  //   chainIds: SUPPORTED_CHAIN_IDS,
  // },
];
