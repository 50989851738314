import { BEGINING_NUM_FOR_EXISTING_REQUESTS } from "components/Vault/constants";

/**
 * Existing Requests 구하기 위함
 * withdrawEpochsTimeLock이 3인 경우 +3 epoch 까지
 * current epoch 기준 -2, -1, current epoch, +1, +2, +3 까지 조회할 목적
 * currentEpoch은 1부터 시작
 *
 * e.g)
 * targerEpochs(1,3) -> [1, 2, 3, 4]
 * targerEpochs(2,3) -> [1, 2, 3, 4, 5]
 * targerEpochs(3,3) -> [1, 2, 3, 4, 5, 6]
 * targerEpochs(4,3) -> [2, 3, 4, 5, 6, 7]
 * targerEpochs(5,3) -> [3, 4, 5, 6, 7, 8]
 */
export const targerEpochs = (currentEpoch: number, withdrawEpochsTimeLock: number) => {
  const results: number[] = [];

  // current epoch보다 BEGINING_NUM_FOR_EXISTING_REQUESTS epoch 이전 것 까지 확인
  for (let i = 1; i <= BEGINING_NUM_FOR_EXISTING_REQUESTS; i++) {
    const subValue = currentEpoch - i;
    if (subValue <= 0) break;
    results.unshift(subValue);
  }

  for (let i = 0; i <= withdrawEpochsTimeLock; i++) {
    results.push(currentEpoch + i);
  }
  return results;
};
