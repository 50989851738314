import PositionTab from "components/Tab/PositionTab";
import "./Exchange.css";

import TradeBoxV2 from "components/Exchange/TradeBoxV2";
import ExchangeTVChartV2 from "components/Exchange/ExchangeTVChartV2";
import PositionListV2 from "components/Exchange/PositionListV2";
import SEO from "components/Common/SEO";
import { getPageTitle } from "futures-lib/legacy";
import { UpdateSlTpModal } from "components/PositionsList/UpdateSlTpModal";
import { DetailTradeModal } from "components/PositionsList/DetailTradeModal";
import { ShareTradeModal } from "components/PositionsList/ShareTradeModal";
import { CloseTradeModal } from "components/PositionsList/CloseTradeModal";
// import { t } from "@lingui/macro";
import { DetailOrderModal } from "components/PositionsList/DetailOrderModal";
// import { useLingui } from "@lingui/react";
import { useWindowSize } from "react-use";
import { useMemo } from "react";
import { useTrader } from "futures-domain/trades/useTrader";
import { EditLiqPriceModal } from "components/PositionsList/EditLiqPriceModal";
import { pairsPricesAtom, selectedPairAtom } from "atoms/exchange";
import { useAtomValue } from "jotai";
import { formatUnits } from "@ethersproject/units";
import { BigNumber } from "ethers";
import { trimPriceString } from "futures-domain/trades/utils";
import { numberWithCommas } from "futures-lib/numbers";
import RollingPairsPrices from "components/Exchange/RollingPairsPrices";

export const ExchangeV2 = () => {
  useTrader();

  // const { i18n } = useLingui();
  const { width } = useWindowSize();

  const isMobile = useMemo(() => {
    return width <= 1350;
  }, [width]);

  // const renderChart = useCallback(() => {
  //   return <ExchangeTVChartV2 />;
  // }, []);

  const selectedPair = useAtomValue(selectedPairAtom);
  const pairsPrices = useAtomValue(pairsPricesAtom);

  const selectedPairPrice = useMemo(() => {
    if (!selectedPair || !pairsPrices[selectedPair?.priceFeedId]) return;

    const { price, expo } = pairsPrices[selectedPair.priceFeedId!];
    return formatUnits(BigNumber.from(price), Math.abs(expo));
  }, [pairsPrices, selectedPair]);

  return (
    // <SEO
    //   title={i18n._(
    //     getPageTitle(
    //       t({
    //         id: "msg.title / Trade",
    //         message: "Trade",
    //       })
    //     )
    //   )}
    // >
    <SEO
      title={getPageTitle(
        selectedPairPrice
          ? `${numberWithCommas(trimPriceString(selectedPairPrice))} | ${selectedPair?.from}/${selectedPair?.to}`
          : ""
      )}
    >
      <UpdateSlTpModal />
      <EditLiqPriceModal />

      <DetailTradeModal />
      <ShareTradeModal />
      <DetailOrderModal />
      <CloseTradeModal />

      {/* Trade 페이지는 기본적으로 헤더를 7px 침범하기 때문에 7px pt 추가 */}
      {/* 42.5px + 7px = 49.5px */}
      <div className="Exchange pt-[0.7rem] pb-[2rem]">
        <RollingPairsPrices />

        {/* <div className="h-[1rem]" />

        <FlowingPairsPrices /> */}

        {/* pt 1.5rem(pt-0과 같음) + 1.2rem(실제 디자인에서 pt) */}
        <div className="Exchange-content px-0 gap-[1.6rem] xs:gap-[2.4rem] xs:px-[1.5rem] pt-[2.7rem]">
          <div className="Exchange-left">
            <ExchangeTVChartV2 />

            {/* Position List for Desktop: 1351px ~ */}
            {!isMobile && (
              <div className="Exchange-lists large overflow-y-auto">
                <div>
                  <div className="mt-[2rem] mb-[1.2rem]">
                    <PositionTab />
                  </div>
                  <PositionListV2 />
                </div>
              </div>
            )}
          </div>
          {/* Exchange-right */}
          <div className="">
            <TradeBoxV2 />
          </div>

          {/* Position List for Mobile: ~ 1350px */}
          {isMobile && (
            <div className="Exchange-lists small overflow-y-auto">
              <div>
                <div className="pb-[1.2rem] px-[1.6rem]">
                  <PositionTab />
                </div>
                <PositionListV2 />
              </div>
            </div>
          )}
        </div>
        {/* <Footer /> */}
      </div>
    </SEO>
  );
};
