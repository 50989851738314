import { formatUnits } from "@ethersproject/units";
import { Trans } from "@lingui/macro";
import {
  isVisibleShareTradeModalAtom,
  pairsPricesAtom,
  selectedTradeAtom,
} from "atoms/exchange";
import BN from "bignumber.js";
import LongShortLable from "components/Common/LongShortLable";
import { GAMBIT_USD_DECIMALS } from "components/Exchange/constants";
import ModalSkeletonWithPortal from "components/Modal/ModalSkeletonWithPortal";
import { getContract } from "config/contracts";
import { getToken } from "config/tokens";
import { BigNumber } from "ethers";
import {
  comma1000,
  getNetPnL,
  getNetPnLPercent,
  getPriceChange,
  trimPriceBN,
  trimPriceString,
} from "futures-domain/trades/utils";
import { useChainId } from "futures-lib/chains";
import { helperToast } from "futures-lib/helperToast";
import { formatAmount } from "futures-lib/numbers";
import * as htmlToImage from "html-to-image";
import GambitLogoImage from "img/logo-mobile.svg";
import { useAtom, useAtomValue } from "jotai";
import { useCallback, useEffect, useMemo, useRef } from "react";

export const ShareTradeModal = () => {
  const imageRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useAtom(isVisibleShareTradeModalAtom);

  const pairsPrices = useAtomValue(pairsPricesAtom);
  const selectedTrade = useAtomValue(selectedTradeAtom);

  const { chainId } = useChainId();

  const fromTokenAddress = getContract(chainId, "CollateralToken");
  const fromToken = getToken(chainId, fromTokenAddress);

  const currentPriceValue = useMemo(() => {
    if (!pairsPrices || !selectedTrade) return;

    try {
      const { price, expo } = pairsPrices[selectedTrade?.pair.priceFeedId!];

      return formatUnits(BigNumber.from(price), Math.abs(expo));
    } catch (e) {
      return;
    }
  }, [pairsPrices, selectedTrade]);

  const openAndNetworkFee = useMemo(() => {
    if (selectedTrade?.initialPosUsdc.gt(0)) {
      try {
        return formatAmount(
          new BN(selectedTrade.initialPosUsdc.toString())
            .minus(selectedTrade.positionSizeUsdc.toString())
            .toString(),
          fromToken.decimals,
          2,
          false
        );
      } catch (e) {
        if (selectedTrade?.initialPosUsdc.gt(0)) {
          const initialPosUsdcString = formatAmount(
            selectedTrade.initialPosUsdc,
            fromToken.decimals,
            6,
            false
          );
          const positionSizeUsdcString = formatAmount(
            selectedTrade.positionSizeUsdc,
            fromToken.decimals,
            6,
            false
          );

          return new BN(initialPosUsdcString)
            .minus(positionSizeUsdcString)
            .toFixed(2);
        }
      }
    }
    return;
  }, [
    fromToken.decimals,
    selectedTrade?.initialPosUsdc,
    selectedTrade?.positionSizeUsdc,
  ]);

  const netPnL = useMemo(() => {
    if (!selectedTrade || !currentPriceValue) return;

    const openPriceValue = formatUnits(
      selectedTrade.openPrice,
      GAMBIT_USD_DECIMALS
    );
    const priceChange = getPriceChange(currentPriceValue, openPriceValue);

    return getNetPnL(
      selectedTrade,
      priceChange,
      fromToken.decimals,
      openAndNetworkFee
    );
  }, [currentPriceValue, fromToken.decimals, openAndNetworkFee, selectedTrade]);

  const netPnLPecent = useMemo(() => {
    if (!selectedTrade || !netPnL) return;

    const positionSizeUsdcValue = formatUnits(
      selectedTrade.positionSizeUsdc,
      fromToken.decimals
    );
    const netPnLPercent = getNetPnLPercent(netPnL, positionSizeUsdcValue);

    return netPnLPercent;
  }, [fromToken.decimals, netPnL, selectedTrade]);

  const handleTradeShare = useCallback(async () => {
    if (imageRef) {
      const dataUrl = await htmlToImage.toPng(imageRef.current as HTMLElement);
      const blob = await fetch(dataUrl).then((res) => res.blob());

      try {
        await navigator.clipboard.write([
          new ClipboardItem({
            "image/png": blob,
          }),
        ]);

        setTimeout(() => {
          // const toastId = `ShareTrade-${new Date().getTime()}`;
          // console.log(toastId);
          helperToast.success(
            <div className="toastify-body-only">
              <Trans>Trade Image Copied</Trans>
            </div>,
            {
              // toastId,
              autoClose: 2500,
            }
          );
          setIsVisible(false);
        }, 500);
      } catch (error) {
        // console.error(error);
      }
    }
  }, [setIsVisible]);

  useEffect(() => {
    if (isVisible) {
      handleTradeShare();
    }
  }, [handleTradeShare, isVisible]);

  return (
    <ModalSkeletonWithPortal
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      shareTrade
    >
      {/* {ShareImageHtml()} */}

      {selectedTrade && (
        <div
          ref={imageRef}
          className="bg-[url('img/bg-trade_share.jpg')] bg-cover pt-[2.3rem] pb-[2rem] sm+:pt-[5.5rem] sm+:pb-[4.8rem] px-[2.5rem] sm+:px-[7rem] text-center rounded-[1.2rem] m-[-2px]"
        >
          <div className="flex justify-center">
            <img
              className="w-[4rem] h-[4rem] mr-[0.64rem]"
              src={GambitLogoImage}
              alt="GAMBIT Logo"
            />
            <span className="text-[2.88rem] text-brand-1 font-bold">
              GAMBIT
            </span>
          </div>

          <div className="flex items-center justify-center mt-[3.5rem] mb-[1.25rem]">
            <span className="text-[1.5rem] font-bold mr-[1rem] sm+:text-[2.4rem] sm+:mr-[1.6rem]">
              {selectedTrade.pair.from}/{selectedTrade.pair.to}
            </span>

            <LongShortLable buy={selectedTrade.buy} />
          </div>

          <div className="font-space-grotesk text-[4.5rem] sm+:text-[7.2rem] font-bold">
            {netPnLPecent && +netPnLPecent >= 0
              ? `+${netPnLPecent}`
              : netPnLPecent}
            %
          </div>

          <div className="flex justify-between gap-[3rem] sm+:gap-[7.2rem] mt-[2.6rem] mb-[2.5rem] sm+:mt-[4.3rem] sm+:mb-[4.1rem]">
            <div className="flex flex-col">
              <span className="text-[1.3rem] sm+:text-[2rem] text-gray-20 font-medium">
                <Trans>Entry Price:</Trans>
              </span>
              <span className="font-space-grotesk text-[2rem] sm+:text-[3.2rem] font-bold">
                {comma1000(trimPriceBN(selectedTrade.openPrice))}
              </span>
            </div>
            <div className="flex flex-col">
              <span className="text-[1.3rem] sm+:text-[2rem] text-gray-20 font-medium">
                <Trans>Leverage:</Trans>
              </span>
              <span className="font-space-grotesk text-[2rem] sm+:text-[3.2rem] font-bold">
                {selectedTrade.leverage.toFixed(2)}x
              </span>
            </div>
            <div className="flex flex-col">
              <span className="text-[1.3rem] sm+:text-[2rem] text-gray-20 font-medium">
                <Trans>Last Price:</Trans>
              </span>
              <span className="font-space-grotesk text-[2rem] sm+:text-[3.2rem] font-bold">
                {comma1000(trimPriceString(currentPriceValue))}
              </span>
            </div>
          </div>

          <div className="text-[1.2rem] sm+:text-[1.6rem] text-gray-30 font-bold">
            <Trans>
              Trade Now at <span className="text-brand-1">Gambit</span>
            </Trans>
          </div>
        </div>
      )}
    </ModalSkeletonWithPortal>
  );
};
