import { accountVaultAtom } from "atoms/vault";
import { getAbi, getContract } from "config/contracts";
import { useChainId } from "futures-lib/chains";
import { gambitContractFetcherForAccountVault } from "futures-lib/contracts/gambitContractFetcher";
import useWallet from "futures-lib/wallets/useWallet";
import { useSetAtom } from "jotai";
import { useEffect } from "react";
import useSWR from "swr";
// import { formatUnits } from "@ethersproject/units";
// import { formatUnits } from "@ethersproject/units";

/**
 * Vault에서 현재 연결된 지갑과 관련된 정보(shares 개수 등)를 가져온다.
 */
export const useAccountVault = (currentEpoch: number) => {
  const { isActive, account, signer } = useWallet();
  const { chainId } = useChainId();
  const vaultAddress = getContract(chainId, "Vault");
  const setAccountVault = useSetAtom(accountVaultAtom);

  const { data, isLoading, error } = useSWR(
    !!currentEpoch && isActive && [isActive, chainId, vaultAddress, "", account],
    {
      fetcher: gambitContractFetcherForAccountVault(signer, getAbi(chainId, "Vault"), [currentEpoch]),
      refreshInterval: 2000,
      errorRetryInterval: 500,
      errorRetryCount: 1000,
    }
  );

  // useEffect(() => {
  //   setAccountVault({} as AccountVault);
  // }, [chainId, setAccountVault]);

  useEffect(() => {
    if (!error && data && !isLoading) {
      const [available, decimals, totalSharesBeingWithdrawn, maxRedeem] = data;

      setAccountVault({
        available,
        decimals,
        totalSharesBeingWithdrawn,
        maxRedeem,
      });
    }
  }, [data, isLoading, error, setAccountVault, chainId]);
};
