import { t } from "@lingui/macro";
import {
  ARBITRUM,
  ARBITRUM_GOERLI,
  ARBITRUM_SEPOLIA,
  HARDHAT,
  WEMIX_TESTNET,
  ZKSYNC,
  ZKSYNC_GOERLI,
  ZKSYNC_SEPOLIA,
} from "config/chains";
import { APP_MODES } from "./../../futures-domain/types";

export enum ErrorCode {
  InsufficientLiquiditySwap = "INSUFFICIENT_LIQUIDITY_SWAP",
  InsufficientLiquidityLeverage = "INSUFFICIENT_LIQUIDITY_LEVERAGE",
  InsufficientCollateralIn = "INSUFFICIENT_COLLATERAL_IN",
  InsufficientProfitLiquidity = "INSUFFICIENT_PROFIT_LIQUIDITY",
  InsufficientReceiveToken = "INSUFFICIENT_RECEIVE_TOKEN",
  ReceiveCollateralTokenOnly = "RECEIVE_CollateralToken_ONLY",
  TokenPoolExceeded = "TOKEN_POOL_EXCEEDED",
  TokenPoolExceededShorts = "TOKEN_POOL_EXCEEDED_SHORTS",
  Buffer = "BUFFER",
  MaxUSDG = "MAX_USDG",
  InvalidLiqPrice = "INVALID_LIQ_PRICE",
}

export enum ErrorDisplayType {
  Modal = "MODAL",
  Tooltip = "TOOLTIP",
}

// export const DEFAULT_PAIR_INFO = {
//   from: "BTC",
//   to: "USD",
//   pairIndex: 0,
//   priceFeedId: "0xe62df6c8b4a85fe1a67db44dc12de5db330f7ac66b72dc658afedf0f4a415b43",
// };

// export const DEFAULT_PAIRS: Pair[] = [
//   {
//     groupIndex: 0,
//     pairIndex: 0,
//     from: "BTC",
//     to: "USD",
//     priceFeedId: "0xe62df6c8b4a85fe1a67db44dc12de5db330f7ac66b72dc658afedf0f4a415b43",
//     tvSymbol: "PYTH:BTCUSD",
//     fee: {
//       openFeeP: "0.02",
//       closeFeeP: "0.02",
//       minLevPosUsdc: "1500",
//     },
//     group: {
//       minLeverage: "2",
//       maxLeverage: "200",
//     },
//     confMultiplierP: 1.2,
//     // spreadP: "400000000",
//     // groupIndex: "0",
//     // feeIndex: "0",
//   },
//   {
//     groupIndex: 0,
//     pairIndex: 1,
//     from: "ETH",
//     to: "USD",
//     priceFeedId: "0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace",
//     tvSymbol: "PYTH:ETHUSD",
//     fee: {
//       openFeeP: "0.02",
//       closeFeeP: "0.02",
//       minLevPosUsdc: "1500",
//     },
//     group: {
//       minLeverage: "2",
//       maxLeverage: "200",
//     },
//     confMultiplierP: 1.2,
//     // spreadP: "400000000",
//     // groupIndex: "0",
//     // feeIndex: "0",
//   },
// ];

export const SL_OPTIONS = [
  {
    text: t`NONE`,
    value: "",
  },
  {
    text: "-10%",
    value: "-10",
  },
  {
    text: "-25%",
    value: "-25",
  },
  {
    text: "-50%",
    value: "-50",
  },
  {
    text: "-75%",
    value: "-75",
  },
];

export const TP_OPTIONS = [
  {
    text: "25%",
    value: "25",
  },
  {
    text: "50%",
    value: "50",
  },
  {
    text: "100%",
    value: "100",
  },
  {
    text: "300%",
    value: "300",
  },
  {
    text: "900%",
    value: "900",
  },
];

// TODO: 온체인에서 읽기?
// constant value in Trading Contract
export const MAX_SL_P = 75;
export const MAX_GAIN_P = 900;

export const GAMBIT_USD_DECIMALS = 10;

export const SUBGRAPH_TIMEOUTS = 5000;

// TODO: 온체인에서 읽기?
export const MAX_TRADES_PER_PAIR = 3;
export const MAX_PENDING_MARKET_ORDERS = 5;
export const MIN_POS_SIZE = 1500;

export const TOASTIFY_ID = {
  WALLET_NOT_CONNECTED: "WALLET_NOT_CONNECTED",
  WALLET_CONNECTED: "WALLET_CONNECTED",
};

// export const CONFIDENCE_MULTIPLIER = 1.4;
// export const CONFIDENCE_MULTIPLIER_NUMERATOR = 14;
// export const CONFIDENCE_MULTIPLIER_DENOMINATOR = 10;

/**
 * groupId
 * 0: crypto
 * 1: forex
 */
// * subgraph 에서 읽는 것으로 변경
// export const CONFIDENCE_MULTIPLIERS = {
//   0: {
//     value: 1.2,
//     valueNumerator: 12,
//     valueDenominator: 10,
//   },
//   1: {
//     value: 1.4,
//     valueNumerator: 14,
//     valueDenominator: 10,
//   },
// };

export const confMultiplierDecimals = 12;

// TODO: 제거하고 subgraph에서 가져오는 값으로 만들어 사용
// export const LEVERAGE_MARKS = {
//   0: {
//     2: "2x",
//     20: "20x",
//     40: "40x",
//     60: "60x",
//     80: "80x",
//     100: "100x",
//   },
//   1: {
//     10: "10x",
//     200: "200x",
//     400: "400x",
//     600: "600x",
//     800: "800x",
//     1000: "1000x",
//   },
// };

// TODO: 제거하고 subgraph에서 가져오는 값 사용
// export const LEVERAGE = {
//   // 0: {
//   //   min: 2,
//   //   max: 200,
//   // },
//   0: {
//     min: 2,
//     max: 100,
//   },
//   1: {
//     min: 10,
//     max: 1000,
//   },
// };

// export const getLeverageMarks = (groupIndex: number) => {
//   if (!LEVERAGE_MARKS[groupIndex]) {
//     throw new Error(`Invalid Group Index`);
//   }

//   return LEVERAGE_MARKS[groupIndex];
// };

export const makeLeverageMarks = (min: number, max: number) => {
  const step = max / 5;
  return {
    [min]: `${min}x`,
    [step * 1]: `${step * 1}x`,
    [step * 2]: `${step * 2}x`,
    [step * 3]: `${step * 3}x`,
    [step * 4]: `${step * 4}x`,
    [max]: `${max}x`,
  };
};

// console.log(makeLeverageMarks(2, 100));
// console.log(makeLeverageMarks(10, 1000));

export const PRICE_DECIMAL_STANDARD = 10;

// TODO: 온체인?
// export const MIN_POS_SIZES = {
//   0: 1_500, // 1500000000
//   1: 10_000, // 10000000000
// };

export const MAX_SLIPPAGE = 10;

export const MAX_POSITIONS = 3;

export interface TradingCompetitionRanker {
  address: string;
  tv: number;
  pnl: number;
  pnlPercent: number;
  sumPnlPercent: number;
  tradeCount: number;
  avgLeverage: number;
  tvRanking: number;
  pnlRanking: number;
  avgPnlPercent: number;
  maxRewardTv: number;
  reward: number;
  maxReward: number;
}

export const colorPalettes = [
  "paletteBrand",
  "paletteGreen",
  "paletteBlue",
  "paletteViolet",
];

export const END_TRADING_COMPETITION = "2024/1/5 23:59:59 UTC"; // UTC 원하면 "2023-07-31 24:00 UTC"

// * NOTE: '23.10.11) Contract에 물타기 기능이 추가되면서 레버리지, gUsdcPrice의 decimal이 +18 되었음
export const ADDED_DECIMALS = 18;

export const TRADING_EVENT_GIT_BOOK_URL =
  "https://event.gambit.trade/v/gambit-trading-competition";

// * pyth API의 publish_time과 현재 시간이 10초 이상 차이나면 CLOSED 판단
export const FOREX_CLOSED_DECISION_TIME = 10;

export const getAPIUrl = (chainId: number) => {
  switch (chainId) {
    case HARDHAT:
      return "http://localhost:3000";
    case ARBITRUM_SEPOLIA:
      return "https://stage-api.gambit.trade";
    case ZKSYNC_SEPOLIA:
      return "https://stage-api.gambit.trade:8443";
    case ARBITRUM:
      return "https://api.gambit.trade:443";
    case ZKSYNC:
      return "https://api.gambit.trade:4443";
    default:
      return;
  }
};

// * return seconds(not ms)
export const getTradeDeadline = (chainId: number) => {
  return (
    new Date().getTime() / 1000 +
    getTradeDeadlineBuffer(chainId)
  ).toFixed(0); // * 1000으로 나누는 이유 -> ms -> s 변환
};

export const getTradeDeadlineBuffer = (chainId: number) => {
  switch (chainId) {
    case ARBITRUM:
      return 60; // 60s

    case ZKSYNC:
      return 60; // 60s

    case ZKSYNC_GOERLI:
      return 60; // 60s

    case ZKSYNC_SEPOLIA:
      return 60; // 60s

    case ARBITRUM_GOERLI:
      return 60; // 60s

    case ARBITRUM_SEPOLIA:
      return 60; // 60s

    case WEMIX_TESTNET:
      return 60; // 60s

    case HARDHAT:
      return 60 * 10; // 10min

    default:
      return 60; // 60s
  }
};

export const getOrderDeadline = (chainId: number) => {
  return (
    new Date().getTime() / 1000 +
    getOrderDeadlineBuffer(chainId)
  ).toFixed(0); // * 1000으로 나누는 이유 -> ms -> s 변환
};

export const getOrderDeadlineBuffer = (chainId: number) => {
  switch (chainId) {
    case ARBITRUM:
      return 60 * 60 * 24 * 3; // 3days

    case ZKSYNC:
      return 60 * 60 * 24 * 3; // 3days

    case ZKSYNC_GOERLI:
      return 60 * 60 * 24 * 3; // 3days

    case ARBITRUM_GOERLI:
      return 60 * 60 * 24 * 3; // 3days

    case WEMIX_TESTNET:
      return 60 * 60 * 24 * 3; // 3days

    case HARDHAT:
      return 60 * 60 * 24 * 3; // 3days

    default:
      return 60 * 60 * 24 * 3; // 3days
  }
};

export const PRODUCTION_OR_STAGING_ENV =
  process.env.REACT_APP_MODE === APP_MODES.PRODUCTION ||
  process.env.REACT_APP_MODE === APP_MODES.STAGING;
