import { timezoneOffset } from "futures-domain/prices";
import { CHART_PERIODS } from "futures-lib/legacy";
import { Bar } from "./types";

export function getObjectKeyFromValue(value, object) {
  return Object.keys(object).find((key) => object[key] === value);
}

export function formatTimeInBarToMs(bar: Bar) {
  return {
    ...bar,
    time: bar.time * 1000,
  };
}

export function getCurrentCandleTime(period: string) {
  // Converts current time to seconds, rounds down to nearest period, adds timezone offset, and converts back to milliseconds
  const periodSeconds = CHART_PERIODS[period];
  return Math.floor(Date.now() / 1000 / periodSeconds) * periodSeconds + timezoneOffset;
}

export function getNextCandleTimeAmount(curTime: number, period: string) {
  const periodMilliseconds = CHART_PERIODS[period] * 1000;
  return Math.floor(curTime) * periodMilliseconds;
}
