import * as Dialog from "@radix-ui/react-dialog";
import { Web3AuthConnector } from "@web3auth/web3auth-wagmi-connector";
import { showEmailAccountModalAtom } from "atoms";
import cx from "classnames";
import "components/Common/radix-ui-dialog.scss";
import SignInModalLayout from "components/SocialLogin/SignInModalLayout";
import useWeb3AuthWallet from "futures-lib/wallets/gambit-web3-auth/useWeb3AuthWallet";
import { useAtom } from "jotai";
import { useCallback, useEffect, useState } from "react";
import { Connector, useConfig, useConnect } from "wagmi";

const EmailWalletModal = () => {
  const [showEmailAccountModal, setShowEmailAccountModal] = useAtom(showEmailAccountModalAtom);
  const { connect } = useConnect();
  const { emailWeb3authConnector } = useWeb3AuthWallet();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const config = useConfig();
  const { connectors } = useConnect();

  const _handleClickContinue = useCallback(
    (email: string) => {
      if (config && connectors && emailWeb3authConnector) {
        const _connectors = connectors.filter((connector) => {
          if (connector.options.loginParams) {
            // * email_passwordless 아닌 소셜 로그인(구글, 트위터 등)은 포함시켜서 반환
            return connector.options.loginParams.loginProvider !== "email_passwordless";
          } else {
            // * external wallet은 포함시켜서 반환
            return true;
          }
        });

        const _emailWeb3authConnector = new Web3AuthConnector({
          chains: emailWeb3authConnector.chains,
          options: {
            web3AuthInstance: (emailWeb3authConnector as any).web3AuthInstance,
            loginParams: {
              loginProvider: "email_passwordless",
              extraLoginOptions: {
                login_hint: email, // email to send the OTP to
              },
            },
          },
        }) as Connector<any, any>;

        config.setConnectors([..._connectors, _emailWeb3authConnector]);
        connect({ connector: _emailWeb3authConnector });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [config, connectors, emailWeb3authConnector]
  );

  const handleClickContinue = useCallback(
    (email: string) => {
      const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

      if (email.match(validRegex)) {
        setLoading(true);
        _handleClickContinue(email);
      } else {
        alert("Please enter a valid email");
      }
    },
    [_handleClickContinue]
  );

  // useEffect(() => {
  //   console.log(continueClicked, config, connectors, emailWeb3authConnector);
  //   if (continueClicked && config && connectors && emailWeb3authConnector) {
  //     console.log(1);
  //     const _connectors = connectors.filter((connector) => {
  //       if (connector.options.loginParams) {
  //         console.log(2);
  //         // * email_passwordless 아닌 소셜 로그인(구글, 트위터 등)은 포함시켜서 반환
  //         return connector.options.loginParams.loginProvider !== "email_passwordless";
  //       } else {
  //         console.log(3);
  //         // * external wallet은 포함시켜서 반환
  //         return true;
  //       }
  //     });

  //     const _emailWeb3authConnector = new Web3AuthConnector({
  //       chains: emailWeb3authConnector.chains,
  //       options: {
  //         web3AuthInstance: (emailWeb3authConnector as any).web3AuthInstance,
  //         loginParams: {
  //           loginProvider: "email_passwordless",
  //           extraLoginOptions: {
  //             login_hint: email, // email to send the OTP to
  //           },
  //         },
  //       },
  //     }) as Connector<any, any>;

  //     config.setConnectors([..._connectors, _emailWeb3authConnector]);
  //     connect({ connector: emailWeb3authConnector });
  //     console.log("email connector setup");
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [config, connectors, continueClicked, emailWeb3authConnector]);

  useEffect(() => {
    if (!showEmailAccountModal) {
      // TODO: 입력 값 리셋
    }
  }, [showEmailAccountModal]);

  useEffect(() => {
    if (emailWeb3authConnector?.options.loginParams?.extraLoginOptions?.login_hint) {
      connect({ connector: emailWeb3authConnector });
    }
  }, [connect, emailWeb3authConnector]);

  return (
    <Dialog.Root open={showEmailAccountModal} onOpenChange={setShowEmailAccountModal}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <SignInModalLayout>
          <Dialog.Content
            className={cx(
              "font-space-grotesk",
              "w-full bottom-0",
              "z-[99] fixed focus-visible:outline-none",
              "xxs+:w-[39.8rem] xxs+:!top-[6.8rem] xxs+:!bottom-auto xxs+:bg-gray-90",
              "xs-:!right-0",
              "mx-0 xs:mx-[1.5rem]",
              "xxs+:rounded-[1rem]"
            )}
          >
            <div className="flex w-full pt-[1.6rem] pr-0 pb-[2.4rem] pl-0 flex-col gap-[1.2rem] items-center bg-gray-70 xxs+:bg-[rgba(23,27,38,0.95)] xxs+:rounded-[1.2rem] rounded-t-[1.2rem] border-solid xxs+:border border-[rgba(255,255,255,0.1)] mx-auto my-0">
              <div className="flex w-full justify-between items-center px-[2rem]">
                <span className="h-[2.6rem] xxs+:text-[2rem] text-[1.7rem] font-medium leading-[25.52px] text-white text-left">
                  Enter Email to Continue
                </span>
                <div
                  onClick={() => {
                    setShowEmailAccountModal(false);
                  }}
                  className="cursor-pointer w-[2.4rem] h-[2.4rem] bg-[url(img/ic-modal-close.png)] bg-cover overflow-hidden"
                />
              </div>
              <div className="flex w-full flex-col gap-[1.2rem] items-center px-[2rem]">
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      handleClickContinue(email);
                    }
                  }}
                  className="h-[5.2rem] bg-black-2 w-full rounded-[1.2rem] text-[1.5rem] xxs+:text-[1.7rem] p-[1.6rem] placeholder-gray-40"
                  placeholder="Enter Your Email"
                />
                <button
                  disabled={loading}
                  onClick={() => {
                    handleClickContinue(email);
                  }}
                  className="xxs+:bg-gray-80 bg-gray-50 h-[5.2rem] w-full text-[1.6rem] rounded-[1.2rem]"
                >
                  Continue
                </button>
              </div>
            </div>
          </Dialog.Content>
        </SignInModalLayout>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default EmailWalletModal;
