import { formatUnits } from "@ethersproject/units";
import { Trans, t } from "@lingui/macro";
import {
  isVisibleDetailTradeModalAtom,
  isVisibleShareTradeModalAtom,
  pairsPricesAtom,
  selectedTradeAtom,
} from "atoms/exchange";
import BN from "bignumber.js";
import cx from "classnames";
import { GAMBIT_USD_DECIMALS } from "components/Exchange/constants";
import ModalSkeletonWithPortal from "components/Modal/ModalSkeletonWithPortal";
import { getContract } from "config/contracts";
import { getToken } from "config/tokens";
import { BigNumber } from "ethers";
import {
  comma1000,
  getClosingFee,
  getNetPnL,
  getPnL,
  getPriceChange,
  trimPriceBN,
  trimPriceString,
  unpadZero,
} from "futures-domain/trades/utils";
import { useChainId } from "futures-lib/chains";
import { formatAmount, numberWithCommas } from "futures-lib/numbers";
import { ReactComponent as InfoCircleSvg } from "img/ic-info-circle.svg";
import { ReactComponent as PresentSvg } from "img/ic-present.svg";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useMemo } from "react";
import ReactDOMServer from "react-dom/server";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

export const DetailTradeModal = () => {
  const { chainId } = useChainId();
  const [isVisible, setIsVisible] = useAtom(isVisibleDetailTradeModalAtom);
  const selectedTrade = useAtomValue(selectedTradeAtom);
  const fromTokenAddress = getContract(chainId, "CollateralToken");
  const fromToken = getToken(chainId, fromTokenAddress);
  const setIsVisibleShareModal = useSetAtom(isVisibleShareTradeModalAtom);
  const pairsPrices = useAtomValue(pairsPricesAtom);

  const longOrShort = useMemo(() => {
    if (isVisible) {
      return selectedTrade?.buy ? t`LONG` : t`SHORT`;
    } else {
      return "None";
    }
  }, [isVisible, selectedTrade?.buy]);

  // human readable
  const currentPriceValue = useMemo(() => {
    // console.log(pairsPrices[selectedTrade?.pair.priceFeedId!]);
    if (
      pairsPrices[selectedTrade?.pair.priceFeedId!]?.price &&
      pairsPrices[selectedTrade?.pair.priceFeedId!]?.expo
    ) {
      const { price, expo } = pairsPrices[selectedTrade?.pair.priceFeedId!];
      return formatUnits(BigNumber.from(price), Math.abs(expo));
    }

    return null;
  }, [pairsPrices, selectedTrade?.pair.priceFeedId]);

  const priceChange = useMemo(() => {
    if (selectedTrade && currentPriceValue) {
      const openPriceValue = formatUnits(
        selectedTrade.openPrice,
        GAMBIT_USD_DECIMALS
      );
      return getPriceChange(currentPriceValue, openPriceValue);
    }
  }, [currentPriceValue, selectedTrade]);

  const positionSize = useMemo(() => {
    if (!selectedTrade) return;

    // return selectedTrade?.positionSizeUsdc.mul(selectedTrade?.leverage);

    const result = BigNumber.from(
      new BN(selectedTrade.positionSizeUsdc.toString())
        .multipliedBy(selectedTrade.leverage)
        .toFixed(0)
    );

    return result;
  }, [selectedTrade]);

  const pay = useMemo(() => {
    if (selectedTrade?.initialPosUsdc.gt(0)) {
      return formatAmount(
        selectedTrade.initialPosUsdc,
        fromToken.decimals,
        2,
        true
      );
    }

    return;
  }, [fromToken.decimals, selectedTrade?.initialPosUsdc]);

  const openAndNetworkFee = useMemo(() => {
    if (selectedTrade?.initialPosUsdc.gt(0)) {
      try {
        return formatAmount(
          new BN(selectedTrade.initialPosUsdc.toString())
            .minus(selectedTrade.positionSizeUsdc.toString())
            .toString(),
          fromToken.decimals,
          2,
          false
        );
      } catch (e) {
        if (selectedTrade?.initialPosUsdc.gt(0)) {
          const initialPosUsdcString = formatAmount(
            selectedTrade.initialPosUsdc,
            fromToken.decimals,
            6,
            false
          );
          const positionSizeUsdcString = formatAmount(
            selectedTrade.positionSizeUsdc,
            fromToken.decimals,
            6,
            false
          );

          return new BN(initialPosUsdcString)
            .minus(positionSizeUsdcString)
            .toFixed(2);
        }
      }
    }
    return;
  }, [
    fromToken.decimals,
    selectedTrade?.initialPosUsdc,
    selectedTrade?.positionSizeUsdc,
  ]);

  // usdc 개수 === $
  const closingFee = useMemo(() => {
    if (!selectedTrade || !positionSize) return;
    // console.log(formatAmount(result, fromToken.decimals, 2, true));
    // console.log(formatAmount(temp, fromToken.decimals, 2, true));

    return getClosingFee(+selectedTrade?.pair.fee.closeFeeP, positionSize);
  }, [positionSize, selectedTrade]);

  const pnl = useMemo(() => {
    if (selectedTrade && priceChange) {
      const result = getPnL(selectedTrade, priceChange, fromToken.decimals);
      return result ? +result : null;
    }

    return null;
  }, [fromToken.decimals, priceChange, selectedTrade]);

  const netPnL = useMemo(() => {
    if (selectedTrade && priceChange) {
      const result = getNetPnL(
        selectedTrade,
        priceChange,
        fromToken.decimals,
        openAndNetworkFee
      );
      return result ? result : "";
    }

    return "";
  }, [fromToken.decimals, openAndNetworkFee, priceChange, selectedTrade]);

  const closeAndFundingFee = useMemo(() => {
    if (selectedTrade && closingFee) {
      try {
        return formatAmount(
          new BN(closingFee.toString())
            .plus(selectedTrade.fundingFee?.toString() || 0)
            .toString(),
          fromToken.decimals,
          2,
          false
        );
      } catch (e) {
        const closeFee = formatAmount(closingFee, fromToken.decimals, 2, true);
        const fundingFee = formatAmount(
          selectedTrade.fundingFee,
          fromToken.decimals,
          2,
          true
        );

        return new BN(closeFee).plus(fundingFee).toFixed(2);
      }
    }
    return "";
  }, [closingFee, fromToken.decimals, selectedTrade]);

  return (
    <ModalSkeletonWithPortal isVisible={isVisible} setIsVisible={setIsVisible}>
      {selectedTrade && (
        <div className="min-w-[30rem]">
          {/* Title */}
          <div className="text-center mt-[3.8rem]">
            <div className="font-space-grotesk text-[2.4rem] font-bold">
              {selectedTrade.pair.from}/{selectedTrade.pair.to}
            </div>
            <div
              className={cx(
                "text-[1.6rem] text-gray-15 font-medium",
                longOrShort === t`LONG` ? "text-green-2" : "text-red-2"
              )}
            >
              {longOrShort}
            </div>
          </div>

          <hr className="h-px mt-[1.25rem] mb-[2.25rem] bg-gray-70 border-0" />

          {/* Content */}
          <ul className="px-[2.4rem] text-[1.4rem] space-y-[1.6rem] overflow-y-auto">
            {pay != null && (
              <li className="flex justify-between">
                <span className="text-gray-15">Pay</span>
                <span className="font-space-grotesk font-medium">
                  {pay} {fromToken.symbol}
                </span>
              </li>
            )}
            {openAndNetworkFee != null && (
              <li className="flex justify-between">
                <span className="text-gray-15">
                  Open Fee + Network Fee
                  {/* <Trans>Opening Fee</Trans> */}
                </span>
                <span
                  className={cx("font-space-grotesk font-medium text-nowrap", {
                    "text-red-2": new BN(openAndNetworkFee).gt(0),
                  })}
                >
                  {new BN(openAndNetworkFee).eq(0) ? (
                    <div className="flex items-center">
                      <PresentSvg className="present-svg mr-[1.5px]" />
                      <span className="text-brand-1 animate-pulse">FREE</span>
                    </div>
                  ) : (
                    `${openAndNetworkFee} ${fromToken.symbol}`
                  )}
                </span>
              </li>
            )}
            <li className="flex justify-between">
              <span className="text-gray-15">
                <Trans>Collateral</Trans>
              </span>
              <span className="font-space-grotesk font-medium">
                {formatAmount(
                  selectedTrade.positionSizeUsdc,
                  fromToken.decimals,
                  2,
                  true
                )}{" "}
                {fromToken.symbol}
              </span>
            </li>
            <li className="flex justify-between">
              <span className="text-gray-15">
                <Trans>Leverage</Trans>
              </span>
              <span className="font-space-grotesk font-medium">
                {unpadZero(selectedTrade.leverage.toFixed(2))}x
              </span>
            </li>
            <li className="flex justify-between">
              <span className="text-gray-15">
                <Trans>Position Size</Trans>
              </span>
              <span className="font-space-grotesk font-medium">
                {formatAmount(positionSize, fromToken.decimals, 2, true)}{" "}
                {fromToken.symbol}
              </span>
            </li>
            <li className="flex justify-between">
              <span className="text-gray-15">
                <Trans>Open Price</Trans>
              </span>
              <span className="font-space-grotesk font-medium">
                ${comma1000(trimPriceBN(selectedTrade.openPrice))}
              </span>
            </li>
            <li className="flex justify-between">
              <span className="text-gray-15">
                <Trans>Current Price</Trans>
              </span>
              {currentPriceValue != null && (
                <span className="font-space-grotesk font-medium flex items-center">
                  ${/* <AnimatedNumber to={+currentPriceValue} /> */}
                  {numberWithCommas(trimPriceString(currentPriceValue))}
                </span>
              )}
            </li>
            <li className="flex justify-between">
              <span className="text-gray-15">
                <Trans>Price Change</Trans>
              </span>
              {priceChange && (
                <span className="font-space-grotesk font-medium flex items-center">
                  {/* <AnimatedNumber to={+priceChange} decimals={2} />% */}
                  {new BN(priceChange).gt(0) ? "+" : ""}
                  {numberWithCommas((+priceChange).toFixed(2))}%
                </span>
              )}
            </li>
            {/* {!TESTNETS.includes(chainId) && (
              <li className="flex justify-between">
                <span className="text-gray-15">
                  <Trans>Rollover Fees</Trans>
                </span>
                <span className="font-space-grotesk font-medium">
                  {formatAmount(
                    selectedTrade.rolloverFee,
                    fromToken.decimals,
                    2,
                    true
                  )}{" "}
                  {fromToken.symbol}
                </span>
              </li>
            )} */}
            <li className="flex justify-between">
              <span className="text-gray-15">
                <Trans>Liquidation Price</Trans>
              </span>
              <span className="font-space-grotesk font-medium">
                {selectedTrade.liqPrice
                  ? `$${numberWithCommas(trimPriceBN(selectedTrade.liqPrice))}`
                  : ""}
              </span>
            </li>
            <li className="flex justify-between">
              <span className={cx("text-gray-15")}>Profit</span>
              <span
                className={cx(
                  "font-space-grotesk font-medium",
                  pnl != null && pnl >= 0 ? "text-green-2" : "text-red-2"
                )}
              >
                {priceChange && pnl != null && (
                  <div className="flex items-center">
                    {/* <AnimatedNumber to={pnl} decimals={2} /> */}
                    <span>{new BN(pnl).gt(0) ? "+" : ""}</span>
                    {numberWithCommas((+pnl).toFixed(2))}
                    <span className="ml-[3.5px]">{fromToken.symbol}</span>
                  </div>
                )}
              </span>
            </li>

            {/* <li className="flex justify-between">
              <span className="text-gray-15">
                <Trans>Funding Fees</Trans>
              </span>
              <span className="font-space-grotesk font-medium">
                {formatAmount(
                  selectedTrade.fundingFee,
                  fromToken.decimals,
                  2,
                  true
                )}{" "}
                {fromToken.symbol}
              </span>
            </li>
            <li className="flex justify-between">
              <span className="text-gray-15">
                <Trans>Close Fee</Trans>
              </span>
              <span className="font-space-grotesk font-medium">
                {formatAmount(closingFee, fromToken.decimals, 2, true)}{" "}
                {fromToken.symbol}
              </span>
            </li> */}
            <li className="flex justify-between">
              <div
                data-tooltip-id="tooltip-closeAndFundingFee"
                data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                  <div>
                    <div className="text-[1.3rem] font-bold">
                      Close Fee:{" "}
                      {formatAmount(closingFee, fromToken.decimals, 2, true)}
                    </div>
                    <div className="text-[1.3rem] font-bold">
                      Funding Fee:{" "}
                      {formatAmount(
                        selectedTrade.fundingFee,
                        fromToken.decimals,
                        2,
                        true
                      )}
                    </div>
                  </div>
                )}
                className="flex items-center"
              >
                <span className="text-gray-15">Close Fee + Funding Fee</span>
                <span>
                  <InfoCircleSvg className="fill-gray-30 ml-[2px]" />
                </span>
                <ReactTooltip
                  className="!bg-gray-70 !bg-opacity-95 !font-space-grotesk !rounded-[6px] !text-white !border !border-white !border-opacity-10"
                  id="tooltip-closeAndFundingFee"
                  noArrow={true}
                  place="top"
                />
              </div>
              {closeAndFundingFee != null && (
                <span
                  className={cx(
                    "font-space-grotesk font-medium text-nowrap",
                    new BN(closeAndFundingFee).gt(0)
                      ? `text-red-2`
                      : `text-green-2`
                  )}
                >
                  {closeAndFundingFee} {fromToken.symbol}
                </span>
              )}
            </li>
            <div className="h-[1px] w-full bg-gray-70" />
            <li className="flex justify-between font-bold">
              <span className={cx("text-gray-15")}>Net PnL</span>
              <span
                className={cx(
                  "font-space-grotesk",
                  netPnL != null && +netPnL >= 0 ? "text-green-2" : "text-red-2"
                )}
              >
                {priceChange && netPnL != null && (
                  <div className="flex items-center">
                    {/* <AnimatedNumber to={pnl} decimals={2} /> */}
                    <span>{new BN(netPnL).gt(0) ? "+" : ""}</span>
                    {numberWithCommas((+netPnL).toFixed(2))}
                    <span className="ml-[3.5px]">{fromToken.symbol}</span>
                  </div>
                )}
              </span>
            </li>
          </ul>

          <button
            onClick={() => setIsVisibleShareModal(true)}
            className="block mt-[2.2rem] mb-[2.4rem] mx-auto py-[1.2rem] px-[3.2rem] rounded-[6px] bg-brand-1 text-[1.7rem] text-gray-70 font-bold"
          >
            <Trans>Share Trade</Trans>
          </button>
        </div>
      )}
    </ModalSkeletonWithPortal>
  );
};
