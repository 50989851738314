import { Provider } from "@ethersproject/providers";
import { Contract } from "ethers";
import { GAS_PRICE_ADJUSTMENT_MAP, MAX_GAS_PRICE_MAP, WEMIX_TESTNET } from "config/chains";
import { bigNumberify } from "../numbers";
import { parseUnits } from "ethers/lib/utils";
import { TxOptions } from "./callContract";

export async function setGasPrice(txnOpts: TxOptions, provider: Provider, chainId: number) {
  let maxGasPrice = MAX_GAS_PRICE_MAP[chainId];
  const premium = GAS_PRICE_ADJUSTMENT_MAP[chainId] || bigNumberify(0);

  const gasPrice = await provider.getGasPrice();
  // const feeData = await provider.getFeeData();

  if (maxGasPrice) {
    if (gasPrice.gt(maxGasPrice)) {
      maxGasPrice = gasPrice;
    }

    const feeData = await provider.getFeeData();

    // the wallet provider might not return maxPriorityFeePerGas in feeData
    // in which case we should fallback to the usual getGasPrice flow handled below
    if ([WEMIX_TESTNET].includes(chainId)) {
      txnOpts.maxPriorityFeePerGas = parseUnits("100", "gwei");
      txnOpts.maxFeePerGas = parseUnits("101", "gwei");
      return;
    }
    if (feeData && feeData.maxPriorityFeePerGas) {
      txnOpts.maxFeePerGas = maxGasPrice;
      txnOpts.maxPriorityFeePerGas = feeData.maxPriorityFeePerGas.add(premium);
      return;
    }
  }

  // * ('23.12.5) goerli에서 max fee per gas less than block base fee 에러 발생 피드백 받음 (from josh)
  // * 다른 네트워크에 적용은 필요 없는 지 검토 필요
  // if (feeData?.maxFeePerGas && chainId === ARBITRUM_GOERLI) {
  //   txnOpts.maxFeePerGas = feeData.maxFeePerGas;
  // }

  txnOpts.gasPrice = gasPrice.add(premium);
  return;
}

export async function getGasLimit(contract: Contract, method, params = [], value) {
  const defaultValue = bigNumberify(0);

  if (!value) {
    value = defaultValue;
  }

  let gasLimit = await contract.estimateGas[method](...params, { value });

  if (gasLimit.lt(22000)) {
    gasLimit = bigNumberify(22000)!;
  }

  return gasLimit.mul(11000).div(10000); // add a 10% buffer
}
