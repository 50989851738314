import gnsGTokenOpenPnLFeed from "futures-abis/GTokenOpenPnLFeed.json";
import gnsPairInfos from "futures-abis/PairInfos.json";
import gnsPriceAggregator from "futures-abis/PriceAggregator.json";
import gnsReferral from "futures-abis/Referral.json";
import gnsStorage from "futures-abis/Storage.json";
import gnsToken from "futures-abis/Token.json"; // getFreeDai() 호출 가능한 Token abi
import gnsTrading from "futures-abis/Trading.json";
import gnsTradingCallbacks from "futures-abis/TradingCallbacks.json";
import gnsVault from "futures-abis/Vault.json";
import {
  ARBITRUM,
  ARBITRUM_GOERLI,
  ARBITRUM_SEPOLIA,
  HARDHAT,
  POLYGON,
  POLYGON_MUMBAI,
  WEMIX_TESTNET,
  ZKSYNC,
  ZKSYNC_GOERLI,
  ZKSYNC_SEPOLIA,
} from "./chains";

// import gambitVault from "gambit-abis/Vault.json";
// import gambitTrading from "gambit-abis/Trading.json";
// import gambitTradingCallbacks from "gambit-abis/TradingCallbacks.json";
// import gambitGTokenOpenPnLFeed from "gambit-abis/GTokenOpenPnLFeed.json";
// import gambitPairInfos from "gambit-abis/PairInfos.json";
// import gambitStorage from "gambit-abis/Storage.json";
// import gambitReferral from "gambit-abis/Referral.json";
// import gambitPriceAggregator from "gambit-abis/PriceAggregator.json";
// import gambitToken from "futures-abis/Token.json"; // 기본 ERC20, mint() 호출 가능한 Token abi

import ContractAddress from "@changerio/futures-contracts/contract-deployment.json";
import { tokens as TokenAddress } from "@changerio/futures-contracts/dist/lib/constants";
import {
  GTokenOpenPnlFeed__factory,
  GambitNftRewardsV1__factory,
  GambitPairInfosV1__factory,
  GambitPriceAggregatorV1__factory,
  GambitReferralsV1__factory,
  GambitTradingCallbacksV1__factory,
  GambitTradingStorageV1__factory,
  GambitTradingV1__factory,
  MockUSDC__factory,
  SimpleGToken__factory,
} from "@changerio/futures-contracts/dist/typechain-types";

const CONTRACTS = {
  // [POLYGON]: {
  //   PriceAggregator: "0x631e885028E75fCbB34C06d8ecB8e20eA18f6632",
  //   Trading: "0x65187FeC6eCc4774C1f632C7503466D5B4353Db1",
  //   PairInfos: "0xEe7442aCcC1C27f2C69423576d3b1D25b563E977",
  //   PairsStorage: "",
  //   TradingCallbacks: "0xb454d8A8C98035C65Bb73FE2a11567b9B044E0fa",
  //   Storage: "0xaee4d11a16B2bc65EDD6416Fb626EB404a6D65BD",
  //   Vault: "0x91993f2101cc758D0dEB7279d41e880F7dEFe827",
  //   GTokenOpenPnLFeed: "0x8d687276543b92819F2f2B5C3faad4AD27F4440c", // to force new epoch
  //   Referral: "0x0F9498b1206Bf9FfDE2a2321fDB56F573A052425",
  //   CollateralToken: "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063", // DAI
  //   NftRewards: "",
  // },
  // [POLYGON_MUMBAI]: {
  //   PriceAggregator: "0x8a90c2DDe880941A3c5240E5C5eA104Fc80B0B89",
  //   Trading: "0xDAFa580585d2849088a5212F729adFe9b9512413",
  //   PairInfos: "0xe780bBf94b0257fEDccF4EAEcBC70722E6F5F574",
  //   PairsStorage: "",
  //   TradingCallbacks: "0xA7443A20B42f9156F7D9DB01e51523C42CAC8eCE",
  //   Storage: "0x4d2dF485c608aa55A23d8d98dD2B4FA24Ba0f2Cf", // Trading Storage
  //   Vault: "0x5215C8B3e76D493c8bcb9A7352F7afe18A6bb091", // GToken, gDAI (shares)
  //   GTokenOpenPnLFeed: "0xa7c829cb2346e12dc49fd5ea8002abaf48e9396c", // force new epoch
  //   Referral: "0x022e26d7ddad3fc311c6472949f19c99b3cb08e6",
  //   CollateralToken: "0x04B2A6E51272c82932ecaB31A5Ab5aC32AE168C3", // (GFARM) DAI (assets)
  //   NftRewards: "",
  // },
  [ZKSYNC]: {
    PriceAggregator:
      ContractAddress.zksyncEra.contracts.GambitPriceAggregatorV1,
    Trading: ContractAddress.zksyncEra.contracts.GambitTradingV1,
    PairInfos: ContractAddress.zksyncEra.contracts.GambitPairInfosV1_Proxy,
    PairsStorage: ContractAddress.zksyncEra.contracts.GambitPairsStorageV1,
    TradingCallbacks:
      ContractAddress.zksyncEra.contracts.GambitTradingCallbacksV1,
    Storage: ContractAddress.zksyncEra.contracts.GambitTradingStorageV1_Proxy, // Trading Storage
    Vault: ContractAddress.zksyncEra.contracts.SimpleGToken_Proxy, // GToken, gUSDC (shares)
    GTokenOpenPnLFeed:
      ContractAddress.zksyncEra.contracts.GTokenOpenPnlFeed_Proxy, // for force new epoch
    Referral: ContractAddress.zksyncEra.contracts.GambitReferralsV1_Proxy,
    CollateralToken: TokenAddress[ZKSYNC].USDC.address, // USDC (assets)
    NftRewards: ContractAddress.zksyncEra.contracts.GambitNftRewardsV1_Proxy,
    CNG: TokenAddress[ZKSYNC].CNG.address,
    IncentiveVault:
      ContractAddress.zksyncEra.contracts.GambitIncentiveVault_Proxy,
  },
  // [ZKSYNC_GOERLI]: {
  //   PriceAggregator: ContractAddress.zksyncEraGoerli.contracts.GambitPriceAggregatorV1,
  //   Trading: ContractAddress.zksyncEraGoerli.contracts.GambitTradingV1,
  //   PairInfos: ContractAddress.zksyncEraGoerli.contracts.GambitPairInfosV1_Proxy,
  //   PairsStorage: ContractAddress.zksyncEraGoerli.contracts.GambitPairsStorageV1,
  //   TradingCallbacks: ContractAddress.zksyncEraGoerli.contracts.GambitTradingCallbacksV1,
  //   Storage: ContractAddress.zksyncEraGoerli.contracts.GambitTradingStorageV1_Proxy, // Trading Storage
  //   Vault: ContractAddress.zksyncEraGoerli.contracts.SimpleGToken_Proxy, // GToken, gUSDC (shares)
  //   GTokenOpenPnLFeed: ContractAddress.zksyncEraGoerli.contracts.GTokenOpenPnlFeed_Proxy, // for force new epoch
  //   Referral: ContractAddress.zksyncEraGoerli.contracts.GambitReferralsV1_Proxy,
  //   CollateralToken: TokenAddress[ZKSYNC_GOERLI].USDC.address, // USDC (assets)
  //   NftRewards: ContractAddress.zksyncEraGoerli.contracts.GambitNftRewardsV1_Proxy,
  //   CNG: TokenAddress[ZKSYNC_GOERLI].CNG.address,
  // },
  [ZKSYNC_SEPOLIA]: {
    PriceAggregator:
      ContractAddress.zksyncEraSepolia.contracts.GambitPriceAggregatorV1,
    Trading: ContractAddress.zksyncEraSepolia.contracts.GambitTradingV1,
    PairInfos:
      ContractAddress.zksyncEraSepolia.contracts.GambitPairInfosV1_Proxy,
    PairsStorage:
      ContractAddress.zksyncEraSepolia.contracts.GambitPairsStorageV1,
    TradingCallbacks:
      ContractAddress.zksyncEraSepolia.contracts.GambitTradingCallbacksV1,
    Storage:
      ContractAddress.zksyncEraSepolia.contracts.GambitTradingStorageV1_Proxy, // Trading Storage
    Vault: ContractAddress.zksyncEraSepolia.contracts.SimpleGToken_Proxy, // GToken, gUSDC (shares)
    GTokenOpenPnLFeed:
      ContractAddress.zksyncEraSepolia.contracts.GTokenOpenPnlFeed_Proxy, // for force new epoch
    Referral:
      ContractAddress.zksyncEraSepolia.contracts.GambitReferralsV1_Proxy,
    CollateralToken: TokenAddress[ZKSYNC_SEPOLIA].USDC.address, // USDC (assets)
    NftRewards:
      ContractAddress.zksyncEraSepolia.contracts.GambitNftRewardsV1_Proxy,
    CNG: TokenAddress[ZKSYNC_SEPOLIA].CNG.address,
    IncentiveVault:
      ContractAddress.zksyncEraSepolia.contracts.GambitIncentiveVault_Proxy,
  },
  [HARDHAT]: {
    PriceAggregator: ContractAddress.hardhat.contracts.GambitPriceAggregatorV1,
    Trading: ContractAddress.hardhat.contracts.GambitTradingV1,
    PairInfos: ContractAddress.hardhat.contracts.GambitPairInfosV1_Proxy,
    PairsStorage: ContractAddress.hardhat.contracts.GambitPairsStorageV1,
    TradingCallbacks:
      ContractAddress.hardhat.contracts.GambitTradingCallbacksV1,
    Storage: ContractAddress.hardhat.contracts.GambitTradingStorageV1_Proxy, // Trading Storage
    Vault: ContractAddress.hardhat.contracts.SimpleGToken_Proxy, // GToken, gUSDC (shares)
    GTokenOpenPnLFeed:
      ContractAddress.hardhat.contracts.GTokenOpenPnlFeed_Proxy, // for force new epoch
    Referral: ContractAddress.hardhat.contracts.GambitReferralsV1_Proxy,
    CollateralToken: ContractAddress.hardhat.contracts.MockUSDC, // Mock USDC (assets)
    NftRewards: ContractAddress.hardhat.contracts.GambitNftRewardsV1_Proxy,
    CNG: ContractAddress.hardhat.contracts.MockCNG,
    IncentiveVault:
      ContractAddress.hardhat.contracts.GambitIncentiveVault_Proxy,
  },
  // [WEMIX_TESTNET]: {
  //   PriceAggregator: ContractAddress.wemixTestnet.contracts.GambitPriceAggregatorV1,
  //   Trading: ContractAddress.wemixTestnet.contracts.GambitTradingV1,
  //   PairInfos: ContractAddress.wemixTestnet.contracts.GambitPairInfosV1_Proxy,
  //   PairsStorage: ContractAddress.wemixTestnet.contracts.GambitPairsStorageV1,
  //   TradingCallbacks: ContractAddress.wemixTestnet.contracts.GambitTradingCallbacksV1,
  //   Storage: ContractAddress.wemixTestnet.contracts.GambitTradingStorageV1_Proxy, // Trading Storage
  //   Vault: ContractAddress.wemixTestnet.contracts.SimpleGToken_Proxy, // GToken, gUSDC (shares)
  //   GTokenOpenPnLFeed: ContractAddress.wemixTestnet.contracts.GTokenOpenPnlFeed_Proxy, // for force new epoch
  //   Referral: ContractAddress.wemixTestnet.contracts.GambitReferralsV1_Proxy,
  //   // CollateralToken: TokenAddress[WEMIX_TESTNET].USDC.address, // Mock DAI (assets)
  //   CollateralToken: "0xae81b9ffcde5ab7673dd4b2f5c648a5579430b17", // Mock DAI (assets)
  //   NftRewards: ContractAddress.wemixTestnet.contracts.GambitNftRewardsV1_Proxy,
  //   CNG: ContractAddress.wemixTestnet.contracts.MockCNG,
  // },
  [ARBITRUM]: {
    PriceAggregator:
      ContractAddress.arbitrumOne.contracts.GambitPriceAggregatorV1,
    Trading: ContractAddress.arbitrumOne.contracts.GambitTradingV1,
    PairInfos: ContractAddress.arbitrumOne.contracts.GambitPairInfosV1_Proxy,
    PairsStorage: ContractAddress.arbitrumOne.contracts.GambitPairsStorageV1,
    TradingCallbacks:
      ContractAddress.arbitrumOne.contracts.GambitTradingCallbacksV1,
    Storage: ContractAddress.arbitrumOne.contracts.GambitTradingStorageV1_Proxy, // Trading Storage
    Vault: ContractAddress.arbitrumOne.contracts.SimpleGToken_Proxy, // GToken, gUSDC (shares)
    GTokenOpenPnLFeed:
      ContractAddress.arbitrumOne.contracts.GTokenOpenPnlFeed_Proxy, // for force new epoch
    Referral: ContractAddress.arbitrumOne.contracts.GambitReferralsV1_Proxy,
    CollateralToken: TokenAddress[ARBITRUM].USDC.address, // USDC (assets)
    NftRewards: ContractAddress.arbitrumOne.contracts.GambitNftRewardsV1_Proxy,
    CNG: TokenAddress[ARBITRUM].CNG.address,
    IncentiveVault:
      ContractAddress.arbitrumOne.contracts.GambitIncentiveVault_Proxy,
  },
  // [ARBITRUM_GOERLI]: {
  //   PriceAggregator: ContractAddress.arbitrumGoerli.contracts.GambitPriceAggregatorV1,
  //   Trading: ContractAddress.arbitrumGoerli.contracts.GambitTradingV1,
  //   PairInfos: ContractAddress.arbitrumGoerli.contracts.GambitPairInfosV1_Proxy,
  //   PairsStorage: ContractAddress.arbitrumGoerli.contracts.GambitPairsStorageV1,
  //   TradingCallbacks: ContractAddress.arbitrumGoerli.contracts.GambitTradingCallbacksV1,
  //   Storage: ContractAddress.arbitrumGoerli.contracts.GambitTradingStorageV1_Proxy, // Trading Storage
  //   Vault: ContractAddress.arbitrumGoerli.contracts.SimpleGToken_Proxy, // GToken, gUSDC (shares)
  //   GTokenOpenPnLFeed: ContractAddress.arbitrumGoerli.contracts.GTokenOpenPnlFeed_Proxy, // for force new epoch
  //   Referral: ContractAddress.arbitrumGoerli.contracts.GambitReferralsV1_Proxy,
  //   CollateralToken: TokenAddress[ARBITRUM_GOERLI].USDC.address || ContractAddress.arbitrumGoerli.contracts.MockUSDC, // USDC (assets)
  //   NftRewards: ContractAddress.arbitrumGoerli.contracts.GambitNftRewardsV1_Proxy,
  //   CNG: ContractAddress.arbitrumGoerli.contracts.MockCNG || TokenAddress[ARBITRUM_GOERLI].CNG.address,
  // },
  [ARBITRUM_SEPOLIA]: {
    PriceAggregator:
      ContractAddress.arbitrumSepolia.contracts.GambitPriceAggregatorV1,
    Trading: ContractAddress.arbitrumSepolia.contracts.GambitTradingV1,
    PairInfos:
      ContractAddress.arbitrumSepolia.contracts.GambitPairInfosV1_Proxy,
    PairsStorage:
      ContractAddress.arbitrumSepolia.contracts.GambitPairsStorageV1,
    TradingCallbacks:
      ContractAddress.arbitrumSepolia.contracts.GambitTradingCallbacksV1,
    Storage:
      ContractAddress.arbitrumSepolia.contracts.GambitTradingStorageV1_Proxy, // Trading Storage
    Vault: ContractAddress.arbitrumSepolia.contracts.SimpleGToken_Proxy, // GToken, gUSDC (shares)
    GTokenOpenPnLFeed:
      ContractAddress.arbitrumSepolia.contracts.GTokenOpenPnlFeed_Proxy, // for force new epoch
    Referral: ContractAddress.arbitrumSepolia.contracts.GambitReferralsV1_Proxy,
    CollateralToken:
      TokenAddress[ARBITRUM_SEPOLIA].USDC.address ||
      ContractAddress.arbitrumSepolia.contracts.MockUSDC, // USDC (assets)
    NftRewards:
      ContractAddress.arbitrumSepolia.contracts.GambitNftRewardsV1_Proxy,
    CNG:
      ContractAddress.arbitrumSepolia.contracts.MockCNG ||
      TokenAddress[ARBITRUM_SEPOLIA].CNG.address,
    IncentiveVault:
      ContractAddress.arbitrumSepolia.contracts.GambitIncentiveVault_Proxy,
  },
};

const ABIS = {
  [POLYGON]: {
    PriceAggregator: gnsPriceAggregator,
    Trading: gnsTrading,
    PairInfos: gnsPairInfos,
    TradingCallbacks: gnsTradingCallbacks,
    Storage: gnsStorage,
    Vault: gnsVault,
    GTokenOpenPnLFeed: gnsGTokenOpenPnLFeed,
    Referral: gnsReferral,
    NftRewards: "",
  },
  [POLYGON_MUMBAI]: {
    PriceAggregator: gnsPriceAggregator,
    Trading: gnsTrading,
    PairInfos: gnsPairInfos,
    TradingCallbacks: gnsTradingCallbacks,
    Storage: gnsStorage,
    Vault: gnsVault,
    GTokenOpenPnLFeed: gnsGTokenOpenPnLFeed,
    Referral: gnsReferral,
    CollateralToken: gnsToken,
    NftRewards: "",
  },
  [ZKSYNC]: {
    PriceAggregator: GambitPriceAggregatorV1__factory,
    Trading: GambitTradingV1__factory,
    PairInfos: GambitPairInfosV1__factory,
    TradingCallbacks: GambitTradingCallbacksV1__factory,
    Storage: GambitTradingStorageV1__factory,
    Vault: SimpleGToken__factory,
    GTokenOpenPnLFeed: GTokenOpenPnlFeed__factory,
    Referral: GambitReferralsV1__factory,
    CollateralToken: MockUSDC__factory,
    NftRewards: GambitNftRewardsV1__factory,
  },
  [ZKSYNC_GOERLI]: {
    PriceAggregator: GambitPriceAggregatorV1__factory,
    Trading: GambitTradingV1__factory,
    PairInfos: GambitPairInfosV1__factory,
    TradingCallbacks: GambitTradingCallbacksV1__factory,
    Storage: GambitTradingStorageV1__factory,
    Vault: SimpleGToken__factory,
    GTokenOpenPnLFeed: GTokenOpenPnlFeed__factory,
    Referral: GambitReferralsV1__factory,
    CollateralToken: MockUSDC__factory,
    NftRewards: GambitNftRewardsV1__factory,
  },
  [ZKSYNC_SEPOLIA]: {
    PriceAggregator: GambitPriceAggregatorV1__factory,
    Trading: GambitTradingV1__factory,
    PairInfos: GambitPairInfosV1__factory,
    TradingCallbacks: GambitTradingCallbacksV1__factory,
    Storage: GambitTradingStorageV1__factory,
    Vault: SimpleGToken__factory,
    GTokenOpenPnLFeed: GTokenOpenPnlFeed__factory,
    Referral: GambitReferralsV1__factory,
    CollateralToken: MockUSDC__factory,
    NftRewards: GambitNftRewardsV1__factory,
  },
  [HARDHAT]: {
    PriceAggregator: GambitPriceAggregatorV1__factory,
    Trading: GambitTradingV1__factory,
    PairInfos: GambitPairInfosV1__factory,
    TradingCallbacks: GambitTradingCallbacksV1__factory,
    Storage: GambitTradingStorageV1__factory,
    Vault: SimpleGToken__factory,
    GTokenOpenPnLFeed: GTokenOpenPnlFeed__factory,
    Referral: GambitReferralsV1__factory,
    CollateralToken: MockUSDC__factory,
    NftRewards: GambitNftRewardsV1__factory,
  },
  [WEMIX_TESTNET]: {
    PriceAggregator: GambitPriceAggregatorV1__factory,
    Trading: GambitTradingV1__factory,
    PairInfos: GambitPairInfosV1__factory,
    TradingCallbacks: GambitTradingCallbacksV1__factory,
    Storage: GambitTradingStorageV1__factory,
    Vault: SimpleGToken__factory,
    GTokenOpenPnLFeed: GTokenOpenPnlFeed__factory,
    Referral: GambitReferralsV1__factory,
    CollateralToken: MockUSDC__factory,
    NftRewards: GambitNftRewardsV1__factory,
  },
  [ARBITRUM]: {
    PriceAggregator: GambitPriceAggregatorV1__factory,
    Trading: GambitTradingV1__factory,
    PairInfos: GambitPairInfosV1__factory,
    TradingCallbacks: GambitTradingCallbacksV1__factory,
    Storage: GambitTradingStorageV1__factory,
    Vault: SimpleGToken__factory,
    GTokenOpenPnLFeed: GTokenOpenPnlFeed__factory,
    Referral: GambitReferralsV1__factory,
    CollateralToken: MockUSDC__factory,
    NftRewards: GambitNftRewardsV1__factory,
  },
  [ARBITRUM_GOERLI]: {
    PriceAggregator: GambitPriceAggregatorV1__factory,
    Trading: GambitTradingV1__factory,
    PairInfos: GambitPairInfosV1__factory,
    TradingCallbacks: GambitTradingCallbacksV1__factory,
    Storage: GambitTradingStorageV1__factory,
    Vault: SimpleGToken__factory,
    GTokenOpenPnLFeed: GTokenOpenPnlFeed__factory,
    Referral: GambitReferralsV1__factory,
    CollateralToken: MockUSDC__factory,
    NftRewards: GambitNftRewardsV1__factory,
  },
  [ARBITRUM_SEPOLIA]: {
    PriceAggregator: GambitPriceAggregatorV1__factory,
    Trading: GambitTradingV1__factory,
    PairInfos: GambitPairInfosV1__factory,
    TradingCallbacks: GambitTradingCallbacksV1__factory,
    Storage: GambitTradingStorageV1__factory,
    Vault: SimpleGToken__factory,
    GTokenOpenPnLFeed: GTokenOpenPnlFeed__factory,
    Referral: GambitReferralsV1__factory,
    CollateralToken: MockUSDC__factory,
    NftRewards: GambitNftRewardsV1__factory,
  },
};

export type ContractT = keyof typeof CONTRACTS[typeof ZKSYNC_SEPOLIA];
export type AbiT = keyof typeof ABIS[typeof ZKSYNC_SEPOLIA];

/**
 * @dev get contract address
 *
 * @param chainId
 * @param name
 * @returns
 */
export function getContract(chainId: number, name: ContractT): string {
  if (!CONTRACTS[chainId]) {
    throw new Error(`Unknown chainId ${chainId}`);
  }

  if (!CONTRACTS[chainId][name]) {
    throw new Error(`Unknown contract "${name}" for chainId ${chainId}`);
  }

  return CONTRACTS[chainId][name];
}

export const getAbi = (chainId: number, name: AbiT) => {
  if (!ABIS[chainId]) {
    throw new Error(`Unknown chainId ${chainId}`);
  }

  if (!ABIS[chainId][name]) {
    throw new Error(`Unknown contract "${name}" for chainId ${chainId}`);
  }

  return ABIS[chainId][name];
};
