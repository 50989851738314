import { Menu } from "@headlessui/react";
import { useMemo, useRef, useState } from "react";
// import ModalWithPortal from "../Modal/ModalWithPortal";
// import { t, Trans } from "@lingui/macro";
import cx from "classnames";
// import "./NetworkDropdown.css";
import { LANGUAGE_LOCALSTORAGE_KEY } from "config/localStorage";
import { defaultLocale } from "futures-lib/i18n";
// import LanguageModalContent from "./LanguageModalContent";
import { getIcon } from "config/icons";
import { NetworkOption } from "futures-domain/types";
import { useChainId } from "futures-lib/chains";

import { TESTNETS } from "config/chains";
import useWallet from "futures-lib/wallets/useWallet";
import warningIcon from "img/ic-warning.svg";

// const LANGUAGE_MODAL_KEY: string = "LANGUAGE";
// const NETWORK_MODAL_KEY: string = "NETWORK";

export default function NetworkDropdown(props) {
  const currentLanguage = useRef(
    localStorage.getItem(LANGUAGE_LOCALSTORAGE_KEY) || defaultLocale
  );
  const [activeModal, setActiveModal] = useState<string | null>(null);
  const { chainId } = useChainId();
  const { isActive, chainId: walletChainId } = useWallet();

  const networkWarning = useMemo(() => {
    if (isActive && chainId !== walletChainId) return true;
    return false;
  }, [chainId, isActive, walletChainId]);

  // function getModalContent(modalName: string | null) {
  //   switch (modalName) {
  //     // case LANGUAGE_MODAL_KEY:
  //     //   return <LanguageModalContent currentLanguage={currentLanguage} />;
  //     case NETWORK_MODAL_KEY:
  //       return (
  //         <NetworkModalContent
  //           setActiveModal={setActiveModal}
  //           networkOptions={props.networkOptions}
  //           onNetworkSelect={props.onNetworkSelect}
  //           selectorLabel={props.selectorLabel}
  //           openSettings={props.openSettings}
  //         />
  //       );
  //     default:
  //       return;
  //   }
  // }

  // function getModalProps(modalName: string | null) {
  //   switch (modalName) {
  //     case LANGUAGE_MODAL_KEY:
  //       return {
  //         className: "language-popup",
  //         isVisible: activeModal === LANGUAGE_MODAL_KEY,
  //         setIsVisible: () => setActiveModal(null),
  //         label: t`Select Language`,
  //       };
  //     case NETWORK_MODAL_KEY:
  //       return {
  //         className: "network-popup",
  //         isVisible: activeModal === NETWORK_MODAL_KEY,
  //         setIsVisible: () => setActiveModal(null),
  //         label: t`Networks and Settings`,
  //       };
  //     default:
  //       return {};
  //   }
  // }

  return (
    <>
      <DropdownModal
        currentLanguage={currentLanguage}
        activeModal={activeModal}
        setActiveModal={setActiveModal}
        networkWarning={networkWarning}
        {...props}
      />

      {/* <ModalWithPortal {...getModalProps(activeModal)}>{getModalContent(activeModal)}</ModalWithPortal> */}
    </>
  );
}

function NavIcons({ selectorLabel }) {
  const { chainId } = useChainId();
  const icon = useMemo(() => {
    return getIcon(chainId, "network");
  }, [chainId]);

  return (
    <button className="">
      <img
        className="min-w-[2.4rem] min-h-[2.4rem] w-[2.4rem] h-[2.4rem]"
        src={icon}
        alt={selectorLabel}
      />
    </button>
  );
}

function DropdownModal({
  setActiveModal,
  selectorLabel,
  networkOptions,
  onNetworkSelect,
  openSettings,
  networkWarning,
}) {
  return (
    <div className="relative z-[1]">
      <Menu>
        <Menu.Button as="div" className="flex items-center cursor-pointer">
          {networkWarning ? (
            <button className="">
              <img
                className="min-w-[2.4rem] min-h-[2.4rem] w-[2.4rem] h-[2.4rem]"
                src={warningIcon}
                alt="warning"
              />
            </button>
          ) : (
            <NavIcons selectorLabel={selectorLabel} />
          )}
        </Menu.Button>

        <Menu.Items
          as="div"
          className={cx(
            "absolute left-[-19.5rem] top-[3.6rem] min-w-[26.5rem]",
            "bg-gray-70 bg-opacity-95 space-y-[2px]",
            "px-[.5rem] py-[.7rem] border border-white border-opacity-10 rounded-[6px]",
            "text-[1.3rem] font-medium"
          )}
        >
          <NetworkMenuItems
            networkOptions={networkOptions}
            selectorLabel={selectorLabel}
            onNetworkSelect={onNetworkSelect}
            networkWarning={networkWarning}
          />
        </Menu.Items>
      </Menu>
    </div>
  );
}

function NetworkMenuItems({
  networkOptions,
  selectorLabel,
  onNetworkSelect,
  networkWarning,
}) {
  return networkOptions.map((network: NetworkOption) => {
    return (
      <Menu.Item key={network.value}>
        <div
          className="flex items-center justify-between cursor-pointer px-[.5rem] py-[.5rem] hover:bg-white hover:bg-opacity-10 hover:rounded-[4px]"
          onClick={async () => {
            await onNetworkSelect({ value: network.value });
          }}
        >
          <div className="flex items-center gap-[.75rem]">
            <img
              className="w-[2.2rem] h-[2.2rem]"
              src={network.icon}
              alt={network.label}
            />
            <span className="">{network.label}</span>
            {TESTNETS.includes(network.value) && (
              <div className="bg-brand-1 px-[2.5px] rounded-[4px] text-black-1 text-[1.2rem]">
                PRACTICE
              </div>
            )}
          </div>

          <div
            className={cx({
              "self-center w-[0.8rem] h-[0.8rem] rounded-full bg-brand-1":
                selectorLabel === network.label && !networkWarning,
            })}
          />
        </div>
      </Menu.Item>
    );
  });
}

// function NetworkModalContent({ networkOptions, onNetworkSelect, selectorLabel, setActiveModal, openSettings }) {
//   console.log("NetworkModalContent");
//   async function handleNetworkSelect(option) {
//     await onNetworkSelect(option);
//   }
//   return (
//     <div className="network-dropdown-items">
//       <div className="network-dropdown-list">
//         <span className="network-dropdown-label">
//           <Trans>Networks</Trans>
//         </span>

//         {networkOptions.map((network) => {
//           return (
//             <div
//               className="network-option"
//               onClick={() => handleNetworkSelect({ value: network.value })}
//               key={network.value}
//             >
//               <div className="menu-item-group">
//                 <img src={network.icon} alt={network.label} />
//                 <span>{network.label}</span>
//               </div>
//               <div
//                 className={cx("w-[0.8rem] h-[0.8rem] rounded-full bg-brand-1", {
//                   [selectorLabel]: selectorLabel === network.label,
//                 })}
//               />
//             </div>
//           );
//         })}
//       </div>
//     </div>
//   );
// }
