import * as Sentry from "@sentry/react";
import ErrorPage from "pages/ErrorPage";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import "regenerator-runtime/runtime";
import App from "./App/App";
import reportWebVitals from "./reportWebVitals";
// import WalletProvider from "futures-lib/wallets/WalletProvider";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

serviceWorkerRegistration.register();

const container = document.getElementById("root");
const root = createRoot(container!);

// if (process.env.REACT_APP_MODE !== "local") {
const ignoreErrors = ["MetaMask Tx Signature: User denied transaction signature."];

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_MODE,
  // integrations: [new Sentry.BrowserTracing()],
  normalizeDepth: 10,

  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

  beforeSend: (event, hint) => {
    if (event && ignoreErrors.includes((event.extra?.__serialized__ as any)?.message)) {
      return null;
    }

    return event;
  },
  ignoreErrors,
});
// }

root.render(
  <React.StrictMode>
    <Router>
      <Sentry.ErrorBoundary fallback={<ErrorPage />}>
        <App />
      </Sentry.ErrorBoundary>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.info))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
