import ContractAddress from "@changerio/futures-contracts/contract-deployment.json";
import { tokens as TokenAddress } from "@changerio/futures-contracts/dist/lib/constants";
import {
  GTokenOpenPnlFeed,
  GTokenOpenPnlFeed__factory,
  GambitIncentiveVault,
  GambitIncentiveVault__factory,
  GambitNftRewardsV1,
  GambitPairInfosV1,
  GambitPairsStorageV1,
  GambitPairsStorageV1__factory,
  GambitPriceAggregatorV1,
  GambitReferralsV1,
  GambitReferralsV1__factory,
  GambitStakingV1,
  GambitStakingV1__factory,
  GambitTradingCallbacksV1,
  GambitTradingStorageV1,
  GambitTradingV1,
  GambitTradingV1__factory,
  MockCNG,
  MockCNG__factory,
  MockUSDC,
  MockUSDC__factory,
  SimpleGToken,
  SimpleGToken__factory,
  TokenWithdrawal,
  TokenWithdrawal__factory,
  Treasury,
} from "@changerio/futures-contracts/dist/typechain-types";
import { JsonRpcProvider, JsonRpcSigner } from "@ethersproject/providers";
import ArbitrumUSDC from "abis/ArbitrumUSDC.json";
import BN from "bignumber.js";
import {
  ARBITRUM,
  ZKSYNC,
  getChainNameForContract,
  getRpcUrl,
} from "config/chains";
import { getContract } from "config/contracts";
import { BigNumber, Signer, ethers } from "ethers";
import { useChainId } from "futures-lib/chains";
import useWallet from "futures-lib/wallets/useWallet";
import { useEffect, useMemo, useState } from "react";

export function getTradingContract(
  _networkName: string,
  singerOrProvider: JsonRpcProvider | JsonRpcSigner | Signer
): GambitTradingV1 {
  const networkName = _networkName as keyof typeof ContractAddress;

  return GambitTradingV1__factory.connect(
    ContractAddress[networkName].contracts.GambitTradingV1,
    singerOrProvider
  );
}

export function getCollateralContract(
  contractAddress: string,
  singerOrProvider: JsonRpcProvider | JsonRpcSigner | Signer
) {
  return MockUSDC__factory.connect(contractAddress, singerOrProvider);
}

export function getGTokenContract(
  _networkName: string,
  providerOrSigner: JsonRpcProvider | JsonRpcSigner
): SimpleGToken {
  const networkName = _networkName as keyof typeof ContractAddress;

  return SimpleGToken__factory.connect(
    ContractAddress[networkName].contracts.SimpleGToken,
    providerOrSigner
  );
}

export function getGReferralsContract(
  _networkName: string,
  singerOrProvider: JsonRpcProvider | JsonRpcSigner | Signer
): GambitReferralsV1 {
  const networkName = _networkName as keyof typeof ContractAddress;

  return GambitReferralsV1__factory.connect(
    ContractAddress[networkName].contracts.GambitReferralsV1,
    singerOrProvider
  );
}

export function getGIncentiveContract(
  _networkName: string,
  singerOrProvider: JsonRpcProvider | JsonRpcSigner | Signer
): GambitIncentiveVault | null {
  const networkName = _networkName as keyof typeof ContractAddress;

  if (
    networkName === "zksyncEraSepolia" ||
    networkName === "arbitrumSepolia" ||
    networkName === "zksyncEra" ||
    networkName === "arbitrumOne"
  ) {
    return GambitIncentiveVault__factory.connect(
      ContractAddress[networkName].contracts.GambitIncentiveVault,
      singerOrProvider
    );
  }

  return null;
}

export function getCngContract(
  _networkName: string,
  providerOrSigner: JsonRpcProvider | JsonRpcSigner | Signer,
  chainId: number
): MockCNG | undefined {
  const networkName = _networkName as keyof typeof ContractAddress;

  if (networkName === "hardhat" || networkName === "arbitrumGoerli") {
    return MockCNG__factory.connect(
      ContractAddress[networkName].contracts.MockCNG,
      providerOrSigner
    );
  } else {
    return MockCNG__factory.connect(
      TokenAddress[chainId].CNG.address,
      providerOrSigner
    );
  }
}

export function getCngStakingContract(
  _networkName: string,
  providerOrSigner: JsonRpcProvider | JsonRpcSigner | Signer
): GambitStakingV1 | undefined {
  const networkName = _networkName as keyof typeof ContractAddress;

  return GambitStakingV1__factory.connect(
    ContractAddress[networkName].contracts.GambitStakingV1,
    providerOrSigner
  );
}

export function getTokenWithdrawalContract(
  _networkName: string,
  providerOrSigner: JsonRpcProvider | JsonRpcSigner | Signer
): TokenWithdrawal | undefined {
  const networkName = _networkName as keyof typeof ContractAddress;

  // TODO: remove any
  return TokenWithdrawal__factory.connect(
    (ContractAddress[networkName].contracts as any).TokenWithdrawal,
    providerOrSigner
  );
}

export function getGTokenOpenPnlFeedContract(
  _networkName: string,
  providerOrSigner: JsonRpcProvider | JsonRpcSigner | Signer
): GTokenOpenPnlFeed | undefined {
  const networkName = _networkName as keyof typeof ContractAddress;

  return GTokenOpenPnlFeed__factory.connect(
    ContractAddress[networkName].contracts.GTokenOpenPnlFeed,
    providerOrSigner
  );
}

export type ContractNameT =
  | "GTokenOpenPnlFeed"
  | "GambitTradingV1"
  | "SimpleGToken"
  | "GambitTradingCallbacksV1"
  | "GambitTradingStorageV1"
  | "GambitReferralsV1"
  | "GambitPairInfosV1"
  | "GambitNftRewardsV1"
  | "GambitPriceAggregatorV1"
  | "GambitPairsStorageV1"
  | "GambitStakingV1"
  | "MockUSDC"
  | "TokenWithdrawal"
  | "Treasury"
  | "GambitIncentiveVault";

const getTContract = (
  chainId: number,
  contractName: ContractNameT,
  providerOrSigner: JsonRpcProvider | JsonRpcSigner | Signer | undefined,
  contractAddress?: string
) => {
  if (!providerOrSigner) return null;

  switch (contractName) {
    case "GTokenOpenPnlFeed":
      return getGTokenOpenPnlFeedContract(
        getChainNameForContract(chainId),
        providerOrSigner
      );

    case "GambitTradingV1":
      return getTradingContract(
        getChainNameForContract(chainId),
        providerOrSigner
      );

    case "GambitPairsStorageV1":
      return getGPairsStorageContract(
        getChainNameForContract(chainId),
        providerOrSigner
      );

    case "GambitStakingV1":
      return getCngStakingContract(
        getChainNameForContract(chainId),
        providerOrSigner
      );

    case "TokenWithdrawal":
      return getTokenWithdrawalContract(
        getChainNameForContract(chainId),
        providerOrSigner
      );

    case "GambitReferralsV1":
      return getGReferralsContract(
        getChainNameForContract(chainId),
        providerOrSigner
      );

    case "GambitIncentiveVault":
      return getGIncentiveContract(
        getChainNameForContract(chainId),
        providerOrSigner
      );

    case "MockUSDC":
      if (!contractAddress) return null;
      return getCollateralContract(contractAddress, providerOrSigner);

    default:
      return null;
  }
};

export function getGPairsStorageContract(
  _networkName: string,
  providerOrSigner: JsonRpcProvider | JsonRpcSigner | Signer
): GambitPairsStorageV1 | undefined {
  const networkName = _networkName as keyof typeof ContractAddress;

  return GambitPairsStorageV1__factory.connect(
    ContractAddress[networkName].contracts.GambitPairsStorageV1,
    providerOrSigner
  );
}

export type ContractT =
  | null
  | undefined
  | GTokenOpenPnlFeed
  | GambitTradingV1
  | SimpleGToken
  | GambitTradingCallbacksV1
  | GambitTradingStorageV1
  | GambitReferralsV1
  | GambitPairInfosV1
  | GambitNftRewardsV1
  | GambitPriceAggregatorV1
  | GambitPairsStorageV1
  | GambitStakingV1
  | MockUSDC
  | TokenWithdrawal
  | Treasury
  | GambitIncentiveVault;

// _public은 지갑 연결하지 않은 상태에서도 보여야 하는 경우(e.g. Vault)
export const useGetContract = (contractName: ContractNameT) => {
  const { isActive, signer } = useWallet();
  const { chainId } = useChainId();
  const [contract, setContract] = useState<ContractT>();
  const [provider, setProvider] = useState<JsonRpcProvider>();

  useEffect(() => {
    if (!isActive) {
      const rpcUrl = getRpcUrl(chainId);
      // _provider = new JsonRpcProvider(rpcUrl);
      setProvider(new JsonRpcProvider(rpcUrl));
    }
  }, [chainId, isActive]);

  useEffect(() => {
    try {
      const fromTokenAddress = getContract(chainId, "CollateralToken");

      if (!signer && !provider) return;

      const result =
        contractName === "MockUSDC"
          ? getTContract(
              chainId,
              contractName,
              signer || provider,
              fromTokenAddress
            )
          : getTContract(chainId, contractName, signer || provider);

      setContract(result);
    } catch (e) {}
  }, [chainId, contractName, isActive, provider, signer]);

  return contract;
};

interface ICollateralContractTypedDomain {
  name: string;
  version: string;
  chainId: number;
  verifyingContract: string;
}

/**
 * * type domain을 위해서 USDC name, version 필요
 *
 * * arbitrum USDC contract - name(), version() 존재 / eip712Domain() 없음
 * * zkSync USDC contract - eip712Domain() 존재
 * * zkSync USDC contract에도 name()이 있지만 eip712Domain().name과 다름 (version()은 없음)
 *
 * * 현재 changerio 컨트랙트에서 제공하는 MockUSDC abi는 openzeppelin ERC20 (eip712Domain() 존재 / version() 없음)
 * * 그래서 version property 추가한 타입 정의
 */
// export type IUsdcForArbitrumAndzkSync = MockUSDC & { version: () => Promise<string> };

export const getUsdcDomainParams = async (
  chainId: number,
  collateralContract,
  signer
) => {
  if (!collateralContract) return;

  switch (chainId) {
    case ARBITRUM: {
      const contract = new ethers.Contract(
        collateralContract.address,
        ArbitrumUSDC.abi,
        signer
      );
      const name = await collateralContract.name();
      // const name = await contract.name();

      const version = await contract.version();

      return {
        name,
        version,
      };
    }
    case ZKSYNC: {
      const { name, version } = await collateralContract.eip712Domain();

      return {
        name,
        version,
      };
    }
    default: {
      const name = await collateralContract.name();

      return {
        name,
        version: "1",
      };
    }
  }
};

export const useGetTypedDomain = () => {
  const { chainId } = useChainId();
  const { signer } = useWallet();

  const tradingContractAddress = useMemo(() => {
    return getContract(chainId, "Trading");
  }, [chainId]);
  const fromTokenAddress = useMemo(() => {
    return getContract(chainId, "CollateralToken");
  }, [chainId]);
  const collateralContract = useGetContract("MockUSDC") as MockUSDC;
  const [_collateralContractTypedDomain, _setCollateralContractTypedDomain] =
    useState<ICollateralContractTypedDomain>();

  useEffect(() => {
    const setCollateralContractTypedDomain = async () => {
      try {
        const result = await getUsdcDomainParams(
          chainId,
          collateralContract,
          signer
        );
        if (!result) return;

        const { name, version } = result;

        _setCollateralContractTypedDomain({
          name,
          version,
          chainId,
          verifyingContract: fromTokenAddress,
        });
      } catch (e) {
        // console.log(e);
      }
    };

    setCollateralContractTypedDomain();
  }, [chainId, collateralContract, fromTokenAddress, signer]);

  const tradingContractTypedDomain = useMemo(() => {
    return {
      name: "Gambit Trading V1",
      version: "1",
      chainId,
      verifyingContract: tradingContractAddress,
    };
  }, [chainId, tradingContractAddress]);

  return {
    tradingContractTypedDomain,
    collateralContractTypedDomain: _collateralContractTypedDomain,
  };
};

// * minLevPosUsdc, delegationFeeThresholdMultiplier는 formatted된 값
export const useDelegationFeeThreshhold = (
  minLevPosUsdc?: string,
  delegationFeeThresholdMultiplier?: BigNumber
) => {
  const result = useMemo(() => {
    if (!minLevPosUsdc || !delegationFeeThresholdMultiplier) return;

    return new BN(minLevPosUsdc)
      .multipliedBy(delegationFeeThresholdMultiplier.toNumber())
      .toNumber();
  }, [delegationFeeThresholdMultiplier, minLevPosUsdc]);

  return result;
};

// * minLevPosUsdc, delegationFeeThresholdMultiplier는 formatted된 값
export const getDelegationFeeThreshhold = (
  minLevPosUsdc?: string,
  delegationFeeThresholdMultiplier?: BigNumber
) => {
  if (!minLevPosUsdc || !delegationFeeThresholdMultiplier) return;

  return new BN(minLevPosUsdc)
    .multipliedBy(delegationFeeThresholdMultiplier.toNumber())
    .toNumber();
};
