import { Web3AuthNoModal } from "@web3auth/no-modal";
import { DEFAULT_CHAIN_ID, SUPPORTED_CHAIN_IDS } from "config/chains";
import { SELECTED_NETWORK_LOCAL_STORAGE_KEY } from "config/localStorage";
import { providers } from "ethers";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  useAccount,
  useNetwork,
  useWalletClient,
  type WalletClient,
} from "wagmi";

export function walletClientToSigner(walletClient: WalletClient) {
  const { account, chain, transport } = walletClient;
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  };
  const provider = new providers.Web3Provider(transport, network);
  const signer = provider.getSigner(account.address);
  return signer;
}

export default function useWallet() {
  const { address, isConnected, connector } = useAccount();
  const { chain } = useNetwork();
  const { data: walletClient } = useWalletClient();

  const isWeb3AuthAccount = useMemo(() => {
    if (!connector) return;

    return connector.id === "web3auth";
  }, [connector]);

  // if (isConnected && chain && !isWeb3AuthAccount) {
  //   localStorage.setItem(SELECTED_NETWORK_LOCAL_STORAGE_KEY, String(chain?.id));
  // }

  // TODO: useWallet에 있는 chainId 필요 여부 검토(useChainId의 chainId로 모두 변경)
  const chainId = useMemo(() => {
    if (chain) return chain.id;

    const chainIdFromLocalStorage = localStorage.getItem(
      SELECTED_NETWORK_LOCAL_STORAGE_KEY
    );
    if (chainIdFromLocalStorage) {
      const _chainId = parseInt(chainIdFromLocalStorage);
      if (!_chainId) {
        return DEFAULT_CHAIN_ID;
      }
      return _chainId;
    }
    return DEFAULT_CHAIN_ID;
  }, [chain]);

  useEffect(() => {
    if (chain?.id && SUPPORTED_CHAIN_IDS.includes(chain.id)) {
      localStorage.setItem(
        SELECTED_NETWORK_LOCAL_STORAGE_KEY,
        String(chain.id)
      );
    }
  }, [chain?.id]);

  const ethersSigner = useMemo(
    () => (walletClient ? walletClientToSigner(walletClient) : undefined),
    [walletClient]
  );

  const [web3authEmail, setWeb3authEmail] = useState("");

  const getWeb3authEmail = useCallback(async () => {
    if (!connector || !isWeb3AuthAccount) return;

    const userInfo = await (
      (connector as any).web3AuthInstance as Web3AuthNoModal
    ).getUserInfo();

    setWeb3authEmail(userInfo.email || "");
  }, [connector, isWeb3AuthAccount]);

  useEffect(() => {
    getWeb3authEmail();
  }, [getWeb3authEmail]);

  return {
    account: address,
    isActive: isConnected,
    connector,
    chainId,
    signer: ethersSigner ?? undefined,
    isWeb3AuthAccount,
    web3authEmail,
  };
}
