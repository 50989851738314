import { Trans } from "@lingui/macro";
import cx from "classnames";
import { Styleit } from "freestyler/lib/react/styleit";
import Slider from "rc-slider";
import { memo, useMemo, useRef } from "react";
import { useMeasure } from "react-use";
import { Focused } from "./TradeBoxV2";

export const LeverageSlider = memo(
  ({
    leverageOption, setLeverageOption, minLeverage, maxLeverage, isLong, isShort, leverageMarks, focused, setFocused,
  }: {
    leverageOption: number | undefined;
    setLeverageOption: (num: number) => void;
    minLeverage: number;
    maxLeverage: number;
    isLong: boolean;
    isShort: boolean;
    leverageMarks: {
      [x: number]: string;
    };
    focused: Focused;
    setFocused: (focused: Focused) => void;
  }) => {
    const [levContainerRef, { width }] = useMeasure<HTMLDivElement>();
    const maxWidth = useMemo(() => {
      return width - 20;
      // return ((width - 30) / width) * 100;
    }, [width]);
    const leverageRef = useRef<HTMLInputElement>(null);

    return (
      <div ref={levContainerRef} className="py-[1.2rem]">
        <div className="flex justify-between items-center">
          <span className="text-[1.4rem] font-medium text-white opacity-50">
            <Trans id="msg.tradeBox / Leverage slider">Leverage slider</Trans>
          </span>
          {/* leverage slider input */}
          <div
            className={cx(
              "group rounded-[4px]",
              "border border-1 border-gray-40",
              "hover:bg-gradient-to-r hover:from-white/50 hover:to-white hover:border-0 hover:p-[1px]",
              {
                "bg-gradient-to-r from-white/50 to-white border-0 p-[1px]": focused.leverage,
              }
            )}
          >
            <div
              onClick={() => {
                leverageRef.current?.focus();
              }}
              className={cx(
                "flex items-center justify-center rounded-[3.6px] p-[6.5px] heading9 text-gray-05 w-[6.7rem] bg-gray-80"
              )}
            >
              <input
                id="leverage-input"
                ref={leverageRef}
                type="text"
                pattern="[0-9]*"
                inputMode="decimal"
                maxLength={4}
                className="font-space-grotesk w-[4.4rem] text-right"
                value={leverageOption ? leverageOption : ""}
                onBlur={(e) => {
                  setFocused({ ...focused, leverage: false });
                  if (!e.target.value) {
                    setLeverageOption(minLeverage);
                  }
                }}
                onChange={(e) => {
                  if (!/^([0-9]*(?:[.,][0-9]*)?)$/.test(e.target.value)) {
                    return;
                  }
                  setLeverageOption(parseFloat(e.target.value));

                  if (maxLeverage && +e.target.value > maxLeverage) {
                    setLeverageOption(maxLeverage);
                  }
                }}
                onFocus={(e) => {
                  setFocused({ ...focused, leverage: true });
                  e.target.select();
                }} />
              <span>X</span>
            </div>
          </div>
        </div>

        <div
          className={cx("Exchange-leverage-slider", "App-slider", {
            positive: isLong,
            negative: isShort,
          })}
        >
          <Styleit
            css={{
              width: `${maxWidth}px`,
              "margin-left": "auto",
              "margin-right": "auto",
            }}
          >
            <Slider
              min={minLeverage}
              max={maxLeverage}
              step={1}
              marks={leverageMarks}
              // handle={leverageSliderHandle}
              onChange={(value) => setLeverageOption(value as number)}
              value={leverageOption} />
          </Styleit>
        </div>
      </div>
    );
  }
);
