import {
  DEFAULT_CHAIN_ID,
  getChainNameForMenu,
  isSupportedChain,
} from "config/chains";
import { useEffect } from "react";

import { Trans } from "@lingui/macro";
import {
  SELECTED_NETWORK_LOCAL_STORAGE_KEY,
  SELECTED_WALLET_LOCALSTORAGE_KEY,
  WALLET_CONNECT_LOCALSTORAGE_KEY,
  WALLET_LINK_LOCALSTORAGE_PREFIX,
} from "config/localStorage";
import { helperToast } from "../helperToast";

import { TOASTIFY_ID } from "components/Exchange/constants";

import { switchNetwork as switchNetworkWagmi } from "@wagmi/core";
import { WALLET_TYPES } from "futures-domain/types";
import { toast } from "react-toastify";
import useWallet from "./useWallet";

export type NetworkMetadata = {
  chainId: string; // "0x..."
  chainName: string;
  nativeCurrency: {
    name: string;
    symbol: string;
    decimals: number;
  };
  rpcUrls: string[];
  blockExplorerUrls: string[];
};

export type NewNetworkMetadata = {
  chainId: number;
  chainName: string;
  nativeCurrency: {
    name: string;
    symbol: string;
    decimals: number;
  };
  rpcUrls: string[];
  blockExplorerUrls: string[];
};

export function hasMetaMaskWalletExtension() {
  return window.ethereum;
}

export function hasCoinBaseWalletExtension() {
  const { ethereum } = window;

  if (!ethereum?.providers && !ethereum?.isCoinbaseWallet) {
    return false;
  }

  return (
    window.ethereum.isCoinbaseWallet ||
    ethereum.providers.find(({ isCoinbaseWallet }) => isCoinbaseWallet)
  );
}

export function clearWalletConnectData() {
  localStorage.removeItem(WALLET_CONNECT_LOCALSTORAGE_KEY);
}

export function clearWalletLinkData() {
  Object.entries(localStorage)
    .map((x) => x[0])
    .filter((x) => x.startsWith(WALLET_LINK_LOCALSTORAGE_PREFIX))
    .map((x) => localStorage.removeItem(x));
}

// export function useEagerConnect() {
//   const { isActive } = useWallet();

//   const selectedWallet = localStorage.getItem(SELECTED_WALLET_LOCALSTORAGE_KEY);

//   useEffect(() => {
//     if (isActive) return;

//     switch (selectedWallet) {
//       case WALLET_TYPES.METAMASK:
//         void metaMask.connectEagerly().catch(() => {
//           // console.debug("Failed to connect eagerly to metamask");
//         });
//         return;
//       case WALLET_TYPES.COINBASE:
//         void coinbaseWallet.connectEagerly().catch(() => {
//           // console.debug('Failed to connect eagerly to coinbase wallet')
//         });
//         return;
//       case WALLET_TYPES.WALLET_CONNECT_V2:
//         walletConnectV2.connectEagerly().catch((error) => {
//           // console.debug("Failed to connect eagerly to walletconnect", error);
//         });
//         return;
//     }

//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []); // intentionally only running on mount (make sure it's only mounted once :))
// }

export const addNetwork = async (metadata: NetworkMetadata) => {
  await window.ethereum
    .request({ method: "wallet_addEthereumChain", params: [metadata] })
    .catch();
};

// // TODO: v8로 마이그레이션 -> NETWORK_METADATA 제거
// export const switchNetwork = async (chainId: number, active?: boolean) => {
//   if (!active) {
//     // chainId in localStorage allows to switch network even if wallet is not connected
//     // or there is no wallet at all
//     localStorage.setItem(SELECTED_NETWORK_LOCAL_STORAGE_KEY, String(chainId));
//     document.location.reload();
//     return;
//   }

//   try {
//     const chainIdHex = "0x" + chainId.toString(16);
//     await window.ethereum.request({
//       method: "wallet_switchEthereumChain",
//       params: [{ chainId: chainIdHex }],
//     });
//     toast.dismiss(TOASTIFY_ID.WALLET_NOT_CONNECTED);
//     helperToast.success(
//       <div className="toastify-body-only">
//         <Trans>Connected To {getChainNameForMenu(chainId)}</Trans>
//       </div>,
//       {
//         autoClose: 5000,
//       }
//     );
//     return getChainNameForMenu(chainId);
//   } catch (ex: any) {
//     // https://docs.metamask.io/guide/rpc-api.html#other-rpc-methods
//     // This error code indicates that the chain has not been added to MetaMask.
//     // 4001 error means user has denied the request
//     // If the error code is not 4001, then we need to add the network
//     if (ex.code !== 4001) {
//       return await addNetwork(NETWORK_METADATA[chainId]);
//     }

//     // eslint-disable-next-line no-console
//     console.error("error", ex);
//   }
// };

// export const useGambitSwitchNetwork = () => {
//   const { chain } = useNetwork();
//   const { chains, error, isLoading, pendingChainId, switchNetwork } = useSwitchNetwork();
//   const { isActive: active } = useWallet();

//   const switchGambitNetwork = useCallback(
//     async (chainId: number) => {
//       if (active) {
//         console.log(1);
//         if (switchNetwork) {
//           await switchNetwork(chainId);
//         }
//       } else {
//         console.log(2);
//         // chainId in localStorage allows to switch network even if wallet is not connected
//         // or there is no wallet at all
//         localStorage.setItem(SELECTED_NETWORK_LOCAL_STORAGE_KEY, String(chainId));
//         document.location.reload();
//         return;
//       }
//     },
//     [active, switchNetwork]
//   );

//   return { switchNetwork: switchGambitNetwork };
// };

export async function switchNetwork(
  chainId: number,
  active: boolean,
  isWeb3AuthAccount: boolean | undefined
) {
  /**
   * * TEMP:
   * * web3auth network 변환이 정상 동작안해서 임시 로직 추가
   * * 추후에 @web3auth/web3auth-wagmi-connector 패키지 업데이트 여부 확인해서 테스트
   */
  if (isWeb3AuthAccount) {
    localStorage.setItem(SELECTED_NETWORK_LOCAL_STORAGE_KEY, String(chainId));
    document.location.reload();
    return;
  }

  if (active) {
    // TODO: connector 체크
    try {
      await switchNetworkWagmi({ chainId });
      localStorage.setItem(SELECTED_NETWORK_LOCAL_STORAGE_KEY, String(chainId));
    } catch (e) {
      // throw e;
    }
  } else {
    // const result = await switchNetworkWagmi({ chainId });
    // console.log(result);
    // chainId in localStorage allows to switch network even if wallet is not connected
    // or there is no wallet at all
    localStorage.setItem(SELECTED_NETWORK_LOCAL_STORAGE_KEY, String(chainId));

    const localStorageChainId = localStorage.getItem(
      SELECTED_NETWORK_LOCAL_STORAGE_KEY
    );
    if (localStorageChainId && chainId === +localStorageChainId) {
      document.location.reload();
    }

    return;
  }
}

export async function addTokenToMetamask(token: {
  address: string;
  symbol: string;
  decimals: number;
  imageUrl?: string;
}) {
  try {
    const wasAdded = await window.ethereum.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20",
        options: {
          address: token.address,
          symbol: token.symbol,
          decimals: token.decimals,
          image: token.imageUrl,
        },
      },
    });
    if (wasAdded) {
      // https://github.com/MetaMask/metamask-extension/issues/11377
      // We can show a toast message when the token is added to metamask but because of the bug we can't. Once the bug is fixed we can show a toast message.
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export function showUnsupportedNetworkToast(
  IsWalletConnectV2: boolean,
  isWeb3AuthAccount: boolean | undefined
) {
  let chainId =
    Number(localStorage.getItem(SELECTED_NETWORK_LOCAL_STORAGE_KEY)) ||
    DEFAULT_CHAIN_ID;
  if (!isSupportedChain(chainId)) {
    chainId = DEFAULT_CHAIN_ID;
  }

  // * web3auth 연결 시 네트워크 변경 토스트 생략
  // * TODO: web3auth-wagmi-connector에서 네트워크 변경 지원하면 필요 여부 재검토
  if (isWeb3AuthAccount) return;

  helperToast.error(
    <div>
      <div className="toastify-title">
        <Trans>
          Your wallet is not connected to {getChainNameForMenu(chainId)}.
        </Trans>
      </div>
      {/* 
        연결된 지갑이 Wallet Connect인 경우 switchNetwork 생략
        Wallet Connect에서 프론트(웹)에서 chain 변경 -> 지갑에서 chain 변경 미지원
        TODO: 프론트(웹)에서 chain 변경 -> 지갑에서 chain 변경 팔로우 업 
        지원 확인 후 switchNetwork 제공
      */}
      {IsWalletConnectV2 ? (
        <div className="toastify-body">
          <Trans>
            Switch to {getChainNameForMenu(chainId)} in your wallet app
          </Trans>
        </div>
      ) : (
        <div
          className="clickable underline toastify-body"
          onClick={() => switchNetwork(chainId, true, isWeb3AuthAccount)}
        >
          <Trans>Switch to {getChainNameForMenu(chainId)}</Trans>
        </div>
      )}
    </div>,
    {
      toastId: TOASTIFY_ID.WALLET_NOT_CONNECTED,
    }
  );
}

// isActive 상태이면서 chainId가 supportedChainIds에 속하지 않는 경우
export function useHandleUnsupportedNetwork() {
  const { connector, isActive, isWeb3AuthAccount } = useWallet();
  let { chainId } = useWallet();

  useEffect(() => {
    if (isActive && !isSupportedChain(chainId)) {
      const IsWalletConnectV2 =
        localStorage.getItem(SELECTED_WALLET_LOCALSTORAGE_KEY) ===
        WALLET_TYPES.WALLET_CONNECT_V2;
      showUnsupportedNetworkToast(IsWalletConnectV2, isWeb3AuthAccount);
    } else if (
      isActive &&
      chainId &&
      toast.isActive(TOASTIFY_ID.WALLET_NOT_CONNECTED)
    ) {
      toast.dismiss(TOASTIFY_ID.WALLET_NOT_CONNECTED);
      helperToast.success(
        <div className="toastify-body-only">
          <Trans>Connected To {getChainNameForMenu(chainId)}</Trans>
        </div>,
        {
          autoClose: 5000,
        }
      );
    }
  }, [chainId, connector, isActive, isWeb3AuthAccount]);
}

// export const activateWallet = async (walletName: string) => {
//   const chainId = window.localStorage.getItem(SELECTED_NETWORK_LOCAL_STORAGE_KEY) || DEFAULT_CHAIN_ID.toString();

//   if (walletName === WALLET_TYPES.WALLET_CONNECT_V2) {
//     // TODO: Wallet Connect에서 network 추가 기능 구현되는 지 팔로우 업
//     // await walletConnectV2.activate(NEW_NETWORK_METADATA[chainId]);
//     await walletConnectV2.activate(+chainId);
//     return;
//   }

//   const { ethereum } = window;

//   if (!ethereum?.providers && !ethereum?.isCoinbaseWallet && !ethereum?.isMetaMask) {
//     return undefined;
//   }

//   switch (walletName) {
//     case WALLET_TYPES.COINBASE:
//       await coinbaseWallet.activate(NEW_NETWORK_METADATA[chainId]);
//       break;
//     case WALLET_TYPES.METAMASK:
//     default:
//       await metaMask.activate(NEW_NETWORK_METADATA[chainId]);
//       break;
//   }
// };
