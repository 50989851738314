import { Trans } from "@lingui/macro";
import { getConstantVersion } from "App/App";
import { showSignInModalAtom, showWalletAccountAtom } from "atoms";
import cx from "classnames";
import LinksDropdown from "components/LinksDropdown/LinksDropdown";
import { SUPPORTED_CHAIN_IDS, getChainNameForMenu } from "config/chains";
import { getIcon } from "config/icons";
import dayjs from "dayjs";
import { APP_MODES } from "futures-domain/types";
import { useChainId } from "futures-lib/chains";
import { useENS } from "futures-lib/legacy";
import { switchNetwork } from "futures-lib/wallets";
import useWallet from "futures-lib/wallets/useWallet";
import AvatarImage from "img/ic-avatar.svg";
import HoveringAvatarImage from "img/ic-hover-avatar.svg";
import { useSetAtom } from "jotai";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import { useHoverDirty } from "react-use";
import { useVersion } from "utils/hooks";
import { useAccount } from "wagmi";
import NetworkDropdown from "../NetworkDropdown/NetworkDropdown";
import "./Header.css";

const NETWORK_OPTIONS = SUPPORTED_CHAIN_IDS.map((chainId) => {
  return {
    label: getChainNameForMenu(chainId),
    value: chainId,
    icon: getIcon(chainId, "network"),
  };
});

export function AppHeaderUser() {
  const { chainId } = useChainId();
  const {
    isActive,
    account,
    chainId: walletChainId,
    isWeb3AuthAccount,
  } = useWallet();
  const { connector } = useAccount();
  // const [version, setVersion] = useState<string>();

  const setShowSignInModal = useSetAtom(showSignInModalAtom);
  const setShowWalletAccount = useSetAtom(showWalletAccountAtom);

  const onNetworkSelect = useCallback(
    (option) => {
      if (option.value === chainId && walletChainId === chainId) {
        return;
      }
      switchNetwork(option.value, isActive, isWeb3AuthAccount);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chainId, isActive, walletChainId, connector]
  );

  const selectorLabel = useMemo(() => {
    return getChainNameForMenu(chainId);
  }, [chainId]);

  const ref = useRef(null);
  const isHovering = useHoverDirty(ref);

  const { ensName } = useENS(account);

  const { data: versionInDB } = useVersion();

  const [needUpdate, setNeedUpdate] = useState(false);

  useEffect(() => {
    if (
      process.env.REACT_APP_MODE !== APP_MODES.LOCAL &&
      versionInDB &&
      dayjs(versionInDB).unix() > dayjs(getConstantVersion()).unix()
    ) {
      setNeedUpdate(true);
    }
  }, [versionInDB]);

  const clearCache = () => {
    caches.keys().then((cacheNames) => {
      // cacheNames.forEach((cacheName) => {
      //   caches.delete(cacheName);
      // });
      for (const cacheName of cacheNames) {
        caches.delete(cacheName);
      }

      window.location.reload();
    });
  };

  return (
    <div className="App-header-user gap-[0.95rem] sm:gap-[1.2rem]">
      {/* {chainId === ZKSYNC && (
        <a
          className={cx(
            "h-[3.6rem] rounded-[999px] bg-brand-1 !flex items-center px-[1.6rem]",
            "text-black-1 text-[1.3rem] font-medium",
            "bg-gradient-to-r from-brand-0 to-brand-1",
            "ml-[1rem]"
          )}
          href={
            currentLanguage === "ko"
              ? "https://event.gambit.trade/v/usdc-event-kr/"
              : "https://event.gambit.trade/v/usdc-event/"
          }
          target="_blank"
          rel="noreferrer"
        >
          <span className="hidden xs-:inline">
            <Trans>{fromToken.symbol.toUpperCase()} Staking Event</Trans>
          </span>
          <span className="xs-:hidden">
            <Trans>{fromToken.symbol.toUpperCase()} Event</Trans>
          </span>
        </a>
      )} */}

      {/* <Link
        className={cx(
          "h-[3.6rem] rounded-[999px] bg-brand-1 !flex items-center px-[1.6rem]",
          "text-black-1 text-[1.3rem] font-medium",
          "bg-gradient-to-r from-brand-0 to-brand-1",
          "ml-[1rem]"
        )}
        to="/trading-competition"
      >
        <span className="hidden xs-:block text-center">
          <Trans>Trading Event</Trans>
        </span>

        <div className="xs-:hidden space-y-[-4px]">
          <Trans>
            <div className="text-center">
              <Trans>Trading</Trans>
            </div>
            <div className="text-center">
              <Trans>Event</Trans>
            </div>
          </Trans>
        </div>
      </Link> */}

      {/* <Link
        className={cx(
          "mx-auto rounded-[6px] bg-gradient-to-r from-brand-0 to-brand-1 p-[1px]"
        )}
        to="/trading-competition"
      >
        <div className="flex items-center justify-center bg-gray-90 back rounded-[6px]">
          <div className={cx("pre-animate-shine animate-shine")}>
            <div
              className={cx(
                "heading8 font-medium h-[3.6rem] px-[.8rem] xs:px-[1.6rem]",
                "bg-gradient-to-r from-brand-0 to-brand-1 inline-block text-transparent bg-clip-text",
                "flex items-center justify-center text-center"
              )}
            >
              Trading Event
            </div>
          </div>
        </div>
      </Link> */}

      {!needUpdate && (
        <>
          {/* Mobile */}
          <a
            className={cx(
              "text-black-1 text-[1.3rem] font-medium",
              "bg-gradient-to-r from-brand-0 to-brand-1",
              "bg-brand-1 hover:bg-gray-70 flex xs:hidden items-center font-space-grotesk px-[.8rem] py-[2px] rounded-[4px] h-[3.6rem] text-[1.3rem]"
            )}
            href={"https://feeturn.com"}
            target="_blank"
            rel="noreferrer"
          >
            <div className={cx(needUpdate || !isActive ? "" : "flex")}>
              <div className="text-[1.2rem] xs:text-[1.3rem] text-center mr-[3px]">
                Get Cashback
              </div>
              {/* <div className="text-[1.2rem] xs:text-[1.3rem] text-center">
            at Feeturn
          </div> */}
            </div>
          </a>

          {/* Desktop */}
          <a
            className={cx(
              "text-black-1 text-[1.3rem] font-medium",
              "bg-gradient-to-r from-brand-0 to-brand-1",
              "bg-brand-1 hover:bg-gray-70 hidden xs:flex items-center font-space-grotesk px-[1.6rem] py-[2px] rounded-[4px] h-[3.6rem] text-[1.3rem]"
            )}
            href={"https://feeturn.com"}
            target="_blank"
            rel="noreferrer"
          >
            {/* <div className="text-center min-w-[113px]">Get Cashback at Feeturn</div> */}
            <div className="text-center">Get Cashback</div>
          </a>
        </>
      )}

      {needUpdate && (
        <div>
          {/* Mobile */}
          <button
            onClick={clearCache}
            className={cx(
              "text-black-1 text-[1.3rem] font-medium",
              "bg-gradient-to-r from-brand-0 to-brand-1",
              "bg-brand-1 hover:bg-gray-70 flex xs:hidden items-center font-space-grotesk px-[.8rem] py-[2px] rounded-[4px] h-[3.6rem] text-[1.3rem]"
            )}
          >
            <div className="flex flex-col justify-center">
              <div className="text-[1.2rem] xs:text-[1.3rem]">Update</div>
              <div className="text-[1.2rem] xs:text-[1.3rem]">Available</div>
            </div>
          </button>

          {/* Desktop */}
          <button
            onClick={clearCache}
            className={cx(
              "text-black-1 text-[1.3rem] font-medium",
              "bg-gradient-to-r from-brand-0 to-brand-1",
              "bg-brand-1 hover:bg-gray-70 hidden xs:flex items-center font-space-grotesk px-[1.6rem] py-[2px] rounded-[4px] h-[3.6rem] text-[1.3rem]"
            )}
          >
            Update Available
          </button>
        </div>
      )}

      {!isActive || !account ? (
        <div
          className={cx(
            "bg-black-3 hover:bg-gray-70 flex items-center justify-center h-[3.6rem] border",
            "border-white border-opacity-10 py-[1rem] px-[1.6rem] rounded-[4.2rem] ",
            "text-[1.3rem] font-medium cursor-pointer leading-[1.7rem]"
          )}
          onClick={() => {
            setShowSignInModal(true);
          }}
        >
          <span className="text-center">
            <Trans>Sign In</Trans>
          </span>
          {/* <span className="hidden sm:inline text-center">
            <Trans>Sign In</Trans>
          </span>
          <span className="sm:hidden">
            <Trans>Connect</Trans>
          </span> */}
        </div>
      ) : (
        <div className="relative">
          {/* <AddressDropdown
            account={account}
            accountUrl={accountUrl}
            disconnectAccountAndCloseSettings={disconnectAccountAndCloseSettings}
          /> */}

          <button
            onClick={() => {
              setShowWalletAccount(true);
            }}
            className={cx(
              "group",
              "bg-black-3 flex items-center sm:border border-white border-opacity-10 rounded-[4.2rem]",
              "sm:py-[6px] sm:px-[8px]",
              "hover:bg-gradient-to-r hover:from-brand-0 hover:to-brand-1"
            )}
          >
            <div className="user-avatar">
              {/* {ethereumProvider && <Davatar size={20} address={account} provider={ethereumProvider} />} */}
              {isActive && (
                <img
                  className="w-[2.4rem] h-[2.4rem]"
                  src={isHovering ? HoveringAvatarImage : AvatarImage}
                  alt="avatar"
                />
              )}
            </div>
            <span
              className={cx(
                "hidden sm:inline mx-[8px] text-[1.3rem] text-white font-medium",
                {
                  "font-space-grotesk": !ensName,
                },
                "group-hover:!text-black-1"
              )}
            >
              {ensName || `${account?.slice(0, 5)}...${account?.slice(-4)}`}
            </span>
            <div className="hidden sm:block">
              <FaChevronDown className="group-hover:text-black-1" />
            </div>
          </button>
        </div>
      )}

      <NetworkDropdown
        networkOptions={NETWORK_OPTIONS}
        selectorLabel={selectorLabel}
        onNetworkSelect={onNetworkSelect}
      />
      {/* <LanguagesDropdown /> */}
      <div className="hidden xs:block">
        <LinksDropdown />
      </div>
    </div>
  );
}
