import { commonVaultAtom } from "atoms/vault";
import { getAbi, getContract } from "config/contracts";
import { useChainId } from "futures-lib/chains";
import { gambitContractFetcherForCommonVault } from "futures-lib/contracts/gambitContractFetcher";
import useWallet from "futures-lib/wallets/useWallet";
import { useSetAtom } from "jotai";
import { useEffect } from "react";
import useSWR from "swr";

/**
 * 연결된 지갑과 무관하게 Vault의 일반정보(현재 Epoch 등)를 가져온다.
 */
export const useCommonVault = () => {
  const { isActive, signer } = useWallet();
  const { chainId } = useChainId();
  const vaultAddress = getContract(chainId, "Vault");
  const setCommonVault = useSetAtom(commonVaultAtom);

  const { data, isLoading, error } = useSWR(
    [isActive, chainId, vaultAddress, "useCommonVault"],
    {
      fetcher: gambitContractFetcherForCommonVault(
        signer,
        getAbi(chainId, "Vault")
      ),
      refreshInterval: 3000,
      errorRetryCount: 3000,
    }
  );

  useEffect(() => {
    if (!error && data && !isLoading) {
      // console.log(data);
      const [
        currentEpoch,
        currentEpochStart,
        withdrawEpochsTimelock,
        tvl,
        collateralizationP,
        gUsdcPrice,
      ] = data;
      // const [currentEpoch, currentEpochStart, withdrawEpochsTimelock, tvl, collateralizationP, availableAssets] = data;
      // console.log(formatAmount(tvl, 18, 2, true));
      // console.log(formatAmount(availableAssets, 18, 2, true));
      // console.log(availableAssets.toString());
      // console.log(collateralizationP.toString());
      // console.log(gUsdcPrice.toString());
      // console.log(gUsdcPrice / 1e18);

      setCommonVault({
        currentEpoch: currentEpoch?.toNumber(),
        currentEpochStart: currentEpochStart?.toNumber(),
        withdrawEpochsTimelock: withdrawEpochsTimelock?.toNumber(),
        tvl,
        collateralizationP,
        // gUsdcPrice: BigNumber.from(gUsdcPrice / 1e18), // * NOTE: '23.10.11) Contract에 물타기 기능이 추가되면서 gUsdcPrice decimal이 +18 되었음
        gUsdcPrice,
      });
    }
  }, [data, isLoading, error, setCommonVault, chainId]);
};
