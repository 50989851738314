import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { currentLanguageAtom, showSignInModalAtom } from "atoms";
import { referralAtom } from "atoms/referral";
import cx from "classnames";
import SEO from "components/Common/SEO";
import { isSupportedChain } from "config/chains";
import { getAbi, getContract } from "config/contracts";
import { getToken } from "config/tokens";
import { ethers } from "ethers";
import { useReferral } from "futures-domain/referral/useReferral";
import { getReferralRank } from "futures-domain/referral/utils";
import { useTrader } from "futures-domain/trades/useTrader";
import { useChainId } from "futures-lib/chains";
import { Opts, callContract } from "futures-lib/contracts";
import { helperToast } from "futures-lib/helperToast";
import { getPageTitle } from "futures-lib/legacy";
import { bigNumberify, formatAmount } from "futures-lib/numbers";
import useWallet from "futures-lib/wallets/useWallet";
import { ReactComponent as CoinBundleSvg } from "img/ic-coin_bundle_volume.svg";
import { ReactComponent as DollarSvg } from "img/ic-dollar_coin_total_profits.svg";
import googleFormImg from "img/ic-google_form.svg";
import { ReactComponent as HandCoinSvg } from "img/ic-hand_coin_unclaimed_rewards.svg";
import { ReactComponent as CopySvg } from "img/ic-link.svg";
import loader from "img/ic-loader.svg";
import { ReactComponent as UsersSvg } from "img/ic-people_users.svg";
import { ReactComponent as PurseTickSvg } from "img/ic-purse_tick_claimed_profits.svg";
import { ReactComponent as CheckboxSvg } from "img/ic-referral-checkbox.svg";
import { ReactComponent as TrophySvg } from "img/ic-trophy_rank.svg";
import { useAtomValue, useSetAtom } from "jotai";
import { useCallback, useState } from "react";

interface IsTxsubmitting {
  claimTxIsSubmitting: boolean;
}

export const ReferralsV2 = () => {
  useTrader();
  useReferral();

  const currentLanguage = useAtomValue(currentLanguageAtom);

  const initialIsTxsubmitting = {
    claimTxIsSubmitting: false,
  };

  const [isTxSubmitting, setIsTxSubmitting] = useState<IsTxsubmitting>(initialIsTxsubmitting);

  const { i18n } = useLingui();

  const referral = useAtomValue(referralAtom);
  const { chainId } = useChainId();
  const { isActive, account, signer, isWeb3AuthAccount } = useWallet();

  const setShowSignInModal = useSetAtom(showSignInModalAtom);

  const fromTokenAddress = getContract(chainId, "CollateralToken");
  const fromToken = getToken(chainId, fromTokenAddress);

  const claimRewards = useCallback(() => {
    if (!signer) return;

    setIsTxSubmitting({
      ...isTxSubmitting,
      claimTxIsSubmitting: true,
    });

    const contractAddress = getContract(chainId, "Referral");
    const contract = new ethers.Contract(contractAddress, getAbi(chainId, "Referral").abi, signer);

    callContract(chainId, contract, "claimReferrerRewards", [], {
      value: bigNumberify(0),
      setPendingTxns: undefined,
      sentMsg: "",
      failMsg: "",
      successMsg: "",
      hideSuccessMsg: true,
      isWeb3AuthAccount,
    } as Opts)
      .then(async () => {})
      .finally(() => {
        setIsTxSubmitting({
          ...isTxSubmitting,
          claimTxIsSubmitting: false,
        });
      });
  }, [chainId, isTxSubmitting, isWeb3AuthAccount, signer]);

  const isPrimaryEnabled = useCallback(() => {
    if (!isActive) {
      return true;
    }

    if (!referral) {
      return false;
    }

    if (isTxSubmitting.claimTxIsSubmitting) {
      return false;
    }

    if (referral.unclaimedRewards.lte(0)) {
      return false;
    }

    return true;
  }, [isActive, isTxSubmitting.claimTxIsSubmitting, referral]);

  const onClickPrimary = useCallback(() => {
    if (!isActive) {
      setShowSignInModal(true);
      return;
    }

    if (!referral) {
      return;
    }

    claimRewards();
  }, [isActive, referral, claimRewards, setShowSignInModal]);

  const getPrimaryText = useCallback(() => {
    if (!isSupportedChain(chainId)) {
      return t`Incorrect Network`;
    }

    if (!isActive) {
      return t`Sign In`;
    }

    return t`Claim Rewards`;
  }, [isActive, chainId]);

  function renderPrimaryButton() {
    const primaryTextMessage = getPrimaryText();

    return (
      <button className={cx("brand-btn")} onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
        {isTxSubmitting.claimTxIsSubmitting ? (
          <img className="mx-auto h-[0.8rem]" src={loader} alt="loader" />
        ) : (
          primaryTextMessage
        )}
      </button>
    );
  }
  // const location = useLocation();

  const handleCopyReferralLink = useCallback(() => {
    if (isActive && account && referral?.active) {
      try {
        // INFO: copy for https protocol
        if (navigator.clipboard) {
          const referralLink = `${window.location.origin}/trade?referrer-address=${account}`;
          navigator.clipboard.writeText(referralLink);

          helperToast.success(<div className="toastify-body-only">{t`Referral Link Copied`}</div>, {
            autoClose: 5000,
          });
        }
        // INFO: copy for http protocol (staging server)
        else if (document.queryCommandSupported("copy")) {
          const textarea = document.createElement("textarea");
          textarea.value = `${window.location.origin}/trade?referrer-address=${account}`;
          textarea.style.top = "0";
          textarea.style.left = "0";
          textarea.style.position = "fixed";

          document.body.appendChild(textarea);
          textarea.focus();
          textarea.select();
          document.execCommand("copy");
          document.body.removeChild(textarea);

          helperToast.success(<div className="toastify-body-only">{t`Referral Link Copied`}</div>, {
            autoClose: 5000,
          });
        } else {
          helperToast.error(<div className="toastify-body-only">{t`This environment does not support copying`}</div>, {
            autoClose: 5000,
          });
        }
      } catch (e) {
        helperToast.error(<div className="toastify-body-only">{t`Failed To Copy Referral Link`}</div>, {
          autoClose: 5000,
        });
      }
    }
  }, [isActive, account, referral?.active]);

  return (
    <SEO
      title={i18n._(
        getPageTitle(
          t({
            id: "msg.title / Referrals",
            message: "Referrals",
          })
        )
      )}
    >
      <div className="max-w-[148rem] mx-auto sm:pt-[3.5rem]">
        <div className="bg-black-2 border border-gray-70 rounded-[1.2rem]">
          <div className="flex flex-col items-center justify-center bg-gray-80 bg-opacity-95 rounded-t-[1.2rem] py-[1.1rem] gap-[1.1rem] sm:gap-[2.4rem] sm:py-[2rem] sm:flex-row">
            <h1 className="text-[1.6rem] font-medium text-center">
              <Trans>REFERRAL REWARDS DASHBOARD</Trans>
            </h1>
            {isActive && account && referral?.active && (
              <div
                onClick={handleCopyReferralLink}
                className="flex items-center bg-white bg-opacity-5 rounded-[2px] py-[3px] px-[8px] text-[1.3rem] text-gray-15 font-medium text-center cursor-pointer"
              >
                <CopySvg />
                <span className="ml-[4px]">
                  <Trans>Copy Referral Link</Trans>
                </span>
              </div>
            )}
            {isActive && account && !referral?.active && (
              <a
                href={
                  currentLanguage === "ko"
                    ? "https://forms.gle/zZtzmRjw1N6KP8B78"
                    : "https://forms.gle/VrSUNrVn3kUW5LEH6"
                }
                target="_blank"
                rel="ugc"
              >
                <span className="flex bg-white bg-opacity-5 rounded-[2px] py-[3px] px-[8px] text-[1.3rem] text-gray-15 font-medium text-center cursor-pointer">
                  <img className="mr-2" width={16} height={16} src={googleFormImg} alt="google form" />
                  <Trans>Apply to Become Ambassador</Trans>
                </span>
              </a>
            )}
          </div>

          <div
            className={
              cx()
              // "bg-[url('img/bg-referral-board-mobile.jpg')] bg-bottom md:bg-[url('img/bg-referral-board.jpg')] md:bg-right bg-cover"
            }
          >
            {/* Grid */}
            <div className="grid justify-center gap-[1.2rem] grid-cols-referral-1 pt-[1.6rem] pb-[2.4rem] sm:pt-[2.4rem] sm:pb-[3.2rem] md:grid-cols-referral-2 lg:grid-cols-referral-3">
              {/* Total Referred Users */}
              <div className="group max-w-[34.3rem] md:max-w-[38rem] bg-black-2 hover:bg-[url('img/bg-referral-card.jpg')] bg-cover border border-gray-70 rounded-[1.2rem] p-[2.4rem]">
                <div className="inline-block border border-white border-opacity-20 p-[1.05rem] rounded-[0.6rem]">
                  <UsersSvg className="fill-gray-20 group-hover:fill-brand-1" width={24} />
                </div>

                <div className="mt-[3.2rem] mb-[1.5rem] text-[1.3rem] text-gray-20 font-medium">
                  <Trans>Total Referred Users</Trans>
                </div>
                <div className="flex justify-between items-center">
                  <span className="font-space-grotesk text-[3.2rem] font-bold h-[4.8rem]">
                    {referral?.numOfTradersReferred}
                  </span>
                  {/* <div className="flex items-center bg-red-3 px-[6px] py-[4px] rounded-[3rem]">
                    <img className="mr-[0.5rem]" src={arrowUpSvg} alt="arrow up" width={15} />
                    <span className="text-[1.2rem] font-medium">20%</span>
                  </div> */}
                </div>
              </div>

              {/* Total Trade Volumes */}
              <div className="group max-w-[34.3rem] md:max-w-[38rem] bg-black-2 hover:bg-[url('img/bg-referral-card.jpg')] bg-cover border border-gray-70 rounded-[1.2rem] p-[2.4rem]">
                <div className="inline-block border border-white border-opacity-20 p-[1.05rem] rounded-[0.6rem]">
                  <CoinBundleSvg className="fill-gray-20 group-hover:fill-brand-1" width={24} />
                </div>

                <div className="mt-[3.2rem] mb-[1.5rem] text-[1.3rem] text-gray-20 font-medium">
                  <Trans>Total Trade Volumes</Trans>
                </div>
                <div className="flex justify-between items-center">
                  <span className="font-space-grotesk text-[3.2rem] font-bold h-[4.8rem]">
                    {referral && formatAmount(referral?.tradeVolumes, fromToken.decimals, 2, true)}
                  </span>
                  {/* <div className="flex items-center bg-red-3 px-[6px] py-[4px] rounded-[3rem]">
                    <img className="mr-[0.5rem]" src={arrowUpSvg} alt="arrow up" width={15} />
                    <span className="text-[1.2rem] font-medium">20%</span>
                  </div> */}
                </div>
              </div>

              {/* Total Profits */}
              <div className="group max-w-[34.3rem] md:max-w-[38rem] bg-black-2 hover:bg-[url('img/bg-referral-card.jpg')] bg-cover border border-gray-70 rounded-[1.2rem] p-[2.4rem]">
                <div className="inline-block border border-white border-opacity-20 p-[1.05rem] rounded-[0.6rem]">
                  <DollarSvg className="fill-gray-20 group-hover:fill-brand-1" width={24} />
                </div>

                <div className="mt-[3.2rem] mb-[1.5rem] text-[1.3rem] text-gray-20 font-medium">
                  <Trans>Total Profits</Trans>
                </div>
                <div className="flex justify-between items-center">
                  <span className="font-space-grotesk text-[3.2rem] font-bold h-[4.8rem]">
                    {referral && formatAmount(referral?.totalProfits, fromToken.decimals, 2, true)}
                  </span>
                  {/* <div className="flex items-center bg-red-3 px-[6px] py-[4px] rounded-[3rem]">
                    <img className="mr-[0.5rem]" src={arrowUpSvg} alt="arrow up" width={15} />
                    <span className="text-[1.2rem] font-medium">20%</span>
                  </div> */}
                </div>
              </div>

              {/* Referral Rank */}
              <div className="group max-w-[34.3rem] md:max-w-[38rem] bg-black-2 hover:bg-[url('img/bg-referral-card.jpg')] bg-cover border border-gray-70 rounded-[1.2rem] p-[2.4rem]">
                <div className="inline-block border border-white border-opacity-20 p-[1.05rem] rounded-[0.6rem]">
                  <TrophySvg className="fill-gray-20 group-hover:fill-brand-1" width={24} />
                </div>

                <div className="mt-[3.2rem] mb-[1.5rem] text-[1.3rem] text-gray-20 font-medium">
                  <Trans>Referral Rank</Trans>
                </div>
                <div className="flex justify-between items-center">
                  <span className="font-space-grotesk text-[3.2rem] font-bold h-[4.8rem]">
                    {referral && `${getReferralRank(referral?.active, referral?.rank)}`}
                  </span>
                  {/* <div className="flex items-center bg-red-3 px-[6px] py-[4px] rounded-[3rem]">
                    <img className="mr-[0.5rem]" src={arrowUpSvg} alt="arrow up" width={15} />
                    <span className="text-[1.2rem] font-medium">20%</span>
                  </div> */}
                </div>
              </div>

              {/* Claimed Profits */}
              <div className="group max-w-[34.3rem] md:max-w-[38rem] bg-black-2 hover:bg-[url('img/bg-referral-card.jpg')] bg-cover border border-gray-70 rounded-[1.2rem] p-[2.4rem]">
                <div className="inline-block border border-white border-opacity-20 p-[1.05rem] rounded-[0.6rem]">
                  <PurseTickSvg className="fill-gray-20 group-hover:fill-brand-1" width={24} />
                </div>

                <div className="mt-[3.2rem] mb-[1.5rem] text-[1.3rem] text-gray-20 font-medium">
                  <Trans>Claimed Profits</Trans>
                </div>
                <div className="flex justify-between items-center">
                  <span className="font-space-grotesk text-[3.2rem] font-bold h-[4.8rem]">
                    {referral && formatAmount(referral.claimedProfits, fromToken.decimals, 2, true)}
                  </span>
                  {/* <div className="flex items-center bg-red-3 px-[6px] py-[4px] rounded-[3rem]">
                    <img className="mr-[0.5rem]" src={arrowUpSvg} alt="arrow up" width={15} />
                    <span className="text-[1.2rem] font-medium">20%</span>
                  </div> */}
                </div>
              </div>

              {/* Unclaimed Rewards */}
              <div className="group max-w-[34.3rem] md:max-w-[38rem] bg-black-2 hover:bg-[url('img/bg-referral-card.jpg')] bg-cover border border-gray-70 rounded-[1.2rem] p-[2.4rem]">
                <div className="inline-block border border-white border-opacity-20 p-[1.05rem] rounded-[0.6rem]">
                  <HandCoinSvg className="fill-gray-20 group-hover:fill-brand-1" width={24} />
                </div>

                <div className="mt-[3.2rem] mb-[1.5rem] text-[1.3rem] text-gray-20 font-medium">
                  <Trans>Unclaimed Rewards</Trans>
                </div>
                <div className="flex justify-between items-center">
                  <span className="font-space-grotesk text-[3.2rem] font-bold h-[4.8rem]">
                    {referral && formatAmount(referral?.unclaimedRewards, fromToken.decimals, 2, true)}
                  </span>
                  {/* <div className="flex items-center bg-red-3 px-[6px] py-[4px] rounded-[3rem]">
                    <img className="mr-[0.5rem]" src={arrowUpSvg} alt="arrow up" width={15} />
                    <span className="text-[1.2rem] font-medium">20%</span>
                  </div> */}
                </div>
              </div>
            </div>

            {/* Text and Button */}
            <div className="bg-[url('img/bg-referral-reward-box.jpg')] bg-cover max-w-[34.3rem] mx-auto rounded-[1.2rem] md:max-w-[77.2rem] lg:max-w-[116.4rem]">
              <div className="max-w-[34.3rem] md:max-w-[42rem] mx-auto px-[2.4rem] py-[2.4rem] md:py-[2.6rem] lg:py-[3rem] mb-[2.4rem] md:mb-[3.4rem] lg:mb-[4.7rem]">
                <div className="flex flex-col gap-[1rem] md:gap-[0.4rem] md:flex-row justify-between items-center text-[1.5rem] md:text-[1.6rem] font-medium">
                  <div className="flex items-center text-gray-15">
                    <span className="mr-[8px]">
                      <CheckboxSvg />
                    </span>
                    <Trans>Unclaimed Rewards</Trans>
                  </div>
                  <span className="font-space-grotesk text-white heading1">
                    {referral && `${formatAmount(referral?.unclaimedRewards, fromToken.decimals, 2, true)}`}{" "}
                    <span className="text-[1.5rem]">{fromToken.symbol.toUpperCase()}</span>
                  </span>
                </div>
                <div className="pt-[1.6rem] md:pt-[1.8rem] lg:pt-[2rem]">{renderPrimaryButton()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SEO>
  );
};
