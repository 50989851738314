import {
  currentLanguageAtom,
  selectedWeb3authConnectorAtom,
  termsAgreementModalVisibleAtom,
} from "atoms";
import ModalSkeletonWithPortal from "components/Modal/ModalSkeletonWithPortal";

import checkedCheckboxImg from "img/ic-checkbox_checked.svg";
import emptyCheckboxImg from "img/ic-checkbox_empty.svg";

import { useAtom, useAtomValue } from "jotai";
// import { Trans } from "@lingui/macro";

import { ScrollShadow } from "@nextui-org/scroll-shadow";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import cx from "classnames";
import { CHECK_TERMS_AND_CONDITIONS_AGREEMENT } from "config/localStorage";
import { memo, useCallback, useEffect, useState } from "react";
import { useLocalStorage, useMedia } from "react-use";
import { useConnect } from "wagmi";

export const TermsAgreementModal = memo(() => {
  const [isVisible, setIsVisible] = useAtom(termsAgreementModalVisibleAtom);
  const { openConnectModal } = useConnectModal();
  const { connect } = useConnect();

  const [checkedTermsAndConditions, setCheckedTermsAndConditions] =
    useLocalStorage(CHECK_TERMS_AND_CONDITIONS_AGREEMENT, false);
  const [checked, setChecked] = useState(checkedTermsAndConditions);

  const currentLanguage = useAtomValue(currentLanguageAtom);

  const [selectedWeb3authConnector, setSelectedWeb3authConnector] = useAtom(
    selectedWeb3authConnectorAtom
  );

  const handleClickCheck = useCallback(() => {
    setChecked(!checked);
  }, [checked, setChecked]);

  const handleClickAgree = useCallback(() => {
    setIsVisible(false);

    if (checked) {
      setCheckedTermsAndConditions(checked);
    }

    if (selectedWeb3authConnector) {
      setSelectedWeb3authConnector(null);
      connect({ connector: selectedWeb3authConnector });
    } else {
      if (openConnectModal) openConnectModal();
    }
  }, [
    checked,
    connect,
    openConnectModal,
    selectedWeb3authConnector,
    setCheckedTermsAndConditions,
    setIsVisible,
    setSelectedWeb3authConnector,
  ]);

  useEffect(() => {
    if (!isVisible) {
      setSelectedWeb3authConnector(null);
    }
  }, [isVisible, setSelectedWeb3authConnector]);

  const xxsPlus = useMedia("(max-width: 397px)");

  return (
    <ModalSkeletonWithPortal
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      mobileBottom={xxsPlus}
    >
      <div
        className={cx(
          "min-w-[36rem] max-w-[39.8rem] bg-gray-70 xxs+:bg-gray-90 bg-opacity-95",
          currentLanguage === "ko" ? "font-pretendard" : "font-space-grotesk"
        )}
      >
        {/* Title */}
        <div className="pt-[1.6rem] px-[2rem] text-[2rem] font-medium">
          {/* <Trans>Terms of Service Agreement</Trans> */}
          Terms of Service Agreement
        </div>

        <hr className="h-px my-[1.2rem] bg-gray-70 border-0" />

        <div className="px-[2rem] pb-[2.4rem]">
          <div className="text-[1.2rem] text-gray-10">
            {/* <Trans>
              By connecting your wallet to this platform, you hereby confirm and agree to the following conditions:
            </Trans> */}
            By connecting your wallet to this platform, you hereby confirm and
            agree to the following conditions:
          </div>
          <ScrollShadow className="h-[26rem] text-[1.2rem] text-gray-10 my-[1.6rem] space-y-[1.6rem]">
            <div>
              <span>1. </span>
              <span className="font-medium text-gray-00 mr-[4px]">
                {/* <Trans>Non-US and Non-South Korean Affiliation: </Trans> */}
                Non-US, UK, China, Singapore, South Korea, Hong Kong, Canada
                Affiliation:
              </span>
              {/* <Trans>
                You affirm that you are neither a citizen nor a resident of the United States of America or the Republic
                of Korea. Furthermore, you are not an entity formed under the laws of these countries, nor are you
                acting on behalf of any citizen, resident, or entity from the United States of America or the Republic
                of Korea.
              </Trans> */}
              You affirm that you are neither a citizen nor a resident of the
              United States of America, the United Kingdom, mainland China,
              Singapore, South Korea, Hong Kong, or Canada. Furthermore, you are
              not an entity formed under the laws of these countries, nor are
              you acting on behalf of any citizen, resident, or entity from
              these countries.
            </div>
            <div>
              <span>2. </span>
              <span className="font-medium text-gray-00 mr-[4px]">
                {/* <Trans>Exclusion of Sanctioned Countries: </Trans> */}
                Exclusion of Sanctioned Countries:
              </span>
              {/* <Trans>
                You confirm that you are not a citizen, resident, or entity of any country that is subject to
                international sanctions, including but not limited to North Korea and Iran. Additionally, you are not
                acting on behalf of any individual or entity from these sanctioned countries.
              </Trans> */}
              You confirm that you are not a citizen, resident, or entity of any
              country that is subject to international sanctions, including but
              not limited to North Korea, Cuba, Iran, Uzbekistan,
              Russian-controlled regions of Ukraine (currently including the
              Crimea, Donetsk, and Luhansk regions), Syria, and Russia.
              Additionally, you are not acting on behalf of any individual or
              entity from these sanctioned countries.
            </div>
            <div>
              <span>3. </span>
              <span className="font-medium text-gray-00 mr-[4px]">
                {/* <Trans>Adherence to Terms and Conditions: </Trans> */}
                Adherence to Terms and Conditions:
              </span>
              {/* <Trans>
                You acknowledge and agree to abide by the Terms and Conditions governing the use of this platform. Your
                access to and use of the platform and its services are contingent upon your continued compliance with
                these terms.
              </Trans> */}
              You acknowledge and agree to abide by the Terms and Conditions
              governing the use of this platform. Your access to and use of the
              platform and its services are contingent upon your continued
              compliance with these terms.
            </div>
            <div>
              <span>4. </span>
              <span className="font-medium text-gray-00 mr-[4px]">
                {/* <Trans>Acknowledgment of Restrictions: </Trans> */}
                Acknowledgment of Restrictions:
              </span>
              {/* <Trans>
                You understand that the restrictions outlined above are necessary for the platform to comply with
                international laws and regulations. Your agreement to these terms is a prerequisite for your use of the
                platform.
              </Trans> */}
              You understand that the restrictions outlined above are necessary
              for the platform to comply with international laws and
              regulations. Your agreement to these terms is a prerequisite for
              your use of the platform.
            </div>
            <div>
              <span>5. </span>
              <span className="font-medium text-gray-00 mr-[4px]">
                {/* <Trans>Right to Restrict Access: </Trans> */}
                Right to Restrict Access:
              </span>
              {/* <Trans>
                The platform reserves the right to restrict or terminate your access to its services if it is determined
                that you do not comply with these terms or if it becomes evident that you belong to any of the
                restricted categories mentioned above.
              </Trans> */}
              The platform reserves the right to restrict or terminate your
              access to its services if it is determined that you do not comply
              with these terms or if it becomes evident that you belong to any
              of the restricted categories mentioned above.
            </div>
          </ScrollShadow>
          <div className="flex items-start gap-[1rem]">
            <img
              className={cx("pt-[3px] cursor-pointer")}
              onClick={handleClickCheck}
              src={checked ? checkedCheckboxImg : emptyCheckboxImg}
              alt="checkbox"
            />
            <p
              onClick={handleClickCheck}
              className="select-none flex text-[1.2rem] text-gray-10 tracking-[-0.005em] cursor-pointer"
            >
              {/* <Trans>
                By connecting your wallet, you signify your understanding of and agreement to these terms. Failure to
                comply with these terms may result in immediate termination of your access to the platform and its
                services.
              </Trans> */}
              By connecting your wallet, you signify your understanding of and
              agreement to these terms. Failure to comply with these terms may
              result in immediate termination of your access to the platform and
              its services.
            </p>
          </div>
          <button
            onClick={handleClickAgree}
            disabled={!checked}
            className="mt-[1.2rem] brand-btn"
          >
            Agree
          </button>
        </div>
      </div>
    </ModalSkeletonWithPortal>
  );
});
